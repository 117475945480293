import {
  GET_CATEGORY_ERROR, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS, ADD_CATEGORY_REQUEST, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, GET_ALL_CATEGORY_ERROR, GET_ALL_CATEGORY_REQUEST,
  GET_ALL_CATEGORY_SUCCESS, ADD_CUSTOMER_CATEGORY_REQUEST, ADD_CUSTOMER_CATEGORY_SUCCESS, ADD_CUSTOMER_CATEGORY_ERROR, GET_ONE_CUSTOMER_CATEGORIES_REQUEST,
  GET_ONE_CUSTOMER_CATEGORIES_SUCCESS,
  GET_ONE_CUSTOMER_CATEGORIES_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getAllCustomerCategory = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_CATEGORY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/customerSale`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: GET_ALL_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (error.response.data.data === "No Record Found !") {
        return dispatch({
          type: GET_ALL_CATEGORY_ERROR,
          payload: [],
        });
      }
      return dispatch({
        type: GET_CATEGORY_ERROR,
        payload: [],
      });
    });
};
export const getOneCustomerCategory = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_CUSTOMER_CATEGORIES_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/custcategory/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: GET_ONE_CUSTOMER_CATEGORIES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (error.response.data.data === "No Record Found !") {
        return dispatch({
          type: GET_ONE_CUSTOMER_CATEGORIES_ERROR,
          payload: [],
        });
      }
      return dispatch({
        type: GET_CATEGORY_ERROR,
        payload: [],
      });
    });
};

export const loadCategory = (token, id) => (dispatch) => {
  dispatch({ type: GET_CATEGORY_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/customerSale/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (error.response.data.data === "No Record Found !") {
        return dispatch({
          type: GET_CATEGORY_ERROR,
          payload: [],
        });
      }
      return dispatch({
        type: GET_CATEGORY_ERROR,
        payload: [],
      });
    });
};

export const addCategory = (token, data, type) => (dispatch) => {

  console.log("data", data)
  dispatch({ type: ADD_CATEGORY_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}create/customerSale`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: type === "edit" ? data : { customers: data }
    // data: { customers: data }
    // data: { ...data[0], customer_id: id }

  })

    .then((response) => {


      return dispatch({ type: ADD_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_CATEGORY_ERROR,
        payload: error.response,
      });
    });
};

export const addCustomerCategory = (token, data, customer, spID, regID) => (dispatch) => {




  dispatch({ type: ADD_CUSTOMER_CATEGORY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/customerSale`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      customer_id: data.customer,
      sp_region_category_id: data.category_id,
      gender_id: data.gender_id,
      sales_person_id: data.salePersonID,
      region_id: data.regionid,
      is_active: true,
    }
  })

    .then((response) => {


      return dispatch({ type: ADD_CUSTOMER_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_CUSTOMER_CATEGORY_ERROR,
        payload: error.response,
      });
    });
};

export const deleteCategory = (token, id, data) => dispatch => {
  data.map((v) => {
    delete v.created_by;
    delete v.created_date;
    delete v.customer_id;
    delete v.gender_id;
    delete v.gender_name;
    delete v.is_active;
    delete v.is_deleted;
    delete v.last_updated_by;
    delete v.last_updated_date;
    delete v.region_id;
    delete v.region_name;
    delete v.sales_person_id;
    delete v.salesperson_name;
    delete v.sp_region_category_id;
    delete v.deleted_by;
    delete v.deleted_date;
    return v;
  })
  dispatch({ type: DELETE_CATEGORY_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/DeleteCategory/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: { data: data }
  })
    .then(response => {

      return dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_CATEGORY_ERROR,
        payload: error.response,
      });
    })
}

export const updateCategory = (token, data, id) => dispatch => {

  dispatch({ type: UPDATE_CATEGORY_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customerSale/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data
  })
    .then(response => {

      return dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_CATEGORY_ERROR,
        payload: error.response,
      });
    })
}
export const updateCustomerCategory = (token, data) => dispatch => {

  dispatch({ type: UPDATE_CATEGORY_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customerSale/${data.id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: {
      customer_id: data.customer_id,
      sp_region_category_id: data.category_id,
      gender_id: data.gender_id,
      sales_person_id: data.salePersonID,
      region_id: data.regionid,
      is_active: data.active,
    }
  })
    .then(response => {

      return dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_CATEGORY_ERROR,
        payload: error.response,
      });
    })
}