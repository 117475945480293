import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { searchProduct } from "../../redux/actions/searchProduct.action";
import { sendProduct } from "../../redux/actions/sendProductToBergen.action";
import notification from "../../utils/notification";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { searchRA } from "../../redux/actions/searchRA.action";
import { sendRA } from "../../redux/actions/sendRAToBergen.action";

const RAToBergen = () => {
  TabTitle("Return Authorization to Bergen");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Company Name",
      dataIndex: "BillingName",
      key: "BillingName",
    },
    {
      title: "Address1",
      dataIndex: "BillingAddress1",
      key: "BillingAddress1",
    },
    {
      title: "Address2",
      dataIndex: "BillingAddress2",
      key: "BillingAddress2",
    },

    {
      title: "City",
      dataIndex: "BillingCity",
      key: "BillingCity",
    },
    {
      title: "State",
      dataIndex: "BillingState",
      key: "BillingState",
    },
    {
      title: "Zip",
      dataIndex: "BillingZip_Code",
      key: "BillingZip_Code",
    },
    {
      title: "Country",
      dataIndex: "BillingCountry",
      key: "BillingCountry",
    },
    {
      title: "Non US Region",
      dataIndex: "Non_US_Region",
      key: "Non_US_Region",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Expected Date",
      
    },
    {
      title: "Carrier",
      dataIndex: "Shipper",
      key: "Shipper",
    },
    {
      title: "Tracking Numbers",
      // dataIndex: "Awbno",
      // key: "Awbno",
    },
    {
      title: "Driver Name",
      dataIndex: "DriverName",
      key: "DriverName",
    },
    {
      title: "License Plate",
      dataIndex: "LicensePlate",
      key: "LicensePlate",
    },
    {
      title: "Customer Po No",
      dataIndex: "PONO",
      key: "PONO",
    },
    {
      title: "Authorized Number",
      dataIndex: "AuthorizedNumber",
      key: "AuthorizedNumber",
      align: "right",
    },
    {
      title: "Memo",
      // dataIndex: "Memo",
      // key: "Memo",
      render: (text, record) => {
        return <>{form.getFieldValue().memo}</>;
      },
    },
    {
      title: "Warehouse",
      dataIndex: "Elanders UK19",
      key: "Elanders UK19",
      render: (text, record) => {
        return <>{"Elanders UK19"}</>;
      },
    },
    {
      title: "Shipment Type list",
      render: (text, record) => {
        return <>{"FLAT"}</>;
      },
    },
    {
      title: "Style",
      dataIndex: "Product_Code",
      key: "Product_Code",
    },
    {
      title: "Color",
      dataIndex: "Washtype",
      key: "Washtype",
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
    },
    {
      title: "Expected Quantity",
      dataIndex: "Qty",
      key: "Qty",
      align: "right",
    },
    {
      title: "Unit Cost",
      dataIndex: "UnitCost",
      key: "UnitCost",
      align: "right",
    },
    {
      title: "Product Description",
      dataIndex: "ProductDescription",
      key: "ProductDescription",
    },
    {
      title: "Product MSRP",
      dataIndex: "ProductMSRP",
      key: "ProductMSRP",
      align: "right",
    },
    {
      title: "Comments",
      dataIndex: "Comments",
      key: "Comments",
    },
    {
      title: "Shipment Type",
      // dataIndex: "ShipmentType",
      // key: "ShipmentType",
      render: (text, record) => {
        return <>{"FLAT"}</>;
      },
    },
    {
      title: "UPC",
      dataIndex: "UPC",
      key: "UPC",
    },
  ];

  const totalQuantity = () => {
    let sumQty = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.Qty));
    }, 0);
    return sumQty;

    console.log("sumQty", sumQty);
  };
  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.ra) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert RA No.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          setLoading(true);
          dispatch(searchRA(token, values)).then((res) => {
            console.log(res, 'res.type');
            setLoading(false);
              if (res?.payload?.data) {  
                setLoading(false);
                setControlSheet(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
              }

          })
          .catch((err)=> {
            console.log(err, 'res.type1');
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // setLoading(true);
    // await form
    //   .validateFields()
    //   .then((values) => {
    //     dispatch(getcontrolSheetLog(token, values)).then((res) => {
    //       setLoading(false);
    //       setControlSheet(res?.payload?.data);
    //       downloadExcelFileData(res?.payload?.data);
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  const onSearch = async () => {
    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchProduct(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  function downloadExcelFileData(data) {
    console.log(data);
    setExcelData(
      data.map((v) => {
        return {
          "Style": v.ProductCode,
          "Color": v.Washtype,
          "Size": v.ActSize,
          "UPC": v.UPC,
          "Description": v.style + v.Cut,
          "Price": v.RetailRate,
          "HsCode": v.HsCode,
          "Custom Description": v.Type.toUpperCase() + " " + v.commodity,
          "Country of Origin": v.COO,
        };
      })
    );
  }

  const onSubmit = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.ra) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert RA No.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          // setLoading(true);
          dispatch(sendRA(token, controlSheet)).then((res) => {
            if (res.type == "GET_SEND_RA_SUCCESS") {
              notification("success", "RA Send Successfully");
            }
            // setLoading(false);
            // setControlSheet(res?.payload?.data);
            downloadExcelFileData(res?.payload?.data);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchProduct(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Send RA to Bergen"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2 d-flex align-items-baseline">
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                style={{ width: "20%" }}
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={24} xl={24} xxl={24}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ra"
                            label="RA No"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            labelAlign="left"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 2 }}
                          >
                            <Button
                              type="primary"
                              className=""
                              onClick={onSearch}
                            >
                              <span className="px-2">Search</span>
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary mrpx-20"
                onClick={onFinish}
              >
                <span className="px-2">Search</span>
              </Button>

              {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : controlSheet.length > 0 ? (
          <>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div>Total Items: {controlSheet.length}</div>
                <div style={{ marginLeft: "20px" }}>
                  Total Quantity: {numeral(totalQuantity()).format("0,00,0")}
                </div>
              </div>
            </div>
            <Table
              // title={() => (
              //   <CSVLink
              //     filename={"StyleMaster.csv"}
              //     data={excelData}
              //     onClick={() => {}}
              //   >
              //     Download Excel
              //   </CSVLink>
              // )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={controlSheet}
              columns={columns}
              loading={controlLog.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
            />
          </>
        ) : (
          // <></>
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default RAToBergen;
