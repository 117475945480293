import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getStyle, addStyle, deleteStyle, updateStyle } from "../../redux/actions/style";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { ReadCookie } from "../../utils/readCookie";
import { getRights } from "../../redux/actions/userRights.action";
import { getStyleCombination, getCombinationForm, addStyleCombination, deleteStyleCombination, editStyleCombination } from '../../redux/actions/styleCombination.action';

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider, Select } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import { styleColumns, logColumns, } from "../../utils/cols";
import notification from '../../utils/notification';
import "./style.css"
import {TabTitle} from "../../utils/GeneralFunction"
const { TabPane } = Tabs;
const { Option } = Select;
export default function Style({ token }) {
    TabTitle("Style")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const styleNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [styleObj, setStyleObj] = useState(null);
    const dispatch = useDispatch();
    const { style, getDemoDefaultCompany, companyAccess, companyAll, log, userRights, styleCombination, combinationForm } = useSelector(state => state)

    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(true);
    const [styleName, setStyleName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');
    const [companyId, setCompanyId] = useState(ReadCookie('defaultCompany'));
    const [gender, setGender] = useState(null);
    const [editCombination, setEditCombination] = useState(false)
    const [styleModal, setStyleModal] = useState(false);
    const [combinationRecord, setCombinationRecord] = useState(null);
    const [defaultCheck, setDefaultCheck] = useState(false);


    const handleEdit = (record, type) => {

        if (type === "active") {
            dispatch(updateCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, styleObj.style_id, menuId)));
        }
        if (type === "delete") {
            dispatch(deleteCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, styleObj.style_id, menuId)));
        }
    }

    const createAssignCompany = (record) => {
        dispatch(createCompanyAccess(token, record, styleObj.style_id, menuId)).then((res) => dispatch(getCompanyAccess(token, styleObj.style_id, menuId)))
    }

    const companyCol = [
        { title: 'Company ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    {
                        record.is_active ?
                            <Tooltip title="Activate" color="#fbfbfb"  ><CheckCircleOutlined className='color-green' onClick={() => handleEdit(record, "active")} /></Tooltip> :
                            <Tooltip title="Deactivate" color="#fbfbfb"  ><CloseCircleOutlined className='color-red' onClick={() => handleEdit(record, "active")} /></Tooltip>}
                    {
                        dCompanyId === record.company_id.toString() ?
                            <Tooltip title="Default company can't be deleted" color="#fbfbfb"  >
                                <DeleteOutlined disabled twoToneColor="red" className='color-red mlpx-10 delete-button' />
                            </Tooltip> :
                            <Tooltip title="delete" color="#fbfbfb"  >
                                <Popconfirm placement="top" title={"Are you sure to delete this?"} onConfirm={() => handleEdit(record, "delete")} okText="Yes" cancelText="No">
                                    <DeleteOutlined twoToneColor="red" className='mlpx-10 color-red' />
                                </Popconfirm>
                            </Tooltip>
                    }
                </>
            ),
        },
    ];

    const companyAccessCol = [
        {
            title: 'Actions',
            key: 'action',
            width: 65,
            fixed: 'left',
            render: (text, record) => (
                <>
                    {
                        companyAccess?.payload?.data?.find(m => m.company_id === record?.company_id) ?
                            <>
                                <Tooltip title="Already assigned" color="#fbfbfb"  >
                                    <Button className='modalActionBTN' onClick={() => { }} shape='circle' icon={<CheckCircleOutlined className='color-red' />} />
                                </Tooltip>
                            </> :
                            <Tooltip title="Click to assign" color="#fbfbfb"  >
                                <Button className='modalActionBTN' onClick={() => createAssignCompany(record)} shape='circle' icon={<CloseCircleOutlined className='color-green' />} />
                            </Tooltip>
                    }
                </>
            ),
        },
        // { title: 'ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },

    ];

    const styleCombinationCol = [
        {
            title: 'Style Combination Id', dataIndex: 'style_combination_id', key: 'style_combination_id', width: 130,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        className='border-rad-4'
                        // placeholder='Search'
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false })
                        }}
                        onPressEnter={() => { confirm() }}
                        onBlur={() => { confirm() }}
                    />
                )
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.style_combination_id) return record.style_combination_id.toLowerCase().includes(value.toLowerCase())
            },
            sorter: (a, b) => a.style_combination_id - b.style_combination_id,
        },
        { title: 'Gender', dataIndex: 'gender_name', key: 'gender_name', width: 130, },
        { title: 'Gender Category', dataIndex: 'gender_category', key: 'gender_category', width: 130, },
        { title: 'Cut', dataIndex: 'cut', key: 'cut', width: 130, },
        { title: 'Fit Category', dataIndex: 'fit_category_name', key: 'fit_category_name', width: 130, },
        { title: 'Rise', dataIndex: 'rise_name', key: 'rise_name', width: 130, },
        { title: 'Rise Label', dataIndex: 'rise_label_name', key: 'rise_label_name', width: 130, },
        {
            title: 'Default', dataIndex: 'is_default', key: 'is_default', width: 130,
            render: (text, record) => record.is_default ? "True" : "False"
        },
        {
            title: 'Actions',
            key: 'action', width: 130,
            render: (text, record) => (
                <>
                    {
                        <>
                            <Tooltip title="Edit" color="#fbfbfb">
                                {/* <Button className='edit-Button' type="primary" size='small' onClick={() => handleEditCombination(record, "edit")} shape="circle" icon={<EditOutlined />} /> */}
                                <EditOutlined className='edit-Button' onClick={() => handleEditCombination(record, "edit")} />
                            </Tooltip>
                            <Popconfirm title={"Are you sure to delete this?"} onConfirm={() => handleEditCombination(record, "delete")} okText="Yes" cancelText="No">
                                <Tooltip placement='bottom' title="Delete" color="#fbfbfb">
                                    {/* <Button className='delete-Button ml-10' type="danger" size='small'  shape="circle" icon={<DeleteOutlined />} />  */}
                                    <DeleteOutlined className='delete-Button' />
                                </Tooltip>
                            </Popconfirm>
                        </>
                    }
                </>
            ),
        },

    ];
    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])


    useEffect(() => {
        dispatch(getStyle(token, menuId))
    }, [token])
    useEffect(() => {
        dispatch(getLog(token, { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }))
    }, [token])

    useEffect(() => {
        if (styleObj) {
            setFormState('view')
            form.setFieldsValue({
                id: styleObj.style_id,
                style: styleObj.style_name,
                active: styleObj.is_active
            });
            setStyleName(styleObj.style_name)
            setCheck(styleObj.is_active)
            dispatch(getCompanyAccess(token, styleObj.style_id, menuId))
            dispatch(getStyleCombination(token, styleObj.style_id))
            dispatch(getCombinationForm(token, dCompanyId))

        }
    }, [styleObj]);



    useEffect(() => {
        if (styleName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [styleName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
        setStyleModal(false)
        setGender(null)
        clearCombinationForm();
    };

    const handleCancel = () => {
        setVisible(false);
        setStyleModal(false)
        setGender(null)
        clearCombinationForm();

    };

    const handleTab = (key) => {
        if (key === "logs") {
            // data: { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }
            dispatch(getLog(token, { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }))
        }
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addStyle(token, { styleName, check }, menuId)).then((res) => {
                if (res.type === "ADD_STYLES_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_STYLES_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    dispatch(createCompanyAccess(token, data, res?.payload?.style_id, menuId)).then((res) => dispatch(getCompanyAccess(token, styleObj.style_id, menuId)))
                    notification("success", "Style Created", "Style created successfully!");
                    form.setFieldsValue({
                        style: ""
                    })
                    setFormState("")
                    setStyleName("")
                    setStyleObj(null)
                }
                dispatch(getStyle(token, menuId))
            })
        }

        if (formState === "edit") {

            dispatch(updateStyle(token, { styleName, check }, styleObj.style_id)).then((res) => {
                if (res.type === "UPDATE_STYLES_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_STYLES_SUCCESS") {
                    notification("success", "Style Updated", "Record updated successfully")
                }
                dispatch(getStyle(token, menuId))
            })
        }
    }

    const handleDelete = () => {
        if (styleObj) {
            dispatch(deleteStyle(token, styleObj.style_id)).then((res) => {
                if (res.type === "DELETE_STYLES_SUCCESS") {
                    notification("success", "Style Deleted", "Style deleted successfully!");
                }
                if (res.type === "DELETE_STYLES_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    style: ""
                })
                // setCheck(false)
                setStyleName("")
                setFormState('')
                dispatch(getStyle(token, menuId))
                setStyleObj(null)
            })
        }
    }

    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            styleNameInput.current.focus();
            form.setFieldsValue({
                style: ""
            })
            setCheck(true)
            setStyleName("")
            setStyleObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
        setStyleObj(null)
        setCheck(true)
        setStyleName("")
    }

    const handleCombinationSubmit = async (e) => {
        form.setFieldsValue({ [e.target.name]: e.target.value });
        await form.validateFields().then((values) => {
            values.company_id = dCompanyId;
            values.is_default = defaultCheck;
            if (!editCombination) {
                dispatch(addStyleCombination(token, values, styleObj)).then((res) => {
                    dispatch(getStyleCombination(token, styleObj.style_id))
                })
            }
            if (editCombination) {
                values.style_id = styleObj.style_id
                dispatch(editStyleCombination(token, combinationRecord.style_combination_id, values)).then((res) => {
                    dispatch(getStyleCombination(token, styleObj.style_id))
                })
            }

        })
        setStyleModal(false)
        setGender(null)
        clearCombinationForm();
    }

    const handleChange = (value, type) => {
        if (type === "gender") {
            setGender(value)
            form.setFieldsValue({
                genderCategory: null
            })
        }
    }

    const clearCombinationForm = () => {
        setGender(null)
        setEditCombination(false);
        setCombinationRecord(null);
        setDefaultCheck(false);
        form.setFieldsValue({
            rise: null,
            riseLabel: null,
            genderCategory: null,
            gender: null,
            fit: null,
            fitCategory: null
        })
    }

    const handleEditCombination = (record, type) => {
        if (type === "edit") {
            setCombinationRecord(record);
            setEditCombination(true)
            setStyleModal(true)
            setGender(record.gender_id);
            setDefaultCheck(record.is_default);
            form.setFieldsValue(({
                gender: record.gender_id,
                genderCategory: record.gender_category_id,
                rise: record.rise_id,
                riseLabel: record.rise_label_id,
                fit: record.cut_id,
                fitCategory: record.fit_category_id
            }))
        }

        if (type === "delete") {
            dispatch(deleteStyleCombination(token, record.style_combination_id)).then((res) => {
                if (res.type === "DELETE_STYLE_COMBINATION_SUCCESS") {
                    dispatch(getStyleCombination(token, styleObj.style_id))
                }
            })
        }

    }

    const handleReset = () => {
        handleEditCombination(combinationRecord, "edit")
    }

    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'>
                <PageHeader title={"Style"} minimize={setMinimize} check={minimize} />
                {!minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new'>
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables formState={formState} type="style" scrollX={"20rem"} styleObj={setStyleObj} scroll={602} perPage={25} source={style?.payload?.data} col={styleColumns} paginate load={style.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'>
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            // labelCol={{ span: 4 }}
                                            >
                                                <div className='customRow styleFormUpper ' >

                                                    {styleObj !== null && !(formState === "add") &&
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="ID"
                                                                name="id"
                                                                initialValue={form.getFieldValue().id}
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 14 }}
                                                            >
                                                                <Input size="small" className='styleInput id-field-style' readOnly />

                                                            </Form.Item>
                                                        </div>
                                                    }
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Style"
                                                            name="style"
                                                            initialValue={form.getFieldValue().style}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Input size="small" disabled={!(formState === "add" || formState === "edit")} ref={styleNameInput} onChange={(e) => setStyleName(e.target.value)} className='styleInput' />
                                                        </Form.Item>
                                                    </div>

                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className='modalStyle' >
                                            {styleObj === null ?
                                                <Tooltip title="Please select style first" color="#fbfbfb"  >
                                                    <Button type='primary' disabled={styleObj === null ? true : false} className='' onClick={showModal} >Assign Companies</Button>
                                                </Tooltip>
                                                : <Button type='primary' className='modalButton' onClick={showModal} >Assign Companies</Button>}
                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                                <TabPane tab="Style Combination" key="1">
                                                    <div className='tabs' >
                                                        {styleObj && <Button type='primary' disabled={formState !== "edit"} className='addCombinationBtn' onClick={() => setStyleModal(true)} >Add Combination</Button>}
                                                        {styleObj ?

                                                            <Tables
                                                                type="styleCombination"
                                                                scroll={340}
                                                                perPage={12}
                                                                source={styleCombination?.payload?.data}
                                                                loading={styleCombination.loading}
                                                                col={styleCombinationCol} />
                                                            : <span className="w-100" >Please select style</span>
                                                        }

                                                        <Modal
                                                            title="Style Combination"
                                                            visible={styleModal}
                                                            onOk={handleCombinationSubmit}
                                                            onCancel={handleCancel}
                                                            // footer={null}
                                                            footer={[
                                                                <Button type='danger' onClick={() => handleCancel()} >Cancel</Button>,
                                                                editCombination && <Button type="primary" onClick={() => handleReset()} >Reset</Button>,
                                                                <Button disabled={(formState !== "edit")} type='primary' onClick={(e) => handleCombinationSubmit(e)} >{editCombination ? "Edit" : "Add"}</Button>,
                                                            ]}
                                                        >
                                                            <Form onFinish={handleCombinationSubmit} form={form} name="basic">
                                                                <Row justify="space-between">
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Style"
                                                                            name="style"
                                                                            labelAlign="left"
                                                                        >
                                                                            <Input disabled readOnly value={styleObj?.style_name} className='border-rad-4' />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Gender"
                                                                            name="gender"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Gender is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Gender"
                                                                                onChange={(value) => handleChange(value, 'gender')}
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.gender?.map(m => {
                                                                                    return (
                                                                                        <Option key={m.gender_id} value={m.gender_id} disabled={!m.is_active}>{m.gender_name}</Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Gender Category"
                                                                            name="genderCategory"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Gender Category is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Gender Category"
                                                                                disabled={gender === null}
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.genderCategory?.map(item => {
                                                                                    if (gender === item.id) {
                                                                                        return (
                                                                                            item?.data?.map(item => {
                                                                                                return (
                                                                                                    <Option disabled={!item.is_active} key={item.gender_category_id} value={item.gender_category_id}>
                                                                                                        {item.gender_category_name}
                                                                                                    </Option>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Rise"
                                                                            name="rise"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Rise is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Rise"
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.rise?.map(m => {
                                                                                    return (
                                                                                        <Option value={m.rise_id} key={m.rise_id} disabled={!m.is_active}>{m.rise_name}</Option>
                                                                                    )
                                                                                })}

                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Rise Label"
                                                                            name="riseLabel"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Rise Label is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Rise Label"
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.riseLabel?.map(m => {
                                                                                    return (
                                                                                        <Option value={m.rise_label_id} key={m.rise_label_id} disabled={!m.is_active}>{m.rise_label_name}</Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Fit/Cut"
                                                                            name="fit"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Fit/Cut is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Fit/Cut"
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.cut?.map(m => {
                                                                                    return (
                                                                                        <Option value={m.cut_id} key={m.cut_id} disabled={!m.is_active}>{m.cut_name}</Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Fit Category"
                                                                            name="fitCategory"
                                                                            labelAlign="left"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Fit Category is required"
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                // placeholder="Fit Category"
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                filterSort={(optionA, optionB) =>
                                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                                }
                                                                            >
                                                                                {combinationForm?.payload?.fitCategory?.map(m => {
                                                                                    return (
                                                                                        <Option value={m.fit_category_id} key={m.fit_category_id} disabled={!m.is_active}>{m.fit_category_name}</Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24}>
                                                                        <Form.Item
                                                                            labelCol={{ span: 6 }}
                                                                            label="Default"
                                                                            name="isDefault"
                                                                            labelAlign="left"
                                                                        >
                                                                            <Checkbox checked={defaultCheck} onChange={(e) => setDefaultCheck(e.target.checked)} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                </Row>
                                                            </Form>
                                                        </Modal>

                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Companies" key="2">
                                                    <div className='tabs' >
                                                        <Tables type="company" scroll={340} perPage={12}
                                                            source={(formState === "add") ? getDemoDefaultCompany?.payload?.data : (formState === "edit" || formState === "view" ? companyAccess?.payload?.data : [])} loading={(formState === "add") ? getDemoDefaultCompany.loading : companyAccess.loading} col={companyCol} />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >

                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                        <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}>
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>}
            </div>
            {minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>}
        </div >
    )
}