import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from 'antd';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { getRegion } from '../../redux/actions/region.action';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import { getAllSaleperson } from '../../redux/actions/salesPerson';
import { loadRegionCategory, addRegionCategory, updateRegionCategory, deleteRegionCategory } from '../../redux/actions/regionCategory';
import { getLog } from '../../redux/actions/log.action';
import Tables from "../../components/Table/Tables";
import { regionCatColumns, logColumns } from '../../utils/cols';
import notification from '../../utils/notification';



const { TabPane } = Tabs;
const { Option } = Select

const RegionSale = ({ token }) => {
    const dispatch = useDispatch()
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [catObj, setCatObj] = useState(null);
    const [form] = Form.useForm();
    const { regionCategory, salePerson, region, userRights, log } = useSelector(state => state)
    const [check, setCheck] = useState(true);
    const [regionid, setRegionId] = useState(null);
    const [personid, setPersonId] = useState(null);
    const [disable, setDisable] = useState(false);
    const id = ReadCookie('menuId');



    useEffect(() => {
        dispatch(getRegion(token, id))
    }, [token, id, dispatch])

    useEffect(() => {
        dispatch(getAllSaleperson(token, id))
    }, [token, id, dispatch])
    useEffect(() => {
        dispatch(loadRegionCategory(token, id))
    }, [token, id, dispatch])
    useEffect(() => {
        dispatch(getLog(token))
        dispatch(getLog(token, { scm_sp_region_category: "scm_sp_region_category" }))
    }, [token, dispatch])
    useEffect(() => {
        if (catObj) {
            setDisable(false)
        }
        if ((regionid || personid) === null) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [catObj, regionid, personid])
    useEffect(() => {

        if (catObj) {
            setFormState('view')
            form.setFieldsValue({
                id: catObj.sp_region_category_id,
                cid: catObj.region_id,
                active: catObj.is_active,
                sid: catObj.sales_person_id
            });
            setPersonId(catObj.sales_person_id)
            setRegionId(catObj.region_id)
            setCheck(catObj.is_active)
        }
    }, [catObj]);

    const handleDelete = () => {
        if (catObj) {

            dispatch(deleteRegionCategory(token, catObj.sp_region_category_id)).then((res) => {
                if (res.type === "DELETE_REGIONCATG_SUCCESS") {
                    notification("success", "Category deleted", "Category deleted successfully!");
                }
                if (res.type === "DELETE_REGIONCATG_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    cid: null,
                    sid: null
                })
                setCatObj(null)
                setPersonId(null)
                setRegionId(null)
                setFormState('view')
                dispatch(loadRegionCategory(token, id))
            })
        }
        setFormState("view")
    }
    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    const formSave = async () => {
        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addRegionCategory(token, { region_id: regionid, is_active: check, sales_person_id: personid }, id)).then((res) => {

                    if (res.type === "ADD_REGIONCATG_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_REGIONCATG_SUCCESS") {
                        notification("success", "Category created", "Category created successfully!");
                        form.setFieldsValue({
                            cid: null,
                            sid: null
                        })
                        setFormState("view")
                        // setCheck(false)
                        setCatObj(null)
                    }
                    dispatch(loadRegionCategory(token, id))

                })
            }
            if (formState === "edit") {
                dispatch(updateRegionCategory(token, { region_id: catObj.region_id, is_active: check, sales_person_id: catObj.sales_person_id }, catObj.sp_region_category_id)).then((res) => {
                    if (res.type === "UPDATE_REGIONCATG_ERROR") {
                        notification("error", "Update", "Something went wrong")
                    }
                    if (res.type === "UPDATE_REGIONCATG_SUCCESS") {
                        notification("success", "Category updated", "Record updated successfully")
                    }
                    dispatch(loadRegionCategory(token, id))
                })
            }
        }).catch((err) => {

        })
    }
    useEffect(() => {
        if (formState === "add") {
            form.setFieldsValue({
                cid: null,
                sid: null,
            })
            setCheck(true)
            setRegionId(null)
            setPersonId(null)
            setCatObj(null)
        }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setCatObj(null)
        setCheck(true)
        form.setFieldsValue({
            cid: null,
            sid: null
        })
        setRegionId(null)
        setPersonId(null)
    }
    const selectHandlerRegion = (e) => {
        if (formState === "edit") {
            catObj.region_id = e
        } else {
            setRegionId(e)

        }
    }
    const selectHandlerPeron = (e) => {
        if (formState === "edit") {
            catObj.salePerson_id = e
        } else {
            setPersonId(e)
        }
    }

    return <div className='styleContainer'>
        <div className='styleBody h-100' >
            <PageHeader title={"Category"} minimize={setMinimize} check={minimize} />
            {
                !minimize &&
                <div className='bodyStyle' >
                    <div className='crudDiv' >
                        <CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} reset={filterReset} disable={disable} save={formSave} onDelete={handleDelete} /></div>
                    <div className='styleContent' >
                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                                <div className='styleTable' >
                                    <Tables type="regionCat" formState={formState} catObj={setCatObj} source={regionCategory?.payload?.data} col={regionCatColumns} paginate scroll={500} perPage={25} load={regionCategory.loading} rowKey="sp_region_category_id" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                                <div className='styleForm'  >
                                    {/* <div className='testStyle' > */}
                                    <Form
                                        form={form}
                                        name="basic"
                                    // labelCol={{ span: 8 }}

                                    >
                                        <div className='customRow styleFormUpper ' >
                                            <div className='customCols slc'>
                                                {catObj !== null && !(formState === "add") &&

                                                    <Form.Item
                                                        label="ID"
                                                        name="id"
                                                        initialValue={form.getFieldValue().id}
                                                    // labelCol={{ span: 6 }}
                                                    // wrapperCol={{ span: 18 }}

                                                    >
                                                        <Input className='styleInput id-field-style' readOnly />

                                                    </Form.Item>


                                                }
                                            </div>
                                            <div className="customCols slc">
                                                <div className='relative '>
                                                    <Form.Item
                                                        name="cid"
                                                        label="Region"
                                                        labelAlign='left'
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Region is required",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            size='small'
                                                            showSearch
                                                            loading={region.loading}
                                                            disabled={
                                                                !(formState === "add" || formState === "edit")
                                                            }
                                                            optionFilterProp="children"
                                                            //    defaultValue={
                                                            //      formState === "edit" || formState==="add"
                                                            //        ? form.getFieldValue().cid
                                                            //        : null
                                                            //    }
                                                            className="w-100"
                                                            // placeholder="Region"

                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }

                                                            onSelect={(e) => selectHandlerRegion(e)}
                                                            allowClear

                                                        >
                                                            {region?.payload?.data.map((region) => {
                                                                return (
                                                                    <Option value={region.region_id}>
                                                                        {region.region_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="sid"
                                                        label="Sale Person"
                                                        labelAlign='left'
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Sales Person is required",
                                                            },
                                                        ]}

                                                    >
                                                        <Select
                                                            size='small'
                                                            showSearch
                                                            loading={salePerson.loading}
                                                            disabled={
                                                                !(formState === "add" || formState === "edit")
                                                            }
                                                            optionFilterProp="children"
                                                            //    defaultValue={
                                                            //      formState === "edit" || formState==="add"
                                                            //        ? form.getFieldValue().cid
                                                            //        : null
                                                            //    }
                                                            className="w-100"
                                                            // placeholder="SalesPerson"

                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                          }
                                                            onSelect={(e) => selectHandlerPeron(e)}
                                                            allowClear

                                                        >
                                                            {salePerson?.payload?.data.map((sale) => {
                                                                return (
                                                                    <Option value={sale.sales_person_id}>
                                                                        {sale.salesperson_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='customCols slc'>
                                            </div>
                                            <div className='customCols slc mlpx-40'>
                                                <Form.Item
                                                    label="Active"
                                                    name="active"

                                                    labelCol={{ span: 4 }}
                                                    wrapperCol={{ span: 18 }}
                                                >
                                                    <Checkbox style={{ marginLeft: "66px" }} disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                    {/* </div> */}
                                    <Divider />
                                    <div className='styleTab' >
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  /**onChange={(key) => handleTab(key)} */ >
                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables type="company" scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>

                            </Col>

                        </Row>
                    </div>

                </div>
            }


        </div>
    </div>
}


export default RegionSale