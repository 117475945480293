import {

	DELETE_CREDIT_MEMO_INVOICE_REQUEST,
	DELETE_CREDIT_MEMO_SUCCESS,
	DELETE__CREDIT_MEMO_ERROR,
	// GET_ALL_BILL_CREDIT_ERROR,
	// GET_ALL_BILL_CREDIT_REQUEST,
	// GET_ALL_BILL_CREDIT_SUCCESS,
	// GET_BILL_CREDIT_FORM_DATA_ERROR,
	// GET_BILL_CREDIT_FORM_DATA_REQUEST,
	// GET_BILL_CREDIT_FORM_DATA_SUCCESS,
	// GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR,
	// GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST,
	// GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS,
	GET_ONE_CREDIT_MEMO_INVOICE_ERROR,
	GET_ONE_CREDIT_MEMO_INVOICE_REQUEST,
	GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS,
	UPDATE_CREDIT_MEMO_INVOICE_REQUEST,
	UPDATE_CREDIT_MEMO_INVOICE_SUCCESS,
	UPDATE_CREDIT_MEMO_INVOICE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

// export const addBillCreditHeader = (token, formData, companyId) => (dispatch) => {
// 	console.log(formData, "formData");
// 	dispatch({ type: ADD_BILL_CREDIT_HEADER_REQUEST });
// 	let newData = {
// 		is_approved: formData.approveStatus,
// 		account_id: formData.account,
// 		amount: formData.amount,
// 		bill_date: moment(formData.billDate).format("MM-DD-YYYY"),
// 		company_id: companyId,
// 		gl_period_id: formData.postingPeriod,
// 		remarks: formData.memo,
// 		reference_no: formData.referenceNo,
// 		vendor_id: formData.adjustmentLocation,
// 		ship_via: formData.shipVia,
// 		currency: formData.currency,
// 		due_date: moment(formData.dueDate).format("MM-DD-YYYY"),
// 		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
// 	};
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}create/payPurchaseBillCreditHeader`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 		data: newData,
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: ADD_BILL_CREDIT_HEADER_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: ADD_BILL_CREDIT_HEADER_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

export const updateCreditInvoice = (token, data, billId) => (dispatch) => {
	data.map((e) => {
		let invoice_id = e.invoice_header_id;
		delete e.invoice_header_id

		return ({
			...e,
			invoice_id: invoice_id
		})
	})
	dispatch({ type: UPDATE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/arcreditmemoinvoice/${billId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { dataLine: data },
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_CREDIT_MEMO_INVOICE_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_CREDIT_MEMO_INVOICE_ERROR,
				payloadUpdate: error.response,
			});
		});
};

// export const getAllBillCreditHeader = (token, transactionNo, account, from_date, to_date, postingPeriod, vendor, currency, referenceNo, fromAmount, toAmount) => (dispatch) => {
// 	dispatch({ type: GET_ALL_BILL_CREDIT_REQUEST });
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}getAll/payPurchaseBillCreditHeader`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 		data: {
// 			company_name: null,
// 			bill_credit_header_no: transactionNo ? transactionNo : null,
// 			account_name: account ? account : null,
// 			from_amount: fromAmount ? fromAmount : null,
// 			to_amount: toAmount ? toAmount : null,
// 			gl_period_name: postingPeriod ? postingPeriod : null,
// 			vendor_name: vendor ? vendor : null,
// 			reference_no: referenceNo ? referenceNo : null,
// 			from_date: from_date ? moment(from_date).format("MM-DD-YYYY") : null,
// 			to_date: to_date ? moment(to_date).format("MM-DD-YYYY") : null,
// 			exchange_rate: currency ? currency : null,
// 			bill_credit_header_id: null,
// 			location_name: null,
// 			is_approved: null,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ALL_BILL_CREDIT_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ALL_BILL_CREDIT_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

export const getOneCreditMemoInvoice = (token, id) => (dispatch) => {
	console.log(id, "iddddd");
	dispatch({ type: GET_ONE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/arcreditmemoinvoice/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_INVOICE_ERROR,
				payload: error.response,
			});
		});
};

// export const getOneBillCreditDetail = (token, id) => (dispatch) => {
// 	dispatch({ type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST });
// 	return axios({
// 		method: "GET",
// 		url: `${BASEURL}getOne/payPurchaseBillHeader/${id}`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS,
// 				payload: response?.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

export const deleteCreditMemoInvoice = (token, id, data) => (dispatch) => {
	dispatch({ type: DELETE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/arcreditmemoinvoice/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_CREDIT_MEMO_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE__CREDIT_MEMO_ERROR,
				payload: error.response,
			});
		});
};

// export const getBillCreditFormData = (token, clear) => (dispatch) => {
// 	dispatch({ type: GET_BILL_CREDIT_FORM_DATA_REQUEST });

// 	if (clear) {
// 		return dispatch({
// 			type: GET_BILL_CREDIT_FORM_DATA_SUCCESS,
// 			payload: {
// 				location: [],
// 			},
// 		});
// 	}
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}getAll/payPurchaseBillHeaderFormData`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_BILL_CREDIT_FORM_DATA_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_BILL_CREDIT_FORM_DATA_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };