import {
  GET_ALL_CUSTOMERS_LIST_ERROR,
  GET_ALL_CUSTOMERS_LIST_SUCCESS,
  GET_ALL_CUSTOMERS_LIST_REQUEST,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
export const loadCustomersList = (token, customerCode, regionID, customerTypeID, salePersonID, paymentTerm, customerName, customerid,shippingMethodID, freightTerm, shippingAccount, resalecertificate, orderStatusID, email, billAddress, apfirstname, aplastname, active, ishold,phoneNumber, isApproved) => (dispatch) => {
  dispatch({ type: GET_ALL_CUSTOMERS_LIST_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/Customerlist`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      //
      p_resale_certificate_no: resalecertificate ? resalecertificate : null,
      p_customer_code: customerCode ? customerCode : null,
      p_region_id: regionID === "All" ? null : regionID,
      p_customer_name: customerName ? customerName : null,
      p_saleperson_id: salePersonID === "All" ? null : salePersonID,
      p_customer_type_id: customerTypeID === "All" ? null : customerTypeID,
      payment_term_id: paymentTerm === "All" ? null : paymentTerm,
      p_shipping_method: shippingMethodID === "All" ? null : shippingMethodID,
      p_freight_term_id: freightTerm === "All" ? null : freightTerm,
      p_shipping_account_no: shippingAccount ? shippingAccount : null,
      // p_resale_certificate_no: null,
      p_order_status_id: orderStatusID === "All" ? null : orderStatusID,
      p_email_address: email ? email : null,
      p_default_bill_address: billAddress ? billAddress : null,
      p_first_name: apfirstname ? apfirstname : null,
      p_last_name: aplastname ? aplastname : null,
      p_active: active ? active : null,
      p_is_hold: ishold ? ishold : null,
      p_customer_id: customerid ? customerid : null,
      p_phone_no: phoneNumber ? phoneNumber : null,
      p_is_approved: isApproved ? isApproved : null,
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CUSTOMERS_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CUSTOMERS_LIST_ERROR,
        payload: [],
      });
    });
};

