import React, { useState, useRef } from 'react';
import "./QueryTool.css";
import { Input, Form, Button, Table } from 'antd';
import { Row as Brow, Col as Bcol } from "reactstrap";
import SubTitles from "../../components/SubTitle/SubTitle";
import { useDispatch } from 'react-redux';
import { getQueries } from "../../redux/actions/querytool.action";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";


const QueryTool = () => {
    // const { log, saleOrderFormData, Shipping, Billing, ProductSale, saleOrderRelatedRecord, saleOrderLine } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [queryList, setQueryList] = useState([]);
    const submitButton = useRef(null);
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const token = ReadCookie("token");


    const onFinish = async (e) => {
        await form.validateFields().then((values) => {
            // 
            // 
            if (values.query) {
                dispatch(getQueries(token, values)).then((res) => {
                    if (res?.type === "GET_ALL_QUERY_SUCCESS") {
                        notification("success", "Successfuly Fetched!");
                        setQueryList(res?.payload?.data);
                        form.setFieldsValue({ error: "" });

                    }
                    if (res?.type === "GET_ALL_QUERY_ERROR") {
                        form.setFieldsValue({ error: res?.payload?.data });

                    }
                })
            } else {
                notification("error", "Enter Your Query");
            }
        })
    }


    const onFinishFailed = () => {
        alert("Hello")
    }
    let cloumns = [];

    queryList.length > 0 && Object.keys(queryList[0])?.map((key, index) => (
        // queryData.push(queryList[index].no = index + 1),
        <span>{cloumns.push({
            title: key, dataIndex: key,
            //defaultSortOrder: 'descend',
            sorter: true
        })}
        </span>))



    return (
        <div >
            {/* <SubTitles name={"Query Tool"} /> */}
            <Form
                requiredMark={false}
                autoComplete="off"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
                name="basic"
            >

                <div className="saleOrderForm">
                    <SubTitles name={"Query Tool"} />

                    <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
                        <Button
                            type="primary"
                            className="queryBuuton"
                            htmlType="submit"
                            // style={{ display: "none" }}
                            ref={submitButton}
                        // onClick={onFinish}
                        >
                            Run Query
                        </Button>
                        <div className='queryTool'>
                            <Brow>
                                <Bcol md={3} xl={3} xxl={3} className="pbpx-10">
                                    <div className="firstSectionRo">
                                        <Form.Item
                                            name="query"
                                            label="Query"
                                            labelAlign="left"
                                        >
                                            <TextArea
                                                className={`queryToolInput`}
                                                placeholder="Query"
                                                autoSize={{ minRows: 14, maxRows: 14 }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Bcol>
                                <Bcol md={3} xl={3} xxl={3}>
                                    <div className="firstSectionRo">
                                        <Form.Item
                                            name="error"
                                            label="Error"
                                            labelAlign="left"
                                        >
                                            <TextArea
                                                className={`queryToolInput`}
                                                placeholder="Error"
                                                autoSize={{ minRows: 14, maxRows: 14 }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Bcol>
                            </Brow>
                        </div>


                    </div>

                    {
                        queryList.length > 0 ? (
                            <Table
                                className='tableBorder'
                                // type={"company"}
                                loading={queryList.loading}
                                // scroll={({ x: 600 }, { y: 400 })}
                                //   perPage={10000}
                                // scroll={({ x: 600 }, { y: 400 })}
                                size="small"
                                bordered={false}
                                dataSource={queryList}
                                columns={cloumns}
                                pagination={{ pageSize: 1000 }}
                            // pagination={false}
                            // pagination={false}
                            />
                        ) : null
                    }



                </div>
            </Form>

        </div>
    )
}

export default QueryTool;