import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getTableSchema } from "../../redux/actions/tableSchema.action";
import { getColumnSchema } from "../../redux/actions/columnSchema.action";
import { updateTable } from "../../redux/actions/updateTable.action";
import { useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { Tag } from "antd";
import { getAllSelectQuery } from "../../redux/actions/getAllSelectQuery.action";
const StockUpdate = () => {
  // const { tableschema } = useSelector((state) => state);
  const [tableName, setTableName] = useState();
  const [selectTable, setSelectTable] = useState([]);
  const [saveColumn, setSaveColumn] = useState([]);
  const [selectCol, setSelectCol] = useState([]);
  const [result, setResult] = useState([]);
  const [response, setResponse] = useState([]);
  const [excel, setExcel] = useState([]);
  const [keys, setKeys] = useState([]);
  // const [matching, setMatching] = useState([]);
  const [initialkey, setinitialkey] = useState();
  // const [data, setData] = useState();
  const [loading, setloading] = useState(false);
  const [excelHeader, setExcelHeader] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [joinData, setJoinData] = useState([]);
  const [operationJoin, setOperationJoin] = useState();
  const [operationQuery, setOperationQuery] = useState();
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  let array = [];


  console.log("operationJoin", {operationJoin
   , operationQuery})


  const handleOnChange = (e) => {
    if (excel === "") {
      Swal.fire({
        title: "Please Insert Excel File First!",
        icon: "warning",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1500,
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    }
    setSelectTable(e.target.value);
    // setSaveColumn([])
    setSelectCol([]);
  };

  const handleOnSubmit = (e, data, m) => {
    array = [...result];
    let arrayIndex;
    const found = array.find((element, index) => {
      arrayIndex = index;
      return element.column_name === data;
    });
    if (array.includes(data)) {
      return;
    } else {
      if (found) {
        //   array[arrayIndex] = {
        //     column_name: data,
        //     action: e.target.value,
        //     matching_column: initialkey,
        //     values: [],
        //   };
        array[arrayIndex].push(data);
      } else {
        //   array.push({
        //     column_name: data,
        //     action: e.target.value,
        //     matching_column: initialkey,
        //     values: [],
        //   });
        array.push(data);
      }
    }
    //
    setResult(array);
    //
  };

  useEffect(() => {
    dispatch(getTableSchema(token))
      .then((res) => {
        let sortedDates = res?.payload?.data.sort((p1, p2) =>
          p1.tablename > p2.tablename ? 1 : p1.tablename < p2.tablename ? -1 : 0
        );
        //
        setTableName(sortedDates);
        // setTableName(res?.payload?.data);
      })
      .catch((error) => {});
  }, [dispatch, token]);

  let body = {
    column: selectTable,
  };
  useEffect(() => {
    dispatch(getColumnSchema(token, body))
      .then((res) => {
        setSelectCol(res?.payload?.data);
      })
      .catch((error) => {});
  }, [selectTable]);

  let arr = [];
  let arr2 = [];
  let json;
  let jsonArray = [];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        //
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        //
        json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });

        jsonArray = json;
        excel.push(jsonArray);
        setExcel(jsonArray);
        //
        excelHeader.push(Object.keys(jsonArray[0]));

        //
        keys.push(Object.keys(excel[0][0]));
        //
        setinitialkey(keys[0][0]);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleOnUpdate = async () => {
    // setloading(true);

    let body = {
      data: [
        { tableName: selectTable },
        { columns: saveColumn }, ////result
        { excel: excel },
      ],
    };
    // { tableName, columns, conditions, operators } =req.body

    dispatch(getAllSelectQuery(token, body)).then((res) => {
      setloading(false);
      setResponse(res?.payload?.data);
      Swal.fire({
        title: "Good Job!",
        text: "Your Query is submitted!",
        icon: "success",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 5000);
    });
  };

  return (
    <div className="styleContainer">
      <PageHeader title={"Stock Update"} />
      {loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : null}
      {
        <div>
          <Collapse
            style={{ width: "99%", margin: "10px" }}
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <div className="">
              <div className="steps" style={{ width: "300px" }}>
                <label className="upload_label">Step 1 - Select a Table</label>
                <select className="choose_table" onChange={handleOnChange}>
                  <option>-Please Choose a Table-</option>
                  {tableName &&
                    tableName.map((val, index) => {
                      // {setCheck(val.tableName)}
                      return <option key={index}>{val.tablename}</option>;
                    })}
                </select>
              </div>
              <br />
              {/* For Operation */}
              <div className="steps">
                <label>Step 2 - OperationPerform</label>
                <div className="column_div">
                  <div className="choose_columns">
                    <input
                      className="upload_checkbox"
                      type="checkbox"
                      value={operationQuery}
                      onChange={(e) => {
                        setOperationQuery(e.target.checked);
                        
                      }}
                    />
                    <p>for Query</p>
                  </div>
                  <div className="choose_columns">
                    <input
                      className="upload_checkbox"
                      type="checkbox"
                      value={operationJoin}
                      onChange={(e) => {
                        console.log(e, "eeeeeeeeeeeeeeee");
                        // if(e.target.checked===true){
                        // console.log(e.target.checked)
                        setOperationJoin(e.target.checked);
                        
                        // }
                      }}
                    />
                    <p>for Join</p>
                  </div>
                </div>
              </div>
              {/* column Selection */}
              {/* Query Column */}
              <div className="steps">
                {operationQuery === true && (
                  <label>Step 3 - Select Columns</label>
                )}
                <div className="column_div">
                  {operationQuery === true &&
                    selectCol.map((val, i) => {
                      return (
                        <div key={i} className="choose_columns">
                          <input
                            className="upload_checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                let testing = {
                                  table: selectTable,
                                  column: val.column_name,
                                };
                                setQueryData([...queryData, testing]);

                                arr = [...saveColumn];
                                arr2.push(selectTable);

                                arr.push(val.column_name);
                                setSaveColumn(arr);
                                // setSelectTable([...selectTable])
                              } else {
                                arr = [...saveColumn];
                                const index = arr.indexOf(val.column_name);
                                if (index > -1) {
                                  arr.splice(index, 1);
                                }
                                setSaveColumn(arr);
                              }
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].column_name === saveColumn[i]) {
                                  result[i] = result.pop();
                                } else {
                                }
                              }
                            }}
                          />
                          <p>{val.column_name}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* Join Column */}
              <div className="steps">
                {operationJoin === true && (
                  <label>Step 3 - Select Columns</label>
                )}
                <div className="column_div">
                  {operationJoin === true &&
                    selectCol.map((val, i) => {
                      return (
                        <div key={i} className="choose_columns">
                          <input
                            className="upload_checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                let testing = {
                                  table: selectTable,
                                  column: val.column_name,
                                };
                                if (
                                  joinData.some(
                                    (v) => v.table === selectTable
                                  )
                                ) {
                                  return;
                                } else {
                                  setJoinData([...joinData, testing]);
                                }
                                console.log("testing", testing)
                                // console.log("testing joinData", joinData)

                                //  joinData?.filter((data)=>{
                                //     console.log(data,"laladata")
                                //       return data.table=!data.table
                                //     })

                                arr = [...saveColumn];
                                arr2.push(selectTable);

                                arr.push(val.column_name);
                                setSaveColumn(arr);
                                // setSelectTable([...selectTable])
                              } else {
                                arr = [...saveColumn];
                                const index = arr.indexOf(val.column_name);
                                if (index > -1) {
                                  arr.splice(index, 1);
                                }
                                setSaveColumn(arr);
                              }
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].column_name === saveColumn[i]) {
                                  result[i] = result.pop();
                                } else {
                                }
                              }
                            }}
                          />
                          <p>{val.column_name}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              {console.log(joinData, "JOINDATA")}
              {/*column End  */}
              {/* Table Join */}
              <div className="steps">
                <br />
                <div>
                  {operationJoin === true ? (
                    <>
                      <table id="tables">
                        <thead>
                          <tr>
                            <th>Database Table Name</th>
                            <th>Database Column Name</th>
                            <th>Join</th>
                            <th>Table Join</th>
                            <th>Select</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {joinData.length > 0 ? (
                            <>
                              {joinData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{data.table}</td>
                                    <td>{data.column}</td>
                                    <td>
                                      <select className="choose_table">
                                        <option>-Please Choose a Joint-</option>
                                        <option key={index} value={"innerJoin"}>
                                          InnerJoin
                                        </option>
                                        ;
                                        <option key={index} value={"leftJoin"}>
                                          leftJoin
                                        </option>
                                        ;
                                      </select>
                                    </td>
                                    <td>
                                      <select className="choose_table">
                                        <option>-Please Choose a Table-</option>
                                        {tableName &&
                                          tableName.map((val, index) => {
                                            // {setCheck(val.tableName)}
                                            return (
                                              <option key={index}>
                                                {val.tablename}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        value={"select"}
                                        name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="input"
                                        // name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <br />
                      <div className=" submit_div">
                        {result[0] == undefined ? (
                          <></>
                        ) : (
                          <button
                            className="submit_btn"
                            onClick={handleOnUpdate}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      <div>{response}</div>
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              {/* Table Query */}
              <div className="steps">
                <br />
                <div>
                  {operationQuery === true ? (
                    <>
                      <table id="tables">
                        <thead>
                          <tr>
                            <th>Database Table Name</th>
                            <th>Database Column Name</th>
                            <th>Where</th>
                            <th>Select</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {queryData.length > 0 ? (
                            <>
                              {queryData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{data.table}</td>
                                    <td>{data.column}</td>
                                    <td>
                                      <input
                                        // className="update_radio"
                                        type="radio"
                                        value={"where"}
                                        name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        value={"select"}
                                        name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="input"
                                        // name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <br />
                      <div className=" submit_div">
                        {result[0] == undefined ? (
                          <></>
                        ) : (
                          <button
                            className="submit_btn"
                            onClick={handleOnUpdate}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      <div>{response}</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      }
    </div>
  );
};

export default StockUpdate;
