import {
  GET_ALL_CUSTOMER_VENDOR_ERROR,
  GET_ALL_CUSTOMER_VENDOR_SUCCESS,
  GET_ALL_CUSTOMER_VENDOR_REQUEST,
  ADD_CUSTOMER_VENDOR_REQUEST,
  ADD_CUSTOMER_VENDOR_SUCCES,
  ADD_CUSTOMER_VENDOR_ERROR,
  DELETE_CUSTOMER_VENDOR_REQUEST,
  DELETE_CUSTOMER_VENDOR_SUCCESS,
  DELETE_CUSTOMER_VENDOR_ERROR,
  UPDATE_CUSTOMER_VENDOR_REQUEST,
  UPDATE_CUSTOMER_VENDOR_SUCCESS,
  UPDATE_CUSTOMER_VENDOR_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const loadCustomerVendor = (token, customerCode, regionID, customerTypeID, salePersonID, paymentTerm, customerName, shippingMethodID, freightTerm, shippingAccount, resalecertificate, orderStatusID, email, billAddress, apfirstname, aplastname, active, ishold, customerid) => (dispatch) => {
  dispatch({ type: GET_ALL_CUSTOMER_VENDOR_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/Customervendor`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      //
      // p_resale_certificate_no: resalecertificate ? resalecertificate : null,
      // p_customer_code: customerCode ? customerCode : null,
      // p_region_id: regionID === "All" ? null : regionID,
      customer_name: customerName ? customerName : null,
      // p_saleperson_id: salePersonID === "All" ? null : salePersonID,
      // p_customer_type_id: customerTypeID === "All" ? null : customerTypeID,
      // payment_term_id: paymentTerm === "All" ? null : paymentTerm,
      // p_shipping_method: null,
      // p_freight_term_id: freightTerm ? freightTerm : null,
      // p_shipping_account_no: shippingAccount ? shippingAccount : null,
      // p_resale_certificate_no: null,
      // p_order_status_id: null,
      // p_email_address: email ? email : null,
      // p_default_bill_address: billAddress ? billAddress : null,
      // p_first_name: apfirstname ? apfirstname : null,
      // p_last_name: aplastname ? aplastname : null,
      // p_active: active ? active : null,
      // p_is_hold: ishold ? ishold : null,
      // p_customer_id: customerid ? customerid : null
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CUSTOMER_VENDOR_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CUSTOMER_VENDOR_ERROR,
        payload: [],
      });
    });
};

// export const getOneCustomer = (token, id) => dispatch => {
//   dispatch({ type: GET_ONE_CUSTOMER_REQUEST });
//   return axios({
//     method: "GET",
//     url: `${BASEURL}getOne/customer/${id}`,
//     headers: {
//       Accept: "application/json",
//       'Authorization': 'Bearer ' + token
//     }
//   })
//     .then(response => {
//       return dispatch({ type: GET_ONE_CUSTOMER_SUCCESS, payload: response.data.data[0] });
//     })
//     .catch((error) => {
//       return dispatch({
//         type: GET_ONE_CUSTOMER_ERROR,
//         payload: error.response,
//       });
//     })
// }

// export const addCustomer = (token, data, address) => (dispatch) => {
//   dispatch({ type: ADD_CUSTOMER_VENDOR_REQUEST });
//   return axios({
//     method: "POST",
//     url: `${BASEURL}create/customer`,
//     headers: {
//       Accept: "application/json",
//       Authorization: "Bearer " + token,
//     },

//     data: {
//       // is_deleted: 0,
//       is_active: true,
//       company_id: data.company,
//       customer_name: data.customerName,
//       customer_type_id: data.customerType,
//       website: data.webAddress,
//       remarks: data.comments,
//       first_name: data.firstName,
//       last_name: data.lastName,
//       email_address: data.email,
//       alternate_email_address: data.otherEmails,
//       phone_no: data.phone,
//       fax_no: data.fax,
//       order_status_id: data.orderStatus,
//       old_customer_code: data.oldID,
//       resale_certificate_no: data.rcn,
//       resale_certificate_date: data.expiryDate,
//       shipping_method_id: data.shippingMethod,
//       freight_term_id: data.freightTerm,
//       bergan_billing_id: data.bergenBilling,
//       courier_service_id: data.shipVia,
//       shipping_account_no: data.accountNo,
//       alcohol_recipient_type: data.alcoholType,
//       is_hold: data.isApproved,
//       is_ship_complete: data.isComplete,
//       federal_tax_id: data.fTaxID,
//       term_id: data.paymentTerm,
//       address
//     },
//   })
//     .then((response) => {

//       return dispatch({ type: ADD_CUSTOMER_VENDOR_SUCCES, payload: response.data })
//     })

//     .catch((error) => {
//       return dispatch({
//         type: ADD_CUSTOMER_VENDOR_ERROR,
//         payload: error.response,
//       });
//     });
// };

// export const deleteCustomer = (token, id) => (dispatch) => {
//   dispatch({ type: DELETE_CUSTOMER_VENDOR_REQUEST });
//   return axios({
//     method: "DELETE",
//     url: `${BASEURL}delete/customer/${id}`,
//     headers: {
//       Accept: "application/json",
//       Authorization: "Bearer " + token,
//     },
//   })
//     .then((response) => {

//       return dispatch({
//         type: DELETE_CUSTOMER_VENDOR_SUCCESS,
//         payload: response.data,
//       });
//     })
//     .catch((error) => {
//       return dispatch({
//         type: DELETE_CUSTOMER_VENDOR_ERROR,
//         payload: error.response,
//       });
//     });
// };

// export const updateCustomer = (token, data, customerAllIdd) => (dispatch) => {
//   dispatch({ type: UPDATE_CUSTOMER_VENDOR_REQUEST });
//   return axios({
//     method: "PUT",
//     url: `${BASEURL}update/customer/${customerAllIdd}`,
//     headers: {
//       Accept: "application/json",
//       Authorization: "Bearer " + token,
//     },
//     data: {
//       is_deleted: 0,
//       active: true,
//       company_id: data.company,
//       customer_name: data.customerName,
//       customer_type_id: data.customerType,
//       website: data.webAddress,
//       remarks: data.comments,
//       first_name: data.firstName,
//       last_name: data.lastName,
//       email_address: data.email,
//       alternate_email_address: data.otherEmails,
//       phone_no: data.phone,
//       fax_no: data.fax,
//       order_status_id: data.orderStatus ? data.orderStatus : 0,
//       old_customer_code: data.oldID,
//       resale_certificate_no: data.rcn,
//       resale_certificate_date: data.expiryDate,
//       shipping_method_id: data.shippingMethod,
//       freight_term_id: data.freightTerm,
//       bergan_billing_id: data.bergenBilling,
//       courier_service_id: data.shipVia,
//       shipping_account_no: data.accountNo,
//       alcohol_recipient_type: data.alcoholType,
//       is_hold: data.isApproved,
//       is_ship_complete: data.isComplete,
//       term_id: data.paymentTerm,
//       federal_tax_id: data.fTaxID,
//       // payment_term_id: data.paymentTerm

//     },
//   })
//     .then((response) => {

//       return dispatch({
//         type: UPDATE_CUSTOMER_VENDOR_SUCCESS,
//         payload: response.data,
//       });
//     })
//     .catch((error) => {
//       return dispatch({
//         type: UPDATE_CUSTOMER_VENDOR_ERROR,
//         payload: error.response,
//       });
//     });
// };
