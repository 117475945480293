import React from 'react'
import { Card } from 'antd';
// var Barcode = require('react-barcode');
import { useSelector } from "react-redux";

const CreditMemoReportFooter = () => {
  const { salesReportOrderPrint, creditMemoReportPrint } = useSelector((state) => state);




  const headerData = creditMemoReportPrint && creditMemoReportPrint?.payload?.data[0];


  return (
    <div className='order_report_footer' 
    style={{width: "874px"}}
    >
      <div style={{display :"flex", justifyContent : "space-between"}}>
        <div className="barcode" style={{ width: "200px" }}>
        {/* <Barcode  value={headerData.note_header_no} /> */}
        </div>
    

        {/* <Card style={{ width: "310px" }}>
          <p className='footer_order_p' style={{ display: "flex", justifyContent: "center"}}>Return Address</p>
          <p className='footer_order_p'>DL1961 Premium Denim c/o Bergen Logistics <br/>
            Bergen Shippers <br />
            299 Thomas E.Dunn Memorial Highway, 
            Rutherford, NJ 07070 <br/>
            Tel: 201 854 1512 Ext.276  </p>
        </Card> */}
      </div>

    </div>

  )
}

export default CreditMemoReportFooter;