import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOneCustomer, deleteCustomer } from "../../redux/actions/customerForm";
import CrudButtons from "./components/CrudButtons";
// import BillDirectPaymentForm from "./components/Form/BillDirectPaymentForm";
import CustomerNewForm from "./components/Form/CustomerNewForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
import { getRights } from "../../redux/actions/userRights.action";

export default function CustomerNew({ token }) {
	TabTitle("Customer Information")
	const dispatch = useDispatch();
	const history = useHistory();

	const dCompanyId = ReadCookie("defaultCompany");
	const { log, getcustomer } = useSelector((state) => state);

	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");

	const [flag, setFlag] = useState(false);
	const { id } = useParams();

	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);

	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const [userRights, setUserRights] = useState({})
	const[menuId, setMenuId] = useState(33);

	const mode = queryParams.get("mode");
	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {

		dispatch(deleteCustomer(token, id)).then((res) => {
			if (res.type === "DELETE_CUSTOMER_SUCCESS") {
				notification("success", "Customer Deleted", res.payload);
				setFlag(true);
				history.push("/customer-info/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	//  ********** FETCHED RIGHTS ***********
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])



	console.log("userRights", userRights)

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneCustomer(token, id)).then((val) => {
			});
		}
	}, [id]);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Customer Information"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						{/* <PageHeader
							userRights={userRights?.payload}
							title={""}
							minimize={setMinimize}
							check={minimize}
						/> */}
						<div className="crudDiv">
							<CrudButtons
								id={id}
								userRights={userRights}
								name={"customer"}
								reset={filterReset}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								customerAllIdd={id}
								formData={getcustomer?.payload}
							/>
							<div className="mainProductContainer">
								<CustomerNewForm
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={(formState === "formView" || formState === "edit") ? getcustomer?.payload && getcustomer?.payload : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									customerAllIdd={id}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Item Transfer{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}