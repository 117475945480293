import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFiscalYear, createFiscalYear, deleteFiscalYear, updateFiscalYear } from "../../redux/actions/fiscalYear.action";
import { createFiscalYearPeriod, getOneFiscalYear, updateFiscalYearPeriod } from "../../redux/actions/fiscalYearPeriod.action";
import { getRights } from "../../redux/actions/userRights.action";
import moment from "moment";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider, DatePicker } from "antd";
import { accountingPeriod } from "../../utils/cols";
import Loader from "react-loader-spinner";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function FiscalYear({ token }) {
	TabTitle("Accounts Period")
	const menuId = ReadCookie("menuId");
	const accPeriodNameInput = useRef(null);
	const [form] = Form.useForm();
	const [fiscalYearObj, setFiscalYearObj] = useState(null);
	const dispatch = useDispatch();
	const { userRights, fiscalYear } = useSelector((state) => state);
	// const stateData = useSelector(state => state)
	const [minimize, setMinimize] = useState(false);
	const [lock, setLock] = useState(false);
	const [closed, setClosed] = useState(false);
	const [yearName, setYearName] = useState("");
	const [disable, setDisable] = useState(false);
	const [formState, setFormState] = useState("view");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [data, setData] = useState([]);

	const [updatedList, setUpdatedList] = useState([]);
	const [loading, setLoading] = useState(false);

	const accPeriodCol = [
		{ title: "Period Start Date", dataIndex: "period_start_date", key: "period_start_date" },
		{ title: "Period End Date", dataIndex: "period_end_date", key: "period_end_date" },
		{ title: "Period Name", dataIndex: "period_name", key: "period_name" },
		{ title: "Quarter No", dataIndex: "quarter_no", key: "quarter_no" },
		{
			title: () => {
				return (
					<div className="d-flex align-items-center">
						<div className="user-right-table-txt">Locked</div>
					</div>
				);
			},
			dataIndex: "ar_locked",
			key: "ar_locked",

			render: (value, record, rowIndex) => (
				<Checkbox
					disabled={formState === "view" || formState === "table"}
					// checked={record.ar_locked}
					// onChange={(e) => setLock(e.target.value)}
					checked={value}
					onChange={changingTable(rowIndex, "ar_locked", record)}
				/>
			),
		},
		{
			title: () => {
				return (
					<div className="d-flex align-items-center">
						<div>Closed</div>
					</div>
				);
			},
			dataIndex: "is_closed",
			key: "is_closed",

			render: (value, record, rowIndex) => (
				<Checkbox
					size="small"
					disabled={formState === "view" || formState === "table"}
					// onChange={(e) => setClosed(e.target.value)}
					// checked={record.is_closed}
					checked={value}
					onChange={changingTable(rowIndex, "is_closed", record)}
				/>
			),
		},
		{
			title: () => {
				return (
					<div className="d-flex align-items-center">
						<div>Adjust</div>
					</div>
				);
			},
			dataIndex: "is_adjust",
			key: "is_adjust",

			render: (value, record, rowIndex) => (
				<Checkbox size="small" disabled={formState === "view" || formState === "table"} checked={value} onChange={changingTable(rowIndex, "is_adjust", record)} />
			),
		},
		{
			title: () => {
				return (
					<div className="d-flex align-items-center">
						<div>Posting</div>
					</div>
				);
			},
			dataIndex: "is_posting",
			key: "is_posting",

			render: (value, record, rowIndex) => (
				<Checkbox size="small" disabled={formState === "view" || formState === "table"} checked={value} onChange={changingTable(rowIndex, "is_posting", record)} />
			),
		},
		{
			title: () => {
				return (
					<div className="d-flex align-items-center">
						<div>Allow Non GL Changes</div>
					</div>
				);
			},
			dataIndex: "allow_non_gl_changes",
			key: "allow_non_gl_changes",

			render: (value, record, rowIndex) => (
				<Checkbox size="small" disabled={formState === "view" || formState === "table"} checked={value} onChange={changingTable(rowIndex, "allow_non_gl_changes", record)} />
			),
		},
	];

	const changingTable = (rowIndex, columnKey, record) => (event) => {
		const newrightsColumns = [...data];
		const index = newrightsColumns.findIndex((ele) => ele.period_start_date === record.period_start_date);
		newrightsColumns[index][columnKey] = event.target.checked;
		// newrightsColumns[rowIndex][columnKey] = event.target.checked;
		setData(newrightsColumns);
		const index1 = updatedList.findIndex((ele) => ele.period_start_date === record.period_start_date);
		const ispresent = updatedList[index1];
		if (ispresent) {
			updatedList[ispresent][columnKey] = event.target.checked;
		} else {
			updatedList.push(record);
		}

	};

	useEffect(() => {
		dispatch(getFiscalYear(token, menuId));
	}, [token]);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				if (!res?.payload.can_add) {
					setFormState("table");
				}
			}
		});
	}, []);

	useEffect(() => {
		if (fiscalYearObj) {
			setFormState("view");
			form.setFieldsValue({
				id: fiscalYearObj.fiscal_year_id,
				year: fiscalYearObj.fiscal_year_name,
				// lock: fiscalYearObj.is_lock,
				// closed: fiscalYearObj.is_closed,
				startDate: moment(fiscalYearObj.year_start_date),
				endDate: moment(fiscalYearObj.year_end_date),
			});
			setLoading(true);
			dispatch(getOneFiscalYear(token, fiscalYearObj.fiscal_year_id)).then((res) => {
				if (res.type === "GET_ONE_FISCALYEAR_PERIOD_SUCCESS") {
					setData(res?.payload?.data);
				}
				if (res.type === "GET_ONE_FISCALYEAR_PERIOD_ERROR") {
					setData([]);
				}
				setLoading(false);
			});
			setYearName(fiscalYearObj.fiscal_year_name);
			setLock(fiscalYearObj.is_lock);
			setClosed(fiscalYearObj.is_closed);
			// dispatch(getOneFiscalYear(token))
		}
	}, [fiscalYearObj]);

	useEffect(() => {
		if (yearName === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [yearName]);

	const formSave = () => {
		if (formState === "add") {
			dispatch(createFiscalYear(token, { yearName, startDate, endDate, lock, closed }, menuId)).then((res) => {
				if (res.type === "ADD_FISCALYEAR_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				if (res.type === "ADD_FISCALYEAR_SUCCESS") {
					dispatch(createFiscalYearPeriod(token, data, res?.payload?.fiscal_year_id));
					notification("success", "Accounting Period Created", "Accounting Period Created Successfully!");
					form.setFieldsValue({
						year: "",
					});
					setFormState("");
					// setCheck(false)
					setYearName("");
					setFiscalYearObj(null);
					setStartDate(null);
					setData([]);
				}
				dispatch(getFiscalYear(token, menuId));
			});
		}

		if (formState === "edit") {
			const datas = {
				"fiscal_year_name": yearName,
				"year_start_date": startDate,
				"year_end_date": endDate,
				"is_lock": lock,
				"is_closed": closed
			}
			dispatch(updateFiscalYear(token, datas, fiscalYearObj.fiscal_year_id)).then((res) => {
				if (res.type === "UPDATE_FISCALYEAR_ERROR") {
					notification("error", "Update", "Something went wrong");
				}
				if (res.type === "UPDATE_FISCALYEAR_SUCCESS") {
					notification("success", "Accounting Period Updated", "Record updated Successfully");
				}
				dispatch(getFiscalYear(token, menuId));
			});

			dispatch(updateFiscalYearPeriod(token, data, fiscalYearObj.fiscal_year_id)).then((res) => {
				if (res.type === "UPDATE_FISCALYEAR_PERIOD_ERROR") {
					notification("error", "Update", "Something went wrong");
				}
				if (res.type === "UPDATE_FISCALYEAR_PERIOD_SUCCESS") {
					notification("success", "Accounting Period Updated", "Record updated Successfully");
				}
				dispatch(getFiscalYear(token, menuId));
			});
		}
	};

	const handleDelete = () => {
		if (fiscalYearObj) {
			dispatch(deleteFiscalYear(token, fiscalYearObj.fiscal_year_id)).then((res) => {
				if (res.type === "DELETE_FISCALYEAR_SUCCESS") {
					notification("success", "Account Period Deleted",
						// res?.payload?.data
						"Record deleted successfully");
				}
				if (res.type === "DELETE_FISCALYEAR_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				form.setFieldsValue({
					year: "",
					startDate: "",
					endDate: "",
				});
				// setCheck(false)
				setYearName("");
				setFiscalYearObj(null);
				setFormState("");
				setData([]);
				dispatch(getFiscalYear(token, menuId));
			});
		}
	};

	useEffect(() => {
		if (formState === "add") {
			// dispatch(getOneDefaultCompany(token, dCompanyId));
			accPeriodNameInput.current.focus();
			form.setFieldsValue({
				year: "",
				startDate: "",
				endDate: "",
			});
			setData([]);
			// setYearName("")
			// setFiscalYearObj(null)
		}
	}, [formState]);

	const filterReset = () => {
		setFormState("view");
	};

	function onDateChange(date, dateString, type) {
		if (type === "start") {
			setStartDate(`${dateString}-01`);
		}
		if (type === "end") {
			setEndDate(`${dateString}-31`);
		}
	}

	const handleGenerate = () => {
		const format = "YYYY-MM-DD";
		const start = moment(startDate, format),
			end = moment(endDate, format);
		const yearPeriod = [];
		var countQtr = 1;
		while (start.isBefore(end)) {
			yearPeriod.push({
				period_start_date: start.startOf("month" - 1).format(format),
				period_end_date: start.endOf("month").format(format),
				ar_locked: lock,
				is_closed: closed,
				is_adjust: true,
				is_posting: true,
				allow_non_gl_changes: true,
				quarter_no: countQtr,
				period_name: start.format("MMM YYYY")
			});
			start.add(1, "month");
		}

		for (let i = 1; i < yearPeriod.length; i++) {
			if (i % 3 === 0) {
				countQtr++;
			}
			yearPeriod[i].quarter_no = countQtr;
		}
		setData(yearPeriod);
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Accounts Period"} minimize={setMinimize} check={minimize} />
				{!minimize && (
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} />
						</div>

						<div className="styleContent">
							<Row className="tables-main-row-new">
								<Col xs={24} sm={24} md={10} lg={8} xl={6}>
									<div className="styleTable">
										<Tables
											type="fiscalYear"
											fiscalYearObj={setFiscalYearObj}
											scrollX={"20rem"}
											scroll={602}
											perPage={25}
											source={fiscalYear?.payload?.data}
											col={accountingPeriod}
											paginate
											load={fiscalYear.loading}
										/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={14} lg={16} xl={18}>
									<div className="styleForm">
										<Form form={form} name="basic" labelCol={{ span: 8 }}>
											<div className="customRow styleFormUpper ">
												{fiscalYearObj !== null && !(formState === "add") && (
													<div className="customCols slc width400">
														<Form.Item label="ID" name="id" initialValue={form.getFieldValue().id} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
															<Input size="small" className="styleInput id-field-style" readOnly />
														</Form.Item>
													</div>
												)}
												<Row>
													<Col xs={24} sm={24} md={14} lg={16} xl={8}>
														<div className="customCols slc width400">
															<Form.Item label="Year" name="year" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValue={form.getFieldValue().style}>
																<Input
																	size="small"
																	// placeholder="Year Name"
																	disabled={!(formState === "add" || formState === "edit")}
																	ref={accPeriodNameInput}
																	onChange={(e) => setYearName(e.target.value)}
																// className="styleInput"
																/>
															</Form.Item>
														</div>
													</Col>
													<Col xs={24} sm={24} md={14} lg={16} xl={8}>
														<div className="customCols slc width400">
															<Form.Item
																label="Year Start Date"
																name="startDate"
																labelCol={{ span: 10 }}
																wrapperCol={{ span: 14 }}
																defaultValue={form.getFieldValue().startDate}
															>
																<DatePicker
																	size="small"
																	className="w-100"
																	onChange={(e, d) => onDateChange(e, d, "start")}
																	picker="month"
																	disabled={!(formState === "add" || formState === "edit")}
																/>
															</Form.Item>
														</div>
													</Col>
													<Col xs={24} sm={24} md={14} lg={16} xl={8}>
														<div className="customCols slc width400">
															<Form.Item label="Year End Date" name="endDate" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} defaultValue={form.getFieldValue().endDate}>
																<DatePicker
																	size="small"
																	className="w-100"
																	onChange={(e, d) => onDateChange(e, d, "end")}
																	picker="month"
																	disabledDate={(d) => !d || d.isBefore(startDate)}
																	disabled={!(formState === "add" || formState === "edit")}
																/>
															</Form.Item>
														</div>
													</Col>
												</Row>
												<Row>
													<Col xs={24} sm={24} md={14} lg={16} xl={8}>
														<div className="customCols slc width400">
															<Form.Item label="Lock" name="ar_locked" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValue={form.getFieldValue().ar_locked}>
																<Checkbox disabled={!(formState === "add" || formState === "edit")} checked={lock} onChange={(e) => setLock(e.target.checked)} />
															</Form.Item>
														</div>
													</Col>
													<Col xs={24} sm={24} md={14} lg={16} xl={6}>
														<div className="customCols slc width400">
															<Form.Item label="Closed" name="closed" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
																<Checkbox disabled={!(formState === "add" || formState === "edit")} checked={closed} onChange={(e) => setClosed(e.target.checked)} />
															</Form.Item>
														</div>
													</Col>
												</Row>
											</div>
										</Form>
										<div className="modalStyle">
											<Button disabled={!yearName || !startDate || !endDate} type="primary" className="modalButton" onClick={handleGenerate}>
												Generate
											</Button>
										</div>

										<Divider />
										<div className="styleTab">
											<Tabs type="card" tabBarGutter={2} defaultActiveKey="1">
												<TabPane tab="Accounting Period" key="1">
													<div className="tabs">
														<Tables
															load={loading && <Loader type="Grid" color="#212121" height={40} width={40} />}
															type="company"
															scroll={340}
															scrollX={"25rem"}
															source={data}
															loading={data.loading}
															col={accPeriodCol}
														/>
													</div>
												</TabPane>
											</Tabs>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
