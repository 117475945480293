import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Tooltip, Popconfirm, Table, Checkbox, InputNumber } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { addBillPaymentLine, updateBillPaymentLine, getOneBillPaymentLine, deleteBillPaymentLine, getAllPendingBillCreadit, addPayablePaymentCreditTake, BillPaymentImpact } from "../../../../redux/actions/payBillPaymentLine.action";
import { addBillPayment, getOneBillPayment, updateBillPayment } from "../../../../redux/actions/billPayments.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getVendors } from "../../../../redux/actions/vendor.action";
import { getARAccounts, getCustomerPayment } from "../../../../redux/actions/AR_Account.action";
import { getPayVendorBill } from "../../../../redux/actions/payVendorBill.action";
import { getLog } from "../../../../redux/actions/log.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import Summary from "../../../../components/OrderSummary/OrderSummary";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import numeral from "numeral";
import { getAccountPayable } from "../../../../redux/actions/accountPayable.action";


export default function BillPaymentForm({ formData, val, token, formState, submit, saleOrderID, changeState, setDisable }) {
	const { Option } = Select;

	const { TabPane } = Tabs;
	const { id } = useParams();
	const dispatch = useDispatch();
	const submitButton = useRef(null);
	const [form] = Form.useForm();
	const { systemLog, itemTransferFormData, customrPayment, accountPayable, ProductSale, invoiceGlPeriod, companyAll, vendor, arAccount, billpayment, payVendorBill, billpaymentLine, glImpact } = useSelector((state) => state);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [oDate, setOrderDate] = useState(moment());
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [lineItems, setLineItems] = useState([]);
	const [pendingBillItems, setPendingBillItems] = useState([]);
	const [selectedpendingBillItems, setSelectedPendingBillItems] = useState([]);
	const [copypendingBillItemsRowKeys, setCopyPendingBillItemsRowKeys] = useState([]);
	const [selectedLineItems, setSelectedLineItems] = useState([]);
	const [CopyselectedRowKeys, setCopyselectedRowKeys] = useState([]);
	const [deletedItemsInformation, setDeletedItemsInformation] = useState([]);
	// sum of total value of payment
	const [totalVal, setTotalVal] = useState(0)
	const [flag, setFlag] = useState(true)
	const [selected, setSelected] = useState(null);
	const [HeaderID, setHeaderID] = useState(false);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [paymentAmount, setPaymentAmount] = useState(null);
	const [dueeDate, setDueDate] = useState(moment());
	const [isChecked, setIsChecked] = useState(false);
	const location = useLocation();

	const [billPaymentIds, setBillPaymentIds] = useState([]);

	const data = location.state;

	let sumVal = 0;
	for (let i = 0; i < lineItems.length; i++) {
		sumVal += parseFloat(lineItems[i].payment_amount)
	}
	console.log(selectedpendingBillItems, "selectedpendingBillItems")
	let sumVal2 = 0;
	for (let i = 0; i < selectedLineItems.length; i++) {
		sumVal2 += parseFloat(selectedLineItems[i].payment_amount)
	}

	let selectedCreaditTakeSum = selectedpendingBillItems.reduce((accum, value) => {
		return accum + parseFloat(value.creadit_amount)
	}, 0);


	// useEffect(() => {
	// 	dispatch(getOneInvoiceGlPeriod(token));
	// 	invoiceGlPeriod?.payload?.map((item) => {
	// 		return (
	// 			resultArr.push(item)
	// 		);
	// 	})
	// 	let result = resultArr.filter((item) => {
	// 		return item.period_name == currentDate
	// 	})

	// 	setCurrentDate(result[0]?.period_id)
	// 	setTest(result[0]?.period_id)

	// }, []);
	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);

	useEffect(() => {
		dispatch(getAllCompany(token));
		dispatch(getVendors(token));
		dispatch(getARAccounts(token));
		dispatch(getAccountPayable(token));
		dispatch(getCustomerPayment(token));

	}, []);

	useEffect(() => {
		dispatch(getLog(token, { pay_bill_payment_header: "pay_bill_payment_header" }));
	}, [token]);

	useEffect(() => {
		if (data?.state && formState === "add") {
			form.setFieldsValue({
				referenceNo: null,
				paymentdate: oDate,
				// apAccount: null,
				payee: data?.state[0]?.vendor_id,
				postingPeriod: currentDate,
				memo: null,
				checkNo: null,
				amount: null,
				subsidiary: 3,
				account: null,
				dueDate: dueeDate,
			});
			setOrderItems([]);
			handleChange(data?.state[0]?.vendor_id, "payee");
		}
		else if (formState === "add") {
			form.setFieldsValue({
				referenceNo: null,
				paymentdate: oDate,
				// apAccount: null,
				payee: null,
				postingPeriod: currentDate,
				memo: null,
				checkNo: null,
				amount: null,
				subsidiary: 3,
				account: null,
				dueDate: dueeDate,
			});
			setOrderItems([]);
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneBillPaymentLine(token, id)).then((res) => {
				setSelected(res?.payload?.data[0]?.is_selected);
				if (res.type === "GET_ONE_BILL_PAYMENT_LINE_SUCCESS") {
					setLineItems(res.payload.data);
					setLoading(false)

				}
			});
			dispatch(getGLImpact(token, { "transaction_header_id": id, "transaction_type": "BILLPAY" }));
			dispatch(getSystemLog(token, { table_name: "pay_bill_payment_header", record_id: saleOrderID }));
		}
	}, [formState, billpayment]);

	useEffect(() => {
		if (formData && formState) {
			setHeaderID(formData?.payment_header_no)
			form.setFieldsValue({

				referenceNo: formData[0]?.payment_header_no,
				paymentdate: moment(formData[0]?.payment_date),
				account: formData[0]?.account_id,
				payee: formData[0]?.vendor_id,
				// apAccount: formData[0]?.ap_account_id,
				subsidiary: formData[0]?.company_id,
				postingPeriod: formData[0]?.gl_period_id,
				memo: formData[0]?.memo,
				checkNo: formData[0]?.check_no,
				amount: formState === "formView" ? formData[0]?.payment_amount : sumVal,
				dueDate: moment(formData?.due_date),
			});
			setPaymentAmount(formData[0]?.payment_amount)
		}
	}, [formData, formState, sumVal]);

	// useEffect(() => {
	// 	if(formData) {
	// 		form.setFieldsValue({
	// 			amount: formState === "formView" ? formData[0]?.payment_amount : 10,
	// 		});
	// 	}
	// }, [formState])

	console.log("formState", formState)

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	const handleTab = (key) => {
		if (key === "header") {
			dispatch(getSystemLog(token, { table_name: "pay_bill_payment_header", record_id: saleOrderID }));
		} else if (key === "line") {
			dispatch(getSystemLog(token, { table_name: "pay_bill_payment_lines", record_id: saleOrderID }));
		}
	};
	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
		else if (type === "payee") {
			dispatch(getPayVendorBill(token, value)).then((res) => {
				setLineItems(res.payload.data)
			});
			dispatch(getAllPendingBillCreadit(token, value)).then((res) => {
				setPendingBillItems(res.payload.data)
			});

		}


	}

	function onChange(e) {
		setIsChecked(e.target.checked);
	}

	const onFinish = async (e) => {
		// if (selectedLineItems.length > 0) {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			setDisable(true);
			if (formState === "add") {


				if (selectedLineItems.length > 0) {
					dispatch(addBillPayment(token, values, sumVal2 ? sumVal2 : sumVal, parseInt(companyId))).then((res) => {
						let HeaderId = res?.payload?.payment_header_id;
						setHeaderID(HeaderId)
						if (res.type === "ADD_BILL_PAYMENT_SUCCESS") {
							dispatch(addBillPaymentLine(token, selectedLineItems.map((v) =>
							(
								delete v.bill_header_no,
								delete v.bill_date,
								delete v.vendor_id,
								delete v.bill_ref_no,
								{
									...v,
									bill_header_id: v.bill_header_id,
									payment_header_id: res.payload.payment_header_id,
									// payment_header_id: 10002,
									is_selected: v.is_selected,
									disc: v.disc,
									payment_amount: v.payment_amount,

								})))
							).then((res) => {
								if (res.type === "ADD_BILL_PAYMENT_LINE_SUCCESS") {
									dispatch(addPayablePaymentCreditTake(token, selectedpendingBillItems.map((v) =>
									(
										delete v.account_id,
										delete v.bill_date,
										delete v.bill_credit_header_no,
										delete v.creadit_amount,
										delete v.gl_period_id,
										delete v.is_selected,
										delete v.reference_no,
										delete v.remarks,
										{
											...v,
											// payment_header_id: 12345,
											payment_header_id: HeaderId,
											// is_selected: v.is_selected,
											// disc: v.disc,
											// payment_amount: v.payment_amount,

										})))).then((res) => {
											dispatch(BillPaymentImpact(token, HeaderId))
										})

									notification("success", "Bill Payment", "Bill Payment created successfully!");
									// notification("success", "Bill Payment Created", res?.payload?.data);
									setLoading(false);
									setDisable(false);

									history.push({
										pathname: `/bill-payments/edit/${HeaderId}`,
										search: `?_id=${HeaderId}`
									})
								} else if (res.type === "ADD_BILL_PAYMENT_ERROR") {
									setLoading(false);
									setDisable(false);
									Swal.fire({
										icon: "error",
										title: "Oops...",
										text: res?.payload?.data?.data,
									});
								}
							});
						} else if (res.type === "ADD_BILL_PAYMENT_ERROR") {
							setLoading(false);
							setDisable(false);
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.data,
							});
						}
						dispatch(getOneBillPayment(token, HeaderId));
						dispatch(getOneBillPaymentLine(token, HeaderId));
					})
				}
				else {
					setLoading(false);
					setDisable(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "select atleast one Bill line",
					});
					return
				}

			} else if (formState === "edit") {
				dispatch(deleteBillPaymentLine(token, saleOrderID, deletedItemsInformation));
				let rest = lineItems.map(({ id, ...rest }) => {
					return {
						...rest,

					}
				});
				const oldLines = rest.filter((value) => {
					delete value.full_name;
					delete value.account_no;
					delete value.bill_ref_no;
					if (value.payment_line_id) {
						return value
					}
				})
				console.log(oldLines, "oldLinesoldLines");
				dispatch(updateBillPayment(token, values, saleOrderID)).then((res) => {
					if (res.type === "UPDATE_BILL_PAYMENT_SUCCESS") {
						notification("success", "Bill Payment Updated");
						dispatch(updateBillPaymentLine(token, oldLines, saleOrderID)).then((res) => {
							if (res.type === "UPDATE_BILL_PAYMENT_LINE_SUCCESS") {
								setLoading(false);
								setDisable(false);
								changeState("formView")
							}
						});
					} else if (res.type === "UPDATE_BILL_PAYMENT_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	}

	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		} else {
			let uniqueOrderItems = [...visibleItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
		setDisable(false);
	};

	useEffect(() => {
		let filtered = lineItems.filter((f) => f.isSelected === true);
		setVisibleItems(filtered);
	}, [lineItems]);


	console.log("billPaymentIds==>>", billPaymentIds)
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {


			let duppary = [...lineItems];

			let items = duppary.filter((v) => {
				setBillPaymentIds(selectedRowKeys)
				if (selectedRowKeys.includes(v.bill_header_id)) {
					// console.log("if",v);
					if (!billPaymentIds.includes(v.bill_header_id)) {
						v.is_selected = true;
						v.payment_amount = v.remaining_amount;
					}
				} else {
					// console.log("else");
					// let deleteItem = billPaymentIds.filter(x => x !== v.bill_header_id);
					// console.log("v.bill_header_id", v.bill_header_id,"asd",deleteItem)
					// console.log("deleteItem", deleteItem)
					// setBillPaymentIds(deleteItem);
					v.is_selected = false;
					v.payment_amount = 0;
				}
				return v;
			});
			setSelectedLineItems(items);
			setLineItems(items);
			console.log("lineItems 423", lineItems)
			console.log("lineItems billPaymentIds", billPaymentIds)

			// let rownum = selectedRowKeys.length - 1

			// let duparry = [...lineItems]

			// let getItem;
			// let filteredKeywords = CopyselectedRowKeys.filter((v) => !selectedRowKeys.includes(v));
			// if (filteredKeywords.length > 0) {
			// 	getItem = duparry.findIndex((val) => val.bill_header_id === filteredKeywords[0])

			// 	duparry[getItem].payment_amount = 0;
			// 	// alert('Running')
			// 	setSelectedLineItems([]);
			// }
			// else {
			// 	getItem = duparry.findIndex((val) => val.bill_header_id === selectedRowKeys[rownum])

			// 	duparry[getItem].payment_amount = duparry[getItem].remaining_amount;

			// 	duparry[getItem].is_selected = true;
			// 	console.log("lineItems", duparry)
			// 	setLineItems(duparry);
			// }
			// setSelectedLineItems(selectedRows);
			// setCopyselectedRowKeys([...selectedRowKeys]);
		},
	};


	const handleDeleteOrder = (record) => {
		const dataSource = [...lineItems];
		const deleteSource = [...deletedItemsInformation];
		console.log(record, 'record');
		deleteSource.push(record);
		// 		deletedItemsInformation
		setDeletedItemsInformation(deleteSource);
		// if (formState !== "add" && record.payment_line_id) {
		// 	dispatch(deleteBillPaymentLine(token, record.payment_line_id)).then((res) => { });
		// }
		if (dataSource.length > 0) {
			setLineItems(dataSource.filter((item) => item.payment_line_id !== record.payment_line_id));
			setSelectedLineItems(dataSource.filter((item) => item.payment_line_id !== record.payment_line_id))
		} else {
			const dataSource = [...visibleItems];
			setLineItems(dataSource.filter((item) => item.payment_line_id !== record.payment_line_id));
			setSelectedLineItems(dataSource.filter((item) => item.payment_line_id !== record.payment_line_id))
		}
		console.log(sumVal, 'sumVal');
		sumVal -= parseFloat(record?.payment_amount)
		console.log(sumVal, 'sumVal');
	};



	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}
	const updateAmount = (payment_line_id, value) => {

		let data = [...lineItems];
		const filter = data.map((item) => {
			if (item.payment_line_id === payment_line_id || item.bill_header_id === payment_line_id) {
				item.payment_amount = parseFloat(item.remaining_amount - value).toFixed(2);
				item.disc = value;
			}
			return item;
		}
		);
		setLineItems(filter);
	};

	const columns = [
		{
			title: "Bill Header No",
			dataIndex: "bill_header_no",
			key: "bill_header_no"
		},
		{
			title: "Bill Reference Number",
			dataIndex: "bill_ref_no",
			key: "bill_ref_no"
		},
		{
			title: "Bill Date",
			dataIndex: "bill_date",
			key: "bill_date",
			render: (text, record) => (
				<>
					{moment(text).format('MM-DD-YYYY')}
				</>
			),
		},
		{
			title: "Orignal Amount",
			dataIndex: "original_amount",
		},
		{
			title: "Remaining Amount",
			dataIndex: "remaining_amount",
		},
		{
			title: "Discount Taken",
			dataIndex: "disc",
			render: (text, record) => {
				return <>
					<InputNumber
						onChange={(value) => updateAmount(record.bill_header_id ? record.bill_header_id : record.payment_line_id, value)}
						size="small"
						value={record.disc}
					/>
				</>
			}
		},
		{
			title: "Payment Amount",
			dataIndex: "payment_amount",
			render: (text, record, index) => {
				console.log(selectedLineItems, "orderItemsorderItems")
				return <>
					<InputNumber
						size="small"
						disabled={!isChecked}
						value={record.is_selected ? record.payment_amount : 0}
						onChange={(value) => handlePayment(value, record, lineItems.findIndex((v) => v.bill_header_id === record.bill_header_id))}
					/>
				</>
			}
		},
		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record) => (
				<Tooltip title="Delete" color={"white"}>
					{formState === "formView" ? "" :
						<Popconfirm title={`Are you sure to delete ${record.payment_line_id}?`}
							onConfirm={() => handleDeleteOrder(record)}
						>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					}
				</Tooltip>
			),
		},
	];


	const changeCreaditAmount = (value, index) => {
		let copyArr = [...pendingBillItems];
		if (value >= copyArr[index].amount) {
			copyArr[index].creadit_amount = copyArr[index].amount;
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Remaining Amount Should be Less Than Amount",
			});
		} else {
			copyArr[index].creadit_amount = value;
		}
		setPendingBillItems(copyArr);
	}


	console.log("billPaymentIds", billPaymentIds)
	const handlePayment = (value, record, index) => {
		console.log("record 626==>>", record);
		if (!billPaymentIds.includes(record.bill_header_id)) {
			setBillPaymentIds([...billPaymentIds, record.bill_header_id]);
		}
		// if (formState === "add") {
		// 	let copyArr = [...lineItems];
		// 	copyArr[index].payment_amount = value;
		// 	setLineItems(copyArr);
		// } else if (formState === "edit") {
		let copyArr = [...lineItems];
		copyArr[index].payment_amount = value.toString();
		setLineItems(copyArr);
		// }

	};
	const pendingBillcolumns = [
		{
			title: "Bill Creadit Header No",
			dataIndex: "bill_credit_header_no",
			key: "bill_credit_header_no"
		},
		{
			title: "Bill Date",
			dataIndex: "bill_date",
			key: "bill_date",
			render: (text, record) => (
				<>
					{moment(text).format('MM-DD-YYYY')}
				</>
			),
		},
		{
			title: "Remaining",
			dataIndex: "amount",
			render: (text, record) => (
				<>
					{numeral(record.amount).format('00.00')}
				</>
			),
		},
		{
			title: "Amount",
			dataIndex: "creadit_amount",
			render: (text, record, index) => (
				<>
					<InputNumber onChange={(e) => changeCreaditAmount(e, index)} value={record.creadit_amount || 0} type="number" placeholder="Amount" />
				</>
			),
		}
	];

	const pendingrowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			let rownum = selectedRowKeys.length - 1

			let duparry = [...pendingBillItems]

			let getItem;
			let filteredKeywords = copypendingBillItemsRowKeys.filter((v) => !selectedRowKeys.includes(v));
			if (filteredKeywords.length > 0) {
				getItem = duparry.findIndex((val) => val.bill_credit_header_id === filteredKeywords[0])

				duparry[getItem].creadit_amount = 0
				// setPendingBillItems([]);
			}
			else {
				getItem = duparry.findIndex((val) => val.bill_credit_header_id === selectedRowKeys[rownum])

				duparry[getItem].creadit_amount = duparry[getItem].amount

				duparry[getItem].is_selected = true;
				setPendingBillItems(duparry);
			}
			setSelectedPendingBillItems(selectedRows);
			setCopyPendingBillItemsRowKeys([...selectedRowKeys])

		},
	}

	return (
		<>
			<EditableTable
				token={token}
				companyId={companyId}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				lineItems={lineItems}
				setShowGrid={setShowGrid}
			/>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{itemTransferFormData.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="referenceNo"
												label="Bill Header #"
												labelAlign="left"
												labelCol={{ span: 6 }}
												defaultValue={form.getFieldValue().referenceNo}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={true} />
											</Form.Item>
										</div>
									</Bcol>
									{/* <Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												label="Payment Date"
												name="paymentdate"
												labelAlign="left"
												labelCol={{ span: 6 }}
											// rules={[{ required: true, message: "Payment Date is required" }]}
											>
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={
														formState === "edit"
															? form.getFieldValue().paymentdate
															: moment(oDate)
													}
													format="MM-DD-YYYY"
													// placeholder="Order Date"
													allowClear
													className="dateInput customInputs"
													onChange={(e) => setOrderDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol> */}

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Payment Date" name="paymentdate" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Bill Date is required" }]}>
												{/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Bill Date"
													allowClear
													className="dateInput customInputs"
												/> */}
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().paymentdate
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id })
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									{/* <Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="A/P ACCOUNT" name="apAccount" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().apAccount} rules={[{ required: true, message: "A/P is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													disabled={!(formState === "add" || formState === "edit")}
													loading={accountPayable.loading}
													defaultValue={form.getFieldValue().apAccount}
													// placeholder="A/P ACCOUNT"
													onChange={(value) => {
														form.setFieldsValue({
															apAccount: value,
														});
													}}
												>
													{accountPayable?.payload?.data.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol> */}



									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().postingPeriod} rules={[{ required: true, message: "PostingPeriod is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													// placeholder="Select Gl Period"
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="payee" label="PAYEE" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Payee is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													showSearch
													// allowClear
													loading={vendor.loading}
													disabled={!(formState === "add")}
													defaultValue={form.getFieldValue().payee}
													// placeholder="Payee"
													onChange={(value) => handleChange(value, "payee")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												// onSelect={(value) => showVendorData(value)}
												>
													{vendor?.payload?.data.map((item) => {
														return (
															<Option key={item.vendor_id} value={item.vendor_id}>
																{item.vendor_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="ACCOUNT" name="account" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Account is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													disabled={!(formState === "add" || formState === "edit")}
													loading={customrPayment.loading}
													defaultValue={form.getFieldValue().account}
													// placeholder="Select Account"
													onChange={(value) => {
														form.setFieldsValue({
															account: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customrPayment?.payload?.data.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 6 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										{/*  */}
										{/*  */}
										{/* 
										// 
										// 
										// 
										// 
										// 
										// 
										// 
										//  */}
										<div className="firstSectionRo">
											<Form.Item name='amount' label="Amount" labelAlign="left" labelCol={{ span: 6 }}>
												<Input tabIndex={1} placeholder={numeral(sumVal2).format("0.00")} size="small" className="customInputs"
													disabled={true}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Due Date" name="dueDate" labelAlign="left" labelCol={{ span: 6 }}
											//  rules={[{ required: true, message: "Due Date is required" }]}
											>

												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().dueDate
															: moment(oDate)
													}
													onChange={(e) => setDueDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="checkNo" label="Check No" labelAlign="left" labelCol={{ span: 6 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>

								</Brow>

								<Checkbox onChange={onChange}>Checkbox</Checkbox>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Payment Summary</div>

										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Bill Applied:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${sumVal2}</p>

											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Bill Creadit:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${selectedCreaditTakeSum}</p>
											</Bcol>
										</Brow>


									</div>
								</div>
								{/* <Summary orderItems={orderItems.length > 0 ? orderItems : visibleItems} shippingCost={form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0} /> */}
							</Bcol>
						</Brow>

						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
				</div>
				<SubTitles name={"Classification"} />
				<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
					<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
						<Brow>
							<Bcol lg={6} xxl={4}>
								<div className="firstSectionRo">
									<Form.Item
										name="subsidiary"
										label="Subsidiary"
										labelAlign="left"
										labelCol={{ span: 6 }}
										rules={[{ required: true, message: "Subsidiary is required" }]}
									>
										<Select
											size="small"
											autoComplete={"nope"}
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											showSearch
											allowClear
											loading={companyAll.loading}
											disabled={!(formState === "add" || formState === "edit")}
											defaultValue={form.getFieldValue().subsidiary}
											// placeholder="Select Subsidiary"
											onChange={(value) => handleChange(value)}
										>
											{companyAll?.payload?.data.map((item) => {
												return (
													<Option key={item.company_id} value={item.company_id}>
														{item.company_name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</div>
							</Bcol>

						</Brow>
					</Bcol>
				</div>
				<Tabs
					type="card"
					tabBarGutter={2}
					defaultActiveKey="1"
					centered
					onChange={(key) => handleTab(key)}
				>
					<TabPane tab="Bill Payment" key="1">
						<Tabs
							type="card"
							tabBarGutter={2}
							defaultActiveKey="1"
							centered
						>

							<TabPane tab="Bill Payment" key="1">
								{
									payVendorBill.loading ?
										<div className="tabs">loading</div> :
										<div className="tabs">
											{/* {(lineItems.length > 0 || visibleItems.length > 0) && ( */}
											{(lineItems.length > 0) && (
												<Table
													loading={lineItems.loading}
													scroll={({ x: 600 }, { y: 400 })}
													size="small"
													bordered
													dataSource={lineItems}
													columns={columns}
													rowKey="bill_header_id"
													rowSelection={{
														...rowSelection,
													}}
													pagination={{ pageSize: 100 }}

												/>
											)}
										</div>
								}
							</TabPane>
							<TabPane tab="Creadit Take" key="2">
								{
									payVendorBill.loading ?
										<div className="tabs">loading</div> :
										<div className="tabs">
											{(pendingBillItems.length > 0) && (
												<Table
													loading={lineItems.loading}
													scroll={({ x: 600 }, { y: 400 })}
													size="small"
													bordered
													dataSource={pendingBillItems}
													columns={pendingBillcolumns}
													rowKey="bill_credit_header_id"
													rowSelection={{
														...pendingrowSelection,
													}}
												// pagination={{ pageSize: 100 }}

												/>
											)}
										</div>
								}
							</TabPane>
						</Tabs>


					</TabPane>

					{(formState === "edit" || formState === "formView") &&
						<TabPane tab="GL Impact" key="gl_impact">
							<div className="tabs">
								<Tables
									type="company"
									scrollX={"50rem"}
									scroll={360}
									perPage={15}
									source={glImpact?.payload?.data}
									col={glImpcatCols}
									load={glImpact.loading}
									paginate
								/>
							</div>
						</TabPane>}


					{(formState === "edit" || formState === "formView") &&
						<TabPane tab="System Information" key="logs" >
							<Tabs
								type="card"
								size="small"
								defaultActiveKey="1"
								centered
								onChange={(key) => handleTab(key)}
							>
								<TabPane tab="Header" key="header">
									<div className="tabs">
										{systemLog?.payload?.data?.length > 0 && <Tables
											type="company"
											scrollX={"50rem"}
											scroll={360}
											perPage={15}
											source={systemLog?.payload?.data}
											dataSource={[]}
											col={renderColumns()}
											load={systemLog.loading}
											paginate
										/>}
									</div>
								</TabPane>
								<TabPane tab="Line" key="line">
									<div className="tabs">
										{systemLog?.payload?.data?.length > 0 && <Tables
											type="company"
											scrollX={"50rem"}
											scroll={360}
											perPage={15}
											source={systemLog?.payload?.data}
											dataSource={[]}
											col={renderColumns()}
											load={systemLog.loading}
											paginate
										/>}
									</div>
								</TabPane>
							</Tabs>
						</TabPane>}
				</Tabs>

			</Form>

		</>
	);
}