import {
	GET_ALL_SCM_BRAND_ERROR,
	GET_ALL_SCM_BRAND_SUCCESS,
	GET_ALL_SCM_BRAND_REQUEST,
	// GET_SCM_BRAND_REQUEST,
	// GET_SCM_BRAND_ERROR,
	// GET_SCM_BRAND_SUCCESS,
	ADD_SCM_BRAND_REQUEST,
	ADD_SCM_BRAND_ERROR,
	ADD_SCM_BRAND_SUCCESS,
	UPDATE_SCM_BRAND_REQUEST,
	UPDATE_SCM_BRAND_ERROR,
	UPDATE_SCM_BRAND_SUCCESS,
	DELETE_SCM_BRAND_REQUEST,
	DELETE_SCM_BRAND_ERROR,
	DELETE_SCM_BRAND_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";


const axios = require("axios");

export const getAllScmBrand = (token, id) => (dispatch) => {
	dispatch({ type: GET_ALL_SCM_BRAND_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/scmBrand`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { id },
	})
		.then((response) => {
			// 
			return dispatch({ type: GET_ALL_SCM_BRAND_SUCCESS, payload: response?.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_SCM_BRAND_ERROR,
				payload: error.response,
			});
		});
};

// export const getOneScmBrand = (token, id) => (dispatch) => {
// 	dispatch({ type: GET_SCM_BRAND_REQUEST });
// 	return axios({
// 		method: "GET",
// 		url: `${BASEURL}getOne/scmBrand/${id}`,	
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 		data: { id },
// 	})
// 		.then((response) => {
// 			return dispatch({ type: GET_SCM_BRAND_SUCCESS, payload: response.data });
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_SCM_BRAND_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

export const deleteScmBrand = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_SCM_BRAND_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/scmBrand/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { id },
	})
		.then((response) => {

			return dispatch({ type: DELETE_SCM_BRAND_SUCCESS, payload: response?.data?.data });

		})
		.catch((error) => {
			return dispatch({
				type: DELETE_SCM_BRAND_ERROR,
				payload: error.response,
			});
		});
};

export const addScmBrand = (token, data) => (dispatch) => {
	dispatch({ type: ADD_SCM_BRAND_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/scmBrand`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { brand_name: data.scmBrandTypeName, is_active: data.check },
	})
		.then((response) => {

			return dispatch({ type: ADD_SCM_BRAND_SUCCESS, payload: response?.data?.data });
		})
		.catch((error) => {
			// 

			return dispatch({
				type: ADD_SCM_BRAND_ERROR,
				payload: error.response,
			});
		});
};

export const updateScmBrand = (token, data, id) => (dispatch) => {

	dispatch({ type: UPDATE_SCM_BRAND_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/scmBrand/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			brand_name: data.scmBrandTypeName,
			is_active: data.check,
			brand_id: id
		},
	})
		.then((response) => {

			return dispatch({ type: UPDATE_SCM_BRAND_SUCCESS, payload: response?.data?.data });
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_SCM_BRAND_ERROR,
				payload: error.response?.data,
			});
		});
};

