import {
    GET_TRANSACTION_NUMBER_REQUEST,
    GET_TRANSACTION_NUMBER_SUCCESS,
    GET_TRANSACTION_NUMBER_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const getTransactionNo = (token, dTransactionNo) => (dispatch) => {
    console.log(dTransactionNo,"dTransactionNodTransactionNodTransactionNodTransactionNo")
    dispatch({ type: GET_TRANSACTION_NUMBER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getTransactionNo`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {transaction_no:dTransactionNo},
    })
        .then((response) => {
            return dispatch({
                type: GET_TRANSACTION_NUMBER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_TRANSACTION_NUMBER_ERROR,
                payload: [],
            });
            //   if (error.response.data.data === "No Record Found !") {
            //     return dispatch({
            //       type: GET_BUSSINESS_DASHBOARD_ERROR,
            //       payload: [],
            //     });
            //   }
            //   return dispatch({
            //     type: GET_REGION_ERROR,
            //     payload: error.response,
            //   });
        });
};
