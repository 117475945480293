import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getWashName } from "../../redux/actions/wash.action";
import { Button, Form, Input, Select, Radio, Checkbox, DatePicker, Spin } from 'antd';
import { getStyle } from '../../redux/actions/style';
import { ReadCookie } from "../../utils/readCookie";
import { CloseCircleOutlined } from '@ant-design/icons';
import { getGenderCategory } from '../../redux/actions/genderCategory.action';
import { getFabric } from '../../redux/actions/fabric.action';
import { getGender } from '../../redux/actions/gender.action';
import { getFitCategory } from '../../redux/actions/fitCategory.action';
import { getSeason } from '../../redux/actions/season.action';
import { useHistory } from "react-router-dom";
import "./productList.css";
import { Row as Brow, Col as Bcol } from "reactstrap";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { getReportIP } from '../../redux/actions/reportIP.action';
import { TabTitle } from '../../utils/GeneralFunction';
import { getSeasonYear } from '../../redux/actions/seasonYear.action';
import { getSku } from '../../redux/actions/sku.action';
import { getProductStatus } from '../../redux/actions/productStatus.actions';
import moment from 'moment';
import { getOmsStyleReport } from '../../redux/actions/omsStyleReport.action';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import numeral from 'numeral';
import jsPDF from 'jspdf';

const OmsStyleReportNew = ({ token }) => {
    TabTitle("OMS Style Report")
    const { style, wash, fabric, gender, fitCategory, season, genderCategory, seasonYear, Sku, productStatus, omsStyle } = useSelector(state => state)
    // const [styleID, setStyleID] = useState('All')
    // const [washID, setWashID] = useState('All')
    // const [date, setDate] = useState(new Date)
    // const [fabricID, setFabricID] = useState('All')
    // const [seasonID, setSeasonID] = useState('All')
    // const [genderID, setGenderID] = useState('All')
    // const [genderCatID, setGenCatID] = useState('All')
    // const [fitCatID, setFitCatID] = useState('All')
    const [active, setActive] = useState(true)
    const [status, setStatus] = useState(1)
    const [allStatus, setAllStatus] = useState(true);
    const [isChecked2, setIsChecked2] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [isPCChecked, setIsPCChecked] = useState([]);
    const [BgradeCheck, setBgradeCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [excelData, setExcelData] = useState([])
    // const [allFilters, setAllFilters] = useState([
    //     "Style",
    //     "Bergen",
    //     "Transit",
    //     "PK",
    //     "Production",
    //     "Pending",
    //     "INT Pending",
    //     "OTS",
    // ]);
    const [filters, setFilters] = useState([]);
    const [check, setCheck] = useState(false);
    const menuId = ReadCookie('menuId')
    const userName = ReadCookie('name')
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [sku, setSku] = useState('')
    const [reportIP, setReportIP] = useState('');
    const { Option } = Select;
    let history = useHistory();
    // const dateFormat = 'DD-MM-YYYY'


    const statusSelectAll = (e) => {
        setAllStatus(e.target.checked);
    };

    useEffect(() => {
        if (allStatus) {
            setIsChecked2([]);
        }
    }, [allStatus])

    const isCheckboxChecked2 = (id, value) => {
        const filterCheckBox =
            productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
        const checkId = isChecked2.includes(id);
        if (!checkId) {
            setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
        }
        if (!value) {
            const removeUnCheck = isChecked2.filter(
                (item) => item !== filterCheckBox.product_status_id
            );
            setIsChecked2(removeUnCheck);
        }
        setAllStatus(false);
    };

    useEffect(() => {
        // setUserID(decoded.user_id)
        dispatch(getReportIP(token)).then((res) => {
            setReportIP(res.payload.data[0].report_server_ip)
        });
        dispatch(getGender(token, menuId));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(getFabric(token, menuId));
        dispatch(getFitCategory(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getWashName(token, menuId))
        dispatch(getSeasonYear(token, menuId));
        dispatch(getProductStatus(token, menuId));
    }, [dispatch, menuId, token])

    function debounce(cb, delay = 1000) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }

    const handleSkuSearch = () => {
        dispatch(getSku(token, null, null, null));
    }

    const optimizedFn = useCallback(debounce(handleSkuSearch), []);

    // const isFilter = (id, value) => {
    //     console.log(id, value);

    //     let checkId = filters.includes(id);
    //     if (!checkId) {
    //         // setFilters((current) => [...current, id])
    //         filters.push(id);
    //         setFilters(filters);
    //     }
    //     if (!value) {
    //         let removeUnCheck = filters.filter((item) => item !== id);
    //         console.log(removeUnCheck, "filters removeUnCheck");
    //         filters.length = 0;
    //         filters.push(...removeUnCheck);
    //     }
    //     console.log(filters, "filters");
    // };

    function generatePDF() {
        console.log("running")
        console.log(loading)
        var doc = new jsPDF();

        // Define columns and rows
        var columns = ["Style", "No of SKU", "BERGEN", "TRNS", "PK", "PROD", "Pend", "INT", "OTS",];
        var rows = [];

        // Populate rows with data
        omsStyle?.payload?.data.forEach(item => {
            var row = [item.style, item.noofsku, item.bergan, item.transit, item.pakistan, item.production, item.pending, item.international, item.ots];
            rows.push(row);
        });

        let bergenTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.bergan), 0)
        let trnsTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.transit), 0)
        let pkTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pakistan), 0)
        let prodTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.production), 0)
        let pendTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pending), 0)
        let intTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.international), 0)
        let otsTotal = omsStyle?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ots), 0)

        rows.push([" ", "Total", bergenTotal.toLocaleString("en-US"), trnsTotal.toLocaleString("en-US"), pkTotal.toLocaleString("en-US"), prodTotal.toLocaleString("en-US"), pendTotal.toLocaleString("en-US"), intTotal.toLocaleString("en-US"), otsTotal.toLocaleString("en-US")])



        // Add table to the PDF
        doc.autoTable({
            head: [columns],
            body: rows,
            styles: { fontSize: 5, align: 'center' },
        })

        doc.save("OMS-Style-Report.pdf");
    }

    const onFinish = async (e) => {
        setLoading(true)
        await form.validateFields().then((values) => {
            console.log(values, 'values-----');
            // values.sortOn = filters.length > 0 ? filters.toString() : null
            dispatch(
                getOmsStyleReport(token, values, isChecked, isChecked2, isPCChecked, BgradeCheck)).then((res => {
                    setLoading(false)
                    setData(res?.payload?.data)
                    // downloadExcelFileData(res?.payload?.data);
                }));
        });
    };

   

    let tableData = omsStyle?.payload?.data;


    function totals() {
        let wsTotal = 0;
        let rtsTotal = 0;
        let berganTotal = 0;
        let transitTotal = 0;
        let pkTotal = 0;
        let productionTotal = 0;
        let pendingTotal = 0;
        let intTotal = 0;
        let otsTotal = 0;
        let ecomTotal = 0;
        let nordTotal = 0;
        let neminTotal = 0;
        let saksTotal = 0;
        let verishopTotal = 0;
        let bgsTotal = 0;
        let podTotal = 0;

        // let data = tableData && tableData;
        tableData && tableData.forEach((item) => {
            berganTotal += item.bergan;
            transitTotal += item.transit;
            pkTotal += item.pakistan;
            productionTotal += item.production;
            pendingTotal += item.pending;
            intTotal += item.international;
            otsTotal += item.ots;
            ecomTotal += item.ecommerce;
            nordTotal += item.nord;
            neminTotal += item.neiman;
            saksTotal += item.saks;
            verishopTotal += item.verishop_canada;
            bgsTotal += item.bgs;
            podTotal += item.pod;
        })

        return {
            berganTotal: berganTotal,
            transitTotal: transitTotal,
            pkTotal: pkTotal,
            productionTotal: productionTotal,
            pendingTotal: pendingTotal,
            intTotal: intTotal,
            otsTotal: otsTotal,
            ecomTotal: ecomTotal,
            nordTotal: nordTotal,
            neminTotal: neminTotal,
            saksTotal: saksTotal,
            verishopTotal: verishopTotal,
            bgsTotal: bgsTotal,
            podTotal: podTotal,
        }

    }

    const sortBySku = (n) => {
        console.log(n);
        const userCopy = [...data];
        if (!check) {
            setCheck(true);
            if (n === "style") {
                userCopy.sort((a, b) => {
                    if (a.style !== null || undefined && b.style !== null || undefined) {
                        return a.style.localeCompare(b.style);
                    }
                });
            }
            else if (n === "noofsku") {
                userCopy.sort((a, b) => {
                    return Number(a.noofsku) - Number(b.noofsku);
                });
            }
            else if (n === "bergan") {
                userCopy.sort((a, b) => {
                    return Number(a.bergan) - Number(b.bergan);
                });
            }
            else if (n === "transit") {
                userCopy.sort((a, b) => {
                    return Number(a.transit) - Number(b.transit);
                });
            }
            else if (n === "pakistan") {
                userCopy.sort((a, b) => {
                    return Number(a.pakistan) - Number(b.pakistan);
                });
            }
            else if (n === "production") {
                userCopy.sort((a, b) => {
                    return Number(a.production) - Number(b.production);
                });
            }
            else if (n === "pending") {
                userCopy.sort((a, b) => {
                    return Number(a.pending) - Number(b.pending);
                });
            }
            else if (n === "international") {
                userCopy.sort((a, b) => {
                    return Number(a.international) - Number(b.international);
                });
            }
            else if (n === "ots") {
                userCopy.sort((a, b) => {
                    return Number(a.ots) - Number(b.ots);
                });
            }
        }
        else {
            setCheck(false);
            if (n === "style") {
                userCopy.sort((a, b) => {
                    if (b.style !== null || undefined && a.style !== null || undefined) {
                        return b.style.localeCompare(a.style);
                    }
                });
            }
            else if (n === "noofsku") {
                userCopy.sort((a, b) => {
                    return Number(b.noofsku) - Number(a.noofsku);
                });
            }
            else if (n === "bergan") {
                userCopy.sort((a, b) => {
                    return Number(b.bergan) - Number(a.bergan);
                });
            }
            else if (n === "transit") {
                userCopy.sort((a, b) => {
                    return Number(b.transit) - Number(a.transit);
                });
            }
            else if (n === "pakistan") {
                userCopy.sort((a, b) => {
                    return Number(b.pakistan) - Number(a.pakistan);
                });
            }
            else if (n === "production") {
                userCopy.sort((a, b) => {
                    return Number(b.production) - Number(a.production);
                });
            }
            else if (n === "pending") {
                userCopy.sort((a, b) => {
                    return Number(b.pending) - Number(a.pending);
                });
            }
            else if (n === "international") {
                userCopy.sort((a, b) => {
                    return Number(b.international) - Number(a.international);
                });
            }
            else if (n === "ots") {
                userCopy.sort((a, b) => {
                    return Number(b.ots) - Number(a.ots);
                });
            }
        }
        setData(userCopy)
    }

    // const clearFields = (event, type) => {
    //     event.preventDefault()
    //     if (type === 'sku') {
    //         setSku('')
    //     }
    //     else if (type === 'style') {
    //         setStyleID('All')
    //     }
    //     else if (type === 'fabric') {
    //         setFabricID('All')
    //     }
    //     else if (type === 'wash') {
    //         setWashID('All')
    //     }
    //     else if (type === 'gender_id') {
    //         setGenderID('All')
    //     }
    //     else if (type === 'genderCat') {
    //         setGenCatID('All')
    //     }
    //     else if (type === 'fitCat') {
    //         setFitCatID('All')
    //     }
    //     else if (type === 'season_id') {
    //         setSeasonID('All')
    //     }
    // };

    return (
        <div className='product-detail-main-wrapper' style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"

        }}>
            <div className='product-detail-main' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: "800px", marginBottom: "20px" }}>
                <div className='pageHead ' >
                    <h4 className='prod-det-title-hd mb-0'>OMS Style Report</h4>
                    <div>
                        <Button className="pos-right-10 iconBtn crudBTN" shape='circle' icon={<CloseCircleOutlined className='color-red' />} onClick={() => history.push("/")} />
                    </div>
                </div>
                <div className='product-detail-content'>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        form={form} name="control-hooks" onFinish={onFinish}>
                        <Brow>
                            <Bcol
                                // lg={8} xl={8} xxl={8} 
                                style={{ maxWidth: "70%" }}
                            >
                                <div className="date-filter">
                                    <h3 className="filter-title">Product Status</h3>
                                    <div className='d-flex' style={{ flexWrap: "wrap" }}>

                                        <div className="checkbox_content">
                                            <Form.Item name="current_status_id" className=" mb-0">
                                                <Checkbox checked={allStatus} onChange={statusSelectAll}>
                                                    All
                                                </Checkbox>
                                            </Form.Item>
                                        </div>
                                        {productStatus &&
                                            productStatus?.payload?.data.map((it, index) => {
                                                return (
                                                    <div key={index} className="checkbox_content">
                                                        <Form.Item name="productStatus" >
                                                            <Checkbox
                                                                style={{ width: "max-content", margin: "2px" }}
                                                                checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                                                                onChange={(e) =>
                                                                    isCheckboxChecked2(
                                                                        it.product_status_id,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            >
                                                                {it.product_status_name}
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </div>
                                                )
                                            })}
                                    </div>

                                </div>
                                <div className="date-filter">
                                    <h3 className="filter-title">Filter</h3>
                                    <Form.Item
                                        name="gender_id"
                                        label="Gender"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            defaultValue="All"
                                            className="filter-fields"
                                            // placeholder="Select Gender"
                                            loading={gender?.loading}
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                        >
                                            <Option value={null}>All</Option>

                                            {gender?.payload?.data.map((item) => {
                                                return <Option defaultValue={"All"} key={item.gender_id} value={item.gender_id}>{item.gender_name}</Option>
                                            })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "gender_id")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="season_id"
                                        label="Current Season"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            className="filter-fields"
                                            // placeholder="Select Current Season"
                                            loading={season?.loading}
                                            defaultValue="All"
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                        >
                                            <Option value={null}>All</Option>
                                            {
                                                season?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.season_id} value={item.season_id}>{item.season_name}</Option>
                                                })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "season_id")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="new_season_year_id"
                                        label="Season Year"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            className="filter-fields"
                                            // placeholder="Select Fabric"
                                            loading={seasonYear?.loading}
                                            defaultValue="All"
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                        >
                                            <Option value={null}>All</Option>
                                            {seasonYear?.payload?.data?.map((res) => {
                                                return <Option defaultValue="All" key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                                            }
                                            )}
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fabric")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="gender_category_id"
                                        label="Gender Category"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                            defaultValue="All"
                                            className="filter-fields"
                                            // placeholder="Select Gender Category"
                                            loading={genderCategory?.loading}

                                        >
                                            <Option value={null}>All</Option>
                                            {
                                                genderCategory?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.gender_category_id} value={item.gender_category_id}>{item.gender_category_name}</Option>
                                                })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "genderCat")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="sku"
                                        label="SKU"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onSearch={(value) => {
                                                optimizedFn(value)
                                            }}
                                            className="filter-fields"
                                            // placeholder="Select Fit Category"
                                            loading={Sku?.loading}
                                            defaultValue="All"
                                        >
                                            <Option value="All">All</Option>
                                            {
                                                Sku &&
                                                Sku?.payload?.data.map((item, index) => {
                                                    return <Option defaultValue="All" key={index} value={item.sku}>
                                                        {item.sku} </Option>
                                                })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fitCat")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="style_id"
                                        label="Style"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            className="filter-fields"
                                            // placeholder="Select Style"
                                            loading={style?.loading}
                                            defaultValue="All"
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                        >
                                            <Option value={null}>All</Option>
                                            {
                                                style?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.style_id} value={item.style_id}>{item.style_name}</Option>
                                                })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "style")}>CLEAR</button> */}
                                    </Form.Item>
                                    <Form.Item
                                        name="wash_id"
                                        label="Wash"
                                        initialValue={"All"}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            className="filter-fields"
                                            // placeholder="Select Wash"
                                            loading={wash?.loading}
                                            defaultValue="All"
                                            filterSort={(optionA, optionB) =>
                                                optionB?.value!==null?
                                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                            }
                                        >
                                            <Option value={null}>All</Option>
                                            {
                                                wash?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.wash_id} value={item.wash_id}>{item.wash_name}</Option>
                                                })
                                            }
                                        </Select>
                                        {/* <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "wash")}>CLEAR</button> */}
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol
                                // lg={4} xl={4} xxl={4}
                                style={{ maxWidth: "30%" }}
                            >
                                <div className="date-filter">
                                    <h3 className="filter-title">Pending Till</h3>
                                    <Form.Item
                                        name="as_on_date"
                                        // className="full_date"
                                        initialValue={moment().endOf('year')}
                                        className='fullDateWidthOmsStyleReport'
                                    >
                                        <DatePicker
                                            size="medium"
                                            format={"MM-DD-YYYY"}
                                            defaultValue={moment().format("MM-DD-YYYY")}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="date-filter">
                                    <h3 className="filter-title">Sort on</h3>
                                    <div className="checkbox_content">
                                        <Form.Item name="sortOn" >
                                            <Radio.Group >
                                                <Radio value={"Style"}>Style</Radio>
                                                <Radio value={"Bergen"}>Bergen</Radio>
                                                <Radio value={"Transit"}>Transit</Radio>
                                                <Radio value={"PK"}>PK</Radio>
                                                <Radio value={"Production"}>Production</Radio>
                                                <Radio value={"Pending"}>Pending</Radio>
                                                <Radio value={"INT Pending"}>INT Pending</Radio>
                                                <Radio value={"OTS"}>OTS</Radio>
                                            </Radio.Group>
                                            {/* <Checkbox
                                                            onChange={(e) => isFilter(it, e.target.checked)}
                                                        >
                                                            {it}
                                                        </Checkbox> */}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit" className='w-100'>
                                            Search
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </Form>
                    <div>
                                <div className="print_icon printing_class">
                                    <input
                                        type="image"
                                        name="ImgPDF"
                                        alt="Export to PDF"
                                        id="ImgPDF"
                                        title="Export to PDF"
                                        src={pdf}
                                        onClick={generatePDF}
                                        style={{ borderWidth: "0px", height: "25px" }}
                                    />
                                </div>
                                </div>
                </div>
            </div>
            <div>
                <div className="overflow distributor"
                    style={{
                        // overflowY: "scroll",
                        height: "57vh",
                        // borderBottom: "1px solid black"
                        width: "75vw"
                    }}
                >
                    {omsStyle?.payload?.data !== undefined ?
                        (
                            data.length > 0 ? (
                                <table
                                    className="table_distributor-data stock_table table_content_oms oms_stock_summary_table"
                                // style={{ margin: "10px 0px" }}
                                // style={{ margin: "10px 0px" }}
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                background: "#dedede",
                                                border: " 2px solid",
                                                height: " 34px",
                                                position: "sticky",
                                                top: "0px",
                                                fontSize: "12px"
                                            }}
                                        >
                                            <th>#</th>
                                            <th onClick={() => sortBySku('style')}>Style</th>
                                            <th onClick={() => sortBySku('noofsku')} style={{ textAlign: "right" }}>No of SKU</th>
                                            <th onClick={() => sortBySku('bergan')} style={{ textAlign: "right" }}>BERGEN</th>
                                            <th onClick={() => sortBySku('transit')} style={{ textAlign: "right" }}>TRNS</th>
                                            <th onClick={() => sortBySku('pakistan')} style={{ textAlign: "right" }}>PK</th>
                                            <th onClick={() => sortBySku('production')} style={{ textAlign: "right" }}>PROD</th>
                                            <th onClick={() => sortBySku('pending')} style={{ textAlign: "right" }}>PEND</th>
                                            <th onClick={() => sortBySku('international')} style={{ textAlign: "right" }}>INT</th>
                                            <th onClick={() => sortBySku('ots')} style={{ textAlign: "right" }}>OTS</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.style}</td>
                                                    <td style={{ textAlign: "right" }}>{data.noofsku}</td>
                                                    <td style={{ textAlign: "right" }}>{data.bergan}</td>
                                                    <td style={{ textAlign: "right" }}>{data.transit}</td>
                                                    <td style={{ textAlign: "right" }}>{data.pakistan}</td>
                                                    <td style={{ textAlign: "right" }}>{data.production}</td>
                                                    <td style={{ textAlign: "right" }}><Link
                                                        style={{
                                                            color: "blue",
                                                            textDecoration: "underline",
                                                            textAlign: "right"
                                                        }}
                                                        to={{
                                                            pathname: '/pending-detail-summary-report',
                                                            state: data
                                                        }}
                                                    >{data.pending}</Link></td>
                                                    <td style={{ textAlign: "right" }}>{data.international}</td>
                                                    <td style={{
                                                        textAlign: "right"
                                                    }}>{data.ots}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr >
                                            <td colSpan={2}></td>
                                            <td style={{ textAlign: "right" }}>Total</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().berganTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().transitTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().pkTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().productionTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().pendingTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().intTotal).format("0,00,0")}</td>
                                            <td style={{ textAlign: "right" }}>{numeral(totals().otsTotal).format("0,00,0")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <div className="no-Data">
                                    {/* {console.log(omsStyle?.payload?.data.length, 'omsStyle?.payload?.data.length')} */}
                                    <h1>No Data</h1>
                                </div>

                            )
                        ) : (
                            loading && (
                                <div className="loader" style={{ marginTop: "20px" }}>
                                    <Spin size="large" />
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div >
    );
};

export default OmsStyleReportNew;