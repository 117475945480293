import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { journalVoucherCols } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getAllJournalVoucherHeaders } from "../../redux/actions/journalVoucherHeader.action";
import { useHistory } from "react-router-dom";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import { CSVLink } from "react-csv";
import { checkDate } from "../../components/CheckDate/checkDate";

export default function JournalVoucherList({ token }) {
	const [form] = Form.useForm();
	const [dateOP, setDateOP] = useState("");
	const { journalVoucherHeaderList } = useSelector((state) => state);
	const history = useHistory();
	const dispatch = useDispatch();
	const [voucherNo, setVoucherNo] = useState(null);
	const [account, setAccount] = useState(null);
	const [postingPeriod, setPostingPeriod] = useState(null);
	const [genderName, setGender] = useState(null);
	const [payee, setPayee] = useState(null);
	// const [memo, setMemo] = useState(null)
	const [subsidiary, setSubsidiary] = useState(null);
	const [apAccount, setApAccount] = useState(null);
	const [from_date, setFromDate] = useState(moment().startOf('year'))
	const [to_date, setToDate] = useState(moment().endOf('year'))
	const [from_amount, setFromAmount] = useState(null);
	const [to_amount, setToAmount] = useState(null);
	const { Panel } = Collapse;
	const [dataLen, setDataLen] = useState(0);
	const [filteredCustomers, setFilteredCustomers] = [];
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const [show, setShow] = useState(false);
	const onFinishFilter = (values) => {
		if (moment(from_date).isAfter(to_date)) {
			checkDate(to_date, from_date, setFromDate);
		} else if (moment(from_date).isSame(to_date)) {
			dispatch(getAllJournalVoucherHeaders(token, voucherNo, postingPeriod, from_date, to_date, genderName)).then((res) => {
				setFilteredCustomers(res?.payload?.data);
				setDataLen(res?.payload?.data);
			});
		} else if (moment(from_date).isBefore(to_date)) {
			dispatch(getAllJournalVoucherHeaders(token, voucherNo, postingPeriod, from_date, to_date, genderName)).then((res) => {
				setFilteredCustomers(res?.payload?.data);
				setDataLen(res?.payload?.data);
			});
		}
	};
	function downloadExcelFileData(data) {
		if (data) {
		  return data.map((v) => {
			return {
			  "Voucher Header No": v.voucher_header_no,
			  "Posting Period": v.period_name,
			  "Date": moment(v.voucher_date).format("MM-DD-YYYY"),
			  "Gender": v.gender_name,
			};
		  });
		}
	  }
	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Journal Voucher List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									//   disabled={true}
									onClick={() => {
										history.push("/journal-voucher/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={{
									remember: true,
								}}
								onFinish={onFinishFilter}
								// onFinishFailed={onFinishFailed}
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="voucherNo" label="Voucher Number" value={voucherNo}>
											<Input
												allowClear
												onChange={(e) => setVoucherNo(e.target.value)}
												// placeholder="Type Voucher Number"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
											// value={form.getFieldValue().from_date} 
											initialValue={from_date}
										>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												placeholder={from_date}
												// value={form.getFieldValue().from_date}
												allowClear
												className="dateInput customInputs"
												// onChange={(e) => check(moment(e))}
												onChange={(e) => {
													setFromDate(moment(e));
													form.setFieldsValue({
														from_date: moment(e)
													});
													setDateOP("fromdate")
												}}

											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
											//  value={form.getFieldValue().to_date} 
											initialValue={to_date}
										>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												// value={form.getFieldValue().to_date}
												placeholder={to_date}
												allowClear
												className="dateInput customInputs"
												onChange={(e) => {
													setToDate(moment(e));
													form.setFieldsValue({
														to_date: moment(e)
													});
													setDateOP("todate")
												}}
											// onChange={(e) => setToDate(moment(e))}

											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" value={postingPeriod}>
											<Input
												allowClear
												onChange={(e) => setPostingPeriod(e.target.value)}
												// placeholder="Type Posting Period"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="genderName" label="Gender" value={genderName}>
											<Input
												allowClear
												onChange={(e) => setGender(e.target.value)}
												// placeholder="Type Posting Period"
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>

								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

{journalVoucherHeaderList?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {journalVoucherHeaderList?.payload?.data.length > 0 && (
                  <CSVLink
                    filename={"JournalVoucher.csv"}
                    data={
                      downloadExcelFileData(journalVoucherHeaderList?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={journalVoucherCols}
                  source={journalVoucherHeaderList?.payload?.data}
                  load={journalVoucherHeaderList?.loading}
                  paginate
                />
              </div>
            </div>
          )}
				</div>
			</div>
		</div>
	
	);
}
