import React, { useEffect, useState } from "react";
import { Spin, Select, DatePicker, Form, Button, Table, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo-black.svg";
import "../Distributor/Distributor.css";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { getChartOfAccount } from "../../redux/actions/Chart_All_Account.action";
import { getJournalLedger } from "../../redux/actions/journalLedger.action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;


const GeneralLedger = ({ token }) => {
  TabTitle("General Ledger")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    chartOfAccount,
    journalLedger
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [tableHeader, setTableHeader] = useState([]);

  let result = journalLedger && journalLedger?.payload?.data?.reduce(function (r, a) {
    r[a.account_id] = r[a.account_id] || [];
    r[a.account_id].push(a);
    return r;
  }, Object.create(null));

  const groupData = result && Object.values(result);

  // groupData.map((v) => console.log("VA", v))

  // console.log("groupData", groupData)
  // console.log("tableHeader", tableHeader)

  let arrSUM = [];
  if (groupData) {
    for (const group of groupData && groupData) {
      const balance = group && group.reduce((acc, obj) => acc + parseFloat(obj.debit), 0);

      arrSUM.push(balance)
    };
  }

  let creditSum = [];
  if (groupData) {
    for (const group of groupData && groupData) {
      const balance = group && group.reduce((acc, obj) => acc + parseFloat(obj.credit), 0);

      creditSum.push(balance)
    };
  }
  // console.log({ creditSum })

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      dispatch(getJournalLedger(token, data)).then((res => {
        setLoading(false)
        setInventoryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  useEffect(() => {
    dispatch(getChartOfAccount(token));
  }, [dispatch, menuId, token]);


  useEffect(() => {
    if (groupData !== undefined) {
      setTableHeader(groupData?.map((v) => v[0]))
    }
  }, [groupData?.length > 0])

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Account": v.account_search_display_name,
          "Transaction Type": v.transaction_type,
          "Transaction No": v.transaction_no,
          "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
          "Customer": v.customer_name,
          "Debit": numeral(v.debit).format("0,00.00"),
          "Credit": numeral(v.credit).format("0,00.00"),
        };
      })
    )
  }

  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"General-Ledger.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              General Ledger
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label htmlFor="" className="labels width30">
                      Account
                    </label>
                    <div style={{ width: "200px" }}>
                      <Form.Item
                        name="account_id"
                        initialValue={"All"}
                        className="w-100"
                      >
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value={null}>All</Option>
                          {chartOfAccount?.payload?.data.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.account_id}
                                >
                                  {`${item.account_id} ${item.account_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                        // defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div style={{ marginTop: "4px", float: "left" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
            </div>
          </div>
        </Form>
      </div>
      <div
        className="table_content international_order"
        style={{ maxWidth: '1350px' }}
      >
        <div>

          {journalLedger?.payload?.data !== undefined ?
            (
              journalLedger?.payload?.data.length > 0 ? (
                <>
                  <div style={{
                    display: "flex", justifyContent: "space-between",
                    paddingRight: "5px",
                    alignItems: "center",
                    background: "grey", color: "white",
                    height: "30px",
                    position: "sticky",
                    top: "0"
                  }} >
                    <div style={{ width: "40px" }}>

                    </div>
                    <div style={{ width: "250px" }}>
                      Account Name
                    </div>
                    <div style={{ width: "150px" }}>
                      Transaction Type
                    </div>
                    <div style={{ width: "210px" }}>
                      Transaction No
                    </div>
                    <div style={{ width: "120px" }}>
                      Transaction Date
                    </div>
                    <div style={{ width: "260px" }}>
                      Customer
                    </div>
                    <div style={{ width: "110px", textAlign: "right" }}>
                      Debit
                    </div>
                    <div style={{ width: "110px", textAlign: "right" }}>
                      Credit
                    </div>
                  </div>
                  <hr style={{ margin: "0px" }} />
                  {tableHeader.map(({ account_search_display_name, debit, credit }, index) => {
                    return <>
                      <Accordion account_name={account_search_display_name} debit={debit} credit={credit} tableRow={groupData[index]} debit_sum={arrSUM[index]} credit_sum={creditSum[index]} />
                    </>
                  })}
                </>
              ) : (
                <div className="no-Data">
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};


const Accordion = ({ account_name, tableRow, debit_sum, credit_sum }) => {
  const [isActive, setIsActive] = useState(false);



  console.log("tableRow", tableRow);
  return (
    <div className="accordion-item">
      <div className="accordion-title" style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px 4px 6px", fontSize: "11px" }} onClick={() => setIsActive(!isActive)}>
        <div style={{ width: "40px", cursor: "pointer" }}>{isActive ? '-' : '+'}</div>
        <div style={{ width: "250px" }}>{account_name}</div>
        <div style={{ width: "150px" }}></div>
        <div style={{ width: "210px" }}></div>
        <div style={{ width: "120px" }}></div>
        <div style={{ width: "260px" }}></div>
        <div style={{ width: "110px", textAlign: "right" }}>${numeral(debit_sum).format("0,0.0")}</div>
        <div style={{ width: "110px", textAlign: "right" }}>${numeral(credit_sum).format("0,0.0")}</div>
      </div>
      <hr style={{ margin: "0px" }} />
      {isActive && <div className="accordion-content">
        {tableRow?.map((v, index) => {
          return (
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "5px",
              paddingLeft: "5px",
              fontSize: "9px",
              fontWeight: "300"

            }}>
              <div style={{ width: "50px" }}></div>
              <div style={{ width: "250px" }}></div>
              <div key={index} style={{ width: "150px" }}>
                <p>{v?.transaction_type}</p>
              </div>
              <div key={index} style={{ width: "210px" }}>
                <p>{v?.transaction_no}</p>
              </div>
              <div key={index} style={{ width: "120px" }}>
                <p>{v?.transaction_date}</p>
              </div>
              <div key={index} style={{ width: "260px" }}>
                <p>{v?.customer_name}</p>
              </div>
              <div key={index} style={{ width: "110px", textAlign: "right" }}>
                <p>${numeral(v?.debit).format("0,0.0")}</p>
              </div>
              <div key={index} style={{ width: "110px", textAlign: "right" }}>
                <p>${numeral(v?.credit).format("0,0.0")}</p>
              </div>
            </div>
          )
        })}
      </div>}
    </div>
  );
};


export default GeneralLedger;
