import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllCreditHeader } from "../../redux/actions/credit.action";
// import { FilterOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
// import { Modal, DatePicker, Button } from "antd";
import Tables from "../../components/Table/Tables";
import { creditColumns } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Swal from "sweetalert2";
import { checkDate, dateChecker } from "../../components/CheckDate/checkDate";

const { Panel } = Collapse;
export default function ReceiptList({ token }) {
	const dispatch = useDispatch();
	const { creditNoteList } = useSelector((state) => state);
	const [form] = Form.useForm();
	const dateFormat = "MM-DD-YYYY";

	const worker = {
		from_date: moment(`1-1-${new Date().getFullYear()}`),
		to_date: moment(`12-31-${new Date().getFullYear()}`),
		noteHeaderNo  : null,
		postingPeriod: null,
		customerName: null,
		locationName: null,
		receiptNo: null,
	};
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {

			let formDateCopy = document.getElementById("fromDate").value;
			let toDateCopy = document.getElementById("toDate").value;

			dateChecker(formDateCopy, toDateCopy, "creditList", token, values, dispatch);

		})
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Credit Note List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="noteHeaderNo" label="Credit Note">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="customerName" label="Customer Name">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="locationName" label="Location">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="receiptNo" label="Reciept #">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>

								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={creditNoteList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{creditNoteList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={creditColumns}
									source={creditNoteList?.payload?.data}
									load={creditNoteList.loading}
									paginate
								/>
								
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
