import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteOrderShipmentHeader, getOneOrderShipmentHeader, getOneOrderShipmentHeaderDetail, markShip } from "../../redux/actions/orderShipment.action";
import CrudButtons from "./components/CrudButtons";
import OrderShipmentForm from "./components/Form/OrderShipmentForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";
import "../../screens/Product/product.css";
import "../Style/style.css";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { getOneOrderShipmentLineDetail } from "../../redux/actions/orderShipmentLine.action";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
import moment from "moment";


export default function OrderShipment({ token }) {
	TabTitle("Pick Ticket")
	const { id, customerCode, shipHeaderNo } = useParams();
	const dispatch = useDispatch();
	const dCompanyId = ReadCookie("defaultCompany");
	const { saleOrderFormData, log, orderShipment, userType } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const saleOrderID = id
	const [flag, setFlag] = useState(false);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [formData, setFormData] = useState(null);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const edit = queryParams.get("edit");
	// const menuId = ReadCookie("menuId");
	const ID = queryParams.get("order_id");
	const history = useHistory();
	const [userRights, setUserRights] = useState({})
	const [loading, setLoading] = useState(false);
	const [menuId, setMenuId] = useState(1254);

	const [transactionLock, setTransactionLock] = useState(false)
	// ********** FILTER RESET FUNCTION **********
	const filterReset = () => {
		setFormState("formView");
	};



	// ********** FETHCED RIGHTS **********
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	// ********** HANDLE MARKED FUNCTION **********
	const handleMarked = () => {
		if (orderShipment?.payload.ship_header_id) {
			dispatch(markShip(token, orderShipment?.payload && orderShipment?.payload.ship_header_id)).then((res) => {
				dispatch(getOneOrderShipmentHeaderDetail(token, orderShipment?.payload.ship_header_id));
				dispatch(getOneOrderShipmentLineDetail(token, orderShipment?.payload.ship_header_id));

			})
		}
	}

	// ********** HANDLE DELETE FUNCTION **********
	const handleDelete = () => {
		if (!orderShipment?.payload?.invoice_id && orderShipment?.payload?.status_id !== 3) {
			setLoading(true);
			dispatch(deleteOrderShipmentHeader(token, ID)).then((res) => {
				if (res.type === "DELETE_ORDER_SHIPMENT_SUCCESS") {
					setLoading(false);
					notification("success", "Order Shipment deleted", res.payload);
					setFlag(true);
					history.push("/order-shipment-new-list");
				}
			});
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Delete Invoice First | Move Status To Packed!",
			});
		}
	};

	// ********** HANDLE FORMSAVE FUNCTION **********
	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, []);

	// ********** MODE USEEFFECT **********
	useEffect(() => {
		if (mode === "edit") {
			dispatch(getOneOrderShipmentHeaderDetail(token, ID)).then((res) => {
				if(res?.type === "GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS") {
					setFormState("formView");
					if (res?.payload?.data[0]?.ship_date) {
						let transaction_date = moment(res?.payload.data[0]?.ship_date).format("YYYY-MM-DD");
						dispatch(getTransationLock(token, transaction_date)).then((r) => {
							// if (r.payload.data.length > 0) {
							if (r.payload.data.length > 0 && orderShipment?.payload?.status_id!==2) {

								setTransactionLock(true);
							}
						});
					}
				}
			})
		}

		else if (mode === "add") {
			dispatch(getOneOrderShipmentHeader(token, ID)).then((res) => setFormData(res?.payload));
			setFormState("add");
		}
	}, [mode])

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Order Shipment"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						{orderShipment?.loading || loading ?
							<div className='loaderDiv' >
								<Loader
									type="Grid"
									color="#212121"
									height={40}
									width={40}
								/>
							</div> :
							null}
						<div className="crudDiv">
							{formState !== "table" && (
								<CrudButtons
									user_type={userType?.payload?.data[0]?.coalesce}
									ID={ID}
									name={"saleOrder"}
									reset={filterReset}
									userRights={userRights}
									formType={formState}
									save={formSave}
									disable={disable}
									formState={setFormState}
									onDelete={handleDelete}
									saleOrderID={saleOrderID}
									id={id}
									handleMarked={handleMarked}
									customerCode={customerCode}
									shipHeaderId={shipHeaderNo}
									formData={orderShipment?.payload}
									token={token}
									setLoading={setLoading}
									transactionLock={transactionLock}
								/>
							)}
							<div className="mainProductContainer">
								<OrderShipmentForm
									user_type={userType?.payload?.data[0]?.coalesce}
									userRights={userRights}
									loading={loading}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "add" || formState === "edit" || formState === "formView" ? orderShipment?.payload : null}
									formState={formState}
									data={saleOrderFormData}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									setDisable={setDisable}
									logs={log}
									saleOrderID={ID}
									shipHeaderId={shipHeaderNo}
									id={id}
									customerCode={customerCode}
									edit={edit}
									mode={mode}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Sales Order{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}