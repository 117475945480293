import {
    GET_MENU_RIGHTS_REQUEST, GET_MENU_RIGHTS_ERROR, GET_MENU_RIGHTS_SUCCESS,
    ADD_MENU_RIGHTS_ERROR, ADD_MENU_RIGHTS_REQUEST, ADD_MENU_RIGHTS_SUCCESS,
    UPDATE_MENU_RIGHTS_ERROR, UPDATE_MENU_RIGHTS_REQUEST, UPDATE_MENU_RIGHTS_SUCCESS
} from "./types";
import { BASEURL } from "../../constant/config.js"
import axios from "axios";






export const getMenuRights = (token, userId, menuId) => dispatch => {
    dispatch({ type: GET_MENU_RIGHTS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getUserRights`,

        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: {
            admin_user_id: userId,
            module_id: menuId
        }
    })
        .then(response => {
            return dispatch({ type: GET_MENU_RIGHTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_MENU_RIGHTS_ERROR,
                payload: error.response,
            });
        })
}


// export const createMenuRights = (token,userId,opt) => dispatch => {
//     if(opt.isSelect){
//         
//         dispatch({ type: ADD_MENU_RIGHTS_REQUEST})
//         return axios({
//             method: "POST",
//             url: `${BASEURL}create/admin_menu_user_right`,

//             headers: {
//                 Accept: "application/json",
//                 'Authorization': 'Bearer ' + token
//             },
//           data:{
//             //   menu_id:3,
//               admin_user_id:userId,
//               menu_id:opt.menu_id,
//               can_view:opt.can_view,
//               can_add:opt.can_add,
//               can_edit:opt.can_edit,
//               can_delete:opt.can_delete,
//               can_print:opt.can_print,

//           }
//         })
//             .then(response => {
//                 return dispatch({ type: ADD_MENU_RIGHTS_SUCCESS, payload: response.data });
//             })
//             .catch((error) => {
//                 return dispatch({
//                     type: ADD_MENU_RIGHTS_ERROR,
//                     payload: error.response,
//                 });
//             })

//     }else{
//         
//     dispatch({ type: ADD_MENU_RIGHTS_REQUEST});
//     return axios({
//         method: "POST",
//         url: `${BASEURL}create/admin_menu_user_right`,

//         headers: {
//             Accept: "application/json",
//             'Authorization': 'Bearer ' + token
//         },
//       data:{
//         //   menu_id:3,
//           admin_user_id:userId,
//           menu_ids:opt.menu_ids,
//           can_view:opt.can_view,
//           can_add:opt.can_add,
//           can_edit:opt.can_edit,
//           can_delete:opt.can_delete,
//           can_print:opt.can_print,
//           is_select:true
//       }
//     })
//         .then(response => {
//             return dispatch({ type: ADD_MENU_RIGHTS_SUCCESS, payload: response.data });
//         })
//         .catch((error) => {
//             return dispatch({
//                 type: ADD_MENU_RIGHTS_ERROR,
//                 payload: error.response,
//             });
//         })}
// }




export const updateMenuRights = (token, userId, opt) => dispatch => {


    dispatch({ type: UPDATE_MENU_RIGHTS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/admin_menu_user_right/${opt.user_rights_id}`,

        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: {
            //   menu_id:3,
            admin_user_id: userId,
            menu_id: opt.menuId,
            can_view: opt.can_view,
            can_add: opt.can_add,
            can_edit: opt.can_edit,
            can_delete: opt.can_delete,
            can_print: opt.can_print,
            user_rights_id: opt.user_rights_id,
            created_by: opt.created_by
            //   is_select:true
        }
    })
        .then(response => {
            return dispatch({ type: UPDATE_MENU_RIGHTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_MENU_RIGHTS_ERROR,
                payload: error.response,
            });
        })
}





export const createMenuRights = (token, userId, data) => dispatch => {

    dispatch({ type: ADD_MENU_RIGHTS_REQUEST })
    return axios({
        method: "POST",
        url: `${BASEURL}create/saveUserRights`,

        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { data },
    })
        .then(response => {
            return dispatch({ type: ADD_MENU_RIGHTS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_MENU_RIGHTS_ERROR,
                payload: error.response,
            });
        })

}






//get_admin_menu_user_right