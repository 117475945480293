import React, { useEffect, useState } from "react";
import { Table, Input, Select, InputNumber, Form, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getProductSale } from "../../redux/actions/productSale.action";
import { getCommitCriteria } from "../../redux/actions/commitCriteria.action";
import numeral from "numeral";
const { Option } = Select;

const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid, orderItems, location_id }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { ProductSale, commitCriteria } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [rate, setRate] = useState("");
  const [costPrice, setCostPrice] = useState(null);
  const [quantity, setQuantity] = useState(null);

  useEffect(() => {
    dispatch(getCommitCriteria(token)).then((res) => {
    });
  }, []);

  const updateCostPrice = (type, id, value) => {
    setCostPrice(value);
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        if (type === "up") {
          item.rate = value;
          item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
          return item;
        }
        if (type === "down") {
          item.rate = value;
          item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
          return item;
        }
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  const updateQuantity = (type, id, value) => {
    setQuantity(value);
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        if (type === "up") {
          item.quantity = value;
          item.amount = numeral(item.quantity * item.rate).format('0.00');
          if (item.commit_status == 3) {
            item.quantity_backorder = item.quantity;
          }
          else {
            item.quantity_backorder =
              parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0;
          }

          if (item.commit_status == 3) {
            item.quantity_committed = 0;
          }
          else {
            item.quantity_committed =
              parseInt(item.quantity) -
              (parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0);
          }

          return item;
        }
        if (type === "down") {
          item.quantity = value;
          item.amount = numeral(item.quantity * item.rate).format('0,0.00');

          if (item.commit_status == 3) {
            item.quantity_backorder = item.quantity;
          }
          else {
            item.quantity_backorder =
              parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
          }
          if (item.commit_status == 3) {
            item.quantity_committed = 0;
          }
          else {
            item.quantity_committed =
              parseInt(item.quantity) -
              (parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0);
          }

          return item;
        }
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  useEffect(() => {
    let arr = [...data];
    let newArr = [...selectedItems];
    let onlyKey = [...newArr].map((sItem) => sItem.item_code);
    arr = [...arr].map((item) => {
      if (onlyKey.indexOf(item.item_code) != -1) {
        return {
          ...newArr[onlyKey.indexOf(item.item_code)],
        };
      }
      return item;
    });
    setData(arr);
  }, [selectedItems]);

  useEffect(() => {
    let arr = [...data];
    arr = [...arr].map((item, i) => {
      if (selectedRowKey.indexOf(item.item_code) === -1) {
        item.isSelected = false;
        return item;
      } else {
        item.isSelected = true;
        return item;
      }
    });
  }, [selectedRowKey]);

  useEffect(() => {
    if (!modalValue) {
      setData([]);
      setSelectedId(null);
      setSelectedItems([]);
    }
  }, [modalValue]);

  const handleGenerate = () => {
    dispatch(getProductSale(token, companyId, selectedId, location_id)).then((res) => {
      if (res.type === "GET_PRODUCTSALE_SUCCESS") {
        const arr = res?.payload?.map(items => {
          let found = orderItems.find(f => f.item_id === items.item_id)
          if (found) {
            found.quantity_available = items.quantity_available
            found.size = items.size
            found.gender_category_name = items.gender_category_name
            found.style_name = items.style_name
            found.wash_name = items.wash_name
            found.already = true
            return found
          } else {
            items.commit_status = 1;
            return items
          }
        })
        setData(arr)

      }
    });
  };

  const handleRate = (rate) => {
    let arr = [...data];
    arr.map((item) => {
      item.rate = rate;
    });
    setData(arr);
    setFilteredItems(filteredItems);
    setRate(rate);
  };

  const handleCommitStatus = (id, value) => {
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        item.commit_status = value;
        if (value == 3) {
          item.quantity_backorder = item.quantity;
          item.quantity_committed = 0;
        }
        return item;
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  const handleOk = () => {
    // setIsModalVisible(false);
    addOrderItems();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRate("");

  };

  const addOrderItems = () => {
    setOrderItems(filteredItems);
    setData([]);
    setSelectedId(null);
    // setShowGrid(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      for (let i = 0; i < selectedRows.length; i++) {
        selectedRows[i].isSelected = true;
        selectedRows[i].quantity = selectedRows[i].hasOwnProperty("quantity") ? selectedRows[i].quantity : 1;
        selectedRows[i].tax_amount = selectedRows[i].hasOwnProperty("tax_amount") ? selectedRows[i].tax_amount : 0;
        selectedRows[i].discount = selectedRows[i].hasOwnProperty("discount") ? selectedRows[i].discount : 0;
        selectedRows[i].discount_percent = selectedRows[i].hasOwnProperty(
          "discount_percent"
        )
          ? selectedRows[i].discount_percent
          : 0;
        selectedRows[i].tax_percent = selectedRows[i].hasOwnProperty(
          "taxt_percent"
        )
          ? selectedRows[i].tax_percent
          : 0;
        selectedRows[i].amount = numeral(selectedRows[i].quantity * selectedRows[i].rate).format('0.00');
        selectedRows[i].net_amount = numeral(selectedRows[i].quantity * selectedRows[i].rate -
          selectedRows[i].discount
        ).format('0.00');
        selectedRows[i].quantity_backorder = 0;
        selectedRows[i].quantity_committed = 0;
        selectedRows[i].balance_quantity = 0;
      }
      setSelectedItems(selectedRows);
      setFilteredItems(selectedRows);
      setSelectedRowKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return { disabled: record.already ? true : false }
    },
  };

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      render: (text, record, index) => {
        return (
          <InputNumber
            readOnly
            size="small"
            disabled={!record?.isSelected}
            value={rate ? record.rate : costPrice}
            defaultValue={rate ? rate : record.rate}
            onChange={(value) => updateCostPrice("up", record.item_code, value, index)}
          />
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      render: (text, record, index) => {
        const res = orderItems.filter((item) => item.item_code === record.item_code)

        return (
          <InputNumber
            size="small"
            disabled={!record?.isSelected}
            onChange={(value) => updateQuantity("up", record.item_code, value, index)}
            defaultValue={res.map((res) => {
              return record.quantity = res.quantity
            })}
          />
        );
      },
    },
    {
      title: "Amount",
      editable: false,
      render: (text, record) => {
        return (
          <>
            {parseFloat(
              record.quantity ? record.quantity * record.rate : 0
            ).toFixed(2)}
          </>
        );
      },
    },
  ];

  return (
    <Modal
      okButtonProps={{
        style: {
          display: selectedItems.length > 0 ? "" : "none",
        },
      }}
      okText="Add Order"
      destroyOnClose={true}
      width={"100%"}
      centered={true}
      title="Products Selection"
      visible={modalValue}
      onOk={handleOk}
      cancelText="Close"
      onCancel={handleCancel}
    >
      <Form form={form} component={false}>
        <div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
          <Input
            size="small"
            onPressEnter={(e) => {
              if (e.target.value.length) {
                handleGenerate();
              }
            }}
            className="w-100"
            value={selectedId}
            name="searchItem"
            onChange={(e) => {
              setSelectedId(e.target.value);
            }}
          />

          <Input
            size="small"
            className="w-100"
            value={rate}
            name="searchItem"
            onChange={(e) => {
              handleRate(e.target.value);
            }}
          />
          <Button
            className="customInputs"
            type="primary"
            disabled={!selectedId}
            onClick={() => handleGenerate()}
          >
            Views
          </Button>
        </div>
        <Table
          size="small"
          loading={ProductSale.loading}
          rowSelection={{ ...rowSelection }}
          bordered
          dataSource={data.map((it) => ({ ...it, key: it.item_code, }))}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </Modal >
  );
};
export default EditableTable;




