import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useSelector, useDispatch } from 'react-redux';
import Tables from "../../components/Table/Tables"
import PageHeader from '../../components/PageHeader/PageHeader';
import { getCountry, addCountry, deleteCountry, updateCountry, } from '../../redux/actions/country.action';
import { logColumns, countryColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";
import "../Style/style.css";
import notification from '../../utils/notification'
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
const { TabPane } = Tabs;

const Country = ({ token }) => {
    TabTitle("Country")
    const dispatch = useDispatch()
    const { country, userRights, log } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [countryName, setCountryName] = useState('');
    const [formState, setFormState] = useState('view');
    const [form] = Form.useForm();
    const countryNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [countryObj, setCountryObj] = useState(null);
    const [check, setCheck] = useState(true);
    const id = ReadCookie('menuId')

    useEffect(() => {
        dispatch(getCountry(token, id))
    }, [token, id, dispatch])



    const handleDelete = () => {
        if (countryObj) {

            dispatch(deleteCountry(token, countryObj.country_id)).then((res) => {
                if (res.type === "DELETE_COUNTRY_SUCCESS") {
                    notification("success", "Country Deleted", "Country Deleted Successfully!");
                }
                if (res.type === "DELETE_COUNTRY_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    country: ""
                })
                // setCheck(false)
                setCountryName("")
                setCountryObj(null)
                setFormState('view')
                dispatch(getCountry(token, id))
            })
        }
        setFormState("view")
    }


    ///CRUD BUTTON 
    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])
    // CRUD BUTTON
    useEffect(() => {
        if (countryObj) {
            setFormState('view')
            form.setFieldsValue({
                id: countryObj.country_id,
                country: countryObj.country_name,
                active: countryObj.is_active,
            });
            setCountryName(countryObj.region_name)
            setCheck(countryObj.is_active)
        }
    }, [countryObj]);

    //// CRUD BUTTON

    useEffect(() => {
        if (countryName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [countryName])




    const formSave = () => {

        if (formState === "add") {
            dispatch(addCountry(token, { country_name: countryName, is_active: check }, id)).then((res) => {

                if (res.type === "ADD_COUNTRY_ERROR") {
                    notification("error", "Not Created", res?.payload?.data?.data)
                }
                if (res.type === "ADD_COUNTRY_SUCCESS") {
                    notification("success", "Country Created", "Country created successfully!");
                    form.setFieldsValue({
                        country: ""
                    })
                    setFormState("")
                    // setCheck(false)
                    setCountryName("")
                    setCountryObj(null)

                }
                form.setFieldsValue({
                    country: ""
                })
                setFormState("")
                // setCheck(false)
                setCountryName("")
                setCountryObj(null)

                dispatch(getCountry(token, id))

            })

        }
        if (formState === "edit") {
            dispatch(updateCountry(token, { country_name: countryName, is_active: check }, countryObj.country_id)).then((res) => {

                if (res.type === "UPDATE_COUNTRY_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_COUNTRY_SUCCESS") {
                    notification("success", "Country Updated", "Record updated successfully")
                }
                dispatch(getCountry(token, id))
            })

            // setFormState("view")

        }
    }


    useEffect(() => {

        dispatch(getLog(token, { admin_country: "admin_country" }))
    }, [dispatch, token])
    useEffect(() => {
        if (formState === "add") {

            countryNameInput.current.focus();
            form.setFieldsValue({
                country: null,
            })
            setCheck(true)
            setCountryName("")
            setCountryObj(null)
        }
        // else if(formState=== "view"){
        //     form.setFieldsValue({

        //         region: null,


        //     })
        //     setCheck(true)


        //     setRegionName("")
        //     setRegionObj(null)
        // }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setCountryObj(null)
        setCheck(true)
        form.setFieldsValue({

            country: null,

        })
        setCountryName(null)
    }
    return <div className='styleContainer'>
        <div className='styleBody h-100' >
            <PageHeader title={"Country"} minimize={setMinimize} check={minimize} />
            {
                !minimize &&
                <div className='bodyStyle' >
                    <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} reset={filterReset} disable={disable} save={formSave} onDelete={handleDelete} /></div>
                    <div className='styleContent' >
                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                <div className='styleTable' >
                                    <Tables type="country" formState={formState} scrollX={"20rem"} countryObj={setCountryObj} source={country?.payload?.data} col={countryColumns} paginate scroll={602} perPage={25} load={country.loading} rowKey="country_id" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                <div className='styleForm'  >
                                    <div className='testStyle' >
                                        <Form
                                            form={form}
                                            name="basic"
                                        // labelCol={{ span: 8 }}

                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {countryObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            initialValue={form.getFieldValue().id}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}

                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>

                                                }

                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Country"
                                                        name="country"
                                                        initialValue={form.getFieldValue().country}
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={countryNameInput} onChange={(e) => setCountryName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>

                                    <div className='modalStyle' >


                                    </div>
                                    <Divider />
                                    <div className='styleTab' >
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered   >


                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                    </div>
                </div>
            }
        </div>
    </div>
};

export default Country