import React, { useEffect, useState } from "react";
import { Table, Input, Select, InputNumber, Form, Button, Modal, Empty, Checkbox } from "antd";
import notification from "../../utils/notification";
import { useSelector, useDispatch } from "react-redux";
import { getProductSale } from "../../redux/actions/productSale.action";
import { getCommitCriteria } from "../../redux/actions/commitCriteria.action";
import numeral from "numeral";
import Swal from "sweetalert2";
const { Option } = Select;

const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid, orderItems, headerNo, filteredItems, setFilteredItems, adjustmentLocation, adjLoc }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { ProductSale, commitCriteria } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [arr, setArr] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [rate, setRate] = useState("");
	const [costPrice, setCostPrice] = useState(null);
	const [quantity, setQuantity] = useState(null);




	useEffect(() => {
		dispatch(getCommitCriteria(token)).then((res) => {
		});
	}, []);


	const updateCostPrice = (type, id, value) => {
		setCostPrice(value);
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
				if (type === "down") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	const updateQuantity = (type, id, value) => {

		setQuantity(value);
		let arr = [...data];
		const filter = arr.map((item) => {



			if (item.item_code === id) {
				if (type === "up") {

					item.quantity = value;
					item.amount = numeral(item.quantity * item.rate).format('0.00');
					if (item.commit_status == 3) {
						item.quantity_backorder = item.quantity;
					}
					else {
						item.quantity_backorder =
							parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0;
					}

					if (item.commit_status == 3) {
						item.quantity_committed = 0;
					}
					else {


						item.quantity_committed =
							parseInt(item.quantity) -
							(parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0);
					}

					return item;
				}
				if (type === "down") {
					item.quantity = value;
					item.amount = numeral(item.quantity * item.rate).format('0,0.00');

					if (item.commit_status == 3) {
						item.quantity_backorder = item.quantity;
					}
					else {
						item.quantity_backorder =
							parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					}
					if (item.commit_status == 3) {
						item.quantity_committed = 0;
					}
					else {
						item.quantity_committed =
							parseInt(item.quantity) -
							(parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0);
					}

					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);

		// 
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	// useEffect(() => {
	//   let arr = [...data];
	//   // console.log("arr", arr)
	//   // console.log("selectedItems",selectedItems);

	//   arr.map((v, i) => {

	//     if (selectedRowKey.indexOf(v.item_code) !== -1) {
	//       arr[i].isSelected = true;
	//       arr[i].quantity = 1;
	//       return v;
	//     } else {
	//       arr[i].isSelected = false;
	//       arr[i].quantity = 0;
	//       return v;
	//     }

	//     // console.log("v", v);
	//     // console.log("selectedRowKeys", selectedRowKey);
	//     // if(selectedRowKey.length == [] ) {
	//     //   console.log("here");
	//     //   v.isSelected = false;
	//     //   v.quantity = 0;
	//     //   return v;
	//     // }

	//     //  selectedRowKey.filter((val) => {

	//     //     // console.log("v.item_code", v.item_code);
	//     //     // console.log("val.item_code", val);

	//     //     if (val === v.item_code) {
	//     //       console.log("Mtached arr[i]", arr[i])
	//     //       // arr[i].isSelected = true;
	//     //       // arr[i].quantity = 1;
	//     //       // return v;
	//     //     }
	//     //     else {
	//     //       console.log("Not Matchedarr[i]", arr[i])
	//     //       // arr[i].isSelected = false;
	//     //       // arr[i].quantity = 0;
	//     //       // return v;

	//     //     }


	//     // })
	//   }
	//   );
	//   // console.log("arr",arr)

	//   setData(arr)



	//   // arr = [...arr].map((item, i) => {

	//   // console.log("item",item);

















	//   // console.log("item.item_code", item.item_code);
	//   // console.log("selectedItems", selectedItems);
	//   // selectedRowKey.filter((v) => {
	//   //   if (v === item.item_code) {
	//   //     item.isSelected = true;
	//   //     return item;
	//   //     // console.log("MAtched", item.item_code)
	//   //   } else {
	//   //     item.quantity = 0;
	//   //     item.isSelected = false;
	//   //     return item;
	//   //     // console.log("Not MAtched")
	//   //   }
	//   // })
	//   // if (item.hasOwnProperty("isSelected")) {
	//   //   item.isSelected = true;
	//   //   return item;
	//   // } else {
	//   //   item.quantity = 0;
	//   //   item.isSelected = false;
	//   //   return item;
	//   // }
	//   // filteredItems.map((val) => {
	//   //   if(val.item_code === item.item_code) {
	//   //     // console.log(val.item_code === item.item_code)
	//   //   }else {
	//   //     // console.log("Not Matched", item)
	//   //     // console.log("Not Matched")

	//   //   }
	//   // })
	//   // console.log("selectedRowKey", item.item_code);
	//   // if (selectedRowKey.indexOf(item.item_code) === -1) {
	//   //   item.isSelected = false;
	//   //   item.quantity = 0;
	//   //   return item;
	//   // } else {
	//   //   item.isSelected = true;
	//   //   return item;
	//   // }
	//   // });
	// }, [selectedRowKey]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	//   const handleGenerate = async () => {

	//     dispatch(getProductSale(token, companyId, selectedId, headerNo)).then((res) => {
	//       if (res.type === "GET_PRODUCTSALE_SUCCESS") {
	//         const arr = res?.payload?.map(items => {
	//           let found = orderItems.find(f => f.item_id === items.item_id)
	//           if (found) {
	//             found.quantity_available = items.quantity_available
	//             found.size = items.size
	//             found.gender_category_name = items.gender_category_name
	//             found.style_name = items.style_name
	//             found.wash_name = items.wash_name
	//             found.already = true
	//             return found
	//           } else {
	//             items.commit_status = 1;
	//             return items
	//           }
	//         })
	//         setData(arr)
	//       }
	//     });



	//   };
	const handleGenerate = () => {
		console.log(adjLoc, 'adjLoc22');
		dispatch(getProductSale(token, companyId, selectedId, adjustmentLocation ? adjustmentLocation : adjLoc)).then((res) => {
			if (res.type === "GET_PRODUCTSALE_SUCCESS") {

				const arr = res?.payload?.map(items => {
					let found = orderItems.find(f => f.item_id === items.item_id)
					if (found) {
						found.quantity_available = items.quantity_available
						found.size = items.size
						found.gender_category_name = items.gender_category_name
						found.style_name = items.style_name
						found.wash_name = items.wash_name
						found.already = true
						return found
					} else {
						items.commit_status = 1;
						return items
					}
				})


				setData(arr);
			} else if (res.type === "GET_PRODUCTSALE_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Select Location",
				});
			}
		});
	};

	const handleRate = (rate) => {
		let arr = [...data];
		arr.map((item) => {
			item.rate = rate;
		});
		setData(arr);
		// setFilteredItems(filteredItems);
		setRate(rate);
	};

	const handleCommitStatus = (id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				item.commit_status = value;
				if (value == 3) {
					item.quantity_backorder = item.quantity;
					item.quantity_committed = 0;
				}
				return item;
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	const handleOk = () => {
		// setIsModalVisible(false);
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setRate("");
	};


	const addOrderItems = () => {
		// console.log("selectedItems", selectedItems);
		// console.log("Data", data);
		// console.log("orderItems", orderItems);
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
		// setShowGrid(true);
	};



	useEffect(() => {

	}, [])

	// const rowSelection = {
	//   onChange: (selectedRowKeys, selectedRows) => {

	//     // console.log("selectedRowKeys", selectedRowKeys);
	//     //   console.log("data", data);













	//     //  console.log("selectedRowKeys", selectedRowKeys)
	//     for (let i = 0; i < selectedRows.length; i++) {
	//       selectedRows[i].isSelected = true;
	//       data[i].isSelected = true;
	//       selectedRows[i].quantity = selectedRows[i].hasOwnProperty("quantity") ? selectedRows[i].quantity : 1;
	//       selectedRows[i].tax_amount = selectedRows[i].hasOwnProperty("tax_amount") ? selectedRows[i].tax_amount : 0;
	//       selectedRows[i].discount = selectedRows[i].hasOwnProperty("discount") ? selectedRows[i].discount : 0;
	//       selectedRows[i].discount_percent = selectedRows[i].hasOwnProperty(
	//         "discount_percent"
	//       )
	//         ? selectedRows[i].discount_percent
	//         : 0;
	//       selectedRows[i].tax_percent = selectedRows[i].hasOwnProperty(
	//         "taxt_percent"
	//       )
	//         ? selectedRows[i].tax_percent
	//         : 0;
	//       selectedRows[i].amount = numeral(selectedRows[i].quantity * selectedRows[i].rate).format('0.00');
	//       selectedRows[i].net_amount = numeral(selectedRows[i].quantity * selectedRows[i].rate -
	//         selectedRows[i].discount
	//       ).format('0.00');
	//       selectedRows[i].quantity = 1;
	//       // if (selectedRows[i].isSelected) {
	//       //   
	//       // }
	//       // if (!selectedRows[selectedRows.length - 1].isSelected) {
	//       //   selectedRows[i].quantity = 0;
	//       // }
	//       // console.log("record Change 1",selectedRows[selectedRows.length - 1].item_id === selectedRows[selectedRows.length - 1].item_id)
	//       // if(selectedRows[i].item_id === selectedRows[selectedRows.length - 1].item_id) {
	//       //   selectedRows[i].quantity = 1;
	//       // }else {
	//       //   selectedRows[i].quantity = 0;
	//       // }
	//       // console.log("record Change 2",selectedRows[i].item_id === selectedRows[selectedRows.length - 1].item_id)
	//       // console.log("record Change 3",selectedRows[selectedRows.length - 1].item_id)
	//       // console.log("record Change 2", selectedRowKeys)
	//       // if (selectedRowKeys.includes(selectedRows[i].item_code)) {
	//       //   selectedRows[i].quantity = 1;
	//       //   console.log("Matcehd")
	//       // } 
	//       // else if(!selectedRowKeys.includes(selectedRows[i].item_code)) {
	//       //   selectedRows[i].quantity = 0;
	//       //   console.log("Not Matcehd")
	//       // }
	//       // console.log("Row", selectedRows)
	//       // selectedRows[i].quantity = 1;

	//       // if (selectedRows[i].isSelected) {

	//       //   selectedRows[i].quantity = 1;
	//       // } else {
	//       //   selectedRows[i].quantity = 0;

	//       // }

	//   let avail = selectedRows[i].quantity_available;
	//   if (avail > selectedRows[i].quantity) {
	//     selectedRows[i].quantity_backorder = 0;
	//     selectedRows[i].quantity_committed = selectedRows[i].quantity;

	//   }
	//   else if (avail < selectedRows[i].quantity) {
	//     selectedRows[i].quantity_backorder = selectedRows[i].quantity - avail;
	//     selectedRows[i].quantity_committed = avail;

	//   }

	// }

	//     // data.map((val) => ({...val, isSelected: true}))

	//     // updateQuantity("up", selectedRowKeys[index], 1);
	// let merge = filteredItems.concat(selectedRows);
	// setFilteredItems(selectedRows);
	// setSelectedItems(merge);
	//     setSelectedRowKey(selectedRowKeys);
	//   },
	//   getCheckboxProps: (record) => {
	//     return {
	//       // checked : true,
	//       disabled: record.already ? true : false
	//     }
	//   },
	// };


	const rowSelection = (event, record, index) => {
		let copyArr = [...data];
		arr.push(record);
		if (event.target.checked) {
			copyArr[index].isSelected = true;
			copyArr[index].quantity = copyArr[index].hasOwnProperty("quantity") ? copyArr[index].quantity : 1;
			copyArr[index].tax_amount = copyArr[index].hasOwnProperty("tax_amount") ? copyArr[index].tax_amount : 0;
			copyArr[index].discount = copyArr[index].hasOwnProperty("discount") ? copyArr[index].discount : 0;
			copyArr[index].discount_percent = copyArr[index].hasOwnProperty(
				"discount_percent"
			)
				? copyArr[index].discount_percent
				: 0;
			copyArr[index].tax_percent = copyArr[index].hasOwnProperty(
				"taxt_percent"
			)
				? copyArr[index].tax_percent
				: 0;
			copyArr[index].quantity = 1;
			copyArr[index].amount = numeral(copyArr[index].quantity * copyArr[index].rate).format('0.00');
			copyArr[index].net_amount = numeral(copyArr[index].quantity * copyArr[index].rate -
				copyArr[index].discount
			).format('0.00');


			let avail = copyArr[index].quantity_available;
			if (avail > copyArr[index].quantity) {
				copyArr[index].quantity_backorder = 0;
				copyArr[index].quantity_committed = copyArr[index].quantity;

			}
			else if (avail < copyArr[index].quantity) {
				copyArr[index].quantity_backorder = copyArr[index].quantity - avail;
				copyArr[index].quantity_committed = avail;

			}


		} else {
			// const deleteArr = arr.filter((v) => v.item_id !== record.item_id);
			//   const deletefilterItem = filteredItems.filter((v) => v.item_id !== record.item_id);
			// setArr(deleteArr);
			//   setFilteredItems(deletefilterItem);
			copyArr[index].quantity = 0;
			copyArr[index].quantity_committed = 0;
			copyArr[index].isSelected = false;
		}
		const selectedItem = copyArr.filter((v) => v.isSelected === true);
		setData(copyArr);
		setFilteredItems(selectedItem);
		console.log(selectedItem, "checkSele");
	}



	const columns = [
		{
			title: "Selected",
			// dataIndex: "item_code",
			render: (text, record, index) => {
				return <Checkbox
					defaultChecked={record.already}
					disabled={record.already}
					onChange={(event) => rowSelection(event, record, index)}></Checkbox>
			},
		},
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style Name",
			dataIndex: "style_name",
		},
		{
			title: "Wash Name",
			dataIndex: "wash_name",
		},

		{
			title: "Inseam",
			dataIndex: "inseam",
		},
		{
			title: "Size",
			dataIndex: "size",
		},

		{
			title: "Rate",
			dataIndex: "rate",
			render: (text, record, index) => {
				return (
					<InputNumber
						readOnly
						size="small"
						disabled={!record?.isSelected || record.already}
						value={rate ? record.rate : costPrice}
						defaultValue={rate ? rate : record.rate}
						onChange={(value) => updateCostPrice("up", record.item_code, value, index)}
					/>
				);
			},
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record, index) => {
				const res = orderItems.filter((item) => item.item_code === record.item_code)
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						onChange={(value) => updateQuantity("up", record.item_code, value, index)}
						value={data.length > 0 && data[index]?.quantity}
					// defaultValue={res.map((res) => {
					//   return record.quantity = res.quantity
					// })}
					/>
				);
			},
		},
		{
			title: "Amount",
			editable: false,
			render: (text, record) => {
				return (
					<>
						{parseFloat(
							record.quantity ? record.quantity * record.rate : 0
						).toFixed(2)}
					</>
				);
			},
		},

	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: arr.length > 0 ? "" : "none",
				},
			}}
			okText="Add Item"
			destroyOnClose={true}
			width={"100%"}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
			cancelText="Close"
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {

							if (e.target.value.length) {
								handleGenerate();
							}


						}}
						placeholder="SKU"
						className="w-100"
						value={selectedId}
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>

					<Input
						size="small"
						className="w-100"
						value={rate}
						name="searchItem"
						placeholder="Rate"

						onChange={(e) => {
							handleRate(e.target.value);
						}}
					/>
					<Button
						className="customInputs"
						type="primary"
						disabled={!selectedId}
						onClick={() => handleGenerate()}
					>
						Views
					</Button>
				</div>
				<Table
					size="small"
					loading={ProductSale.loading}
					// rowSelection={{
					//   ...rowSelection,

					// }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code, }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal >
	);
};
export default EditableTable;




