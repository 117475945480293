import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { Row as Brow, Col as Bcol } from "reactstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./pickticket.css"
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getPendingPodSummary } from "../../redux/actions/pendingPodSummary.action";
import { getShipStatus } from "../../redux/actions/shipStatus.action";
import { getPickTicketStatus } from '../../redux/actions/pickTicketStatus.action';
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Table,
    InputNumber,
    Space, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getTrackingStatus } from "../../redux/actions/trackingStatus.action";

const { Option } = Select;

const PendingPodSummary = () => {
    TabTitle("Pending Pod Summary")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const submitButton = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const [pendingSummaryList, setPendingSummaryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    const [excelData, setExcelData] = useState([])
    const {
        gender,
        region,
        style,
        salePersonForm,
        defaultCompany,
        paymentterm,
        adminLocation,
        shipStatus,
        getPendingSummaryReducer,
        PickTicketSummary,
        saleOrderFormData,
        pendingPodSummary,
        pickTicketStatus,
        trackingStatus
    } = useSelector((state) => state);



    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getAllCompany(token));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getTrackingStatus(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getShipStatus(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
        dispatch(getPickTicketStatus(token, menuId));
    }, [dispatch, menuId, token]);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let to_date = query.get("to_date");
    let from_date = query.get("from_date");
    let customer_name = query.get("customer_name");
    let region_id = query.get("region_id");
    let gender_id = query.get("gender_id");

    console.log("from_date", from_date);
    console.log("to_date", to_date);
    console.log("region_id", region_id);
    console.log("gender_id", Number(gender_id));

    useEffect(() => {
        if (to_date) {
            const values = {
                ship_no: null,
                order_no: null,
                from_date: from_date,
                to_date: to_date,
                po_no: null,
                customer: customer_name,
                billing_addressee: null,
                sale_person: null,
                region: region_id,
                gender: gender_id,
                terms: null,
                subsidary: null,
                status: null,
                ship_status: null,
                location: null,
                tracking_status: null
            }
              form.setFieldsValue({
                // to_date: values.to_date,
                customer: values.customer,
                region_id: values.region,
                gender_id: values.gender,
              });
            setLoading(true);
            dispatch(getPendingPodSummary(token, values)).then((res) => {
                setToogle(true);
                setLoading(false);
                setPendingSummaryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
                setLoading(false);
            });
        }
    }, [to_date])
    


    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'PT #',
            dataIndex: 'ship_header_no',
            key: 'ship_header_no',
            render: (text, record) => (
                <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                    to={"/order-shipment?mode=edit&order_id=" + record.ship_header_id + "&customer_id/" + record.customer_id + "?edit=yes"}
                >
                    {text}
                </Link>
            ),
        },
        {
            title: 'PT Date',
            render: (text, record) => {
                return <>{moment(record.ship_date).format("MM-DD-YYYY")}</>;
            },
            // width: 90
        },
        {
            title: 'Cancel Date',
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
            },
            // width: 90
        },
        {
            title: 'Customer',
            dataIndex: 'billingcustomer',
            key: 'billingcustomer',
            width: 200
        },
        {
            title: 'Billing Addressee',
            dataIndex: 'billing_addressee',
            key: 'billing_addressee',
            width: 200
        },
        {
            title: 'SO #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            // width: 80
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            // width: 120
        },
        {
            title: 'PT Qty',
            render: (text, record) => {
                return <>{parseFloat(record.quantity).toFixed(0)}</>;
            },
            // width: 90
            align: "right",
        },
        {
            title: 'Amount',
            render: (text, record) => {
                return <>$ {numeral(record.amount).format('0,0.00')}</>
            },

            // width: 100,
            align: "right",
        },
        {
            title: 'Term',
            dataIndex: 'payment_term',
            key: 'payment_term',
            // width: 100
        },
        {
            title: 'Sale Person Name',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            // width: 150
        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            key: 'region_name',
            // width: 150
        },
        {
            title: 'PT Status',
            dataIndex: 'pt_status_name',
            key: 'pt_status_name',
            // width: 80
        },
        {
            title: 'NS Status',
            dataIndex: 'ship_status_name',
            key: 'ship_status_name',
            // width: 50
        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location_name',
            // width: 80
        },
        {
            title: 'Tracking Status',
            dataIndex: 'tracking_status',
            key: 'tracking_status',
            // width: 80
        },
    ];

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "PT #": v.ship_header_no,
                    "PT Date": moment(v.ship_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "Customer": v.billingcustomer,
                    "Billing Addressee": v.billing_addressee,
                    "SO #": v.order_header_no,
                    "PO #": v.reference_no,
                    "PT Qty": numeral(v.quantity).format("0,00"),
                    "Amount": parseFloat(v.amount).toFixed(2),
                    "Term": v.payment_term,
                    "Sale person": v.salesperson_name,
                    "Region": v.region_name,
                    "PT Status": v.pt_status_name,
                    "NS Status": v.ship_status_name,
                    "Loaction": v.location_name,
                    "Tracking Status": v.tracking_status,
                };
            })
        )
    }

    const onFinish = async () => {
        setLoading(true);
        await form.validateFields().then((values) => {
            console.log("values", values);
            // 
            dispatch(getPendingPodSummary(token, values)).then((res) => {
                setToogle(true);
                setLoading(false);
                setPendingSummaryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }).catch((error) => {
            setLoading(false);
        })
    }

    const totalQuantity = () => {
        let sumQty = pendingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity);
        }, 0);
        return sumQty;
    }

    const totalSum = () => {
        let sumAmount = pendingSummaryList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }


    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Pending POD Summary"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-0">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_no"
                                                        label="PT #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={from_date  ? moment(from_date) : moment().startOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={from_date  ? moment(from_date) : moment().startOf('year')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={to_date ? moment(to_date) : moment().endOf('year')}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={to_date ? moment(to_date) : moment().endOf('year')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={region_id === "All" ? region_id : (region_id ? Number(region_id) : null)}

                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                // initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={gender_id === "All" ? gender_id : (gender_id ? Number(gender_id) : null)}

                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Pt Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        loading={pickTicketStatus.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {pickTicketStatus?.payload?.data.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.pt_status_id}
                                                                        value={item.pt_status_id}
                                                                    >
                                                                        {item.pt_status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="ship_status"
                                                        label="Ship Status"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={"All"}
                                                    >
                                                        <Select
                                                            size='small'
                                                            showSearch
                                                            optionFilterProp="children"
                                                            // onSearch={onSearch}
                                                            filterSort={(optionA, optionB) =>
                                                                optionB?.value !== null ?
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                            }
                                                            className="styleInput w-100"
                                                            defaultValue={form.getFieldValue().terms}
                                                        >
                                                            <Option value={null}>All</Option>
                                                            {
                                                                shipStatus?.payload?.data.map((val) => {
                                                                    return (
                                                                        <Option value={val.ship_status_id}>{val.ship_status_name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="subsidary"
                                                    label="Subsidary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={3}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }
                                                        defaultValue={form.getFieldValue().subsidary}
                                                        className="styleInput w-100"
                                                    >
                                                        {/* <Option value={null}>All</Option> */}
                                                        <Option value={defaultCompany?.payload?.company_id}>{defaultCompany?.payload?.company_name}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="tracking_status"
                                                        label="Tracking Status"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={"All"}
                                                    >
                                                        <Select
                                                            size='small'
                                                            showSearch
                                                            optionFilterProp="children"
                                                            
                                                            filterSort={(optionA, optionB) =>
                                                                optionB?.value !== null ?
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                            }
                                                            className="styleInput w-100"
                                                            defaultValue={form.getFieldValue().terms}
                                                        >
                                                            <Option value={null}>All</Option>
                                                            {
                                                                trackingStatus?.payload?.data.map((val) => {
                                                                    return (
                                                                        <Option value={val.tracking_status}>{val.tracking_status}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (
                        pendingSummaryList.length > 0 ? (
                            <Table
                                title={() =>
                                    <CSVLink
                                        filename={"Pending-Pod-Summary.csv"}
                                        data={excelData}
                                        onClick={() => {
                                        }}
                                    >
                                        Download Excel
                                    </CSVLink>
                                    // <DownloadTableExcel
                                    //     filename={`Pending-Pod-Summary-${moment().format("MM-DD-YYYY")}`}
                                    //     sheet="Pending Pod Summary"
                                    //     currentTableRef={tableRef.current}
                                    // >
                                    //     <span
                                    //         className="print_icon printing_class">
                                    //         <input style={{ height: "25px" }}
                                    //             type="image"
                                    //             name="ImgExcel"
                                    //             alt="Export to excel"
                                    //             id="ImgExcel"
                                    //             title="Export to excel"
                                    //             src={xlsx}
                                    //         />
                                    //     </span>
                                    // </DownloadTableExcel>
                                }
                                ref={tableRef}
                                size="small"
                                bordered={false}
                                type="company"
                                scroll={{
                                    x: 'max-content',
                                }}
                                pagination={{
                                    defaultCurrent: 1,
                                    defaultPageSize: 100,
                                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                                }}
                                summary={(pageData) => {
                                    let sumQty = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            currentValue.quantity
                                        ));
                                    },
                                        0);
                                    let Amount = pageData.reduce(function (
                                        previousValue,
                                        currentValue
                                    ) {
                                        return (previousValue += parseFloat(
                                            Number(currentValue.amount)
                                        ));
                                    },
                                        0);
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        {sumQty.toLocaleString("en-US")}
                                                    </p>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <p style={{ textAlign: "right" }} className="totalquantity">
                                                        $ {numeral(Amount).format('0,0.00')}
                                                    </p>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                                dataSource={pendingSummaryList}
                                columns={columns}
                                loading={pendingPodSummary.loading}
                                paginate
                            />
                        ) : <div
                            style={{
                                backgroundColor: "#CCCCCC",
                                borderWidth: "1pt",
                                borderStyle: "Dashed",
                                fontWeight: "bold",
                                height: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            No record(s)
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default PendingPodSummary;
