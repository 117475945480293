import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useSelector, useDispatch } from 'react-redux';
import Tables from "../../components/Table/Tables"
import PageHeader from '../../components/PageHeader/PageHeader';
import { getRegion, addRegion, deleteRegion, updateRegion } from '../../redux/actions/region.action';
import { logColumns, regionColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";
import "../Style/style.css";
import notification from '../../utils/notification'
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
const { TabPane } = Tabs;

const Region = ({ token }) => {
    TabTitle("Region")
    const dispatch = useDispatch()
    const { region, userRights, log } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [regionName, setRegionName] = useState('');
    const [formState, setFormState] = useState('view');
    const [form] = Form.useForm();
    const regionNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [regionObj, setRegionObj] = useState(null);
    const [check, setCheck] = useState(true);
    const id = ReadCookie('menuId')

    useEffect(() => {
        dispatch(getRegion(token, id))
    }, [token, id, dispatch])



    const handleDelete = () => {
        if (regionObj) {

            dispatch(deleteRegion(token, regionObj.region_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_REGION_SUCCESS ") {
                    notification("success", "Region Deleted", "Region deleted successfully!");
                }
                if (res.type === "DELETE_ADMIN_REGION_ERROR ") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    region: ""
                })
                // setCheck(false)
                setRegionName("")
                setRegionObj(null)
                setFormState('view')
                dispatch(getRegion(token, id))
            })
        }
        setFormState("view")
    }


    ///CRUD BUTTON 
    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])
    // CRUD BUTTON
    useEffect(() => {

        if (regionObj) {
            setFormState('view')
            form.setFieldsValue({
                id: regionObj.region_id,
                region: regionObj.region_name,
                active: regionObj.is_active,

            });
            setRegionName(regionObj.region_name)
            setCheck(regionObj.is_active)

            // dispatch(getCompanyAccess(token, stateObj.state_id, id))

        }

    }, [regionObj]);

    //// CRUD BUTTON

    useEffect(() => {
        if (regionName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [regionName])




    const formSave = () => {

        if (formState === "add") {
            dispatch(addRegion(token, { region_name: regionName, is_active: check }, id)).then((res) => {

                if (res.type === "ADD_ADMIN_REGION_ERROR ") {
                    notification("error", "Not Created", res?.payload?.data?.data)
                }
                if (res.type === "ADD_ADMIN_REGION_SUCCESS ") {
                    notification("success", "Region Created", "Region created successfully!");
                    form.setFieldsValue({
                        region: ""
                    })
                    setFormState("")
                    // setCheck(false)
                    setRegionName("")
                    setRegionObj(null)

                }
                form.setFieldsValue({
                    region: ""
                })
                setFormState("")
                // setCheck(false)
                setRegionName("")
                setRegionObj(null)

                dispatch(getRegion(token, id))

            })

        }
        if (formState === "edit") {
            dispatch(updateRegion(token, { region_name: regionName, is_active: check }, regionObj.region_id)).then((res) => {

                if (res.type === "UPDATE_ADMIN_REGION_ERROR ") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_ADMIN_REGION_SUCCESS ") {
                    notification("success", "Region Updated", "Record updated successfully")
                }
                dispatch(getRegion(token, id))
            })

            // setFormState("view")

        }
    }


    useEffect(() => {

        dispatch(getLog(token, { admin_region: "admin_region" }))
    }, [dispatch, token])
    useEffect(() => {
        if (formState === "add") {

            regionNameInput.current.focus();
            form.setFieldsValue({
                region: null,
            })
            setCheck(true)
            setRegionName("")
            setRegionObj(null)
        }
        // else if(formState=== "view"){
        //     form.setFieldsValue({

        //         region: null,


        //     })
        //     setCheck(true)


        //     setRegionName("")
        //     setRegionObj(null)
        // }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setRegionObj(null)
        setCheck(true)
        form.setFieldsValue({

            region: null,

        })
        setRegionName(null)
    }
    return <div className='styleContainer'>
        <div className='styleBody h-100' >
            <PageHeader title={"Region"} minimize={setMinimize} check={minimize} />
            {
                !minimize &&
                <div className='bodyStyle' >
                    <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} reset={filterReset} disable={disable} save={formSave} onDelete={handleDelete} /></div>
                    <div className='styleContent' >
                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                <div className='styleTable' >
                                    <Tables type="region" formState={formState} scrollX={"20rem"} regionObj={setRegionObj} source={region?.payload?.data} col={regionColumns} paginate scroll={602} perPage={25} load={region.loading} rowKey="region_id" />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                <div className='styleForm'  >
                                    <div className='testStyle' >
                                        <Form
                                            form={form}
                                            name="basic"
                                        // labelCol={{ span: 8 }}

                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {regionObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            initialValue={form.getFieldValue().id}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}

                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>

                                                }

                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Region"
                                                        name="region"
                                                        initialValue={form.getFieldValue().region}
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={regionNameInput} onChange={(e) => setRegionName(e.target.value)}
                                                            className={`styleInput `} />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>

                                    <div className='modalStyle' >


                                    </div>
                                    <Divider />
                                    <div className='styleTab' >
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered   >


                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                    </div>
                </div>
            }
        </div>
    </div>
};

export default Region