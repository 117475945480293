import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesOrderAdjustmentHeader, deleteSaleOrderAdjustmentHeader, getOneSaleOrderAdjustmentHeader } from "../../redux/actions/saleOrderAdjustment.action";
import CrudButtons from "./components/CrudButtons";
import SaleOrderAdjustmentForm from "./components/Form/SalesOrderAdjustmentForm";
//import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
// import Loader from "react-loader-spinner";
// import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import Loader from "react-loader-spinner";

export default function SaleOrderAdjustment({ token }) {
  const { id, customerCode } = useParams();
  const dispatch = useDispatch();
  const dCompanyId = ReadCookie("defaultCompany");
  const { saleOrderAdjustmentFormData, log, salesOrder } = useSelector((state) => {
    return state;
  });
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const [formData, setFormData] = useState(null);
  const saleOrderID = id;
  const [flag, setFlag] = useState(false);

  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get("mode");
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState(false);
  const startDateList = "01-1-2022";
  const endDateList = "06-01-2022";

  const filterReset = () => {
    setFormState("view");
  };

  useEffect(() => {
    if (saleOrderID) {
      setFormState("formView");
      dispatch(getOneSaleOrderAdjustmentHeader(token, saleOrderID)).then((res) => {
        if (res?.type === "GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR") {
          setFormData(salesOrder?.payload);
        } else if (res?.type === "GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS") {
          setFormData(res?.payload);
        }
        // if()
      });
    }
  }, [saleOrderID]);



  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteSaleOrderAdjustmentHeader(token, saleOrderID)).then((res) => {
      if (res.type === "DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS") {
        setLoading(false);
        dispatch(getAllSalesOrderAdjustmentHeader(token, startDateList, endDateList));
        notification("success", "Sale Order deleted", res.payload);
        setFlag(true);
      }
    });
  };

  useEffect(() => {
    if (mode === 'add') {
      setFormState('add');
    }
  }, []);
  const formSave = () => {
    setSubmit(true);
  };



  useEffect(() => {
    if (formState === "list") {
      setShowList(true);
    }
  }, [formState]);

  // useEffect(() => {
  //   if (saleOrderID) {
  //     setFormState("formView");
  //     dispatch(getOneSaleOrderAdjustmentHeader(token, saleOrderID));
  //   }
  // }, [saleOrderID]);

  const handleReport = () => {

    window.location.href = "http://127.0.0.1:62075/viewer/frameset?__report=C%3A%5Ceclipse%5Cworkspace%5CTalha%5CsaleOrder_report.rptdesign&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5Ceclipse%5Cworkspace%5CTalha&-808515879"

  }

  return (
    <>
      <div className="styleContainer">
        <div className="styleBody h-100">
          <PageHeader
            title={"Sales Order Adjustment"}
            minimize={setMinimize}
            check={minimize}
          />
          {/* <Button onClick={window.location("http://127.0.0.1:54395/viewer/frameset?__report=C%3A%5Ceclipse%5Cworkspace%5CTalha%5CsaleOrder_report.rptdesign&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5Ceclipse%5Cworkspace%5CTalha&1531926694")}></Button> */}
          {/* <Button onClick={() => handleReport()}> View Report</Button> */}


          <div className="bodyStyle">
            {formState === "formView" ?
              <>
                {salesOrder?.loading ?
                  <div className='loaderDiv' >
                    <Loader
                      type="Grid"
                      color="#212121"
                      height={80}
                      width={80}
                    />
                  </div> :
                  null}
              </> : null}
            <div className="crudDiv">
              <CrudButtons
                reportTittle="Sale Order"
                reportName="rpt_sale_order_print.rptdesign"
                id={id}
                customerCode={customerCode}
                name={"saleOrder"}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
                saleOrderID={saleOrderID}
                formData={salesOrder?.payload}
              />
              <div className="mainProductContainer">
                <SaleOrderAdjustmentForm
                  dCompany={dCompanyId}
                  defaultCompany={companyId}
                  companyId={setCompanyId}
                  submit={setSubmit}
                  val={submit}
                  formData={formState === "formView" || formState === "edit" ? formData : null}
                  formState={formState}
                  data={saleOrderAdjustmentFormData}
                  changeState={setFormState}
                  token={token}
                  checkFlag={flag}
                  setFlag={setFlag}
                  logs={log}
                  saleOrderID={saleOrderID}
                  customerCode={customerCode}
                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className='minimize-button-style'
              onClick={() => setMinimize(false)}
            >
              {" "}
              Sales Order{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}