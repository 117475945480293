import {
	GET_ONE_BILL_CREDIT_APPLY_LINE_REQUEST,
	GET_ONE_BILL_CREDIT_APPLY_LINE_SUCCESS,
	GET_ONE_BILL_CREDIT_APPLY_LINE_ERROR,
	ADD_BILL_CREDIT_APPLY_LINE_REQUEST,
	ADD_BILL_CREDIT_APPLY_LINE_SUCCESS,
	ADD_BILL_CREDIT_APPLY_LINE_ERROR,
	UPDATE_BILL_CREDIT_APPLY_LINE_REQUEST,
	UPDATE_BILL_CREDIT_APPLY_LINE_SUCCESS,
	UPDATE_BILL_CREDIT_APPLY_LINE_ERROR,
	DELETE_BILL_CREDIT_APPLY_LINE_REQUEST,
	DELETE_BILL_CREDIT_APPLY_LINE_SUCCESS,
	DELETE_BILL_CREDIT_APPLY_LINE_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");
export const addBillCreditApplyLine = (token, data) => (dispatch) => {


	const result = data.map(({ is_selected, bill_header_id, bill_header_no, vendor_id, vendor_name, ...rest }) => ({ ...rest }));
	console.log(result, 'payload');
	// const newData=forEach((res) => console.log(res)));
	// console.log(data, "inDispatch");
	// const newData = data?.map((item) => {
	// 	delete item.bill_header_id;
	// 	delete item.bill_header_no;
	// 	delete item.is_selected;
	// 	return {
	// 		item,
	// 	};
	// })
	// console.log("DATA", data);
	// const newData = data.map((item) => {
	// 	delete item.bill_header_id;
	// 	delete item.bill_header_no;
	// 	delete item.is_selected;
	// 	console.log('DATA2', item);
	// })
	// console.log("NEWDATA", newData);
	dispatch({ type: ADD_BILL_CREDIT_APPLY_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/paypurchasebillcreditapply`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: result },
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_CREDIT_APPLY_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_CREDIT_APPLY_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillCreditApplyLine = (token, data, id) => (dispatch) => {
	data.map((item) => {
		delete item.bill_date;
		delete item.bill_header_no;
		delete item.bill_header_id;
		delete item.original_amount;
		delete item.remaining_amount;
		// delete item.is_selected,
	});
	dispatch({ type: UPDATE_BILL_CREDIT_APPLY_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/paypurchasebillcreditapply/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data },
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_APPLY_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_APPLY_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCREDITAPPLYLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_APPLY_LINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/paypurchasebillcreditapply/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_APPLY_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_APPLY_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillCreditApplyLine = (token, id, deletedItems) => (dispatch) => {
	console.log(deletedItems, "datoo");
	dispatch({ type: DELETE_BILL_CREDIT_APPLY_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/paypurchasebillcreditapply/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: deletedItems }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_APPLY_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_APPLY_LINE_ERROR,
				payload: error.response,
			});
		});
};