import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Tooltip, Table, Popconfirm, InputNumber, Checkbox, Divider } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import { addCreditMemoHeader, updateCreditMemoHeader } from "../../../../redux/actions/creditMemo.action";
import { addCreditMemoLine, updateCreditMemoLine, getOneCreditMemoLine, deleteCreditMemoLine } from "../../../../redux/actions/creditMemoLine.action";
import { getChartofAccount } from "../../../../redux/actions/chartofaccount.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import EditableTable from "../../../../components/EditableTable/EditableTable";
import EditExpenseTable from "../../../../components/EditExpenseTable/EditExpenseTable";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAdminCurrency } from "../../../../redux/actions/adminCurrency.action";
import { getARAccounts } from "../../../../redux/actions/AR_Account.action";
import { getAdminLocation } from "../../../../redux/actions/adminLocation.action";
import { getShipVia } from "../../../../redux/actions/shipvia.action";
import { loadCustomers } from "../../../../redux/actions/customerForm";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getOneCreditMemoInvoice, deleteCreditMemoInvoice, updateCreditInvoice } from "../../../../redux/actions/creditMemoInvoice.action";
import { getPaymentTerm } from "../../../../redux/actions/paymentterm.action";
import { getGender } from "../../../../redux/actions/gender.action";
import { loadSalePerson } from "../../../../redux/actions/salePerson/salePersonForm.action";
import numeral from "numeral";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
const { Option } = Select;
const { TabPane } = Tabs;

export default function CreditMemoForm({ formData, val, token, formState, submit, saleOrderID, loading: deleteLoading, setDisable }) {
	const dispatch = useDispatch();
	const { invoiceGlPeriod, companyAll, creditMemoLine, customerForms, arAccount, creditMemoInvoice, glImpact, paymentterm, gender, salePersonForm, systemLog } = useSelector((state) => state);
	const companyId = ReadCookie("defaultCompany");
	// const menuId = ReadCookie('menuId');
	const [form] = Form.useForm();
	const submitButton = useRef(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	//Expense Modal
	const [isExpenseModalVisible, setIsExpenseModalVisible] = useState(false);
	const [visibleExpenseItems, setVisibleExpenseItems] = useState([]);
	const [expenseItems, setExpenseItems] = useState([]);
	const [test, setTest] = useState(null);
	const [resultArr, setresultArr] = useState([]);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [oDate, setOrderDate] = useState(moment());
	const [adjustmentLocation, setAadjustmentLocation] = useState(null);
	const [headerNo, setHeaderNo] = useState(ReadCookie("defaultCompany"));
	const [deletedItems, setDeletedItems] = useState([]);
	const [deletedMemoItems, setDeletedMemoItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [discountBasis, setDiscountBasis] = useState("");
	const [adjLoc, setadjLoc] = useState(null);
	const [customer, setCustomer] = useState([])
	const focusInput = useRef(null);
	const [lines, setLines] = useState([]);
	const [memoInvoice, setMemoInvoice] = useState([]);
	const [linesid, setLinesId] = useState(null)
	const [menuId, setMenuId] = useState(1261);

	const handleCustomerChanges = (val) => {
		if (val === "" || val === 'All') return
		setCustomer(val);
		dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
			(res) => {
				if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
					setCustomer(res?.payload?.data)
				}
			}
		);
	};

	const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

	function debounce(cb, delay = 1000) {
		let timeout
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	useEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token));
		invoiceGlPeriod?.payload?.map((item) => {
			return (
				resultArr.push(item)
			);
		})

		let result = resultArr.filter((item) => {
			return item.period_name == currentDate
		})

		setCurrentDate(result[0]?.period_id)
		setTest(result[0]?.period_id)

	}, []);

	useEffect(() => {
		setLoading(deleteLoading);
	}, [deleteLoading]);


	useEffect(() => {
		dispatch(getChartofAccount(token));
		dispatch(getAdminCurrency(token, menuId));
		dispatch(getAllCompany(token));
		dispatch(getShipVia(token));
		dispatch(getARAccounts(token));
		dispatch(getAdminLocation(token));
		dispatch(loadSalePerson(token));
		dispatch(getGender(token, menuId));
		dispatch(getPaymentTerm(token, menuId));

	}, []);

	var a = companyAll?.payload?.data[0]?.company_id;

	useEffect(() => {
		if (formState === "edit" || formState === "formView") {
			setLoading(true)
			dispatch(getOneCreditMemoInvoice(token, saleOrderID)).then((res) => {
				setMemoInvoice(res?.payload?.data);
				setLoading(false)
			});
			dispatch(getGLImpact(token, { "transaction_header_id": saleOrderID, "transaction_type": "CREDITMEMO" }));
		}
	}, [formState])

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				creditMemo: null,
				date: oDate,
				memo: null,
				postingPeriod: test,
				paymentTerm: null,
				subsidiary: 3,
				customer: null,
				gender: null,
				arAccount: null,
				sale_person: null,
			});
			setOrderItems([]);
			setExpenseItems([]);
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneCreditMemoLine(token, saleOrderID)).then((res) => {
				if (res.type === "GET_ONE_CREDIT_MEMO_LINE_SUCCESS") {
					setLines(res?.payload);
					setLoading(false)
				}
			});
		}
	}, [formState]);

	useEffect(() => {
		if (formData) {
			setLoading(false);
			form.setFieldsValue({
				creditMemo: formData?.memo_header_no,
				date: moment(formData?.memo_date),
				memo: formData?.memo,
				gender: formData?.gender_id,
				arAccount: formData?.ar_account_name,
				sale_person: formData?.sales_person_name,
				postingPeriod: formData?.period_name,
				paymentTerm: formData?.term_id,
				subsidiary: formData?.company_name,
				customer: formData?.customer_name
			});
		}
	}, [formData]);

	useEffect(() => {
		let filtered = expenseItems.filter((f) => f.isSelected === true);
		setVisibleExpenseItems(filtered);
	}, [expenseItems]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}


	const onFinish = async (e) => {
		submit(false);
		await form.validateFields().then((values) => {
			orderItems.filter((test) => {
			})
			setLoading(true);
			setDisable(true);
			if (formState === "add") {
				if (lines.length > 0) {
					dispatch(addCreditMemoHeader(token, values, parseInt(companyId))).then((res) => {
						let id = res?.payload?.memo_header_id;
						if (res.type === "ADD_CREDIT_MEMO_HEADER_SUCCESS") {
							dispatch(
								addCreditMemoLine(
									token,
									lines.map((item) => {
										delete item.account_id;
										delete item.id;
										delete item.item_name;
										return {
											...item,
											memo_header_id: id
										};
									})
								)
							)
								.then((resline) => {
									if (resline.type === "ADD_CREDIT_MEMO_LINE_SUCCESS") {
										notification("success", "Credit", "Credit Memo created successfully!");
										// notification("success", "Bill Created", res?.payload?.data);
										setLoading(false);
										setDisable(false);
										history.push({
											pathname: `/credit-memo/edit/${id}`,
											search: `?_id=${id}`
										})

									} else if (res.type === "ADD_CREDIT_MEMO_LINE_ERROR") {
										Swal.fire({
											icon: "error",
											title: "Oops...",
											text: res?.payload?.data?.data,
										});
									}
								});
						} else if (res.type === "ADD_CREDIT_MEMO_HEADER_ERROR") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.data,
							});
						}
					})

				}
				else {
					setLoading(false);
					setDisable(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "select atleast one Non Inventory Item",
					});
					return
				}
			} else if (formState === "edit") {
				if (deletedItems.length > 0) {
					dispatch(deleteCreditMemoLine(token, saleOrderID, deletedItems));
				}
				if (deletedMemoItems.length > 0) {
					dispatch(deleteCreditMemoInvoice(token, saleOrderID, deletedMemoItems));
				}
				dispatch(updateCreditMemoHeader(token, values, saleOrderID, parseInt(companyId))).then((res) => {
					if (res.type === "UPDATE_CREDIT_MEMO_HEADER_SUCCESS") {
						dispatch(

							updateCreditMemoLine(
								token,
								lines.map((item) => {
									delete item.deleted_date;
									delete item.deleted_by;
									delete item.last_updated_by;
									delete item.last_updated_date;
									delete item.created_by;
									delete item.created_date;
									delete item.item_name;
									// delete item.gross_amount;
									delete item.memo_header_id;
									return {
										...item,
									};
								}), saleOrderID

							)
						).then((resline) => {
							if (resline.type === "UPDATE_CREDIT_MEMO_LINE_SUCCESS") {
								dispatch(
									updateCreditInvoice(
										token,
										memoInvoice,
										saleOrderID
									)
								).then((res) => {
									if (res.type === "UPDATE_CREDIT_MEMO_INVOICE_SUCCESS") {
										setLoading(false);
										setDisable(false);
										notification("success", "Credit Memo Updated");
									} else if (res.type === "UPDATE_CREDIT_MEMO_INVOICE_ERROR") {
										Swal.fire({
											icon: "error",
											title: "Oops...",
											text: res?.payload?.data?.data,
										});
									}
								});
							} else if (res.type === "UPDATE_CREDIT_MEMO_LINE_ERROR") {
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "UPDATE_CREDIT_MEMO_HEADER_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};

	const handleTab = (key) => {
		if (key === "header") {
			dispatch(getSystemLog(token, { table_name: "ar_credit_memo_header", record_id: saleOrderID }));
		} else if (key === "line") {
			dispatch(getSystemLog(token, { table_name: "ar_credit_memo_lines", record_id: saleOrderID }));
		}
	};


	const deleteItemRow = (record) => {
		const data = [...lines];
		const deleteSource = [...deletedItems];
		deleteSource.push(record);
		// setDeletedExpenseInformation(deleteSource);
		if (data.length > 0) {

			setLines(data.filter((val) => formState === "add" ? (val.id !== record.id) : (val.memo_line_id !== record.memo_line_id)));
			setDeletedItems(deleteSource);

		} else {
			const data = [...visibleItems];
			setLines(data.filter((item) => item.memo_line_id !== record.memo_line_id));
		}

	}
	const deleteMemoInvoiceRow = (record) => {
		const data = [...memoInvoice];
		const deleteSource = [...deletedMemoItems];
		deleteSource.push(record);
		setDeletedMemoItems(deleteSource);

		setMemoInvoice(data.filter((val) => val.memo_invoice_id !== record.memo_invoice_id));

	}

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}
	const addLines = () => {
		const data = [...lines];
		const iterateId = Math.floor(Math.random() * 100);
		const newItem = { id: iterateId, rate: 0, quantity: 0, description: '' }
		if (lines.length <= 50) {
			data.push(newItem);
			setLines(data);

		} else {
			notification("error", "Only 50 Lines Item can be Edit");
		}
	}

	const summary = () => {
		let quantity = 0;
		let amount = 0;
		lines.map((v) => {

			quantity += parseInt(v.quantity);
			amount += v.hasOwnProperty("net_amount") ? parseFloat(v.net_amount) : 0;
		})

		return {
			quantity: quantity,
			amount: parseFloat(amount)
		}

	}


	const LinesColumns = [
		{
			title: "No Inventory",
			dataIndex: "item_id",
			render: (text, record, index) => {

				const findCustomer = customerForms && customerForms?.payload?.data;


				return (
					<Bcol lg={6} xxl={8}>
						<div className="firstSectionRo">
							<Form.Item
								labelAlign="left"
							// labelCol={{ span: 6 }}
							>
								<Select
									value={record?.item_id}
									size="small"
									// tabIndex={18}
									autoComplete={"nope"}
									allowClear
									disabled={!(formState === "add" || formState === "edit")}
									loading={arAccount.loading}
									onChange={(e) => {
										let arr = [...lines];
										arr[index].item_id = e;
										setLines(arr);


									}}

								>

									<Option key={22} value={537374}>10001-MARK DOWN ALLOWANCE</Option>
									<Option key={21} value={537375}>10002-COOP ALLOWANCE</Option>
									<Option key={20} value={537376}>10003-DISCOUNT ALLOWANCE</Option>
									<Option key={19} value={537377}>10004-OTHERS</Option>
								</Select>

							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Qty",
			dataIndex: "quantity",
			render: (text, record, index) => {

				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.quantity}
								style={{ width: 250 }}
							>
								<InputNumber
									onChange={(e, i) => {

										if (formState === "add") {
											let arr = [...lines];
											let found = arr.find(i => i.id === record.id);


											if (found) {
												found.quantity = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];
											let found = arr.find(i => i.memo_line_id === record.memo_line_id);


											if (found) {
												found.quantity = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.memo_line_id === found.memo_line_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									value={record?.quantity === "" ? null : record?.quantity}
									tabIndex={18} size="small" className="customInputs" disabled={
										!(formState === "add" || formState === "edit")
										// record.credit
									} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Amounts",
			dataIndex: "gross_amount",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.net_amount}
								style={{ width: 250 }}
							>
								<InputNumber
									onChange={(e) => {
										if (formState === "add") {
											let arr = [...lines];
											let found = arr.find(i => i.id === record.id);
											if (found) {
												found.net_amount = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];

											let found = arr.find(i => i.memo_line_id === record.memo_line_id);
											if (found) {
												found.net_amount = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.memo_line_id === found.memo_line_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									value={record?.net_amount || 0}
									tabIndex={18} size="small" className="customInputs" disabled={
										!(formState === "add" || formState === "edit")
										// record.quantity
									} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Description",
			dataIndex: "description",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.description}
							>
								<Input
									style={{ width: 250 }}
									onChange={(e) => {
										if (formState === "add") {
											let arr = [...lines];

											let found = arr.find(i => i.id === record.id);

											if (found) {
												found.description = e.target.value
											}
											setLinesId(found)
											setLines(arr.map(f => {

												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];

											let found = arr.find(i => i.memo_line_id === record.memo_line_id);

											if (found) {
												found.description = e.target.value
											}
											setLinesId(found)
											setLines(arr.map(f => {

												if (f.memo_line_id === found.memo_line_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}
									}
									value={record?.description === "" ? null : record?.description}
									tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},

		{
			title: "operation",
			render: (record, index) => (
				formState === "add" || formState === "edit" ? (
					<Tooltip title="Delete" color={"white"}>

						<Popconfirm title={`Are you sure to delete the item ${formState === "add" ? record.id : record.memo_line_id}?`} onConfirm={() => deleteItemRow(record)}>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					</Tooltip >
				) : null
			)
		}
	];


	const updateInvoiceAmount = (value, index) => {
		let copyArr = [...memoInvoice];
		copyArr[index].invoice_amount = value;
		setMemoInvoice(copyArr);
	}


	const updatePaymentAmount = (value, index) => {
		let copyArr = [...memoInvoice];
		copyArr[index].payment_amount = value;
		setMemoInvoice(copyArr);
	}



	const memoInvoiceLine = [

		{
			title: "Invoice Header No",
			dataIndex: "invoice_header_no",
		},
		{
			title: "Transaction Type",
			dataIndex: "transaction_type",
		},
		{
			title: "Invoice Amount",
			dataIndex: "invoice_amount",
			render: (text, record, index) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								defaultValue={record?.invoice_amount}
								style={{ width: 250 }}
							>
								<InputNumber
									value={record?.invoice_amount}
									onChange={(e) => {
										updateInvoiceAmount(e, index)

									}}
								/>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Payment Amount",
			dataIndex: "payment_amount",
			render: (text, record, index) => {

				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								style={{ width: 250 }}
							>
								<InputNumber
									value={record.payment_amount}
									onChange={(e, i) => {
										updatePaymentAmount(e, index)
									}}
								/>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "operation",
			render: (record, index) => (
				formState === "add" || formState === "edit" ? (
					<Tooltip title="Delete" color={"white"}>
						<Popconfirm title={`Are you sure to delete the item ${record.memo_invoice_id}`} onConfirm={() => deleteMemoInvoiceRow(record)}>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					</Tooltip >
				) : null
			)
		}
	];

	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const systemCols = [

	]

	const discountAmount = (rate, orderItem = []) => {
		let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
		if (orderItem.length > 0) {
			orderIt = orderItem;
		}

		const sum = orderIt.map((item) => item.rate).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
		let totalAmount = orderIt.map((item) => item.quantity * item.rate);
		if (rate !== null || rate !== undefined) {
			if (orderIt.length > 0) {
				let calculateDiscount = orderIt.map((item) => {
					const totalSum = totalAmount.reduce((accum, value) => {
						return accum + value
					})
					item.discount = numeral((item.quantity * item.rate * numeral((rate / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00").replace(/,/g, '');
					item.discount_percent = numeral((rate / totalSum) * 100).format("0,0.00");
					item.net_amount = item.quantity * item.rate - numeral((item.quantity * item.rate * numeral((rate / totalSum) * 100).format("0,0.00")) / 100).format("0,0.00");
					return item;
				});
				setOrderItems(calculateDiscount);
			}
		}
	};

	const discountPercent = (percent, orderItem = []) => {

		let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
		if (orderItem.length > 0) {
			orderIt = orderItem;
		}
		if (percent !== null || percent !== undefined) {
			if (orderIt.length > 0) {
				let calculateDiscount = orderIt.map((item, index) => {
					item.discount_percent = numeral(percent).format("0");
					item.discount = numeral(((item.quantity * item.rate) * percent) / 100).format("0,0.00").replace(/,/g, '');
					item.net_amount = numeral((item.quantity * item.rate) - (((item.quantity * item.rate) * percent) / 100) +
						(((item.quantity * item.rate) * item.tax_percent) / 100)).format("0.00");
					return item;
				});
				setOrderItems(calculateDiscount);
			}
		}
	};

	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const discount = form?.getFieldValue()?.discount ? form.getFieldValue().discount : 0;
		const discountPercentage = form?.getFieldValue()?.discountPercent ? form.getFieldValue().discountPercent : 0;
		const onlyKey = [...filteredItems].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => { return onlyKey.find((i) => i === f.item_code) });
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...filteredItems];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
			if (discountBasis === "A") {
				if (discount !== null || discount !== undefined) {
					discountAmount(parseFloat(discount).toFixed(0), uniqueOrderItems);
				}
			} else if (discountBasis === "P") {
				if (discountPercentage !== null || discountPercentage !== undefined) {
					discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
				}
			}
		} else {
			//remove Duplicates
			let uniqueOrderItems = [...visibleItems, ...filteredItems];
			uniqueOrderItems = uniqueOrderItems.filter(
				(v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
			);
			setOrderItems(uniqueOrderItems);

			if (discountBasis === "A") {
				if (discount !== null || discount !== undefined) {
					discountAmount(discount, uniqueOrderItems.map((item) => { return item; })
					);
				}
			} else if (discountBasis === "P") {
				if (discountPercentage !== null || discountPercentage !== undefined) {
					discountPercent(parseFloat(discountPercentage).toFixed(0), uniqueOrderItems);
				}
			}
		}
		notification("success", "Item Added Successfully");
	};

	const addedExpense = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (expenseItems.length > 0) {
			let uniqueOrderItems = [...expenseItems, ...value];
			//remove Duplicates
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.account_id === v.account_id) === i);
			setExpenseItems(uniqueOrderItems);
		} else {
			//remove Duplicates
			let uniqueOrderItems = [...visibleExpenseItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.account_id === v.account_id) === i);
			setExpenseItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};



	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			setLoading(false)
			if (r.payload.data.length > 0) {
				setOrderDate(moment())
				form.setFieldsValue({
					date: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setOrderDate(moment(e))
			}
		});
	}




	return (
		<>
			<EditableTable
				companyId={companyId}
				token={token}
				headerNo={headerNo}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				//   setShowGrid={setShowGrid}
				adjustmentLocation={adjustmentLocation}
				setFilteredItems={setFilteredItems}
				adjLoc={adjLoc}
			/>
			<EditExpenseTable
				token={token}
				companyId={companyId}
				modalValue={isExpenseModalVisible}
				setIsModalVisible={setIsExpenseModalVisible}
				setOrderItems={addedExpense}
				orderItems={expenseItems}
			/>
			{/* {loading && (
				<div className="loaderDiv">
					<Loader type="Grid" color="#212121" height={40} width={40} />
				</div>
			)} */}
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>

							<Bcol md={12} xl={12} xxl={10} className="pbpx-10">

								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="creditMemo" label="Credit Memo" labelAlign="left" labelCol={{ span: 5 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Date is required" }]}>

												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().date
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id });
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													showSearch
													allowClear
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													// placeholder="Select Gl Period"
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												labelAlign="left"
												name="customer"
												label="Customer"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Customer is required" }]}
											>
												<Select
													notFoundContent={customerForms?.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Loader type="Oval" color="#3c4b58" height={20} width={20} />
													</div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													disabled={!(formState === "add" || formState === "edit")}
													optionFilterProp="children"
													className="filter-fields"
													// onSelect={(e) => onSelectCustomer(e)}
													loading={customerForms?.loading}
													// placeholder="Select Customer"
													onSearch={(value) => {
														optimizedFn(value)
													}}
													ref={focusInput}
													value={customer}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customerForms?.payload?.data?.map(
														(item) => {
															return (
																<Option
																	key={item.customer_id}
																	value={item.customer_id}
																>
																	{`${item.customer_code} ${item.customer_name}`}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>

										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Payment Terms" name="paymentTerm" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().paymentTerm} rules={[{ required: true, message: "Payment Term is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().paymentTerm}
													// placeholder="Select Payment Term"
													onChange={(value) => {
														form.setFieldsValue({
															paymentTerm: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{paymentterm?.payload?.data.map((item) => {
														return (
															<Option key={item.term_id} value={item.term_id}>
																{item.term_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }}>
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="gender"
												label="Gender"
												labelAlign="left"
												labelCol={{ span: 5 }}

												rules={[{ required: true, message: "Gender is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().gender}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>

													{
														gender?.payload?.data.map((val) => {
															return (
																<Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
															)
														})
													}

												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="sale_person"
												label="Sale Person"
												labelAlign="left"
												labelCol={{ span: 5 }}

											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().sale_person}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>

													{
														salePersonForm?.payload?.data.map((val, index) => {
															return (
																<Option key={val.sales_person_id} value={val.sales_person_id}>{val.salesperson_name}</Option>
															)
														})
													}

												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="slc">
											<Form.Item
												name="arAccount"
												label="A/R Account"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "AR Account is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													className="styleInput w-100"
													defaultValue={form.getFieldValue().arAccount}
													disabled={!(formState === "add" || formState === "edit")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>

													{
														arAccount?.payload?.data.map((val) => {
															return (
																<Option key={val.account_id} value={val.account_id}>{val.account_name}</Option>
															)
														})
													}

												</Select>
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Credit Memo Summary</div>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Quantity:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													{summary().quantity}
												</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Amount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">
													${numeral(summary().amount).format("00.00")}
												</p>
											</Bcol>
										</Brow>





									</div>
								</div>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<SubTitles name={"Classification"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Bcol md={12} xl={9} xxl={10}>
							<Brow>
								<Bcol lg={6} xxl={4}>
									<div className="firstSectionRo">
										<Form.Item
											name="subsidiary"
											label="Subsidiary"
											labelAlign="left"
											labelCol={{ span: 5 }}
											rules={[{ required: true, message: "Subsidiary Id is required" }]}
										>
											<Select
												size="small"
												autoComplete={"nope"}
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												showSearch
												allowClear
												loading={companyAll.loading}
												disabled={!(formState === "add" || formState === "edit")}
												defaultValue={form.getFieldValue().companyId}
												// placeholder="Select Subsidiary"
												onChange={(value) => handleChange(value)}
												filterSort={(optionA, optionB) =>
													optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Bcol>
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Credit" key="1">
							<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={addLines}>
								Non Inventory Item
							</Button>
							{(lines.length > 0) && (
								<Table
									type={"company"}
									loading={creditMemoLine?.loading}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									style={{ paddingTop: 10 }}
									// dataSource={formState === "add" ? lines : lineItems}
									dataSource={lines}
									// dataSource={lines.map((it) => ({ ...it, key: it.account_id }))}
									columns={LinesColumns}
									pagination={false}
								/>
							)}

						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="Credit Memo Invoice" key="credit_Memo_Invoice" >
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										// dataSource={memoInvoice}
										source={memoInvoice}
										col={memoInvoiceLine}
										load={creditMemoInvoice.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<Tabs
									type="card"
									size="small"
									defaultActiveKey="1"
									centered
									onChange={(key) => handleTab(key)}
								>
									<TabPane tab="Header" key="header">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
									<TabPane tab="Line" key="line">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
								</Tabs>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact" >
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact.loading}
										paginate
									/>
								</div>
							</TabPane>}


					</Tabs>
				</div>
			</Form>
		</>
	);
}
