import {
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR,
    ADD_COUNTRY_REQUEST, ADD_COUNTRY_SUCCESS, ADD_COUNTRY_ERROR,
    DELETE_COUNTRY_ERROR, DELETE_COUNTRY_REQUEST, DELETE_COUNTRY_SUCCESS,
    UPDATE_COUNTRY_ERROR, UPDATE_COUNTRY_REQUEST, UPDATE_COUNTRY_SUCCESS

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


///GET CUSTOMER TYPE
export const loadCountry = (token) => dispatch => {
    dispatch({ type: GET_COUNTRY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminCountry`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_COUNTRY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_COUNTRY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_COUNTRY_ERROR,
                payload: error.response,
            });
        })
};



export const addCountry = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_COUNTRY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminCountry`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_COUNTRY_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: ADD_COUNTRY_ERROR,
                payload: error.response,
            });
        });
};



export const updateCountry = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_COUNTRY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminCountry/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_COUNTRY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_COUNTRY_ERROR,
                payload: error.response,
            });
        });
};


export const deleteCountry = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_COUNTRY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminCountry/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_COUNTRY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_COUNTRY_ERROR,
                payload: error.response,
            });
        });
};

