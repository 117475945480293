import React, { useState } from 'react'
import "../OMSBooking/styleSheet.css"
import { Select, DatePicker, Form, Table, Spin, Button } from 'antd';
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react'
import { getRegion } from '../../redux/actions/region.action'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getGender } from '../../redux/actions/gender.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { getOmsReportByCustomer } from '../../redux/actions/omsReportByCustomer'
import { getOmsReturnReportByCustomer } from '../../redux/actions/omsReturnByCustomerRegionState.action'
import { getReportIP } from '../../redux/actions/reportIP.action';
import { getSaleReturnOmsReportByCustomer } from "../../redux/actions/getSaleReturnOmsCustomerbyReport.action"
import numeral from "numeral";
import moment from 'moment';
import { getCustomerType } from '../../redux/actions/customerType.action';

const { Option } = Select;
const dateFormatList = ['DD-MM-YYYY'];
const OmsReportByCustomer = ({ token, id, reportTittle, reportName }) => {
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const paramsData = useParams();
    // const [sDate, setSDate] = useState(moment().format("YYYY-MM-DD"))
    // const [eDate, setEDate] = useState(moment().format("YYYY-MM-DD"))
    const [data, setData] = useState([]);
    const { gender, region, companyAll, omsReportByCustomer, getSaleOmsReturnCustomer, customerTypes } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)
    const [reportIP, setReportIP] = useState("");
    const history = useHistory();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }



    let query = useQuery();
    let check = query.get("submited")
    let company_id = query.get("company_id")
    // let date_type = query.get("date_type")
    let gender_id = query.get("gender_id")
    let customer_type_id = query.get("customer_type_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let region_name = query.get("region_name")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let series = query.get("series")
    let select = query.get("select")
    let state_id = query.get("state_id")
    let customer_id = query.get("customer_id")
    let customer_name = query.get("customer_name")
    let state_name = query.get("state_name")



    useLayoutEffect(() => {

        dispatch(getReportIP(token)).then((res) => {
            setReportIP(res.payload.data[0].report_server_ip);
        });
        setValues(history?.location?.values)
        setLoading(true)



        // dispatch(getOmsReturnReportByCustomer(token, dataPayload)).then((res) => {
        dispatch(getOmsReturnReportByCustomer(token, { id, company_id, 
            // date_type, 
            gender_id, from_date, from_date, to_date, customer_type_id, region_id, selected_Region, select, customer_id, state_id })).then((res) => {
            setData(res?.payload?.data)
            setSubmit(true)
            setLoading(false)
        });

    }, []);

    // const paramValues = {
    //     from_date: "2022-01-01",
    //     to_date:"2022-10-13",
    //     gender_id : null,
    //     region_id: 6,
    //     customer_type_id:0,
    //     state_id: 25,
    //     customer_id: 944869,
    //     company_id:2
    // }








    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId))
        dispatch(getAllCompany(token));
    dispatch(getCustomerType(token));
        dispatch(getSaleReturnOmsReportByCustomer(token, { from_date, to_date, gender_id, selected_id, customer_type_id, state_id, customer_id, company_id }))
        // dispatch(getSaleReturnOmsReportByCustomer(token, paramValues))
    }, [dispatch, menuId, token])

    const handleChange = (value) => {

    };
    const columns = [
        {
            title: "S#",
            dataIndex: "serial_no",
            key: 'serial_no',
            align: 'left',
            width: 50,
            render: (text, object, index) => index + 1,

        },

        {
            title: 'Invoice #',
            dataIndex: 'invoice_header_no',
            key: 'invoice_header_no',
            align: 'left',
            width: 70,
            render: (record, text) => {
                return <p
                    style={{ marginBottom: "0px" }}
                //  target="_blank" rel="noopener noreferrer"
                // to={{
                //     pathname: `/sales-report`,
                //     search: `?order_header_id=${text.order_header_id}`
                // }
                // }
                > {record}</ p >
            }

        },
        {
            title: 'PO#',
            dataIndex: 'po_no',
            key: 'po_no',
            align: 'left',
            width: 80,
        },
        {
            title: 'Invoice Date',
            render: (text, record) => {
                return <>{moment(record.invoice_date).format("MM-DD-YY")}</>
            },
            align: 'left',
            width: 100,
        },
        {
            title: 'CR#',
            dataIndex: 'note_header_no',
            key: 'note_header_no',
            align: 'left',
            width: 120,
        },
        {
            title: 'CR Date',
            render: (text, record) => {
                return <>{moment(record.note_date).format("MM-DD-YY")}</>
            },
            align: 'left',
            width: 120,
        },
        {
            title: 'Ref#',
            dataIndex: 'return_header_no',
            key: 'return_header_no',
            align: 'left',
            width: 90,
            // sorter: (a, b) => a.salesperson_name - b.salesperson_name,

        },
        {
            title: 'RA Date',
            render: (text, record) => {
                return <>{moment(record.return_date).format("MM-DD-YY")}</>
            },
            align: 'left',
            width: 90
        },

        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 90,
            align: "right",
            render: (text) => {
                return (
                  <span style={{ textAlign: "right" }}> {numeral(text).format('0,0')}</span>
                )
              },
            sorter: (a, b) => a.quantity.localeCompare(b.quantity),

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 90,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}>$ {numeral(text).format("0,0.00")}</span >,
            // sorter: (a, b) => a.net_amount.localeCompare(b.amount),
        },
        {
            title: "AUC",
            dataIndex: "auc",
            key: "auc",
            width: 120,
            align: "right",
            render: (text) => <span style={{ textAlign: "right" }}>${parseFloat(text).toFixed(2)}</span>,
          },
        {
            title: 'Sold by',
            dataIndex: 'sold_by',
            key: 'sold_by',
            width: 170,
            align: "right",
            // render: text => <span style={{ textAlign: "right" }}> {text}</span >,
            // sorter: (a, b) => a.net_amount.localeCompare(b.amount),
        },

    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id)
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(region_id)
    })

    const filterCustomerType = customerTypes.payload?.data.find((item) => {
        return item.customer_type_id === Number(customer_type_id);
    })
    //    

    const totalSum = () => {
        let sumAmount = getSaleOmsReturnCustomer && getSaleOmsReturnCustomer?.payload?.data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount.replace(/\$|,/g, ''));
        }, 0);
        return sumAmount;
    }

    const totalQuantity = () => {
        let sumQty = getSaleOmsReturnCustomer && getSaleOmsReturnCustomer?.payload?.data.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity.replace(/\$|,/g, ''));
        }, 0);
        return sumQty;
    }



    return (
        <>
            <div className='row report-wrapper-main mb-3'>
                <div className='col p-0'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                </td>
                                <td width="103">
                                    State:
                                </td>
                                <td>
                                    {state_name}
                                    {/* {select === "true" ? "All" : state_name} */}
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Payment Term:
                                </td>
                                <td>
                                {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}

                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    {region_name}
                                    {/* {region_id === "All" ? "All" : filterRegion && filterRegion.region_name} */}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    {from_date}

                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    {to_date}
                                </td>
                            </tr>
                            <tr>
                                {/* <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td>
                                <td>
                                    {date_type === "B" ? "Booking Date" : "Start Date"}
                                </td> */}
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    {company_id === "3" ? "WARP WEFT" : ""}
                                </td>
                            </tr>
                        </tbody >
                    </table >

                    <table className="tablestyleControls" style={{ marginTop: "5px" }}>
                        <tbody>
                            <tr >
                                <td >
                                    Selected Customer:
                                </td>
                                <td style={{ fontWeight: "bolder" }} rowSpan={"15"}>
                                    {customer_name}
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin />
                </div> :
                <>{(submit) ?
                    <div className='maxWidth mt-3'>
                        <Table
                            showSorterTooltip={false}
                            // scroll={{ x: 200, y: 420 }}
                            className='w-100 test04'
                            size='small'
                            pagination={{ pageSize: 1000 }}
                            summary={pageData => {
                                let sumQty = pageData && pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.quantity)
                                }, 0);
                                let sumAmount = pageData && pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.amount)
                                }, 0);
                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{
                                                    fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                    borderBottom: "1px solid black"
                                                }}>{numeral(sumQty).format("0,0")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}> $ {numeral(sumAmount).format("0,0.00")}</p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            dataSource={getSaleOmsReturnCustomer && getSaleOmsReturnCustomer?.payload?.data}
                            sortDirections={["ascend", "descend", "ascend"]}
                        />
                    </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                        fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                    }} >
                        <tbody>
                            <tr style={{
                                backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                            }} >
                                <td colSpan="5">
                                    No record(s)</td>
                            </tr>
                        </tbody>
                    </table>}
                </>
            }
        </>
    )
}
export default OmsReportByCustomer;
