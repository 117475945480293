import {
  GET_SHIPPING_ERROR, GET_SHIPPING_REQUEST, GET_SHIPPING_SUCCESS, ADD_SHIPPING_REQUEST, ADD_SHIPPING_SUCCESS, ADD_SHIPPING_ERROR,
  DELETE_SHIPPING_REQUEST, DELETE_SHIPPING_SUCCESS, DELETE_SHIPPING_ERROR, UPDATE_SHIPPING_REQUEST, UPDATE_SHIPPING_SUCCESS, UPDATE_SHIPPING_ERROR,
  GET_CUSTOMER_SINGLE_SHIPPING_REQUEST, GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS, GET_CUSTOMER_SINGLE_SHIPPING_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js"

const axios = require("axios");

export const getShipping = (token, id) => dispatch => {

  dispatch({ type: GET_SHIPPING_REQUEST });

  return axios({
    method: "GET",
    url: `${BASEURL}getOne/customerShip/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    }
  })
    .then(response => {
      return dispatch({ type: GET_SHIPPING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (error.response.data.data === "No Record Found !") {
        return dispatch({
          type: GET_SHIPPING_ERROR,
          payload: [],
        });
      }
      return dispatch({
        type: GET_SHIPPING_ERROR,
        payload: error.response,
      });
    })
}

export const addShipper = (token, data, type) => (dispatch) => {

  dispatch({ type: ADD_SHIPPING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/customerShip`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: type === "edit" ? data : { customers: data }

    // data: { customers: data }
    // data: { ...data[0], customer_id: id }
  })
    .then((response) => {
      return dispatch({ type: ADD_SHIPPING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_SHIPPING_ERROR,
        payload: error.response,
      });
    });
};


export const deleteShipper = (token, id) => dispatch => {
  dispatch({ type: DELETE_SHIPPING_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/customerShip/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
  })
    .then(response => {

      return dispatch({ type: DELETE_SHIPPING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SHIPPING_ERROR,
        payload: error.response,
      });
    })
}

export const updateShipper = (token, data, id) => dispatch => {
  dispatch({ type: UPDATE_SHIPPING_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customerShip/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: {
      is_default: data.defaults,
      addressee: data.addressee,
      address_1: data.address_1,
      address_2: data.address_2,
      address_3: data.address_3,
      city_id: data.city,
      country_id: data.country,
      state_id: data.state,
      email: data.email,
      fax: data.fax,
      phone: data.phone,
      zip_code: data.zip_code,
    },
  })
    .then(response => {
      return dispatch({ type: UPDATE_SHIPPING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_SHIPPING_ERROR,
        payload: error.response,
      });
    })
}

export const getSingleShipping = (token, id) => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_SINGLE_SHIPPING_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/shipTo/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS, payload: response.data });

    })
    .catch((error) => {
      return dispatch({
        type: GET_CUSTOMER_SINGLE_SHIPPING_ERROR,
        payload: error.response,
      });
    });
};