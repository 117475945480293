import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getAdminDiscount, addAdminDiscount, deleteAdminDiscount, updateAdminDiscount } from "../../redux/actions/adminDiscount.action";
import {  getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import { logColumns, adminDiscountColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;

export default function AdminDiscount({ token }) {
    TabTitle("Admin Discount")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const adminDiscountNameInput = useRef(null);
    const [form] = Form.useForm();
    const [adminDiscountObj, setadminDiscountObj] = useState(null);
    const dispatch = useDispatch();
    const { adminDiscount, log, userRights } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [adminDiscountName, setadminDiscountName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');

    useEffect(() => {
        dispatch(getAdminDiscount(token, menuId));
        dispatch(getLog(token, { admin_discount: "admin_discount"}))
      }, [token]);
    
     useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (adminDiscountObj) {
            setFormState('view')
            form.setFieldsValue({
                id: adminDiscountObj.discount_id,
                adminDiscountType: adminDiscountObj.discount_name,
                active: adminDiscountObj.is_active
            });
            setadminDiscountName(adminDiscountObj.discount_name)
            setCheck(adminDiscountObj.is_active)
            dispatch(getCompanyAccess(token, adminDiscountObj.discount_id, menuId))

        }
    }, [adminDiscountObj]);

    useEffect(() => {
        if (adminDiscountName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [adminDiscountName])

    const handleTab = (key) => {
        if (key === "logs") {
            dispatch(getLog(token, { admin_discount: "admin_discount"}))
        }
    }


    const formSave = () => {
        if (formState === "add") {
            dispatch(addAdminDiscount(token, { adminDiscountName, check }, menuId)).then((res) => {

                if (res.type === "ADD_ADMIN_DISCOUNT_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_ADMIN_DISCOUNT_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    notification("success", "Admin Discount Created", "Admin Discount Created Successfully!");
                    form.setFieldsValue({
                        adminDiscountType: ""
                    })
                    setFormState("")
                    setadminDiscountName("")
                    setadminDiscountObj(null)
                }
                dispatch(getAdminDiscount(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updateAdminDiscount(token, { adminDiscountName, check }, adminDiscountObj.discount_id)).then((res) => {
                if (res.type === "UPDATE_ADMIN_DISCOUNT_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_ADMIN_DISCOUNT_SUCCESS") {
                    notification("success", "Admin Discount Updated", "Record Updated Successfully")
                }
                dispatch(getAdminDiscount(token, menuId))
            })


        }


    }

    const handleDelete = () => {
        if (adminDiscountObj) {
            dispatch(deleteAdminDiscount(token, adminDiscountObj.discount_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_DISCOUNT_SUCCESS") {
                    notification("success", "Admin Discount Deleted", "Admin Discount Deleted Successfully!");
                }
                if (res.type === "DELETE_ADMIN_DISCOUNT_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    adminDiscountType: ""
                })
                setadminDiscountName("")
                setadminDiscountObj(null)
                setFormState('')
                dispatch(getAdminDiscount(token, menuId))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            adminDiscountNameInput.current.focus();
            form.setFieldsValue({
                adminDiscountType: ""
            })
            setCheck(true)
            setadminDiscountName("")
            setadminDiscountObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Admin Discount"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="adminDiscount" adminDiscountObj={setadminDiscountObj} scrollX={"20rem"} scroll={602} perPage={25} source={adminDiscount?.payload?.data} col={adminDiscountColumns} paginate load={adminDiscount?.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 12 }}
                                        >
                                             <div className='customRow styleFormUpper ' >
                                            {adminDiscountObj !== null && !(formState === "add") &&
                                            <div className='customCols slc'>
                                                <Form.Item
                                                    label="ID"
                                                    name="id"
                                                    labelCol={{ span: 10 }}
                                                    wrapperCol={{ span: 14 }}
                                                    initialValue={form.getFieldValue().id}
                                                >
                                                    <Input  size='small' className='styleInput id-field-style' readOnly />

                                                </Form.Item>
                                                </div>
                                            }
                                            <div className='customCols slc'>
                                            <Form.Item
                                                label="Admin Discount Type"
                                                name="adminDiscountType"
                                                labelCol={{ span: 12 }}
                                                wrapperCol={{ span: 14 }}
                                                initialValue={form.getFieldValue().adminDiscountType}
                                            >

                                                <Input  size='small'  disabled={!(formState === "add" || formState === "edit")} ref={adminDiscountNameInput} onChange={(e) => setadminDiscountName(e.target.value)} className='styleInput' />
                                            </Form.Item>
                                            </div>
                                            <div className='customCols slc'>
                                            <Form.Item
                                                label="Active"
                                                labelCol={{ span: 12 }}
                                                wrapperCol={{ span: 14 }}
                                                name="active"
                                            >
                                                <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                            </Form.Item>
                                            </div>
                                            </div>
                                        </Form>
                                
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                         
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                   
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}