import {
	GET_INVOICE_FORM_DATA_REQUEST, GET_INVOICE_FORM_DATA_SUCCESS, GET_INVOICE_FORM_DATA_ERROR, ADD_INVOICE_HEADER_REQUEST, ADD_INVOICE_HEADER_SUCCESS, ADD_INVOICE_HEADER_ERROR, GET_ALL_INVOICE_HEADER_REQUEST, GET_ALL_INVOICE_HEADER_SUCCESS, GET_ALL_INVOICE_HEADER_ERROR, DELETE_INVOICE_HEADER_REQUEST, DELETE_INVOICE_HEADER_SUCCESS, DELETE_INVOICE_HEADER_ERROR, GET_ONE_INVOICE_HEADER_SUCCESS, GET_ONE_INVOICE_HEADER_REQUEST, GET_ONE_INVOICE_HEADER_ERROR,
	UPDATE_INVOICE_HEADER_REQUEST, UPDATE_INVOICE_HEADER_SUCCESS, UPDATE_INVOICE_HEADER_ERROR, GET_ONE_INVOICE_HEADER_DETAIL_REQUEST, GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS, GET_ONE_INVOICE_HEADER_DETAIL_ERROR, GET_ONE_INVOICE_GL_PERIOD_REQUEST, GET_ONE_INVOICE_GL_PERIOD_SUCCESS, GET_ONE_INVOICE_GL_PERIOD_ERROR,
} from "./types";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getInvoiceHeaderFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_INVOICE_FORM_DATA_REQUEST });
	if (clear) {
		return dispatch({
			type: GET_INVOICE_FORM_DATA_SUCCESS,
			payload: {
				berganPaymentTerm: [],
				termForPrint: [],
				factorDetail: [],
				customerDetail: [],
				adminShipTaxCode: [],
				adminShipMethod: [],
				shipVia: [],
				customerTerm: [],
				freightTerm: [],
				berganBilling: [],
				salesPersonDetail: [],
				adminRegionDetail: [],
				orderStatusDetail: [],
				gender: [],
				locationDetail: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/shipHeaderFormList`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token }
	}).then((response) => {
		return dispatch({
			type: GET_INVOICE_FORM_DATA_SUCCESS,
			payload: response.data,
		});
	}).catch((error) => {
		return dispatch({
			type: GET_INVOICE_FORM_DATA_ERROR,
			payload: error.response,
		});
	});
};

export const getOneInvoiceHeaderDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_INVOICE_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/getInvoiceDetail/${id}`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token }
	}).then((response) => {
		return dispatch({
			type: GET_ONE_INVOICE_HEADER_SUCCESS,
			payload: response?.data,
		});
	}).catch((error) => {
		return dispatch({
			type: GET_ONE_INVOICE_HEADER_ERROR,
			payload: error.response,
		});
	});
};

export const getOneInvoiceGlPeriod = (token) => (dispatch) => {
	dispatch({ type: GET_ONE_INVOICE_GL_PERIOD_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/glPeriod`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token }
	}).then((response) => {
		return dispatch({
			type: GET_ONE_INVOICE_GL_PERIOD_SUCCESS,
			payload: response?.data,
		});
	}).catch((error) => {
		return dispatch({
			type: GET_ONE_INVOICE_GL_PERIOD_ERROR,
			payload: error.response,
		});
	});
};

export const getOneInvoiceHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_INVOICE_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/invoiceHeader/${id}`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token }
	}).then((response) => {
		return dispatch({
			type: GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS,
			payload: response?.data?.data,
		});
	}).catch((error) => {
		return dispatch({
			type: GET_ONE_INVOICE_HEADER_DETAIL_ERROR,
			payload: error.response,
		});
	});
};

export const addInvoiceHeader = (token, values, formData, items) => (dispatch) => {

	let newData = {
		order_header_id: formData.order_header_id,
		ship_header_id: formData.ship_header_id,
		sales_person_id: formData.sales_person_id,
		billing_address_id: formData.billing_address_id,
		customer_id: formData.customer_id,
		location_id: formData.location_id,
		company_id: formData.company_id,
		shipping_cost: values.shippingCost,
		// company_id: formData.company_id,
		// remarks: dbData.remarks,
		remarks: values.remarks,
		memo: values.memo,
		InvoiceLine: items,
		gl_period_id: values.glPeriodId,
		payment_term_id: values.paymentTermId,
		invoice_date: moment(values.invoiceDate).subtract(1, 'days').format("MM-DD-YYYY"),
		edi: formData.edi,
		due_date: moment(values.dueDate).subtract(1, 'days').format("MM-DD-YYYY"),
	};
	dispatch({ type: ADD_INVOICE_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/invoiceHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_INVOICE_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_INVOICE_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateInvoiceHeader = (token, id, formData) => (dispatch) => {
	let newData = {
		payment_term_id: formData.paymentTermId,
		location_id: formData.location,
		gl_period_id: formData.glPeriodId,
		shipping_cost: formData.shippingCost,
		invoice_date: moment(formData.invoiceDate).format("MM-DD-YYYY"),
		edi: formData.edi,
		memo: formData.memo,
		due_date: moment(formData.dueDate).format("MM-DD-YYYY"),
	};
	dispatch({ type: UPDATE_INVOICE_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/invoiceHeader/${id}`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token },
		data: newData,
	}).then((response) => {
		return dispatch({
			type: UPDATE_INVOICE_HEADER_SUCCESS,
			payload: response.data,
		});
	}).catch((error) => {
		return dispatch({
			type: UPDATE_INVOICE_HEADER_ERROR,
			payload: error.response,
		});
	});
};

export const getAllInvoiceHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_INVOICE_HEADER_REQUEST });
	
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/invoicelist`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token },
		data:
		// {
		// 	invoice_header_id: null,
		// 	invoice_no: data.invoiceNo === "" ? null : data.invoiceNo,
		// 	customer_name: data.customer === "" ? null : data.customer,
		// 	po: data.po === "" ? null : data.po,
		// 	order_number: data.orderNumber === "" ? null : data.orderNumber,
		// 	posting_period: data.postingPeriod === "" ? null : data.postingPeriod,
		// 	ship_header_no:data.shipHeaderId === "" ? null : data.shipHeaderId,
		// 	from_date: moment(data.from_date).format("MM-DD-YYYY"),
		// 	to_date: moment(data.to_date).format("MM-DD-YYYY"),
		// 	sales_person_name: data.saleperson_name === "" ? null : data.saleperson_name,
		// 	region_name: data.region === "" ? null : data.region,
		// 	gender_name:data.gender === "" ? null : data.gender,
		// 	term_name:data.term === "" ? null : data.term,
		// },
		{
			invoice_header_id: null,
			invoice_header_no: data.invoiceNo === "" ? null : data.invoiceNo,
			customer_code: data.customer === "" ? null : data.customer,
			ship_header_no: data.shipHeaderId === "" ? null : data.shipHeaderId,
			period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			po_no: data.po === "" ? null : data.po,
			order_header_no: data.orderNumber === "" ? null : data.orderNumber,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			sales_person_name: data.saleperson_name === "" ? null : data.saleperson_name,
			region_name: data.region === "" ? null : data.region,
			gender_name: data.gender === "" ? null : data.gender,
			term_name: data.term === "" ? null : data.term
		}







	}).then((response) => {
		return dispatch({
			type: GET_ALL_INVOICE_HEADER_SUCCESS,
			payload: response.data,
		});
	}).catch((error) => {
		return dispatch({
			type: GET_ALL_INVOICE_HEADER_ERROR,
			payload: error.response,
		});
	});
};




export const deleteInvoiceHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_INVOICE_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/invoiceHeader/${id}`,
		headers: { Accept: "application/json", Authorization: "Bearer " + token },
	}).then((response) => {
		return dispatch({
			type: DELETE_INVOICE_HEADER_SUCCESS,
			payload: response?.data.data,
		});
	}).catch((error) => {
		return dispatch({
			type: DELETE_INVOICE_HEADER_ERROR,
			payload: error.response,
		});
	});
};
