import {
	ADD_PURCHASE_BILL_ERROR,
	ADD_PURCHASE_BILL_REQUEST,
	ADD_PURCHASE_BILL_SUCCESS,
	ADD_CREATE_BILL_REQUEST,
	ADD_CREATE_BILL_SUCCESS,
	ADD_CREATE_BILL_ERROR
	// DELETE_ITEM_TRANSFER_HEADER_ERROR,
	// DELETE_ITEM_TRANSFER_HEADER_REQUEST,
	// DELETE_ITEM_TRANSFER_HEADER_SUCCESS,
	// GET_ALL_ITEM_TRANSFER_ERROR,
	// GET_ALL_ITEM_TRANSFER_REQUEST,
	// GET_ALL_ITEM_TRANSFER_SUCCESS,
	// GET_ITEM_TRANSFER_FORM_DATA_ERROR,
	// GET_ITEM_TRANSFER_FORM_DATA_REQUEST,
	// GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
	// GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR,
	// GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST,
	// GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS,
	// GET_ONE_ITEM_TRANSFER_HEADER_ERROR,
	// GET_ONE_ITEM_TRANSFER_HEADER_REQUEST,
	// GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS,
	// UPDATE_ITEM_TRANSFER_HEADER_ERROR,
	// UPDATE_ITEM_TRANSFER_HEADER_REQUEST,
	// UPDATE_ITEM_TRANSFER_HEADER_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const addPurchaseBill = (token, billNumber, dDate, rate, via, LocationId) => (dispatch) => {
	console.log(billNumber, dDate, rate, via, LocationId, "formData");
	dispatch({ type: ADD_PURCHASE_BILL_REQUEST });
	let newData = {
		bill_no: billNumber,
		delivery_date: moment(dDate).format("MM-DD-YYYY"),
		rateType: rate,
		via: via,
		location: LocationId,

	};
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/getBills`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_PURCHASE_BILL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_PURCHASE_BILL_ERROR,
				payload: error.response,
			});
		});
};
export const addCreateBill = (token, data) => (dispatch) => {

	dispatch({ type: ADD_CREATE_BILL_REQUEST });
	const newData = data.map((item) => {
		return {
			Product2: item.Product2, ActualSizeQty: item.ActualSizeQty, Rate: item.Rate, amount: item.AMOUNT, account_id: 865, delivery_date: item.delivery_date, bill_date: item.Invoice_Date, reference_no: item.Invoice_ID.toString(), remarks: item.Invoice_ID.toString(), vendor_id: 100000028, currency: 1, ship_via: item.VIA === "AIR" ? 1 : 2, remarks: item.Invoice_ID, f_transaction: item.Invoice_ID, d_transaction: null, is_approved: "A", company_id: 3, location_id: item.location_id
		};
	})

	return axios({
		method: "POST",
		url: `${BASEURL}create/createBill`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_CREATE_BILL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_CREATE_BILL_ERROR,
				payload: error.response,
			});
		});
};

// export const updateItemTransferHeader = (token, formData, itemTransferId) => (dispatch) => {
// 	let newData = {
// 		asn_no: formData.asnNo,
// 		transfer_date: moment(formData.transferDate).format("MM-DD-YYYY"),
// 		gl_period_id: formData.postingPeriod,
// 		from_location_id: formData.fromLocation,
// 		ship_via: formData.shipVia,
// 		to_location_id: formData.toLocation,
// 		memo: formData.memo,
// 		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
// 	};
// 	dispatch({ type: UPDATE_ITEM_TRANSFER_HEADER_REQUEST });
// 	return axios({
// 		method: "PUT",
// 		url: `${BASEURL}update/iNVItemTransferHeader/${itemTransferId}`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 		data: newData,
// 	})
// 		.then((response) => {

// 			return dispatch({
// 				type: UPDATE_ITEM_TRANSFER_HEADER_SUCCESS,
// 				payloadUpdate: response?.data?.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: UPDATE_ITEM_TRANSFER_HEADER_ERROR,
// 				payloadUpdate: error.response,
// 			});
// 		});
// };

// export const getAllItemTransferHeader = (token, data) => (dispatch) => {
// 	dispatch({ type: GET_ALL_ITEM_TRANSFER_REQUEST });
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}getAll/iNVItemTransferHeader`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 		data: {
// 			transfer_header_no: data.transferNo === "" ? null : data.transferNo,
// 			from_date: moment(data.from_date).format("MM-DD-YYYY"),
// 			to_date: moment(data.to_date).format("MM-DD-YYYY"),
// 			from_location_name: data.fromLocation === "" ? null : data.fromLocation,
// 			to_location_name: data.toLocation === "" ? null : data.toLocation,
// 			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
// 			ship_via: null,
// 			asn_no: data.asn === "" ? null : data.asn,
// 			memo: data.memo === "" ? null : data.memo,
// 			transfer_header_id: null,
// 		},

// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ALL_ITEM_TRANSFER_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ALL_ITEM_TRANSFER_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

// export const getOneItemTransferHeader = (token, id) => (dispatch) => {
// 	dispatch({ type: GET_ONE_ITEM_TRANSFER_HEADER_REQUEST });
// 	return axios({
// 		method: "GET",
// 		url: `${BASEURL}getOne/iNVItemTransferHeader/${id}`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS,
// 				payload: response?.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ONE_ITEM_TRANSFER_HEADER_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

// export const getOneItemTransferHeaderDetail = (token, id) => (dispatch) => {
// 	dispatch({ type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST });
// 	return axios({
// 		method: "GET",
// 		url: `${BASEURL}getOne/iNVItemTransferHeader/${id}`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS,
// 				payload: response?.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

// export const deleteItemTransferHeader = (token, id) => (dispatch) => {
// 	dispatch({ type: DELETE_ITEM_TRANSFER_HEADER_REQUEST });
// 	return axios({
// 		method: "DELETE",
// 		url: `${BASEURL}delete/iNVItemTransferHeader/${id}`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: DELETE_ITEM_TRANSFER_HEADER_SUCCESS,
// 				payload: response?.data.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: DELETE_ITEM_TRANSFER_HEADER_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

// export const getItemTransferFormData = (token, clear) => (dispatch) => {
// 	dispatch({ type: GET_ITEM_TRANSFER_FORM_DATA_REQUEST });

// 	if (clear) {
// 		return dispatch({
// 			type: GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
// 			payload: {
// 				location: [],
// 			},
// 		});
// 	}
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}getAll/iNVItemTransferFormData`,
// 		headers: {
// 			Accept: "application/json",
// 			Authorization: "Bearer " + token,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: GET_ITEM_TRANSFER_FORM_DATA_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: GET_ITEM_TRANSFER_FORM_DATA_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };
