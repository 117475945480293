import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getProductSaleTransfer } from "../../redux/actions/productSaleTransfer.action";

const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid, orderItems, fromLocation }) => {

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { ProductSaleTransfer } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selected, setSelected] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);

	const updateQuantity = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					if (parseInt(value) > parseInt(item.quantity_available)) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Quantity is greater than available quantity",
						});
						item.quantity = item.quantity_available;
						return item;
					} else {
						item.quantity = value;
						item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
						item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
						item.quantity_committed =
							parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
						return item;
					}
				}

				if(parseInt(item.quantity_available) < 0) {
					item.quantity = 0;
					return item;
				}

				if (type === "down") {
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					item.quantity_committed =
						parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
					return item;
				}



			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
		setSelected(false);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	useEffect(() => {
		let arr = [...data];
		arr = [...arr].map((item, i) => {
			if (selectedRowKey.indexOf(item.item_code) === -1) {
				item.isSelected = false;
				return item;
			} else {
				item.isSelected = true;
				return item;
			}
		});
	}, [selectedRowKey]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		dispatch(getProductSaleTransfer(token, companyId, selectedId, fromLocation)).then((res) => {
			if (res.type === "GET_PRODUCTSALETRANSFER_SUCCESS") {

				const arr = res?.payload?.map(items => {
					let found = orderItems.find(f => f.item_id === items.item_id)
					if (found) {
						found.quantity_available = items.quantity_available
						found.size = items.size
						found.gender_category_name = items.gender_category_name
						found.style_name = items.style_name
						found.wash_name = items.wash_name
						found.already = true
						return found
					} else {
						items.commit_status = 1;
						return items
					}
				})

				setData(arr)
			} else if (res.type === "GET_PRODUCTSALETRANSFER_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Select From Location",
				});
			}


		});
	};

	const handleOk = () => {
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
		setShowGrid(true);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].isSelected = true;
				selectedRows[i].quantity = selectedRows[i].hasOwnProperty("quantity") ? selectedRows[i].quantity : 1;
				selectedRows[i].amount = parseFloat(selectedRows[i].quantity * selectedRows[i].rate).toFixed(2);
				selectedRows[i].quantity_backorder = 0;
				selectedRows[i].quantity_committed = 1;
			}
			setSelected(false);
			setSelectedItems(selectedRows);
			setFilteredItems(selectedRows);
			setSelectedRowKey(selectedRowKeys);
		},
		getCheckboxProps: (record) => {

			return { disabled: record.already ? true : false }
		},
	};

	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style Name",
			dataIndex: "style_name",
		},
		{
			title: "Wash Name",
			dataIndex: "wash_name",
		},
		{
			title: "Inseam",
			dataIndex: "inseam",
			width: "10%",
		},
		{
			title: "Rate",
			dataIndex: "rate",
			width: "10%",
		},

		{
			title: "Size",
			dataIndex: "size",
			width: "8%",
		},

		{
			title: "Available Quantity",
			dataIndex: "quantity_available",
		},
		{
			title: "Quantity Committed",
			dataIndex: "quantity_committed",
			width: "10%",
		},
		{
			title: "Quantity On Hand",
			dataIndex: "quantity_on_hand",
			width: "10%",
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						// placeholder="Quantity"
						value={record.quantity}
						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={1}
					/>
				);
			},
		},
		{
			title: "Amount",
			editable: false,
			render: (text, record) => {
				return <>{parseFloat(record.quantity ? record.rate * record.quantity : record.rate).toFixed(2)}</>;
			},
		},
	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: selectedItems.length > 0 ? "" : "none",
				},
			}}
			okText="Add Item"
			destroyOnClose={true}
			width={1200}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
			cancelText={'Close'}
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						// placeholder="Search Item"
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>
				<Table
					size="small"
					loading={ProductSaleTransfer.loading}
					// scroll={({ x: 600 }, { y: 400 })}
					scroll={{ x: "50rem", y: 360 }}
					rowSelection={{ ...rowSelection }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default EditableTable;