import {
  GET_INTRANSIT_REPORT_REQUEST,
  GET_INTRANSIT_REPORT_SUCCESS,
  GET_INTRANSIT_REPORT_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const intransitReport = (token, data) => (dispatch) => {
  dispatch({ type: GET_INTRANSIT_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/Stylewiseintransitreport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      gender_id: data.gender_id === undefined || data.gender_id === "All" ? null : data.gender_id ,
      // gender_category_id: data.gender_category_id === "All" ? null : data.gender_category_id,
      gender_category_id: null,
      sku: data.sku === "All" ? null : data.sku,
      style_id: data.style_id === undefined || data.style_id === "All" ? null : data.style_id,
      // product_status_id: data.product_status_id === "All" ? null : data.product_status_id,
      product_status_id: null,
      season_id: data.current_season_id === undefined || data.current_season_id === "All" ? null : data.current_season_id,
      wash_id: data.wash_id === undefined || data.wash_id === "All" ? null : data.wash_id
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_INTRANSIT_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_INTRANSIT_REPORT_ERROR,
        payload: [],
      });
    });
};
