import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
// import { getAllBillHeader, deleteBillCreditHeader, getOneBillCreditHeader, getBillCreditFormData } from "../../redux/actions/bill.action";
import { deleteBillCreditHeader, getOneBillCreditHeader, getBillCreditFormData } from "../../redux/actions/billCredit.action";
import CrudButtons from "./components/CrudButtons";
// import BillForm from "./components/Form/BillForm";
import BillCreditForm from "./components/Form/BillCreditForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import moment from "moment";
import { getTransationLock } from "../../redux/actions/transactionLock.action";

export default function BillCredit({ token }) {
	TabTitle("BillCredit")
	const dispatch = useDispatch();
	const companyId = ReadCookie("defaultCompany");
	const { log, billCredit } = useSelector((state) => state);
	const history = useHistory();
	const { id } = useParams();
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [flag, setFlag] = useState(false);
	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const [loading, setLoading] = useState(false);
	const startDate = "01-1-2022";
	const endDate = "06-01-2022";
	const [userRights, setUserRights] = useState({})

	const [transactionLock, setTransactionLock] = useState(false);


	useLayoutEffect(() => {
		dispatch(getRights(token, 59)).then((res) => {

			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		setLoading(true);
		dispatch(deleteBillCreditHeader(token, id)).then((res) => {
			if (res.type === "DELETE_BILL_CREDIT_HEADER_SUCCESS") {
				// dispatch(getAllBillHeader(token, startDate, endDate));
				setLoading(false);
				notification("success", "Bill Created Deleted", res.payload);
				setFlag(true);
				history.push("/bill-credit/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
		dispatch(getBillCreditFormData(token));
	}, []);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneBillCreditHeader(token, id)).then((res) => {
				if(res?.type === "GET_ONE_BILL_CREDIT_HEADER_SUCCESS") {
					if (res?.payload?.data[0]?.bill_date) {
						let transaction_date = moment(res?.payload.data[0]?.bill_date).format("YYYY-MM-DD");
						dispatch(getTransationLock(token, transaction_date)).then((r) => {
							if (r.payload.data.length > 0) {
								setTransactionLock(true);
							}
						});
					}
				}
			});
		}
	}, [id]);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Bill Credit"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={id}
								formData={billCredit?.payload?.data}
								transactionLock={transactionLock}
							/>
							<div className="mainProductContainer">
								<BillCreditForm
									loading={loading}
									companyId={companyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "formView" || formState === "edit" ? billCredit?.payload?.data[0] : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									saleOrderID={id}
									transactionLock={transactionLock}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Bill{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
