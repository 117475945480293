import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesReturnHeader, deleteSaleReturnHeader, getOneSaleReturnHeader, getOneSaleReturnHeaderDetail } from "../../redux/actions/salesReturn.action";
import CrudButtons from "./components/CrudButtons";

import SaleReturnForm from "./components/Form/SaleReturnForm";
//import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";
// import Loader from "react-loader-spinner";
// import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import SaleReturn from "../SaleReturn/SaleReturn";
import SaleReturnList from "../SaleReturn/SaleReturnList";
import Loader from "react-loader-spinner";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function ReturnAuthorization({ token }) {
  TabTitle("Return Authorization")
  const { id, customerCode, shipHeaderNo } = useParams();
  const dispatch = useDispatch();
  const dCompanyId = ReadCookie("defaultCompany");

  const { salesReturnFormDataReducer, log, salesReturn, salesReturnList, userType } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const [saleOrderID, setSaleOrderID] = useState(id);
  const [flag, setFlag] = useState(false);
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [ReturnAuthID, setReturnAuthID] = useState(null);
  const [ReturnHeaderData, setReturnHeaderData] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search)
  const mode = queryParams.get("mode");
  const customerID = queryParams.get("customer_id");
  const orderNewID = queryParams.get("order_id");
  const invoice_id = queryParams.get("invoice_id");
  const return_id = queryParams.get("return_id");
  const history = useHistory();
  const startDateList = "01-1-2022"
  const endDateList = "06-01-2022";
  const [userRights, setUserRights] = useState({})

  const [menuId, setMenuId] = useState(56);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        setUserRights(res?.payload)
      }
    });
  }, [token])

  useEffect(() => {
    if (mode === "return" && invoice_id) {
      dispatch(getOneSaleReturnHeaderDetail(token, invoice_id)).then((res) =>
        setReturnHeaderData(res?.payload));
    }
    else if (mode === "edit") {
      dispatch(getOneSaleReturnHeader(token, return_id)).then((res) =>
        setReturnHeaderData(res?.payload)
      )
    }

  }, [mode]);



  useEffect(() => {
    if (mode === 'add') {
      setFormState('add');
    }
    if (mode === "edit") {
      setFormState('formView');
    }
  }, []);


  // useEffect(() => {
  //   if (ReturnAuthID) {
  //     dispatch(getOneSaleReturnHeader(token, ReturnAuthID)).then((res) =>
  //       setReturnHeaderData(res?.payload)
  //     )
  //   }

  //     // dispatch(getOneSaleReturnHeaderDetail(token, return_id)).then((res) =>
  //     //   setReturnHeaderData(res?.payload));

  // }, [ReturnAuthID])

  const filterReset = () => {
    setFormState("view");
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteSaleReturnHeader(token, return_id)).then((res) => {
      if (res.type === "DELETE_SALE_RETURN_HEADER_SUCCESS") {
        setLoading(false);
        // dispatch(getAllSalesReturnHeader(token, startDateList, endDateList));
        notification("success", "Sale Return Header deleted", res.payload);
        setFlag(true);
        history.push('/return-authorization-list')
      }
    });
  };

  const formSave = () => {
    setSubmit(true);
  };

  return (
    <>
      <div className="styleContainer">
        <div className="styleBody" style={minimize ? {} : { height: "100%" }}>
          <PageHeader
            title={"Sale Return"}
            minimize={setMinimize}
            check={minimize}
          />
          <div className="bodyStyle">
            {formState !== "add" ?
              !ReturnHeaderData && formState !== "view" &&
              <div className='loaderDiv' >
                <Loader
                  type="Grid"
                  color="#212121"
                  height={40}
                  width={40}
                />
              </div> :
              null}
            <div className="crudDiv">
              <CrudButtons
                user_type={userType?.payload?.data[0]?.coalesce}
                mode={mode}
                name={"returnAuthorization"}
                reset={filterReset}
                userRights={userRights}
                formType={formState}
                save={formSave}
                return_id={return_id}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
                formData={ReturnHeaderData}
              />
              <div className="mainProductContainer">
                <SaleReturnForm
                  userRights={userRights}
                  invoice_id={invoice_id}
                  setReturnAuthID={setReturnAuthID}
                  name={"returnAuthorization"}
                  ReturnAuthID={ReturnAuthID}
                  return_id={return_id}
                  customerID={customerID}
                  mode={mode}
                  companyId={dCompanyId}
                  submit={setSubmit}
                  val={submit}
                  formData={ReturnHeaderData}
                  formState={formState}
                  data={salesReturnFormDataReducer}
                  setDisable={setDisable}
                  changeState={setFormState}
                  token={token}
                  checkFlag={flag}
                  setFlag={setFlag}
                  logs={log}
                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className="fw-600 b-radius-5"
              onClick={() => setMinimize(false)}
            >
              {" "}
              Sales Order{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}