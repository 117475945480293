import React, { useState } from 'react'
import logo from "../../assets/images/logo-black.svg"
import btnPrint from "../../assets/images/button_print.gif"
import xlsx from "../../assets/images/ico_xls_sm.gif"
import "../OMSBooking/styleSheet.css"
import { Select, DatePicker, Form, Table, Spin, Button, Input } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { useEffect } from 'react'
import { getRegion } from '../../redux/actions/region.action'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getGender } from '../../redux/actions/gender.action'
import { getOmsReport } from '../../redux/actions/omsReport.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { Link, useParams, useLocation } from 'react-router-dom'
import Tables from '../../components/Table/Tables'
import { CSVLink } from "react-csv";
import { Excel } from "antd-table-saveas-excel";
import { getOmsStateReport } from '../../redux/actions/omsRegionStateReport.action'
import { useLayoutEffect } from 'react'
import { getOmsShipStateReport } from '../../redux/actions/omsShipRegionStateReport.action'
import numeral from "numeral";
import { getCustomerType } from '../../redux/actions/customerType.action'
const { Option } = Select;
const OmsRegionStateReport = ({ token, id, propsData, formData }) => {
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const paramsData = useParams();

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const [sDate, setSDate] = useState(moment().format("YYYY-MM-DD"))
    // const [eDate, setEDate] = useState(moment().format("YYYY-MM-DD"))
    const [data, setData] = useState([])
    const { gender, region, companyAll, omsShipRegionStateReport, customerTypes } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)


    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let company_id = query.get("company_id")
    let gender_id = query.get("gender_id")
    let customer_type_id = query.get("customer_type_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let select = query.get("select")
    let region_name = query.get("region_name")

    useLayoutEffect(() => {
        setLoading(true)
        dispatch(getOmsShipStateReport(token, id, { company_id, gender_id, from_date, from_date, to_date, customer_type_id, region_id, selected_Region, select })).then((res) => {
            setSubmit(true)
            setLoading(false)
        });

    }, []);

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId))
        dispatch(getAllCompany(token));
        dispatch(getCustomerType(token));
    }, [dispatch, menuId, token])

    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            align: 'left',
            key: 'serial_no',
            width: 50,
            render: (text, object, index) => index + 1,
            // filterDropdown: ({
            //     setSelectedKeys,
            //     selectedKeys,
            //     confirm,
            //     clearFilters,
            // }) => {
            //     return (
            //         <>
            //             <Input
            //                 allowClear
            //                 autoFocus
            //                 className='pop-search-gen pop-220'
            // placeholder="Type text here"
            //                 value={selectedKeys[0]}
            //                 onChange={(e) => {
            //                     setSelectedKeys(e.target.value ? [e.target.value] : []);
            //                     confirm({ closeDropdown: false });
            //                 }}
            //                 onPressEnter={() => {
            //                     confirm();
            //                 }}
            //                 onBlur={() => {
            //                     confirm();
            //                 }}
            //             />
            //         </>
            //     );
            // },
            // filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            // onFilter: (value, record) => {
            //     if (record.serial_no !== null) {
            //         return record.serial_no.toString().toLowerCase().includes(value.toLowerCase())
            //     }
            // },
            // sorter: (a, b) => {
            //     return a.serial_no - b.serial_no;
            // },
        },
        {
            title: 'Region Name',
            dataIndex: 'region_name',
            key: 'region_id',
            width: 180,
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }}
                    to={{
                        pathname: `/shipped-region-wise`,
                        search: `?submited=${true}&report=${3}&company_id=${company_id}&gender_id=${gender_id}&region_id=${region_id}&customer_type_id=${customer_type_id}&from_date=${from_date}&to_date=${to_date}&select=${true}&selected_id=${selected_id}&state_id=${text.state_id}&state_name=${text.state_name}&region_name=${text.region_name}`,
                    }}
                > {record}</ Link >
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.region_name !== null) {
                    return record.region_name.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.region_name !== null & b.region_name !== null) {
                    return a.region_name.localeCompare(b.region_name)
                }
            },
        },
        {
            title: "State",
            dataIndex: "state_name",
            align: 'left',
            key: "state_id",
            width: 70,
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }}
                    to={{
                        pathname: `/shipped-region-wise`,
                        search: `?submited=${true}&report=${3}&company_id=${formData.company_id}&gender_id=${formData.gender_id}&region_id=${formData.region_id}&customer_type_id=${formData.customer_type_id}&from_date=${moment(formData.from_date).format("MM-DD-YYYY")}&to_date=${moment(formData.to_date).format("MM-DD-YYYY")}&select=${false}&selected_id=${selected_id}&state_id=${text.state_id}&state_name=${text.state_name}&region_name=${text.region_name}`,
                        state: { ...text, val: true }, values,
                    }}
                > {record}</ Link >
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.state_name !== null) {
                    return record.state_name.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                if (a.state_name !== null & b.state_name !== null) {
                    return a.state_name.localeCompare(b.state_name)
                }
            },

        },
        {
            title: 'Shipped QTY',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            align: "right",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.quantity !== null) {
                    return record.quantity.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Shipped Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 130,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> ${text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.amount !== null) {
                    return record.amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.amount.replaceAll(',', '') - b.amount.replaceAll(',', '')
                )
            },

        },
        {
            title: 'Return Qty',
            dataIndex: 'return_qty',
            key: 'return_qty',
            width: 100,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {text}</span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.return_qty !== null) {
                    return record.return_qty.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.return_qty.replaceAll(',', '') - b.return_qty.replaceAll(',', '')
                )
            },

        },
        {
            title: 'Return Amount',
            dataIndex: 'return_amount',
            key: 'return_amount',
            width: 130,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> ${text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.return_amount !== null) {
                    return record.return_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.return_amount.replaceAll(',', '') - b.return_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Net Qty',
            dataIndex: 'net_qty',
            key: 'net_qty',
            width: 100,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {text}</span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.net_qty !== null) {
                    return record.net_qty.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.net_qty.replaceAll(',', '') - b.net_qty.replaceAll(',', '')
                )
            },
        },
        {
            title: 'Net Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            width: 130,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> ${text} </span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.net_amount !== null) {
                    return record.net_amount.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.net_amount.replaceAll(',', '') - b.net_amount.replaceAll(',', '')
                )
            },
        },
        {
            title: "AUC",
            dataIndex: "auc",
            key: "auc",
            width: 120,
            align: "right",
            render: (text) => <span style={{ textAlign: "right" }}>${parseFloat(text).toFixed(2)}</span>,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              return (
                <>
                  <Input
                    allowClear
                    autoFocus
                    className="pop-search-gen pop-220"
                    // placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                      setSelectedKeys(e.target.value ? [e.target.value] : []);
                      confirm({ closeDropdown: false });
                    }}
                    onPressEnter={() => {
                      confirm();
                    }}
                    onBlur={() => {
                      confirm();
                    }}
                  />
                </>
              );
            },
            filterIcon: (filtered) =>
              filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
              if (record.auc !== null) {
                return record.auc
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }
            },
            sorter: (a, b) => {
              return (
                a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
              )
            },
          },
        {
            title: 'Return %',
            align: 'right',
            dataIndex: 'percentage',
            key: 'percentage',
            width: 80,
            render: text => <span style={{ textAlign: "right" }}> {text}%</span >,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-220'
                            // placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) => {
                if (record.percentage !== null) {
                    return record.percentage.toString().toLowerCase().includes(value.toLowerCase())
                }
            },
            sorter: (a, b) => {
                return (
                    a.percentage.replaceAll(',', '') - b.percentage.replaceAll(',', '')
                )
            },

        },
    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id);
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id);
    })

    const filterCustomerType = customerTypes.payload?.data.find((item) => {
        return item.customer_type_id === Number(customer_type_id);
    })


    return (
        <>

            <div className='row report-wrapper-main p-0'>
                <div className='col p-0'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Customer Type:
                                </td>
                                <td>
                                    <b>
                                    {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {from_date}
                                    </b>
                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {to_date}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                </td>
                                <td>

                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                        {company_id === "3" ? "WARP WEFT" : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin size='large' />
                </div> :
                <>{(submit) ?
                    <div className='w-100 mt-3'>
                        <Table
                        showSorterTooltip = {false}

                            // scroll={{ x: 200, y: 420 }}
                            className='w-100 test04'
                            size='small'
                            pagination={{ pageSize: 3000 }}
                            summary={pageData => {
                                let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                                }, 0);
                                let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.amount.replace(/\$|,/g, ''))
                                }, 0);
                                let sumReturnQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.return_qty.replace(/\$|,/g, ''))
                                }, 0);
                                let sumReturnAmt = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.return_amount.replace(/\$|,/g, ''))
                                }, 0);
                                let sumNetQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_qty.replace(/\$|,/g, ''))
                                }, 0);
                                let sumNetAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                                }, 0);

                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{
                                                    fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                    borderBottom: "1px solid black"
                                                }}>{sumQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>$ {sumAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}> {sumReturnQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>$ {sumReturnAmt.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}> {sumNetQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>$ {sumNetAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            dataSource={omsShipRegionStateReport?.payload?.data}
                        />
                    </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                        fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                    }} >
                        <tbody>
                            <tr style={{
                                backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                            }} >
                                <td colSpan="5">
                                    No record(s)</td>
                            </tr>
                        </tbody>
                    </table>}
                </>
            }

        </>

    )
}
export default OmsRegionStateReport;
