import {
    GET_ALL_STYLE_BOOKED_WISE_REQUEST, GET_ALL_STYLE_BOOKED_WISE_SUCCESS, GET_ALL_STYLE_BOOKED_WISE_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const getStyleBookedReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_ALL_STYLE_BOOKED_WISE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getStyleWiseBookedReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ...values },
    })
        .then((response) => {
            return dispatch({
                type: GET_ALL_STYLE_BOOKED_WISE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_STYLE_BOOKED_WISE_ERROR,
                payload: [],
            });
            //   if (error.response.data.data === "No Record Found !") {
            //     return dispatch({
            //       type: GET_BUSSINESS_DASHBOARD_ERROR,
            //       payload: [],
            //     });
            //   }
            //   return dispatch({
            //     type: GET_REGION_ERROR,
            //     payload: error.response,
            //   });
        });
};
