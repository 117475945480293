import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFabricUsable,
  addFabricUsable,
  deleteFabricUsable,
  updateFabricUsable,
} from "../../redux/actions/FabricUsable.action";

import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";

import { logColumns, fabricUsableColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function FabricUsable({ token }) {
  TabTitle("Fabric Usable")
  const menuId = ReadCookie("menuId");

  const FabricUsableNameInput = useRef(null);
  const [form] = Form.useForm();
  const [fabricUsableObj, setFabricUsableObj] = useState(null);
  const dispatch = useDispatch();
  const { fabricUsable, log, userRights } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [fabricUsableName, setFabricUsableName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");



  useEffect(() => {
    dispatch(getFabricUsable(token, menuId));
    // dispatch(getLog(token));
    dispatch(getLog(token, { inv_fabric_usable: "inv_fabric_usable" }))
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (fabricUsableObj) {
      setFormState("view");
      form.setFieldsValue({
        id: fabricUsableObj.fabric_usable_id,
        fabricUsable: fabricUsableObj.fabric_usable_name,
        active: fabricUsableObj.is_active,
      });
      setFabricUsableName(fabricUsableObj.fabric_usable_name);
      setCheck(fabricUsableObj.is_active);
    }
  }, [fabricUsableObj]);

  useEffect(() => {
    if (fabricUsableName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [fabricUsableName]);

  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(
        getLog(token, {
          inv_fabric_usable: "inv_fabric_usable",
          admin_company_entity_access: "admin_company_entity_access",
        })
      );
    }
  };

  const formSave = () => {
    if (formState === "add") {
      dispatch(addFabricUsable(token, { fabricUsableName, check }, menuId)).then(
        (res) => {
          if (res.type === "ADD_FABRICUSABLE_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          if (res.type === "ADD_FABRICUSABLE_SUCCESS") {
            notification(
              "success",
              "Fabric Usable Created",
              "Record updated successfully"
            );
            form.setFieldsValue({
              fabricUsable: ""
            })
            setFormState("")
            // setCheck(false)
            setFabricUsableName("")
            setFabricUsableObj(null)
          }
          dispatch(getFabricUsable(token));
        }
      );
    }

    if (formState === "edit") {
      dispatch(
        updateFabricUsable(
          token,
          { fabricUsableName, check },
          fabricUsableObj.fabric_usable_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_FABRICUSABLE_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_FABRICUSABLE_SUCCESS") {
          notification(
            "success",
            "Fabric Usable Updated",
            "Record updated successfully"
          );
        }
        dispatch(getFabricUsable(token));
      });
    }
  };

  const handleDelete = () => {
    if (fabricUsableObj) {
      dispatch(deleteFabricUsable(token, fabricUsableObj.fabric_usable_id)).then(
        (res) => {
          if (res.type === "DELETE_FABRICUSABLE_SUCCESS") {
            notification(
              "success",
              "Fabric Usable Deleted",
              "Fabric Usable deleted successfully!"
            );
          }
          if (res.type === "DELETE_FABRICUSABLE_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            fabricUsable: "",
          });
          // setCheck(false)
          setFabricUsableName("");
          setFabricUsableObj(null);
          setFormState("");
          dispatch(getFabricUsable(token));
        }
      );
    }
  };

  useEffect(() => {
    if (formState === "add") {

      FabricUsableNameInput.current.focus();
      form.setFieldsValue({
        fabricUsable: ""
      })
      setCheck(true)
      setFabricUsableName("")
      setFabricUsableObj(null)
    }
  }, [formState]);

  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Fabric Usable"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="fabricUsable"
                      fabricUsableObj={setFabricUsableObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={fabricUsable?.payload?.data}
                      col={fabricUsableColumns}
                      paginate
                      load={fabricUsable.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 8 }}>
                      <div className="customRow styleFormUpper ">
                        {fabricUsableObj !== null && !(formState === "add") && (
                          <div className="customCols slc">
                            <Form.Item
                              label="ID"
                              name="id"
                              initialValue={form.getFieldValue().id}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input
                                size="small"
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className="customCols slc">
                          <Form.Item
                            label="Fabric Usable"
                            name="fabricUsable"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            initialValue={form.getFieldValue().style}
                          >
                            <Input
                              size="small"
                              // placeholder="Fabric Usable Name"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={FabricUsableNameInput}
                              onChange={(e) =>
                                setFabricUsableName(e.target.value)
                              }
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc">
                          <Form.Item
                            label="Active"
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
