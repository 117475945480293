import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadCustomers,
  addCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../../../redux/actions/customerForm";
import { loadBergenBillingOption } from "../../../../redux/actions/bergenBillingOption";
import { loadShippingMethod } from "../../../../redux/actions/shippingMethod";
import { loadOrderStatus } from "../../../../redux/actions/customerSection";
import { getCustomerTerm } from "../../../../redux/actions/customerTerm.action";
import { getOneCustomer } from "../../../../redux/actions/CustomerFormById";
import { getCustomerType } from "../../../../redux/actions/customerType.action";
import { loadFreightTerm } from "../../../../redux/actions/freightTerm";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getRights } from "../../../../redux/actions/userRights.action";
import { loadShipVia } from "../../../../redux/actions/shipVia";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import {
  addBiller,
  getBilling,
  getRelatedTransactions,
} from "../../../../redux/actions/billing";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SubTitle from "../../../../components/SubTitle/SubTitle";
import Tables from "../../../../components/Table/Tables";
import {
  FilterFilled,
  SearchOutlined,
  UndoOutlined,
  FileAddOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Tabs,
  Tooltip,
  Divider,
  Select,
  DatePicker,
  Space,
  Upload,
  Text,
} from "antd";

import BillingSection from "../../billingSection";
import CategorySection from "../../categorySection";
import notification from "../../../../utils/notification";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { ReadCookie } from "../../../../utils/readCookie";
import "../../../Style/style.css";
import "../../customer.css";
import "../../../Product/product.css";
import { addShipper, getShipping } from "../../../../redux/actions/shipping";
import {
  addCategory,
  deleteCategory,
  loadCategory,
  updateCategory,
} from "../../../../redux/actions/customerCategory";
import { loadSalePerson } from "../../../../redux/actions/salePerson/salePersonForm.action";
import { getRegion } from "../../../../redux/actions/region.action";
import { getPaymentTerm } from "../../../../redux/actions/paymentterm.action";
import {
  addCustomerUpload,
  getCustomerUpload,
  updateCustomerUpload,
} from "../../../../redux/actions/customer.upload.action";
import {
  addCustomerAddress,
  deleteAddress,
  getOneCustomerAddress,
  updateAddress,
} from "../../../../redux/actions/customerAddress.action";
import { BASEURL1 } from "../../../../constant/config.js";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { relatedTransactions } from "../../../../utils/cols";
import Loader from "react-loader-spinner";
import { getSaleOrderAdjustmentFormData } from "../../../../redux/actions/saleOrderAdjustment.action";
export default function CustomerNewForm({
  token,
  submit,
  val,
  formState,
  changeState,
  formData,
  customerAllIdd,
}) {
  const focusInput = useRef(null);
  const { TextArea } = Input;
  const history = useHistory();
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const { Option } = Select;
  // const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitButton = useRef(null);
  const [customerObj, setCustomerObj] = useState(null);
  const [customerAllId, setCustomerAllId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const menuId = ReadCookie("menuId");
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [orderStatusID, setOrderStatusID] = useState(ReadCookie(null));

  const dispatch = useDispatch();
  const [customerCode, setCustomerCode] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [mainId, setMainId] = useState(null);
  const {
    ShipVia,
    ShippingMethod,
    FreightTerm,
    BergenBillingOption,
    customerForms,
    systemLog,
    customerTypes,
    customerSections,
    companyAll,
    customerTerm,
    customerUploads,
    saleOrderAdjustmentFormData,
  } = useSelector((state) => state);

  const [customerGridView, setCustomerGridView] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  // const [formState, setFormState] = useState("view");
  const [addressDataBill, setAddressDataBill] = useState([]);
  const [addressDataShip, setAddressDataShip] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [customerID, setCustomerId] = useState("");
  // const [token, setToken] = useState(token);
  const [expireDate, setExpiryDate] = useState(moment());
  const [disable, setDisable] = useState(false);
  const [dataLen, setDataLen] = useState(0);
  const [show, setShow] = useState(false);
  const [relatedTrans, setRelatedTrans] = useState([]);
  // const [filteredCustomers, setFilteredCustomers] = useState([])
  const [customerName, setCustomerName] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [billingAddress, setBillingAddress] = useState([]);
  const [deletedBillingLine, setDeletedBillingLine] = useState([]);
  const [deletedCategoryLine, setDeletedCategoryLine] = useState([]);
  const [test, setTest] = useState([]);
  const [oldTruee, setoldTruee] = useState([]);
  const [orderType, setOrderType] = useState(null);
  const [communicationFiles, setCommunicationFiles] = useState([]);

  const fileRef = useRef();


  const onSearch = (val) => {
    setOrderType(val);
    if (val.length > 2) {
      dispatch(
        getSaleOrderAdjustmentFormData(token, companyId, menuId, val, "")
      ).then((res) => {
        if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload.length === 0
        ) {
          notification("warning", "empty List");
        }

        if (res?.payload?.customerDetail?.length === 0) {
          notification("warning", "Customer Not Found");
        }
      });
    }
  };
  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          // changeState("table");
          changeState("formView");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (companyId) {
      setCompanyId(parseInt(companyId));
      form.setFieldsValue({
        company: companyId,
      });
    }
    dispatch(getAllCompany(token));
  }, [companyId]);

  useEffect(() => {
    if (formState === "table") {
      setCustomerAllId(null);
      setShow(false);
      setFilteredInfo(null);
      setSortedInfo(null);
    }
  }, [formState]);

  useEffect(() => {
    dispatch(loadSalePerson(token));
    dispatch(getRegion(token));
    dispatch(getPaymentTerm(token));
    dispatch(getCustomerType(token));
    dispatch(loadFreightTerm(token));
    dispatch(loadShippingMethod(token));
    dispatch(loadBergenBillingOption(token));
    dispatch(getCustomerTerm(token));
    dispatch(getAllCompany(token));

    dispatch(loadShipVia(token));
    dispatch(loadOrderStatus(token)).then((res) => {
      setOrderStatusID(res?.payload?.data[0].order_status_id);
    });
    dispatch(getAllCompany(token));
    dispatch(
      getSaleOrderAdjustmentFormData(token, companyId, menuId, null, null)
    );

  }, []);
  var paymentTermActive = customerTerm?.payload?.data.filter((t) => {
    return t.is_active === true
  })

  useEffect(() => {
    setCustomerCode(null);
    setCustomerName(null);
  }, [formState]);

  let dBill =
    billingAddress && billingAddress.find((val) => val.default_bill_to == true);

  useEffect(() => {
    if (formData) {
      // changeState("formView");
      form.setFieldsValue({
        company: formData?.company_id,
        customer_id: formData?.customer_code,
        customerName: formData?.customer_name,
        customerType: formData?.customer_type_id,
        webAddress: formData?.website,
        comments: formData?.remarks,
        paymentTerm: formData?.term_id,
        fTaxID: formData?.federal_tax_id,

        firstName: formData?.first_name,
        lastName: formData?.last_name,
        email: formData?.email_address,
        otherEmails: formData?.alternate_email_address,
        secondary_email: formData?.secondary_email,
        phone: formData?.phone_no,
        fax: formData?.fax_no,
        address: formData?.address,

        orderStatus: formData?.order_status_id,
        oldID: formData?.old_customer_code,
        rcn: formData?.resale_certificate_no,

        shippingMethod: formData?.shipping_method_id,
        freightTerm: formData?.freight_term_id,
        bergenBilling: formData?.bergan_billing_id,
        orderType: formData?.order_type_id,
        // shipVia: formData?.courier_service_id,
        // shipVia: formData?.bergan_ship_via_id,
        shipVia: formData?.bergan_ship_via_id,
        accountNo: formData?.shipping_account_no,
        taxDuties: formData?.tax_duties,
        shipToNonUs: formData?.ship_to_non_us,
        billToNonUs: formData?.bill_to_non_us,
        alcoholType: formData?.alcohol_recipient_type,
        isApproved: formData?.is_approved,
        isactive: formData?.is_active,
        isComplete: formData?.is_ship_complete,
        // dBillAdd: formData?.default_bill_address,
        dBillAdd:
          dBill &&
          dBill?.address_addressee +
          "\n" +
          dBill?.address_address_1 +
          (dBill?.address_address_2 != null
            ? "\n" + dBill?.address_address_2
            : "") +
          "\n" +
          dBill?.address_city_name +
          " " +
          (dBill?.address_state_name != null
            ? dBill?.address_state_name + " "
            : "") +
          dBill?.address_zip_code +
          "\n" +
          dBill?.address_country_name,
        expiryDate:
          formData?.resale_certificate_date === null
            ? null
            : moment(formData?.resale_certificate_date),
      });
      setCustomerId(formData.customer_id);
      if (formData.customer_id) {
        dispatch(loadCategory(token, formData.customer_id)).then((res) => {
          setCategory(res?.payload?.data);
          setCategoryData(res?.payload?.data);
        });
      }
    }
  }, [formData, dBill]);


  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  useEffect(() => {
    if (formState === "table") {
      setCustomerGridView(true);
      setCustomerObj(null);
      //setFormState("edit");
    }
    if (formState === "add") {
      setCustomerObj(null);
    }
  }, [formState]);

  useEffect(() => {
    if (!show) {
      setDataLen(0);
    }
    if (show) {
      setDataLen(customerForms?.payload?.data.length);
    }
  }, [show]);

  const handleTab = (key) => {
    if (key === "category") {
      // dispatch((token));
    }
    if (key === "shipping") {
      return dispatch(getShipping(token, customerID));
    }
    // if (key === "preferenceSection") {
    // 	return dispatch(getLog(token));
    // }
    if (key === "billing") {
      return dispatch(getBilling(token, customerID));
    }
    if (key === "logs") {
      return dispatch(
        getSystemLog(token, {
          table_name: "scm_customer_communication",
          record_id: customerID,
        })
      );
    }
    if (key === "communication") {
      return dispatch(getCustomerUpload(token, customerID));
    }
    if (key === "relatedTransactions") {
      return dispatch(getRelatedTransactions(token, customerID)).then((res) => {
        setRelatedTrans(res?.payload?.data);
      });
    }
  };

  useEffect(() => {
    if (formState === "view" || formState === "table" || formState === "add") {
      setCustomerId("");
      form.setFieldsValue({
        customer_id: null,
        customerName: null,
        customerType: null,
        webAddress: null,
        comments: null,

        //Presonal Info
        firstName: null,
        lastName: null,
        email: null,
        otherEmails: null,
        secondary_email: null,
        phone: null,
        fax: null,
        address: null,

        //Classification
        orderStatus: 1,
        oldID: null,
        rcn: null,
        expiryDate: expireDate,
        fTaxID: null,

        ///Preferences
        shippingMethod: null,
        freightTerm: null,
        bergenBilling: null,
        orderType: null,
        shipVia: null,
        accountNo: null,
        taxDuties: null,
        shipToNonUs: null,
        billToNonUs: null,
        alcoholType: null,
        isApproved: null,
        isactive: null,
        isComplete: null,
        paymentTerm: null,
      });
    }
  }, [formState]);

  let HeaderId;


  const onFinish = async (e) => {
    // form.setFieldsValue({ [e.target.name]: e.target.value });
    submit(false);
    setLoading(true);


    let checkBilling_Shipping = false;


    billingAddress.slice(0, 2).map((v) => {
      if (v?.default_bill_to || v?.default_ship_to) {
        checkBilling_Shipping = true;
      } else if (billingAddress[0].default_bill_to || billingAddress[0].default_ship_to) {
        checkBilling_Shipping = true;
      } else {
        setLoading(false);
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There should be one default billing and default shipping address",
        });
        // checkBilling_Shipping = false;
        // return checkBilling_Shipping;
      }
    })

    // if (!form.getFieldValue().bergenBilling) {
    //   return Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Select the bergen Billing",
    //   });
    // }
    if (!form.getFieldValue().shipVia) {
      setLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select the Ship Via",
      });
    }
    if (!form.getFieldValue().freightTerm) {
      setLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select the Frieght Term",
      });
    }
    if (categoryData.length === 0) {
      setLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select At Least One Category",
      });
    }

    await form.validateFields().then((values) => {



      if (formState === "add") {
        if (billingAddress?.length > 0) {
          // ****** Add Customer Information Header *****
          if (checkBilling_Shipping) {
            dispatch(addCustomer(token, values)).then((res) => {
              if (res.type === "ADD_CUSTOMER_SUCCESS") {
                // ***** Get One Customer Information Header *****
                // ***** set Customer Id ******
                HeaderId = res?.payload?.customer_id;
                dispatch(getOneCustomer(token, res?.payload?.customer_id)).then(
                  (res) => {
                    form.setFieldsValue({
                      customer_id: res?.payload?.customer_code,
                    });
                    // ***** Add Billing Addressess ******
                    dispatch(
                      addCustomerAddress(
                        token,
                        billingAddress.map((v) => (
                          delete v.index,
                          {

                            ...v,
                            customer_id: res?.payload?.customer_id,
                          }))
                      )
                    ).then((response) => {
                      if (response.type === "ADD_CUSTOMER_ADREESS_SUCCESS") {
                        // Add Category Lines
                        dispatch(getOneCustomerAddress(token, HeaderId)).then(
                          (res) => {
                            setBillingAddress(res?.payload?.data);
                          }
                        );
                        if (categoryData.length > 0) {
                          categoryData.map((sell) => {
                            delete sell.gender_name;
                            delete sell.region_name;
                            delete sell.salesperson_name;
                            // delete sell.gender_name
                            return (sell.customer_id = res?.payload?.customer_id);
                          });
                          dispatch(
                            addCategory(token, categoryData, HeaderId)
                          ).then((res) => {
                            if (res.type === "ADD_CATEGORY_SUCCESS") {
                              dispatch(loadCategory(token, HeaderId));
                            }
                          });
                        }
                        notification(
                          "success",
                          res?.payload?.request?.statusText,
                          "Customer created successfully!"
                        );
                        setLoading(false);
                        changeState("formView");

                        history.push({
                          pathname: `/customer-New-Info/edit/${HeaderId}`,
                          search: `?edit=yes`,
                        });
                      }
                    });
                  }
                );
              }
              if (res.type === "ADD_CUSTOMER_ERROR") {
                notification(
                  "error",
                  res?.payload?.request?.statusText,
                  res?.payload?.data?.data
                );
              }
            });
          }
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Select At least One  Line",
          });
          return;
        }
      } else if (formState === "edit") {
        if (deletedBillingLine.length > 0) {
          dispatch(deleteAddress(token, customerAllIdd, deletedBillingLine));
        }
        if (deletedCategoryLine.length > 0) {
          dispatch(deleteCategory(token, customerAllIdd, deletedCategoryLine));
        }
        // values.expiryDate = expireDate
        values.is_active = form.getFieldValue().isactive;
        if (checkBilling_Shipping) {
          dispatch(updateCustomer(token, values, customerAllIdd)).then((res) => {
            if (res.type === "UPDATE_CUSTOMER_ERROR") {
              notification("error", "Update", "Something went wrong");
            }
            if (res.type === "UPDATE_CUSTOMER_SUCCESS") {
              dispatch(
                updateAddress(token, billingAddress, formData?.customer_id)
              ).then((res) => {
                if (res.type === "UPDATE_CUSTOMER_ADREESS_SUCCESS") {
                  if (categoryData.length > 0) {
                    categoryData.map((sell) => {
                      delete sell.gender_name;
                      delete sell.region_name;
                      delete sell.salesperson_name;
                      delete sell.last_updated_by;
                      delete sell.last_updated_date;
                      delete sell.key;
                      // delete sell.gender_name
                      return (sell.customer_id = formData?.customer_id);
                    });
                    dispatch(
                      updateCategory(token, categoryData, formData?.customer_id)
                    ).then((res) => {
                      if (res.type === "UPDATE_CATEGORY_SUCCESS") {
                        dispatch(loadCategory(token, formData?.customer_id)).then(
                          (response) => {
                            setCategory(response?.payload?.data);
                          }
                        );
                      }
                    });
                  }
                  dispatch(
                    getOneCustomerAddress(token, formData?.customer_id)
                  ).then((res) => {
                    setBillingAddress(res?.payload?.data);
                  });
                  dispatch(getOneCustomer(token, formData?.customer_id));
                  setLoading(false);
                  notification(
                    "success",
                    "Customer update",
                    "Customer updated successfully"
                  );
                  changeState("formView");
                }
              });
            }
          });
        }
      }
    });
  };

  const uploadCols = [
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (text, record) => {
        return moment(record.created_date).format("MM-DD-YYYY");
      },
    },
    {
      title: "File Name",
      dataIndex: "physical_file_name",
      key: "physical_file_name",
    },
    {
      title: "Description",
      dataIndex: "upload_remarks",
      key: "upload_remarks",
    },
    {
      title: "Download",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            size="small"
            icon={<CloudDownloadOutlined style={{ fontSize: "16px" }} />}
            onClick={() => downloadFiled(record)}
          // disabled={!(formType === "add" || formType === "edit")}
          />
        </Space>
      ),
    },
  ];

  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0]);
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      };
    });
  };

  const handleUpload = () => {
    console.log("fileList", fileList);


        dispatch(addCustomerUpload(token, fileList, customerID, fileName)).then(
        (res) => {
          if (res.type === "ADD_CUSTOMER_UPLAOD_SUCCESS") {
            console.log("RES", res?.payload?.imgUrl);
            fileList.map((v, i) => {
              v.file = res?.payload?.imgUrl[i].imageUrl;
              v.name = res?.payload?.imgUrl[i].name;
              return v;
            })
            console.log("fileList", fileList);
            dispatch(updateCustomerUpload(token, fileList, customerID)).then((res)=> {
              if (res.type === "UPDATE_CUSTOMER_UPLAOD_SUCCESS"){
                setUploading(false);
                setFileList([]);
                setFileName("");
                notification(
                  "success",
                  "File Upload",
                  "File Uploaded Successfully"
                );
                dispatch(getCustomerUpload(token, customerID));
              }
            })
          }
        }
      
    );

    // if (fileName === null) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "File Name is Required",
    //   });
    // } else if (fileName === "") {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "File Name is Required",
    //   });
    // } else {
    //   // const formData = new FormData();
    //   // fileList.forEach((file) => {
    //   //   formData.append("uploadFiles", file);
    //   // });

    //   // console.log("fileList", fileList);

    //   // setUploading(true); // You can use any AJAX library you like


    //   // dispatch(addCustomerUpload(token, fileList, customerID, fileName)).then(
    //   //   (res) => {
    //   //     if (res.type === "ADD_CUSTOMER_UPLAOD_SUCCESS") {
    //   //       setUploading(false);
    //   //       setFileList([]);
    //   //       setFileName("");
    //   //       notification(
    //   //         "success",
    //   //         "File Upload",
    //   //         "File Uploaded Successfully"
    //   //       );
    //   //       dispatch(getCustomerUpload(token, customerID));
    //   //     }
    //   //   }
    //   // );
    // }
  };
  const downloadFiled = (record) => {

    console.log("RECORD", record);
    // Assuming physical_file is an object with type 'Buffer' and data as an array
    const fileData = new Uint8Array(record.physical_file.data);
    const blob = new Blob([fileData], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${record?.physical_file_name}`; // Specify the file name with the appropriate extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    // window.open(`${BASEURL1}${record.physical_file_name.replace(/\s/g, "")}`);
  };

  async function download(url) {
    let abc = url.split("https://erpbe.fasreports.com/");
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = abc[1];
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, { uploadRemarks: "", file: file }]);
      return false;
    },
    fileList,
  };




  const deleteFunc = (idx) => {
    let copyArr = [...fileList];
    const deleteObj = copyArr.filter((v, i) => i !== idx);
    setFileList(deleteObj);
  }

  return (
    <>
      <div className="styleContainer">
        {/* <div className="styleBody h-100"> */}
        <div className="bodyStyle"></div>
        <div className="styleContainer">
          <div className="styleBody h-100">
            <div className="bodyStyle">
              <div className="customerContainer">
                {loading && (
                  <div className="loaderDiv">
                    <Loader
                      type="Grid"
                      color="#212121"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
                <SubTitle name={"Primary Information"} />
                <div className="formProduct">
                  {/* {formState === "edit" && (
										<div className="undoDiv">
											<Tooltip
												placement="left"
												title="Form Reset"
												color="#fbfbfb"
											>
												<Button
													className="iconBtn undoBtn "
													icon={<UndoOutlined />}
													shape="circle"
												/>
											</Tooltip>
										</div>
									)} */}

                  <Form onFinish={onFinish} form={form} name="basic">
                    <div className="container-fluid pbpx-5 ptpx-5">
                      <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol slc">
                            <Form.Item
                              name="company"
                              label="Company"
                              labelAlign="left"
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              initialValue={
                                formState === "add"
                                  ? null
                                  : form.getFieldValue().company
                              }
                            >
                              <Select
                                size="small"
                                showSearch
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, options) =>
                                  options.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={
                                  formState === "add" ||
                                  formState === "edit" ||
                                  formState === "view" ||
                                  formState === "formView"
                                }
                                className="styleInput w-100"
                              >
                                {companyAll?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.company_id}
                                      value={item.company_id}
                                    >
                                      {item.company_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().customer_id
                                }
                                rules={[
                                  {
                                    pattern: new RegExp(/^[0-9\b]+$/),
                                    message: "Must be a Number",
                                  },
                                ]}
                                wrapperCol={{ span: 16 }}
                                labelCol={{ span: 8 }}
                                label="Customer Code"
                                name="customer_id"
                                labelAlign="left"
                              >
                                <Input
                                  bordered={false}
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Customer Code"
                                  disabled={
                                    formState === "add" ||
                                    formState === "edit" ||
                                    formState === "view" ||
                                    formState === "formView"
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="customerName"
                                label="Customer Name"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().customerName
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Customer Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Customer Name"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol slc">
                            <div className="relative">
                              <Form.Item
                                size="small"
                                name="customerType"
                                label="Customer Type"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Customer Type is required",
                                  },
                                ]}
                              >
                                <Select
                                  size="small"
                                  filterSort={(optionA, optionB) =>
                                    
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().customerType
                                      : null
                                  }
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  // placeholder="Customer Type"
                                  loading={customerTypes.loading}
                                  optionFilterProp="children"
                                  className="w-100"
                                  onSearch={onSearch}
                                  showSearch
                                  allowClear
                                >
                                  {customerTypes?.payload?.data.map(
                                    (customer) => {
                                      return (
                                        <Option
                                          value={customer.customer_type_id}
                                        >
                                          {customer.customer_type_name}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="webAddress"
                                label="Web Address"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().webAddress
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Web Address"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="comments"
                                label="Comments"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().comments
                                }
                              >
                                <Input
                                  size="small"
                                  disable
                                  className="styleInput"
                                  // placeholder="Comments"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Button
                          htmlType="submit"
                          style={{ display: "none" }}
                          ref={submitButton}
                          onClick={onFinish}
                        />
                      </Brow>
                    </div>
                  </Form>
                </div>
                <SubTitle name={"Email | Phone | Address"} />
                <div className="formProduct">
                  {/* {formState === "edit" && (
										<div className="undoDiv">
											<Tooltip
												placement="left"
												title="Form Reset"
												color="#fbfbfb"
											>
												<Button
													className="iconBtn undoBtn "
													icon={<UndoOutlined />}
													shape="circle"
												/>
											</Tooltip>
										</div>
									)} */}
                  <Form onFinish={onFinish} form={form} name="basic">
                    <div className="container-fluid pbpx-5 ptpx-5">
                      <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="firstName"
                                label="AP First Name"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().firstName
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "AP FIRST NAME is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="First Name"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="lastName"
                                label="AP Last Name"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().lastName
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "AP LAST NAME is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Last Name"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="otherEmails"
                                label="Other Emails"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().otherEmails
                                }
                                rules={[
                                  {
                                    type: "email",
                                    message: "Email is not Valid",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="other Emails"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="email"
                                label="Email"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().email
                                }
                                rules={[
                                  // {
                                  //   type: "email",
                                  //   message: "Email is not Valid",
                                  // },
                                  {
                                    required: true,
                                    message: "Email is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Email"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="secondary_email"
                                label="Secondary Email"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().secondary_email
                                }
                                rules={[
                                  {
                                    type: "email",
                                    message: "Email is not Valid",
                                  },
                                  // {
                                  //   required: true,
                                  //   message: "Email is required",
                                  // },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Email"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="phone"
                                label="Phone"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().phone
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Phone"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="fax"
                                label="fax"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().fax
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Fax"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol
                          // lg={6} xl={4} xxl={3}
                          lg={6}
                          xl={8}
                          xxl={5}
                        >
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="dBillAdd"
                                label="Default Billing Address"
                                labelAlign="left"
                                labelCol={{
                                  // span: 8
                                  xs: { span: 52 },
                                  lg: { span: 52 },
                                  xxl: { span: 4 },
                                }}
                                // wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().dBillAdd
                                }
                              >
                                {/* <Input
																	disabled
																	bordered={false}
																	size="small"
																	className="styleInput"
																	readOnly
																/> */}
                                <TextArea
                                  disabled
                                  bordered={false}
                                  size="small"
                                  className="styleInput"
                                  readOnly
                                  placeholder=""
                                  autoSize={{
                                    minRows: 2,
                                    maxRows: 6,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Button
                          htmlType="submit"
                          style={{ display: "none" }}
                          ref={submitButton}
                          onClick={onFinish}
                        />
                      </Brow>
                    </div>
                    {/* <div className="customRow-Customer">
                      </div> */}
                  </Form>
                </div>
                <SubTitle name={"Classificaion"} />
                <div className="formProduct">
                  {/* {formState === "edit" && (
										<div className="undoDiv">
											<Tooltip
												placement="left"
												title="Form Reset"
												color="#fbfbfb"
											>
												<Button
													className="iconBtn undoBtn "
													icon={<UndoOutlined />}
													shape="circle"
												/>
											</Tooltip>
										</div>
									)} */}
                  <Form onFinish={onFinish} form={form} name="basic">
                    <div className="container-fluid pbpx-5 ptpx-5">
                      <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol slc">
                            <div className="relative">
                              <Form.Item
                              
                                name="orderStatus"
                                label="Order Status:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().orderStatus
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Order Status is required",
                                  },
                                ]}
                              >
                                <Select
                                  size="small"
                                  filterOption={(input, options) =>
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  disabled={
                                    !(
                                      formState === "add" 
                                      // ||
                                      // formState === "edit"
                                    )
                                  }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().orderStatus
                                      : null
                                  }
                                  
                                  // placeholder="Order Status"
                                  loading={customerSections.loading}
                                  optionFilterProp="children"
                                  className="w-100"
                                  onSearch={onSearch}
                                  showSearch
                                  allowClear
                                  filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                                >
                                  {customerSections?.payload?.data.map(
                                    (order) => {
                                      return (
                                        <Option value={order.order_status_id}>
                                          {order.status_name}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="oldID"
                                label="Old ID"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().oldID
                                }
                              // rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Please enter correct Id." }]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Old ID"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="rcn"
                                label="Resale Certificate"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add" || formState === "view"
                                    ? null
                                    : form.getFieldValue().rcn
                                }
                              // rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Please enter correct Number." }]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div>
                              <Form.Item
                                name="expiryDate"
                                label="Resale Expiry Date"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                              // initialValue={formState === "add" ? null : form.getFieldValue().expiryDate}
                              >
                                {form.getFieldValue().expiryDate == null ? (
                                  <DatePicker
                                    size="small"
                                    className="styleInput customInputs w-100"
                                    placeholder="Not Available"
                                    disabled={
                                      !(
                                        formState === "add" ||
                                        formState === "edit"
                                      )
                                    }
                                    format={"DD/MM/YYYY"}
                                    onChange={(e) => {
                                      setExpiryDate(moment(e));
                                    }}
                                  />
                                ) : (
                                  <DatePicker
                                    size="small"
                                    className="styleInput customInputs w-100"
                                    // placeholder="expiryDate"
                                    disabled={
                                      !(
                                        formState === "add" ||
                                        formState === "edit"
                                      )
                                    }
                                    format={"DD/MM/YYYY"}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="paymentTerm"
                                label="Payment Term:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().paymentTerm
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Payment Term is required",
                                  },
                                ]}
                              >
                                <Select
                                  size="small"
                                  filterOption={(input, options) =>
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0

                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().paymentTerm
                                      : null
                                  }
                                  // placeholder="Payment Term"
                                  loading={customerTerm.loading}
                                  optionFilterProp="children"
                                  className="w-100"
                                  onSearch={onSearch}
                                  showSearch
                                  allowClear
                                >

                                  {paymentTermActive?.map((e) => {
                                    return (
                                      <Option key={e.term_id} value={e.term_id} disabled={!e.is_active}>
                                        {e.term_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="customCol">
                            <div className="relative">
                              <Form.Item
                                name="fTaxID"
                                label="Federal Tax ID"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().fTaxID
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder="Enter Fedral Tax ID"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Button
                          htmlType="submit"
                          style={{ display: "none" }}
                          ref={submitButton}
                          onClick={onFinish}
                        />
                      </Brow>
                    </div>
                  </Form>
                </div>
                <Divider />
                <div className="styleTab">
                  <Tabs
                    size="small"
                    type="card"
                    tabBarGutter={1}
                    defaultActiveKey="billing"
                    centered
                    onChange={(key) => handleTab(key)}
                  >
                    <TabPane tab="Address" key="Address">
                      <div className="tabs">
                        <BillingSection
                          test={test}
                          setTest={setTest}
                          oldTruee={oldTruee}
                          addressDataBill={setAddressDataBill}
                          customer_id={customerID}
                          formState={changeState}
                          formType={formState}
                          token={token}
                          billingAddress={billingAddress}
                          setBillingAddress={setBillingAddress}
                          deletedBillingLine={deletedBillingLine}
                          setDeletedBillingLine={setDeletedBillingLine}
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="Category" key="category">
                      <div className="tabs">
                        <CategorySection
                          categoryData={setCategoryData}
                          customer_id={customerID}
                          formState={changeState}
                          formType={formState}
                          token={token}
                          categorylist={categoryData}
                          category={category}
                          setCategory={setCategory}
                          deletedCategoryLine={deletedCategoryLine}
                          setDeletedCategoryLine={setDeletedCategoryLine}
                        // setCategory={setCategory}
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="Preferences" key="preferenceSection">
                      <div className="tabs">
                        <br />
                        <Form onFinish={onFinish} form={form} name="basic">
                          <div className="container-fluid">
                            <Brow>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative">
                                    <Form.Item
                                      name="shippingMethod"
                                      label="Shipping Method:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Shipping Method is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, options) =>
                                          options.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        loading={customerSections.loading}
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        defaultValue={
                                          formState === "edit"
                                            ? form.getFieldValue()
                                              .shippingMethod
                                            : null
                                        }
                                        className="w-100"
                                        // placeholder="Shipping Method"
                                        allowClear
                                        filterSort={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                      >
                                        {ShippingMethod?.payload?.data.map(
                                          (shipMethod) => {
                                            return (
                                              <Option
                                                value={
                                                  shipMethod.shipping_method_id
                                                }
                                              >
                                                {
                                                  shipMethod.shipping_method_name
                                                }
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>

                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative">
                                    <Form.Item
                                      name="freightTerm"
                                      label="Freight Term:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Freight Term is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, options) =>
                                          options.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        loading={FreightTerm.loading}
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        defaultValue={
                                          formState === "edit"
                                            ? form.getFieldValue().freightTerm
                                            : null
                                        }
                                        className="w-100"
                                        // placeholder="Freight Term"
                                        allowClear
                                        filterSort={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                      >
                                        {FreightTerm?.payload?.data.map(
                                          (freight) => {
                                            return (
                                              <Option
                                                value={freight.freight_term_id}
                                              >
                                                {freight.freight_term_name}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative">
                                    <Form.Item
                                      name="bergenBilling"
                                      label="Bergen Billing:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Bergen Billing is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, options) =>
                                          options.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                        loading={BergenBillingOption?.loading}
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        defaultValue={
                                          formState === "edit"
                                            ? form.getFieldValue().bergenBilling
                                            : null
                                        }
                                        className="w-100"
                                        // placeholder="Bergen Billing"
                                        allowClear
                                      >
                                        {BergenBillingOption?.payload?.data?.map(
                                          (bill) => {
                                            return (
                                              <Option
                                                value={bill?.bergan_billing_id}
                                              >
                                                {bill?.bergan_billing_name}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative">
                                    <Form.Item
                                      name="shipVia"
                                      label="Ship Via"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Ship Via is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        size="small"
                                        showSearch
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, options) =>
                                          options.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                        loading={ShipVia.loading}
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        defaultValue={
                                          formState === "edit"
                                            ? form.getFieldValue().shipVia
                                            : null
                                        }
                                        className="w-100"
                                        // placeholder="Ship Via"
                                        allowClear
                                      >
                                        {ShipVia?.payload?.data.map((ship) => {
                                          return (
                                            <Option
                                              key={ship.courier_service_id}
                                              value={ship.courier_service_id}
                                            >
                                              {ship.service_name}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>

                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative">
                                    <Form.Item
                                      name="accountNo"
                                      label="Shipping Account:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      initialValue={
                                        formState === "add"
                                          ? null
                                          : form.getFieldValue().accountNo
                                      }
                                    // rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}
                                    >
                                      <Input
                                        size="small"
                                        className="styleInput b-radius-10"
                                        // placeholder="Shipping Account No"
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative">
                                    <Form.Item
                                      name="alcoholType"
                                      label="Alcohol Recepient:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                    >
                                      <Select
                                        size="small"
                                        defaultValue={
                                          formState === "edit"
                                            ? form.getFieldValue().alcoholType
                                            : null
                                        }
                                        // placeholder="Alcohol Recipient Type"
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        loading={customerSections.loading}
                                        className="w-100"
                                        allowClear
                                      >
                                        <Option value={"1"}>Consumer</Option>
                                        <Option value={"2"}>NonConsumer</Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              {/* JAVED */}
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative">
                                    <Form.Item
                                      name="taxDuties"
                                      label="Tax Duties:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      initialValue={
                                        formState === "add"
                                          ? null
                                          : form.getFieldValue().taxDuties
                                      }
                                    // rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}
                                    >
                                      <Input
                                        size="small"
                                        className="styleInput b-radius-10"
                                        // placeholder="Shipping Account No"
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative">
                                    <Form.Item
                                      name="shipToNonUs"
                                      label="Ship To Non Us:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      initialValue={
                                        formState === "add"
                                          ? null
                                          : form.getFieldValue().shipToNonUs
                                      }
                                    // rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}
                                    >
                                      <Input
                                        size="small"
                                        className="styleInput b-radius-10"
                                        // placeholder="Shipping Account No"
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative">
                                    <Form.Item
                                      name="billToNonUs"
                                      label="Bill To Non Us:"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      initialValue={
                                        formState === "add"
                                          ? null
                                          : form.getFieldValue().billToNonUs
                                      }
                                    // rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}
                                    >
                                      <Input
                                        size="small"
                                        className="styleInput b-radius-10"
                                        // placeholder="Shipping Account No"
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>

                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="firstSectionRo">
                                  <Form.Item
                                    name="orderType"
                                    label="Order Type"
                                    labelAlign="left"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Select OrderType",
                                      },

                                    ]}
                                  >
                                    <Select
                                      size="small"
                                      tabIndex={1}
                                      autoComplete={"nope"}
                                      allowClear
                                      showSearch
                                      optionFilterProp="children"
                                      loading={
                                        saleOrderAdjustmentFormData.loading
                                      }
                                      // disabled
                                      defaultValue={
                                        form.getFieldValue().orderType
                                      }
                                      disabled={
                                        !(
                                          formState === "add" ||
                                          formState === "edit"
                                        )
                                      }
                                      onSearch={onSearch}
                                      ref={focusInput}
                                      filterOption={(input, options) =>
                                        options.children[1]
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      filterSort={(optionA, optionB) =>
                                        // console.log(optionB,"JAVED tets")
                                        optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase())
                                  }
                                    >
                                      {saleOrderAdjustmentFormData?.payload?.scmOrderType.map(
                                        (item) => {
                                          return (
                                            <Option
                                              key={item.order_type_id}
                                              value={item.order_type_id}
                                            >
                                              {" "}
                                              {`${item.order_type_name}`}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol slc">
                                  <div className="relative mx-10">
                                    <Form.Item
                                      name="isComplete"
                                      valuePropName="checked"
                                      // labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                    >
                                      <Checkbox
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        className="styleInput"
                                      >
                                        Ship Complete
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative mx-10">
                                    <Form.Item
                                      name="isApproved"
                                      valuePropName="checked"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                    >
                                      <Checkbox
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        className="styleInput"
                                      >
                                        Approved
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>
                              <Bcol lg={6} xl={4} xxl={3}>
                                <div className="customCol">
                                  <div className="relative mx-10">
                                    <Form.Item
                                      name="isactive"
                                      valuePropName="checked"
                                      labelAlign="left"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                    >
                                      <Checkbox
                                        disabled={
                                          !(
                                            formState === "add" ||
                                            formState === "edit"
                                          )
                                        }
                                        className="styleInput"
                                      >
                                        Active
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Bcol>

                              <Button
                                htmlType="submit"
                                style={{ display: "none" }}
                                ref={submitButton}
                                onClick={onFinish}
                              />
                            </Brow>
                          </div>

                          {/* <div className="customRow-Customer">
                            </div> */}
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane
                      tab="Related Transactions"
                      key="relatedTransactions"
                      disabled={formState === "view" || formState === "add"}
                    >
                      <div className="tabs">
                        <Tables
                          type="company"
                          scrollX={"50rem"}
                          scroll={360}
                          perPage={15}
                          source={relatedTrans}
                          col={relatedTransactions}
                          // load={log.loading}
                          paginate
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      tab="Communication"
                      key="communication"
                    // disabled={formState === "view" || formState === "add"}
                    >
                      <br />
                      <Form onFinish={onFinish} form={form} name="basic">
                        <div className="container-fluid">
                          <Space>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <input ref={fileRef} style={{ display: "none" }} type="file" onChange={(e) => {
                                if (e.target.files[0]) {
                                  setFileList([...fileList, { file: e.target.files[0], upload_remarks: "" }])
                                }
                              }} />
                              <Button
                                icon={
                                  <FileAddOutlined
                                    style={{ fontSize: "16px" }}
                                  />
                                }
                                onClick={() => fileRef.current.click()}
                              >
                                Select File
                              </Button>
                              {/* <Upload {...props} maxCount={1}>
                              <Button
                                icon={
                                  <FileAddOutlined
                                    style={{ fontSize: "16px" }}
                                  />
                                }
                              >
                                Select File
                              </Button>
                            </Upload> */}
                              {
                                fileList.map((v, i) => {
                                  return (
                                    <div key={i}>
                                      <p>{v.file.name}</p>
                                      <Input
                                        size="small"
                                        className="styleInput"
                                        placeholder="File Name"
                                        value={fileList[i].upload_remarks}
                                        onChange={(e) => {
                                          let copyArr = [...fileList];
                                          copyArr[i].upload_remarks = e.target.value;
                                          setFileList(copyArr);
                                        }}
                                      // disabled={!(formState === "add" || formState === "edit")}
                                      />
                                      <DeleteOutlined size={20} onClick={() => deleteFunc(i)} />
                                    </div>
                                  )
                                })
                              }
                            </div>
                            {/* <Form.Item
                              name="fileName"
                              // label="File Name"
                              labelAlign="left"
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              initialValue={
                                formState === "add"
                                  ? null
                                  : form.getFieldValue().fileName
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "File Name is required",
                                },
                              ]}
                            >
                              <Input
                                size="small"
                                className="styleInput"
                                placeholder="File Name"
                                value={fileName}
                                onChange={(e) => setFileName(e.target.value)}
                              // disabled={!(formState === "add" || formState === "edit")}
                              />
                            </Form.Item> */}
                            <Button
                              icon={
                                <CloudUploadOutlined
                                  style={{ fontSize: "16px" }}
                                />
                              }
                              type="primary"
                              onClick={handleUpload}
                              disabled={fileList.length === 0}
                              loading={uploading}
                              style={{
                                marginTop: 5,
                                marginBottom: 5,
                              }}
                            >
                              {uploading ? "Uploading" : "Start Upload"}
                            </Button>
                          </Space>
                          <Brow>
                            <Bcol lg={12} xl={12} xxl={12}>
                              <Tables
                                type="company"
                                scrollX={"50rem"}
                                scroll={360}
                                perPage={15}
                                // source={customerUploads?.payload[0]?.data}
                                source={
                                  customerUploads?.payload &&
                                  customerUploads?.payload?.[0]?.data
                                }
                                col={uploadCols}
                                load={
                                  customerUploads && customerUploads?.loading
                                }
                                paginate
                              />
                            </Bcol>
                            <Button
                              htmlType="submit"
                              style={{ display: "none" }}
                              ref={submitButton}
                              onClick={onFinish}
                            />
                          </Brow>
                        </div>
                      </Form>
                    </TabPane>
                    {(formState === "edit" || formState === "formView") && (
                      <TabPane tab="System Information" key="logs">
                        <div className="tabs">
                          {systemLog?.payload?.data?.length > 0 && (
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={systemLog?.payload?.data}
                              dataSource={[]}
                              col={renderColumns()}
                              load={systemLog?.loading}
                              paginate
                            />
                          )}
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
