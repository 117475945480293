import {
    GET_INCOME_STATEMENT_REQUEST,
    GET_INCOME_STATEMENT_SUCCESS,
    GET_INCOME_STATEMENT_ERROR,
    GET_INCOME_DETAIL_REQUEST,
    GET_INCOME_DETAIL_SUCCESS,
    GET_INCOME_DETAIL_ERROR,
    GET_BALANCE_SHEET_REQUEST,
    GET_BALANCE_SHEET_SUCCESS,
    GET_BALANCE_SHEET_ERROR,
    GET_BALANCE_DETAIL_REQUEST,
    GET_BALANCE_DETAIL_SUCCESS,
    GET_BALANCE_DETAIL_ERROR,
    GET_BALANCE_SHEET_OLD_REQUEST,
    GET_BALANCE_SHEET_OLD_SUCCESS,
    GET_BALANCE_SHEET_OLD_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

///Post Bussiness dashboard
export const getIncomeStatement = (token, data) => (dispatch) => {
    console.log(data)
    dispatch({ type: GET_INCOME_STATEMENT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/IncomeStatment`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_from: moment(data.cy_start_date).format("MM-DD-YYYY"),
            date_to: moment(data.cy_end_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_INCOME_STATEMENT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INCOME_STATEMENT_ERROR,
                payload: [],
            });
        });
};

export const getIncomeDetail = (token, data) => (dispatch) => {
    console.log(data)
    dispatch({ type: GET_INCOME_DETAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/StatementDetail`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_from: moment(data.date_from).format("MM-DD-YYYY"),
            date_to: moment(data.date_to).format("MM-DD-YYYY"),
            account_name: data.account_name
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_INCOME_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INCOME_DETAIL_ERROR,
                payload: [],
            });
        });
};

export const getBalanceSheet = (token, data) => (dispatch) => {
    dispatch({ type: GET_BALANCE_SHEET_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetBalanceSheet`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_to: moment(data.cy_end_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_BALANCE_SHEET_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_BALANCE_SHEET_ERROR,
                payload: [],
            });
        });
};

export const getBalanceSheetOld = (token, data) => (dispatch) => {
    dispatch({ type: GET_BALANCE_SHEET_OLD_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetBalanceSheet`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_to: moment(data).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_BALANCE_SHEET_OLD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_BALANCE_SHEET_OLD_ERROR,
                payload: [],
            });
        });
};

export const getBalanceSheetNew = (token, data) => (dispatch) => {
    console.log({data})
    dispatch({ type: GET_BALANCE_SHEET_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/balanceSheet`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_to: moment(data).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_BALANCE_SHEET_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_BALANCE_SHEET_ERROR,
                payload: [],
            });
        });
};


export const getBalanceDetail = (token, data) => (dispatch) => {
    dispatch({ type: GET_BALANCE_DETAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/SheetDetail`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            account_id: parseFloat(data.account_id),
            to_date: moment(data.date_to).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_BALANCE_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_BALANCE_DETAIL_ERROR,
                payload: [],
            });
        });
};
