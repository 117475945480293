import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { getItemTransferFormData } from "../../redux/actions/itemTransfer.action";
import { getRights } from "../../redux/actions/userRights.action";
import CrudButtons from "./components/CrudButtons";
// import CustomerRefundForm from "./components/Form/CustomerRefundForm";
import JournalVoucherForm from "./components/Form/JournalVoucherForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { deleteJournalVoucherHeader, getOneJournalVoucherHeader } from "../../redux/actions/journalVoucherHeader.action";
import { TabTitle } from "../../utils/GeneralFunction";
import moment from "moment";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
export default function JournalVoucher({ token }) {
	TabTitle("Journal Voucher")
	const [userRights, setUserRights] = useState({})
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const { log, journalVoucherHeader, journalVoucherLine } = useSelector((state) => state);
	const queryParams = new URLSearchParams(window.location.search);
	const [formState, setFormState] = useState("view");
	const dCompanyId = ReadCookie("defaultCompany");
	const [minimize, setMinimize] = useState(false);
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [flag, setFlag] = useState(false);
	const mode = queryParams.get("mode");
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();

	const [transactionLock, setTransactionLock] = useState(false)

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		dispatch(deleteJournalVoucherHeader(token, id)).then((res) => {
			if (res.type === "DELETE_JOURNAL_VOUCHER_HEADER_SUCCESS") {
				notification("success", "Journal Voucher deleted", res.payload);
				setFlag(true);
				history.push("/journal-voucher/list");
			}
		});
	};

	const changeState = () => {
		// 
		setFormState("formView");
	}

	const formSave = () => {
		setSubmit(true);
		setDisable(true)
	};
	useLayoutEffect(() => {
		dispatch(getRights(token, 839)).then((res) => {
			console.log(res?.payload)
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])
	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneJournalVoucherHeader(token, id)).then((res) => {
				if (res?.type === "GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS") {
					if (res?.payload?.data[0]?.voucher_date) {
						let transaction_date = moment(res?.payload.data[0]?.voucher_date).format("YYYY-MM-DD");
						dispatch(getTransationLock(token, transaction_date)).then((r) => {
							if (r.payload.data.length > 0) {
								setTransactionLock(true);
							}
						});
					}
				}
			});
		}
	}, [id]);




	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Journal Voucher"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							{/* <CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								journalVoucherId={id}
								formData={journalVoucherHeader?.payload?.data}

							/> */}

							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								journalVoucherId={id}
								formData={journalVoucherHeader?.payload?.data}
								transactionLock={transactionLock}
							/>

							<div className="mainProductContainer">
								<JournalVoucherForm
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									//formData={formState === "formView" || formState === "edit" ? journalVoucherHeader?.payload?.data[0] : null}
									formState={formState}
									changeState={changeState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									journalVoucherId={id}
									disable={setDisable}
									formData={journalVoucherHeader?.payload?.data}
									formLine={journalVoucherLine?.payload?.data}
									transactionLock={transactionLock}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Customer Refund{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
