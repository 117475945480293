import {
    ADD_JOURNAL_VOUCHER_HEADER_REQUEST,
    ADD_JOURNAL_VOUCHER_HEADER_SUCCESS,
    ADD_JOURNAL_VOUCHER_HEADER_ERROR,

    GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST,
    GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS,
    GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR,

    UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST,
    UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS,
    UPDATE_JOURNAL_VOUCHER_HEADER_ERROR,

    GETALL_JOURNAL_VOUCHER_HEADER_REQUEST,
    GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS,
    GETALL_JOURNAL_VOUCHER_HEADER_ERROR,

    DELETE_JOURNAL_VOUCHER_HEADER_REQUEST,
    DELETE_JOURNAL_VOUCHER_HEADER_SUCCESS,
    DELETE_JOURNAL_VOUCHER_HEADER_ERROR,

} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const addJournalVoucherHeader = (token, formData) => (dispatch) => {
    // 
    // 
    dispatch({ type: ADD_JOURNAL_VOUCHER_HEADER_REQUEST });
    let newData = {

        /////
        // payment_date: moment(formData.date).format("MM-DD-YYYY"),
        // payment_type_id: formData.paymentType,
        // payment_term_id: formData.paymentTerm,
        // gl_period_id: formData.postingPeriod,
        // customer_id: formData.customerID,
        gender_id: formData?.gender,
        gl_period_id: formData?.postingPeriod,
        // account_id :formData.account,
        voucher_date:moment( formData?.voucher_date).format("MM-DD-YYYY"),
        memo: formData?.memo,
        // ar_account_id:formData.arAccount,
        company_id: formData?.subsidiary,
        // remarks:formData.remarks
        // credit_account_id: formData.arAccount,
        // debit_account_id: formData.debitAccount
    };
    return axios({
        method: "POST",
        url: `${BASEURL}create/glvoucherheader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getAllJournalVoucherHeaders = (token, voucherNo, postingPeriod, from_date, to_date, genderName) => (dispatch) => {
    dispatch({ type: GETALL_JOURNAL_VOUCHER_HEADER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/glvoucherheader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: from_date ? moment(from_date).format("MM-DD-YYYY") : null,
            to_date: to_date ? moment(to_date).format("MM-DD-YYYY") : null,
            voucher_header_no: voucherNo ? voucherNo : null,
            gl_period_name: postingPeriod ? postingPeriod : null,
            gender_name: genderName ? genderName : null,
        },
    })
        .then((response) => {
            return dispatch({
                type: GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GETALL_JOURNAL_VOUCHER_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getOneJournalVoucherHeader = (token, id) => (dispatch) => {



    dispatch({ type: GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/glvoucherheader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const updateJournalVoucherHeader = (token, formData, id) => (dispatch) => {

    let newData = {
        // date:moment(formData.refund_date),
        // date:formData.refund_date,
        voucher_date: formData.voucher_date,
        gl_period_id: formData.postingPeriod,
        memo: formData.memo,
        // remarks:formData.remarks,
        // customer_id: formData.customerID,
        company_id: formData.subsidiary,
        // arAccount: formData.credit_account_id,
        // account_id: formData.account_id,
    };
    dispatch({ type: UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/glvoucherheader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            // 
            return dispatch({

                type: UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS,
                payloadUpdate: response?.data?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_JOURNAL_VOUCHER_HEADER_ERROR,
                payloadUpdate: error.response,
            });
        });
};

export const deleteJournalVoucherHeader = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_JOURNAL_VOUCHER_HEADER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/glvoucherheader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: DELETE_JOURNAL_VOUCHER_HEADER_SUCCESS,
                payload: response?.data.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_JOURNAL_VOUCHER_HEADER_ERROR,
                payload: error.response,
            });
        });
};
