import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Tooltip, Popconfirm, Table, InputNumber } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import numeral from "numeral";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getChartOfAccount } from "../../../../redux/actions/Chart_All_Account.action";
import { loadCustomerVendor } from "../../../../redux/actions/customerVendor.action";
import { getAdminPaymentTypes } from "../../../../redux/actions/adminPaymentType.action";
import { addJournalVoucherHeader, getOneJournalVoucherHeader, updateJournalVoucherHeader } from "../../../../redux/actions/journalVoucherHeader.action";
import { addJournalVoucherLine, addJournalVoucherLines, deleteJournalVoucherLine, getJournalVoucherDetails, getOneJournalVoucherLine, updateJournalVoucherLine } from "../../../../redux/actions/journalVoucherLine.action";
import Tables from "../../../../components/Table/Tables";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getGender } from "../../../../redux/actions/gender.action";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
const { Option } = Select;
const { TabPane } = Tabs;

export default function JournalVoucherForm({ formData, formLine, val, token, formState, submit, journalVoucherId, disable, changeState, checkFlag }) {

	const dispatch = useDispatch();
	const submitButton = useRef(null);
	const focusInput = useRef(null);
	const [form] = Form.useForm();
	const [headerId, setHeaderId] = useState("");
	const [voucherID, setVoucherID] = useState("");
	const { journalVoucherFormData, invoiceGlPeriod, customerVendor, companyAll, journalVoucherHeader, arAccount, systemLog, glImpact, gender, chartOfAccount } = useSelector((state) => state);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [oDate, setOrderDate] = useState(moment());
	const [customer, setCustomer] = useState([])
	const [lineId, setLineId] = useState("")
	const [lines, setLines] = useState([])
	const menuId = ReadCookie('menuId');
	const [newLineCheck, setNewLineCheck] = useState(false)
	const [linesid, setLinesId] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [lineItems, setLineItems] = useState([]);
	const [customerPaymentNote, setcustomerPaymentNote] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [list1, setList] = useState([]);
	const [Lineidd, setLineIdd] = useState(null);
	const history = useHistory();
	const [id, setId] = useState(null);
	const [updateRecord, setUpdateRecord] = useState(false)
	const [resultArr, setresultArr] = useState([]);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [test, setTest] = useState(null);
	const [deletedItems, setDeletedItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	useEffect(() => {
		dispatch(getAllCompany(token));
		dispatch(getChartOfAccount(token));
		dispatch(getAdminPaymentTypes(token));
		dispatch(getGender(token, menuId));
	}, []);
	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);
	useEffect(() => {
		if (journalVoucherId && voucherID) {


			dispatch(getOneJournalVoucherLine(token, journalVoucherId)).then((res) => {
				let arr = [...lines]
				let count = res?.payload?.data?.length;
				for (let i = 0; i < count; i++) {
					arr.shift()
				}
				let values = res?.payload?.data?.reverse()
				for (let i = 0; i < count; i++) {
					arr.unshift({ ...values[i], id: i + 1 })
				}
				setLines(arr)
			});
		}
	}, [journalVoucherId, voucherID, updateRecord])

	useEffect(() => {
		if (formState === "formView") {
			dispatch(getGLImpact(token, { "transaction_header_id": journalVoucherId, "transaction_type": "JV" }));
			dispatch(getSystemLog(token, { table_name: "gl_voucher_header", record_id: journalVoucherId }));
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				postingPeriod: test,
				memo: null,
				voucher_date: oDate,
				subsidiary: 3,
				voucherNo: null,
				gender: null,
			});
			setOrderItems([]);
		}
	}, [formState]);



	useEffect(() => {
		if (formData) {
			setHeaderId(formData[0]?.voucher_header_id)
			form.setFieldsValue({
				voucher_date: moment(formData[0]?.voucher_date) && moment(formData[0]?.voucher_date),
				// date:formData[0].refund_date,
				voucherNo: Number(formData[0]?.voucher_header_no) && Number(formData[0]?.voucher_header_no),
				postingPeriod: formData[0]?.gl_period_id && formData[0]?.gl_period_id,
				memo: formData[0]?.memo && formData[0]?.memo,
				subsidiary: formData[0]?.company_id && formData[0]?.company_id,
				gender: formData[0]?.gender_name && formData[0]?.gender_name,
				// account_id:linesid.account_id,
				// memo: linesid && linesid.memo
			});
		}
	}, [formData]);

	useEffect(() => {
		if (linesid) {
			form.setFieldsValue({

			});
		}
	}, [linesid]);



	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneJournalVoucherLine(token, journalVoucherId)).then((res) => {
				setLines(res?.payload?.data.map((item) => {
					setLoading(false)
					return {
						...item,
						id: item.voucher_line_id,
					}
				}));

			});
		}
	}, [formState])

	useEffect(() => {
		let filtered = orderItems.filter((f) => f.isSelected === true);
		setVisibleItems(filtered);
	}, [orderItems]);

	useEffect(() => {
		let filtered = customerPaymentNote.filter((g) => g.isSelected === true);
		setVisibleItems(filtered);
	}, [customerPaymentNote]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	window.addEventListener('keydown', function (e) { if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) { if (e.target.nodeName == 'INPUT' && e.target.type == 'text') { e.preventDefault(); return false; } } }, true);


	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}


	const onFinish = async (values) => {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			if (formState === "add") {
				if (lines.length > 0) {
					dispatch(addJournalVoucherHeader(token, values, parseInt(companyId))).then((res) => {

						setId(res?.payload?.voucher_header_id)
						let headerID = res?.payload?.voucher_header_id
						setHeaderId(headerID)
						setVoucherID(headerID)
						if (res.type === "ADD_JOURNAL_VOUCHER_HEADER_SUCCESS") {
							setVoucherID(headerID)
							if (headerID) {
								dispatch(getOneJournalVoucherHeader(token, headerID)).then(() => {
									// datas.voucher_header_id = headerID;
									// 
									let rest = lines.map(({ id, credit, debit, ...rest }) => {
										return {
											...rest, voucher_header_id: headerID,
											credit: (credit === "") ? 0 : credit,
											debit: (debit === "") ? 0 : debit,
										}
									});
									const remove = lines.map((item) => {
										return {
											...item,
											credit: (item.credit == "") ? 0 : item.credit,

										}
									});


									if (headerID) {
										dispatch(addJournalVoucherLines(token, rest)).then((res) => {
											if (res.type === "ADD_JOURNAL_VOUCHER_LINE_SUCCESS") {


												dispatch(getOneJournalVoucherLine(token, headerID)).then((res) => {
													let arr = [...lines]
													let count = res?.payload?.data?.length;
													for (let i = 0; i < count; i++) {
														arr.shift()
													}
													let values = res?.payload?.data?.reverse()
													for (let i = 0; i < count; i++) {
														arr.unshift({ ...values[i], id: i + 1 })
													}
													setLines(arr)
												});
												notification("success", "Journal Voucher Created", "Journal Voucher Created Successfully!", res?.payload?.data?.data);
												setLineId(res?.payload?.voucher_line_id)
												setLoading(false)
												history.push({
													pathname: `/journal-voucher/edit/${headerID}`,
													search: `?_id=${headerID}`
												})

												// formState("edit")
												// changeState("formView")
												//disable(true)
											} else if (res.type === "ADD_JOURNAL_VOUCHER_LINE_ERROR") {
												Swal.fire({
													icon: "error",
													title: "Oops...",
													text: res?.payload?.data?.data,
												});
											}

											dispatch(getOneJournalVoucherLine(token, headerID)).then(res => {
												let arr = [...lines]
												let count = res?.payload?.data?.length;
												for (let i = 0; i < count; i++) {
													arr.shift()
												}
												let values = res?.payload?.data?.reverse()
												for (let i = 0; i < count; i++) {
													arr.unshift({ ...values[i], id: i + 1 })
												}
												setLines(arr);

											});
										});
									}
								});
							}

						} else if (res.type === "ADD_JOURNAL_VOUCHER_HEADER_ERROR") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.data,
							});
						}
					})
				}
				else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "select atleast one JournalVoucher line",
					});
					return
				}
			}

			else if (formState === "edit") {
				if (deletedItems.length > 0) {
					dispatch(deleteJournalVoucherLine(token, journalVoucherId, deletedItems));
				}

				let rest = lines.map(({ id, last_updated_date,last_updated_by, ...rest  }) => {
					return {
						...rest,
						
					}
				});
				console.log(rest, 'rest');
				const oldLines = rest.filter((value) => {
					if (value.voucher_line_id) {
						return value
					}
				})

				dispatch(updateJournalVoucherLine(token, oldLines, journalVoucherId)).then((res) => {
					if (res.type === "UPDATE_JOURNAL_VOUCHER_LINE_SUCCESS") {
						setLoading(false);
						changeState("formView")
						notification("success", "Journal Voucher Updated", "Journal Voucher Updated Successfully!");
					}

				});

				dispatch(updateJournalVoucherHeader(token, values, id ? id : journalVoucherId)).then((res) => {
					if (res.type === "UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS") {
						Object.keys(values).forEach(key => {
							if (values[key] === undefined) {
								delete values[key];
							}
						});
					}

				})


				const newLines = rest.filter((value) => {
					if (!value.voucher_line_id) {
						setNewLineCheck(true);
						return (
							journalVoucherId,
							value)
					}

				})

				let checkNewLine = rest.filter((item) => {
					if (item.voucher_line_id && item.account_id) {
						return item
					}

					return item
				})

				if (checkNewLine.length > 0) {


					dispatch(addJournalVoucherLine(token, newLines, journalVoucherId, journalVoucherId)).then((res) => {
						setLineIdd(res?.payload?.voucher_line_id)
						delete rest.voucher_line_id
						if (res.type === "ADD_JOURNAL_VOUCHER_LINE_SUCCESS") {
							setLoading(false);
							// notification("success", "Journal Voucher Updated", "Journal Voucher Updated Successfully!");
							setVoucherID(journalVoucherId)
							setUpdateRecord(!updateRecord);
						}
					});

				}
				setLoading(false);
			}
		})
	};

	const handleTab = (key) => {
		if (key === "header") {
		  dispatch(getSystemLog(token, { table_name: "gl_voucher_header", record_id: journalVoucherId }));
		} else if (key === "line") {
		  dispatch(getSystemLog(token, { table_name: "gl_voucher_lines", record_id: journalVoucherId }));
		} 
	  };
	const showModal = () => {
		setIsModalVisible(true);
	};

	const onSelectCustomer = (e) => {
		setCustomer(e);
		dispatch(getJournalVoucherDetails(token, e)).then((res) => {
			if (res.type === "GET_JOURNAL_VOUCHER_DETAIL_SUCCESS") {
				setOrderItems(res.payload.data);
				setList(res.payload.data);

			}
		});
	};
	console.log(form.getFieldValue().customer, 'found')

	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...value];
			//remove Duplicates
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		} else {
			//remove Duplicates
			let uniqueOrderItems = [...visibleItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {

		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const handleCustomerChanges = (val) => {
		if (val === "" || val === 'All') return
		setCustomer(val);
		dispatch(loadCustomerVendor(token, null, null, null, null, null, val)).then(
			(res) => {
				if (res?.type === "GET_ALL_CUSTOMER_VENDOR_SUCCESS") {
					console.log(res?.payload?.data, 'found454');
					setCustomer(res?.payload?.data)
				}
			}
		);
	};

	function debounce(cb, delay = 1000) {
		let timeout
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

	const current = new Date();
	const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
	const result = journalVoucherHeader.payload && journalVoucherHeader.payload[0]?.refund_header_no;

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}
	const columns = [
		// {
		// 	title: "Account",
		// 	dataIndex: "account_id",
		// 	render: (text, record) => {

		// 		return (
		// 			<Brow>

		// 				<Bcol lg={3} xxl={3}>
		// 					<div className="firstSectionRo">
		// 						<Form.Item labelAlign="left" labelCol={{ span: 5 }}  >
		// 							<Select
		// 								value={record.account_id === "" ? null : record.account_id}
		// 								size="small"
		// 								style={{ width: 250 }}
		// 								tabIndex={18}
		// 								autoComplete={"nope"}
		// 								allowClear
		// 								disabled={!(formState === "add" || formState === "edit")}
		// 								loading={chartOfAccount.loading}
		// 								// placeholder={"Select Account"}
		// 								onChange={(value) => {

		// 									let arr = [...lines];

		// 									let found = arr.find(i => i.id === record.id);
		// 									if (found) {
		// 										found.account_id = value
		// 									}

		// 									setLinesId(found)
		// 									setLines(arr.map(f => {
		// 										if (f.id === found.id) {
		// 											return found
		// 										} else {
		// 											return f
		// 										}
		// 									}))
		// 								}}
		// 							>
		// 								{chartOfAccount?.payload?.data.map((item) => {
		// 									return (
		// 										<Option key={item.account_id} value={item.account_id}>
		// 											{item.account_name}
		// 										</Option>
		// 									);
		// 								})}
		// 							</Select>
		// 						</Form.Item>

		// 					</div>
		// 				</Bcol>
		// 			</Brow>
		// 		)
		// 	}
		// },
		{
			title: "Account",
			dataIndex: "account_id",
			render: (text, record) => {

				const findCustomer = chartOfAccount && chartOfAccount?.payload?.data;


				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item
								labelAlign="left"
								labelCol={{ span: 6 }}
							>
								<Select
									size="small"
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								  }
									tabIndex={18}
									autoComplete={"nope"}
									style={{ width: 250 }}
									allowClear
									showSearch
									disabled={!(formState === "add" || formState === "edit")}
									optionFilterProp="children"
									className="filter-fields"
									// placeholder={"Select Customer"}
									onSelect={(e) => {
										let arr = [...lines];
										let found = arr.find(i => i.id === record.id);
										if (found) {
											found.account_id = e
										}
										setLines(arr.map(f => {
											if (f.id === found.id) {
												return found
											} else {
												return f
											}
										}))

									}}
									loading={chartOfAccount?.loading}

									onSearch={(value) => {
										optimizedFn(value)
									}}
									ref={focusInput}
									value={record.account_id}
								>
									{chartOfAccount?.payload?.data.map(
										(item) => {
											return (
												<Option
													key={item.account_id}
													value={item.account_id}
												>
													{`${item.account_id} ${item.account_name}`}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.debit}
								style={{ width: 250 }}
							>
								<InputNumber

									onChange={(e) => {
										let arr = [...lines];
										let found = arr.find(i => i.id === record.id);


										if (found) {
											found.debit = e
										}
										setLinesId(found)
										setLines(arr.map(f => {
											if (f.id === found.id) {
												return found
											} else {
												return f
											}
										}))
									}}
									value={record?.debit === "" ? null : record?.debit}
									tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.credit}
								style={{ width: 250 }}
							>
								<InputNumber

									onChange={(e) => {
										let arr = [...lines];
										let found = arr.find(i => i.id === record.id);
										if (found) {
											found.credit = e
										}
										setLinesId(found)
										setLines(arr.map(f => {
											if (f.id === found.id) {
												return found
											} else {
												return f
											}
										}))
									}}
									value={record?.credit === "" ? null : record?.credit}
									tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Memo",
			dataIndex: "memo",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.memo}
							>
								<Input
									style={{ width: 250 }}
									onChange={(e) => {
										let arr = [...lines];
										let found = arr.find(i => i.id === record.id);
										if (found) {
											found.memo = e.target.value
										}
										setLinesId(found)
										setLines(arr.map(f => {
											if (f.id === found.id) {
												return found
											} else {
												return f
											}
										}))
									}}
									value={record?.memo === "" ? null : record?.memo}
									tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Customer",
			dataIndex: "customer_id",
			render: (text, record) => {
				const findCustomer = customerVendor && customerVendor?.payload?.data;


				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item
								labelAlign="left"
								// name="customer"
								labelCol={{ span: 6 }}
							>
								<Select
									size="small"
							
									tabIndex={18}
									autoComplete={"nope"}
									style={{ width: 250 }}
									allowClear
									showSearch
									disabled={!(formState === "add" || formState === "edit")}
									optionFilterProp="children"
									className="filter-fields"
									// placeholder={"Select Customer"}
									defaultValue={record.customer_code ? record.customer_code + ' ' + record.customer_name : ''}
									onSelect={(e) => {
										let arr = [...lines];
										let found = arr.find(i => i.id === record.id);
										if (found) {
											found.customer_id = e
										}
										setLines(arr.map(f => {
											if (f.id === found.id) {
												return found
											} else {
												return f
											}
										}))
										// form.setFieldsValue({
										// 	customer: found.customer_id
										// })
									}}
									loading={customerVendor?.loading}

									onSearch={(value) => {
										optimizedFn(value)
									}}
									ref={focusInput}
									// value={record.customer_code + ' ' + record.customer_name}
								>
									{customerVendor?.payload?.data?.map(
										(item) => {
											return (
												<Option
													key={item.customer_id}
													value={item.customer_id}
												>
													{`${item?.customer_code} ${item?.customer_name}`}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "operation",
			width: 80,
			dataIndex: "operation",
			render: (_, record, index) => (
				<Tooltip title="Delete" color={"white"}>
					{ formState==="formView"?"":
					<>
						<Popconfirm
							title={`Are you sure to delete the item ${record.id}?`}
							onConfirm={() => deleteItemRow(record)}
						>
							<DeleteOutlined className={`delete-Button`} />
						</Popconfirm>
						{/* <EditOutlined
							className={`edit-Button`}
							onClick={() => {
								showEditModal(record, orderItems.findIndex((v) => v.item_code === record.item_code))
								setItemIndex(orderItems.findIndex((v) => v.item_code === record.item_code))
							}}
						// onClick={() => showEditModal(record)}
						/> */}
					</>
		}
				</Tooltip>
			),
		}



	];
	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]
	const addLines = () => {
		const data = [...lines];
		const iterateId = Math.floor(Math.random() * 100);
		const newItem = { id: iterateId, credit: 0, debit: 0, memo: '', customer_id: '', account_id: '' }
		if (lines.length <= 50) {
			data.push(newItem);
			setLines(data);

		} else {
			notification("error", "Only 50 Lines Item can be Edit");
		}
	}

	// const deleteItemRow = (id,record) => {
	// 	console.log(id,"123====>");
	// 	const data = [...lines];

	// 	if (data.length > 0) {

	// 		setLines(data.filter((val) => val.id !== id));
	// 	}
	// 	// const deletedRow = ;

	// }
	const deleteItemRow = (record) => {
		const dataSource = [...lines];
		const deleteSource = [...deletedItems];
		const deleteEditableItem = [...filteredItems];
		deleteSource.push(record)
		setDeletedItems(deleteSource);
		setLines(
			dataSource.filter((item) => item.id !== record.id)
		);
		setFilteredItems(
			deleteEditableItem.filter((item) => item.id !== record.id)
		);
		
	};



	function checkTransactionDate(e){
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r)=> {
		  setLoading(false)
		  if (r.payload.data.length > 0) {
			setOrderDate(moment())
			  form.setFieldsValue({
				voucher_date: moment()
			  })
			  Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
		  }
		  else{
			setOrderDate(moment(e))
		  }
		});
	  }




	return (
		<>
			<EditableTable
				token={token}
				companyId={companyId}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				setShowGrid={setShowGrid}
			/>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{journalVoucherFormData.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))
				}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={9} xxl={8} className="pbpx-10">
								<Brow>

									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">

											<Form.Item name="voucherNo" label="Voucher #" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().voucherNo} >
												<Input tabIndex={18} size="small" className="customInputs" disabled={true} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item label="Date" name="voucher_date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Date is required" }]}>

												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													// placeholder="Transfer Date"
													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().voucher_date
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id });
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											{/* <Form.Item label="Posting Period" name="PostingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}> */}
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod} rules={[{ required: true, message: "PostingPeriod is required" }]}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													// defaultValue={form.getFieldValue().postingPeriod}
													// placeholder="Posting Period"
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }} >
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="slc">
											<Form.Item
												name="gender"
												label="Gender"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Gender is required" }]}
											>
												<Select
													size='small'
													showSearch
													optionFilterProp="children"
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												  }
													className="styleInput w-100"
													defaultValue={form.getFieldValue().gender}
													disabled={!(formState === "add" || formState === "edit")}
												>

													{
														gender?.payload?.data.map((val) => {
															return (
																<Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
															)
														})
													}

												</Select>
											</Form.Item>
										</div>
									</Bcol>

								</Brow>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					{/* <Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>

					</Tabs> */}
				</div>
				<SubTitles name={"Classification"} />
				<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
					<Brow>
						<Bcol md={12} xl={9} xxl={8} className="pbpx-10">
							<Brow>

								<Bcol lg={6} xxl={6}>
									<div className="firstSectionRo">
										{/* <Form.Item label="Posting Period" name="PostingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}> */}
										<Form.Item label="Subsidiary" name="subsidiary" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().subsidiary} rules={[{ required: true, message: "Subsidiary is required" }]}>
											<Select
												size="small"
												tabIndex={18}
												autoComplete={"nope"}
												allowClear
												showSearch
												optionFilterProp="children"
												className="filter-fields"
												disabled={!(formState === "add" || formState === "edit")}
												loading={invoiceGlPeriod.loading}
												defaultValue={form.getFieldValue().subsidiary}
												// defaultValue={form.getFieldValue().postingPeriod}
												// placeholder="Subsidiary"
												onChange={(value) => {
													form.setFieldsValue({
														subsidiary: value,
													});
												}}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>

							</Brow>
						</Bcol>

					</Brow>

					<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Voucher Line Information" key="1">
							<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={addLines}>
								Add Item
							</Button>

							{(lines.length > 0) && (
								<Table
									type={"company"}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									style={{ paddingTop: 10 }}
									dataSource={lines ? lines : []}
									columns={columns}
									pagination={false}
								/>
							)}

						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact?.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
							  <Tabs
                type="card"
                size="small"
                defaultActiveKey="1"
                centered
              onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Header" key="header">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                <TabPane tab="Line" key="line">
                <div className="tabs">
                  {systemLog?.payload?.data?.length > 0 && <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    perPage={15}
                    source={systemLog?.payload?.data}
                    dataSource={[]}
                    col={renderColumns()}
                    load={systemLog.loading}
                    paginate
                  />}
                </div>
                </TabPane>
                  </Tabs>
							</TabPane>}
					</Tabs>


					{/* lines */}


				</div>
			</Form>
		</>
	);
}