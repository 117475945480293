import {
  GET_PICKTICKET_SUMMARY_REQUEST,
  GET_PICKTICKET_SUMMARY_SUCCESS,
  GET_PICKTICKET_SUMMARY_ERROR,
  GET_PICK_TICKET_DETAIL_REQUEST,
  GET_PICK_TICKET_DETAIL_SUCCESS,
  GET_PICK_TICKET_DETAIL_ERROR,
  GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_REQUEST,
  GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_SUCCESS,
  GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getPickTicketSummary = (token, values) => (dispatch) => {
  let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
  dispatch({ type: GET_PICKTICKET_SUMMARY_REQUEST });
  const newData = {
    customer_cat_id: values.cutomer_category === "All" ? null : values.cutomer_category,
    // date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
    sp_id: values.sale_person === "All" ? null : values.sale_person,
    region_id: values.region === "All" ? null : values.region,
    gender_id: values.gender === "All" ? null : values.gender,
    payment_term_id: values.terms === "All" ? null : values.terms,
    store_id: values.store === undefined || values.store === "" ? null : values.store,
    company_id: values.subsidary === "All" ? null : values.subsidary,
    source_type: values.source === undefined || values.source === "" ? null : values.source,
    sku: values.sku === undefined || values.sku === "" ? null : values.sku,
    factor_decision: values.factor_decision === undefined || values.factor_decision === "" ? null : values.factor_decision,
    status: values.status === undefined || values.status === "" ? null : values.status,
    location_id: values.location === undefined || values.location === "All" ? null : values.location
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/funcGetOmsCreatePt`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_PICKTICKET_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICKTICKET_SUMMARY_ERROR,
        payload: [],
      });
    });
};


export const getPickTicketDetail = (token, values) => (dispatch) => {
  let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
  dispatch({ type: GET_PICK_TICKET_DETAIL_REQUEST });
  const newData = {
    customer_cat_id: values.cutomer_category === "All" ? null : values.cutomer_category,
    // date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
    billcustomer: values.billing_addressee === undefined || values.billing_addressee === "" ? null : values.billing_addressee,
    sp_id: values.sale_person === "All" ? null : values.sale_person,
    region_id: values.region === "All" ? null : values.region,
    gender_id: values.gender === "All" ? null : values.gender,
    payment_term_id: values.terms === "All" ? null : values.terms,
    store_id: values.store === undefined || values.store === "" ? null : values.store,
    company_id: values.subsidary === "All" ? null : values.subsidary,
    source_type: values.source === undefined || values.source === "" ? null : values.source,
    sku: values.sku === undefined || values.sku === "" ? null : values.sku,
    factor_decision: values.factor_decision === undefined || values.factor_decision === "" ? null : values.factor_decision,
    status: values.status === undefined || values.status === "" ? null : values.status,
    location_id: values.location === undefined || values.location === "All" ? null : values.location
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/createptDetail`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_PICK_TICKET_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICK_TICKET_DETAIL_ERROR,
        payload: [],
      });
    });
};


export const getPendingAvailability = (token, values) => (dispatch) => {
  let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
  dispatch({ type: GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_REQUEST });
  const newData = {
    customer_cat_id: values.cutomer_category === "All" ? null : values.cutomer_category,
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
    reference_no: values.po_no === undefined || values.po_no === "" ? null : values.po_no,
    customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
    sp_id: values.sale_person === "All" ? null : values.sale_person,
    region_id: values.region === "All" ? null : values.region,
    gender_id: values.gender === "All" ? null : values.gender,
    payment_term_id: values.terms === "All" ? null : values.terms,
    store_id: values.store === undefined || values.store === "" ? null : values.store,
    company_id: values.subsidary === "All" ? null : values.subsidary,
    source_type: values.source === undefined || values.source === "" ? null : values.source,
    sku: values.sku === undefined || values.sku === "" ? null : values.sku,
    factor_decision: values.factor_decision === undefined || values.factor_decision === "" ? null : values.factor_decision,
    status: values.status === undefined || values.status === "" ? null : values.status,
    location_id: values.location === undefined || values.location === "All" ? null : values.location
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/PendingOrderSummaryOts`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_ERROR,
        payload: [],
      });
    });
};