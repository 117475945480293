import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from 'antd';
import { loadRegionCategory, addRegionCategory, updateRegionCategory, deleteRegionCategory } from '../../redux/actions/regionCategory';
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import { addCustomerCategory, deleteCategory, getAllCustomerCategory, loadCategory, updateCategory, updateCustomerCategory } from '../../redux/actions/customerCategory';
import { getOneCustomerCategory } from '../../redux/actions/customerCategory';
import { getAllSaleperson } from '../../redux/actions/salesPerson';
import { getRights } from "../../redux/actions/userRights.action";
import { getRegion } from '../../redux/actions/region.action';
import { getLog } from '../../redux/actions/log.action';

import { logColumns, customerCatCols } from '../../utils/cols';
import { ReadCookie } from '../../utils/readCookie';

import CrudButtons from "../../components/CrudButtons/CrudButtons";
import PageHeader from '../../components/PageHeader/PageHeader';
import notification from '../../utils/notification';
import Tables from "../../components/Table/Tables";
import "../Style/style.css";
import { getBilling } from '../../redux/actions/billing';
import { getOneCustomer, loadCustomers } from "../../redux/actions/customerForm";
import { getGender } from '../../redux/actions/gender.action';
import { getRegionCategory } from '../../redux/actions/regionCategoryById';
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;
const { Option } = Select
const CustomerCategory = ({ token }) => {
  TabTitle("Customer Category")
  const dispatch = useDispatch()
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState('view');
  const [customerCatObj, setCustomerCatObj] = useState(null);
  const [form] = Form.useForm();
  const focusInput = useRef(null);
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);

  const { regionCategory, salePerson, region, userRights, log, Category, customerForms, gender, invoiceGlPeriod, companyAll, onecustomerCategory } = useSelector(state => state)
  const [check, setCheck] = useState(true);
  const [regionid, setRegionId] = useState(null);
  const [salePersonID, setSalePersonID] = useState(null);
  const [personid, setPersonId] = useState(null);
  const [disable, setDisable] = useState(false);
  const id = ReadCookie('menuId');

  useEffect(() => {
    dispatch(getAllCustomerCategory(token))
    dispatch(getGender(token, id));
    dispatch(loadRegionCategory(token, id));
    dispatch(getRegion(token, id))
    dispatch(getAllSaleperson(token, id))
    dispatch(getOneCustomerCategory(token, id))
    dispatch(getLog(token, { scm_sp_region_category: "scm_sp_region_category" }))
  }, [])

  useEffect(() => {
    if (customerCatObj) {
      setDisable(false)
    }
    if ((customer || personid) === null) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [customerCatObj, salePersonID, regionid])

  useEffect(() => {
    if (customerCatObj) {
      dispatch(getOneCustomer(token, customerCatObj.customer_id)).then((res) => {
        setCustomer(res?.payload)
        form.setFieldsValue({
          id: customerCatObj.customer_sales_category_id,
          customer_id: res?.payload?.customer_name,
          gender_id: customerCatObj.gender_id,
          region_id: customerCatObj.region_name,
          billing_id: res?.payload?.default_bill_address === null ? "N/A" : res?.payload?.default_bill_address,
          active: customerCatObj.is_active,
          sp_id: customerCatObj.salesperson_name == " " ? "N/A" : customerCatObj.salesperson_name,
          category_id: customerCatObj.sp_region_category_id
        });
      })
      setFormState('view')
      // let findItemShipping;
      // let billingName;
      // dispatch(getBilling(token, customerCatObj.customer_id)).then((res) => {
      //   findItemShipping = res.payload?.data.find((item) => {
      //     return item.default_ship_to === true;
      //   });
      //   billingName = findItemShipping.addressee_bill_to + " " + findItemShipping.zip_code_bill_to;
      // });
      // 
      setPersonId(customerCatObj.sales_person_id)
      setRegionId(customerCatObj.region_id)
      setCheck(customerCatObj.is_active)
    }


  }, [customerCatObj]);
  const handleChange = (id) => {
    dispatch(getOneCustomerCategory(token, id))
  }




  const handleDelete = () => {
    if (customerCatObj) {
      dispatch(deleteCategory(token, customerCatObj.customer_sales_category_id)).then((res) => {
        if (res.type === "DELETE_CATEGORY_SUCCESS") {
          notification("success", "Category deleted", "Category deleted successfully!");
        }
        if (res.type === "DELETE_CATEGORY_ERROR") {
          notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
        }
        form.setFieldsValue({
          id: null,
          customer_id: null,
          region_id: null,
          gender_id: null,
          customer_id: null,
          category_id: null,
          sp_id: null,
          billing_id: null
        })
        setCustomerCatObj(null)
        setPersonId(null)
        setRegionId(null)
        setFormState('view')
        setCheck(false)

        dispatch(getAllCustomerCategory(token, id))
      })
    }
    setFormState("view")
  }

  useLayoutEffect(() => {
    dispatch(getRights(token, id)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table")
        }
      }
    });
  }, [])

  const formSave = async () => {
    await form.validateFields().then((values) => {




      if (formState === "add") {
        dispatch(addCustomerCategory(token, { ...values, customer, salePersonID, regionid, is_active: check },)).then((res) => {
          if (res.type === "ADD_CUSTOMER_CATEGORY_ERROR") {
            notification("error", "Not Created", res?.payload?.data?.data)
          }
          if (res.type === "ADD_CUSTOMER_CATEGORY_SUCCESS") {
            notification("success", "Category created", "Category created successfully!");
            form.setFieldsValue({
              id: null,
              customer_id: null,
              region_id: null,
              gender_id: null,
              customer_id: null,
              category_id: null,
              sp_id: null,
              billing_id: null
            })
            setFormState("view")
            setCheck(false)
            setCustomerCatObj(null)
          }
          dispatch(getAllCustomerCategory(token, id))

        })
      }
      if (formState === "edit") {
        dispatch(updateCustomerCategory(token, { ...values, customer_id: customer.customer_id, salePersonID, regionid, is_active: check }, customerCatObj.sp_region_category_id)).then((res) => {
          if (res.type === "UPDATE_CATEGORY_ERROR") {
            notification("error", "Update", "Something went wrong")
          }
          if (res.type === "UPDATE_CATEGORY_SUCCESS") {
            notification("success", "Customer Category", "Record updated successfully")
            form.setFieldsValue({
              id: null,
              customer_id: null,
              region_id: null,
              gender_id: null,
              customer_id: null,
              category_id: null,
              sp_id: null,
              billing_id: null
            })
          }
          setCheck(false)
          dispatch(getAllCustomerCategory(token, id))
        })
      }
    }).catch((err) => {

    })
  }

  useEffect(() => {
    if (formState === "add") {
      form.setFieldsValue({
        id: null,
        customer_id: null,
        region_id: null,
        gender_id: null,
        customer_id: null,
        category_id: null,
        sp_id: null,
        billing_id: null
      })
      setCheck(true)
      setRegionId(null)
      setPersonId(null)
      setCustomerCatObj(null)
    }
  }, [formState]);

  const filterReset = () => {
    setFormState("view")
    setCustomerCatObj(null)
    setCheck(true)
    form.setFieldsValue({
      cid: null,
      sid: null
    })
    setRegionId(null)
    setPersonId(null)
  }

  const selectHandlerRegion = (e) => {
    if (formState === "edit") {
      customerCatObj.region_id = e
    } else {
      setRegionId(e)

    }
  }

  const selectHandlerPeron = (e) => {
    if (formState === "edit") {
      customerCatObj.salePerson_id = e
    } else {
      setPersonId(e)
    }
  }

  const onSelectCustomer = (e) => {
    setCustomer(e)
    dispatch(getBilling(token, e)).then((res) => {
      const findItemShipping = res.payload?.data.find((item) => {
        return item.default_ship_to === true;
      });
      const billingName = findItemShipping.addressee_bill_to + " " + findItemShipping.zip_code_bill_to;
      form.setFieldsValue({
        billing_id: billingName,
      });
    });
  };

  const onSelectRegion = (e) => {
    dispatch(getRegionCategory(token, `${e}`)).then((regionCatg) => {
      setRegionId(regionCatg.payload.data[0].region_id,)
      setSalePersonID(regionCatg.payload.data[0].sales_person_id)
      form.setFieldsValue({
        sp_id: regionCatg.payload.data[0].salesperson_name === " " ? "N/A" : regionCatg.payload.data[0].salesperson_name,
        region_id: regionCatg.payload.data[0].region_name === " " ? "N/A" : regionCatg.payload.data[0].region_name,
      });

    });
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleCustomerChanges = (val) => {
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

  return <div className='styleContainer'>
    <div className='styleBody h-100' >
      <PageHeader title={"Customer Category"} minimize={setMinimize} check={minimize} />
      {
        !minimize &&
        <div className='bodyStyle' >
          <div className='crudDiv' >
            <CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} reset={filterReset} disable={disable} save={formSave} onDelete={handleDelete} /></div>
          <div className='styleContent' >
            <Row className='tables-main-row-new' >
              <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                <div className='styleTable' >
                  <Tables type="customerCat" formState={formState} customerCatObj={setCustomerCatObj} source={onecustomerCategory?.payload?.data} col={customerCatCols} paginate scroll={500} perPage={25} load={onecustomerCategory.loading} rowKey="customer_sales_category_id" />
                </div>
              </Col>
              <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                <div className='styleForm'  >
                  <div className='testStyle' >
                    <Form
                      form={form}
                      name="basic"
                    // labelCol={{ span: 8 }}
                    >
                      <div className='customRow ' >
                        <div className="customCols slc">
                          <div className='relative '>
                            <Form.Item
                              labelAlign="left"
                              name="customer_id"
                              label="Customer"
                              labelCol={{ span: 5 }}
                            >
                              <Select
                                notFoundContent={
                                  customerForms.loading ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Loader
                                        type="Oval"
                                        color="#3c4b58"
                                        height={20}
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      No Data
                                    </div>
                                  )
                                }
                                size="small"
                                tabIndex={18}
                                autoComplete={"nope"}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="filter-fields"
                                onSelect={(e) => onSelectCustomer(e)}
                                onChange={(id) => handleChange(id)}
                                // onChange = {(e)=> handleChange(e, "customer") }
                                // onSelect = {(e)=>handleChange(e)}
                                loading={customerForms?.loading}
                                // placeholder="Select Customer"
                                onSearch={(value) => {
                                  optimizedFn(value);
                                }}
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                                ref={focusInput}
                                value={customer}
                              >
                                {customerForms?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.customer_id}
                                      value={item.customer_id}
                                    >
                                      {`${item.customer_code} ${item.customer_name}`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              labelAlign="left"
                              name="category_id"
                              label="Category"
                              labelCol={{ span: 5 }}
                            >
                              <Select
                                size="small"
                                tabIndex={18}
                                autoComplete={"nope"}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="filter-fields"
                                onSelect={(e) => onSelectRegion(e)}
                              // placeholder="Select Category"
                              >
                                {regionCategory?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.sp_region_category_id}
                                      value={item.sp_region_category_id}
                                    >
                                      {`${item.sp_region_category_id}`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Active"
                              name="active"
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <Checkbox
                                style={{ marginLeft: "32px" }}
                                disabled={!(formState === "add" || formState === "edit")}
                                checked={check}
                                onChange={(e) => setCheck(e.target.checked)} />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="customCols slc">
                          <div className='relative '>
                            <Form.Item
                              labelAlign="left"
                              name="billing_id"
                              label="Billing"
                              labelCol={{ span: 6 }}
                            >
                              <Select
                                disabled={true}
                                notFoundContent={
                                  customerForms.loading ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Loader
                                        type="Oval"
                                        color="#3c4b58"
                                        height={20}
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      No Data
                                    </div>
                                  )
                                }
                                size="small"
                                tabIndex={18}
                                autoComplete={"nope"}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="filter-fields"
                                onSelect={(e) => onSelectCustomer(e)}
                                loading={customerForms?.loading}
                                // placeholder="Billing"
                                onSearch={(value) => {
                                  optimizedFn(value);
                                }}
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                                ref={focusInput}
                                value={customer}
                              >
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Sales Person"
                              name="sp_id"
                              labelAlign="left"
                              labelCol={{ span: 6 }}
                            // defaultValue={form.getFieldValue().sp_id}
                            >
                              <Select
                                size="small"
                                tabIndex={18}
                                autoComplete="nope"
                                allowClear
                                disabled={!(formState === "add" || formState === "edit")}
                                loading={salePerson.loading}
                                // defaultValue={form.getFieldValue().sp_id}

                                // defaultValue={form.getFieldValue().postingPeriod}
                                // placeholder="Sales Person"
                                onChange={(value) => { form.setFieldsValue({ sp_id: value }) }}
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                              >
                                {salePerson?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.sales_person_id}
                                      value={item.sales_person_id}
                                    >
                                      {item.salesperson_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            {customerCatObj !== null && !(formState === "add") &&

                              <Form.Item
                                label="ID"
                                name="id"
                                initialValue={form.getFieldValue().id}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                              >
                                <Input style={{ marginLeft: "23px" }} className='styleInput id-field-style' disabled />
                              </Form.Item>

                            }
                          </div>
                        </div>
                        <div className="customCols slc">
                          <div className='relative '>
                            <Form.Item label="Gender" name="gender_id" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().gender}>
                              <Select
                                size="small"
                                tabIndex={18}
                                autoComplete={"nope"}
                                allowClear
                                loading={gender.loading}
                                defaultValue={form.getFieldValue().gender}
                                // placeholder="Select Gender"
                                onChange={(value) => { form.setFieldsValue({ gender: value }) }}
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                              >
                                {gender?.payload?.data?.map((item) => {
                                  return (
                                    <Option key={item.gender_id} value={item.gender_id}>
                                      {item.gender_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Region"
                              name="region_id"
                              labelAlign="left"
                              labelCol={{ span: 5 }}
                            >
                              <Select
                                size="small"
                                tabIndex={18}
                                autoComplete={"nope"}
                                allowClear
                                disabled={!(formState === "add" || formState === "edit")}
                                loading={region.loading}
                                defaultValue={form.getFieldValue().region_id}
                                // placeholder="Region"
                                onChange={(value) => { form.setFieldsValue({ region_id: value }) }}
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                              >
                                {region?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.region_id}
                                      value={item.region_id}
                                    >
                                      {item.region_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <Divider />
                  <div className='styleTab' >
                    <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered >
                      <TabPane tab="Log Info" key="logs"  >
                        <div className='tabs' >
                          <Tables type="company" scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>

              </Col>

            </Row>
          </div>

        </div>
      }


    </div>
  </div>
}


export default CustomerCategory