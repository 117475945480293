import {
	GET_ONE_BILL_PAYMENT_LINE_REQUEST,
	GET_ONE_BILL_PAYMENT_LINE_SUCCESS,
	GET_ONE_BILL_PAYMENT_LINE_ERROR,
	ADD_BILL_PAYMENT_LINE_REQUEST,
	ADD_BILL_PAYMENT_LINE_SUCCESS,
	ADD_BILL_PAYMENT_LINE_ERROR,
	UPDATE_BILL_PAYMENT_LINE_REQUEST,
	UPDATE_BILL_PAYMENT_LINE_SUCCESS,
	UPDATE_BILL_PAYMENT_LINE_ERROR,
	DELETE_BILL_PAYMENT_LINE_REQUEST,
	DELETE_BILL_PAYMENT_LINE_SUCCESS,
	DELETE_BILL_PAYMENT_LINE_ERROR,
	GET_ALL_PENDING_BILL_CREADIT_REQUEST,
	GET_ALL_PENDING_BILL_CREADIT_SUCCESS,
	GET_ALL_PENDING_BILL_CREADIT_ERROR,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR,
	BILL_PAYMENT_IMPACT_REQUEST,
	BILL_PAYMENT_IMPACT_SUCCESS,
	BILL_PAYMENT_IMPACT_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");


export const addBillPaymentLine = (token, data, headerID) => (dispatch) => {


	// data.forEach((val) => val.payment_header_id = headerID);
	//  data.map((item) => {
	// 		delete item.bill_date,
	// 			delete item.bill_header_no,
	// 			// delete item.bill_header_id,
	// 			delete item.original_amount,
	// 			delete item.remaining_amount
	// 			delete item.bill_header_no,

	// 		{
	// 			...item,
	// 			payment_header_id: headerID
	// 		}
	// 	});
	dispatch({ type: ADD_BILL_PAYMENT_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/payBillPaymentLine`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data },
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_PAYMENT_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_PAYMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillPaymentLine = (token, data, id) => (dispatch) => {
	data.map((item) => {
		delete item.bill_date;
		delete item.bill_header_no;
		delete item.bill_header_id;
		delete item.original_amount;
		delete item.remaining_amount;
		// delete item.is_selected,
	});
	dispatch({ type: UPDATE_BILL_PAYMENT_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payBillPaymentLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data },
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_BILL_PAYMENT_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_PAYMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillPaymentLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_PAYMENT_LINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payBillPaymentLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_PAYMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_PAYMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};
export const getAllPendingBillCreadit = (token, id) => (dispatch) => {
	dispatch({ type: GET_ALL_PENDING_BILL_CREADIT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/pendingBillCredit`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { vendor_id: id }
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_PENDING_BILL_CREADIT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_PENDING_BILL_CREADIT_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillPaymentLine = (token, id, deletedItems) => (dispatch) => {
	console.log(deletedItems, "datoo");
	dispatch({ type: DELETE_BILL_PAYMENT_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payBillPaymentLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: deletedItems }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_PAYMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_PAYMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};


export const addPayablePaymentCreditTake = (token, data) => (dispatch) => {

	console.log("DATAAA", data);
	dispatch({ type: ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/payBillPaymentCreditTake`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data },
	})
		.then((response) => {
			return dispatch({
				type: ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR,
				payload: error.response,
			});
		});
};


export const BillPaymentImpact = (token, data) => (dispatch) => {
	dispatch({ type: BILL_PAYMENT_IMPACT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/billpaymentimpact`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { payment_header_id: data }
	})

		.then((response) => {
			return dispatch({ type: BILL_PAYMENT_IMPACT_SUCCESS, payload: response.data });
		})
		.catch((error) => {
			return dispatch({
				type: BILL_PAYMENT_IMPACT_ERROR,
				payload: error.response,
			});
		});
};
