import { GET_OMS_STOCK_SUMMARY_REPORT_ERROR, GET_OMS_STOCK_SUMMARY_REPORT_REQUEST, GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS } from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getOmsStockSummary = (token, data, seasonID,statusID, PCID, BgradeCheck) => (dispatch) => {



    dispatch({ type: GET_OMS_STOCK_SUMMARY_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getOtsSummaryOmsReport`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            as_on_date: moment(data.as_on_date).format("YYYY-MM-DD"),
            sku: (data.sku === "" || data.sku === "All") ? null : data.sku,
            // season_id: seasonID.length > 0 ? seasonID.toString() : null,
            season_id: data.season_id === "All" ? null : data.season_id,
            // category_id: data.gender_category_id === "All" ? null : data.gender_category_id,
            category_id: data.gender_category_id === "All" ? null : data.gender_category_id,
            // fit_category_id: data.fit_category_id === "All" ? null : data.fit_category_id,
            fit_category_id: null,
            style_id: data.style_id === "All" ? null : data.style_id,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            product_status_id: statusID.length > 0 ? statusID.toString() : null,
            // product_status_id: data.product_status_id,
            // product_class_id: PCID.length > 0 ? String(PCID) : null,
            product_class_id: null,
            sort_on: null,
            include_bgrade: "abc",
            wash_id: data.wash_id === "All" ? null : data.wash_id,
            new_sesaon_year_id: data.new_season_year_id === "All" ? null : data.new_season_year_id
        }
    })
        .then((response) => {
            return dispatch({ type: GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_OMS_STOCK_SUMMARY_REPORT_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_OMS_STOCK_SUMMARY_REPORT_ERROR,
                payload: error.response,
            });
        });
};
