import {
    ADD_CUSTOMER_UPLAOD_REQUEST, ADD_CUSTOMER_UPLAOD_SUCCESS, ADD_CUSTOMER_UPLAOD_ERROR, GET_CUSTOMER_UPLAOD_REQUEST, GET_CUSTOMER_UPLAOD_SUCCESS,
    GET_CUSTOMER_UPLAOD_ERROR, DOWNLOAD_CUSTOMER_FILE_REQUEST, DOWNLOAD_CUSTOMER_FILE_SUCCESS, DOWNLOAD_CUSTOMER_FILE_ERROR, UPDATE_CUSTOMER_UPLAOD_REQUEST, UPDATE_CUSTOMER_UPLAOD_SUCCESS, UPDATE_CUSTOMER_UPLAOD_ERROR,
} from "./types"
import { BASEURL } from "../../constant/config.js";
import { BASEURL1 } from "../../constant/config.js";
const axios = require("axios");

// export const getAllUploads = (token, menu_id) => (dispatch) => {
//     dispatch({ type: GET_CUSTOMER_UPLAOD_REQUEST });
//     return axios({
//         method: "POST",
//         url: `${BASEURL}getAll/fabric`,
//         headers: {
//             Accept: "application/json",
//             Authorization: "Bearer " + token,
//         },
//         data: { menu_id }
//     }).then((response) => {
//         return dispatch({ type: GET_ALL_CUSTOMER_UPLAOD_SUCCESS, payload: response.data })
//     }).catch((error) => {
//         return dispatch({ type: GET_ALL_CUSTOMER_UPLAOD_ERROR, payload: error.response })
//     })
// }

export const addCustomerUpload = (token, data, id, fileName) => (dispatch) => {

    dispatch({ type: ADD_CUSTOMER_UPLAOD_REQUEST });
    // const remove_Special_Characters = data[0].name.replace(/[^0-9a-zA-Z.]/g, "");

    // const bodyFormData = new FormData();
    // // bodyFormData.append('is_active', true)
    // // bodyFormData.append('customer_id', id)
    // // bodyFormData.append('upload_remarks', fileName)
    // // bodyFormData.append('file', data[0], remove_Special_Characters);
    // let files = [];
    // data.forEach((v, idx) => {
    //     bodyFormData.append("uploadFiles", v.file);
    //     bodyFormData.append("upload_remarks", data[idx].upload_remarks);
       
    // })
    // let ddd = {
    //     customer_id: bodyFormData.get('customer_id'),
    //     upload_remarks: bodyFormData.get('upload_remarks'),
    //     uploadFiles: files,
    // };

    // console.log("ddddd", data);


    function convertToFormData(dataArray) {
        const formData = new FormData();
      
        dataArray.forEach((item, index) => {
          const file = item.file;
          const uploadRemarks = item.upload_remarks || '';
      
          // Append the file and remarks to the FormData object
          formData.append(`uploadFiles`, file);
          formData.append(`upload_remarks`, uploadRemarks);
        });
      
        return formData;
      }

    
      const formData = convertToFormData(data);

    // bodyFormData.append('fileName', fileName)
    return axios({
        method: "POST",
        url: `${BASEURL}upload/customerComm/${id}`,
        data: formData,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data"
        },
    })
        .then((response) => {

            return dispatch({ type: ADD_CUSTOMER_UPLAOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: ADD_CUSTOMER_UPLAOD_ERROR,
                payload: error.response,
            });
        });
};

export const updateCustomerUpload = (token, data, id, fileName) => (dispatch) => {

    dispatch({ type: UPDATE_CUSTOMER_UPLAOD_REQUEST });


    return axios({
        method: "POST",
        url: `${BASEURL}upload/customerCommUpdate/${id}`,
        data: data,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token
        },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_CUSTOMER_UPLAOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: UPDATE_CUSTOMER_UPLAOD_ERROR,
                payload: error.response,
            });
        });
};

export const getCustomerUpload = (token, id) => dispatch => {
    dispatch({ type: GET_CUSTOMER_UPLAOD_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}upload/customerComm/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_CUSTOMER_UPLAOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_UPLAOD_ERROR,
                payload: error.response,
            });
        })
}

export const downloadCustomerFile = (token, fileName) => dispatch => {
    dispatch({ type: DOWNLOAD_CUSTOMER_FILE_REQUEST });
    return axios({
        method: "GET",
        // url: `${BASEURL}getOne/file/${fileName}`,
        url: `${BASEURL1}${"test.csv"}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: DOWNLOAD_CUSTOMER_FILE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DOWNLOAD_CUSTOMER_FILE_ERROR,
                payload: error.response,
            });
        })
}
