import {
    GET_SELL_ORDER_PENDING_STATE_REPORT_ERROR,
    GET_SELL_ORDER_PENDING_STATE_REPORT_REQUEST,
    GET_SELL_ORDER_PENDING_STATE_REPORT_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getOmsPendingStateReport = (token, text, data) => (dispatch) => {

    dispatch({ type: GET_SELL_ORDER_PENDING_STATE_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getSalePendingOrderOmsStateReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: data.from_date,
            to_date: data.to_date,
            // gender_id: data.gender_id === "All" ? null : data.gender_id,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            customer_type_id: data.customer_type_id === "All" ? null : data.customer_type_id,
            region_id: data.region_id === "All" ? null : data.region_id,
            company_id: data.company_id
            // from_date: moment(data.from_date).format("YYYY-MM-DD"),
            // to_date: moment(data.to_date).format("YYYY-MM-DD"),
            // // gender_id: data.gender_id === "All" ? null : data.gender_id,
            // gender_id: data.gender_id === "All" ? null : Number(data.gender_id),
            // term_id: data.term_id === "All" ? null : Number(data.term_id),
            // region_id: data.region_id === "All" ? null : Number(data.region_id),
            // company_id: Number(data.company_id)
            //=== "All" ? null : data.term_id,

        }
    })
        .then((response) => {
            return dispatch({ type: GET_SELL_ORDER_PENDING_STATE_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SELL_ORDER_PENDING_STATE_REPORT_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SELL_ORDER_PENDING_STATE_REPORT_ERROR,
                payload: error.response,
            });
        });
};
