import {
	ADD_SALEORDER_HEADER_SUCCESS,
	ADD_SALEORDER_HEADER_ERROR,
	ADD_SALEORDER_HEADER_REQUEST,
	GET_ALL_SALEORDER_REQUEST,
	GET_ALL_SALEORDER_SUCCESS,
	GET_ALL_SALEORDER_ERROR,
	DELETE_SALEORDERHEADER_REQUEST,
	DELETE_SALEORDERHEADER_SUCCESS,
	DELETE_SALEORDERHEADER_ERROR,
	UPDATE_SALEORDER_HEADER_REQUEST,
	UPDATE_SALEORDER_HEADER_ERROR,
	UPDATE_SALEORDER_HEADER_SUCCESS,
	GET_ONE_SALEORDERHEADER_SUCCESS,
	GET_ONE_SALEORDERHEADER_REQUEST,
	GET_ONE_SALEORDERHEADER_ERROR,
	UPDATE_SALE_ORDER_ISCLOSED_REQUEST,
	UPDATE_SALE_ORDER_ISCLOSED_SUCCESS,
	UPDATE_SALE_ORDER_ISCLOSED_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_SALEORDER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_SALEORDER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case ADD_SALEORDER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_SALEORDER_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_SALEORDER_SUCCESS:
			// 
			// 
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_SALEORDER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_SALEORDER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_SALEORDER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: payload,
			};
		case UPDATE_SALEORDER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				errorPayload: payload,
			};
		case DELETE_SALEORDERHEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALEORDERHEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_SALEORDERHEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALEORDERHEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALEORDERHEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_SALEORDERHEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_SALE_ORDER_ISCLOSED_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_SALE_ORDER_ISCLOSED_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case UPDATE_SALE_ORDER_ISCLOSED_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}