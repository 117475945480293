import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { FilterOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { depositCols } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { getAllDepositHeaders } from "../../redux/actions/depositHeader.action";
import { useHistory } from "react-router-dom";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import { CSVLink } from "react-csv";
import { getRights } from "../../redux/actions/userRights.action";
export default function DepositList({ token }) {
	const [form] = Form.useForm();

	const [dataLen, setDataLen] = useState(0);
	const history = useHistory();
	const dispatch = useDispatch();
	const { depositHeaderList } = useSelector((state) => state);
	const [show, setShow] = useState(false);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const { Panel } = Collapse;
	const [userRights, setUserRights] = useState({})
	const dateFormat = "MM-DD-YYYY";
	const worker = {
		transactionNo: null,
		account: null,
		postingPeriod: null,
		subsidiary: null,
		vendor: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
	};

	useLayoutEffect(() => {
		dispatch(getRights(token, 1103)).then((res) => {
			console.log(res?.payload)
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => dateChecker(values.from_date, values.to_date, "depositList", token, values, dispatch))
	};
	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};
	function downloadExcelFileData(data) {
		if (data) {
		  return data.map((v) => {
			return {
			  "Deposit Header No": v.deposit_header_no,
			  "Account Name": v.account_name,
			  "Posting Period": v.period_name,
			  "Subsidiary": v.company_name,
			  "Deposit Date": moment(v.deposit_date).format("MM-DD-YYYY"),
			};
		  });
		}
	  }
	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Deposit List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/deposit/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={worker}
								form={form}
								onFinish={onFinishFilter}
								// onFinishFailed={onFinishFailed}
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="transactionNo" label="Transaction Number" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="subsidiary" label="Subsidiary" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="account" label="Account">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="vendor" label="Vendor">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>


{depositHeaderList?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {depositHeaderList?.payload?.data.length > 0 && (
                  <CSVLink
                    filename={"Deposit.csv"}
                    data={
                      downloadExcelFileData(depositHeaderList?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={depositCols}
                  source={depositHeaderList?.payload?.data}
                  load={depositHeaderList?.loading}
                  paginate
                />
              </div>
            </div>
          )}
				</div>
			</div>
		</div>

		
	);
}
