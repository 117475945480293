import {
    GET_VENDORS_REQUEST, GET_VENDORS_ERROR, GET_VENDORS_SUCCESS, ADD_VENDOR_REQUEST, ADD_VENDOR_ERROR, ADD_VENDOR_SUCCESS,
    UPDATE_VENDOR_REQUEST, UPDATE_VENDOR_ERROR, UPDATE_VENDOR_SUCCESS, DELETE_VENDOR_REQUEST, DELETE_VENDOR_ERROR,
    DELETE_VENDOR_SUCCESS, GET_ONE_VENDOR_REQUEST, GET_ONE_VENDOR_SUCCESS, GET_ONE_VENDOR_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
export const getVendors = (token,data) => (dispatch) => {
    console.log(data,"<------------------")
    dispatch({ type: GET_VENDORS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/scmVendor`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
			vendor_id: data?.vendorId === "" ? null : data?.vendorId,
			from_date: moment(data?.from_date).format("MM-DD-YYYY"),
			to_date: moment(data?.to_date).format("MM-DD-YYYY"),
			mobile_no: data?.mobileNo === "" ? null : data?.mobileNo,
			fax: data?.fax === "" ? null : data?.fax,
			VendorCode: data?.vendorCode === "" ? null : data?.vendorCode,
			vendorName: data?.vendorName === "" ? null : data?.vendorName,
			vendorCategory: data?.vendorCategory === "" ? null : data?.vendorCategory,
			webAddress: data?.webAddress === "" ? null : data?.webAddress,
			email: data?.email === "" ? null : data?.email,
			officePhoneNo: data?.officePhoneNo === "" ? null : data?.officePhoneNo,
		},
    })
        .then((response) => {

            return dispatch({
                type: GET_VENDORS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_VENDORS_ERROR,
                payload: error.response,
            });
        });
};
export const getOneVendor = (token, id) => (dispatch) => {
    dispatch({ type: GET_ONE_VENDOR_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/scmVendor/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({
                type: GET_ONE_VENDOR_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_VENDOR_ERROR,
                payload: error.response,
            });
        });
};

export const addVendor = (token, data, check) => (dispatch) => {

    dispatch({ type: ADD_VENDOR_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/scmVendor`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

        data: {
            company_id: data.company,
            is_active: check,
            vendor_name: data.vendorName,
            vendor_type: data.vendorType,
            vendor_category_id: data.vendorCat,
            email_address: data.email,
            // vendor_code: data.vendorCode,
            mobile: data.mobilePhone,
            office: data.officePhone,
            web_address: data.webaddress,
            balance: data.balance,
            fax: data.fax,
        },
    })
        .then((response) => {

            return dispatch({ type: ADD_VENDOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_VENDOR_ERROR,
                payload: error.response,
            });
        });
};

export const deleteVendor = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_VENDOR_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/scmVendor/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({
                type: DELETE_VENDOR_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_VENDOR_ERROR,
                payload: error.response,
            });
        });
};

export const updateVendor = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_VENDOR_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/scmVendor/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            company_id: data.company,
            is_active: true,
            vendor_name: data.vendorName,
            vendor_type: data.vendorType,
            vendor_category_id: 23,
            email_address: data.email,
            vendor_code: data.vendorCode,
            mobile: data.mobilePhone,
            office: data.officePhone,
            web_address: data.webaddress,
            balance: data.balance,
            fax: data.fax,
        },
    })
        .then((response) => {

            return dispatch({
                type: UPDATE_VENDOR_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_VENDOR_ERROR,
                payload: error.response,
            });
        });
};
