import {
  GET_PICK_TICKET_PRINT_REQUEST,
  GET_PICK_TICKET_PRINT_SUCCESS,
  GET_PICK_TICKET_PRINT_ERROR,

  GET_PICK_TICKET_COLUMN_REQUEST,
  GET_PICK_TICKET_COLUMN_SUCCESS,
  GET_PICK_TICKET_COLUMN_ERROR,

  GET_PICK_TICKET_COLUMN_VALUE_REQUEST,
  GET_PICK_TICKET_COLUMN_VALUE_SUCCESS,
  GET_PICK_TICKET_COLUMN_VALUE_ERROR,

  GET_PICK_TICKET_ITEM_REQUEST,
  GET_PICK_TICKET_ITEM_SUCCESS,
  GET_PICK_TICKET_ITEM_ERROR,

  GET_NEW_PT_REPORT_SUCCESS,
  GET_NEW_PT_REPORT_REQUEST,
  GET_NEW_PT_REPORT_ERROR

  // GET_SALES_REPORT_ORDER_ITEM_REQUEST,
  // GET_SALES_REPORT_ORDER_ITEM_SUCCESS,
  // GET_SALES_REPORT_ORDER_ITEM_ERROR,
  // GET_PICK_TICKET_PRINT_REQUEST,
  // GET_SALES_REPORT_ORDER_PRINT_SUCCESS,
  // GET_PICK_TICKET_PRINT_ERROR,

  // GET_SALES_REPORT_ORDER_COLUMNSVALUE_REQUEST,
  // GET_PICK_TICKET_COLUMN_VALUE_SUCCESS,
  // GET_PICK_TICKET_COLUMN_VALUE_ERROR,

  // GET_SALES_REPORT_ORDER_COLUMN_REQUEST,
  // GET_SALES_REPORT_ORDER_COLUMN_SUCCESS,
  // GET_SALES_REPORT_ORDER_COLUMN_ERROR,

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post getSalesReport
export const getPickTicketItem = (token, values) => (dispatch) => {
  dispatch({ type: GET_PICK_TICKET_ITEM_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getPickTicketItem`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {


      return dispatch({
        type: GET_PICK_TICKET_ITEM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICK_TICKET_ITEM_ERROR,
        payload: [],
      });
    });
};

export const getPickTicketPrint = (token, values) => (dispatch) => {
  dispatch({ type: GET_PICK_TICKET_PRINT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/GetPickTicketPrint`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {

      return dispatch({
        type: GET_PICK_TICKET_PRINT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICK_TICKET_PRINT_ERROR,
        payload: [],
      });
    });
};

export const getPickTicketColumnValue = (token, values) => (dispatch) => {
  dispatch({ type: GET_PICK_TICKET_COLUMN_VALUE_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getPickTicketColumnsValue`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_PICK_TICKET_COLUMN_VALUE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICK_TICKET_COLUMN_VALUE_ERROR,
        payload: [],
      });
    });
};


export const getPickTicketColumn = (token, values) => (dispatch) => {
  dispatch({ type: GET_PICK_TICKET_COLUMN_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getPickTicketColumns`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_PICK_TICKET_COLUMN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PICK_TICKET_COLUMN_ERROR,
        payload: [],
      });
    });
};


export const getNewPTReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_NEW_PT_REPORT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getNewPTReport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_NEW_PT_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_NEW_PT_REPORT_ERROR,
        payload: [],
      });
    });
};