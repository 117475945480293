import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { Input, Space, Tag } from "antd";
import moment from "moment";
import numeral from "numeral";
import { Link } from "react-router-dom";
const queryParams = new URLSearchParams(window.location.search);

const columns = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        // width: 50,
        render: (text, record, index) => {
            return index + 1;
        },
    },
    {
        title: 'Order #',
        dataIndex: 'order_header_no',
        key: 'order_header_id',
        render: (text, record) => (
            <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                target="_blank"
                to={"/sale-order?mode=edit&order_id=" + record.order_header_id + "/&customer_id/" + record.customer_id + "?edit=yes"}
            >
                {text}
            </Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_header_no - b.order_header_no;
        },

    },


    {
        title: "Order Date",
        render: (text, record) => {
            return <>{moment(record.order_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_date - b.order_date;
        },
    },

    {
        title: "PO #",
        dataIndex: "reference_no",
        key: "reference_no",
        // width: 150,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.reference_no - b.reference_no;
        },
    },

    {
        title: "Start Date",
        render: (text, record) => {
            return <>{moment(record.start_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.start_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.start_date - b.start_date;
        },
    },

    {
        title: "Cancel Date",
        render: (text, record) => {
            return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.cancel_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.cancel_date - b.cancel_date;
        },
    },

    {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        // width: 170,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.customer_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.customer_name - b.customer_name;
        },
    },
    {
        title: "Billing Addressee",
        dataIndex: "billing_addressee",
        key: "billing_addressee",
        // width: 170,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billing_addressee.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billing_addressee - b.billing_addressee;
        },
    },

    {
        title: "Payment Term",
        dataIndex: "payment_term",
        key: "payment_term",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.payment_term - b.payment_term;
        },
    },
    {
        title: 'Quantity',
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => {
            return <>{parseFloat(record.quantity).toFixed(0)}</>
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity - b.quantity;
        },
    },

    {
        title: "Net Amount",
        // dataIndex: "net",
        // key: "net",
        render: (text, record) => {
            return <>$ {numeral(record.net).format("0,0.00")}</>
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.net.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.net - b.net;
        },
    },

    {
        title: "CIT Status",
        dataIndex: "factor_decision",
        key: "factor_decision",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.factor_decision.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.factor_decision - b.factor_decision;
        },
    },

    {
        title: "Source",
        dataIndex: "source_type",
        key: "source_type",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.source_type.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.source_type - b.source_type;
        },
    },

    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.status.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.status - b.status;
        },
    },

    {
        title: "Loaction",
        dataIndex: "location_name",
        key: "location_name",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.location_name - b.location_name;
        },
    },
    {
        title: "Store",
        dataIndex: "store_id",
        key: "store_id",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.store_id - b.store_id;
        },
    },
    {
        title: "DC #",
        dataIndex: "dc_no",
        key: "dc_no",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.dc_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.dc_no - b.dc_no;
        },
    },
    {
        title: "Department code",
        dataIndex: "department_code",
        key: "department_code",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.department_code.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.department_code - b.department_code;
        },
    },
    {
        title: "Gender",
        dataIndex: "gender_name",
        key: "gender_name",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.gender_name - b.gender_name;
        },
    },

    {
        title: "Sale person Name",
        dataIndex: "salesperson_name",
        key: "salesperson_name",
        // width: 220,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.salesperson_name - b.salesperson_name;
        },
    },

    {
        title: "Region",
        dataIndex: "region_name",
        key: "region_name",
        // width: 220,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.region_name - b.region_name;
        },
    },

    {
        title: "State",
        dataIndex: "state_name",
        key: "state_name",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.state_name - b.state_name;
        },
    },
    {
        title: 'Resale Expiry',
        dataIndex: 'resaleexpiry',
        key: 'resaleexpiry',
        render: (text, record) => {
            return <>{text === "N/A" ? text : moment(record.resaleexpiry).format("MM-DD-YYYY")}</>
        }
    },

    {
        title: "Resale #",
        dataIndex: "resaleno",
        key: "resaleno",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.resaleno.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.resaleno - b.resaleno;
        },
    },
];

const columnsPending = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (text, record, index) => {
            return index + 1;
        },
    },
    {
        title: "Order #",
        dataIndex: "order_header_no",
        key: "order_header_no",
        // width: 100,
        render: (text, record) => (
            <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                target="_blank"
                to={"/sale-order?mode=edit&order_id=" + record.order_header_id + "/&customer_id/" + record.customer_id + "?edit=yes"}
            >
                {text}
            </Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_header_no - b.order_header_no;
        },
    },
    {
        title: "Order Date",
        render: (text, record) => {
            return <>{moment(record.order_date).format("MM-DD-YY")}</>
        },
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_date - b.order_date;
        },

    },
    {
        title: "PO #",
        dataIndex: "reference_no",
        key: "reference_no",
        // width: 100,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.reference_no - b.reference_no;
        },
    },
    {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        // width: 200,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.customer_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.customer_name - b.customer_name;
        },
    },
    {
        title: "Billing Addressee",
        dataIndex: "billing_addressee",
        key: "billing_addressee",
        // width: 200,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billing_addressee.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billing_addressee - b.billing_addressee;
        },
    },
    {
        title: "Start Date",
        // width: 100,
        render: (text, record) => {
            return <>{moment(record.start_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.start_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.start_date - b.start_date;
        },
    },
    {
        title: "Cancel Date",
        // width: 120,
        render: (text, record) => {
            return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.cancel_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.cancel_date - b.cancel_date;
        },
    },
    {
        title: 'Pending Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        // width: 120,
        render: (text, record) => {
            return <>{parseFloat(record.quantity).toFixed(0)}</>;
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity - b.quantity;
        },
    },
    {
        title: 'Commited',
        dataIndex: 'quantity_committed',
        key: 'quantity_committed',
        // width: 100,
        render: (text, record) => {
            return <>{parseFloat(record.quantity_committed).toFixed(0)}</>;
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity_committed.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity_committed - b.quantity_committed;
        },
    },
    {
        title: "Pending Amount",
        dataIndex: "net",
        key: "net",
        // width: 150,
        align: "right",
        render: (text, record) => {
            return <>$ {numeral(record.net).format('0,0.00')}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.net.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.net - b.net;
        },
    },

    {
        title: "Term",
        dataIndex: "payment_term",
        key: "payment_term",
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.payment_term - b.payment_term;
        },
    },

    {
        title: "CIT Status",
        dataIndex: "factor_decision",
        key: "factor_decision",
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.factor_decision.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.factor_decision - b.factor_decision;
        },
    },

    {
        title: "Order Status",
        dataIndex: "status",
        key: "status",
        // width: 140,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.status.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.status - b.status;
        },
    },

    {
        title: "Hold Status",
        dataIndex: "holdstatus",
        key: "holdstatus",
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.holdstatus.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.holdstatus - b.holdstatus;
        },
    },

    {
        title: "Region",
        dataIndex: "region_name",
        key: "region_name",
        // width: 160,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.region_name - b.region_name;
        },
    },

    {
        title: "Location",
        dataIndex: "location_name",
        key: "location_name",
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.location_name - b.location_name;
        },
    },

    {
        title: "Sale Person Name",
        dataIndex: "salesperson_name",
        key: "salesperson_name",
        // width: 150,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.salesperson_name - b.salesperson_name;
        },
    },

    {
        title: "Gender",
        dataIndex: "gender_name",
        key: "gender_name",
        // width: 100,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.gender_name - b.gender_name;
        },
    },

    {
        title: "Customer Type",
        dataIndex: "customer_type_name",
        key: "customer_type_name",
        // width: 150,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.customer_type_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.customer_type_name - b.customer_type_name;
        },
    },

    {
        title: "Billing State",
        dataIndex: "state_name",
        key: "state_name",
        // width: 150,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.state_name - b.state_name;
        },
    },

    {
        title: "Resale #",
        dataIndex: "resaleno",
        key: "resaleno",
        // width: 100,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.resaleno.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.resaleno - b.resaleno;
        },
    },
    {
        title: 'Resale Expiry',
        // width: 120,
        render: (text, record) => {
            return <>{moment(record.resale_expiry).format("MM-DD-YYYY")}</>;
        },
    },

    {
        title: "Store",
        dataIndex: "store_id",
        key: "store_id",
        // width: 80,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.store_id - b.store_id;
        },
    },

    {
        title: "Shipping Zip",
        dataIndex: "shipping_zip",
        key: "shipping_zip",
        // width: 120,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.shipping_zip.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.shipping_zip - b.shipping_zip;
        },
    },

    {
        title: "Shipper",
        dataIndex: "ship_via_description",
        key: "ship_via_description",
        // width: 180,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_via_description.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_via_description - b.ship_via_description;
        },
    },

    {
        title: "Bergan Billing",
        dataIndex: "bergan_billing_name",
        key: "bergan_billing_name",
        // width: 130,
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.bergan_billing_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.bergan_billing_name - b.bergan_billing_name;
        },
    },
];

const columnsPodSummary = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (text, record, index) => {
            return index + 1;
        },
    },
    {
        title: 'PT #',
        dataIndex: 'ship_header_no',
        key: 'ship_header_no',
        render: (text, record) => (
            <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                target="_blank"
                to={"/order-shipment?mode=edit&order_id=" + record.ship_header_id + "&customer_id/" + record.customer_id + "?edit=yes"}
            >
                {text}
            </Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_header_no - b.ship_header_no;
        },
    },
    {
        title: 'PT Date',
        render: (text, record) => {
            return <>{moment(record.ship_date).format("MM-DD-YYYY")}</>;
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_date - b.ship_date;
        },
        // width: 90
    },
    {
        title: 'Cancel Date',
        render: (text, record) => {
            return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.cancel_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.cancel_date - b.cancel_date;
        },
        // width: 90
    },
    {
        title: 'Customer',
        dataIndex: 'billingcustomer',
        key: 'billingcustomer',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billingcustomer.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billingcustomer - b.billingcustomer;
        },
        // width: 200
    },
    {
        title: 'Billing Addressee',
        dataIndex: 'billing_addressee',
        key: 'billing_addressee',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billing_addressee.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billing_addressee - b.billing_addressee;
        },
        // width: 200
    },
    {
        title: 'SO #',
        dataIndex: 'order_header_no',
        key: 'order_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_header_no - b.order_header_no;
        },
        // width: 80
    },
    {
        title: 'PO #',
        dataIndex: 'reference_no',
        key: 'reference_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.reference_no - b.reference_no;
        },
        // width: 120
    },
    {
        title: 'PT Qty',
        render: (text, record) => {
            return <>{parseFloat(record.quantity).toFixed(0)}</>;
        },
        // width: 90
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity - b.quantity;
        },
    },
    {
        title: 'Amount',
        render: (text, record) => {
            return <>$ {numeral(record.amount).format('0,0.00')}</>
        },
        // width: 100,
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.amount.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.amount - b.amount;
        },
    },
    {
        title: 'Term',
        dataIndex: 'payment_term',
        key: 'payment_term',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.payment_term - b.payment_term;
        },
        // width: 100
    },
    {
        title: 'Sale Person Name',
        dataIndex: 'salesperson_name',
        key: 'salesperson_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.salesperson_name - b.salesperson_name;
        },
        // width: 150
    },
    {
        title: 'Region',
        dataIndex: 'region_name',
        key: 'region_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.region_name - b.region_name;
        },
        // width: 150
    },
    {
        title: 'PT Status',
        dataIndex: 'pt_status_name',
        key: 'pt_status_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.pt_status_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.pt_status_name - b.pt_status_name;
        },
        // width: 80
    },
    {
        title: 'NS Status',
        dataIndex: 'ship_status_name',
        key: 'ship_status_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_status_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_status_name - b.ship_status_name;
        },
        // width: 50
    },
    {
        title: 'Location',
        dataIndex: 'location_name',
        key: 'location_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.location_name - b.location_name;
        },
        // width: 80
    },
];

const columnShippedSummary = [
    {
        title: 'Invoice #',
        dataIndex: 'invoice_header_no',
        key: 'invoice_header_no',
        render: (text, record) => (
            <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                target="_blank"
                to={"/invoice?mode=edit&order_header_id=" + record.order_header_id + "&invoice_header_id=" + record.invoice_header_id + "&customer_id=" + record.customer_id}
            >
                {text}
            </Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.invoice_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.invoice_header_no - b.invoice_header_no;
        },
    },
    {
        title: 'Invoice Date',
        // dataIndex: 'order_date',
        // key: 'order_date',
        render: (text, record) => {
            return <> {moment(record.invoice_date).format("MM-DD-YYYY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.invoice_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.invoice_date - b.invoice_date;
        },
    },
    {
        title: 'PT #',
        dataIndex: 'ship_header_no',
        key: 'ship_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_header_no - b.ship_header_no;
        },
    },
    {
        title: 'Order #',
        dataIndex: 'order_header_no',
        key: 'order_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_header_no - b.order_header_no;
        },
    },
    {
        title: 'PO #',
        dataIndex: 'reference_no',
        key: 'reference_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.reference_no - b.reference_no;
        },
    },
    {
        title: 'Customer',
        dataIndex: 'billingcustomer',
        key: 'billingcustomer',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billingcustomer.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billingcustomer - b.billingcustomer;
        },
    },
    {
        title: 'Payment Term',
        dataIndex: 'payment_term',
        key: 'payment_term',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.payment_term - b.payment_term;
        },
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, record) => {
            return <>{parseFloat(record.quantity).toFixed(0)}</>
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity - b.quantity;
        },
    },
    {
        title: 'Amount',
        dataIndex: 'net',
        key: 'net',
        render: (text, record) => {
            return <>{numeral(record.net).format('0,0.00')}</>
        },
        align: "right",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.net.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.net - b.net;
        },
    },
    {
        title: 'EDI #',
        dataIndex: 'edi',
        key: 'edi',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.edi.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.edi - b.edi;
        },
    },
    {
        title: 'Billing City',
        dataIndex: 'city_name',
        key: 'city_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.city_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.city_name - b.city_name;
        },
    },
    {
        title: 'Billing State',
        dataIndex: 'bill_state_name',
        key: 'bill_state_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.bill_state_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.bill_state_name - b.bill_state_name;
        },
    },
    {
        title: 'Shipping State',
        dataIndex: 'ship_state_name',
        key: 'ship_state_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_state_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_state_name - b.ship_state_name;
        },
    },
    {
        title: 'Customer Category',
        dataIndex: 'customer_category',
        key: 'customer_category',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.customer_category.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.customer_category - b.customer_category;
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.status.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.status - b.status;
        },
    },
    {
        title: 'Due Date',
        // dataIndex: 'order_date',
        // key: 'order_date',
        render: (text, record) => {
            return <> {moment(record.due_date).format("MM-DD-YYYY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.due_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.due_date - b.due_date;
        },
    },
    {
        title: 'Gender',
        dataIndex: 'gender_name',
        key: 'gender_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.gender_name - b.gender_name;
        },
    },
    {
        title: 'Region',
        dataIndex: 'region_name',
        key: 'region_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.region_name - b.region_name;
        },
    },
    {
        title: 'Salesperson',
        dataIndex: 'salesperson_name',
        key: 'salesperson_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.salesperson_name - b.salesperson_name;
        },
    },
    {
        title: 'Scf No',
        dataIndex: 'scf_no',
        key: 'scf_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.scf_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.scf_no - b.scf_no;
        },
    },
    {
        title: 'Store',
        dataIndex: 'store_id',
        key: 'store_id',
        // align: "right"
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.store_id - b.store_id;
        },
    },
    {
        title: 'Order Date',
        // dataIndex: 'order_date',
        // key: 'order_date',
        render: (text, record) => {
            return <> {moment(record.order_date).format("MM-DD-YYYY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.order_date - b.order_date;
        },
    },
    {
        title: 'Payment Class',
        dataIndex: 'factor_name',
        key: 'factor_name',
        // align: "right"
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.factor_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.factor_name - b.factor_name;
        },
    },
];

const columnsReturnSummary = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (text, record, index) => {
            return index + 1;
        },
    },
    {
        title: 'CN #',
        dataIndex: 'note_header_no',
        key: 'note_header_no',
        width: 150,
        render: (text, record) => (
            <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
                target="_blank"
                to={"/item-receipt-new?mode=edit&return_id=" + record.receipt_header_id}
            >
                {text}
            </Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.note_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.note_header_no - b.note_header_no;
        },
    },
    {
        title: 'CN Date',
        dataIndex: 'note_date',
        key: 'note_date',
        render: (text, record) => {
            return <>{moment(record.note_date).format("MM-DD-YY")}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.note_date.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.note_date - b.note_date;
        },
    },
    {
        title: 'RA #',
        dataIndex: 'return_header_no',
        key: 'return_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.return_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.return_header_no - b.return_header_no;
        },
    },
    {
        title: 'INV #',
        dataIndex: 'invoice_header_no',
        key: 'invoice_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.invoice_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.invoice_header_no - b.invoice_header_no;
        },
    },
    {
        title: 'PO #',
        dataIndex: 'reference_no',
        key: 'reference_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.reference_no - b.reference_no;
        },
    },
    {
        title: 'Customer',
        dataIndex: 'billingcustomer',
        key: 'billingcustomer',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.billingcustomer.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.billingcustomer - b.billingcustomer;
        },
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: "right",
        render: (text, record) => {
            return <>{parseFloat(record.quantity).toFixed(0)}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.quantity.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.quantity - b.quantity;
        },
    },
    {
        title: 'Amount',
        dataIndex: 'net',
        key: 'net',
        align: "right",
        render: (text) => {
            return <>$ {numeral(text).format('0,0.00')}</>
        },
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.net.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.net - b.net;
        },
    },
    {
        title: 'Payment Term',
        dataIndex: 'payment_term',
        key: 'payment_term',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.payment_term.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.payment_term - b.payment_term;
        },
    },
    {
        title: 'Region',
        dataIndex: 'region_name',
        key: 'region_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.region_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.region_name - b.region_name;
        },
    },
    {
        title: 'Gender',
        dataIndex: 'gender_name',
        key: 'gender_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.gender_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.gender_name - b.gender_name;
        },
    },
    {
        title: 'PT #',
        dataIndex: 'ship_header_no',
        key: 'ship_header_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ship_header_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ship_header_no - b.ship_header_no;
        },
    },
    {
        title: 'ASN #',
        dataIndex: 'asn_no',
        key: 'asn_no',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.asn_no.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.asn_no - b.asn_no;
        },
    },
    {
        title: 'RA Approval',
        dataIndex: 'ra_aproval',
        key: 'ra_aproval',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.ra_aproval.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.ra_aproval - b.ra_aproval;
        },
    },
    {
        title: 'Sale Person Name',
        dataIndex: 'salesperson_name',
        key: 'salesperson_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.salesperson_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.salesperson_name - b.salesperson_name;
        },
    },
    {
        title: 'Country',
        dataIndex: 'country_name',
        key: 'country_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.country_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.country_name - b.country_name;
        },
    },
    {
        title: 'City',
        dataIndex: 'city_name',
        key: 'city_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.city_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.city_name - b.city_name;
        },
    },
    {
        title: 'Loaction',
        dataIndex: 'location_name',
        key: 'location_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.location_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.location_name - b.location_name;
        },
    },
    {
        title: 'State',
        dataIndex: 'state_name',
        key: 'state_name',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.state_name.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.state_name - b.state_name;
        },
    },
    {
        title: 'Address',
        dataIndex: 'full_address',
        key: 'full_address',
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        autoFocus
                        allowClear
                        className='pop-search-gen pop-170'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.full_address.toString().toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => {
            return a.full_address - b.full_address;
        },
    },
];

export { columns, columnsPending, columnsPodSummary, columnShippedSummary, columnsReturnSummary };