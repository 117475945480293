import {
    GET_SHIPPED_SUMMARY_REQUEST,
    GET_SHIPPED_SUMMARY_SUCCESS,
    GET_SHIPPED_SUMMARY_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  import moment from "moment";

  export const getShippedSummary = (token, values) => (dispatch) => {
    dispatch({ type: GET_SHIPPED_SUMMARY_REQUEST });

    const newData = {
            customer_cat_id: values.cutomer_category === "All" || values.cutomer_category === undefined ? null : values.cutomer_category,
            date_from: moment(values.from_date).format("MM-DD-YYYY"),
            date_to: moment(values.to_date).format("MM-DD-YYYY"),
            order_header_no: values.order_no === undefined || values.order_no === "" ? null : values.order_no,
            reference_no: values.po_no === undefined || values.po_no === "" ?  null : values.po_no,
            customer: values.customer === undefined || values.customer === "" ? null : values.customer,
            billcustomer: values.billing_addressee === undefined || values.billing_addressee === "" ? null : values.billing_addressee,
            sp_id: values.sale_person === "All" || values.sale_person === undefined ? null : values.sale_person,
            region_id: values.region === "All" || values.region === undefined ? null : values.region,
            gender_id: values.gender === "All" || values.gender === undefined ? null : values.gender,
            payment_term_id: values.terms === "All" || values.terms === undefined ? null : values.terms,
            store_id: values.store === undefined || values.store === "" ? null : values.store,
            company_id: values.subsidary === "All" || values.subsidary === undefined ? null : values.subsidary,
            source_type: values.source === undefined || values.source === "" ? null : values.source,
            sku: values.sku === undefined || values.sku === "" ? null : values.sku,
            status: values.status === undefined || values.status === "" ? null : values.status,
            invoice_header_no: values.invoice_no === undefined || values.invoice_no === "" ? null : values.invoice_no,
            ship_header_no: values.ship_no === undefined || values.ship_no === "" ? null : values.ship_no,
            location_id: values.location === undefined || values.location === "All" ? null : values.location,
            factor_id: (values.paymentClass === undefined || values.paymentClass === "All") ? null : values.paymentClass,
            customer_id : values.customerNew === undefined ? null : values.customerNew,
            memo : values.memo === undefined ? null : values.memo,
            style_id: values.style === undefined || values.style === "All" ? null : values.style,
    };
    
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/omsShipped`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_SHIPPED_SUMMARY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SHIPPED_SUMMARY_ERROR,
          payload: [],
        });
        //   if (error.response.data.data === "No Record Found !") {
        //     return dispatch({
        //       type: GET_BUSSINESS_DASHBOARD_ERROR,
        //       payload: [],
        //     });
        //   }
        //   return dispatch({
        //     type: GET_REGION_ERROR,
        //     payload: error.response,
        //   });
      });
  };
  