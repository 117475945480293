import React, { useEffect, useState, useRef } from "react";
import { Spin, Checkbox, Form, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { ReadCookie } from "../../utils/readCookie";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo-black.svg";
import "../Distributor/Distributor.css";
import { useHistory } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getMissingUpc } from "../../redux/actions/missingUpc.action";

const MissingUpc = ({ token }) => {
  TabTitle("Missing UPC")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    productStatus,
    missingUpc
  } = useSelector((state) => state);
  const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [isChecked2, setIsChecked2] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const tableRef = useRef(null);
  const [excelData, setExcelData] = useState([])




  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      console.log(values, 'values-----');
      dispatch(
        getMissingUpc(token, isChecked2)).then((res => {
          setLoading(false)
          setData(res?.payload?.data)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.sku,
          "Item Code": v.item_code,
          "Location name": v.location_name,
          "Product Status": v.product_status_name,
          "Qty": v.on_hand_quantity,
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getProductStatus(token, menuId));
  }, [dispatch, menuId, token]);


  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])


  const handlePrint = () => {
    window.print();
  };

  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      // width: 70,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      key: "item_code",
      // width: 70,
    },
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
      // width: 70,
    },
    {
      title: "Product Status",
      dataIndex: "product_status_name",
      key: "product_status_name",
      // width: 70,
    },
    {
      title: "Qty",
      dataIndex: "on_hand_quantity",
      key: "on_hand_quantity",
      // width: 70,
      align: 'right',
    },
  ];

  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Missing-UPC.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Missing UPC
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
              <hr className="my-1" />
            <div className="col-12 " style={{display: "flex", alignItems: "center"}}>
              <div className=" col-6">
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
                <div className=" col-6" style={{justifyContent: "flex-end"}}>
                  <div className=" d-flex flex-row align-items-baseline">
                    <div style={{ marginTop: "4px", display: "flex" }} >
                      <Button type="primary" htmlType="submit" className='submit-btn-btm m-0'
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
            </div>
              <hr className="my-1" />
          </div>
        </Form>
      </div>
      <div
        className="table_content international_order"
        style={{ maxWidth: '1200px' }}
      >
        <div>
          {
            console.log(missingUpc?.payload?.data, 'oms')
          }
          {missingUpc?.payload?.data !== undefined ?
            (
              missingUpc?.payload?.data.length > 0 ? (
                <Table

                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit omsStockSummaryTableTransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={data}
                  columns={column}
                  loading={missingUpc.loading}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 100,
                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                  }}
                  // scroll={{ x: 'calc(500px + 90%)', y: 450 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    let Quantity = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.on_hand_quantity
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Quantity.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(intransit?.payload?.data.length, 'intransit?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default MissingUpc;
