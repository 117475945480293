import {
    GET_ORDER_STATUS_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    GET_CUSTOMER_TYPE_REQUEST,
    GET_CUSTOMER_TYPE_SUCCESS,
    GET_CUSTOMER_TYPE_ERROR,

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"

// ////GET CUSTOMER BY ID
// export const customersById = (customer_id) => {
//   alert(customer_id)
//   return function (dispatch) {
//     axios
//       .get(
//         `${process.env.REACT_APP_API}/getOne/customer/${customer_id}`,
//         { user_id: 1, session_id: 1 },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: "Bearer token",
//           },
//         }
//       )
//       .then((response) => {
//         
//         dispatch(customerById(response));
//       })
//       .catch((error) => 
//   };
// };



export const loadOrderStatus = (token) => dispatch => {
    dispatch({ type: GET_ORDER_STATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/orderStatus`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_ORDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ORDER_ERROR,
                payload: error.response,
            });
        })
}



///DELETE CUSTOMER
// export const deleteCustomer = (customer_id) => {
//   return function (dispatch) {
//     axios
//       .delete(
//         `${process.env.REACT_APP_API}/delete/customerShip/${customer_id}`,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: "Bearer token",
//           },
//           data: {
//             user_id: "4",
//             session_id: "1",
//           },
//         }
//       )
//       .then((response) => {
//
//         dispatch(customerDeleted(response));
//         dispatch(loadCustomers());
//       })
//       .catch((error) =>
//   };
// };
