import {
    GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_REQUEST,
    GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_SUCCESS,
    GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getOmsPendingReportByCustomer = (token, data) => (dispatch) => {

    dispatch({ type: GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getSalePendingOrderOmsByCustomerReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            // from_date: moment(data.from_date).format("YYYY-MM-DD"),
            // to_date: moment(data.to_date).format("YYYY-MM-DD"),
            // gender_id:data.gender_id === "All" ? null : Number(data.gender_id),
            // term_id: data.term_id === "All" ? null :Number( data.term_id),
            // region_id:Number(data.region_id=== "All" ? null : data.region_id),
            // company_id: Number(data.company_id),
            // customer_id:Number(data.customer_id),
            // state_id: Number(data.state_id),
            from_date: data.from_date,
            to_date: data.to_date,
            gender_id:null,
            customer_type_id: data.customer_type_id === "All" ? null : data.customer_type_id,
            region_id:data.region_id=== "All" ? null : data.region_id,
            company_id: data.company_id,
            customer_id:data.customer_id,
            state_id: data.state_id,
          

        }
    })
        .then((response) => {
            return dispatch({ type: GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_ERROR,
                payload: error.response,
            });
        });
};
