import React from 'react'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Doughnut, Bar, Line, Chart } from "react-chartjs-2";
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);



export default function MultiTypeChart() {
    return (
        <div>
            <Chart
                type="bar"
                data={{
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    // datasets: [
                    //     {
                    //         id: 1,
                    //         label: 'Jan',
                    //         data: [12, 6, 7],
                    //         backgroundColor: 'rgba(255, 99, 132, 0.5)',

                    //     },
                    //     {
                    //         id: 2,
                    //         label: 'Feb',
                    //         data: [4, 5, 71],
                    //         backgroundColor: 'rgba(215, 49, 132, 0.5)',

                    //     },
                    //     {
                    //         id: 3,
                    //         label: 'Mar',
                    //         data: [4, 8, 1],
                    //         backgroundColor: 'rgba(215, 69, 112, 0.5)',

                    //     },
                    //     {
                    //         id: 4,
                    //         label: 'Apr',
                    //         data: [3, 12, 9],
                    //         backgroundColor: 'rgba(115, 78, 21, 0.5)',

                    //     },
                    //     {
                    //         id: 5,
                    //         label: 'May',
                    //         data: [5, 8, 1],
                    //         backgroundColor: 'rgba(123, 12, 76, 0.5)',

                    //     },
                    //     {
                    //         id: 6,
                    //         label: 'Jun',
                    //         data: [3, 0, 9],
                    //         backgroundColor: 'rgba(75, 231, 34, 0.5)',

                    //     },
                    //     {
                    //         id: 7,
                    //         label: 'Jul',
                    //         data: [2, 8, 11],
                    //         backgroundColor: 'rgba(123, 256, 23, 0.5)',

                    //     },
                    //     {
                    //         id: 8,
                    //         label: 'Aug',
                    //         data: [3, 2, 1],
                    //         backgroundColor: 'rgba(123, 34, 35, 0.5)',

                    //     },
                    //     {
                    //         id: 9,
                    //         label: 'Sep',
                    //         data: [3, 2, 1],
                    //         backgroundColor: 'rgba(122, 65, 76, 0.5)',

                    //     },
                    //     {
                    //         id: 10,
                    //         label: 'Oct',
                    //         data: [3, 2, 1],
                    //         backgroundColor: 'rgba(54, 69, 23, 0.5)',

                    //     },
                    //     {
                    //         id: 11,
                    //         label: 'Nov',
                    //         data: [13, 5, 7],
                    //         backgroundColor: 'rgba(215, 34, 23, 0.5)',

                    //     },
                    //     {
                    //         id: 12,
                    //         label: 'Dec',
                    //         data: [3, 2, 1],
                    //         backgroundColor: 'rgba(24, 69, 12, 0.5)',

                    //     },
                    // ],
                    datasets: [
                        {
                            id: 1,
                            type: 'line',
                            label: '2020',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                            fill: false,
                            data: [10, 6, 2, 7, 6, 3, 9, 4, 6, 1, 8, 3],
                            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                        },
                        {
                            id: 2,
                            type: 'bar',
                            label: '2021',
                            backgroundColor: 'rgb(75, 192, 192)',
                            data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],

                            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                            borderColor: 'white',
                            borderWidth: 2,
                        },
                        {
                            id: 3,
                            type: 'bar',
                            label: '2022',
                            backgroundColor: 'rgb(53, 162, 235)',
                            data: [2, 1, 9, 4, 8, 1, 5, 4, 3, 0, 9, 10],
                            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                        },
                    ],
                }} />
        </div>
    )
}
