import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Form, Input, Tabs, Divider, Select, Checkbox, DatePicker, Table } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import CrudButtons from "./CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import "../Style/style.css";
import "../User/user.css";
import notification from "../../utils/notification";
import { getAdminCurrency } from "../../redux/actions/adminCurrency.action";
import { getAccountType } from "../../redux/actions/accounttype.action";
// import { getLog } from '../../redux/actions/log.action';
import { TabTitle } from "../../utils/GeneralFunction";
import { TransationLockLog, addTransationLock, getAllTransationLock, updateTransationLock } from "../../redux/actions/transactionLock.action";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import moment from "moment";
import Swal from "sweetalert2";
const { TabPane } = Tabs;
const { Option } = Select;


const TransactionLock = ({ token }) => {
    TabTitle("Transaction Lock");
    const { currency, log, accounttype, transactionLock } = useSelector((state) => state);
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState("view");
    const [list, setList] = useState([]);
    const [form] = Form.useForm();
    const [disable, setDisable] = useState(false);
    const [transactionObj, setTransactionObj] = useState(null);
    const [userRights, setUserRights] = useState({})
    const dispatch = useDispatch();
    const id = ReadCookie("menuId");



    // ******** FETCHED USER RIGHTS *******
    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                setUserRights(res?.payload)
            }
        });
    }, [token])



    useEffect(() => {
        dispatch(getAllTransationLock(token)).then((res) => {
            if (res?.type === "GET_ALL_TRANSACTION_LOCK_SUCCESS") {
                setList(res?.payload?.data);
            }
        })
    }, [token]);

    // useEffect(() => {
    //     if (transactionObj) {
    //         setFormState("view");
    //         changingState("view");
    //     }
    // }, [transactionObj]);


    useEffect(() => {
        dispatch(getAdminCurrency(token));
    }, [token]);


    const changingState = (state) => {
        // if (state === "view") {
        //     form.setFieldsValue({
        //         month: moment(transactionObj?.month),
        //         year: moment(transactionObj?.year),
        //         lock: transactionObj?.lock ? "True" : "False",
        //     });

        // }
        if (state === "delete") {
            form.setFieldsValue({
                month: null,
                year: null,
                lock: null,
            });
        }
    };

    const formSave = async () => {
        await form
            .validateFields()
            .then((values) => {
                if (formState === "add") {
                    let copyArr = [...list];
                    let matched_with_month_year = copyArr.find((v) => {
                        if (v.month === moment(values.month).format("MM") && v.year === moment(values.year).format("YYYY")) {
                            return v;
                        }
                    })


                    if (matched_with_month_year) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "You Have Already created with This Year and Month!",
                        });
                    } else {
                        dispatch(addTransationLock(token, values)).then((res) => {
                            if (res?.type === "ADD_TRANSACTION_LOCK_SUCCESS") {
                                dispatch(TransationLockLog(token, {
                                    lock: values?.lock,
                                    transaction_id: res?.payload?.transaction_id
                                }));
                                setTransactionObj({ transaction_id: res?.payload?.transaction_id })
                                dispatch(getAllTransationLock(token)).then((result) => {
                                    setList(result?.payload?.data);
                                })
                                setFormState("view");
                                notification("success", "Transaction Lock", "Record Inserted!");
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Some Thing Went Wrong!",
                                });
                            }
                        })

                    }

                }
                if (formState === "edit") {
                    const data = {
                        lock: values.lock === "True" || values.lock === true ? true : false,
                        // year: values.year,
                        // month: values.month,
                    }

                    dispatch(updateTransationLock(token, data, transactionObj?.transaction_id)).then((res) => {
                        if (res?.type === "UPDATE_TRANSACTION_LOCK_SUCCESS") {
                            dispatch(TransationLockLog(token, {
                                lock: values.lock === "True" || values.lock === true ? true : false,
                                transaction_id: transactionObj?.transaction_id || null
                            }));
                            dispatch(getAllTransationLock(token)).then((result) => {
                                setList(result?.payload?.data);
                            })
                            notification("success", "Transaction Lock Updated", "Record Successfully Updated!");
                            setFormState("view");
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Some Thing Went Wrong!",
                            });
                        }
                    })

                }
            })
            .catch((err) => {

            });
    };

    useEffect(() => {
        if (formState === "add") {
            form.setFieldsValue({
                month: null,
                year: null,
                lock: null,
            });
        }
    }, [formState]);



    const filterReset = () => {
        setFormState("view");
        changingState("delete");
    };


    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "transaction_id",
            key: "transaction_id",
            width: 120,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.transaction_id !== null) {
                    return record.transaction_id
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if (a.transaction_id !== null && b.transaction_id !== null) {
                    // Convert transaction_id to numbers before comparison
                    const transactionIdA = parseFloat(a.transaction_id);
                    const transactionIdB = parseFloat(b.transaction_id);
                    return transactionIdA - transactionIdB;
                }
            },
        },
        {
            title: "Year",
            dataIndex: "year",
            key: "year",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.year !== null) {
                    return record.year
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.year !== null) & (b.year !== null)) {
                    return a.year.localeCompare(b.year);
                }
            },
        },
        {
            title: "Month",
            dataIndex: "month",
            key: "month",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.month !== null) {
                    return record.month
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if ((a.month !== null) & (b.month !== null)) {
                    return a.month.localeCompare(b.month);
                }
            },
        },
        {
            title: "lock",
            dataIndex: "lock",
            key: "lock",
            render: (text) => {
                return text ? "True" : "False";
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className="pop-search-gen pop-220"
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
            onFilter: (value, record) => {
                if (record.lock !== null) {
                    return record.lock
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
            },
            sorter: (a, b) => {
                if (a.lock !== null && b.lock !== null) {
                    // Directly compare boolean values
                    return a.lock - b.lock;
                    // or return a.lock ? 1 : -1; // Depending on your desired sorting order
                }
            },
        },
    ];


    const handleRowClick = (record, rowIndex) => {
        // Your logic when a row is clicked
        setTransactionObj(record);
        setFormState("formView");
        form.setFieldsValue({
            month: moment(record?.month),
            year: moment(record?.year),
            lock: record?.lock ? "True" : "False",
        });
    };

    const getRowProps = (record) => {
        return {
            onClick: () => handleRowClick(record)
        };
    };

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader title={"Transaction Lock"} minimize={setMinimize} check={minimize} />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="crudDiv">
                            <CrudButtons
                                formType={formState}
                                userRights={userRights}
                                formState={setFormState}
                                disable={disable}

                                save={formSave}
                                reset={filterReset}
                                transactionObj={transactionObj}
                            />
                        </div>
                        <div className="styleContent">
                            <Row className="tables-main-row-new">
                                <Col xs={24} sm={24} md={10} lg={8} xl={7} xxl={6}>
                                    <div className="styleTable">
                                        {/* <CSVLink
                                            filename={"chart-of-account.csv"}
                                            data={list.length > 0 ? list : []}
                                        >
                                            Download Excel
                                        </CSVLink> */}

                                        {/* <Tables
                                            type="transaction"
                                            formState={formState}
                                            source={list}
                                            col={columns}
                                            onChange={() => console.log("HELLO")}
                                            paginate
                                            scrollX={"28rem"}
                                            scroll={602}
                                            rowSelection={{
                                                onSelect: handleRowSelect
                                            }}
                                            // paginate
                                            perPage={1000}
                                            load={transactionLock.loading}
                                            transactionObj={setTransactionObj}
                                            rowKey="transaction_id"
                                        /> */}
                                        <Table
                                            //   disabled={!(formState === "edit" || formState === "add")}
                                            type={"transaction"}
                                            scrollX={"28rem"}
                                            scroll={602}
                                            size="small"
                                            dataSource={list}
                                            columns={columns}
                                            rowSelection={false}
                                            onRow={getRowProps}
                                            loading={transactionLock.loading}
                                            // pagination={true}
                                            pagination={{ pageSize: 500 }}
                                            rowClassName="editable-row"
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={17} xxl={18}>
                                    <div className="styleForm w-100">
                                        <div className="testStyle">
                                            <Form form={form} name="basic" labelWrap labelAlign="left">
                                                <div className="customRow styleFormUpper "></div>
                                                <div className="container-fluid label-mob-space">
                                                    <Brow>
                                                        <Bcol lg={6} xxl={4}>
                                                            <Form.Item
                                                                label="Month"
                                                                name="month"
                                                                // initialValue={form.getFieldValue().month}
                                                                labelCol={{ xs: 11, xxl: 10 }}
                                                                wrapperCol={{ span: 14 }}
                                                                rules={formState === "add" ? [{ required: true, message: "Month is required" }] : null}
                                                            >

                                                                <DatePicker
                                                                    size="small"
                                                                    className="filter-fields full_date"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                    // value={moment(monthId)}
                                                                    // disabled={dateTypeId === "ytd"}
                                                                    // onChange={(e) =>
                                                                    //     setMonthId(moment(e).format("MM"))
                                                                    // }
                                                                    picker="month"
                                                                    format={"MM"}
                                                                    mode="month"
                                                                />
                                                            </Form.Item>
                                                        </Bcol>
                                                        <Bcol lg={6} xxl={4}>
                                                            <Form.Item
                                                                label="Year"
                                                                name="year"
                                                                // initialValue={form.getFieldValue().year}
                                                                labelCol={{ xs: 11, xxl: 10 }}
                                                                wrapperCol={{ span: 14 }}
                                                                rules={formState === "add" ? [{ required: true, message: "Year is required" }] : null}
                                                            >

                                                                <DatePicker
                                                                    size="small"
                                                                    className="filter-fields full_date"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                    // value={moment(monthId)}
                                                                    // disabled={dateTypeId === "ytd"}
                                                                    // onChange={(e) =>
                                                                    //     setMonthId(moment(e).format("MM"))
                                                                    // }
                                                                    picker="year"
                                                                    format={"YYYY"}
                                                                    mode="year"
                                                                />
                                                            </Form.Item>
                                                        </Bcol>
                                                        <Bcol lg={6} xxl={4}>
                                                            <Form.Item
                                                                label="Transaction Lock"
                                                                name="lock"
                                                                // initialValue={form.getFieldValue().lock}
                                                                labelCol={{ xs: 11, xxl: 10 }}
                                                                wrapperCol={{ span: 14 }}
                                                                rules={formState === "add" ? [{ required: true, message: "Transaction Lock is required" }] : null}
                                                            >
                                                                <Select
                                                                    size="small"
                                                                    className="styleInput"
                                                                    disabled={!(formState === "add" || formState === "edit")}
                                                                    // onChange={(e) => setGeneralRate(e)}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                    }
                                                                >
                                                                    <Option value={true}>True</Option>
                                                                    <Option value={false}>False</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Bcol>
                                                    </Brow>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default TransactionLock;
