import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getTableSchema } from "../../redux/actions/tableSchema.action";
import { getColumnSchema } from "../../redux/actions/columnSchema.action";
import { updateTable } from "../../redux/actions/updateTable.action";
import { useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "./UpdateTool.css";
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { Tag } from 'antd';

const UpdateTool = () => {
  // const { tableschema } = useSelector((state) => state);
  const [tableName, setTableName] = useState();
  const [selectTable, setSelectTable] = useState("");
  const [saveColumn, setSaveColumn] = useState([]);
  const [selectCol, setSelectCol] = useState([]);
  const [result, setResult] = useState([]);
  const [excel, setExcel] = useState([]);
  const [keys, setKeys] = useState([]);
  // const [matching, setMatching] = useState([]);
  const [initialkey, setinitialkey] = useState();
  // const [data, setData] = useState();
  const [loading, setloading] = useState(false);
  const [excelHeader, setExcelHeader] = useState([]);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  let array = [];

  const handleOnChange = (e) => {
    if (excel === "") {
      Swal.fire({
        title: "Please Insert Excel File First!",
        icon: "warning",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1500,
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    }
    setSelectTable(e.target.value);
  };

  const handleOnSubmit = (e, data, m) => {
    array = [...result];
    let arrayIndex;
    const found = array.find((element, index) => {
      arrayIndex = index;
      return element.column_name === data;
    });
    if (found) {
      array[arrayIndex] = {
        column_name: data,
        action: e.target.value,
        matching_column: initialkey,
        values: [],
      };
    } else {
      array.push({
        column_name: data,
        action: e.target.value,
        matching_column: initialkey,
        values: [],
      });
    }
    //
    setResult(array);
    //
  };

  useEffect(() => {
    dispatch(getTableSchema(token))
      .then((res) => {
        let sortedDates = res?.payload?.data.sort((p1, p2) =>
          p1.tablename > p2.tablename ? 1 : p1.tablename < p2.tablename ? -1 : 0
        );
        //
        setTableName(sortedDates);
        // setTableName(res?.payload?.data);
      })
      .catch((error) => {});
  }, [dispatch, token]);

  let body = {
    column: selectTable,
  };
  useEffect(() => {
    dispatch(getColumnSchema(token, body))
      .then((res) => {
        setSelectCol(res?.payload?.data);
      })
      .catch((error) => {});
  }, [selectTable]);

  let arr = [];
  let json;
  let jsonArray = [];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        //
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        //
        json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });

        // console.log(json);
        jsonArray = json;
        excel.push(jsonArray);
        setExcel(jsonArray);
        //
        excelHeader.push(Object.keys(jsonArray[0]));
        console.log(excelHeader);

        //
        keys.push(Object.keys(excel[0][0]));
        //
        setinitialkey(keys[0][0]);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleOnUpdate = async () => {
    setloading(true);

    let body = {
      data: [
        { tableName: selectTable },
        { matching: result },
        { excel: excel },
      ],
    };

    dispatch(updateTable(token, body)).then((res) => {
      setloading(false);

      Swal.fire({
        title: "Good Job!",
        text: "Your Query is submitted!",
        icon: "success",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 5000);
    });
  };

  return (
    <div className="styleContainer">
      <PageHeader title={"Update Tool"} />
      {loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : null}
      {
        <div>
          <Collapse
            style={{ width: "99%", margin: "10px" }}
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <div className="">
              <div className="steps">
                <label className="upload_label">
                  Step 1 - Upload Excel File
                </label>
                <input
                  className="upload_input"
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
              </div>
              {excelHeader.length > 0 ? <>
                  <div style={{marginTop: "10px"}}>
                    {excelHeader[0].map((element, index) => {
                        return(
                          <Tag id={index} key={index} style={{marginBottom: "10px"}}>{element}</Tag>
                        )
                    })}
                  </div>
              </> : <></>}
              <br />
              <div className="steps" style={{ width: "300px" }}>
                <label className="upload_label">Step 2 - Select a Table</label>

                <select className="choose_table" onChange={handleOnChange}>
                  <option>-Please Choose a Table-</option>
                  {tableName &&
                    tableName.map((val, index) => {
                      // {setCheck(val.tableName)}
                      return <option key={index}>{val.tablename}</option>;
                    })}
                </select>
              </div>
              <br />
              <div className="steps">
                {selectCol.length > 0 && <label>Step 3 - Select Columns</label>}
                <div className="column_div">
                  {selectCol.length > 0 &&
                    selectCol.map((val, i) => {
                      return (
                        <div key={i} className="choose_columns">
                          <input
                            className="upload_checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                arr = [...saveColumn];
                                arr.push(val.column_name);
                                setSaveColumn(arr);
                              } else {
                                arr = [...saveColumn];
                                const index = arr.indexOf(val.column_name);
                                if (index > -1) {
                                  arr.splice(index, 1);
                                }

                                setSaveColumn(arr);
                              }
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].column_name === saveColumn[i]) {
                                  result[i] = result.pop();
                                } else {
                                  alert("done");
                                }
                              }
                            }}
                          />
                          <p>{val.column_name}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="steps">
                {selectTable && (
                  <label>Step 4 - First Select Radio Buttons</label>
                )}
                <br />
                <div>
                  {selectTable ? (
                    <>
                      <table id="tables">
                        <thead>
                          <tr>
                            <th>Database Column Name</th>
                            <th>Where</th>
                            <th>Update</th>
                            <th>Excel Column Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saveColumn.length > 0 ? (
                            <>
                              {saveColumn.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{data}</td>
                                    <td>
                                      <input
                                        // className="update_radio"
                                        type="radio"
                                        value={"where"}
                                        name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {console.log(result)}
                                      <input
                                        type="radio"
                                        value={"update"}
                                        name={index}
                                        // name="column"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                   { 
                                   result.length === saveColumn.length ?
                                   <td>
                                    {console.log(result[0], 'res')}
                                      <select
                                        className="match_columns"
                                        defaultValue={Object.keys(keys)[1]}
                                        name="matchColumn"
                                        id="matchColumn"
                                        onChange={(m) =>
                                          (result[index].matching_column =
                                            m.target.value)
                                        }
                                      >
                                        {keys[0].map((val, i) => {
                                          return <option key={i}>{val}</option>;
                                        })}
                                      </select>
                                    </td>
                                    : 
                                    <></>}
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  <div className=" submit_div">
                    {console.log(result)}
                    {result[0] == undefined ? (
                      <></>
                    ) : (
                      <button className="submit_btn" onClick={handleOnUpdate}>
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      }
    </div>
  );
};

export default UpdateTool;
