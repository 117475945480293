import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getAdminJob, addAdminJob, deleteAdminJob, updateAdminJob } from "../../redux/actions/adminJob.action";
import {  getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs,  Divider } from 'antd';
import { logColumns, adminJobColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;

export default function AdminJob({ token }) {
    TabTitle("Admin Job")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const adminJobNameInput = useRef(null);
    const [form] = Form.useForm();
    const [adminJobObj, setadminJobObj] = useState(null);
    const dispatch = useDispatch();
    const { adminJob,  log, userRights } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [adminJobName, setadminJobName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');

 


    useEffect(() => {
        dispatch(getAdminJob(token, menuId));
        dispatch(getLog(token, { admin_job: "admin_job"}))
      }, [token]);

    useEffect(() => {
        dispatch(getAdminJob(token, menuId))
    }, [token])

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (adminJobObj) {
            setFormState('view')
            form.setFieldsValue({
                id: adminJobObj.job_id,
                adminJobType: adminJobObj.job_title,
                active: adminJobObj.is_active
            });
            setadminJobName(adminJobObj.job_title)
            setCheck(adminJobObj.is_active)
            dispatch(getCompanyAccess(token, adminJobObj.job_id, menuId))
        }
    }, [adminJobObj]);

    useEffect(() => {
        if (adminJobName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [adminJobName])

    const handleTab = (key) => {
        if (key === "logs") {
            dispatch(getLog(token, { admin_job: "admin_job"}))
        }
    }


    const formSave = () => {
        if (formState === "add") {
            dispatch(addAdminJob(token, { adminJobName, check }, menuId)).then((res) => {

                if (res.type === "ADD_ADMIN_JOB_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_ADMIN_JOB_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    notification("success", "Admin Job Created", "Admin Job created successfully!");
                    form.setFieldsValue({
                        adminJobType: ""
                    })
                    setFormState("")
                    setadminJobName("")
                    setadminJobObj(null)
                }
                dispatch(getAdminJob(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updateAdminJob(token, { adminJobName, check }, adminJobObj.job_id)).then((res) => {
                if (res.type === "UPDATE_ADMIN_JOB_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_ADMIN_JOB_SUCCESS") {
                    notification("success", "Admin Job Updated", "Record updated successfully")
                }
                dispatch(getAdminJob(token, menuId))
            })


        }


    }

    const handleDelete = () => {
        if (adminJobObj) {
            dispatch(deleteAdminJob(token, adminJobObj.job_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_JOB_SUCCESS") {
                    notification("success", "Admin Job Deleted", "Admin Job deleted successfully!");
                }
                if (res.type === "DELETE_ADMIN_JOB_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    adminJobType: ""
                })
                setadminJobName("")
                setadminJobObj(null)
                setFormState('')
                dispatch(getAdminJob(token, menuId))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            adminJobNameInput.current.focus();
            form.setFieldsValue({
                adminJobType: ""
            })
            setCheck(true)
            setadminJobName("")
            setadminJobObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Admin Job"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="adminJob" adminJobObj={setadminJobObj} scrollX={"20rem"} scroll={602} perPage={25} source={adminJob?.payload?.data} col={adminJobColumns} paginate load={adminJob.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 12 }}
                                        >
                                             <div className='customRow styleFormUpper ' >
                                            {adminJobObj !== null && !(formState === "add") &&
                                            <div className='customCols slc'>
                                                <Form.Item
                                                    label="ID"
                                                    name="id"
                                                    labelCol={{ span: 10 }}
                                                    wrapperCol={{ span: 14 }}
                                                    initialValue={form.getFieldValue().id}
                                                >
                                                    <Input  size='small' className='styleInput id-field-style' readOnly />

                                                </Form.Item>
                                                </div>
                                            }
                                            <div className='customCols slc'>
                                            <Form.Item
                                                label="Type"
                                                name="adminJobType"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                                initialValue={form.getFieldValue().adminJobType}
                                            >

                                                <Input  size='small'   disabled={!(formState === "add" || formState === "edit")} ref={adminJobNameInput} onChange={(e) => setadminJobName(e.target.value)} className='styleInput' />
                                            </Form.Item>
                                            </div>
                                            <div className='customCols slc'>
                                            <Form.Item
                                                label="Active"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                                name="active"
                                            >
                                                <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                            </Form.Item>
                                            </div>
                                            </div>
                                        </Form>
                                
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                         
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                   
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}