import React from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { useHistory, Link } from "react-router-dom";

import "../../../components/CrudButtons/crud.css";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, rights, edit, formData, saleReturnId, orderHeaderId, customerCode, orderID }) {
  const history = useHistory();
  const confirmDelete = () => {
    onDelete();
  };
  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} key={1}>
                {formType.toUpperCase()}
              </Tag>
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}
            </div>
          )}
        </div>
        <div>
          {formData?.is_returned ? (
            <Button disabled type="primary" className="modalButton">
              Returned
            </Button>
          ) : (
            edit && (
              <Tooltip placement="bottom" title="Receipt" color="#fbfbfb">
                <Button
                  type="primary"
                  onClick={() => history.push(`/item-receipt/edit/${saleReturnId}`)}
                  className="modalButton"
                >
                  Receipt
                </Button>
              </Tooltip>
            )
          )}
          {(formType === "formView" || formType === "view") && (
            <Link
              style={{ paddingLeft: "10px", textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
              to={{
                pathname: `/sales-return-report`,
                search: `?order_header_id=${orderID}`,
              }}
            >
              View Report
            </Link>
          )}
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              onClick={() => {
                history.push("/sale-return/list");
                //  window.history.go(0);
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {/*Add Button*/}
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              // disabled={true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/sale-return/add?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
          {/*Edit Button*/}
          <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
            <Button
              // disabled={
              //   disable ||
              //   formType === "view" ||
              //   formType === "add" ||
              //   (rights?.can_edit === false ? true : false)
              // }
              onClick={() => formState("edit")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
          {/*Delete Button*/}
          <Tooltip placement="bottom" title="Delete" color="#fbfbfb">
            {rights?.can_delete === false ? (
              <Button
                disabled={edit ? false : true}
                className="iconBtn crudBTN"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            ) : (
              <Popconfirm
                placement="topLeft"
                title={`Are you sure to delete RA# ${formData?.invoice_header_no}?`}
                onConfirm={confirmDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={edit ? false : true}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          </Tooltip>
          {/*Save Button*/}
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </div>
      </div>
      <Divider />
    </>
  );
}