import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from "antd";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useSelector, useDispatch } from "react-redux";
import Tables from "../../components/Table/Tables";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  getState,
  addState,
  deleteState,
  updateState,
} from "../../redux/actions/state.action";
import { loadCountry } from "../../redux/actions/adminCountry";
import { logColumns, stateColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";
// import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess } from "../../redux/actions/companyAccess";
import "../Style/style.css";
import notification from "../../utils/notification";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
const { Option } = Select;
const State = ({ token }) => {
  TabTitle("State")
  const dispatch = useDispatch();

  const {
    adminstate,
    userRights,
    log,
    getDemoDefaultCompany,
    companyAccess,
    companyAll,
    country,
  } = useSelector((state) => state);
  //  const [allState,setAllState] = useState([])
  //  
  const [minimize, setMinimize] = useState(false);
  const [stateName, setStateName] = useState("");
  const [formState, setFormState] = useState("view");
  const [form] = Form.useForm();
  const stateNameInput = useRef(null);
  const countryNameInput = useRef(null);
  const [disable, setDisable] = useState(false);
  const [countryid, setCountryId] = useState(null);
  const [stateObj, setStateObj] = useState(null);
  const [check, setCheck] = useState(true);
  const id = ReadCookie("menuId");

  useEffect(() => {
    dispatch(loadCountry(token));
  }, []);

  const handleDelete = () => {
    if (stateObj) {

      dispatch(deleteState(token, stateObj.state_id)).then((res) => {
        if (res.type === "DELETE_STATE_SUCCESS") {
          notification(
            "success",
            "State Deleted",
            "State deleted successfully!"
          );
        }
        if (res.type === "DELETE_STATE_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        form.setFieldsValue({
          state: "",
          cid: null,
        });
        // setCheck(false)
        setStateName("");
        setStateObj(null);
        setCountryId(null);
        setFormState("view");
        dispatch(getState(token, id));
      });
    }
    setFormState("view");
  };

  /// CRUD HANDLERS END
  useEffect(() => {
    // const data = dispatch(getState(token, id)).then((res)=>setAllState(res.payload.data))
    dispatch(getState(token, id));
  }, [token, id]);

  ///CRUD BUTTON
  useLayoutEffect(() => {
    dispatch(getRights(token, id)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);
  //// CRUD BUTTON
  useEffect(() => {
    if (stateObj) {
      setFormState("view");
      form.setFieldsValue({
        id: stateObj.state_id,
        state: stateObj.state_name,
        active: stateObj.is_active,
        cid: stateObj.country_id,
      });
      setCountryId(stateObj.cid);
      setStateName(stateObj.state_name);
      setCheck(stateObj.is_active);

      dispatch(getCompanyAccess(token, stateObj.state_id, id));
    }
  }, [stateObj]);

  //// CRUD BUTTON

  useEffect(() => {
    if (stateName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [stateName]);

  useEffect(() => {
    dispatch(getLog(token, { admin_state: "admin_state" }));
  }, [token]);

  const formSave = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (formState === "add") {
          dispatch(
            addState(
              token,
              {
                state_name: stateName.toUpperCase(),
                is_active: check,
                country_id: countryid,
              },
              id
            )
          ).then((res) => {
            if (res.type === "ADD_STATE_ERROR") {
              notification("error", "Not Created", res?.payload?.data?.data);
            }
            if (res.type === "ADD_STATE_SUCCESS") {
              notification(
                "success",
                "State Created",
                "State created successfully!"
              );
              form.setFieldsValue({
                style: "",
              });
              setFormState("view");
              // setCheck(false)
              setStateName("");
              setStateObj(null);
            }
            dispatch(getState(token, id));
          });
        }
        if (formState === "edit") {
          dispatch(
            updateState(
              token,
              {
                state_name: stateName.toUpperCase(),
                is_active: check,
                country_id: stateObj.country_id,
              },
              stateObj.state_id
            )
          ).then((res) => {

            if (res.type === "UPDATE_STATE_ERROR") {
              notification("error", "Update", "Something went wrong");
            }
            if (res.type === "UPDATE_STATE_SUCCESS") {
              notification(
                "success",
                "State Updated",
                "Record updated successfully"
              );
            }
            dispatch(getState(token, id));
          });

          // setFormState("view")
        }
      })
      .catch((err) => {

      });
  };
  useEffect(() => {
    if (formState === "add") {
      countryNameInput.current.focus();
      // stateNameInput.current.focus();
      form.setFieldsValue({
        cid: null,
        state: null,
      });
      setCheck(true);
      setCountryId(null);

      setStateName("");
      setStateObj(null);
    }
    // else if(formState=== "view"){
    //     form.setFieldsValue({
    //         cid:null,
    //         state: null,

    //     })
    //     setCheck(true)
    //     setCountryId(null)

    //     setStateName("")
    //     setStateObj(null)
    // }
  }, [formState]);
  const filterReset = () => {
    setFormState("view");
    setStateObj(null);
    setCheck(true);
    form.setFieldsValue({
      cid: null,
      state: null,
    });
    setStateName(null);
  };
  const selectHandler = (e) => {
    if (formState === "edit") {
      stateObj.country_id = e;
    } else {
      setCountryId(e);
    }
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"State"} minimize={setMinimize} check={minimize} />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                formType={formState}
                rights={userRights?.payload}
                reset={filterReset}
                disable={disable}
                save={formSave}
                onDelete={handleDelete}
                formState={setFormState}
                disabled={disable}
              />
            </div>
            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="state"
                      formState={formState}
                      scrollX={"20rem"}
                      stateObj={setStateObj}
                      source={adminstate?.payload?.data}
                      col={stateColumns}
                      paginate
                      scroll={500}
                      perPage={25}
                      load={adminstate.loading}
                      rowKey="state_id"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <div className="testStyle">
                      <Form
                        form={form}
                        name="basic"
                      // labelCol={{ span: 8 }}
                      >
                        <div className="customRow styleFormUpper ">
                          {stateObj !== null && !(formState === "add") && (
                            <div className="customCols slc">
                              <Form.Item
                                label="ID"
                                name="id"
                                initialValue={form.getFieldValue().id}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                              >
                                <Input
                                  size="small"
                                  className="styleInput id-field-style"
                                  readOnly
                                />
                              </Form.Item>
                            </div>
                          )}
                          <div className="customCols slc">
                            {/* <div className="country-spaceremove-mob relative "> */}
                            <Form.Item
                              name="cid"
                              label="Country "
                              // labelAlign="left"
                              // className='width400'
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Country id is required",
                                },
                              ]}
                            >
                              <Select
                                size="small"
                                ref={countryNameInput}
                                showSearch
                                loading={country.loading}
                                disabled={
                                  !(
                                    formState === "add" ||
                                    formState === "edit"
                                  )
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                                optionFilterProp="children"
                                //    defaultValue={
                                //      formState === "edit" || formState==="add"
                                //        ? form.getFieldValue().cid
                                //        : null
                                //    }
                                className="w-100"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(e) => selectHandler(e)}
                                allowClear
                              >
                                {country?.payload?.data.map((country) => {
                                  return (
                                    <Option value={country.country_id}>
                                      {country.country_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            {/* </div> */}
                          </div>
                          <div className="customCols slc">
                            <Form.Item
                              // className='width400'
                              label="State"
                              name="state"
                              initialValue={form.getFieldValue().state}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input
                                size="small"
                                disabled={
                                  !(formState === "add" || formState === "edit")
                                }
                                ref={stateNameInput}
                                onChange={(e) => setStateName(e.target.value)}
                                className="styleInput"
                              />
                            </Form.Item>
                          </div>
                          <div className="customCols slc">
                            <Form.Item
                              label="Active"
                              name="active"
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Checkbox
                                disabled={
                                  !(formState === "add" || formState === "edit")
                                }
                                checked={check}
                                onChange={(e) => setCheck(e.target.checked)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <div className="modalStyle"></div>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered /**onChange={(key) => handleTab(key)} */
                      >
                        ///**/
                        {/* <TabPane tab="Admin_state" key="admin_state">
                                            <div className='tabs'>
                                                <Tables type="state" />

                                            </div>


                                        </TabPane> */}
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={800}
                              perPage={10}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                    {/* <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}
                                            >
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default State;
