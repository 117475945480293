import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBillPayment } from "../../redux/actions/billPayments.action";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { BillPaymentColumns } from "../../utils/cols";
import { useParams } from "react-router-dom";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { dateChecker } from "../../components/CheckDate/checkDate";

export default function BillPaymentList({ token }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { billpayment } = useSelector((state) => {
    return state;
  });

  const { RangePicker } = DatePicker;
  const { search } = useParams();
  const dateFormat = "MM-DD-YYYY";
  const { Panel } = Collapse;
  const [show, setShow] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const worker = {
    referenceNo: null,
    account: null,
    postingPeriod: null,
    payee: null,
    subsidiary: null,
    apAccount: null,
    from_amount: null,
    to_amount: null,
    cheaque_no: null,
    from_date: moment().startOf("year"),
    to_date: moment().endOf("year"),
    due_date: moment().endOf("year"),
  };

  const onFinishFilter = async (e) => {
    await form
      .validateFields()
      .then((values) =>
        dateChecker(
          values.from_date,
          values.to_date,
          "billPaymentList",
          token,
          values,
          dispatch
        )
      );
  };
  const handleFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setShow(true);
  };

  // const checkFrom = () => {

  // }
  function downloadExcelFileData(data) {
    if (data) {
      return data.map((v) => {
        return {
          "Payment Header No": v.payment_header_no,
          "Payee": v.vendor_name,
          "Account Name": v.account_name,
          "AP Account": v.ap_account_name,
          "Posting Period": v.period_name,
          "Payment Amount": v.payment_amount,
          "Subsidiary": v.company_name,
          "Payment Header Date": moment(v.payment_date).format("MM-DD-YYYY"),
        };
      });
    }
  }
  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"Bill Payment List"} />
        <div className="bodyStyle">
          <Collapse
            style={{ width: "99%", margin: "10px" }}
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header="Filters"
              key="1"
              className="site-collapse-custom-panel"
              extra={
                <Button
                  //   disabled={true}
                  onClick={() => {
                    history.push("/bill-payments/add?mode=add");
                  }}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<PlusOutlined />}
                />
              }
            >
              <Form
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                form={form}
                initialValues={worker}
                onFinish={onFinishFilter}
                autoComplete="off"
              >
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="referenceNo"
                      label="Refference Number"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Must Be a Number.",
                        },
                      ]}
                    >
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="From Date" name="from_date">
                      <DatePicker
                        size="small"
                        id="fromDate"
                        format={dateFormat}
                        className="dateInput customInputs"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="To Date" name="to_date">
                      <DatePicker
                        size="small"
                        id="toDate"
                        format={dateFormat}
                        className="dateInput ustomInputs"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item name="account" label="Account">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Ap-Account" name="apAccount">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name="postingPeriod" label="Posting Period">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={8}>
                    <Form.Item name="payee" label="Payee">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name="subsidiary" label="Subsidiary">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="from_amount"
                      label="From Amount"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Must Be a Number.",
                        },
                      ]}
                    >
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="to_amount"
                      label="To Amount"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Must Be a Number.",
                        },
                      ]}
                    >
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="cheaque_no"
                      label="Check No"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Must Be a Number.",
                        },
                      ]}
                    >
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Due Date" name="due_date">
                      <DatePicker
                        size="small"
                        id="due_date"
                        format={dateFormat}
                        className="dateInput ustomInputs"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                </div>
              </Form>
            </Panel>
          </Collapse>
          {billpayment?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {billpayment?.payload?.data?.length > 0 && (
                  <CSVLink
                    filename={"BillPayment.csv"}
                    data={
                      downloadExcelFileData(billpayment?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={BillPaymentColumns}
                  source={billpayment?.payload?.data}
                  load={billpayment?.loading}
                  paginate
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
