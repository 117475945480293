import React, { useEffect, useState, useRef } from "react";
import "./Distributor.css";
import logo from "../../assets/images/logo-black.svg";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStyleWiseStockReport, getWise_Stock_Report } from "../../redux/actions/wiseStockReport.action";
import { getProductType } from "../../redux/actions/productType.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Select, DatePicker, Checkbox, Form, Input } from "antd";
import { getStyle } from "../../redux/actions/style";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getNewSeason } from "../../redux/actions/newSeason.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { useDownloadExcel } from "react-export-table-to-excel";
import numeral, { Numeral } from "numeral";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import Loader from "react-spinners/BarLoader";
import { TabTitle } from "../../utils/GeneralFunction";

const dateFormatList = ["MM-DD-YYYY"];

const StockStyleWise = ({ token }) => {
  TabTitle("Stock Style Wise US")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    season,
    getWiseStockReport,
    productType,
    gender,
    productStatus,
    adminLocation,
    getNewSeasonReducer,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [BGradechecked, setBGradeChecked] = useState(false);
  const [pendingDate, setpendingDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isChecked, setIsChecked] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [steps, setSteps] = useState([]);
   // const stepsArr = [1, 17, 18, 25, 2, 29, 20, 21, 22, 28, 33];
   const stepsArr = [4, 20, 99, 18, 3, 25, 29, 27, 100, 32];
  const [pendingChecked, setPendingChecked] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [isChecked2, setIsChecked2] = useState([]);
  const [loadding, setLoadding] = useState(false);
  const [StockwiseReportData, setStockWiseReport] = useState([]);
  const initialDate = moment('2026-12-31')

  const tableRef = useRef(null);
  const buttonRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Stock-Style-Wise",
    sheet: "Stock-Style-Wise",
  });

  const customSortOrder = [
    "BERGEN WS",
    "NORD D2C",
    "L&T D2C",
    // "BERGEN ECOM",
    "In Transit",
    "PK Warehouse W+W",
    "Production",
    "International",
    "BERGEN Canada",
    "Direct Shipment",
    // "DL1961 Showroom",
    "B-Grade",
    // "ADI Logistics",
    // "RILEY LIFE" 
  ];

  adminLocation?.payload?.data?.sort((a, b) => {
    const indexA = customSortOrder.indexOf(a.location_name);
    const indexB = customSortOrder.indexOf(b.location_name);
    return indexA - indexB;
  })

  useEffect(() => {
    if (adminLocation?.payload?.data?.length > 0) {
      setSteps(
        adminLocation?.payload?.data
          .filter((val) => stepsArr.includes(val.location_id))
          .map((it) => ({ ...it, selected: true }))
      );
    }
  }, [adminLocation]);

  // const arr = stepsArr.map((val) => {
  //   return (
  //     val
  //   )
  // });
  const stepschecked =
    steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);
  //
  const getStepData =
    adminLocation &&
    adminLocation?.payload?.data.filter((val) =>
      stepsArr.includes(val.location_id)
    );
  // var arr1 = [1,2,3,4],
  //     arr2 = [2,4],
  //     res = arr1.filter(item => !arr2.includes(item));
  //
  const [stepsChecked, setStepChecked] = useState([]);

  let result =
    StockwiseReportData &&
    StockwiseReportData.reduce(function (r, a) {
      r[a.item_code + a.inseam + a.description] =
        r[a.item_code + a.inseam + a.description] || [];
      r[a.item_code + a.inseam + a.description].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const { Option } = Select;

  const onChangeDate = (date, dateString) => {
    setpendingDate(dateString);
  };

  const onChangeCheckBox = (e) => {
    setBGradeChecked(e.target.checked);
  };

  const handlePendingOrder = (e) => {
    setPendingChecked(e.target.checked);
  };

  const sesionSelectAll = (e) => {
    setAllSesion(e.target.checked);
  };

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };
  console.log(allStatus, 'is1111');

  const isCheckboxChecked = (id, value) => {
    const filterCheckBox =
      getNewSeasonReducer &&
      getNewSeasonReducer?.payload?.data.find((val) => val.season_id === id);
    const checkId = isChecked.includes(id);
    if (!checkId) {
      setIsChecked((current) => [...current, filterCheckBox.season_id]);
    }

    if (!value) {
      const removeUnCheck = isChecked.filter(
        (item) => item !== filterCheckBox.season_id
      );
      setIsChecked(removeUnCheck);
      //
    }
    //
    // if(isChecked)

    setAllSesion(false);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus &&
      productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [
        ...current,
        filterCheckBox.product_status_id,
      ]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      //  **** get Last Index of the current Year ****
      // values.pendingDate = pendingDate;
      values.BGradeStock = BGradechecked;
      values.stepsChecked = stepsChecked;
      values.isChecked = isChecked;
      values.isChecked2 = isChecked2;
      // if (allSesion) {
      //   values.allSesion = allSesion;
      // };

      if (allStatus) {
        values.allStatus = allStatus;
      }

      if (pendingChecked) {
        stepschecked.push(-1);
      }
      // get the current style Id
      const styleId =
        style &&
        style?.payload?.data.find((val) => val.style_name === values.style);
      // get the product Status Id
      const productStatusId =
        productStatus &&
        productStatus?.payload?.data.find(
          (val) => val.product_status_name === values.product_Status
        );

      console.log("productStatusId", productStatusId);
      //
      const distributorParam = {
        // item_id: values.sku,
        location_id: stepschecked.length === 0 ? null : stepschecked.toString(),
        season_id: values.season_id === "All" ? null : values.season_id,
        sku: values.sku ? values.sku : null,
        gender_id: values.gender === "All" ? null : values.gender,
        gender_category_id: null,
        style: styleId ? styleId.style_id : null, //null
        product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
        pending_date: moment(values.pending_date).format("YYYY-MM-DD"),
      };
      setLoading(true);
      dispatch(getStyleWiseStockReport(token, distributorParam)).then((res) => {
        setLoading(false);
        setStockWiseReport(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  const downLoadExcel = (values) => {
    setLoadding(true)
    values.BGradeStock = BGradechecked;
    values.stepsChecked = stepsChecked;
    values.isChecked = isChecked;
    values.isChecked2 = isChecked2;
    if (allStatus) {
      values.allStatus = allStatus;
    }
    if (pendingChecked) {
      stepschecked.push(-1);
    }
    const styleId =
      style &&
      style?.payload?.data.find((val) => val.style_name === values.style);
    // get the product Status Id
    const productStatusId =
      productStatus &&
      productStatus?.payload?.data.find(
        (val) => val.product_status_name === values.product_Status
      );

    // console.log("productStatusId", productStatusId);
    //
    const distributorParam = {
      // item_id: values.sku,
      location_id: stepschecked.length === 0 ? null : stepschecked.toString(),
      season_id: values.season_id === "All" ? null : values.season_id,
      sku: values.sku ? values.sku : null,
      gender_category_id: values.type === "All" ? null : values.type,
      style: styleId ? styleId.style_id : null, //null
      product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
      pending_date: moment(values.pending_date).format("YYYY-MM-DD"),
    };
    dispatch(getWise_Stock_Report(token, distributorParam)).then((res) => {
      // setExcelData(res?.payload?.data);
      downloadExcelFileData(res?.payload?.data);
      if (buttonRef.current) {
        return buttonRef.current.link.click();
      }
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.item_code,
          "Style": v.style_name,
          "Wash": v.description,
          "Inseam": v.inseam,
          "Bulk Order": null,
          "Location": v.location_name,
          "Ex-Factory Date": null,
          "Bergen In Dc Date": null,
          " 23 ": v.qty_1,
          " 24 ": v.qty_2,
          " 25 ": v.qty_3,
          " 26 ": v.qty_4,
          " 27 ": v.qty_5,
          " 28 ": v.qty_6,
          " 29 ": v.qty_7,
          " 30 ": v.qty_8,
          " 31 ": v.qty_9,
          " 32 ": v.qty_10,
          " 33 ": v.qty_11,
          " 34 ": v.qty_12,
          " 35 ": v.qty_13,
          " 36 ": v.qty_14,
          " 37 ": v.qty_15,
          " 38 ": v.qty_16,
          "Quantity": v.sum_quantity,
        };
      })
    );
    setLoadding(false)

  }

  let sumQuantity;

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getNewSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion]);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  const handlePrint = () => {
    window.print();
  };


  // useEffect(() => {
  //   console.log('is123');
  //   setIsChecked2([1])
  // }, [])
  // console.log(isChecked2, 'is');
  // useEffect(() => {
  //   if (buttonRef.current) {
  //     console.log("buttonRef", buttonRef.current);
  //     return buttonRef.current.click();
  //   }
  // }, []);

  return (
    <div className="distributor header_class stock_style_wise">
      <div className="row justify-content-between distributr">
        {/**** / Logo *****/}
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div
                className="print_icon printing_class"
                name="ImgExcel"
                alt="Export to excel"
                id="ImgExcel"
                title="Export to excel"
                src={xlsx}
              >
                {/* <i className="fa-solid fa-file-excel"></i> */}

                {wiseStockReport ? (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    onClick={onDownload}
                    style={{
                      borderWidth: "0px",
                      height: "14px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                )}
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Stock Style Wise US
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
              {/* type="image"
              name="ImgExcel"
              alt="Export to excel"
              id="ImgExcel"
              title="Export to excel"
              src={xlsx}
              style={{ borderWidth: "0px", height: "20px" }}
            />  */}
            </span>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="business_class_oms distributr">
        {/* Table Selects */}
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className=" col-12 ">
              <hr className="mt-0 mb-2" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        checked={allStatus}
                        onChange={statusSelectAll}

                      >
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {/* <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        // checked={allStatus} 
                        checked={
                          allStatus
                            ? false
                            : isChecked2.includes(5)
                        }
                        onChange={(e) =>
                          isCheckboxChecked2(
                            5,
                            e.target.checked
                          )
                        }>
                        Promo
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        // checked={allStatus} 
                        checked={
                          allStatus
                            ? false
                            : isChecked2.includes(4)
                        }
                        onChange={(e) =>
                          isCheckboxChecked2(
                            4,
                            e.target.checked
                          )
                        }>
                        Int.Customer
                      </Checkbox>
                    </Form.Item>
                  </div> */}
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        // checked={allStatus} 
                        checked={
                          allStatus
                            ? false
                            : isChecked2.includes(2)
                        }
                        onChange={(e) =>
                          isCheckboxChecked2(
                            2,
                            e.target.checked
                          )
                        }>
                        Discontinued
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        // checked={allStatus} 
                        checked={
                          allStatus
                            ? false
                            : isChecked2.includes(1)
                        }
                        onChange={(e) =>
                          isCheckboxChecked2(
                            1,
                            e.target.checked
                          )
                        }>
                        Off Price
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox
                        checked={
                          allStatus
                            ? false
                            : isChecked2.includes(3)
                        }
                        onChange={(e) =>
                          isCheckboxChecked2(
                            3,
                            e.target.checked
                          )
                        }>
                        Active Line
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {/* {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={
                                allStatus
                                  ? false
                                  : isChecked2.includes(it.product_status_id)
                              }
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })} */}
                </div>
              </div>
              {/* <div>
                <label>Season</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Checkbox
                      size="large"
                      checked={allSesion}
                      onChange={sesionSelectAll}
                    >
                      All
                    </Checkbox>
                  </div>
                  {getNewSeasonReducer &&
                    getNewSeasonReducer?.payload?.data.map((session, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Checkbox
                            size="large"
                            checked={allSesion ? false : isChecked.includes(session.season_id)}
                            // checked={checked}
                            // checked={isChecked}
                            onChange={(e) =>
                              isCheckboxChecked(
                                session.season_id,
                                e.target.checked
                              )
                            }
                          // onChange={(e) =>
                          //   sesionCheckBoxHandler
                          //   // setCheckedId({
                          //   //   ...checkedId,
                          //   //   checked: e.target.checked,
                          //   //   checkItemId: session.season_id,
                          //   // })
                          // }
                          >
                            {session.season_name}
                          </Checkbox>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div>
                <label>Steps</label>
                <div className="checkboxes">
                  {getStepData &&
                    getStepData.map((step, i) => {
                      return (
                        <div className="checkbox_content">
                          <Checkbox
                            checked={steps[i]?.selected}
                            value={12}
                            onChange={(e) => {
                              setSteps(
                                steps.map((it) => {
                                  if (it.location_id == step.location_id) {
                                    return {
                                      ...it,
                                      selected: e.target.checked,
                                    };
                                  } else {
                                    return it;
                                  }
                                })
                              );
                            }}
                          >
                            {step.location_name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  <Checkbox
                    checked={pendingChecked}
                    onChange={handlePendingOrder}
                  >
                    Pending Order
                  </Checkbox>
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className="w-100">
                      <Form.Item name="season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select Product Status"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.season_id}>
                                  {status.season_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/* Second */}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44">Gender</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select a Type"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                </div>
                {/* Third */}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44">Style</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          // placeholder="Select a person"
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_name}>
                                  {style.style_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/* Fourth */}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44">Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" className="sku_input">
                        <Input size="small" className={"select_Inputs mb-0"} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Pending till</label>
                    <div className="w-100">
                      <Form.Item
                        name="pending_date"
                        className="full_date "
                        initialValue={initialDate}
                      >
                        <DatePicker
                          format={dateFormatList}
                          size="small"
                          defaultValue={moment().format("MM-DD-YYYY")}
                        // placeholder="From Date"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    {/* <label 

                      className=" no_visibility"
                      style={{ marginBottom: "3px" }}
                    >
                      none
                    </label> */}
                    <div
                      style={{
                        marginTop: "4px",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {!loading ?
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="submit-btn-btm m-0"
                        >
                          Generate
                        </Button> : <Spin size="medium" />}
                      <div style={{ display: "flex" }}>
                        <label className="no_visibility">none</label>
                        <div
                          style={{
                            display: "none",
                          }}
                        >
                          <CSVLink
                            className="submit-btn-btm m-0"
                            ref={buttonRef}
                            filename={"Stock-Style-Wise.csv"}
                            data={excelData}
                          >
                            Download Excel
                          </CSVLink>
                        </div>
                        {!loadding ?
                          <Button
                            type="primary"
                            className="submit-btn-btm m-0"
                            onClick={() => downLoadExcel(form.getFieldValue())}
                          >
                            Generate Excel
                          </Button> : <Spin size="medium" />}
                        {/* {wiseStockReport && wiseStockReport.length > 0 ? (
                          <CSVLink
                            className="submit-btn-btm m-0 "
                            style={{ width: "fit-content", color: "white", background: "rgb(60, 75, 88)", padding: "0 15px" }}
                            filename={"Stock-Style-Wise.csv"}
                            data={excelData}
                            onClick={() => {
                            }}
                          >
                            Download Excel
                          </CSVLink>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        {/* <div
          style={{
            display: "none",
          }}
        >
          <CSVLink
            className="submit-btn-btm m-0"
            ref={buttonRef}
            filename={"Stock-Style-Wise.csv"}
            data={excelData}
          >
            Download Excel
          </CSVLink>
        </div>
        <Button
          type="primary"
          className="submit-btn-btm m-0"
          onClick={() => downLoadExcel(form.getFieldValue())}
        >
          {!loadding ? "Generate Excel" : <Loader className="spinner" />}
        </Button> */}
        {console.log(excelData, 'excelData')}
        {/* {excelData?.length > 0 ? (
          <div className="loader" style={{ marginTop: "20px" }}>
            <Spin size="medium" />
          </div>
        ) : null} */}
      </div>
      {/* Table  Data */}

      <div className="business_class_oms " ref={tableRef}>
        {wiseStockReport ? (
          wiseStockReport.length > 0 ? (
            wiseStockReport.map((data, index) => {
              const sizes = data;
              //

              let qty1 = null;
              let qty2 = null;
              let qty3 = null;
              let qty4 = null;
              let qty5 = null;
              let qty6 = null;
              let qty7 = null;
              let qty8 = null;
              let qty9 = null;
              let qty10 = null;
              let qty11 = null;
              let qty12 = null;
              let qty13 = null;
              let qty14 = null;
              let qty15 = null;
              let qty16 = null;

              for (let i = 0; i < data.length; i++) {
                qty1 += parseInt(data[i].qty_1);
                qty2 += parseInt(data[i].qty_2);
                qty3 += parseInt(data[i].qty_3);
                qty4 += parseInt(data[i].qty_4);
                qty5 += parseInt(data[i].qty_5);
                qty6 += parseInt(data[i].qty_6);
                qty7 += parseInt(data[i].qty_7);
                qty8 += parseInt(data[i].qty_8);
                qty9 += parseInt(data[i].qty_9);
                qty10 += parseInt(data[i].qty_10);
                qty11 += parseInt(data[i].qty_11);
                qty12 += parseInt(data[i].qty_12);
                qty13 += parseInt(data[i].qty_13);
                qty14 += parseInt(data[i].qty_14);
                qty15 += parseInt(data[i].qty_15);
                qty16 += parseInt(data[i].qty_16);
              }

              let sumQ = 0;

              for (let i = 0; i < data.length; i++) {
                sumQ += parseInt(data[i].sum_quantity);
              }

              return (
                <div key={index}>
                  <div className="table_data-Heading distributr">
                    {data[0].item_code} - {data[0].description}
                  </div>
                  <table className="table_distributor-data distributr">
                    <tbody>
                      <tr className=" font_weight">
                        <td className="font-weig">#</td>
                        <td
                          style={{ width: "60px", fontWeight: "800" }}
                          className="font-weig"
                        >
                          SKU
                        </td>
                        <td className="font-weig">Inseam</td>
                        <td className="font-weig">Style</td>
                        <td className="font-weig">Wash</td>
                        <td className="font-weig">Bulk Order</td>
                        <td className="font-weig">Location</td>
                        <td className="font-weig">Ex-Factory Date</td>
                        <td className="font-weig">Bergen In Dc Date</td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_1}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_2}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_3}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_4}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_5}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_6}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_7}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_8}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_9}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_10}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_11}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_12}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_13}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_14}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_15}
                        </td>
                        <td style={{ width: "30px", textAlign: "right" }} className="font-weig">
                          {sizes[0].size_16}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right" }} >Quantity</td>
                      </tr>
                      {data.map((values, index) => {
                        const filterQuantity = data.filter(
                          (val) => val.location_id === values.location_id && val.seq === values.seq
                        );

                        const totalQuantity = filterQuantity.map((qty) => {
                          return (
                            Number(qty.qty_1) +
                            Number(qty.qty_2) +
                            Number(qty.qty_3) +
                            Number(qty.qty_4) +
                            Number(qty.qty_5) +
                            Number(qty.qty_6) +
                            Number(qty.qty_7) +
                            Number(qty.qty_8) +
                            Number(qty.qty_9) +
                            Number(qty.qty_10) +
                            Number(qty.qty_11) +
                            Number(qty.qty_12) +
                            Number(qty.qty_13) +
                            Number(qty.qty_14) +
                            Number(qty.qty_15) +
                            Number(qty.qty_16)
                          );
                        });

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{values.item_code}</td>
                            <td>{values.inseam}</td>
                            <td>{values.style_name}</td>
                            <td>{values.description}</td>
                            <td>{values.bulk_order_no}</td>
                            <td>{values.location_name}</td>
                            {/* <td>{(values.location_name == 'In Transit' || values.location_name == 'PK Warehouse' || values.location_name == 'Production') && (moment().format("MM-DD-YYYY") !== moment(values.ex_factory_date).format("MM-DD-YYYY")) ? moment(values.ex_factory_date).format("MM-DD-YYYY") : <></>}</td>
                            <td>{(values.location_name == 'In Transit' || values.location_name == 'PK Warehouse' || values.location_name == 'Production') && (moment().format("MM-DD-YYYY") !== moment(values.burgen_indc_date).format("MM-DD-YYYY")) ? moment(values.burgen_indc_date).format("MM-DD-YYYY") : <></>}</td> */}
                            <td>{(moment(values.ex_factory_date).format("MM-DD-YYYY") === "01-01-2000" || moment(values.ex_factory_date).format("MM-DD-YYYY") === "12-31-1999") ? null : moment(values.ex_factory_date).format("MM-DD-YYYY")}</td>
                            <td>{(moment(values.burgen_indc_date).format("MM-DD-YYYY") === "01-01-2000" || moment(values.burgen_indc_date).format("MM-DD-YYYY") === "12-31-1999") ? null : moment(values.burgen_indc_date).format("MM-DD-YYYY")}</td>
                            {/* <td>{(values.location_name === "Production" || values.location_name === "In Transit")  ? (moment(values.ex_factory_date).format("MM-DD-YYYY")) : null}</td>
                            <td>{(values.location_name === "Production" || values.location_name === "In Transit")  ? moment(values.burgen_indc_date).format("MM-DD-YYYY") : null}</td> */}
                            {filterQuantity.map((quan, index) => {
                              console.log("quan", quan);
                              sumQuantity =
                                Number(quan.qty_1) +
                                Number(quan.qty_2) +
                                Number(quan.qty_3) +
                                Number(quan.qty_4) +
                                Number(quan.qty_5) +
                                Number(quan.qty_6) +
                                Number(quan.qty_7) +
                                Number(quan.qty_8) +
                                Number(quan.qty_9) +
                                Number(quan.qty_10) +
                                Number(quan.qty_11) +
                                Number(quan.qty_12) +
                                Number(quan.qty_13) +
                                Number(quan.qty_14) +
                                Number(quan.qty_15) +
                                Number(quan.qty_16);

                              return (
                                <>
                                  {quan.qty_1 && (
                                    <td key={index} style={{ textAlign: "right" }} >{quan.qty_1}</td>
                                  )}
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_2}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_3}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_4}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_5}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_6}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_7}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_8}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_9}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_10}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_11}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_12}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_13}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_14}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_15}</td>
                                  <td style={{ textAlign: "right", paddingRight: "2px" }}>{quan.qty_16}</td>
                                </>
                              );
                            })}
                            <td style={{ textAlign: "right", paddingRight: "2px" }}>{totalQuantity.toString()}</td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig"></td>
                        <td className="font-weig">Total</td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty1 !== qty1 ? "" : qty1}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty2 !== qty2 ? "" : qty2}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty3 !== qty3 ? "" : qty3}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty4 !== qty4 ? "" : qty4}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty5 !== qty5 ? "" : qty5}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty6 !== qty6 ? "" : qty6}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty7 !== qty7 ? "" : qty7}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty8 !== qty8 ? "" : qty8}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty9 !== qty9 ? "" : qty9}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty10 !== qty10 ? "" : qty10}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty11 !== qty11 ? "" : qty11}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty12 !== qty12 ? "" : qty12}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty13 !== qty13 ? "" : qty13}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty14 !== qty14 ? "" : qty14}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty15 !== qty15 ? "" : qty15}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                          {qty16 !== qty16 ? "" : qty16}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>{numeral(sumQ).format("0,00,0")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })
          ) : (
            <div className="no-Data">
              <h1>No Data</h1>
            </div>
          )
        ) : (
          loading && (
            <div className="loader" style={{ marginTop: "20px" }}>
              <Spin size="medium" />
            </div>
          )
        )}
        {console.log(loading, 'loading')}
      </div>
    </div>
  );
};

export default StockStyleWise;