import {
    GET_BUSSINESS_DASHBOARD_REQUEST,
    GET_BUSSINESS_DASHBOARD_SUCCESS,
    GET_BUSSINESS_DASHBOARD_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  
  ///Post Bussiness dashboard
  export const getBussinessDashboard = (token, values) => (dispatch) => {
    dispatch({ type: GET_BUSSINESS_DASHBOARD_REQUEST });
    let lastYear = isNaN(values.last_year);
  
    const newData = {
      company_id: values.company_id !== "All" ? values.company_id : null,
      current_year: values.current_year !== "All" ? values.current_year : null,
      customer_id: values.customer_id !== "All" ? values.customer_id : null,
      gender_id: values.gender_id !== "All" ? values.gender_id : null,
      last_year: lastYear ? null : values.last_year,
      region_id: values.region_id !== "All" ? values.region_id : null,
    };
    
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/getBusinessDashboardReport`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
  
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_BUSSINESS_DASHBOARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BUSSINESS_DASHBOARD_ERROR,
          payload: [],
        });
        //   if (error.response.data.data === "No Record Found !") {
        //     return dispatch({
        //       type: GET_BUSSINESS_DASHBOARD_ERROR,
        //       payload: [],
        //     });
        //   }
        //   return dispatch({
        //     type: GET_REGION_ERROR,
        //     payload: error.response,
        //   });
      });
  };
  