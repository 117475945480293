import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesOrderHeader } from "../../redux/actions/salesOrder.action";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import { Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Tables from "../../components/Table/Tables";
import { saleOrder_Adj_Columns } from "../../utils/cols";
import { useHistory } from "react-router-dom";
// import { getRights } from "../../../../redux/actions/userRights.action";
import { getRights } from "../../redux/actions/userRights.action";

import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
// import { ReadCookie } from "../../../../utils/readCookie";
import { ReadCookie } from "../../utils/readCookie";
import { getAllSalesOrderAdjustmentHeader } from "../../redux/actions/saleOrderAdjustment.action";
import { checkDate } from "../../components/CheckDate/checkDate";
import Swal from "sweetalert2";
export default function SaleOrderAdjustmentList() {
	const [form] = Form.useForm();
	const [dateOP, setDateOP] = useState("");
	const history = useHistory();
	const dispatch = useDispatch();
	const { salesOrderList, saleOrderAdjustmentList } = useSelector((state) => state);
	const [saleOrderID, setSaleOrderID] = useState(null);
	const [startDate, setStartDate] = useState(moment().startOf("year").format("MM-DD-YYYY"));
	const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));
	const [startMonthDate, setStartMonthDate] = useState(moment().startOf("month").format("MM-DD-YYYY"));
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [customerGridView, setCustomerGridView] = useState(true);
	const [customerCode, setCustomerCode] = useState(null);
	const [term, setTerm] = useState(null);
	const [soNo, setSoNo] = useState(null);
	const [poNo, setPoNo] = useState(null);
	const [saleperson, setSaleperson] = useState(null);
	const [region, setRegion] = useState(null);
	const [gender, setGender] = useState(null);
	const [category, setCategory] = useState(null);
	const [from_date, setFromDate] = useState(moment(`1-1-${new Date().getFullYear()}`))
	const [to_date, setToDate] = useState(moment(`12-31-${new Date().getFullYear()}`))
	const [dataLen, setDataLen] = useState(0);
	const [show, setShow] = useState(false);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const [customerAllId, setCustomerAllId] = useState(null);
	const [filteredCustomers, setFilteredCustomers] = [];
	const [customerObj, setCustomerObj] = useState(null);
	const [formState, setFormState] = useState("view");
	const menuId = ReadCookie("menuId");
	const token = ReadCookie("token");
	const { RangePicker } = DatePicker;
	const dateFormat = "MM-DD-YYYY";

	const { Panel } = Collapse;

	// useEffect(() => {
	// 	if (salesOrderList.length === 0) {
	// 		dispatch(getAllSalesOrderHeader(token, startMonthDate, endDate));
	// 	}
	// }, []);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {

		});
	}, []);
	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = (value) => {
		dispatch(getAllSalesOrderHeader(token, startDate, endDate));
		setIsModalVisible(false);
	};

	const onDateChange = (value, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleClose = () => {
		//setFormState("view");
	};

	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};

	const onFinishFilter = (values) => {
		if (moment(from_date).isAfter(to_date)) {
			checkDate(to_date, from_date, setFromDate);
		} else if (moment(from_date).isSame(to_date)) {
			dispatch(getAllSalesOrderHeader(token, soNo, form.getFieldValue().from_date, form.getFieldValue().to_date, poNo, customerCode, saleperson, region, gender, term)).then((res) => {
				// setFilteredCustomers(res?.payload?.data)
				setDataLen(res?.payload?.data);

				// setCustomerGridView(true);
				// 
			});
		} else if (moment(from_date).isBefore(to_date)) {
			dispatch(getAllSalesOrderHeader(token, soNo, form.getFieldValue().from_date, form.getFieldValue().to_date, poNo, customerCode, saleperson, region, gender, term)).then((res) => {
				// setFilteredCustomers(res?.payload?.data)
				setDataLen(res?.payload?.data);

				// setCustomerGridView(true);
				// 
			});
		}
	};

	// 
	// useEffect(() => {
	// 	if (formState === "table") {
	// 	  setCustomerGridView(true);
	// 	  setCustomerObj(null);
	// 	  //setFormState("edit");
	// 	}
	// 	if (formState === "add") {
	// 	  setCustomerObj(null);

	// 	}
	//   }, []);



	useEffect(() => {

	}, [form]);

	useEffect(() => {
		form.setFieldsValue({
			from_date: moment("01-01-2023"),
			to_date: moment("01-31-2023"),
		});
	}, []);


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Sales Order List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									//   disabled={true}
									onClick={() => {
										history.push("/sale-order/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={{
									remember: true,
								}}
								onFinish={onFinishFilter}
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="sono" label="SO NO" value={soNo}>
											<Input
												allowClear
												onChange={(e) => setSoNo(e.target.value)}
												// placeholder="SO NO"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
											// value={form.getFieldValue().from_date} 
											initialValue={from_date}
										>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												placeholder={from_date}
												// value={form.getFieldValue().from_date}
												allowClear
												className="dateInput customInputs"
												// onChange={(e) => check(moment(e))}
												onChange={(e) => {
													setFromDate(moment(e));
													form.setFieldsValue({
														from_date: moment(e)
													});
													setDateOP("fromdate")
												}}

											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
											//  value={form.getFieldValue().to_date} 
											initialValue={to_date}
										>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												// value={form.getFieldValue().to_date}
												placeholder={to_date}
												allowClear
												className="dateInput customInputs"
												onChange={(e) => {
													setToDate(moment(e));
													form.setFieldsValue({
														to_date: moment(e)
													});
													setDateOP("todate")
												}}
											// onChange={(e) => setToDate(moment(e))}

											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="PO no" label="PO NO" value={poNo}>
											<Input
												allowClear
												onChange={(e) => setPoNo(e.target.value)}
												// placeholder="PO NO"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="customer_code" label="Customer Code" value={customerCode}>
											<Input
												allowClear
												onChange={(e) => setCustomerCode(e.target.value)}
												// placeholder="Customer Code"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="saleperson name" label="Sale Person Name" value={saleperson}>
											<Input
												allowClear
												onChange={(e) => setSaleperson(e.target.value)}
												// placeholder="Sale person Name"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="region" label="Region" value={region}>
											<Input
												allowClear
												onChange={(e) => setRegion(e.target.value)}
												// placeholder="Region"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="gender" label="Gender" value={gender}>
											<Input
												allowClear
												onChange={(e) => setGender(e.target.value)}
												// placeholder="Gender"
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="term" label="Terms" value={term}>
											<Input
												allowClear
												onChange={(e) => setTerm(e.target.value)}
												// placeholder="Term"
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>

								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={salesOrderList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{saleOrderAdjustmentList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									dataLen={dataLen}
									setDataLen={setDataLen}
									show={show}
									handleFilter={handleFilter}
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									customerAllId={setCustomerAllId}
									// customerObj={setCustomerObj}
									scroll={570}
									perPage={100}
									scrollX={"80rem"}
									col={saleOrder_Adj_Columns}
									source={saleOrderAdjustmentList?.payload && saleOrderAdjustmentList?.payload[0]?.data}
									load={saleOrderAdjustmentList.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
