import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie, } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { loadCountry } from '../../redux/actions/adminCountry';
import { logColumns, cityColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import { getCity, addCity, updateCity, deleteCity } from '../../redux/actions/city.actions';
import notification from '../../utils/notification'
import { loadShipVia } from '../../redux/actions/shipVia';
import { TabTitle } from '../../utils/GeneralFunction';



const { TabPane } = Tabs;
const { Option } = Select

const City = ({ token }) => {
    TabTitle("City")
    const { City, userRights, log, country } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [cityName, setCityName] = useState('');
    const [form] = Form.useForm();
    const cityNameInput = useRef(null);
    const countryNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [countryid, setCountryId] = useState(null)
    const [cityObj, setCityObj] = useState(null);
    const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');


    useEffect(() => {
        dispatch(loadShipVia(token))
    }, [dispatch, token])

    useEffect(() => {
        dispatch(loadCountry(token))
    }, [dispatch, token])

    const handleDelete = () => {
        if (cityObj) {

            dispatch(deleteCity(token, cityObj.city_id)).then((res) => {
                if (res.type === "DELETE_CITY_SUCCESS") {
                    notification("success", "City Deleted", "City deleted successfully!");
                }
                if (res.type === "DELETE_CITY_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    city: "",
                    cid: null
                })
                // setCheck(false)
                setCityName("")
                setCityObj(null)
                setCountryId(null)
                setFormState('view')
                dispatch(getCity(token, id))

            })
        }
        setFormState("view")
    }

    useEffect(() => {
        dispatch(getCity(token, id))
    }, [token, id, dispatch])

    useEffect(() => {

        if (cityObj) {
            setFormState('view')
            form.setFieldsValue({
                id: cityObj.city_id,
                city: cityObj.city_name,
                active: cityObj.is_active,
                cid: cityObj.country_id
            });
            setCountryId(cityObj.cid)
            setCityName(cityObj.city_name)
            setCheck(cityObj.is_active)


        }

    }, [cityObj]);

    useEffect(() => {
        if (cityName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [cityName])

    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {
        dispatch(getLog(token, { admin_city: "admin_city" }))
    }, [dispatch, token])

    const formSave = async () => {
        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addCity(token, { city_name: cityName, is_active: check, country_id: countryid }, id)).then((res) => {
                    if (res.type === "ADD_CITY_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_CITY_SUCCESS") {
                        notification("success", "City Created", "City created successfully!");
                        form.setFieldsValue({
                            style: ""
                        })
                        setFormState("view")
                        // setCheck(false)
                        setCityName("")
                        setCityObj(null)
                    }
                    dispatch(getCity(token, id))

                })

            }
            if (formState === "edit") {
                dispatch(updateCity(token, { city_name: cityName, is_active: check, country_id: cityObj.country_id }, cityObj.city_id)).then((res) => {

                    if (res.type === "UPDATE_CITY_ERROR") {
                        notification("error", "Update", "Something went wrong")
                    }
                    if (res.type === "UPDATE_CITY_SUCCESS") {
                        notification("success", "City Updated", "Record updated successfully")
                    }
                    dispatch(getCity(token, id))
                })

                // setFormState("view")

            }

        }).catch((err) => {

        })




    }

    useEffect(() => {
        if (formState === "add") {
            countryNameInput.current.focus();
            // cityNameInput.current.focus();
            form.setFieldsValue({
                cid: null,
                city: null,
            })
            setCheck(true)
            setCountryId(null)

            setCityName("")
            setCityObj(null)
        }
        // else if(formState=== "view"){
        //     form.setFieldsValue({
        //         cid:null,
        //         state: null,


        //     })
        //     setCheck(true)
        //     setCountryId(null)

        //     setStateName("")
        //     setStateObj(null)
        // }

    }, [formState]);

    const filterReset = () => {
        setFormState("view")
        setCityObj(null)
        setCheck(true)
        form.setFieldsValue({
            cid: null,
            city: null,

        })
        setCityName(null)

    }

    const selectHandler = (e) => {
        if (formState === "edit") {
            cityObj.country_id = e
        } else {
            setCountryId(e)
        }
    }

    return (
        <div className='styleContainer'>
            <div className='styleBody h-100' >
                <PageHeader title={"City"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="city" formState={formState} cityObj={setCityObj} scrollX={"20rem"} source={City?.payload?.data} col={cityColumns} paginate scroll={500} perPage={25} load={City.loading} rowKey="city_id" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            // labelCol={{ span: 8 }}

                                            >
                                                <div className='customRow styleFormUpper ' >
                                                    {cityObj !== null && !(formState === "add") &&
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="ID"
                                                                name="id"
                                                                initialValue={form.getFieldValue().id}
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 14 }}

                                                            >
                                                                <Input size='small' className='styleInput id-field-style' readOnly />

                                                            </Form.Item>
                                                        </div>


                                                    }
                                                    <div className="customCols slc">
                                                        <div className='country-spaceremove-mob mlpx-10 relative'>
                                                            <Form.Item
                                                                name="cid"
                                                                label="Country "
                                                                labelAlign='left'
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 15 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    style={{ marginLeft: "-5px" }}
                                                                    size='small'
                                                                    showSearch
                                                                    ref={countryNameInput}
                                                                    loading={country.loading}
                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                  }
                                                                    optionFilterProp="children"
                                                                    //    defaultValue={
                                                                    //      formState === "edit" || formState==="add"
                                                                    //        ? form.getFieldValue().cid
                                                                    //        : null
                                                                    //    }
                                                                    // className="w-100"
                                                                    // placeholder="Country"

                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }

                                                                    onSelect={(e) => selectHandler(e)}
                                                                    allowClear

                                                                >
                                                                    {country?.payload?.data.map((country) => {
                                                                        return (
                                                                            <Option key={country.country_id} value={country.country_id}>
                                                                                {country.country_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="City"
                                                            name="city"
                                                            initialValue={form.getFieldValue().city}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >

                                                            <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={cityNameInput} onChange={(e) => setCityName(e.target.value)} className='styleInput' />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>

                                        <div className='modalStyle' >


                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  /**onChange={(key) => handleTab(key)} */ >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log?.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                        {/* <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}
                                            >
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div> */}
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>





    )
}


export default City