import {
    GET_VENDOR_BILLING_ERROR,
    GET_VENDOR_BILLING_REQUEST,
    GET_VENDOR_BILLING_SUCCESS,
    ADD_VENDOR_BILLING_REQUEST,
    ADD_VENDOR_BILLING_SUCCESS,
    ADD_VENDOR_BILLING_ERROR,
    DELETE_VENDOR_BILLING_REQUEST,
    DELETE_VENDOR_BILLING_SUCCESS,
    DELETE_VENDOR_BILLING_ERROR,
    UPDATE_VENDOR_BILLING_REQUEST,
    UPDATE_VENDOR_BILLING_SUCCESS,
    UPDATE_VENDOR_BILLING_ERROR,
    GET_CUSTOMER_SINGLE_BILLING_REQUEST,
    GET_CUSTOMER_SINGLE_BILLING_SUCCESS,
    GET_CUSTOMER_SINGLE_BILLING_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getVendorBilling = (token, id) => (dispatch) => {
    dispatch({ type: GET_VENDOR_BILLING_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/vendorBill/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: GET_VENDOR_BILLING_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_VENDOR_BILLING_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_VENDOR_BILLING_ERROR,
                payload: error.response,
            });
        });
};

export const addVendorBiller = (token, data) => (dispatch) => {
    dispatch({ type: ADD_VENDOR_BILLING_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/vendorBill`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data : { vendors: data }
        // data: typeText === "edit" ? 

        // data: { vendors: data, }
        // data: { ...data[0], customer_id: id }
    })

        .then((response) => {
            return dispatch({ type: ADD_VENDOR_BILLING_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_VENDOR_BILLING_ERROR,
                payload: error.response,
            });
        });
};

export const deleteVendorBiller = (token, id) => dispatch => {
    dispatch({ type: DELETE_VENDOR_BILLING_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/vendorBill/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
    })
        .then(response => {

            return dispatch({ type: DELETE_VENDOR_BILLING_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_VENDOR_BILLING_ERROR,
                payload: error.response,
            });
        })
}

export const updateVendorBiller = (token, data, id) => dispatch => {
    dispatch({ type: UPDATE_VENDOR_BILLING_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/vendorBill/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: {
            is_active: true,
            addressee: data.addressee,
            address_1: data.address_1,
            address_2: data.address_2,
            address_3: data.address_3,
            city_id: data.city_id,
            country_id: data.country_id,
            state_id: data.state_id,
            email: data.email,
            fax: data.fax,
            phone: data.phone,
            zip_code: data.zip_code,
        },
    })
        .then(response => {

            return dispatch({ type: UPDATE_VENDOR_BILLING_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_VENDOR_BILLING_ERROR,
                payload: error.response,
            });
        })
}

export const getSingleBilling = (token, id) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_SINGLE_BILLING_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/billTo/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: GET_CUSTOMER_SINGLE_BILLING_SUCCESS, payload: response.data });

        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_SINGLE_BILLING_ERROR,
                payload: error.response,
            });
        });
};
