import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";
export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, rights, edit, formData }) {
	const history = useHistory();
	const confirmDelete = () => {
		onDelete();
	};
	return (
		<>
			<div className="crudContainer">
				<div>
					{formType === "" ? null : (
						<div className="buttons-div">
							<Tag color={"green"} key={1}>
								{formType.toUpperCase()}
							</Tag>
							{formType !== "view" && (
								<Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />
							)}
						</div>
					)}
				</div>
				<div>
					<Tooltip placement="bottom" title="Search" color="#fbfbfb">
						<Button
							onClick={() => {
								history.push("/credit-note/list");
							}}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<SearchOutlined />}
						/>
					</Tooltip>
					{/*Add Button*/}
					<Tooltip placement="bottom" title="Add" color="#fbfbfb">
						<Button
							disabled={true}
							onClick={() => {
								if (formType === "add") {
									formState("add");
								} else {
									history.push("/item-receipt/add?mode=add");
									formState("add");
								}
							}}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<PlusOutlined />}
						/>
					</Tooltip>
					{/*Edit Button*/}
					<Tooltip placement="bottom" title="Edit" color="#fbfbfb">
						<Button
							disabled={disable ||  formType === "add" || (rights?.can_edit === false ? true : false)}
							onClick={() => formState("edit")}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<EditOutlined />}
						/>
					</Tooltip>
					{/*Delete Button*/}
					<Tooltip placement="bottom" title="Delete" color="#fbfbfb">
						{rights?.can_delete === false ? (
							<Button disabled={edit ? false : true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
						) : (
							<Popconfirm
								placement="topLeft"
								title={`Are you sure to delete Receipt # ${formData?.receipt_header_no}?`}
								onConfirm={confirmDelete}
								okText="Yes"
								cancelText="No"
							>
								<Button disabled={edit ? false : true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
							</Popconfirm>
						)}
					</Tooltip>
					{/*Save Button*/}
					<Tooltip placement="bottom" title="Save" color="#fbfbfb">
						<Button onClick={() => save()} className="iconBtn crudBTN" shape="circle" icon={<FileDoneOutlined />} />
					</Tooltip>
				</div>
			</div>
			<Divider />
		</>
	);
}