import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess, } from "../../redux/actions/companyAccess";
import { getVendorCategory, addVendorCategory, updateVendorCategory, deleteVendorCategor, deleteVendorCategory } from "../../redux/actions/vendorCategory.action"
import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import { ReadCookie } from "../../utils/readCookie";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from "antd";
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { vendroCatColumns, logColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";

const { TabPane } = Tabs;

export default function VendorCategory({ token }) {
    TabTitle("Vendor Category")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const vendorCatInput = useRef(null);
    const [form] = Form.useForm();
    const [vendorCatObj, setVendorCatObj] = useState(null);
    const dispatch = useDispatch();
    const { log, userRights, vendorCategory } = useSelector((state) => state);
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [vendorCatName, setVendorCat] = useState("");
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState("");

    useEffect(() => {
        dispatch(getVendorCategory(token));
    }, [token]);

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
        dispatch(getLog(token, { scm_vendor_category: "scm_vendor_category" }));
    }, [])

    useEffect(() => {

        if (vendorCatObj) {
            setFormState("view");
            form.setFieldsValue({
                id: vendorCatObj.vendor_category_id,
                vendorCat: vendorCatObj.category_name,
                active: vendorCatObj.is_active,
            });
            setVendorCat(vendorCatObj.category_name);
            setCheck(vendorCatObj.is_active);
        }
    }, [vendorCatObj]);

    useEffect(() => {
        if (vendorCatName === "") {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [vendorCatName]);

    const handleTab = (key) => {
        if (key === "logs") {
            dispatch(getLog(token, { inv_wash: "inv_wash" }));
        }
    };

    const formSave = () => {
        if (formState === "add") {
            dispatch(addVendorCategory(token, { vendorCatName, check })).then((res) => {

                // 
                if (res.type === "ADD_VENDOR_CATEGORY_ERROR") {
                    notification(
                        "error",
                        res?.payload?.request?.statusText,
                        res?.payload?.data?.data
                    );
                }
                if (res.type === "ADD_VENDOR_CATEGORY_SUCCESS") {
                    let data = {
                        company_id: dCompanyId,
                    };
                    // 
                    // 
                    dispatch(
                        createCompanyAccess(token, data, res?.payload?.vendor_category_id, menuId)).then((res) => dispatch(getCompanyAccess(token, vendorCatObj.vendor_category_id, menuId)));
                    notification("success", "Vendor Category Created", "Vendor Category created successfully!");
                    form.setFieldsValue({
                        vendorCat: "",
                    });
                    setFormState("");
                    // setCheck(false);
                    setVendorCat("");
                    setVendorCatObj(null);
                }
                dispatch(getVendorCategory(token));
            });
        }

        if (formState === "edit") {
            dispatch(updateVendorCategory(token, { vendorCatName, check }, vendorCatObj.vendor_category_id)).then((res) => {
                if (res.type === "UPDATE_VENDOR_CATEGORY_ERROR") {
                    notification("error", "Update", "Something went wrong");
                }
                if (res.type === "UPDATE_VENDOR_CATEGORY_SUCCESS") {
                    notification("success", "Vendor Category Updated", "Vendor Category updated successfully");
                }
                dispatch(getVendorCategory(token));
            });
        }
    };

    const handleDelete = () => {
        if (vendorCatObj) {
            dispatch(deleteVendorCategory(token, vendorCatObj.vendor_category_id)).then((res) => {
                if (res.type === "DELETE_VENDOR_CATEGORY_SUCCESS") {
                    notification(
                        "success",
                        "Vendor Category Deleted",
                        "Vendor Category Deleted Successfully!"
                    );
                }
                if (res.type === "DELETE_VENDOR_CATEGORY_ERROR") {
                    notification(
                        "error",
                        res?.payload?.request?.statusText,
                        res?.payload?.data?.data
                    );
                }
                form.setFieldsValue({
                    vendorCat: "",
                });
                setCheck(false);
                setVendorCat("");
                setVendorCatObj(null);
                setFormState("");
                dispatch(getVendorCategory(token));
            });
        }
    };

    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            vendorCatInput.current.focus();
            form.setFieldsValue({
                vendorCat: "",
            });
            setCheck(true);
            setVendorCat("");
            setVendorCatObj(null);
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view");
    };

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Vendor Category"}
                />
                <div className="bodyStyle">
                    <div className="crudDiv">
                        <CrudButtons
                            rights={userRights?.payload}
                            reset={filterReset}
                            formType={formState}
                            save={formSave}
                            disable={disable}
                            formState={setFormState}
                            onDelete={handleDelete}
                        />
                    </div>

                    <div className="styleContent">
                        <Row className='tables-main-row-new'>
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                <div className="styleTable">
                                    <Tables
                                        type="vendorCat"
                                        vendorCatObj={setVendorCatObj}
                                        scrollX={"20rem"}
                                        scroll={602}
                                        perPage={25}
                                        source={vendorCategory?.payload?.data}
                                        col={vendroCatColumns}
                                        paginate
                                        load={vendorCategory?.loading}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                <div className="styleForm">
                                    <Form form={form} name="basic" labelCol={{ span: 12 }}>
                                        <div className='customRow styleFormUpper ' >
                                            {vendorCatObj !== null && !(formState === "add") && (
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="ID"
                                                        name="id"
                                                        labelCol={{ span: 13 }}
                                                        wrapperCol={{ span: 14 }}
                                                        initialValue={form.getFieldValue().id}
                                                    >
                                                        <Input className="styleInput" size='small' readOnly />
                                                    </Form.Item>
                                                </div>
                                            )}
                                            <div className='customCols slc'>
                                                <Form.Item
                                                    label="Vendor Category Name"
                                                    name="vendorCat"
                                                    labelCol={{ span: 13 }}
                                                    wrapperCol={{ span: 14 }}
                                                    initialValue={form.getFieldValue().vendorCat}
                                                >
                                                    <Input
                                                        size='small'
                                                        disabled={
                                                            !(formState === "add" || formState === "edit")
                                                        }
                                                        ref={vendorCatInput}
                                                        onChange={(e) => setVendorCat(e.target.value)}
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className='customCols slc'>
                                                <Form.Item label="Active" name="active" labelCol={{ span: 13 }} wrapperCol={{ span: 14 }}>
                                                    <Checkbox
                                                        disabled={
                                                            !(formState === "add" || formState === "edit")
                                                        }
                                                        checked={check}
                                                        onChange={(e) => setCheck(e.target.checked)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>

                                    <Divider />
                                    <Tabs
                                        defaultActiveKey="logs"
                                        onChange={(key) => handleTab(key)}
                                    >
                                        <TabPane tab="Log Info" key="logs">
                                            <div className="tabs">
                                                <Tables
                                                    type="logs"
                                                    scrollX={"50rem"}
                                                    scroll={360}
                                                    perPage={15}
                                                    source={log?.payload?.data}
                                                    col={logColumns}
                                                    load={log.loading}
                                                    paginate
                                                />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </div>
    );
}
