import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFabricClass2,
  addFabricClass2,
  deleteFabricClass2,
  updateFabricClass2,
} from "../../redux/actions/FabricClass2.action";

import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";

import { logColumns, fabricClass2Columns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function FabricClass2({ token }) {
  TabTitle("Fabric Class 2")
  const menuId = ReadCookie("menuId");

  const FabricClass2NameInput = useRef(null);
  const [form] = Form.useForm();
  const [fabricClass2Obj, setFabricClass2Obj] = useState(null);
  const dispatch = useDispatch();
  const { fabricClass2, log, userRights } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [fabricClass2Name, setFabricClass2Name] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");

  useEffect(() => {
    dispatch(getFabricClass2(token, menuId));
    // dispatch(getLog(token));
    dispatch(getLog(token, { inv_fabric_class2: "inv_fabric_class2" }))
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (fabricClass2Obj) {
      setFormState("view");
      form.setFieldsValue({
        id: fabricClass2Obj.fabric_class_id2,
        fabricClass2: fabricClass2Obj.fabric_class_name2,
        active: fabricClass2Obj.is_active,
      });
      setFabricClass2Name(fabricClass2Obj.fabric_class_name2);
      setCheck(fabricClass2Obj.is_active);
    }
  }, [fabricClass2Obj]);

  useEffect(() => {
    if (fabricClass2Name === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [fabricClass2Name]);

  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(
        getLog(token, {
          inv_fabric_class2: "inv_fabric_class2",
          admin_company_entity_access: "admin_company_entity_access",
        })
      );
    }
  };

  const formSave = () => {
    if (formState === "add") {
      dispatch(addFabricClass2(token, { fabricClass2Name, check }, menuId)).then(
        (res) => {
          if (res.type === "ADD_FABRICCLASS2_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          if (res.type === "ADD_FABRICCLASS2_SUCCESS") {
            notification(
              "success",
              "Fabric Class Created",
              "Record updated successfully"
            );
            form.setFieldsValue({
              fabricClass2: ""
          })
            setFormState("")
            // setCheck(false)
            setFabricClass2Name("")
            setFabricClass2Obj(null)
          }
          dispatch(getFabricClass2(token));
        }
      );
    }

    if (formState === "edit") {
      dispatch(
        updateFabricClass2(
          token,
          { fabricClass2Name, check },
          fabricClass2Obj.fabric_class_id2
        )
      ).then((res) => {
        if (res.type === "UPDATE_FABRICCLASS2_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_FABRICCLASS2_SUCCESS") {
          notification(
            "success",
            "Fabric Class Updated",
            "Record updated successfully"
          );
        }
        dispatch(getFabricClass2(token));
      });
    }
  };

  const handleDelete = () => {
    if (fabricClass2Obj) {
      dispatch(deleteFabricClass2(token, fabricClass2Obj.fabric_class_id2)).then(
        (res) => {
          if (res.type === "DELETE_FABRICCLASS2_SUCCESS") {
            notification(
              "success",
              "Fabric Class Deleted",
              "Fabric Class deleted successfully!"
            );
          }
          if (res.type === "DELETE_FABRICCLASS2_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            fabricClass2: "",
          });
          // setCheck(false)
          setFabricClass2Name("");
          setFabricClass2Obj(null);
          setFormState("");
          dispatch(getFabricClass2(token));
        }
      );
    }
  };

  useEffect(() => {
    if (formState === "add") {
       
        FabricClass2NameInput.current.focus();
        form.setFieldsValue({
            fabricClass2: ""
        })
        setCheck(true)
        setFabricClass2Name("")
        setFabricClass2Obj(null)
    }
}, [formState]);


  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Fabric Class 2"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="fabricClass2"
                      fabricClass2Obj={setFabricClass2Obj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={fabricClass2?.payload?.data}
                      col={fabricClass2Columns}
                      paginate
                      load={fabricClass2.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 8 }}>
                      <div className="customRow styleFormUpper ">
                        {fabricClass2Obj !== null && !(formState === "add") && (
                          <div className="customCols slc">
                            <Form.Item
                              label="ID"
                              name="id"
                              initialValue={form.getFieldValue().id}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input
                                size="small"
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className="customCols slc">
                          <Form.Item
                            label="Fabric Class 2"
                            name="fabricClass2"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            initialValue={form.getFieldValue().style}
                          >
                            <Input
                              size="small"
                              // placeholder="Fabric Class 2 Name"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={FabricClass2NameInput}
                              onChange={(e) =>
                                setFabricClass2Name(e.target.value)
                              }
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc">
                          <Form.Item
                            label="Active"
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
