import { GET_DETAIL_ERROR, GET_DETAIL_REQUEST, GET_DETAIL_SUCCESS, GET_RELATED_TRANSAACTION_REQUEST, GET_RELATED_TRANSACTION_SUCCESS, GET_RELATED_TRANSACTION_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js"

const axios = require("axios");
export const getProductForm = (token, menu_id, company_id) => dispatch => {
    dispatch({ type: GET_DETAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getDetail`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { menu_id, company_id: 3 }
    })
        .then(response => {
            return dispatch({ type: GET_DETAIL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_DETAIL_ERROR,
                payload: error.response,
            });
        })
}

export const getRelatedTransactions = (token, id) => dispatch => {
    dispatch({ type: GET_RELATED_TRANSAACTION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/itemTransaction`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { id }
    })
        .then(response => {
            return dispatch({ type: GET_RELATED_TRANSACTION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_RELATED_TRANSACTION_ERROR,
                payload: error.response,
            });
        })
}