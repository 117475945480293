import { GET_FABRICTYPE_REQUEST, GET_FABRICTYPE_SUCCESS, GET_FABRICTYPE_ERROR, ADD_FABRICTYPE_REQUEST, ADD_FABRICTYPE_ERROR, ADD_FABRICTYPE_SUCCESS, UPDATE_FABRICTYPE_REQUEST, UPDATE_FABRICTYPE_SUCCESS, UPDATE_FABRICTYPE_ERROR, DELETE_FABRICTYPE_ERROR, DELETE_FABRICTYPE_REQUEST, DELETE_FABRICTYPE_SUCCESS } from "./types"

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabricType = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_FABRICTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fabricType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_FABRICTYPE_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_FABRICTYPE_ERROR, payload: error.response })
    })
}

export const addFabricType = (token, data) => (dispatch) => {
    // 
    dispatch({ type: ADD_FABRICTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/fabricType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_type_name: data.fabricTypeName, is_active: data.check },
    })
        .then((response) => {
            // 
            return dispatch({ type: ADD_FABRICTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FABRICTYPE_ERROR,
                payload: error.response,
            });
        });
};
export const updateFabricType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FABRICTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fabricType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_type_name: data.fabricTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FABRICTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FABRICTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFabricType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FABRICTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fabricType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            // 
            return dispatch({ type: DELETE_FABRICTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FABRICTYPE_ERROR,
                payload: error.response,
            });
        });
};
