import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { searchProduct } from "../../redux/actions/searchProduct.action";
import { sendProduct } from "../../redux/actions/sendProductToBergen.action";
import notification from "../../utils/notification";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import Swal from "sweetalert2";

const ProductToBergen = () => {
  TabTitle("Style Master to Bergen");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Style",
      dataIndex: "ProductCode",
      key: "ProductCode",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className="pop-search-gen pop-170"
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.ProductCode.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.ProductCode - b.ProductCode;
      },
    },
    // {
    //   title: "Cut",
    //   dataIndex: "Cut",
    //   key: "Cut",
    // },
    {
      title: "Color",
      dataIndex: "Washtype",
      key: "Washtype",
    },
    {
      title: "Size",
      dataIndex: "ProductSize",
      key: "ProductSize",
    },
    {
      title: "UPC",
      dataIndex: "UPC",
      key: "UPC",
    },
    {
      title: "Description",
      render: (text, record) => {
        return record.style + " " + record.Cut;
      },
    },
    {
      title: "Price",
      render: (text, record) => {
        return <>{numeral(record.RetailRate).format("0,00")}</>;
      },
      align: "right",
    },
    {
      title: "HsCode",
      dataIndex: "HsCode",
      key: "HsCode",
    },
    {
      title: "Custom Description",

      render: (text, record) => {
        return record.Type.toUpperCase() + " " + record.commodity;
      },
    },
    {
      title: "Country of Origin",
      dataIndex: "COO",
      key: "COO",
    },
    // {
    //   title: "NRF Color",
    //   dataIndex: "NRFColor",
    //   key: "NRFColor",
    // },
    // {
    //   title: "NRF Size",
    //   dataIndex: "NRFSize",
    //   key: "NRFSize",
    // },
    // {
    //   title: "Type",
    //   dataIndex: "Type",
    //   key: "Type",
    // },



  ];

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.sku) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert SKU.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          setLoading(true);
          dispatch(searchProduct(token, values)).then((res) => {
            console.log(res, 'res.type');
            setLoading(false);
              if (res?.payload?.data) {  
                setLoading(false);
                setControlSheet(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
              }

          })
          .catch((err)=> {
            console.log(err, 'res.type1');
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // setLoading(true);
    // await form
    //   .validateFields()
    //   .then((values) => {
    //     dispatch(getcontrolSheetLog(token, values)).then((res) => {
    //       setLoading(false);
    //       setControlSheet(res?.payload?.data);
    //       downloadExcelFileData(res?.payload?.data);
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  const onSearch = async () => {
    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchProduct(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  function downloadExcelFileData(data) {
    console.log(data);
    setExcelData(
      data.map((v) => {
        return {
          "Style": v.ProductCode,
          "Color": v.Washtype,
          "Size": v.ActSize,
          "UPC": v.UPC,
          "Description": v.style + v.Cut,
          "Price": v.RetailRate,
          "HsCode": v.HsCode,
          "Custom Description": v.Type.toUpperCase() + " " + v.commodity,
          "Country of Origin": v.COO,
        };
      })
    );
  }

  const onSubmit = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.sku) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert SKU.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          // setLoading(true);
          dispatch(sendProduct(token, values)).then((res) => {
            if (res.type == "GET_SEND_PRODUCT_SUCCESS") {
              notification("success", "Style Send Successfully");
            }
            // setLoading(false);
            // setControlSheet(res?.payload?.data);
            downloadExcelFileData(res?.payload?.data);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchProduct(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Send Style Master to Bergen"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2 d-flex align-items-baseline">
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                style={{ width: "20%" }}
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={24} xl={24} xxl={24}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="sku"
                            label="SKU"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            labelAlign="left"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 2 }}
                          >
                            <Button
                              type="primary"
                              className=""
                              onClick={onSearch}
                            >
                              <span className="px-2">Search</span>
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary mrpx-20"
                onClick={onFinish}
              >
                <span className="px-2">Search</span>
              </Button>

              {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : controlSheet.length > 0 ? (
          <>
            <Table
              title={() => (
                <CSVLink
                  filename={"StyleMaster.csv"}
                  data={excelData}
                  onClick={() => {}}
                >
                  Download Excel
                </CSVLink>
              )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={controlSheet}
              columns={columns}
              loading={controlLog.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
            />
          </>
        ) : (
          // <></>
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductToBergen;
