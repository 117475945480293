import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminPaymentTypes } from "../../redux/actions/adminPaymentType.action"
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Table, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { getARAccounts } from "../../redux/actions/AR_Account.action";
import { getcit } from "../../redux/actions/cit.action";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
const { Option } = Select;

const CitReport = () => {
  TabTitle("Cit Report")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [citList, setCitSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const {
    cit
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])


  useEffect(() => {
    dispatch(getARAccounts(token));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAdminPaymentTypes(token));
    dispatch(loadSalePerson(token));
    dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
  }, [dispatch, menuId, token]);



  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Client No",
      dataIndex: "Client Number (Required)",
      key: "Client Number (Required)",
      width: 70,
    },
    {
      title: "Client #",
      dataIndex: "Client Customer # (required)",
      key: "Client Customer # (required)",
      width: 70,
    },
    {
      title: "Customer Name",
      dataIndex: "Customer Name (required)",
      key: "Customer Name (required)",
      width: 70,
    },
    {
      title: "Address 1",
      dataIndex: "Customer Address1 (required)",
      key: "Customer Address1 (required)",
      width: 150,
    },
    {
      title: "Address 2",
      dataIndex: "Customer Address2 (conditional)",
      key: "Customer Address2 (conditional)",
      width: 150,
    },
    {
      title: "Customer City",
      dataIndex: "Customer City (required)",
      key: "Customer City (required)",
      width: 120,
    },
    {
      title: "Customer State",
      dataIndex: "Customer State (required)",
      key: "Customer State (required)",
      width: 100,
    },
    {
      title: "Customer Zip",
      dataIndex: "Customer Zip (required)",
      key: "Customer Zip (required)",
      width: 100,
    },
    {
      title: "Customer Phone",
      dataIndex: "Customer Phone (required)",
      key: "Customer Phone (required)",
      width: 100,
    },
    {
      title: "Invoice #",
      dataIndex: "Invoice Number (required)",
      key: "Invoice Number (required)",
      width: 80,
    },
    {
      title: "Sum of Invoice Amount",
      dataIndex: "Sum of Invoice Amount (required - negative if credit memo)",
      key: "Sum of Invoice Amount (required - negative if credit memo)",
      width: 130,
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Invoice Date",
      dataIndex: "Invoice Date (required)",
      key: "Invoice Date (required)",
      width: 100,
    },
    {
      title: "Client Term Code",
      dataIndex: "Client Terms Code (conditional)",
      key: "Client Terms Code (conditional)",
      width: 100,
    },
    {
      title: "Client Term Name",
      dataIndex: "Client Terms Description (conditional)",
      key: "Client Terms Description (conditional)",
      width: 100,
    },
    {
      title: "Merchandise Amount",
      dataIndex: "Merchandise Amount (conditional)",
      key: "Merchandise Amount (conditional)",
      width: 120,
    },
    {
      title: "Customer Store",
      dataIndex: "Customer Store # (conditional)",
      key: "Customer Store # (conditional)",
      width: 100,
    },
    {
      title: "PO #",
      dataIndex: "Customer PO Number (required if known)",
      key: "Customer PO Number (required if known)",
      width: 100,
    },
    {
      title: "Customer Department",
      dataIndex: "Customer Department Number (conditional)",
      key: "Customer Department Number (conditional)",
      width: 120,
    },
    {
      title: "Discount Amount",
      dataIndex: "Discount Amount (conditional)",
      key: "Discount Amount (conditional)",
      width: 100,
    },
    {
      title: "Original Invoice #",
      dataIndex: "Original Invoice Number(if item is Cr Memo)",
      key: "Original Invoice Number(if item is Cr Memo)",
      width: 100,
    },
    {
      title: "Risk Code",
      dataIndex: "Risk Code (conditional)",
      key: "Risk Code (conditional)",
      width: 80,
    },
    {
      title: "Discount Code",
      dataIndex: "Discount Code (conditional)",
      key: "Discount Code (conditional)",
      width: 100,
    },
    {
      title: "Invoice As Of Date",
      dataIndex: "Invoice As Of Date (conditional)",
      key: "Invoice As Of Date (conditional)",
      width: 100,
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getcit(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setCitSummaryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Client Number (Required)": v["Client Number (Required)"],
          "Client Customer # (required)": v["Client Customer # (required)"],
          "Customer Name (required)": v["Customer Name (required)"],
          "Customer Address1 (required)": v["Customer Address1 (required)"],
          "Customer Address2 (conditional)": v["Customer Address2 (conditional)"],
          "Customer City (required)": v["Customer City (required)"],
          "Customer State (required)": v["Customer State (required)"],
          "Customer Zip (required)": v["Customer Zip (required)"],
          "Customer Phone (required)": v["Customer Phone (required)"],
          "Invoice Number (required)": v["Invoice Number (required)"],
          "Sum of Invoice Amount (required - negative if credit memo)": v["Sum of Invoice Amount (required - negative if credit memo)"],
          "Invoice Date (required)": v["Invoice Date (required)"],
          "Client Terms Code (conditional)": v["Client Terms Code (conditional)"],
          "Client Terms Description (conditional)": v["Client Terms Description (conditional)"],
          "Merchandise Amount (conditional)": v["Merchandise Amount (conditional)"],
          "Customer Store # (conditional)": v["Customer Store # (conditional)"],
          "Customer PO Number (required if known)": v["Customer PO Number (required if known)"],
          "Customer Department Number (conditional)": v["Customer Department Number (conditional)"],
          "Discount Amount (conditional)": v["Discount Amount (conditional)"],
          "Original Invoice Number(if item is Cr Memo)": v["Original Invoice Number(if item is Cr Memo)"],
          "Risk Code (conditional)": v["Risk Code (conditional)"],
          "Discount Code (conditional)": v["Discount Code (conditional)"],
          "Invoice As Of Date (conditional)": v["Invoice As Of Date (conditional)"],
        };
      })
    )
  }

  const totalSum = () => {
    let sumAmount = citList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue["Sum of Invoice Amount (required - negative if credit memo)"]);
    }, 0);
    return sumAmount;
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"CIT Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            citList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Cit-Report.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(1200px + 90%)',
                  y: 450,
                }}
                //   scrollX={"50rem"}
                dataSource={citList}
                columns={columns}
                loading={cit.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue["Sum of Invoice Amount (required - negative if credit memo)"]
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CitReport;
