import PageHeader from "../../components/PageHeader/PageHeader";
import "../Style/style.css";
import { Row, Col, Form, Input, Tabs, Divider, Checkbox, InputNumber } from "antd";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { logColumns, taxColumn } from "../../utils/cols";
import notification from "../../utils/notification";
import { getLog } from "../../redux/actions/log.action";
import { getAdminTax, addAdminTax, updateAdminTax, deleteAdminTax } from "../../redux/actions/adminTax.acions";
import { TabTitle } from "../../utils/GeneralFunction";

const { TabPane } = Tabs;

const TaxSetup = ({ token }) => {
	TabTitle("Tax Setup")
	const { userRights, log, taxsetup } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [taxName, setTaxName] = useState("");
	const [taxPercentage, setTaxPercentage] = useState("");
	const [form] = Form.useForm();
	const taxNameInput = useRef(null);
	const taxPercentageInput = useRef(null);
	const [disable, setDisable] = useState(false);
	const [taxObj, setTaxObj] = useState(null);
	const [check, setCheck] = useState(true);
	const dispatch = useDispatch();
	const id = ReadCookie("menuId");

	useEffect(() => {
		dispatch(getAdminTax(token, id));
	}, [dispatch, token, id]);

	const handleDelete = () => {
		if (taxObj) {

			dispatch(deleteAdminTax(token, taxObj.tax_id)).then((res) => {
				if (res.type === "DELETE_TAX_SETUP_SUCCESS") {
					notification("success", "Tax Setup Deleted", "Tax Setup Deleted Successfully!");
				}
				if (res.type === "DELETE_TAX_SETUP_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				form.setFieldsValue({
					term: "",
					percent: "",
					id: null,
				});
				setTaxName("");
				setTaxPercentage(null);
				setTaxObj(null);
				setFormState("view");
				dispatch(getAdminTax(token, id));
			});
		}
		setFormState("view");
	};

	useEffect(() => {
		if (taxObj) {
			setFormState("view");
			form.setFieldsValue({
				id: taxObj.tax_id,
				term: taxObj.tax_name,
				percent: taxObj.tax_percentage,
				active: taxObj.is_active,
			});
			setTaxName(taxObj.term_name);
			setTaxPercentage(taxObj.tax_percentage);
			setCheck(taxObj.is_active);
		}
	}, [taxObj]);

	useEffect(() => {
		if (taxName === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [taxName]);

	useLayoutEffect(() => {
		dispatch(getRights(token, id)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				if (!res?.payload.can_add) {
					setFormState("table");
				}
			}
		});
	}, []);
	useEffect(() => {
		dispatch(getLog(token, { admin_tax: "admin_tax" }))
	}, [dispatch, token]);

	const formSave = async () => {
		await form
			.validateFields()
			.then((values) => {
				if (formState === "add") {
					dispatch(addAdminTax(token, { tax_name: taxName, tax_percentage: taxPercentage, is_active: check }, id)).then((res) => {
						if (res.type === "ADD_TAX_SETUP_ERROR") {
							notification("error", "Not Created", res?.payload?.data?.data);
						}
						if (res.type === "ADD_TAX_SETUP_SUCCESS") {
							notification("success", "Tax Setup Created", "Tax Setup Created Successfully!");
							form.setFieldsValue({
								term: "",
								percent: "",
							});
							setFormState("view");
							setTaxName("");
							setTaxPercentage(null);
							setTaxObj(null);
						}
						dispatch(getAdminTax(token, id));
					});
				}
				if (formState === "edit") {
					dispatch(updateAdminTax(token, { tax_name: taxName, tax_percentage: taxPercentage, is_active: check }, taxObj.tax_id)).then((res) => {
						if (res.type === "UPDATE_TAX_SETUP_ERROR") {
							notification("error", "Update", "Something went wrong");
						}
						if (res.type === "UPDATE_TAX_SETUP_SUCCESS") {
							notification("success", "Tax Setup Updated", "Tax Setup Updated Successfully");
						}
						dispatch(getAdminTax(token, id));
					});
				}
			})
			.catch((err) => {

			});
	};

	useEffect(() => {
		if (formState === "add") {
			taxNameInput.current.focus();
			form.setFieldsValue({
				term: null,
				percent: null,
			});
			setCheck(true);
			setTaxName("");
			setTaxPercentage(null);
			setTaxObj(null);
		}
	}, [formState]);

	const filterReset = () => {
		setFormState("view");
		setTaxObj(null);
		setCheck(true);
		form.setFieldsValue({
			term: null,
			percent: null,
		});
		setTaxName(null);
		setTaxPercentage(null);
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Tax Setup"} minimize={setMinimize} check={minimize} />
				{!minimize && (
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} />
						</div>
						<div className="styleContent">
							<Row className="tables-main-row-new">
								<Col xs={24} sm={24} md={10} lg={8} xl={6}>
									<div className="styleTable">
										<Tables
											type="taxsetup"
											scrollX={"25rem"}
											formState={formState}
											taxObj={setTaxObj}
											source={taxsetup?.payload?.data}
											col={taxColumn}
											paginate
											scroll={500}
											perPage={25}
											load={taxsetup?.loading}
											rowKey="tax_id"
										/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={14} lg={16} xl={18}>
									<div className="styleForm">
										<div className="testStyle">
											<Form
												form={form}
												name="basic"
											// labelCol={{ span: 8 }}
											>
												<div className="customRow styleFormUpper ">
													{taxObj !== null && !(formState === "add") && (
														<div className="customCols slc">
															<Form.Item label="ID" name="id" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
																<Input size="small" className="styleInput id-field-style" readOnly />
															</Form.Item>
														</div>
													)}

													<div className="customCols slc">
														<Form.Item
															label="Tax Name"
															name="term"
															labelCol={{ span: 10 }}
															wrapperCol={{ span: 14 }}
															rules={[{ required: true, message: "Payment term is required" }]}
														>
															<Input
																size="small"
																// placeholder="Tax Name"
																disabled={!(formState === "add" || formState === "edit")}
																ref={taxNameInput}
																onChange={(e) => setTaxName(e.target.value)}
																className="styleInput"
															/>
														</Form.Item>
													</div>
													<div className="customCols slc">
														<Form.Item
															label="Tax Percentage"
															name="percent"
															labelCol={{ span: 10 }}
															wrapperCol={{ span: 14 }}
															rules={[{ required: true, message: "Percentage is required" }]}
														>
															<Input
																size="small"
																// placeholder="Tax Percentage"
																disabled={!(formState === "add" || formState === "edit")}
																ref={taxPercentageInput}
																onChange={(e) => setTaxPercentage(e.target.value)}
																className="styleInput"
															/>
														</Form.Item>
													</div>
													<div className="customCols slc">
														<Form.Item label="Active" name="active" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
															<Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
														</Form.Item>
													</div>
												</div>
											</Form>
										</div>

										<div className="modalStyle"></div>
										<Divider />
										<div className="styleTab">
											<Tabs type="card" tabBarGutter={2} defaultActiveKey="1" centered>
												<TabPane tab="Log Info" key="logs">
													<div className="tabs">
														<Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
													</div>
												</TabPane>
											</Tabs>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaxSetup;
