import React, { useEffect, useState } from "react";
import { Table, Input, Select, InputNumber, Form, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getProductSale } from "../../redux/actions/productSale.action";

const { Option } = Select;

const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { ProductSale } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [rate, setRate] = useState("");

	// ********** UPDATE COST PRICE FUNCTION *********
	const updateCostPrice = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
				if (type === "down") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	// ********** UPDATE QUANTITY FUNCTION *********
	const updateQuantity = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					item.quantity_committed =
						parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
					return item;
				}
				if (type === "down") {
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					item.quantity_committed =
						parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	// ********** SELECTED ITEMS USEEFFECT *********
	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	// ********** SELECTED ROWS KEYS USEEFFECT *********
	useEffect(() => {
		let arr = [...data];
		arr = [...arr].map((item, i) => {
			if (selectedRowKey.indexOf(item.item_code) === -1) {
				item.isSelected = false;
				return item;
			} else {
				item.isSelected = true;
				return item;
			}
		});
	}, [selectedRowKey]);

	// ********** MODAL VALUES USEEFFECT *********
	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	// ********** HANDLE GENERATE FUNCTIONS *********
	const handleGenerate = () => {
		dispatch(getProductSale(token, companyId, selectedId)).then((res) => {
			if (res.type === "GET_PRODUCTSALE_SUCCESS") {
				let items = res?.payload.map((item) => {
					item.commit_status = "C";
					return item;
				});
				setData(items);
			}
		});
	};

	// ********** HANDLE RATE FUNCTIONS *********
	const handleRate = (rate) => {
		let arr = [...data];
		arr.map((item) => {
			item.rate = rate;
		});
		setData(arr);
		setFilteredItems(arr);
		setRate(rate);
	};

	// ********** HANDLE COMMIT STATUS FUNCTIONS *********
	const handleCommitStatus = (id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				item.commit_status = value;
				return item;
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	// ********** HANDLE OK FUNCTIONS *********
	const handleOk = () => {
		addOrderItems();
	};

	// ********** HANDLE CANCEL FUNCTIONS *********
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	// ********** HANDLE ADD ORDER ITEMS FUNCTIONS *********
	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
		setShowGrid(true);
	};

	// ********** HANDLE ROW SELECTION ROWS FUNCTIONS *********
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].isSelected = true;
				selectedRows[i].quantity = selectedRows[i].hasOwnProperty("quantity") ? selectedRows[i].quantity : 1;
				selectedRows[i].tax_amount = selectedRows[i].hasOwnProperty("tax_amount") ? selectedRows[i].tax_amount : 0;
				selectedRows[i].discount = selectedRows[i].hasOwnProperty("discount") ? selectedRows[i].discount : 0;
				selectedRows[i].discount_percent = selectedRows[i].hasOwnProperty("discount_percent") ? selectedRows[i].discount_percent : 0;
				selectedRows[i].tax_percent = selectedRows[i].hasOwnProperty("taxt_percent") ? selectedRows[i].tax_percent : 0;
				selectedRows[i].amount = parseFloat(selectedRows[i].quantity * selectedRows[i].rate).toFixed(2);
				selectedRows[i].net_amount = parseFloat(selectedRows[i].quantity * selectedRows[i].rate - selectedRows[i].discount).toFixed(2);
				selectedRows[i].quantity_backorder = 0;
				selectedRows[i].quantity_committed = 1;
			}

			setSelectedItems(selectedRows);
			setFilteredItems(selectedRows);
			setSelectedRowKey(selectedRowKeys);
		},
	};

	// ********** ROWS COLUMNS *********
	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style Name",
			dataIndex: "style_name",
		},
		{
			title: "Wash Name",
			dataIndex: "wash_name",
		},
		{
			title: "Inseam",
			dataIndex: "inseam",
		},
		{
			title: "Size",
			dataIndex: "size",
		},
		{
			title: "Cost Price",
			dataIndex: "rate",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						onStep={(value, info) => updateCostPrice(info.type, record.item_code, value)}
						value={rate ? rate : record.rate}
						defaultValue={rate ? rate : record.rate}
						onChange={(value) => updateCostPrice("up", record.item_code, value)}
					/>
				);
			},
		},

		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={1}
					/>
				);
			},
		},
		{
			title: "Amount",
			editable: false,
			render: (text, record) => {
				return <>{parseFloat(record.quantity ? record.rate * record.quantity : record.rate).toFixed(2)}</>;
			},
		},
		{
			title: "Available",
			editable: false,
			render: (text, record) => {
				return <>{record.quantity_available}</>;
			},
		},
		{
			title: "Commit Status",
			width: "10%",
			dataIndex: "commit_status",
			editable: true,
			render: (text, record) => {
				return (
					<Select
						disabled={!record?.isSelected}
						size="small"
						style={{ width: 150 }}
						autoComplete={"nope"}
						defaultValue={"C"}
						onChange={(value) => handleCommitStatus(record.item_code, value)}
					>
						<Option key={1} value={"C"}>
							Available Quantity
						</Option>
						<Option key={2} value={"D"}>
							Do Not Commit
						</Option>
					</Select>
				);
			},
		},
		{
			title: "Commited",
			editable: false,
			render: (text, record) => {
				let backOrder = record.quantity - record.quantity_available;
				backOrder = backOrder > 0 ? backOrder : 0;
				return <>{record.quantity - backOrder}</>;
			},
		},
		{
			title: "Back Order",
			editable: false,
			render: (text, record) => {
				let backOrder = record.quantity - record.quantity_available;
				return <>{backOrder > 0 ? backOrder : 0}</>;
			},
		},
	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: selectedItems.length > 0 ? "" : "none",
				},
			}}
			okText="Add Order"
			destroyOnClose={true}
			width={"100%"}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
			cancelText="Close"
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>

					<Input
						size="small"
						className="w-100"
						value={rate}
						name="searchItem"
						onChange={(e) => {
							handleRate(e.target.value);
						}}
					/>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>
				<Table
					size="small"
					loading={ProductSale.loading}
					scroll={{ x: "50rem", y: 360 }}
					rowSelection={{ ...rowSelection }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default EditableTable;