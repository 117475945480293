import React, { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import {Button, Form, Input,  DatePicker, Collapse, Row, Col } from "antd";
import Tables from "../../components/Table/Tables";
import { itemTransferColumns } from "../../utils/cols";
import { getRights } from "../../redux/actions/userRights.action";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import {  dateChecker } from "../../components/CheckDate/checkDate";
import { CSVLink } from "react-csv";
export default function ItemTransferList({ token }) {
	const [form] = Form.useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const menuId = ReadCookie("menuId");
	const { itemTransferList } = useSelector((state) => {
		return state
	});
	const { Panel } = Collapse;

	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		transferNo: null,
		fromLocation: null,
		toLocation: null,
		postingPeriod: null,
		asn: null,
		memo: null
	};
	function downloadExcelFileData(data) {
		if (data) {
		  return data.map((v) => {
			return {
			  "Transfer Header No": v.transfer_header_no,
			  "Transfer Date": moment(v.transfer_date).format("MM-DD-YYYY"),
			  "From Location": v.from_location_name,
			  "To Location": v.to_location_name,
			  "Posting Period": v.period_name,
			  "Asn No": v.asn_no,
			  "Memo": v.memo,
			};
		  });
		}
	  }
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => { });
	}, []);

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "itemTransferList", token, values, dispatch);

		})
	}


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Item Transfer List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "99%", margin: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<Button
							onClick={() => {
								history.push("/item-transfer/add?mode=add");
							}}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<PlusOutlined />}
						/>}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="transferNo"
											label="Transfer Number"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input

												allowClear
												// onChange={(e) => setTransferNo(e.target.value)}
												// placeholder="Transfer Number"
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										// value={form.getFieldValue().from_date} 
										// initialValue={from_date}
										>
											<DatePicker
												size="small"
												id="fromDate"
												format="MM-DD-YYYY"
												// placeholder={from_date}
												// value={form.getFieldValue().from_date}
												allowClear
												className="dateInput customInputs"
											// onChange={(e) => check(moment(e))}
											// onChange={(e) => {
											// 	setFromDate(moment(e));
											// 	form.setFieldsValue({
											// 		from_date: moment(e)
											// 	});
											// 	setDateOP("fromdate")
											// }}

											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										//  value={form.getFieldValue().to_date} 
										// initialValue={to_date}
										>
											<DatePicker
												size="small"
												id="toDate"
												format="MM-DD-YYYY"
												// value={form.getFieldValue().to_date}
												// placeholder={to_date}
												allowClear
												className="dateInput customInputs"
											// onChange={(e) => {
											// 	setToDate(moment(e));
											// 	form.setFieldsValue({
											// 		to_date: moment(e)
											// 	});
											// 	setDateOP("todate")
											// }}
											// onChange={(e) => setToDate(moment(e))}

											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											label="From Location"
											name="fromLocation"
										// value={fromLocation}
										>
											<Input

												allowClear
												// onChange={(e) => setFromLocation(e.target.value)}
												// placeholder="From Location"
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											label="To Location"
											name="toLocation"
										// value={toLocation}
										>

											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="postingPeriod"
											label="Posting Period"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="asn"
											label="Asn"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="memo"
											label="Memo"
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit">
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{/* {itemTransferList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={itemTransferColumns}
									source={itemTransferList?.payload?.data}
									load={itemTransferList.loading}
									paginate
								/>
							</div>
						</div>
					)} */}


{itemTransferList?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {itemTransferList?.payload?.data.length > 0 && (
                  <CSVLink
                    filename={"Item_Transfer.csv"}
                    data={
                      downloadExcelFileData(itemTransferList?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={itemTransferColumns}
                  source={itemTransferList?.payload?.data}
                  load={itemTransferList?.loading}
                  paginate
                />
              </div>
            </div>
          )}
				</div>
			</div>
		</div>
	);
}
