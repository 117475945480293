import {
  GET_BILLING_ERROR,
  GET_BILLING_REQUEST,
  GET_BILLING_SUCCESS,
  ADD_BILLING_REQUEST,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_ERROR,
  DELETE_BILLING_REQUEST,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_ERROR,
  UPDATE_BILLING_REQUEST,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_ERROR,
  GET_CUSTOMER_SINGLE_BILLING_REQUEST,
  GET_CUSTOMER_SINGLE_BILLING_SUCCESS,
  GET_CUSTOMER_SINGLE_BILLING_ERROR,
  GET_RELATED_TRANSAACTION_REQUEST,
  GET_RELATED_TRANSACTION_SUCCESS,
  GET_RELATED_TRANSACTION_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getBilling = (token, id) => (dispatch) => {
  dispatch({ type: GET_BILLING_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/customerBill/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_BILLING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (error.response.data.data === "No Record Found !") {
        return dispatch({
          type: GET_BILLING_ERROR,
          payload: [],
        });
      }
      return dispatch({
        type: GET_BILLING_ERROR,
        payload: error.response,
      });
    });
};

export const addBiller = (token, data, typeText) => (dispatch) => {
  dispatch({ type: ADD_BILLING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/customerBill`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: typeText === "edit" ? data : { customers: data }
  })
    .then((response) => {
      return dispatch({ type: ADD_BILLING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_BILLING_ERROR,
        payload: error.response,
      });
    });
};

export const deleteBiller = (token, id) => dispatch => {
  dispatch({ type: DELETE_BILLING_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/customerBill/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
  })
    .then(response => {
      return dispatch({ type: DELETE_BILLING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_BILLING_ERROR,
        payload: error.response,
      });
    })
}

export const updateBiller = (token, data, id,) => dispatch => {
  dispatch({ type: UPDATE_BILLING_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/customerBill/${id}`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: {
      is_active: data.isActive,
      addressee: data.addressee_bill_to,
      address_1: data.address_1_bill_to,
      address_2: data.address_2_bill_to,
      address_3: data.address_3,
      city_id: data.city_id_bill_to,
      country_id: data.country_id_bill_to,
      state_id: data.state_id_bill_to,
      email: data.email_bill_to,
      fax: data.fax_bill_to,
      phone: data.phone_bill_to,
      zip_code: data.zip_code_bill_to,
      first_name: data.first_name,
      last_name: data.last_name,
      is_default: data.default_bill_to,
      default_ship_to: data.default_ship_to
    },
  })
    .then(response => {
      return dispatch({ type: UPDATE_BILLING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_BILLING_ERROR,
        payload: error.response,
      });
    })
}

export const getSingleBilling = (token, orderId, customerId, id) => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_SINGLE_BILLING_REQUEST });
  return axios({
    method: "GET",
    // url: `${BASEURL}getOne/custAddressDetail/${id}`,
    url: `${BASEURL}getOne/customerAddressDetail/${customerId}&${orderId || 0}&${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_CUSTOMER_SINGLE_BILLING_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CUSTOMER_SINGLE_BILLING_ERROR,
        payload: error.response,
      });
    });
};

export const getRelatedTransactions = (token, id) => dispatch => {
  dispatch({ type: GET_RELATED_TRANSAACTION_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/customerTransaction`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data: { id }
  })
    .then(response => {
      return dispatch({ type: GET_RELATED_TRANSACTION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_RELATED_TRANSACTION_ERROR,
        payload: error.response,
      });
    })
}