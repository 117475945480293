import {
  GET_CIT_REQUEST,
  GET_CIT_SUCCESS,
  GET_CIT_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getcit = (token, values) => (dispatch) => {
  dispatch({ type: GET_CIT_REQUEST });
  const newData = {
    date_from: moment(values.from_date).format("MM-DD-YYYY"),
    date_to: moment(values.to_date).format("MM-DD-YYYY"),
    company_id: 3,
};
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/CitReport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_CIT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CIT_ERROR,
        payload: [],
      });
    });
};
