import {
	ADD_INVENTORYADJUSTMENT_HEADER_ERROR, ADD_INVENTORYADJUSTMENT_HEADER_REQUEST, ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	DELETE_INVENTORYADJUSTMENT_HEADER_ERROR, DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST, DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR, GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST, GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR, GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST, GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR, GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST, GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR, GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST, GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR, UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST, UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS, GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR, GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const addInvAdjustmentHeader = (token, formData, values) => (dispatch) => {



	// 
	dispatch({ type: ADD_INVENTORYADJUSTMENT_HEADER_REQUEST });
	let newData = {
		adjustment_header_id: formData.adjustment_header_id,
		adjust_date: moment(formData.transferDate).format("MM-DD-YYYY"),
		gl_period_id: formData.postingPeriod,
		memo: formData.memo,
		company_id: formData.subsidiary,
		location_id: formData.adjustmentLocation,
		// department: formData.department,
		// estimatedTotalValue: formData.estimatedTotalValue,
		account_id: formData.account_id
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/InvStockAdjustmentHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_INVENTORYADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateInvAdjustmentHeader = (token, formData, invTransferId) => (dispatch) => {

	let newData = {
		// refferenceNumber: formData.adjustment_header_no,
		gl_period_id: formData.postingPeriod,
		adjust_date: formData.adjustDate,
		location_id: formData.adjustmentLocation,
		memo: formData.memo,
		company_id: formData.subsidiary,
		adjustmentLocation: formData.location_name,
		account_id: formData.account_id,
		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
	};
	dispatch({ type: UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/InvStockAdjustmentHeader/${invTransferId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllInvAdjustmentHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/InvStockAdjustmentHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			adjustment_header_no: data.invAdjustmentNo === "" ? null : data.invAdjustmentNo,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			// estimatedTotalValue: estimatedTotalValue ? estimatedTotalValue,
			account_name: data.adjustmentAccount === "" ? null : data.adjustmentAccount,
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			location_name: data.adjustmentLocation === "" ? null : data.adjustmentLocation,
			memo: data.memo === "" ? null : data.memo,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneInvAdjustmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/InvStockAdjustmentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const getOneItemInvAdjustmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/InvStockAdjustmentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const deleteInvAdjustmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/InvStockAdjustmentHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_INVENTORYADJUSTMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getInvAdjustmentFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS,
			payload: {
				location: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/InvStockAdjustmentFormData`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {

			return dispatch({
				type: GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};

export const getAllAdjustmentAccount = (token) => (dispatch) => {
	dispatch({ type: GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/getAccountForStockAdjustment`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},

	})
		.then((response) => {

			return dispatch({
				type: GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR,
				payload: error.response,
			});
		});
};