import {
	ADD_BILL_HEADER_ERROR,
	ADD_BILL_HEADER_REQUEST,
	ADD_BILL_HEADER_SUCCESS,
	DELETE_BILL_HEADER_ERROR,
	DELETE_BILL_HEADER_REQUEST,
	DELETE_BILL_HEADER_SUCCESS,
	GET_ALL_BILL_ERROR,
	GET_ALL_BILL_REQUEST,
	GET_ALL_BILL_SUCCESS,
	GET_BILL_FORM_DATA_ERROR,
	GET_BILL_FORM_DATA_REQUEST,
	GET_BILL_FORM_DATA_SUCCESS,
	GET_ONE_BILL_HEADER_DETAIL_ERROR,
	GET_ONE_BILL_HEADER_DETAIL_REQUEST,
	GET_ONE_BILL_HEADER_DETAIL_SUCCESS,
	GET_ONE_BILL_HEADER_ERROR,
	GET_ONE_BILL_HEADER_REQUEST,
	GET_ONE_BILL_HEADER_SUCCESS,
	UPDATE_BILL_HEADER_ERROR,
	UPDATE_BILL_HEADER_REQUEST,
	UPDATE_BILL_HEADER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const addBillHeader = (token, formData, companyId) => (dispatch) => {
	dispatch({ type: ADD_BILL_HEADER_REQUEST });
	let newData = {
		is_approved: formData.approveStatus,
		account_id: formData.account,
		amount: formData.amount,
		bill_date: moment(formData.billDate).format("MM-DD-YYYY"),
		company_id: companyId,
		gl_period_id: formData.postingPeriod,
		location_id: formData.adjustmentLocation,
		remarks: formData.memo,
		reference_no: formData.referenceNo,
		// f_transaction: formData.fTransactionNo,
		// f_amount: formData.fAmount,
		f_amount: formData.fAmount === undefined ? 0 : formData.fAmount,
		d_amount: formData.dAmount === undefined ? 0 : formData.dAmount,
		f_transaction: formData.fTransactionNo === "" ? null : formData.fTransactionNo,
		d_transaction: formData.dTransactionNo === "" ? null : formData.dTransactionNo,
		// d_amount: formData.dAmount,
		vendor_id: formData.vendor,
		ship_via: formData.shipVia,
		currency: formData.currency,
		delivery_date: formData.dileveryDate
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/payPurchaseBillHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillHeader = (token, formData, billId, companyId) => (dispatch) => {
	console.log(formData,"hklay")
	let newData = {

		is_approved: formData.approveStatus,
		account_id: formData.account,
		amount: formData.amount,
		bill_date: moment(formData.billDate).format("MM-DD-YYYY"),
		company_id: companyId,
		gl_period_id: formData.postingPeriod,
		location_id: formData.adjustmentLocation,
		remarks: formData.memo,
		reference_no: formData.referenceNo,
		vendor_id: formData.vendor,
		delivery_date: formData.dileveryDate.format("MM-DD-YYYY"),
		f_amount: formData.fAmount === undefined ? 0 : formData.fAmount,
		d_amount: formData.dAmount === undefined ? 0 : formData.dAmount,
		f_transaction: formData.fTransactionNo === "" ? null : formData.fTransactionNo,
		d_transaction: formData.dTransactionNo === "" ? null : formData.dTransactionNo,
		ship_via: formData.shipVia === "" ? null : formData.shipVia,

	};
	dispatch({ type: UPDATE_BILL_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payPurchaseBillHeader/${billId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_BILL_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllBillHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_BILL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/payPurchaseBillHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			bill_header_no: data.billHeaderno === "" ? null : data.billHeaderno,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			account_name: data.account === "" ? null : data.account,
			from_amount: data.fromAmount === "" ? null : data.fromAmount,
			to_amount: data.toAmount === "" ? null : data.toAmount,
			memo: data.memo === "" ? null : data.memo,
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			gender_name: data.gender_name === "" ? null : data.gender_name,
			vendor_name: data.vendor === "" ? null : data.vendor,
			location_name: data.location_name === "" ? null : data.location_name,
			is_approved: data.approveStatus === "" ? null : data.approveStatus,
			reference_no: data.referenceNo === "" ? null : data.referenceNo,
			exchange_rate: data.currency === "" ? null : data.currency,
			ship_via_name: data.ship_via_name === "" ? null : data.ship_via_name,
			company_name: null,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_BILL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_BILL_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_HEADER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_BILL_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payPurchaseBillHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getBillFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_BILL_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_BILL_FORM_DATA_SUCCESS,
			payload: {
				location: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/payPurchaseBillHeaderFormData`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BILL_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BILL_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};