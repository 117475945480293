import {
    GET_INVOICE_EMAIL_REQUEST,GET_INVOICE_EMAIL_SUCCESS,GET_INVOICE_EMAIL_ERROR,
    CREATE_INVOICE_EMAIL_REQUEST, CREATE_INVOICE_EMAIL_SUCCESS,CREATE_INVOICE_EMAIL_ERROR,
    GET_INVOICE_PENDING_REQUEST,GET_INVOICE_PENDING_SUCCESS,GET_INVOICE_PENDING_ERROR,
    CREATE_INVOICE_PENDING_REQUEST,CREATE_INVOICE_PENDING_SUCCESS,CREATE_INVOICE_PENDING_ERROR
} from "./types";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");


export const getInvoiceEmail = (token, values) => (dispatch) => {
    dispatch({ type: GET_INVOICE_EMAIL_REQUEST });

    const data = {
        from_date: moment(values.fromDate).format("MM-DD-YYYY"),
        to_date: moment(values.toDate).format("MM-DD-YYYY"),
        factor_id: values.paymentClass === undefined ? null : values.paymentClass,
        customer_id: values.customer === undefined ? null : values.customer,
        order_status_id: values.status === undefined ? null : values.status,
    };


    return axios({
        method: "POST",
        url: `${BASEURL}getAll/selectInvoices`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: data
    }).then((response) => {
        return dispatch({
            type: GET_INVOICE_EMAIL_SUCCESS,
            payload: response?.data,
        });
    }).catch((error) => {
        return dispatch({
            type: GET_INVOICE_EMAIL_ERROR,
            payload: error.response,
        });
    });
};


export const createInvoiceEmail = (token, data) => (dispatch) => {
    dispatch({ type: CREATE_INVOICE_EMAIL_REQUEST });

    // const data = {
    //     from_date: moment(values.fromDate).format("MM-DD-YYYY"),
    //     to_date: moment(values.toDate).format("MM-DD-YYYY"),
    //     factor_id: values.paymentClass === undefined ? null : values.paymentClass,
    //     customer_id: values.customer === undefined ? null : values.customer,
    //     order_status_id: values.status === undefined ? null : values.status,
    // };


    return axios({
        method: "POST",
        url: `${BASEURL}communication/InvoiceEmail`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: [data]
    }).then((response) => {
        return dispatch({
            type: CREATE_INVOICE_EMAIL_SUCCESS,
            payload: response?.data,
        });
    }).catch((error) => {
        return dispatch({
            type: CREATE_INVOICE_EMAIL_ERROR,
            payload: error.response,
        });
    });
};


export const getInvoicePending = (token, values) => (dispatch) => {
    dispatch({ type: GET_INVOICE_PENDING_REQUEST });

    const data = {
        from_date: moment(values.fromDate).format("MM-DD-YYYY"),
        to_date: moment(values.toDate).format("MM-DD-YYYY"),
        factor_id: values.paymentClass === undefined ? null : values.paymentClass,
        payment_term_id: values.terms === undefined ? null : values.terms,
    };


    return axios({
        method: "POST",
        url: `${BASEURL}getAll/InvoicePending`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: data
    }).then((response) => {
        return dispatch({
            type: GET_INVOICE_PENDING_SUCCESS,
            payload: response?.data,
        });
    }).catch((error) => {
        return dispatch({
            type: GET_INVOICE_PENDING_ERROR,
            payload: error.response,
        });
    });
};


export const createInvoicePending = (token, data) => (dispatch) => {
    dispatch({ type: CREATE_INVOICE_PENDING_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/sendPendingInvoiceEmail`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: [data]
    }).then((response) => {
        return dispatch({
            type: CREATE_INVOICE_PENDING_SUCCESS,
            payload: response?.data,
        });
    }).catch((error) => {
        return dispatch({
            type: CREATE_INVOICE_PENDING_ERROR,
            payload: error.response,
        });
    });
};