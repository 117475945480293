import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie, } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { logColumns, adminCompanyColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import notification from '../../utils/notification'
import { getAdminCompany, addAdminCompany, deleteAdminCompany, updateAdminCompany } from '../../redux/actions/adminCompany.action';
import { loadAdminUser } from '../../redux/actions/adminUser.actions';
import { getAllCompany } from '../../redux/actions/companyAccess';
import { TabTitle } from '../../utils/GeneralFunction';






const { TabPane } = Tabs;
const { Option } = Select

const Company = ({ token }) => {
    TabTitle("User Company Access")
    const { userRights, log, adminCompany, users, companyAll } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [form] = Form.useForm();
    const userNameInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [company_id, setCompanyId] = useState(null)
    const [user_id, setUserId] = useState(null)
    const [adminCompanyObj, setAdminCompanyObj] = useState(null);
    const [is_active, setActive] = useState(true);
    const [is_default, setDefault] = useState(true)
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');

    useEffect(() => {
        dispatch(getAdminCompany(token))
    }, [dispatch, token])
    useEffect(() => {
        dispatch(getAllCompany(token))
    }, [dispatch, token,])

    useEffect(() => {
        dispatch(loadAdminUser(token))
    }, [token])

    const handleDelete = () => {
        if (adminCompanyObj) {
            dispatch(deleteAdminCompany(token, adminCompanyObj.user_company_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_COMPANY_SUCCESS") {
                    notification("success", "User Company deleted", "User Company deleted successfully!");
                }
                if (res.type === "DELETE_ADMIN_COMPANY_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    uid: null,
                    cid: null
                })
                setUserId(null)
                setAdminCompanyObj(null)
                setCompanyId(null)
                setFormState('view')
                dispatch(getAdminCompany(token, id))

            })
        }
        setFormState("view")
    }


    useEffect(() => {

        if (adminCompanyObj) {
            setFormState('view')
            form.setFieldsValue({
                id: adminCompanyObj.user_company_id,
                cid: adminCompanyObj.company_id,
                uid: adminCompanyObj.user_id,
                active: adminCompanyObj.is_active,
                default: adminCompanyObj.is_default
            });
            setUserId(adminCompanyObj.user_id)
            setCompanyId(adminCompanyObj.cid)
            setActive(adminCompanyObj.is_active)


        }

    }, [adminCompanyObj]);

    useEffect(() => {
        if (user_id === null) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [user_id])




    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])
    useEffect(() => {

        dispatch(getLog(token, { admin_company: "admin_company" }))
    }, [dispatch, token])




    const formSave = async () => {

        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addAdminCompany(token, { user_id, company_id, is_default, is_active }, id)).then((res) => {

                    if (res.type === "ADD_ADMIN_COMPANY_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                        return
                    }
                    if (res.type === "ADD_ADMIN_COMPANY_SUCCESS") {
                        notification("success", "User Company created", "User Company created successfully!");
                        form.setFieldsValue({
                            uid: null,
                            cid: null
                        })
                        setFormState("view")
                        setCompanyId(null)
                        setUserId(null)
                        setAdminCompanyObj(null)
                    }



                })
                dispatch(getAdminCompany(token, id))

            }
            if (formState === "edit") {
                dispatch(updateAdminCompany(token, { company_id, user_id, is_active, is_default }, adminCompanyObj.user_company_id)).then((res) => {
                    if (res.type === "UPDATE_ADMIN_COMPANY_ERROR") {
                        notification("error", "Update", "Something went wrong")

                    }
                    if (res.type === "UPDATE_ADMIN_COMPANY_SUCCESS") {
                        notification("success", "Admin Company updated", "Record updated successfully")
                    }
                    dispatch(getAdminCompany(token, id))
                })
            }

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (formState === "add") {
            userNameInput.current.focus();
            form.setFieldsValue({
                cid: null,
                uid: null
            })
            setActive(true)
            setDefault(true)
            setCompanyId(null)
            setUserId(null)
            setAdminCompanyObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
        setAdminCompanyObj(null)
        setActive(true)
        setDefault(true)
        form.setFieldsValue({
            cid: null,
            uid: null,
        })
        setCompanyId(null)
        setUserId(null)

    }

    return (
        <div className='styleContainer'>
            <div className='styleBody h-100' >
                <PageHeader title={"Company"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="adminCompany" formState={formState} adminCompanyObj={setAdminCompanyObj} scrollX={"20rem"} source={adminCompany?.payload?.data} col={adminCompanyColumns} paginate scroll={500} perPage={25} load={adminCompany?.loading} rowKey="user_company_id" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"


                                            >
                                                <div className='customRow styleFormUpper ' >
                                                    {adminCompanyObj !== null && !(formState === "add") &&
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="ID"
                                                                name="id"
                                                                initialValue={form.getFieldValue().id}
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 14 }}

                                                            >
                                                                <Input bordered={false} className='styleInput  id-field-style' readOnly />

                                                            </Form.Item>
                                                        </div>


                                                    }
                                                    <div className="customCols slc">
                                                        <div className='country-spaceremove-mob relative  mlpx-50'>
                                                            <Form.Item

                                                                name="uid"
                                                                label="User"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "User is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size="small"
                                                                    showSearch
                                                                    ref={userNameInput}
                                                                    loading={users.loading}
                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"
                                                                    className="w-100"
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onSelect={(e) => setUserId(e)}
                                                                    allowClear
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                  }
                                                                >
                                                                    {users?.payload?.data?.map((user) => {
                                                                        return (
                                                                            <Option value={user.user_id}>
                                                                                {user.login_id}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="customCols slc">
                                                        <div className='country-spaceremove-mob relative mlpx-50'>
                                                            <Form.Item
                                                                name="cid"
                                                                label="Company "
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Company is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size="small"
                                                                    showSearch

                                                                    loading={companyAll.loading}
                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"
                                                                    className="w-100"
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onSelect={(e) => setCompanyId(e)}
                                                                    allowClear
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                  }
                                                                >
                                                                    {companyAll?.payload?.data?.map((comp) => {
                                                                        return (
                                                                            <Option value={comp.company_id}>
                                                                                {comp.company_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={is_active} onChange={(e) => setActive(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Default"
                                                            name="default"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={is_default} onChange={(e) => setDefault(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>

                                        <div className='modalStyle' >


                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log?.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>

                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>





    )
}


export default Company