// export const BASEURL = "https://erpbe.fasreports.com/v1/"
// export const BASEURL1 = "https://erpbe.fasreports.com/"

// export const BASEURL = "http://devubuntu.fascom.com:8000/v1/"
// export const BASEURL1 = "http://devubuntu.fascom.com:8000/"

export const BASEURL = "https://backend.warpweftworld.com/v1/"
export const BASEURL1 = "https://backend.warpweftworld.com/"

// export const BASEURL = "http://localhost:3430/v1/"
// export const BASEURL1 = "http://localhost:3430/"

