import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin, DatePicker, Input
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { getCommissionReport } from "../../redux/actions/commissionReport.action";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";
const { Option } = Select;

const CommissionReport = () => {
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    dscoInvoice,
    commissionReport
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      // width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Payment #",
      dataIndex: "payment_header_id",
      key: "payment_header_id",
      // width: 70,
    },
    {
      title: "SalePerson Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      // width: 70,
    },
    {
      title: "Voucher No",
      dataIndex: "voucher_no",
      key: "voucher_no",
      // width: 100,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_no",
      key: "invoice_no",
      // width: 70,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "Order #",
      dataIndex: "order_no",
      key: "order_no",
      // width: 70,
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (text, record) => {
        return moment(text).format("MM-DD-YYYY")
      }
    },
    {
      title: "PO",
      dataIndex: "po_no",
      key: "po_no",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Shipping Cost",
      dataIndex: "shipping_cost",
      key: "shipping_cost",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className="pop-search-gen pop-170"
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record?.commission
          // .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      ,
      sorter: (a, b) => {
        return a.commission - b.commission;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type_name",
      key: "payment_type_name",
      // width: 70,
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "UnApplied",
      dataIndex: "un_applied",
      key: "un_applied",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getCommissionReport(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setDscoInvoiceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Payment #": v.payment_header_id,
          "SalePerson Name": v.salesperson_name,
          "Voucher No": v.voucher_no,
          "Payment Date": moment(v.payment_date).format("MM-DD-YYYY"),
          "Invoice #": v.invoice_no,
          "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
          "Order #": v.order_no,
          "Order Date": moment(v.order_date).format("MM-DD-YYYY"),
          "PO": v.po_no,
          "Customer": v.customer_name,
          "Region": v.region_name,
          "Amount Paid": numeral(v.amount_paid).format("$0,00.00"),
          "Shipping Cost": numeral(v.shipping_cost).format("$0,00.00"),
          "Net Amount": numeral(v.net_amount).format("$0,00.00"),
          "Commission": numeral(v.commission).format("$0,00.00"),
          "Payment Type": v.payment_type_name,
          "Gender": v.gender_name,
          "UnApplied": numeral(v.un_applied).format("$0,00.00"),
        };
      })
    )
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'Payment #', key: 'payment_header_id', width: 13 },
      { header: 'SalePerson Name', key: 'salesperson_name', width: 30 },
      { header: 'Voucher No', key: 'voucher_no', width: 15 },
      { header: 'Payment Date', key: 'payment_date', width: 15 },
      { header: 'Invoice #', key: 'invoice_no', width: 15 },
      { header: 'Invoice Date', key: 'invoice_date', width: 15 },
      { header: 'Order #', key: 'order_no', width: 15 },
      { header: 'Order Date', key: 'order_date', width: 15 },
      { header: 'PO', key: 'po_no', width: 20 },
      { header: 'Customer', key: 'customer_name', width: 35 },
      { header: 'Region', key: 'region_name', width: 25 },
      { header: 'Amount Paid', key: 'amount_paid', width: 15 },
      { header: 'Shipping Cost', key: 'shipping_cost', width: 15 },
      { header: 'Net Amount', key: 'net_amount', width: 15 },
      { header: 'Commission', key: 'commission', width: 15 },
      { header: 'Payment Type', key: 'payment_type_name', width: 20 },
      { header: 'Gender', key: 'gender_name', width: 15 },
      { header: 'UnApplied', key: 'un_applied', width: 15 },
    ];

    let totalAmount = 0;
    let totalCommission = 0;
    // Assuming 'data' is an array of objects with properties 'name', 'email', and 'age'
    commissionReport?.payload?.data.forEach((item) => {
      worksheet.addRow({ payment_header_id: item.payment_header_id, salesperson_name: item.salesperson_name, voucher_no: item.voucher_no, payment_date: item.payment_date, invoice_no: item.invoice_no, invoice_date: item.invoice_date, order_no: item.order_no, order_date: item.order_date, po_no: item.po_no, customer_name: item.customer_name, region_name: item.region_name, amount_paid: numeral(item.amount_paid).format("$0,00.00"), shipping_cost: numeral(item.shipping_cost).format("$0,00.00"), net_amount: numeral(item.net_amount).format("$0,00.00"), commission: numeral(item.commission).format("$0,00.00"), payment_type_name: item.payment_type_name, gender_name: item.gender_name, un_applied: numeral(item.un_applied).format("$0,00.00") });
      totalAmount += Number(item.amount_paid);
      totalCommission += Number(item.commission);
    });
    worksheet.addRow({ amount_paid: numeral(totalAmount).format("$0,00.00"), commission: numeral(totalCommission).format("$0,00.00")});

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // You can modify the filename as needed
      const filename = 'Commission-Report.xlsx';

      // Create a download link and trigger a click event to download the file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();

      console.log('Excel file created!');
    } catch (error) {
      console.error(error);
    }
  };

  function generatePDF() {
    var doc = new jsPDF();

    // Define columns and rows
    var columns = ["Payment #", "SalesPerson", "Voucher No", "Payment Date", "Invoice #", "Invoice Date", "Order #", "Order Date", "PO", "Customer", "Region", "Amount Paid", "Shipping Cost", "Net Amount", "Commission", "Payment Type", "Gender", "UnApplied",];
    var rows = [];

    // Populate rows with data
    commissionReport?.payload?.data.forEach(item => {
      var row = [item.payment_header_id, item.salesperson_name, item.voucher_no, moment(item.payment_date).format("MM-DD-YYYY"), item.invoice_no, moment(item.invoice_date).format("MM-DD-YYYY"), item.order_no, moment(item.order_date).format("MM-DD-YYYY"), item.po_no, item.customer_name, item.region_name, "$" + numeral(item.amount_paid).format("0,00.00"), "$" + numeral(item.shipping_cost).format("0,00.00"), "$" + numeral(item.net_amount).format("0,00.00"), "$" + numeral(item.commission).format("0,00.00"), item.payment_type_name, item.gender_name, "$" + numeral(item.un_applied).format("0,00.00")];
      rows.push(row);
    });

    const totalAmount = commissionReport?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.amount_paid), 0)
    const totalCommission = commissionReport?.payload?.data.reduce((acc, item, index) => acc + ( parseFloat(item.commission) || 0), 0)

    // Add table to the PDF
    doc.autoTable({
      head: [columns],
      body: rows,
      styles: { fontSize: 4 },
    })

    const startY = doc.lastAutoTable.finalY || 10; // Default to 10 if there is no table
    const footerRow = ["", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "", "", "","","","", "Total:", "$" + numeral(totalAmount).format("0,00.00"), " ", " ", " ", "$" + numeral(totalCommission).format("0,00.00")," ", " ", " ", " ", " "];
    doc.autoTable({
      body: [footerRow],
      startY: startY + 2,
      styles: { fontSize: 4 },
    });

    // Save the PDF
    doc.save("Commission-Report.pdf");
  }

  const totalQuantity = () => {
    let sumQty = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.total_qty);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.cost_price);
    }, 0);
    return sumAmount;
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Commission Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            dscoInvoiceList.length > 0 ? (
              <Table
                title={() =>
                  <>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <div className="print_icon printing_class" >
                        <CSVLink
                          filename={"Commission-Report.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgCSV"
                            alt="Export to CSV"
                            id="ImgCSV"
                            title="Export to CSV"
                            src={csv}
                            // onClick={onDownload}
                            // className="cursor-not-allowed"
                            style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                          />
                        </CSVLink>
                      </div>
                      <div className="print_icon printing_class" >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to Excel"
                          id="ImgExcel"
                          title="Export to Excel"
                          src={xlsx}
                          onClick={exportToExcel}
                          // className="cursor-not-allowed"
                          style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                        />
                      </div>
                      <div className="print_icon printing_class" >
                        <input
                          type="image"
                          name="ImgPDF"
                          alt="Export to PDF"
                          id="ImgPDF"
                          title="Export to PDF"
                          src={pdf}
                          onClick={generatePDF}
                          // className="cursor-not-allowed"
                          style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                        />
                        {/* <button onClick={() => generatePDF()}>Generate PDF</button> */}
                      </div>
                    </div>
                  </>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(550px + 90%)',
                  y: 450,
                }}
                //   scrollX={"50rem"}
                dataSource={dscoInvoiceList}
                columns={columns}
                loading={commissionReport.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  let amountPaid = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.amount_paid
                    ));
                  },
                    0);
                  let commission = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.commission || 0
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {amountPaid.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {commission.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CommissionReport;
