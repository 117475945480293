import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";
export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, saleOrderID, userRights, formData, transactionLock }) {
	const history = useHistory();
	const confirm = () => {
		onDelete();
	};

	return (
		<>
			<div className="crudContainer">
				<div>
					{formType === "" ? null : (
						<div className="buttons-div">
							<Tag color={"green"} key={1}>
								{formType.toUpperCase()}
							</Tag>
							{
								transactionLock && <Tag color={"red"} key={2}>LOCKED</Tag>
							}
							{formType !== "view" && <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />}
						</div>
					)}
				</div>
				<div>
					<Tooltip placement="bottom" title="Accept Bill Payment" color="#fbfbfb">
						<Button
							type="primary"
							onClick={() => history.push(`/bill-payments/add?mode=add`, { state: formData })}
							className="modalButton"
							disabled={formType === "add" || formType === "view" ? true : false || userRights.can_add === true ? false : true}
						>
							Accept Bill Payment
						</Button>
					</Tooltip>
					<Tooltip placement="bottom" title="Search" color="#fbfbfb">
						<Button disabled={userRights.can_view === true ? false : true} onClick={() => history.push("/bill/list")} className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
					</Tooltip>
					{formType !== "add" ? <>

						{(formType === "view" || formType === "formView") && (

							<Tooltip placement="bottom" title="Add" color="#fbfbfb">
								<Button
									disabled={formType === "add" ? true : false || userRights.can_add === true ? false : true}
									onClick={() => {
										if (formType === "add") {
											formState("add");
										} else {
											history.push("/bill/add?mode=add");
											formState("add");
										}
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							</Tooltip>
						)}
						{(formType === "formView" || formType === "view") && (
							<Tooltip placement="bottom" title="Edit" color="#fbfbfb">
								<Button
									disabled={(disable || formType === "view" || formType === "add" || (userRights?.can_edit === false ? true : false)) || transactionLock}
									onClick={() => formState("edit")}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<EditOutlined />}
								/>
							</Tooltip>
						)}
						<Tooltip placement="bottom" title="Delete" color="#fbfbfb">
							{userRights?.can_delete === false ? (
								<Button className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} disabled={true}/>
							) : (formType === "formView" || formType === "view") && (
								<Popconfirm
									disabled={(disable || formType === "add" || formType === "view") || transactionLock}
									placement="topLeft"
									title={`Are you sure to delete this ${saleOrderID}?`}
									onConfirm={confirm}
									okText="Yes"
									cancelText="No"
								>
									<Button disabled={(disable || formType === "add" || formType === "view" || userRights.can_delete === true ? false : true) || transactionLock} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
								</Popconfirm>
							)}
						</Tooltip>
					</> : null}
					<Tooltip placement="bottom" title="Save" color="#fbfbfb">
						<Button
							disabled={!((formType === "add" || formType === "edit") && (name === "product" ? (formType === "edit" ? true : flag ? true : false) : !disable)) || transactionLock}
							onClick={() => save()}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<FileDoneOutlined />}
						/>
					</Tooltip>
				</div>
			</div>
			<Divider />
		</>


		// <>
		// 	<div className="crudContainer">
		// 		<div>
		// 			{formType === "" ? null : (
		// 				<div className="buttons-div">
		// 					<Tag color={"green"} key={1}>
		// 						{formType.toUpperCase()}
		// 					</Tag>
		// 					{formType !== "view" && <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />}
		// 				</div>
		// 			)}
		// 		</div>
		// 		<div>
		// 			<Tooltip placement="bottom" title="Search" color="#fbfbfb">
		// 				<Button onClick={() => history.push("/bill/list")} className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
		// 			</Tooltip>
		// 			<Tooltip placement="bottom" title="Add" color="#fbfbfb">
		// 				<Button
		// 					disabled={formType === "add" ? true : false}
		// 					onClick={() => {
		// 						if (formType === "add") {
		// 							formState("add");
		// 						} else {
		// 							formState("add");
		// 						}
		// 					}}
		// 					className="iconBtn crudBTN"
		// 					shape="circle"
		// 					icon={<PlusOutlined />}
		// 				/>
		// 			</Tooltip>
		// 			{/*Edit Button*/}
		// 			<Tooltip placement="bottom" title="Edit" color="#fbfbfb">
		// 				<Button
		// 					disabled={disable || formType === "view" || formType === "add" || (rights?.can_edit === false ? true : false)}
		// 					onClick={() => formState("edit")}
		// 					className="iconBtn crudBTN"
		// 					shape="circle"
		// 					icon={<EditOutlined />}
		// 				/>
		// 			</Tooltip>
		// 			{/*Delete Button*/}
		// 			<Tooltip placement="bottom" title="Delete" color="#fbfbfb">
		// 				{rights?.can_delete === false ? (
		// 					<Button className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
		// 				) : (
		// 					<Popconfirm
		// 						disabled={disable || formType === "add" || formType === "view"}
		// 						placement="topLeft"
		// 						title={`Are you sure to delete this ${saleOrderID}?`}
		// 						onConfirm={confirm}
		// 						okText="Yes"
		// 						cancelText="No"
		// 					>
		// 						<Button disabled={disable || formType === "add" || formType === "view"} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
		// 					</Popconfirm>
		// 				)}
		// 			</Tooltip>
		// 			{/*Save Button*/}
		// 			<Tooltip placement="bottom" title="Save" color="#fbfbfb">
		// 				<Button
		// 					disabled={!((formType === "add" || formType === "edit") && (name === "product" ? (formType === "edit" ? true : flag ? true : false) : !disable))}
		// 					onClick={() => save()}
		// 					className="iconBtn crudBTN"
		// 					shape="circle"
		// 					icon={<FileDoneOutlined />}
		// 				/>
		// 			</Tooltip>
		// 		</div>
		// 	</div>
		// 	<Divider />
		// </>
	);
}