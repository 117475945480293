import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getRights } from "../../redux/actions/userRights.action";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess";
import { getLog } from "../../redux/actions/log.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables";
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from "antd";
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { logColumns, washTypeColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { addWashType, deleteWashType, getWashType, updateWashType } from "../../redux/actions/washType.action";
import { TabTitle } from "../../utils/GeneralFunction";

const { TabPane } = Tabs;
export default function Wash({ token }) {
  TabTitle("Wash Type")
  const menuId = ReadCookie('menuId')
  const dCompanyId = ReadCookie('defaultCompany')
  const washNameInput = useRef(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [washObj, setWashObj] = useState(null);
  const dispatch = useDispatch();
  const { washType, getDemoDefaultCompany, companyAccess, companyAll, log, userRights } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [washName, setWashName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("");

  const handleEdit = (record, type) => {
    if (type === "active") {
      dispatch(updateCompanyAccess(token, record)).then((res) =>
        dispatch(getCompanyAccess(token, washObj.wash_type_id, menuId))
      );
    }
    if (type === "delete") {
      dispatch(deleteCompanyAccess(token, record)).then((res) =>
        dispatch(getCompanyAccess(token, washObj.wash_type_id, menuId))
      );
    }
  };

  const createAssignCompany = (record) => {
    dispatch(createCompanyAccess(token, record, washObj.wash_type_id, menuId)).then((res) =>
      dispatch(getCompanyAccess(token, washObj.wash_type_id, menuId))
    );
  };
  const companyCol = [
    { title: "Company ID", dataIndex: "company_id", key: "company_id" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Tooltip title="Minimize" color="#fbfbfb"></Tooltip>
          {record.is_active ? (
            <Tooltip title="Activate" color="#fbfbfb">
              <CheckCircleOutlined
                className='color-green'
                onClick={() => handleEdit(record, "active")}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Deactivate" color="#fbfbfb">
              <CloseCircleOutlined
                className="color-red"
                onClick={() => handleEdit(record, "active")}
              />
            </Tooltip>
          )}
          {dCompanyId ===
            record.company_id.toString() ? (
            <Tooltip title="Default company can't be deleted" color="#fbfbfb">
              <DeleteOutlined
                disabled
                twoToneColor="red"
                className='delete-Button'
              />
            </Tooltip>
          ) : (
            <Tooltip title="delete" color="#fbfbfb">
              <Popconfirm
                placement="top"
                title={"Are you sure to delete this?"}
                onConfirm={() => handleEdit(record, "delete")}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  twoToneColor="red"
                  className='delete-Button'
                />
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const companyAccessCol = [
    { title: "ID", dataIndex: "company_id", key: "company_id" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {companyAccess?.payload?.data?.find(
            (m) => m.company_id === record?.company_id
          ) ? (
            <>
              <Tooltip title="Already assigned" color="#fbfbfb">
                <Button
                  className="modalActionBTN"
                  disabled
                  shape="circle"
                  icon={<CheckCircleOutlined className="color-red" />}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Click to assign" color="#fbfbfb">
              <Button
                className="modalActionBTN"
                onClick={() => createAssignCompany(record)}
                shape="circle"
                icon={<CloseCircleOutlined className='color-green' />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   dispatch(getDefaultCompany(token));
  // }, [token]);

  useEffect(() => {
    dispatch(getWashType(token, menuId));
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table")
        }
      }
    });
  }, [])

  useEffect(() => {
    if (washObj) {
      setFormState("view");
      form.setFieldsValue({
        id: washObj.wash_type_id,
        style: washObj.wash_type_name,
        active: washObj.is_active,
      });
      setWashName(washObj.wash_type_name);
      setCheck(washObj.is_active);
      dispatch(getCompanyAccess(token, washObj.wash_type_id, menuId));
    }
  }, [washObj]);

  useEffect(() => {
    if (washName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [washName]);

  const showModal = () => {
    dispatch(getAllCompany(token));
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(getLog(token, { inv_wash: "inv_wash" }));
    }
  };

  const formSave = () => {
    if (formState === "add") {
      dispatch(addWashType(token, { washName, check }, menuId)).then((res) => {

        if (res.type === "ADD_WASHTYPE_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        if (res.type === "ADD_WASHTYPE_SUCCESS") {
          let data = {
            company_id: dCompanyId,
          };
          // 
          // 
          dispatch(
            createCompanyAccess(token, data, res?.payload?.wash_type_id, menuId)
          ).then((res) =>
            dispatch(getCompanyAccess(token, washObj.wash_type_id, menuId))
          );
          notification("success", "Wash Type Created", "Wash created successfully!");
          form.setFieldsValue({
            wash: "",
          });
          setFormState("");
          // setCheck(false);
          setWashName("");
          setWashObj(null);
        }
        dispatch(getWashType(token, menuId));
      });
    }

    if (formState === "edit") {
      dispatch(
        updateWashType(token, { washName, check }, washObj.wash_type_id,)
      ).then((res) => {
        // dispatch(Wash(token));
        if (res.type === "UPDATE_WASHTYPE_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_WASHTYPE_SUCCESS") {
          notification("success", "Wash Type Updated", "Record updated successfully");
        }
        dispatch(getWashType(token, menuId));
      });
    }
  };

  const handleDelete = () => {
    if (washObj) {
      dispatch(deleteWashType(token, washObj.wash_type_id)).then((res) => {
        if (res.type === "DELETE_WASHTYPE_SUCCESS") {
          notification(
            "success",
            "Wash Type Deleted",
            "Wash Type deleted successfully!"
          );
        }
        if (res.type === "DELETE_WASHTYPE_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        form.setFieldsValue({
          style: "",
        });
        // setCheck(false);
        setWashName("");
        setWashObj(null);
        setFormState("");
        dispatch(getWashType(token, menuId));
      });
    }
  };

  useEffect(() => {
    if (formState === "add") {
      dispatch(getOneDefaultCompany(token, dCompanyId));
      washNameInput.current.focus();
      form.setFieldsValue({
        style: "",
      });
      setCheck(true);
      setWashName("");
      setWashObj(null);
    }
  }, [formState]);

  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Wash Type"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className='tables-main-row-new'>
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="wash"
                      washObj={setWashObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={washType?.payload?.data}
                      col={washTypeColumns}
                      paginate
                      load={washType.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 12 }}>
                      <div className='customRow styleFormUpper ' >
                        {washObj !== null && !(formState === "add") && (
                          <div className='customCols slc'>
                            <Form.Item
                              label="ID"
                              name="id"
                              initialValue={form.getFieldValue().id}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input className="styleInput" size='small' readOnly />
                            </Form.Item>
                          </div>
                        )}
                        <div className='customCols slc'>
                          <Form.Item
                            label="Wash Type"
                            name="style"
                            initialValue={form.getFieldValue().style}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}

                          >
                            <Input
                              size='small'
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={washNameInput}
                              onChange={(e) => setWashName(e.target.value)}
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                        <div className='customCols slc'>
                          <Form.Item label="Active" name="active" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="modalStyle">
                      {washObj === null ? (
                        <Tooltip
                          title="Please select style first"
                          color="#fbfbfb"
                        >
                          <Button
                            disabled={washObj === null ? true : false}
                            className=""
                            onClick={showModal}
                          >
                            Assign Companies
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button type="primary" className="modalButton" onClick={showModal}>
                          Assign Companies
                        </Button>
                      )}
                    </div>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type='card' tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Companies" key="1">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"25rem"}
                              scroll={340}
                              perPage={12}
                              source={(formState === "add") ? getDemoDefaultCompany?.payload?.data : (formState === "edit" || formState === "view" ? companyAccess?.payload?.data : [])} loading={(formState === "add") ? getDemoDefaultCompany.loading : companyAccess.loading}
                              col={companyCol}
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                    <div className="modalForm">
                      <Modal
                        title="Assign Companies"
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                      >
                        <Tables
                          type="companyAccess"
                          scroll={334}
                          perPage={12}
                          source={companyAll?.payload?.data}
                          col={companyAccessCol}
                          load={companyAll.loading}
                        />
                      </Modal>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className='minimize-button-style'
            onClick={() => setMinimize(false)}
          >
            Wash
          </Button>
        </div>
      )}
    </div>
  );
}
