import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Input, Select, DatePicker, Table, InputNumber, Spin } from "antd";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Loader from 'react-loader-spinner';
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getPendingDetail } from "../../redux/actions/pendingDetailReport.action";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getStyle } from "../../redux/actions/style";
import { ReadCookie } from "../../utils/readCookie";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminLocation } from '../../redux/actions/adminLocation.action';
import { useLocation } from 'react-router-dom';

import moment from "moment";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
const { Option } = Select;

const PendingDetailReport = () => {
    TabTitle("Pending Detail")
    const [form] = Form.useForm();
    const [minimize, setMinimize] = useState(false);
    const [toogle, setToogle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pendingDetailList, setPendingDetailList] = useState([]);
    const [excelData, setExcelData] = useState([])
    const submitButton = useRef(null);
    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");
    const { gender, region, style, salePersonForm, defaultCompany, paymentterm, customerTypes, pendingdDetailReport, saleOrderFormData, adminLocation } = useSelector((state) => state);
    const queryParams = new URLSearchParams(window.location.search);
    const customerID = queryParams.get("customer_id");
    const companyId = ReadCookie("defaultCompany");
    const location = useLocation();
    const initialDate = moment('2026-12-31')


    // console.log("data",location?.state?.style_id)

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let query_sku = query.get("sku");
    console.log("query_sku", query_sku)

    useEffect(() => {
        if (query_sku) {
            const values = {
                sku: query_sku,
                to_date: moment('2026-12-31').endOf('day')
            }
            form.setFieldsValue({
                sku: values.sku,
            });
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
    }, [query_sku])


    useEffect(() => {
        if (location?.state?.item_code) {
            const values = {
                cutomer_category: null,
                to_date: initialDate,
                order_no: null,
                po_no: null,
                // customer: null,
                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: location?.state?.item_code,
                status: null,
                location: null,
                style: null
            }
            form.setFieldsValue({
                sku: values.sku,
            });
            console.log("value", values.date_to)
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
        if (location?.state?.style_id) {
            const values = {
                cutomer_category: null,
                to_date: initialDate,
                order_no: null,
                po_no: null,
                // customer: null,
                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: null,
                status: null,
                location: null,
                style: location?.state?.style_id
            }
            form.setFieldsValue({
                style: values.style,
            });
            console.log("value", values.date_to)
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
        if (location?.state?.order_header_no) {
            const values = {
                cutomer_category: null,
                to_date: initialDate,
                order_no: location?.state?.order_header_no,
                po_no: null,
                // customer: null,
                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: null,
                status: null,
                location: null,
                style: null
            }
            form.setFieldsValue({
                order_no: values.order_no,
            });
            console.log("value", values.date_to)
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
        else if (location?.state?.po_no) {
            const values = {
                cutomer_category: null,
                to_date: initialDate,
                order_no: null,
                po_no: location?.state?.po_no,
                // customer: null,
                sale_person: null,
                region: null,
                gender: null,
                terms: null,
                store: null,
                subsidary: null,
                source: null,
                sku: null,
                status: null,
                location: null,
                style: null
            }
            form.setFieldsValue({
                // order_no: values.order_no,
                po_no: values.po_no
            });
            console.log("value", values.date_to)
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
    }, [location?.state?.item_code, location?.state?.style_id, location?.state?.order_header_no, location?.state?.po_no])

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Order #": v.order_header_no,
                    "Order Date": moment(v.order_date).format("MM-DD-YY"),
                    "Start Date": moment(v.start_date).format("MM-DD-YY"),
                    "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
                    "PO #": v.reference_no,
                    "Customer": v.customer_name,
                    "Billing Addressee": v.billing_addressee,
                    "SKU": v.parent_item_code,
                    "Store": v.store_id,
                    "Product Status": v.productstatus,
                    "SKU": v.parent_item_code,
                    "Inseam": v.inseam,
                    "Style": v.style_name,
                    "Wash": v.wash_name,
                    // "=\"\"2/3\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.size1qty1,
                    // "4/5 / S / 3 / 28 / 24": v.size1qty2,
                    // "6/6X / M / 4 / 29 / 25": v.size1qty3,
                    // "- / L / 5 / 30 / 26": v.size1qty4,
                    // "- / XL / 6 / 31 / 27": v.size1qty5,
                    // "- / 1XL / 6x / 32 / 28": v.size1qty6,
                    // "- / 2XL / 7 / 33 / 29": v.size1qty7,
                    // "- / 3XL / 7x / 34 / 30": v.size1qty8,
                    // "- / - / 8 / 35 / 31": v.size1qty9,
                    // "- / - / 10 / 36 / 32": v.size1qty10,
                    // "- / - / 12 / 38 / 33": v.size1qty11,
                    // "- / - / 14 / 40 / 34": v.size1qty12,
                    // "- / - / 16 / 42 / 35": v.size1qty13,
                    // "- / - / 18 / 44 / 36": v.size1qty14,
                    // "- / - / 20 / 46 / 37": v.size1qty15,
                    // "- / - / - / 48 / 38": v.size1qty16,
                    "=\"\"2/3\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.size1qty1,
                    "=\"\"4/5\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.size1qty2,
                    "=\"\"6/6X\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.size1qty3,
                    "=\"\"-\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.size1qty4,
                    "=\"\"-\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.size1qty5,
                    "=\"\"-\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.size1qty6,
                    "=\"\"-\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.size1qty7,
                    "=\"\"-\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.size1qty8,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.size1qty9,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.size1qty10,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.size1qty11,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.size1qty12,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.size1qty13,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.size1qty14,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.size1qty15,
                    "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.size1qty16,
                    "Order Qty": v.orderqty,
                    "PT Qty": v.ptqty,
                    "Pending Qty": v.pending_qty,
                    "Rate": parseFloat(v.rate).toFixed(2),
                    "Pending Amount": parseFloat(v.amount).toFixed(2),
                    "Tax Amount": parseFloat(v.tax_amount).toFixed(2),
                    "Discount Amount": parseFloat(v.disc_amount).toFixed(2),
                    "Net Amount": parseFloat(v.net_amount).toFixed(2),
                    "Gender": v.gender_name,
                    "Order Status": v.status,
                    "Customer Category": v.customer_type_name,
                    "Term": v.payment_term,
                    "Region": v.region_name,
                    "Sale person Name": v.salesperson_name,
                    "Billing State": v.billingstate,
                    "Loaction": v.location_name,
                    "Product Status": v.productstatus,
                    "New Season": v.newseason,
                    "Store": v.store_id,

                };

            })
        )
    }

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getPaymentTerm(token));
        dispatch(getCustomerType(token));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getSaleOrderFormData(token, companyId, menuId, "", customerID));
    }, [dispatch, menuId, token]);

    const BDCols = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'Order #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            width: 80,
        },
        {
            title: 'Order Date',
            width: 80,
            render: (text, record) => {
                return <>{moment(record.order_date).format("MM-DD-YY")}</>
            }
        },
        {
            title: 'Start Date',
            width: 80,
            render: (text, record) => {
                return <>{moment(record.start_date).format("MM-DD-YY")}</>
            }
        },
        {
            title: 'Cancel Date',
            width: 80,
            render: (text, record) => {
                return <>{moment(record.cancel_date).format("MM-DD-YY")}</>
            }
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            width: 100,
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: 150,
        },
        {
            title: 'Billing Addressee',
            dataIndex: 'billing_addressee',
            key: 'billing_addressee',
            width: 150,
        },
        {
            title: 'SKU',
            dataIndex: 'parent_item_code',
            key: 'parent_item_code',
            width: 70,
        },
        {
            title: 'Inseam',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (text, record, index) => record.inseam === null ? "N/A" : record.inseam
        },
        {
            title: 'Style',
            dataIndex: 'style_name',
            key: 'style_name',
            width: 150,
        },
        {
            title: 'Wash',
            dataIndex: 'wash_name',
            key: 'wash_name',
            width: 100,
        },
        {
            children: [
                {
                    title: 'Infant',
                    dataIndex: 'Infant',
                    key: 'Infant',
                    width: 50,
                    children: [
                        {
                            title: 'T-Jacket',
                            dataIndex: 'building',
                            key: 'building',
                            width: 70,
                            children: [
                                {
                                    title: 'Shirt',
                                    dataIndex: 'building',
                                    key: 'building',
                                    width: 50,
                                    children: [
                                        {
                                            title: 'Y-Adult',
                                            dataIndex: 'building',
                                            key: 'building',
                                            width: 50,
                                            children: [
                                                {
                                                    title: 'Kids',
                                                    dataIndex: 'building',
                                                    key: 'building',
                                                    width: 50,
                                                    children: [
                                                        {
                                                            title: 'Men',
                                                            dataIndex: 'building',
                                                            key: 'building',
                                                            width: 50,
                                                            children: [
                                                                {
                                                                    title: 'Women',
                                                                    dataIndex: 'building',
                                                                    key: 'building',
                                                                    width: 70,
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '3m',
                    align: 'center',
                    dataIndex: 'size1qty1',
                    key: 'size1qty1',
                    width: 50,
                    children: [
                        {
                            title: '"2/3"',
                            align: 'center',
                            dataIndex: 'size1qty1',
                            key: 'size1qty1',
                            width: 55,
                            children: [
                                {
                                    title: 'XS',
                                    dataIndex: 'size1qty1',
                                    key: 'size1qty1',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '00',
                                            dataIndex: 'size1qty1',
                                            key: 'size1qty1',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '2',
                                                    dataIndex: 'size1qty1',
                                                    key: 'size1qty1',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '27',
                                                            dataIndex: 'size1qty1',
                                                            key: 'size1qty1',
                                                            align: 'center',
                                                            width: 50,
                                                            children: [
                                                                {
                                                                    title: '23',
                                                                    dataIndex: 'size1qty1',
                                                                    key: 'size1qty1',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '6m',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '"4/5"',
                            align: 'center',
                            dataIndex: 'size1qty2',
                            key: 'size1qty2',
                            width: 50,
                            children: [
                                {
                                    title: 'S',
                                    align: 'center',
                                    dataIndex: 'size1qty2',
                                    key: 'size1qty2',
                                    width: 55,
                                    children: [
                                        {
                                            title: '0',
                                            align: 'center',
                                            dataIndex: 'size1qty2',
                                            key: 'size1qty2',
                                            width: 50,
                                            children: [
                                                {
                                                    title: '3',
                                                    align: 'center',
                                                    dataIndex: 'size1qty2',
                                                    key: 'size1qty2',
                                                    width: 50,
                                                    children: [
                                                        {
                                                            title: '28',
                                                            align: 'center',
                                                            dataIndex: 'size1qty2',
                                                            key: 'size1qty2',
                                                            width: 50,
                                                            children: [
                                                                {
                                                                    title: '24',
                                                                    align: 'center',
                                                                    dataIndex: 'size1qty2',
                                                                    key: 'size1qty2',
                                                                    width: 50,
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '12m',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '"6/6X"',
                            dataIndex: 'size1qty3',
                            key: 'size1qty3',
                            align: 'center',
                            width: 60,
                            children: [
                                {
                                    title: 'M',
                                    dataIndex: 'size1qty3',
                                    key: 'size1qty3',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '1',
                                            dataIndex: 'size1qty3',
                                            key: 'size1qty3',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '4',
                                                    dataIndex: 'size1qty3',
                                                    key: 'size1qty3',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '29',
                                                            dataIndex: 'size1qty3',
                                                            key: 'size1qty3',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '25',
                                                                    dataIndex: 'size1qty3',
                                                                    key: 'size1qty3',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '18m',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty4',
                            key: 'size1qty4',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'L',
                                    dataIndex: 'size1qty4',
                                    key: 'size1qty4',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '2',
                                            dataIndex: 'size1qty4',
                                            key: 'size1qty4',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '5',
                                                    dataIndex: 'size1qty4',
                                                    key: 'size1qty4',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '30',
                                                            dataIndex: 'size1qty4',
                                                            key: 'size1qty4',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '26',
                                                                    dataIndex: 'size1qty4',
                                                                    key: 'size1qty4',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '24m',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty5',
                            key: 'size1qty5',
                            width: 50,
                            align: 'center',
                            children: [
                                {
                                    title: 'XL',
                                    dataIndex: 'size1qty5',
                                    key: 'size1qty5',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '3',
                                            dataIndex: 'size1qty5',
                                            key: 'size1qty5',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '6',
                                                    dataIndex: 'size1qty5',
                                                    key: 'size1qty5',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '31',
                                                            dataIndex: 'size1qty5',
                                                            key: 'size1qty5',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '27',
                                                                    dataIndex: 'size1qty5',
                                                                    key: 'size1qty5',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty6',
                            key: 'size1qty6',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '1XL',
                                    dataIndex: 'size1qty6',
                                    key: 'size1qty6',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '4',
                                            dataIndex: 'size1qty6',
                                            key: 'size1qty6',
                                            width: 50,
                                            align: 'center',

                                            children: [
                                                {
                                                    title: '6x',
                                                    dataIndex: 'size1qty6',
                                                    key: 'size1qty6',
                                                    width: 50,
                                                    align: 'center',

                                                    children: [
                                                        {
                                                            title: '32',
                                                            dataIndex: 'size1qty6',
                                                            key: 'size1qty6',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '28',
                                                                    dataIndex: 'size1qty6',
                                                                    key: 'size1qty6',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty7',
                            key: 'size1qty7',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '2XL',
                                    dataIndex: 'size1qty7',
                                    key: 'size1qty7',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '5',
                                            dataIndex: 'size1qty7',
                                            key: 'size1qty7',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '7',
                                                    dataIndex: 'size1qty7',
                                                    key: 'size1qty7',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '33',
                                                            dataIndex: 'size1qty7',
                                                            key: 'size1qty7',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '29',
                                                                    dataIndex: 'size1qty7',
                                                                    key: 'size1qty7',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty8',
                            key: 'size1qty8',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '3XL',
                                    dataIndex: 'size1qty8',
                                    key: 'size1qty8',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '6',
                                            dataIndex: 'size1qty8',
                                            key: 'size1qty8',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '7x',
                                                    dataIndex: 'size1qty8',
                                                    key: 'size1qty8',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '34',
                                                            dataIndex: 'size1qty8',
                                                            key: 'size1qty8',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '30',
                                                                    dataIndex: 'size1qty8',
                                                                    key: 'size1qty8',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty9',
                            key: 'size1qty9',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty9',
                                    key: 'size1qty9',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '7',
                                            dataIndex: 'size1qty9',
                                            key: 'size1qty9',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '8',
                                                    dataIndex: 'size1qty9',
                                                    key: 'size1qty9',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '35',
                                                            dataIndex: 'size1qty9',
                                                            key: 'size1qty9',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '31',
                                                                    dataIndex: 'size1qty9',
                                                                    key: 'size1qty9',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty10',
                            key: 'size1qty10',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty10',
                                    key: 'size1qty10',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '8',
                                            dataIndex: 'size1qty10',
                                            key: 'size1qty10',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '10',
                                                    dataIndex: 'size1qty10',
                                                    key: 'size1qty10',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '36',
                                                            dataIndex: 'size1qty10',
                                                            key: 'size1qty10',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '32',
                                                                    dataIndex: 'size1qty10',
                                                                    key: 'size1qty10',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty11',
                            key: 'size1qty11',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty11',
                                    key: 'size1qty11',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '9',
                                            dataIndex: 'size1qty11',
                                            key: 'size1qty11',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '12',
                                                    dataIndex: 'size1qty11',
                                                    key: 'size1qty11',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '38',
                                                            dataIndex: 'size1qty11',
                                                            key: 'size1qty11',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '33',
                                                                    dataIndex: 'size1qty11',
                                                                    key: 'size1qty11',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty12',
                            key: 'size1qty12',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty12',
                                    key: 'size1qty12',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '10',
                                            dataIndex: 'size1qty12',
                                            key: 'size1qty12',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '14',
                                                    dataIndex: 'size1qty12',
                                                    key: 'size1qty12',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '40',
                                                            dataIndex: 'size1qty12',
                                                            key: 'size1qty12',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '34',
                                                                    dataIndex: 'size1qty12',
                                                                    key: 'size1qty12',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty13',
                            key: 'size1qty13',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty13',
                                    key: 'size1qty13',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty13',
                                            key: 'size1qty13',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '16',
                                                    dataIndex: 'size1qty13',
                                                    key: 'size1qty13',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '42',
                                                            dataIndex: 'size1qty13',
                                                            key: 'size1qty13',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '35',
                                                                    dataIndex: 'size1qty13',
                                                                    key: 'size1qty13',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty14',
                            key: 'size1qty14',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty14',
                                    key: 'size1qty14',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty14',
                                            key: 'size1qty14',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '18',
                                                    dataIndex: 'size1qty14',
                                                    key: 'size1qty14',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '44',
                                                            dataIndex: 'size1qty14',
                                                            key: 'size1qty14',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '36',
                                                                    dataIndex: 'size1qty14',
                                                                    key: 'size1qty14',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty15',
                            key: 'size1qty15',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty15',
                                    key: 'size1qty15',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty15',
                                            key: 'size1qty15',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '20',
                                                    dataIndex: 'size1qty15',
                                                    key: 'size1qty15',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '46',
                                                            dataIndex: 'size1qty15',
                                                            key: 'size1qty15',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '37',
                                                                    dataIndex: 'size1qty15',
                                                                    key: 'size1qty15',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '-',
                    align: 'center',
                    width: 50,
                    children: [
                        {
                            title: '-',
                            dataIndex: 'size1qty16',
                            key: 'size1qty16',
                            width: 50,
                            align: 'center',

                            children: [
                                {
                                    title: '-',
                                    dataIndex: 'size1qty16',
                                    key: 'size1qty16',
                                    width: 50,
                                    align: 'center',
                                    children: [
                                        {
                                            title: '-',
                                            dataIndex: 'size1qty16',
                                            key: 'size1qty16',
                                            width: 50,
                                            align: 'center',
                                            children: [
                                                {
                                                    title: '-',
                                                    dataIndex: 'size1qty16',
                                                    key: 'size1qty16',
                                                    width: 50,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '48',
                                                            dataIndex: 'size1qty16',
                                                            key: 'size1qty16',
                                                            width: 50,
                                                            align: 'center',
                                                            children: [
                                                                {
                                                                    title: '38',
                                                                    dataIndex: 'size1qty16',
                                                                    key: 'size1qty16',
                                                                    width: 50,
                                                                    align: 'center',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Order Qty',
            dataIndex: "orderqty",
            key: 'orderqty',
            width: 60,
            align: "right",
        },
        {
            title: 'PT Qty',
            dataIndex: "ptqty",
            key: 'ptqty',
            width: 70,
            align: "right",
        },
        {
            title: 'Pending Qty',
            dataIndex: "pending_qty",
            key: 'pending_qty',
            width: 100,
            align: "right",
        },
        {
            title: 'Rate',
            dataIndex: "rate",
            key: 'rate',
            width: 70,
            align: "right",
            render: (text, record) => {
                return <>$ {numeral(record.rate).format('0,0.00')}</>
            },
        },
        {
            title: 'Pending Amount',
            dataIndex: "amount",
            key: 'amount',
            width: 100,
            align: "right",
            render: (text, record) => {
                return <>$ {numeral(record.amount).format('0,0.00')}</>
            },
        },
        {
            title: 'Tax Amount',
            dataIndex: "tax_amount",
            key: 'tax_amount',
            width: 70,
            align: "right",
            render: (text, record) => {
                return <>$ {numeral(record.tax_amount).format('0,0.00')}</>
            },
        },
        {
            title: 'Discount',
            dataIndex: "disc_amount",
            key: 'disc_amount',
            width: 80,
            align: "right",
            render: (text, record) => {
                return <>$ {numeral(record.disc_amount).format('0,0.00')}</>
            },
        },
        {
            title: 'Net Amount',
            dataIndex: "net_amount",
            key: 'net_amount',
            width: 80,
            align: "right",
            render: (text, record) => {
                return <>$ {numeral(record.net_amount).format('0,0.00')}</>
            },
        },
        {
            title: 'Gender',
            dataIndex: 'gender_name',
            key: 'gender_name',
            width: 70,
        },
        {
            title: 'Order Status',
            dataIndex: "status",
            key: 'status',
            width: 80,
        },
        {
            title: 'Customer Category',
            dataIndex: 'customer_type_name',
            key: 'customer_type_name',
            width: 150,
        },
        {
            title: 'Terms',
            dataIndex: "payment_term",
            key: 'payment_term',
            width: 80,
        },
        {
            title: 'Region',
            dataIndex: "region_name",
            key: 'region_name',
            width: 120,
        },
        {
            title: 'Sales Person',
            dataIndex: "salesperson_name",
            key: 'salesperson_name',
            width: 150,
        },
        {
            title: 'Billing State',
            dataIndex: 'billingstate',
            key: 'billingstate',
            width: 80,
        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location_name',
            width: 120,
        },
        {
            title: 'Product Status',
            dataIndex: 'productstatus',
            key: 'productstatus',
            width: 80,
        },
        {
            title: 'New Season',
            dataIndex: "newseason",
            key: 'newseason',
            width: 80,
        },
        {
            title: 'Store',
            dataIndex: "store_id",
            key: 'store_id',
            width: 80,
            render: (text, record) => (record.store_id === "-1" || record.store_id === null) ? "N/A" : record.store_id
        },
    ];

    const onFinish = async () => {
        await form.validateFields().then((values) => {
            setLoading(true)
            dispatch(getPendingDetail(token, values)).then((res) => {
                setToogle(true);
                setLoading(false)
                setPendingDetailList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        })
    }

    const totalQuantity = () => {
        let sumQty = pendingDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.pending_qty);
        }, 0);
        return sumQty;
    }

    const totalSum = () => {
        let sumAmount = pendingDetailList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.amount);
        }, 0);
        return sumAmount;
    }

    return (
        <div className="styleContainer">
            <div className="styleBodyPendingSummary">
                <PageHeader
                    title={"Pending Detail"}
                    minimize={setMinimize}
                    check={minimize}
                />
                {!minimize && (
                    <div className="bodyStyle">
                        <div className="formProduct p-2">
                            <Form onFinish={onFinish} form={form} name="control-hooks">
                                <div className="container-fluid pbpx-5 ptpx-5">
                                    <Brow>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="cutomer_category"
                                                    label="Customer Category"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        defaultValue={form.getFieldValue().cutomer_category}
                                                        className="styleInput w-100"
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            customerTypes?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        {/* 
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div className="relative">
                                                    <Form.Item
                                                        name="from_date"
                                                        label="From Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={moment(("01-01-2023"))}

                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}


                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol> */}
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div >
                                                <div className="relative">
                                                    <Form.Item
                                                        name="to_date"
                                                        label="To Date:"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        initialValue={initialDate}
                                                    >
                                                        <DatePicker
                                                            size='small'
                                                            className="styleInput w-100"
                                                            format={"MM-DD-YYYY"}
                                                            defaultValue={moment().format("MM-DD-YYYY")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="order_no"
                                                        label="Order #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <InputNumber
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="customer"
                                                        label="Customer"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="billing_addressee"
                                                        label="Billing addressee"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="po_no"
                                                        label="PO #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="sale_person"
                                                    label="Sale Person"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        // onSearch={onSearch}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().sale_person}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            salePersonForm?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().region}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            region?.payload?.data.map((val) => {
                                                                return (

                                                                    <Option key={val.region_id} value={val.region_id}>{val.region_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="gender"
                                                    label="Gender"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, options) =>
                                                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                            0
                                                        }

                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().gender}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            gender?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="terms"
                                                    label="Terms"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().terms}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            paymentterm?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.term_id}>{val.term_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Input
                                                        size='small'
                                                        className="styleInput"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="store"
                                                        label="Store #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item name="subsidary"
                                                    label="Subsidary"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={3}
                                                    >
                                                    <Select
                                                        showSearch
                                                        defaultValue={form.getFieldValue().subsidary}
                                                        // placeholder=""
                                                        optionFilterProp="children"
                                                        size="small"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {/* <Option value={null}>All</Option> */}
                                                        <Option
                                                            key={defaultCompany?.payload?.company_id}
                                                            value={defaultCompany?.payload?.company_id}
                                                        >
                                                            {defaultCompany?.payload?.company_name}
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        size="small"
                                                        autoComplete={"nope"}
                                                        allowClear
                                                        loading={saleOrderFormData.loading}
                                                        //   disabled={!(formState === "add" || formState === "edit")}
                                                        defaultValue={form.getFieldValue().orderStatus}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {saleOrderFormData?.payload?.orderStatusDetail.map(
                                                            (item) => {
                                                                return (
                                                                    <Option
                                                                        key={item.order_status_id}
                                                                        value={item.order_status_id}
                                                                    >
                                                                        {item.status_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="">
                                                <div >
                                                    <Form.Item
                                                        name="sku"
                                                        label="Sku #"
                                                        labelAlign="left"
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        defaultValue={form.getFieldValue().sku}
                                                    >
                                                        <Input
                                                            size='small'
                                                            className="styleInput"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Bcol>

                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="slc">
                                                <Form.Item
                                                    name="style"
                                                    label="Style"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}

                                                >
                                                    <Select
                                                        size='small'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                        className="styleInput w-100"
                                                        defaultValue={form.getFieldValue().style}
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            style?.payload?.data.map((val) => {
                                                                return (
                                                                    <Option value={val.style_id}>{val.style_name}</Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                        <Bcol lg={6} xl={4} xxl={3}>
                                            <div className="firstSectionRo">
                                                <Form.Item
                                                    name="location"
                                                    label="Location"
                                                    labelAlign="left"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={"All"}
                                                >
                                                    <Select
                                                        size="small"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        autoComplete={"nope"}
                                                        loading={adminLocation.loading}
                                                        filterSort={(optionA, optionB) =>
                                                            optionB?.value !== null ?
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                        }
                                                    >
                                                        <Option value={null}>All</Option>
                                                        {
                                                            adminLocation?.payload?.data.map((val, index) => {
                                                                return (
                                                                    <Option key={index} value={val.location_id}>
                                                                        {val.location_name}
                                                                    </Option>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Bcol>
                                    </Brow>
                                </div>
                            </Form>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm mb-2 mrpx-10"
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    loading ? (
                        <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                            <Spin size='large' />
                        </div>
                    ) : (pendingDetailList.length > 0 ? (
                        < Table
                            title={() =>
                                <CSVLink
                                    filename={"Pending-Detail.csv"}
                                    data={excelData}
                                    onClick={() => {

                                    }}
                                >
                                    Download Excel
                                </CSVLink>
                            }
                            ref={tableRef}
                            columns={BDCols}
                            loading={pendingdDetailReport?.loading}
                            dataSource={pendingDetailList}
                            size="small"
                            bordered
                            scroll={{
                                x: 'calc(1600px + 80%)',
                                y: 450,
                            }}
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 100,
                                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                            }}
                            summary={(pageData) => {
                                let Size1 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty1
                                    ));
                                },
                                    0);
                                let Size2 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty2
                                    ));
                                },
                                    0);
                                let Size3 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty3
                                    ));
                                },
                                    0);
                                let Size4 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty4

                                    ));
                                },
                                    0);
                                let Size5 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty5

                                    ));
                                },
                                    0);
                                let Size6 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty6

                                    ));
                                },
                                    0);
                                let Size7 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty7

                                    ));
                                },
                                    0);
                                let Size8 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty8

                                    ));
                                },
                                    0);
                                let Size9 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty9

                                    ));
                                },
                                    0);
                                let Size10 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty10

                                    ));
                                },
                                    0);
                                let Size11 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty11

                                    ));
                                },
                                    0);
                                let Size12 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty12

                                    ));
                                },
                                    0);
                                let Size13 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty13

                                    ));
                                },
                                    0);
                                let Size14 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty14

                                    ));
                                },
                                    0);
                                let Size15 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty15

                                    ));
                                },
                                    0);
                                let Size16 = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.size1qty16

                                    ));
                                },
                                    0);
                                let OrderQty = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.orderqty
                                    ));
                                },
                                    0);
                                let PTQty = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.ptqty
                                    ));
                                },
                                    0);
                                let PendingQty = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.pending_qty
                                    ));
                                },
                                    0);
                                let PendingAmount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.amount
                                    ));
                                },
                                    0);
                                let TaxAmount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.tax_amount
                                    ));
                                },
                                    0);
                                let Discount = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.disc_amount
                                    ));
                                },
                                    0);
                                let Net = pageData.reduce(function (
                                    previousValue,
                                    currentValue
                                ) {
                                    return (previousValue += parseFloat(
                                        currentValue.net_amount
                                    ));
                                },
                                    0);
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size1.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size2.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size3.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size4.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size5.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size6.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size7.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size8.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size9.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size10.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size11.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size12.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size13.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size14.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size15.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p className="totalquantity aligntext">
                                                    {Size16.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {OrderQty.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {PTQty.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    {PendingQty.toLocaleString("en-US")}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    $ {numeral(PendingAmount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    $ {numeral(TaxAmount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    $ {numeral(Discount).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ textAlign: "right" }} className="totalquantity">
                                                    $ {numeral(Net).format('0,0.00')}
                                                </p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    ) : <div
                        style={{
                            backgroundColor: "#CCCCCC",
                            borderWidth: "1pt",
                            borderStyle: "Dashed",
                            fontWeight: "bold",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        No record(s)
                    </div>
                    )
                }

            </div>

        </div>

    )
}

export default PendingDetailReport;