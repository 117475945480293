import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie, } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { logColumns, shipViaColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import notification from '../../utils/notification'
import { getShipVia, addShipVia, updateShipVia, deleteShipVia } from '../../redux/actions/shipvia.action';
import { loadShippingMethod } from '../../redux/actions/shippingMethod';
import { TabTitle } from '../../utils/GeneralFunction';



const { TabPane } = Tabs;

const ShipVia = ({ token }) => {
    TabTitle("Ship Via")
    const { ShipVia, userRights, log, } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [description, setDescription] = useState('');
    const [form] = Form.useForm();
    const shipViaInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [shipObj, setShipObj] = useState(null);
    const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');


    useEffect(() => {
        dispatch(getShipVia(token))
    }, [dispatch, token])



    useEffect(() => {
        dispatch(loadShippingMethod(token))
    }, [token, dispatch])

    const handleDelete = () => {
        if (shipObj) {
            dispatch(deleteShipVia(token, shipObj.ship_via_id)).then((res) => {
                if (res.type === "DELETE_SHIP_VIA_SUCCESS") {
                    notification("success", "Ship Via Deleted", "Record deleted successfully!");
                }
                if (res.type === "DELETE_SHIP_VIA_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    desc: ""
                })
                // setCheck(false)
                setDescription("")
                setShipObj(null)
                setFormState('view')
                dispatch(getShipVia(token, id))

            })
        }
        setFormState("view")
    }

    useEffect(() => {

        if (shipObj) {
            setFormState('view')
            form.setFieldsValue({
                id: shipObj.ship_via_id,
                desc: shipObj.ship_via_description,
                active: shipObj.is_active,

            });

            setDescription(shipObj.ship_via_description)
            setCheck(shipObj.is_active)


        }

    }, [shipObj]);

    useEffect(() => {
        if (description === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [description])




    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        dispatch(getLog(token, { admin_ship_via: "admin_ship_via" }))
    }, [dispatch, token])


    const formSave = async () => {

        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addShipVia(token, { ship_via_description: description, is_active: check }, id)).then((res) => {

                    if (res.type === "ADD_SHIP_VIA_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_SHIP_VIA_SUCCESS") {
                        notification("success", "Ship Via Created", "Ship created successfully!");
                        form.setFieldsValue({
                            desc: ""
                        })
                        setFormState("view")
                        setDescription("")
                        setShipObj(null)
                    }
                    dispatch(getShipVia(token, id))
                })
            }
            if (formState === "edit") {
                dispatch(updateShipVia(token, { ship_via_description: description, is_active: check }, shipObj.ship_via_id)).then((res) => {
                    if (res.type === "UPDATE_SHIP_VIA_ERROR") {
                        notification("error", "Not update", "Something went wrong")
                    }
                    if (res.type === "UPDATE_SHIP_VIA_SUCCESS") {
                        notification("success", "Ship Via Updated", "Record updated successfully")
                    }
                    dispatch(getShipVia(token, id))
                })
            }

        }).catch((err) => {

        })
    }

    useEffect(() => {
        if (formState === "add") {
            shipViaInput.current.focus()
            form.setFieldsValue({
                id: null,
                ship: null,
            })
            setCheck(true)
            setDescription("")
            setShipObj(null)
        }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setShipObj(null)
        setCheck(true)
        form.setFieldsValue({
            id: null,
            desc: null,
        })
        setDescription(null)
    }


    return (
        <div className='styleContainer'>
            <div className='styleBody h-100' >
                <PageHeader title={"Ship Via"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="shipVia" formState={formState} scrollX={"20rem"} shipViaObj={setShipObj} source={ShipVia?.payload?.data} col={shipViaColumns} paginate scroll={500} perPage={25} load={ShipVia.loading} rowKey="ship_via_id" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            // labelCol={{ span: 8 }}

                                            >
                                                <div className='customRow styleFormUpper ' >
                                                    {shipObj !== null && !(formState === "add") &&
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="ID"
                                                                name="id"
                                                                initialValue={form.getFieldValue().id}
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 14 }}

                                                            >
                                                                <Input size='small' className='styleInput id-field-style' readOnly />

                                                            </Form.Item>
                                                        </div>
                                                    }

                                                    {/*
<div className="customCols slc">
 <div>
   <Form.Item
     name="cid"
     label="Country "
     labelAlign='left'
     labelCol={{ span: 8 }}
     wrapperCol={{ span: 16 }}
     rules={[
       {
         required: true,
         message: "Country id is required",
       },
     ]}
   >
     <Select
     showSearch
     ref={countryNameInput}
       loading={country.loading}
       disabled={
         !(formState === "add" || formState === "edit")
       }
       optionFilterProp="children"
       onChange={(v)=>
    //    defaultValue={
    //      formState === "edit" || formState==="add"
    //        ? form.getFieldValue().cid
    //        : null
    //    }
       className="w-100"
       placeholder="Country"
  
       filterOption={(input, option) =>
         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={(v)=>
 
    onSelect={(e)=>selectHandler(e)}
       allowClear
    
     >
       {country?.payload?.data.map((country) => {
         return (
           <Option value={country.country_id}>
             {country.country_name}
           </Option>
         );
       })} 
     </Select>
   </Form.Item>
 </div>
</div> */}
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Desc"
                                                            name="desc"
                                                            initialValue={form.getFieldValue().desc}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >

                                                            <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={shipViaInput} onChange={(e) => setDescription(e.target.value)} className='styleInput' />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>

                                        <div className='modalStyle' >


                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  /**onChange={(key) => handleTab(key)} */ >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>





    )
}


export default ShipVia