import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { searchPackingList } from "../../redux/actions/searchPackingList.action";
import { sendProduct } from "../../redux/actions/sendProductToBergen.action";
import notification from "../../utils/notification";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { sendPackingList } from "../../redux/actions/sendPackingList.action";

const AsnToBergen = () => {
  TabTitle("ASN to Bergen");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);
  const [sDate, setStartDate] = useState(moment());
  console.log(controlSheet, "controlSheet");
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Company Name",
      render: (text, record) => {
        return <>{form.getFieldValue().company}</>;
      },
      // dataIndex: "Company",
      // key: "Company",
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //   }) => {
      //     return (
      //       <>
      //         <Input
      //           autoFocus
      //           allowClear
      //           className="pop-search-gen pop-170"
      //           placeholder="Type text here"
      //           value={selectedKeys[0]}
      //           onChange={(e) => {
      //             setSelectedKeys(e.target.value ? [e.target.value] : []);
      //             confirm({ closeDropdown: false });
      //           }}
      //           onPressEnter={() => {
      //             confirm();
      //           }}
      //           onBlur={() => {
      //             confirm();
      //           }}
      //         />
      //       </>
      //     );
      //   },
      //   filterIcon: (filtered) =>
      //     filtered ? <FilterFilled /> : <SearchOutlined />,
      //   onFilter: (value, record) =>
      //     record.ProductCode.toString()
      //       .toLowerCase()
      //       .includes(value.toLowerCase()),
      //   sorter: (a, b) => {
      //     return a.ProductCode - b.ProductCode;
      //   },
    },
    {
      title: "Address 1",
      render: (text, record) => {
        return <>{form.getFieldValue().address1}</>;
      },
    },
    {
      title: "Address 2",
      render: (text, record) => {
        return <>{form.getFieldValue().address2}</>;
      },
      // dataIndex: "ADDRESS2",
      // key: "ADDRESS2",
    },
    {
      title: "City",
      render: (text, record) => {
        return <>{form.getFieldValue().city}</>;
      },
      // dataIndex: "CITY",
      // key: "CITY",
    },
    {
      title: "State",
      dataIndex: "STATE",
      key: "STATE",
    },
    {
      title: "Zip",
      render: (text, record) => {
        return <>{form.getFieldValue().zip}</>;
      },
      // dataIndex: "zip",
      // key: "zip",
    },
    {
      title: "Country",
      render: (text, record) => {
        return <>{form.getFieldValue().country}</>;
      },
      // dataIndex: "COUNTRY",
      // key: "COUNTRY",
    },
    {
      title: "Non US Region",
      dataIndex: "Non_US_Region",
      key: "Non_US_Region",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Expected Date",
      render: (text, record) => {
        return (
          <>
            {!form.getFieldValue().startDate
              ? moment().format("MM-DD-YYYY")
              : moment(form.getFieldValue().startDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "Carrier",
      // dataIndex: "Carrier",
      // key: "Carrier",
      render: (text, record) => {
        return <>{form.getFieldValue().carrier}</>;
      },
    },
    {
      title: "Tracking Numbers",
      // dataIndex: "Awbno",
      // key: "Awbno",
      render: (text, record) => {
        return <>{form.getFieldValue().awb}</>;
      },
    },
    {
      title: "Driver Name",
      dataIndex: "DriverName",
      key: "DriverName",
    },
    {
      title: "License Plate",
      dataIndex: "LicensePlate",
      key: "LicensePlate",
    },
    {
      title: "Customer Po No",
      // dataIndex: "CustomerPoNo",
      // key: "CustomerPoNo",
      render: (text, record) => {
        return <>{form.getFieldValue().customer_po}</>;
      },
    },
    {
      title: "Authorized Number",
      dataIndex: "AuthorizedNumber",
      key: "AuthorizedNumber",
      align: "right",
    },
    {
      title: "Memo",
      // dataIndex: "Memo",
      // key: "Memo",
      render: (text, record) => {
        return <>{form.getFieldValue().memo}</>;
      },
    },
    {
      title: "Warehouse",
      dataIndex: "WAREHOUSE",
      key: "WAREHOUSE",
    },
    {
      title: "Shipment Type list",
      dataIndex: "ShipmentTypelist",
      key: "ShipmentTypelist",
    },
    {
      title: "Style",
      dataIndex: "Style",
      key: "Style",
    },
    {
      title: "Color",
      dataIndex: "COLOR",
      key: "COLOR",
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
    },
    {
      title: "Expected Quantity",
      dataIndex: "ExpectedQuantity",
      key: "ExpectedQuantity",
      align: "right",
    },
    {
      title: "Unit Cost",
      dataIndex: "UnitCost",
      key: "UnitCost",
      align: "right",
    },
    {
      title: "Product Description",
      dataIndex: "ProductDescription",
      key: "ProductDescription",
    },
    {
      title: "Product MSRP",
      dataIndex: "ProductMSRP",
      key: "ProductMSRP",
      align: "right",
    },
    {
      title: "Comments",
      dataIndex: "Comments",
      key: "Comments",
    },
    {
      title: "Shipment Type",
      dataIndex: "ShipmentType",
      key: "ShipmentType",
    },
    {
      title: "UPC",
      dataIndex: "UPC",
      key: "UPC",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      company: "ARTISTIC DENIM MILLS.LTD",
      brand: "DL1961 PREMIUM DENIM",
      address1: "PLOT NO. 5-9,23-26, SECTOR-16",
      address2: "KORANGI INDUSTRIAL AREA",
      city: "KARACHI",
      zip: "",
      country: "PAKISTAN",
    });
  }, []);
  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.packing_id) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert Packing ID.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          console.log(values, "values");
          setLoading(true);
          dispatch(searchPackingList(token, values)).then((res) => {
            setLoading(false);
            setControlSheet(res?.payload?.data);
            // setControlSheet(values);
            downloadExcelFileData(res?.payload?.data);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // setLoading(true);
    // await form
    //   .validateFields()
    //   .then((values) => {
    //     dispatch(getcontrolSheetLog(token, values)).then((res) => {
    //       setLoading(false);
    //       setControlSheet(res?.payload?.data);
    //       downloadExcelFileData(res?.payload?.data);
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  const totalQuantity = () => {
    let sumQty = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.ExpectedQuantity));
    }, 0);
    return sumQty;

    console.log("sumQty", sumQty);
  };

  const totalSum = () => {
    let sumAmount = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.amount));
    }, 0);
    return sumAmount;
  };

  const onSearch = async () => {
    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchPackingList(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Company Name": form.getFieldValue().company,
          "Address 1": form.getFieldValue().address1,
          "Address 2": form.getFieldValue().address2,
          City: form.getFieldValue().city,
          State: v.STATE,
          Zip: form.getFieldValue().zip,
          Country: form.getFieldValue().country,
          "Non US Region": v.Non_US_Region,
          Phone: v.phone,
          Fax: v.fax,
          Email: v.email,
          "Expected Date": !form.getFieldValue().startDate
            ? moment().format("MM-DD-YYYY")
            : moment(form.getFieldValue().startDate).format("MM-DD-YYYY"),
          Carrier: form.getFieldValue().carrier,
          "Tracking Numbers": form.getFieldValue().awb,
          "Driver Name": v.DriverName,
          "License Plate": v.LicensePlate,
          "Customer Po No": form.getFieldValue().customer_po,
          "Authorized Number": v.AuthorizedNumber,
          Memo: form.getFieldValue().memo,
          Warehouse: v.WAREHOUSE,
          "Shipment Type list": v.ShipmentTypelist,
          Style: v.Style,
          Color: v.COLOR,
          Size: v.Size,
          "Expected Quantity": v.ExpectedQuantity,
          "Unit Cost": v.UnitCost,
          "Product Description": v.ProductDescription,
          "Product MSRP": v.ProductMSRP,
          Comments: v.Comments,
          "Shipment Type": v.ShipmentType,
          UPC: v.UPC,

          // "Log Date": moment(v.log_date).format("MM-DD-YYYY"),
          // "PT Date": moment(v.ship_date).format("MM-DD-YYYY"),
          // "Cancel Date": moment(v.cancel_date).format("MM-DD-YYYY"),
          // Customer: v.addressee,
          // "SO #": v.order_header_no,
          // "PO #": v.reference_no,
          // Qty: v.quantity,
          // Amount: v.gross_amount,
          // Freight: v.shipping_cost,
          // "Net Amount": v.net_amount,
          // Term: v.term_name,
          // "Sale Person": v.salesperson_name,
          // Region: v.region_name,
          // "PT Status": v.pt_status_name,
          // Approved: v.is_approved ? "Yes" : "No",
          // "Customer Email": v.email,
          // "Customer Phone": v.phone,
          // Comment: v.cs_comment,
        };
      })
    );
  }

  const onSubmit = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.packing_id) {
          // notification("error","Please Insert SKU");
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert SKU.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          console.log(form.getFieldValue().company, 'form.getFieldValue().company');
          let final_data = controlSheet.map((e)=> {
            e.Company = form.getFieldValue().company;
            e.ADDRESS1 = form.getFieldValue().address1;
            e.ADDRESS2 = form.getFieldValue().address2;
            e.CITY = form.getFieldValue().city;
            e.zip = form.getFieldValue().zip;
            e.COUNTRY = form.getFieldValue().country;
            e.ExpectedDate = !form.getFieldValue().startDate
            ? moment().format("MM-DD-YYYY")
            : moment(form.getFieldValue().startDate).format("MM-DD-YYYY");
            e.Carrier = form.getFieldValue().carrier;
            e.Awbno = form.getFieldValue().awb;
            e.CustomerPoNo = form.getFieldValue().customer_po;
            e.Memo = form.getFieldValue().memo;

            return e
          })

          console.log(final_data, 'final_data');
          dispatch(sendPackingList(token, final_data)).then((res) => {
            if (res.type == "GET_SEND_PACKING_LIST_SUCCESS") {
              notification("success", "Packing List Send Successfully");
            }
            // setLoading(false);
            // setControlSheet(res?.payload?.data);
            // downloadExcelFileData(res?.payload?.data);
          });
          // setLoading(true);
          // dispatch(sendProduct(token, values)).then((res) => {
          //   if (res.type == "GET_SEND_PRODUCT_SUCCESS") {
          //     notification("success", "Style Send Successfully");
          //   }
          //   // setLoading(false);
          //   // setControlSheet(res?.payload?.data);
          //   // downloadExcelFileData(res?.payload?.data);
          // });
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchPackingList(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Packing List"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                // style={{ width: "20%" }}
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="packing_id"
                            label="F. Packing ID"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            label="Expected Date"
                            name="startDate"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Start Date is required",
                            //   },
                            // ]}
                          >
                            <DatePicker
                              size="small"
                              tabIndex={6}
                              format="MM-DD-YYYY"
                              allowClear
                              className={`dateInput customInputs fw-400 `}
                              onChange={(e) =>
                                setStartDate(moment(e).format("MM-DD-YYYY"))
                              }
                              defaultValue={moment(sDate)}
                              initialValue={moment(sDate)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="carrier"
                            label="Carrier"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="awb"
                            label="AWB #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="customer_po"
                            label="Customer PO#"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="memo"
                            label="Memo"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="company"
                            label="Company"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="address1"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="address2"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="city"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="zip"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="country"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // disabled= {true}
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              // onChange={sourceTypeChange}
                              className={`styleInput customInputs`}
                              // disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="brand"
                            label="Brand"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            disabled={true}
                          >
                            <Input size="small" className="styleInput customInputs" disabled={true}/>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ctn_from"
                            label="CTN From"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            defaultValue={1}
                            initialValue={1}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ctn_to"
                            label="CTN To"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            defaultValue={100}
                            initialValue={100}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Button
                            htmlType="submit"
                            type="primary"
                            className="ant-btn ant-btn-primary mrpx-20"
                            onClick={onFinish}
                          >
                            <span className="px-2">Search</span>
                          </Button>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>

              {/* {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )} */}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : controlSheet && controlSheet.length > 0 ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div>Total Items: {controlSheet.length}</div>
                <div style={{ marginLeft: "20px" }}>
                  Total Quantity: {numeral(totalQuantity()).format("0,00,0")}
                </div>
              </div>
              <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary"
                onClick={onSubmit}
              >
                <span className="px-2">Send Data</span>
              </Button>
            </div>
            <Table
              title={() => (
                <CSVLink
                  filename={"Packing-List.csv"}
                  data={excelData}
                  onClick={() => {}}
                >
                  Download Excel
                </CSVLink>
              )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={controlSheet}
              columns={columns}
              loading={controlLog.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
              // summary={(pageData) => {
              //   let sumQty = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.totalqty));
              //   },
              //   0);
              //   let PendingQty = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.pendingqty));
              //   },
              //   0);
              //   let AccCommited = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(
              //       currentValue.quantity_committed
              //     ));
              //   },
              //   0);
              //   let Backorder = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(
              //       currentValue.quantity_backorder
              //     ));
              //   },
              //   0);
              //   let Amount = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.amount));
              //   },
              //   0);
              //   return (
              //     <>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell colSpan={3}>
              //           Total Quantity :{" "}
              //           {numeral(totalQuantity()).format("0,00,0")}
              //         </Table.Summary.Cell>
              //         {/* <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {sumQty.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {PendingQty.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {AccCommited.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {Backorder.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       $ {Amount.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell> */}
              //       </Table.Summary.Row>
              //       {/* <Table.Summary.Row>
              //           <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
              //           </Table.Summary.Cell>
              //       </Table.Summary.Row> */}
              //     </>
              //   );
              // }}
            />
          </>
        ) : (
          // <></>
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default AsnToBergen;
