import {
  GET_ALL_SALESORDERLINE_REQUEST,
  GET_ALL_SALESORDERLINE_SUCCESS,
  GET_ALL_SALESORDERLINE_ERROR,
  DELETE_SALESORDERLINE_ERROR,
  GET_ONE_SALESORDERLINE_ERROR,
  GET_ONE_SALESORDERLINE_SUCCESS,
  DELETE_SALESORDERLINE_REQUEST,
  DELETE_SALESORDERLINE_SUCCESS,
  GET_ONE_SALESORDERLINE_REQUEST,
  GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST,
  GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS,
  GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR,
  GET_ONE_LINES_REQUEST,
  GET_ONE_LINES_SUCCESS,
  GET_ONE_LINES_ERROR,
  UPDATE_ONE_LINES_SUCCESS,
  UPDATE_ONE_LINES_REQUEST,
  UPDATE_ONE_LINES_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getAllSalesOrderLine = (token, data) => (dispatch) => {
  dispatch({ type: GET_ALL_SALESORDERLINE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/saleOrderLine`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      startDate: "1-1-2022",
      endDate: "1-31-2022",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_SALESORDERLINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_SALESORDERLINE_ERROR,
        payload: error.response,
      });
    });
};
export const getOneSalesOrderLine = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALESORDERLINE_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/saleOrderLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALESORDERLINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALESORDERLINE_ERROR,
        payload: error.response,
      });
    });
};
export const deleteSalesOrderLine = (token, id, data) => (dispatch) => {

  data.map((val) => {
    delete val.order_header_id
    delete val.item_id
    delete val.rate
    delete val.amount
    delete val.discount_id
    delete val.discount
    delete val.tax_id
    delete val.tax_percent
    delete val.tax_amount
    delete val.net_amount
    delete val.created_by
    delete val.created_date
    delete val.last_updated_by
    delete val.last_updated_date
    delete val.deleted_by
    delete val.quantity
    delete val.quantity_billed
    delete val.quantity_packed
    delete val.quantity_rejected
    delete val.quantity_shipped
    delete val.quantity_shipped
    delete val.quantity_committed
    delete val.quantity_backorder
    delete val.item_code
    delete val.parent_item_id
    delete val.wash_id
    delete val.wash_name
    delete val.style_id
    delete val.style_name
    delete val.discount_percent
    delete val.commit_status
    delete val.gender_category_name
    delete val.quantity_available
    delete val.inseam
    delete val.size
    delete val.isSelected
    delete val.quantity_picked
    delete val.deleted_date
    // [{"order_lines_id":3206""gender_category_name":"W-Regular Shorts","quantity_available":100,"inseam":"-","size":"24","isSelected":true}]
  })
  dispatch({ type: DELETE_SALESORDERLINE_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/deleteThroughHeader/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: data }
  })
    .then((response) => {
      return dispatch({
        type: DELETE_SALESORDERLINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SALESORDERLINE_ERROR,
        payload: error.response,
      });
    });
};

export const getOnesaleOrderRelatedRecord = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/saleOrderRelatedRecord/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR,
        payload: error.response,
      });
    });
};

export const getOneItemLines =
  (token, order_header_id, item_id) => (dispatch) => {
    dispatch({ type: GET_ONE_LINES_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/getOneLines`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        order_header_id: order_header_id,
        item_id: item_id,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_ONE_LINES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ONE_LINES_ERROR,
          payload: error.response,
        });
      });
  };

export const updateOneItemLines = (token, data, id) => (dispatch) => {


  const [
    already,
    amount,
    commit_status,
    created_by,
    created_date,
    deleted_by,
    deleted_date,
    discount,
    discount_id,
    discount_percent,
    gender_category_name,
    inseam,
    isSelected,
    item_code,
    item_id,
    last_updated_by,
    last_updated_date,
    net_amount,
    order_header_id,
    order_lines_id,
    parent_item_id,
    quantity,
    quantity_available,
    quantity_backorder,
    quantity_billed,
    quantity_committed,
    quantity_packed,
    quantity_picked,
    quantity_rejected,
    quantity_shipped,
    rate,
    size,
    style_id,
    style_name,
    tax_amount,
    tax_id,
    tax_percent,
    wash_id,
    wash_name,
  ] = data;

  dispatch({ type: UPDATE_ONE_LINES_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/saleOrderLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      order_header_id: data[0].order_header_id,
      item_id: data[0].item_id,
      rate: data[0].rate,
      discount_id: data[0].discount_id,
      discount: data[0].discount,
      tax_id: data[0].tax_id,
      tax_percent: data[0].tax_percent,
      tax_amount: data[0].tax_amount,
      net_amount: data[0].net_amount,
      quantity: data[0].quantity,
      quantity_billed: data[0].quantity_billed,
      quantity_packed: data[0].quantity_packed,
      quantity_picked: data[0].quantity_picked,
      quantity_rejected: data[0].quantity_rejected,
      quantity_shipped: data[0].quantity_shipped,
      style_id: data[0].style_id,
      wash_id: data[0].wash_id,
      quantity_committed: data[0].quantity_committed,
      quantity_backorder: data[0].quantity_backorder,
      discount_percent: data[0].discount_percent,
      amount: data[0].amount,
      commit_status: data[0].commit_status,
      new_rate: data[0].new_rate,
      // already: data[0].already,
      // amount: data[0].amount ,
      // commit_status: data[0].commit_status,
      // created_by: data[0].created_by,
      // created_date: data[0].created_date,
      // deleted_by: data[0].deleted_by,
      // deleted_date: data[0].deleted_date,
      // discount: data[0].discount,
      // discount_id: data[0].discount_id,
      // discount_percent: data[0].discount_percent,
      // gender_category_name: data[0].gender_category_name,
      // inseam: data[0].inseam,
      // isSelected: data[0].isSelected,
      // item_code: data[0].item_code,
      // item_id: data[0].item_id,
      // last_updated_by: data[0].last_updated_by,
      // last_updated_date: data[0].last_updated_date,
      // net_amount: data[0].net_amount,
      // order_header_id: data[0].order_header_id,
      // order_lines_id: data[0].order_lines_id,
      // parent_item_id: data[0].parent_item_id,
      // quantity: data[0].quantity,
      // quantity_available: data[0].quantity_available,
      // quantity_backorder: data[0].quantity_backorder,
      // quantity_billed: data[0].quantity_billed,
      // quantity_committed: data[0].quantity_committed,
      // quantity_packed: data[0].quantity_packed,
      // quantity_picked: data[0].quantity_picked,
      // quantity_rejected: data[0].quantity_rejected,
      // quantity_shipped: data[0].quantity_shipped,
      // rate: data[0].rate,
      // size: data[0].size,
      // style_id: data[0].style_id,
      // style_name: data[0].style_name,
      // tax_amount: data[0].tax_amount,
      // tax_id: data[0].tax_id,
      // tax_percent: data[0].tax_percent,
      // wash_id: data[0].wash_id,
      // wash_name: data[0].wash_name,
    },
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_ONE_LINES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_ONE_LINES_ERROR,
        payload: error.response,
      });
    });
};
