import {
    GET_ADMIN_CURRENCY_ERROR, GET_ADMIN_CURRENCY_REQUEST, GET_ADMIN_CURRENCY_SUCCESS,
    ADD_ADMIN_CURRENCY_ERROR, ADD_ADMIN_CURRENCY_REQUEST, ADD_ADMIN_CURRENCY_SUCCESS,
    UPDATE_ADMIN_CURRENCY_ERROR, UPDATE_ADMIN_CURRENCY_REQUEST, UPDATE_ADMIN_CURRENCY_SUCCESS,
    DELETE_ADMIN_CURRENCY_REQUEST, DELETE_ADMIN_CURRENCY_SUCCESS, DELETE_ADMIN_CURRENCY_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getAdminCurrency = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_ADMIN_CURRENCY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminCurrency`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_CURRENCY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ADMIN_CURRENCY_ERROR,
                payload: error.response,
            });
        });
};


export const addAdminCurrency = (token, data, menu_id) => (dispatch) => {
    // 
    dispatch({ type: ADD_ADMIN_CURRENCY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminCurrency`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { currency_name: data.adminCurrencyName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_ADMIN_CURRENCY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_CURRENCY_ERROR,
                payload: error.response,
            });
        });
};

export const updateAdminCurrency = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_CURRENCY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminCurrency/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { currency_name: data.adminCurrencyName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_ADMIN_CURRENCY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_CURRENCY_ERROR,
                payload: error.response,
            });
        });
};

export const deleteAdminCurrency = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_CURRENCY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminCurrency/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_CURRENCY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_CURRENCY_ERROR,
                payload: error.response,
            });
        });
};

