import {
    GET_PAYMENT_DATA_ERROR,
    GET_PAYMENT_DATA_REQUEST,
    GET_PAYMENT_DATA_SUCCESS,
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getPaymentReport = (token, data) => (dispatch) => {
    // 
    dispatch({ type: GET_PAYMENT_DATA_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funcGetPaymentOmsReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: moment(data.from_date).format("YYYY-MM-DD"),
            to_date: moment(data.to_date).format("YYYY-MM-DD"),
            // gender_id: data.gender_id === "All" ? null : data.gender_id,
            // date_type: data.date_type,
            // term_id: data.term_id,
            // region_id: data.region_id === "All" ? null : data.region_id,
            company_id: data.company_id
        }
    })
        .then((response) => {
            return dispatch({ type: GET_PAYMENT_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_PAYMENT_DATA_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_PAYMENT_DATA_ERROR,
                payload: error.response,
            });
        });
};
