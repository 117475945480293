import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import Swal from "sweetalert2";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-loader-spinner";
import * as XLSX from "xlsx/xlsx.mjs";
import notification from "../../utils/notification";
import { sendBergenEdi } from "../../redux/actions/sendBergenEdi.action";
const { Option } = Select;

const menuId = ReadCookie("menuId");
const userName = ReadCookie("name");
const company_id = ReadCookie("defaultCompany");

const BergenEdi = () => {
  TabTitle("Bergen Edi");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog, saleOrderFormData } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);
  const [sDate, setStartDate] = useState(moment());
  const [excel, setExcel] = useState([]);
  const [excelHeader, setExcelHeader] = useState([]);
  const [count, setCount] = useState();
  console.log(controlSheet, "bergen edi");
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Store",
      // render: (text, record) => {
      //   return <>{form.getFieldValue().address2}</>;
      // },
      dataIndex: "Store",
      key: "Store",
    },
    {
      title: "Customer PO Number",
      dataIndex: "CustomerPONumber",
      key: "CustomerPONumber",
    },
    {
      title: "Payment Terms",
      dataIndex: "PaymentTerms",
      key: "PaymentTerms",
    },
    {
      title: "UPC",
      dataIndex: "UPC",
      key: "UPC",
    },
    {
      title: "Style",
      dataIndex: "Style",
      key: "Style",
    },
    {
      title: "Color",
      dataIndex: "Color",
      key: "Color",
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: "Unit Price",
      dataIndex: "UnitPrice",
      key: "UnitPrice",
    },
    // {
    //   title: "State",
    //   dataIndex: "STATE",
    //   key: "STATE",
    // },
    // {
    //   title: "Expected Date",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {!form.getFieldValue().startDate
    //           ? moment().format("MM-DD-YYYY")
    //           : moment(form.getFieldValue().startDate).format("MM-DD-YYYY")}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Carrier",
    //   // dataIndex: "Carrier",
    //   // key: "Carrier",
    //   render: (text, record) => {
    //     return <>{form.getFieldValue().carrier}</>;
    //   },
    // },
    // {
    //   title: "Tracking Numbers",
    //   // dataIndex: "Awbno",
    //   // key: "Awbno",
    //   render: (text, record) => {
    //     return <>{form.getFieldValue().awb}</>;
    //   },
    // }
  ];

  useEffect(() => {
    form.setFieldsValue({
      company: "ARTISTIC DENIM MILLS.LTD",
      brand: "DL1961 PREMIUM DENIM",
      address1: "PLOT NO. 5-9,23-26, SECTOR-16",
      address2: "KORANGI INDUSTRIAL AREA",
      city: "KARACHI",
      zip: "",
      country: "PAKISTAN",
    });
  }, []);
  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        console.log(values, "val");
        excel[0].customerId = values.customerID;

          console.log(excel, "excel");
          notification("success", "Order Process Started");
          setLoading(true);
          dispatch(sendBergenEdi(token, excel)).then((res) => {
            setLoading(false);
            notification("success", "Order Placed Successfully");
            // setControlSheet(res?.payload?.data);
            // // setControlSheet(values);
            // downloadExcelFileData(res?.payload?.data);
          });
          

        // if (!values.packing_id) {
        //   // notification("error","Please Insert SKU");
        //   Swal.fire({
        //     icon: "error",
        //     title: "OOPS",
        //     text: `Please Insert Packing ID.`,
        //     confirmButtonColor: "#3c4b58",
        //   });
        // } else {
        //   console.log(values, "values");
        //   setLoading(true);
        //   dispatch(searchPackingList(token, values)).then((res) => {
        //     setLoading(false);
        //     setControlSheet(res?.payload?.data);
        //     // setControlSheet(values);
        //     downloadExcelFileData(res?.payload?.data);
        //   });
        // }
      })
      .catch((error) => {
        setLoading(false);
      });
    // setLoading(true);
    // await form
    //   .validateFields()
    //   .then((values) => {
    //     dispatch(getcontrolSheetLog(token, values)).then((res) => {
    //       setLoading(false);
    //       setControlSheet(res?.payload?.data);
    //       downloadExcelFileData(res?.payload?.data);
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  const totalQuantity = () => {
    let sumQty = excel.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.Quantity));
    }, 0);
    return sumQty;

    console.log("sumQty", sumQty);
  };

  const totalSum = () => {
    let sumAmount = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.amount));
    }, 0);
    return sumAmount;
  };

  const onSearch = async () => {
    // await form
    // .validateFields()
    // .then((values) => {
    //   if (!values.sku) {
    //     notification("error","Please Insert SKU");
    //   } else {
    //       setLoading(true);
    //       dispatch(searchPackingList(token, values)).then((res) => {
    //         setLoading(false);
    //         setControlSheet(res?.payload?.data);
    //         // downloadExcelFileData(res?.payload?.data);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Company Name": form.getFieldValue().company,
          "Address 1": form.getFieldValue().address1,
          "Address 2": form.getFieldValue().address2,
          City: form.getFieldValue().city,
          State: v.STATE,
          Zip: form.getFieldValue().zip,
          Country: form.getFieldValue().country,
          "Non US Region": v.Non_US_Region,
          Phone: v.phone,
          Fax: v.fax,
          Email: v.email,
          "Expected Date": !form.getFieldValue().startDate
            ? moment().format("MM-DD-YYYY")
            : moment(form.getFieldValue().startDate).format("MM-DD-YYYY"),
          Carrier: form.getFieldValue().carrier,
          "Tracking Numbers": form.getFieldValue().awb,
          "Driver Name": v.DriverName,
          "License Plate": v.LicensePlate,
          "Customer Po No": form.getFieldValue().customer_po,
          "Authorized Number": v.AuthorizedNumber,
          Memo: form.getFieldValue().memo,
          Warehouse: v.WAREHOUSE,
          "Shipment Type list": v.ShipmentTypelist,
          Style: v.Style,
          Color: v.COLOR,
          Size: v.Size,
          "Expected Quantity": v.ExpectedQuantity,
          "Unit Cost": v.UnitCost,
          "Product Description": v.ProductDescription,
          "Product MSRP": v.ProductMSRP,
          Comments: v.Comments,
          "Shipment Type": v.ShipmentType,
          UPC: v.UPC,

          // "Log Date": moment(v.log_date).format("MM-DD-YYYY"),
          // "PT Date": moment(v.ship_date).format("MM-DD-YYYY"),
          // "Cancel Date": moment(v.cancel_date).format("MM-DD-YYYY"),
          // Customer: v.addressee,
          // "SO #": v.order_header_no,
          // "PO #": v.reference_no,
          // Qty: v.quantity,
          // Amount: v.gross_amount,
          // Freight: v.shipping_cost,
          // "Net Amount": v.net_amount,
          // Term: v.term_name,
          // "Sale Person": v.salesperson_name,
          // Region: v.region_name,
          // "PT Status": v.pt_status_name,
          // Approved: v.is_approved ? "Yes" : "No",
          // "Customer Email": v.email,
          // "Customer Phone": v.phone,
          // Comment: v.cs_comment,
        };
      })
    );
  }

  let json;
  let jsonArray = [];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        //
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        //
        json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });

        console.log(json, "json");
        jsonArray = json;
        excel.push(jsonArray);
        setExcel(jsonArray);
        excelHeader.push(Object.keys(jsonArray[0]));
        // keys.push(Object.keys(excel[0][0]));

        const uniqueStores = {};

        // Iterate through the array and mark each unique store in the object
        jsonArray.forEach((item) => {
          uniqueStores[item.Store] = true;
        });

        // Count the unique stores
        const count = Object.keys(uniqueStores).length;
        console.log(uniqueStores, "uniqueStores");
        console.log(count, "count");
        setCount(count);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleChanges = (val) => {
    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };
  // ******** HANDLE DEBOUCE ON CUSTOMER TYPING FUNCTION *******
  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const optimizedFn = useCallback(debounce(handleChanges), []);

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Bergen Edi"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0" style={{ display: "flex" }}>
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                style={{ width: "60%" }}
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={4}>
                      <div className="customCol">
                        <Form.Item
                        //   name="sku"
                        // label="SKU"
                        // labelAlign="left"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        >
                          <input
                            className="upload_input"
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                          />
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={8} xxl={8}>
                      <div className="firstSectionRo">
                        <Form.Item
                          labelAlign="left"
                          name="customerID"
                          label="Customer"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            { required: true, message: "Customer is required" },
                          ]}
                        >
                          <Select
                            notFoundContent={
                              saleOrderFormData.loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Loader
                                    type="Oval"
                                    color="#3c4b58"
                                    height={20}
                                    width={20}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  No Data
                                </div>
                              )
                            }
                            className={"select_Inputs w-100"}
                            autoComplete={"nope"}
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            loading={saleOrderFormData.loading}
                            // onSelect={(data) => onCustomerSelect(data, true)}
                            defaultValue={form.getFieldValue().customer}
                            placeholder="Select Customer"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                          >
                            <Option value="All">All</Option>
                            {saleOrderFormData?.payload?.customerDetail.map(
                              (item) => {
                                return (
                                  <Option
                                    defaultValue={"All"}
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} - ${item.customer_name}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Bcol
                lg={6}
                xl={4}
                xxl={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <div className="relative">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="ant-btn ant-btn-primary mrpx-20"
                      onClick={onFinish}
                    >
                      <span className="px-2">Send</span>
                    </Button>
                  </div>
                </div>
              </Bcol>

              {/* {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )} */}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : excel && excel.length > 0 ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div>Total Store(s): {count}</div>
                <div style={{ marginLeft: "20px" }}>
                  Total Quantity: {numeral(totalQuantity()).format("0,00,0")}
                </div>
              </div>
              {/* <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary"
                onClick={onSubmit}
              >
                <span className="px-2">Send</span>
              </Button> */}
            </div>
            {console.log(excel, "excel")}
            <Table
              // title={() => (
              //   <CSVLink
              //     filename={"Packing-List.csv"}
              //     data={excelData}
              //     onClick={() => {}}
              //   >
              //     Download Excel
              //   </CSVLink>
              // )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={excel}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
              // summary={(pageData) => {
              //   let sumQty = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.totalqty));
              //   },
              //   0);
              //   let PendingQty = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.pendingqty));
              //   },
              //   0);
              //   let AccCommited = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(
              //       currentValue.quantity_committed
              //     ));
              //   },
              //   0);
              //   let Backorder = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(
              //       currentValue.quantity_backorder
              //     ));
              //   },
              //   0);
              //   let Amount = pageData.reduce(function (
              //     previousValue,
              //     currentValue
              //   ) {
              //     return (previousValue += parseFloat(currentValue.amount));
              //   },
              //   0);
              //   return (
              //     <>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell colSpan={3}>
              //           Total Quantity :{" "}
              //           {numeral(totalQuantity()).format("0,00,0")}
              //         </Table.Summary.Cell>
              //         {/* <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell></Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {sumQty.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {PendingQty.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {AccCommited.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       {Backorder.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell>
              //               <Table.Summary.Cell>
              //                   <p style={{ textAlign: "right" }} className="totalquantity">
              //                       $ {Amount.toLocaleString("en-US")}
              //                   </p>
              //               </Table.Summary.Cell> */}
              //       </Table.Summary.Row>
              //       {/* <Table.Summary.Row>
              //           <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
              //           </Table.Summary.Cell>
              //       </Table.Summary.Row> */}
              //     </>
              //   );
              // }}
            />
          </>
        ) : (
          // <></>
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default BergenEdi;
