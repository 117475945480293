import {
    GET_PAYMENT_STATE_REPORT_ERROR,
    GET_PAYMENT_STATE_REPORT_REQUEST,
    GET_PAYMENT_STATE_REPORT_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getPaymentStateReport = (token, selected_id, data) => (dispatch) => {


    dispatch({ type: GET_PAYMENT_STATE_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funcGetPaymentInvoiceOmsReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            // from_date: moment(data.from_date).format("YYYY-MM-DD"),
            // to_date: moment(data.to_date).format("YYYY-MM-DD"),
            // gender_id: data.gender_id === "All" ? null : data.gender_id,
            // date_type: data.date_type,
            // term_id: data.term_id === "All" ? null : data.term_id,
            // region_id: selected_id,
            // company_id: data.company_id
            payment_header_id: data.payment_header_id
        }
    })
        .then((response) => {
            return dispatch({ type: GET_PAYMENT_STATE_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_PAYMENT_STATE_REPORT_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_PAYMENT_STATE_REPORT_ERROR,
                payload: error.response,
            });
        });
};
