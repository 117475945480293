import React, { useEffect, useState, useRef } from "react";
import "./Distributor2.css";
import logo from "../../assets/images/logo-black.svg";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { useDispatch, useSelector } from "react-redux";
import { getskuloopup } from "../../redux/actions/skuLookUp.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Form, Table } from "antd";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { useDownloadExcel } from "react-export-table-to-excel";
import numeral, { Numeral } from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getIncomeDetail } from "../../redux/actions/incomeStatement.action";
import Input from "rc-input";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";

const dateFormatList = ["MM-DD-YYYY"];

const IncomeDetail = ({ token }) => {
  TabTitle("Statement Detail")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { incomeDetailStatement } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const location = useLocation();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [detailStatement, setDetailStatement] = useState([]);
  const [excelData, setExcelData] = useState([])

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  let account_name = query.get("account_name");
  let date_from = query.get("date_from");
  let date_to = query.get("date_to");


  useEffect(() => {
    if (account_name) {
      let value = {
        // order_header_id : 4696671
        account_name: account_name,
        date_from: date_from,
        date_to: date_to
      };
      setLoading(true)
      dispatch(getIncomeDetail(token, value)).then((res) => {
        setDetailStatement(res?.payload?.data)
        downloadExcelFileData(res?.payload?.data);
        setLoading(false)
      });
    }
  }, [account_name])

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Transaction Type": v.transaction_type,
          "Transaction No": v.transaction_no,
          "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
          "Debit": numeral(v.debit).format("0,00.00"),
          "Credit": numeral(v.credit).format("0,00.00"),
          "Balance": numeral(v.balance).format("0,00.00"),
        };
      })
    )
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'Transaction Type', key: 'transaction_type', width: 30 },
      { header: 'Transaction No', key: 'transaction_no', width: 30 },
      { header: 'Transaction Date', key: 'transaction_date', width: 15 },
      { header: 'Debit', key: 'debit', width: 15 },
      { header: 'Credit', key: 'credit', width: 15 },
      { header: 'Balance', key: 'balance', width: 15 },
    ];


    let totalBalance = 0;
    let totalDebit = 0;
    let totalCredit = 0;
    // Assuming 'data' is an array of objects with properties 'name', 'email', and 'age'
    incomeDetailStatement?.payload?.data.forEach((item) => {
      worksheet.addRow({ transaction_type: item.transaction_type, transaction_no: item.transaction_no, transaction_date: item.transaction_date, debit: numeral(item.debit).format("0,00.00"), credit: numeral(item.credit).format("0,00.00"), balance: numeral(item.balance).format("0,00.00") });
      totalDebit += Number(item.debit);
      totalCredit += Number(item.credit);
      totalBalance += Number(item.balance);
    });
    worksheet.addRow({ debit: numeral(totalDebit).format("0,00.00"), credit: numeral(totalCredit).format("0,00.00"), balance: numeral(totalBalance).format("0,00.00") });

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // You can modify the filename as needed
      const filename = 'Statement-Detail.xlsx'

      // Create a download link and trigger a click event to download the file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();

      console.log('Excel file created!');
    } catch (error) {
      console.error(error);
    }
  };


  function generatePDF() {
    var doc = new jsPDF();

    // Define columns and rows
    var columns = ["Transaction Type", "Transaction No", "Transaction Date", "Debit", "Credit", "Balance    "];
    var rows = [];

    // Populate rows with data
    incomeDetailStatement?.payload?.data.forEach(item => {
      var row = [item.transaction_type, item.transaction_no, item.transaction_date, "$" + numeral(item.debit).format("0,00.00"), "$" + numeral(item.credit).format("0,00.00"), "$" + numeral(item.balance).format("0,00.00")];
      rows.push(row);
    });

    const debit = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0)
    const credit = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0)
    const balance = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)



    // Add table to the PDF
    doc.autoTable({
      head: [columns],
      body: rows,

    })

    const startY = doc.lastAutoTable.finalY || 10; // Default to 10 if there is no table
    const footerRow = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "Total:", "$" + numeral(debit).format("0,00.00"), "$" + numeral(credit).format("0,00.00"), "$" + numeral(balance).format("0,00.00")];
    doc.autoTable({
      body: [footerRow],
      startY: startY + 2,
    });

    // doc.text('Total Salary')
    // Save the PDF
    doc.save("Statement-Detail.pdf");
  }

  const debit = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0)
  const credit = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0)
  const balance = incomeDetailStatement?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)

  const columns = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_type !== null) {
          return record.transaction_type
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_type !== null) & (b.transaction_type !== null)) {
          return a.transaction_type.localeCompare(b.transaction_type);
        }
      },
    },
    {
      title: "Transaction No",
      dataIndex: "transaction_no",
      key: "transaction_no",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_no !== null) {
          return record.transaction_no
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_no !== null) & (b.transaction_no !== null)) {
          return a.transaction_no.localeCompare(b.transaction_no);
        }
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transaction_date !== null) {
          return record.transaction_date
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transaction_date !== null) & (b.transaction_date !== null)) {
          return a.transaction_date.localeCompare(b.transaction_date);
        }
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.debit !== null) {
          return record.debit
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.debit !== null) & (b.debit !== null)) {
          return a.debit.localeCompare(b.debit);
        }
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.credit !== null) {
          return record.credit
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.credit !== null) & (b.credit !== null)) {
          return a.credit.localeCompare(b.credit);
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.balance !== null) {
          return record.balance
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.balance !== null) & (b.balance !== null)) {
          return a.balance.localeCompare(b.balance);
        }
      },
    },
  ];


  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="distributor header_class distributor_table">
      <div className="row justify-content-between distributr">
        {/**** / Logo *****/}
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"Statement-Detail.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgCSV"
                    alt="Export to CSV"
                    id="ImgCSV"
                    title="Export to CSV"
                    src={csv}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
              <div className="print_icon printing_class" >

                <input
                  type="image"
                  name="ImgExcel"
                  alt="Export to Excel"
                  id="ImgExcel"
                  title="Export to Excel"
                  src={xlsx}
                  onClick={exportToExcel}
                  // className="cursor-not-allowed"
                  style={{ borderWidth: "0px", height: "14px" }}
                />
              </div>
              <div className="print_icon printing_class" >
                <input
                  type="image"
                  name="ImgPDF"
                  alt="Export to PDF"
                  id="ImgPDF"
                  title="Export to PDF"
                  src={pdf}
                  onClick={generatePDF}
                  // className="cursor-not-allowed"
                  style={{ borderWidth: "0px", height: "18px" }}
                />
                {/* <button onClick={() => generatePDF()}>Generate PDF</button> */}
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Statement Detail
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>

            </span>
          </div>
        </div>
      </div>
      <hr className="mt-0 mb-2" />

      {/* Table  Data */}
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }
        className="table_content international_order"
        style={{ maxWidth: '1460px' }}
      >
        <tr>
          <td className="font_weight">
            Account Name :
          </td>
          <td style={{ width: "406px" }} colSpan={3}>
            <b className="font_weight">

              {incomeDetailStatement?.payload?.data[0].account_name}
            </b>
          </td>

        </tr>
        <div>
          {incomeDetailStatement?.payload?.data !== undefined ?
            (
              incomeDetailStatement?.payload?.data.length > 0 ? (
                <Table
                  className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={incomeDetailStatement?.payload?.data}
                  columns={columns}
                  loading={incomeDetailStatement.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {(numeral(debit).format("0,00.00")).toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {(numeral(credit).format("0,00.00")).toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                              {(numeral(balance).format("0,00.00")).toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {console.log(intransit?.payload?.data.length, 'intransit?.payload?.data.length')} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default IncomeDetail;
