import React, { useEffect, useMemo } from "react";
import SalesInvoiceReportHeader from "./SalesInvoiceReportHeader/SalesInvoiceReportHeader";
import {
  getInoviceOrderItem,
  getInvoiceColumns,
  getInvoiceColumnValue,
  getInvoicePrint,
  getInvoiceReportData,
} from "../../redux/actions/invoiceReport.action";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./SalesInvoiceReport.css";
import { Card } from "antd";
import moment from "moment";
import { TabTitle } from "../../utils/GeneralFunction";
const SalesInvoiceReport = () => {
  TabTitle("Sales-Invoice-Report")
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const { invoicePrint, invoiceColumns, invoiceColumnsValues } = useSelector(
    (state) => state
  );

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let invoice_header_id = query.get("invoice_header_id");

  useEffect(() => {
    dispatch(getInvoicePrint(token, invoice_header_id));
    dispatch(getInvoiceColumnValue(token, invoice_header_id));
    dispatch(getInvoiceColumns(token, invoice_header_id));
  }, [dispatch, menuId, token]);

  const footerData = invoicePrint && invoicePrint?.payload?.data[0];
  let groupValues =
    invoiceColumnsValues &&
    invoiceColumnsValues?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));

  const groupedColumnValue = groupValues && Object.values(groupValues);
  const myData = useMemo(() => {
    return (
      invoiceColumns &&
      invoiceColumns?.payload?.data.map((it, i) => {
        return {
          column: it,
          detail: groupedColumnValue && groupedColumnValue[i],
        };
      })
    );
  }, [groupedColumnValue, invoiceColumns]);

  return (
    <div>
      {invoiceColumnsValues?.loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : (
        <div
          // className="sales_report_view"
          className="sales_report_view"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <table>
            <thead>
              <tr>
                <td className="footer_invoice">
                  <div>
                    <SalesInvoiceReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block2">
                    <div className="sales-report-Table-data">
                      {myData?.map((size, index) => {
                        return (
                          <div key={index}>
                            <table
                              className="sales-report-table"
                              style={{ width: "99%" }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="font_weight"
                                    style={{ width: "36px" }}
                                  >
                                    S.No
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "88px",
                                    }}
                                  >
                                    SKU #
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "240px",
                                    }}
                                  >
                                    Style/Desc
                                    <span style={{ marginLeft: "5px" }}>
                                      {size?.column?.gender_category_name}
                                    </span>
                                  </td>
                                  <td
                                    key={index}
                                    className="sales_table_col font_weight text-center pl-0"
                                  >
                                    {size.column.hq23 && size.column.hq23}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq24 && size.column.hq24}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq25 && size.column.hq25}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq26 && size.column.hq26}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq27 && size.column.hq27}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq28 && size.column.hq28}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq29 && size.column.hq29}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq30 && size.column.hq30}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq31 && size.column.hq31}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq32 && size.column.hq32}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq33 && size.column.hq33}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq34 && size.column.hq34}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq35 && size.column.hq35}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq36 && size.column.hq36}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq37 && size.column.hq37}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq38 && size.column.hq38}
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Quantity
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Amount
                                  </td>
                                </tr>
                                {size.detail?.map((it, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td
                                        style={{
                                          marginLeft: "0",
                                        }}
                                      >
                                        {it.item_code}
                                      </td>
                                      <td>{it.description}</td>
                                      <td className="text-center pl-0">
                                        {it.q23 && it.q23}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q24 && it.q24}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q25 && it.q25}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q26 && it.q26}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q27 && it.q27}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q28 && it.q28}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q29 && it.q29}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q30 && it.q30}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q31 && it.q31}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q32 && it.q32}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q33 && it.q33}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q34 && it.q34}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q35 && it.q35}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q36 && it.q36}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q37 && it.q37}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q38 && it.q38}
                                      </td>
                                      <td>
                                        {it.item_quantity && it.item_quantity}
                                      </td>
                                      <td className="text-center pl-0">
                                        $ {it.rate && it.rate}
                                      </td>
                                      <td>$ {it.amount && it.amount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <table
                    className="invoice-report-table-total"
                  >
                    <tbody>
                      <tr className="font_weight">
                        <td
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                          className="font_weight total_cell"
                        >
                          Total
                        </td>
                        <td className="sum_cell" style={{ width: "48px" }}>
                          {footerData && footerData.sum_quantity}
                        </td>
                        <td
                          className="empty_cell"
                          style={{ width: "43px" }}
                        ></td>
                        <td className="total_amount" style={{ width: "70px" }}>
                          $ {footerData && footerData.sum_gross_amount}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                          className="font_weight"
                        >
                          Disc %
                        </td>
                        <td></td>
                        <td></td>
                        <td>0.00 %</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Disc Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>$ {footerData && footerData.discount}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          S.Tax Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>$ {footerData && footerData.tax_percent}</td>
                      </tr>
                      <tr>
                        <td
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }}
                        >
                          Net Amount
                        </td>
                        <td></td>
                        <td></td>
                        <td>$ {footerData && footerData.net_amount}</td>
                      </tr>
                    </tbody>
                  </table> */}

                  <table className="invoice-report-table-total">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          colSpan={3}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className="font_weight total_cell"
                        >
                          Gross Total
                        </td>

                        <td className="total_amount" style={{ width: "70px" }}>
                          $ {footerData && footerData.sum_gross_amount}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "12px",
                          }}
                          className="font_weight"
                        >
                          LESS
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Disc Amount
                        </td>

                        <td>$ {footerData && footerData.discount}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "12px",
                          }}
                          className="font_weight"
                        >
                          ADD
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Tax
                        </td>
                        <td>$ {footerData && footerData.tax_percent}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Freight
                        </td>
                        <td>$ {footerData && Number(footerData.shipping_cost).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          C. C. Charges
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="font_weight"
                          style={{
                            width: "100px",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          Net Amount
                        </td>
                        <td>$ {footerData && footerData.net_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            {console.log(footerData, "footerDatafooterDatafooterData")}
            {
              footerData && footerData.term === "CREDIT CARD" ?
                <tfoot>
                  <tr>
                    {/* test */}
                    <td
                    // className="footer2_invoice"
                    >
                      <div
                      // className="sales-report-footer"
                      >
                        <div
                        // className="order_report_footer"
                        // style={{width: "1150px"}}
                        >
                          <div style={{ float: "left", fontSize: "14px" }}>
                            Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}

                          </div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Card style={{ width: "320px" }}>
                              <p
                                className="footer_order_p"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textDecoration: "underline",
                                }}
                              >
                                Terms & Conditions
                              </p>
                              <p className="footer_order_p">
                                1) No Return of goods after 15 days of shipment.
                                <br />
                                2) Damaged goods must be returned within 10 days
                                of shipment.
                                <br />
                                3) No Returns will be accepted without RA #.
                              </p>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>

                :


                footerData && (footerData.term === "WIRE TRANSFER" || footerData.term === "CONSIGNMENT" || footerData.term === "PRE-PAYMENT" || footerData.factor_id == 2) ? (
                  <tfoot>
                    <tr>
                      <td className="footer2_invoice">
                        <div className="sales-report-footer">
                          <div
                            className="order_report_footer"
                          // style={{width: "1150px"}}
                          >
                            <div style={{ float: "left", fontSize: "14px" }}>
                              Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}
                            </div>
                            <br />
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Card style={{ width: "300px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Remit Payment To
                                </p>
                                <p className="footer_order_p">
                                  US Customer Send Payment To:
                                </p>
                                <p className="footer_order_p">
                                  Warp Weft World Inc.
                                </p>
                                <p className="footer_order_p">
                                  121 Varick Street, 4th Floor
                                </p>
                                <p className="footer_order_p">
                                  NEW YORK ,NY 10013, USA
                                </p>
                              </Card>

                              <Card style={{ width: "300px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Customer Wire Payment To:
                                </p>
                                <p className="footer_order_p">
                                  JPMorgan Chase Bank,NA.
                                </p>
                                <p className="footer_order_p">
                                  Address : 601 8thAve,New York,NY 10018
                                </p>
                                <p className="footer_order_p">
                                  Phone : (212) 695-9164
                                </p>
                                <p className="footer_order_p">
                                  Account Number : 218335361
                                </p>
                                <p className="footer_order_p">
                                  Routing Number : 021000021
                                </p>
                                <p className="footer_order_p">
                                  Swift Code : CHASUS33
                                </p>
                              </Card>

                              <Card style={{ width: "420px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Terms & Conditions
                                </p>
                                <p className="footer_order_p">
                                  1) No Return of goods after 15 days of shipment.
                                  <br />
                                  2) Damaged goods must be returned within 10 days
                                  of shipment.
                                  <br />
                                  3) No Returns will be accepted without RA #.
                                </p>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                  // "test"
                ) : footerData && footerData.factor_id == 1 ? (
                  <tfoot>
                    <tr>
                      <td className="footer2_invoice">
                        <div className="sales-report-footer">
                          <div
                            className="order_report_footer"
                          // style={{width: "1150px"}}
                          >
                            <div style={{ float: "left", fontSize: "14px" }}>
                              Due Date: {footerData && moment(footerData.due_date).format('MM/DD/YYYY')}
                            </div>
                            <br />
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Card style={{ width: "463px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  This Invoice has been sold or assigned to
                                </p>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  THE CIT GROUP / COMMERCIAL SERVICES INC.
                                </p>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  P.O BOX 1036 , CHARLOTTE,NC 28201-1036
                                </p>
                                <p className="footer_order_p">
                                  to whom the account has been assigned solely for
                                  collection
                                </p>
                                <p className="footer_order_p">
                                  and to whom notice must be given of any
                                  merchandise
                                </p>
                                <p className="footer_order_p">
                                  returns or claims.Payment made to any other party
                                  does
                                </p>
                                <p className="footer_order_p">
                                  not constitute valid payment of this invoice.
                                </p>
                              </Card>

                              <Card style={{ width: "463px" }}>
                                <p
                                  className="footer_order_p"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Terms & Conditions
                                </p>
                                <p className="footer_order_p">
                                  1) No Return of goods after 45 days of shipment.
                                  <br />
                                  2) Damaged goods must be returned within 10 days of
                                  shipment.
                                  <br />
                                  3) No Returns will be accepted without RA#.
                                  <br />
                                  4) Payments must be received within due date.
                                  <br />
                                  5) All Overdue invoices will be charged @1.8% per month.
                                  <br />
                                  6) No Return allowed on sale item.
                                </p>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>

                )
                  :
                  (
                    <></>
                  )}
          </table>
        </div>
      )}
    </div>
  );
};

export default SalesInvoiceReport;
