import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getShipping, deleteShipper, addShipper, updateShipper } from "../../redux/actions/shipping";
import { loadCountry } from "../../redux/actions/adminCountry";
import { loadState } from "../../redux/actions/adminState";
import { loadCity } from "../../redux/actions/adminCity";
import { getRights } from "../../redux/actions/userRights.action";

import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space, Checkbox, Row, Col } from "antd";

import Tables from "../../components/Table/Tables";

import { ReadCookie } from "../../utils/readCookie"
import notification from "../../utils/notification";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { addVendorShipper, deleteVendorShipper, getVendorShipping, updateVendorShipper } from "../../redux/actions/vendor_ship.action";

const { confirm } = Modal;
const { Option } = Select;

export default function VendorShipping({ customer_id, token, formType, addressDataShip }) {

  const { Shipping, City, State, userRights, country } = useSelector((state) => state);
  const [customer_idForm, setcustomer_idForm] = useState(null);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [shippingObj, setShippingObj] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [countryForm, setCountry] = useState(null);
  const [visible, setVisible] = useState(false);
  const [stateForm, setState] = useState(null);
  const [editForm, setEditForm] = useState("");
  const [shipID, setShipID] = useState(null);
  const [eventType, setEventType] = useState("");

  const [cityForm, setCity] = useState(null);
  const [count, setCount] = useState(null);
  const menuId = ReadCookie('menuId');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(true);


  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          //setFormState("table")
        }
      }
    });
  }, [])

  const shippingColumns = [
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_id",
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_id"
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_id",

    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {(userRights?.payload?.can_edit !== false && formType !== "add") &&
            <Button size="small" icon={<EditOutlined />} onClick={() => hanldeSubItems(record)} disabled={!(formType === "add" || formType === "edit")} />
          }
          {
            userRights?.payload?.can_delete !== false &&
            <Button size="small" icon={<DeleteOutlined />} onClick={() => hanldeDeleteItems(record)} disabled={!(formType === "add" || formType === "edit")}
            />
          }
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (formType === "add") {
      setShippingAddress([])
    }
    else if (customer_id != "" && formType === "formView") {
      dispatch(getVendorShipping(token, customer_id)).then((res) => {

        setShippingAddress(res?.payload?.data)
      })
      setShowTable(true);
    }
    else { setShowTable(false); }
  }, [customer_id]);

  useEffect(() => {
    dispatch(loadCountry(token));
  }, []);

  const hanldeSubItems = (record) => {
    if (formType === "edit") {
      setEventType("edit");
      setShipID(record?.vendor_ship_to_id);
    }
    setVisible(true);
    form.setFieldsValue({
      addressee: record.addressee,
      address_1: record.address_1,
      address_2: record.address_2,
      address_3: record.address_3,
      email: record.email,
      fax: record.fax,
      phone: record.phone,
      zip_code: record.zip_code,
      city: record.country_id,
      state: record.state_id,
      country: record.country_id,
      is_active: record.is_active
    });
    dispatch(loadCity(token, record.country_id));
    dispatch(loadState(token, record.state_id));
  };


  const hanldeDeleteItems = (record) => {
    if (customer_id == "") {
      const dataSource = [...shippingAddress]
      const filtered = dataSource.filter((item) => item.key !== record.key)
      setShippingAddress(filtered)
    }
    else {

      confirm({
        title: 'Do you Want to delete these items?',
        icon: <ExclamationCircleOutlined />,
        content: 'Delete Shipping Record',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {


          dispatch(deleteVendorShipper(token, record.vendor_ship_to_id)).then(
            (res) => {
              if (res.type === "DELETE_VENDOR_SHIPPING_SUCCESS") {
                notification(
                  "success",
                  "Vendor Ship deleted",
                  "Vendor Ship deleted successfully!"
                );

                dispatch(getVendorShipping(token, customer_id)).then((res) => {
                  setShippingAddress(res?.payload?.data)
                });
              }
              if (res.type === "DELETE_VENDOR_SHIPPING_ERROR") {
                notification(
                  "error",
                  res?.payload?.request?.statusText,
                  res?.payload?.data?.data
                );
              }
            }
          );
        },
        onCancel() {

        },
      });


      setcustomer_idForm(null);
    }
  };

  const onChangeCountry = (value, obj) => {
    setCountry(obj);

    dispatch(loadCity(token, `${value}`));
    dispatch(loadState(token, `${value}`));

    setDisableFields(true);
  };
  const onChangeCity = (value, obj) => {
    setCity(obj);
  };
  const onChangeState = (value, obj) => {
    setState(obj);
  };

  const onSearch = (val) => {

  };

  const handleOk = async (e) => {
    form.setFieldsValue({ [e.target.name]: e.target.value });
    await form.validateFields().then((values) => {

      if (formType === "add" && eventType === "add") {
        const dummy = [
          {
            address_1: values.address_1,
            address_2: values.address_2,
            address_3: values.address_3,
            addressee: values.addressee,
            country_id: values.country,
            state_id: values.state,
            city_id: values.city,
            email: values.email,
            phone: values.phone,
            key: count,
            fax: values.fax,
            zip_code: values.zip_code,
            is_deleted: 0,
          }]
        setShippingAddress([...shippingAddress, {
          ...dummy[0], country_name: countryForm.children, country_id: countryForm.value,
          city_name: cityForm.children, city_id: cityForm.value, state_name: stateForm.children, state_id: stateForm.value
        }])
        addressDataShip([...shippingAddress, dummy[0]])

        setCount(count + 1)
        setVisible(false)
      }
      else if (formType === "edit" && eventType === "add") {
        const dummy =
        {
          address_1: values.address_1,
          address_2: values.address_2,
          address_3: values.address_3,
          addressee: values.addressee,
          country_id: values.country,
          state_id: values.state,
          is_active: values.active,
          city_id: values.city,
          email: values.email,
          phone: values.phone,
          fax: values.fax,
          zip_code: values.zip_code,
          is_deleted: 0,
        }

        const upodateValue = { ...dummy, vendor_id: customer_id }

        dispatch(addVendorShipper(token, upodateValue, "edit")).then((res) => {
          dispatch(getVendorShipping(token, customer_id)).then((res) => {
            setShippingAddress(res?.payload?.data)
          })
        })
      }
      else if (editForm === "edit" || shipID !== null) {

        const update = {
          address_1: values.addressee,
          address_2: values.address_2,
          address_3: values.address_3,
          addressee: values.addressee,
          country_id: values.country,
          state_id: values.state,
          city_id: values.city,
          email: values.email,
          phone: values.phone,
          fax: values.fax,
          zip_code: values.zip_code,
        }
        dispatch(updateVendorShipper(token, update, shipID)).then((res) => {
          if (res.type === "UPDATE_VENDOR_SHIPPING_SUCCESS") {
            dispatch(getVendorShipping(token, customer_id)).then((res) => {
              setShippingAddress(res?.payload?.data)
            })
          }
        })
      }
    });
    setVisible(false)
    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
    })
  };

  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
    });
  };

  const getModalData = () => {
    dispatch(loadCountry(token));
    setEventType("add")
    setVisible(true)
  }

  return (
    <>
      <div>
        <Modal
          // width={"40%"}
          className="modal-40"
          visible={visible}
          title="Shipping Form"
          okText="Submit"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >

            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="addressee" label="Addressee">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_1" label="Address">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_2" label="Address">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_3" label="Address">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="country" label="Country">
                    <Select
                      size="small"
                      showSearch
                      // placeholder="Select Country"
                      optionFilterProp="children"
                      value={countryForm}
                      onChange={onChangeCountry}
                      onSearch={onSearch}
                      filterOption={(input, options) =>
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {country?.payload?.data.map((country) => (
                        <Option value={country.country_id}>
                          {country.country_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="city" label="City">
                    <Select
                      size="small"
                      showSearch
                      // placeholder="Select City"
                      optionFilterProp="children"
                      value={cityForm}
                      onChange={onChangeCity}
                      onSearch={onSearch}
                    // filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {City?.payload?.data.map((city) => (
                        <Option value={city.city_id}>{city.city_name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="state" label="State">
                    <Select
                      size="small"
                      showSearch
                      // placeholder="Select State"
                      optionFilterProp="children"
                      value={stateForm}
                      onChange={onChangeState}
                      onSearch={onSearch}
                      filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {State?.payload?.data.map((state) => (
                        <Option value={state.state_id}>{state.state_name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="email" label="Email"
                    rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}>
                    <Input
                      size="small"
                      className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="fax" label="Fax">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="phone" label="Phone"
                    rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="zip_code" label="Zip" rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="Active"
                    name="is_active"
                  >
                    <Checkbox size="small" className="styleInput" checked={check} defaultChecked />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>


          </Form>
        </Modal>
      </div>

      {/* <div className="productTable"> */}
      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => getModalData()}
      />
      <Tables
        type="shipping"
        scroll={600}
        perPage={5}
        scrollX={"45rem"}
        source={shippingAddress}
        col={shippingColumns}
        load={Shipping.loading}
        paginate
      />
      {/* </div> */}

    </>
  );
}
