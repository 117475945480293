import {
	ADD_BILL_CREDIT_EXPENSE_LINE_ERROR,
	ADD_BILL_CREDIT_EXPENSE_LINE_REQUEST,
	ADD_BILL_CREDIT_EXPENSE_LINE_SUCCESS,
	DELETE_BILL_CREDIT_EXPENSE_LINE_ERROR,
	DELETE_BILL_CREDIT_EXPENSE_LINE_REQUEST,
	DELETE_BILL_CREDIT_EXPENSE_LINE_SUCCESS,
	GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_ERROR,
	GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_REQUEST,
	GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_SUCCESS,
	UPDATE_BILL_CREDIT_EXPENSE_LINE_ERROR,
	UPDATE_BILL_CREDIT_EXPENSE_LINE_REQUEST,
	UPDATE_BILL_CREDIT_EXPENSE_LINE_SUCCESS,

} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addBillCreditExpenseLine = (token, object) => (dispatch) => {
	dispatch({ type: ADD_BILL_CREDIT_EXPENSE_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/paypurchasebillcreditexpense`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_CREDIT_EXPENSE_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_CREDIT_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillCreditExpenseLine = (token, object, BillId) => (dispatch) => {
	object.map((items) => {
		delete items.account_no;
		delete items.full_name;
		delete items.quantity;
		delete items.key;
		delete items.isSelected;
		return items;
	});
	dispatch({ type: UPDATE_BILL_CREDIT_EXPENSE_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/paypurchasebillcreditexpense/${BillId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			dataLine: object,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_EXPENSE_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCreditExpenseLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/paypurchasebillcreditexpense/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillCreditExpenseLine = (token, id, deletedExpensesItems) => (dispatch) => {
	console.log(deletedExpensesItems, "deletedExpensesItems");
	dispatch({ type: DELETE_BILL_CREDIT_EXPENSE_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/paypurchasebillcreditexpense/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: deletedExpensesItems
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_EXPENSE_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};
