import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getCustomerType, addCustomerType, deleteCustomerType, updateCustomerType } from "../../redux/actions/customerType.action";
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";
import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { ReadCookie } from '../../utils/readCookie';
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import { logColumns, customerTypeColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;

export default function CustomerType({ token }) {
    TabTitle("Customer Type")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const customerTypeNameInput = useRef(null);
    const [form] = Form.useForm();
    const [customerTypeObj, setCustomerTypeObj] = useState(null);
    const dispatch = useDispatch();
    const { customerTypes, getDemoDefaultCompany, log, userRights } = useSelector(state => state)
    // const stateData = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [customerTypeName, setCustomerTypeName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');


    useEffect(() => {
        dispatch(getCustomerType(token, menuId))
        dispatch(getLog(token, { scm_customer_type: "scm_customer_type" }))
    }, [token])

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (customerTypeObj) {
            setFormState('view')

            form.setFieldsValue({
                id: customerTypeObj.customer_type_id,
                customerType: customerTypeObj.customer_type_name,
                active: customerTypeObj.is_active
            });
            setCustomerTypeName(customerTypeObj.customer_type_name)
            setCheck(customerTypeObj.is_active)


        }
    }, [customerTypeObj]);

    useEffect(() => {
        if (customerTypeName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [customerTypeName])

    const formSave = () => {
        if (formState === "add") {
            dispatch(addCustomerType(token, { customerTypeName, check }, menuId)).then((res) => {

                if (res.type === "ADD_CUSTOMER_TYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_CUSTOMER_TYPE_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    notification("success", "Customer Type Created", "Customer Type created successfully!");
                    form.setFieldsValue({
                        customerType: ""
                    })
                    setFormState("")
                    // setCheck(false)
                    setCustomerTypeName("")
                    setCustomerTypeObj(null)
                }
                dispatch(getCustomerType(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updateCustomerType(token, { customerTypeName, check }, customerTypeObj.customer_type_id)).then((res) => {
                if (res.type === "UPDATE_CUSTOMER_TYPE_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_CUSTOMER_TYPE_SUCCESS") {
                    notification("success", "Customer Type Updated", "Record updated successfully")
                }
                dispatch(getCustomerType(token, menuId))
            })


        }


    }

    const handleDelete = () => {
        if (customerTypeObj) {
            dispatch(deleteCustomerType(token, customerTypeObj.customer_type_id)).then((res) => {
                if (res.type === "DELETE_CUSTOMER_TYPE_SUCCESS") {
                    notification("success", "Customer Type Deleted", "Customer Type deleted successfully!");
                }
                if (res.type === "DELETE_CUSTOMER_TYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    customerType: ""
                })
                // setCheck(false)
                setCustomerTypeName("")
                setCustomerTypeObj(null)
                setFormState('')
                dispatch(getCustomerType(token, menuId))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            customerTypeNameInput.current.focus();
            form.setFieldsValue({
                customerType: ""
            })
            setCheck(true)
            setCustomerTypeName("")
            setCustomerTypeObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Customer Type"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="customerType" customerTypeObj={setCustomerTypeObj} scrollX={"20rem"} scroll={602} perPage={25} source={customerTypes?.payload?.data} col={customerTypeColumns} paginate load={customerTypes.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {customerTypeObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                            name="id"
                                                            initialValue={form.getFieldValue().id}
                                                        >
                                                            <Input className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>
                                                }<div className='customCols slc'>
                                                    <Form.Item
                                                        label="Cutomer Type"
                                                        name="customerType"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                        initialValue={form.getFieldValue().customerType}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={customerTypeNameInput} onChange={(e) => setCustomerTypeName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}