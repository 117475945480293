import React, { useState, useCallback } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';
import { Button, Form, Select, Input } from 'antd';
import Swal from 'sweetalert2';
import { Row as Brow, Col as Bcol } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getSaleOrderFormData } from '../../redux/actions/salesOrder.action';
import { ReadCookie } from '../../utils/readCookie';
import Loader from "react-loader-spinner";
import { getOneCustomerAddress } from '../../redux/actions/customerAddress.action';
import { getOneCustomer, updateCustomer } from '../../redux/actions/customerForm';
import notification from "../../utils/notification";

const { Option } = Select;
const CustomerHold = () => {
    const [minimize, setMinimize] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const companyId = ReadCookie("defaultCompany");
    const token = ReadCookie("token");
    const [menuId, setMenuId] = useState(53);
    const { saleOrderFormData } = useSelector((state) => state);




    const onCustomerSelect = (e, valid) => {
        if (e) {
            //   if (formState === "add") form.setFieldsValue({ berganPaymentTermId: 1 });
            //   setCustomer(e);
            dispatch(getOneCustomer(token, e)).then((res) => {

                console.log("res", res?.payload);
                if (res?.type === "GET_ONE_CUSTOMER_SUCCESS") {
                    form.setFieldsValue({
                        company: res?.payload.company_id,
                        customer_id: e,
                        customerName: res?.payload.customer_name,
                        customerType: res?.payload.customer_type_id,
                        webAddress: res?.payload.website,
                        comments: res?.payload.remarks,
                        paymentTerm: res?.payload.term_id,
                        fTaxID: res?.payload.federal_tax_id,

                        firstName: res?.payload.first_name,
                        lastName: res?.payload.last_name,
                        email: res?.payload.email_address,
                        otherEmails: res?.payload.alternate_email_address,
                        phone: res?.payload.phone_no,
                        fax: res?.payload.fax_no,
                        address: res?.payload.address,

                        orderStatus: res?.payload.order_status_id,
                        oldID: res?.payload.old_customer_code,
                        rcn: res?.payload.resale_certificate_no,

                        shippingMethod: res?.payload.shipping_method_id,
                        freightTerm: res?.payload.freight_term_id,
                        bergenBilling: res?.payload.bergan_billing_id,
                        shipVia: res?.payload.courier_service_id,
                        accountNo: res?.payload.shipping_account_no,
                        alcoholType: res?.payload.alcohol_recipient_type,
                        isApproved: res?.payload.is_approved,
                        is_active: res?.payload.is_active ? res?.payload.is_active : null,
                        isComplete: res?.payload.is_ship_complete,
                        dBillAdd: res?.payload.default_bill_address,

                    })
                }
                
            })
        }
    };


    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const handleChanges = (val) => {
        // setCustomer(val);
        dispatch(getSaleOrderFormData(token, companyId, menuId, val, "")).then(
            (res) => {
                if (
                    res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                    res?.payload.length === 0
                ) {
                    notification("warning", "Empty List");
                }
                if (res?.payload?.customerDetail?.length === 0) {
                }
            }
        );
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);


    const onFinish = async (e) => {
        await form.validateFields().then((values) => {

            dispatch(updateCustomer(token, form.getFieldValue(), form.getFieldValue().customer_id)).then((res) => {
                if (res.type === "UPDATE_CUSTOMER_ERROR") {
                    notification("error", "Update", "Something went wrong");
                }
                if (res.type === "UPDATE_CUSTOMER_SUCCESS") {
                    notification("success", "Customer update", "Customer updated successfully");

                }

            })

            console.log("FORM", form.getFieldValue());

        })

    }


    const onFinishFailed = () => {
        let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors[0]?.errors[0],
        });
    };
    return (
        <>
            <div className="styleContainer">
                <div className="styleBody" style={minimize ? {} : { height: "100%" }}>
                    <PageHeader
                        title={"Customer Hold"}
                        minimize={setMinimize}
                        check={minimize}
                    />
                    <div className="bodyStyle">
                        <Form
                            requiredMark={false}
                            autoComplete="off"
                            onFinish={onFinish}
                            form={form}
                            onFinishFailed={onFinishFailed}
                            name="basic"
                        >
                            {/* {deleteLoading ||
          saleOrderFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))} */}
                            <div className="saleOrderForm">
                                {/* <SubTitles name={"Primary Information"} /> */}
                                <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
                                    <Brow>
                                        <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                                            <Brow>

                                                <Bcol lg={6} xl={8} xxl={6}>
                                                    <div className="firstSectionRo">
                                                        <Form.Item
                                                            name="customer"
                                                            label="Customer Name"
                                                            labelAlign="left"
                                                            labelCol={{
                                                                xs: { span: 52 },
                                                                lg: { span: 52 },
                                                                xxl: { span: 4 }
                                                            }}
                                                            rules={[{ required: true, message: "Please Select Customer" }]}

                                                        >
                                                            {/* <div style={{ display: 'flex', gap: 5 }}> */}

                                                            <Select
                                                                notFoundContent={saleOrderFormData.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                                                                </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                                                                size="small"
                                                                autoComplete={"nope"}
                                                                allowClear
                                                                showSearch
                                                                optionFilterProp="children"
                                                                loading={saleOrderFormData.loading}
                                                                onSelect={(data) => onCustomerSelect(data, true)}
                                                                //   disabled={!(formState === "add" || formState === "edit")}
                                                                defaultValue={form.getFieldValue().customer}
                                                                onSearch={(value) => optimizedFn(value)}
                                                            //   ref={focusInput}
                                                            //   onMouseDownCapture={onMouseDownSelectableHack}
                                                            >
                                                                {saleOrderFormData?.payload?.customerDetail.map(
                                                                    (item) => {
                                                                        return (
                                                                            <Option
                                                                                key={item.customer_id}
                                                                                value={item.customer_id}
                                                                            >
                                                                                {" "}
                                                                                {`${item.customer_code} - ${item.customer_name}`}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>


                                                            {/* </div> */}

                                                        </Form.Item>

                                                    </div>
                                                </Bcol>

                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="firstSectionRo">
                                                        <Form.Item
                                                            name="orderStatus"
                                                            label="Order Status"
                                                            labelAlign="left"
                                                            labelCol={{ span: 10 }}
                                                            rules={[{ required: true, message: "Please Select Order Status" }]}
                                                        >
                                                            <Select
                                                                size="small"
                                                                autoComplete={"nope"}
                                                                allowClear
                                                                loading={saleOrderFormData.loading}
                                                                //   disabled={!(formState === "add" || formState === "edit")}
                                                                defaultValue={form.getFieldValue().orderStatus}
                                                                value={form.getFieldValue().orderStatus}
                                                                //   onMouseDownCapture={onMouseDownSelectableHack}
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {saleOrderFormData?.payload?.orderStatusDetail.map(
                                                                    (item) => {
                                                                        return (
                                                                            <Option
                                                                                key={item.order_status_id}
                                                                                value={item.order_status_id}
                                                                            >
                                                                                {item.status_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="firstSectionRo">
                                                        <Form.Item
                                                            name="remarks"
                                                            label="Remarks"
                                                            labelAlign="left"
                                                            labelCol={{ span: 10 }}

                                                        >
                                                            <Input
                                                                size="small"
                                                                onChange={(e) => form.setFieldsValue({ comments: e.target.value })}
                                                                className={`customInputs`}
                                                            //   disabled={!(formState === "add" || formState === "edit")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Bcol>
                                                <Bcol lg={6} xl={4} xxl={3}>
                                                    <div className="firstSectionRo">
                                                        <Button onClick={() => onFinish()}>Save</Button>
                                                    </div>
                                                </Bcol>
                                            </Brow>
                                        </Bcol>

                                    </Brow>

                                </div>



                            </div>
                        </Form>
                    </div>
                </div>
                {minimize && (
                    <div className="styleFooter">
                        {" "}
                        <Button
                            className="fw-600 b-radius-5"
                            onClick={() => setMinimize(false)}
                        >
                            {" "}
                            Sales Order{" "}
                        </Button>{" "}
                    </div>
                )}
            </div>
        </>
    )
}

export default CustomerHold;