import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
// import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, DirectPaymentID, id, customerCode, formData, userRights, transactionObj }) {
    const history = useHistory();
    const confirm = () => {
        onDelete();
    };

    return (
        <>
            <div className="crudContainer">
                <div>
                    {formType === "" ? null : (
                        <div className="buttons-div">
                            <Tag color={"green"} key={1}>
                                {formType.toUpperCase()}
                            </Tag>

                            {formType !== "view" && <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />}
                        </div>
                    )}
                </div>
                <div>


                    {/*Add Button*/}
                    {formType !== "add" ? <>
                        <Tooltip placement="bottom" title="Add" color="#fbfbfb">
                            <Button
                                disabled={(userRights.can_add ? false : true) || formType === "edit"}
                                // disabled={(formType === "add" ? true : false || userRights.can_add === true ? false : true)}
                                onClick={() => {
                                    formState("add")
                                }}
                                className="iconBtn crudBTN"
                                shape="circle"
                                icon={<PlusOutlined />}
                            />
                        </Tooltip>
                        {/*Edit Button*/}
                        <>
                            <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
                                <Button
                                    disabled={(formType === "view" || formType === "edit" ? true : false) || !userRights.can_edit}
                                    // disabled={disable || formType === "view" || formType === "add" || (userRights?.can_edit === false ? true : false) || transactionLock}
                                    onClick={() => formState("edit")}
                                    className="iconBtn crudBTN"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                />
                            </Tooltip>
                        </>


                    </> : null}


                    <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                        <Button
                            // disabled={(formType === "view") || (formType !== "edit" || formType !== "add")}
                            disabled={!((formType === "add" || formType === "edit") )}
                            onClick={() => save()}
                            className="iconBtn crudBTN"
                            shape="circle"
                            icon={<FileDoneOutlined />}
                        />
                    </Tooltip>
                </div>
            </div>
            <Divider />
        </>
    );
}
