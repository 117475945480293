import {
	ADD_EXPENSE_LINE_ERROR,
	ADD_EXPENSE_LINE_REQUEST,
	ADD_EXPENSE_LINE_SUCCESS,
	DELETE_EXPENSE_LINE_ERROR,
	DELETE_EXPENSE_LINE_REQUEST,
	DELETE_EXPENSE_LINE_SUCCESS,
	GET_ONE_EXPENSE_LINE_DETAIL_ERROR,
	GET_ONE_EXPENSE_LINE_DETAIL_REQUEST,
	GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS,
	UPDATE_EXPENSE_LINE_ERROR,
	UPDATE_EXPENSE_LINE_REQUEST,
	UPDATE_EXPENSE_LINE_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addExpenseLine = (token, object) => (dispatch) => {
	dispatch({ type: ADD_EXPENSE_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/payPurchaseBillItemExpense`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: ADD_EXPENSE_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateExpenseLine = (token, object, BillId) => (dispatch) => {
	object.map((items) => {
		delete items.account_no;
		delete items.full_name;
		delete items.quantity;
		delete items.key;
		delete items.isSelected;
		return items;
	});
	dispatch({ type: UPDATE_EXPENSE_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payPurchaseBillItemExpense/${BillId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			dataLine: object,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_EXPENSE_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneExpenseLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_EXPENSE_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillItemExpense/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_EXPENSE_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteExpenseLine = (token, id,deletedExpensesItems) => (dispatch) => {
	console.log(deletedExpensesItems,"deletedExpensesItems");
	dispatch({ type: DELETE_EXPENSE_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payPurchaseBillItemExpense/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data:deletedExpensesItems
	})
		.then((response) => {
			return dispatch({
				type: DELETE_EXPENSE_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_EXPENSE_LINE_ERROR,
				payload: error.response,
			});
		});
};
