
import {
	GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST,
	GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
	GET_BILL_DIRECT_PAYMENT_ITEM_ERROR,
	GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST,
	GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
	GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR,
	ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST,
	ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
	ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR,
	UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST,
	UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
	UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR,
	DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST,
	DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
	DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR
} from "./types"
import { BASEURL } from "../../constant/config.js";
const axios = require("axios")
export const getBillDirectPaymentItem = (token, company_id, direct_payment_header_id) => (dispatch) => {
	dispatch({ type: GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/productsSale`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { company_id, item_code:direct_payment_header_id,location_id:1 }
	})
		.then((response) => {
			return dispatch({ type: GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS, payload: response.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_BILL_DIRECT_PAYMENT_ITEM_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillDirectPaymentItem = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payBillDirectPaymentItem/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {

			return dispatch({
				type: GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR,
				payload: error.response,
			});
		});
};

export const addBillDirectPaymentItem = (token, object) => (dispatch) => {
	// object.map((item)=>{
	// 	parseInt(item.item_code)

	// })
	let data = object

	dispatch({ type: ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/payBillDirectPaymentItem`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data },
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillDirectPaymentItem = (token, orderItems, DirectPaymentID) => (dispatch) => {
	orderItems.map((item) => {
		delete item.style_name;
		delete item.cut_name;
		delete item.fabric_name;
		delete item.gender_category_name;
		delete item.gender_name;
		delete item.inseam;
		delete item.isSelected;
		delete item.quantity_available;
		delete item.quantity_backorder;
		delete item.quantity_committed;
		delete item.quantity_on_hand;
		delete item.rate;
		delete item.size;
		delete item.style_id;
		delete item.wash_id;
		delete item.wash_name;
		delete item.amount;
		delete item.item_name;
		delete item.direct_payment_header_id;
		// delete item.direct_payment_item_id;

		return item;
	});
	dispatch({ type: UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payBillDirectPaymentItem/${DirectPaymentID}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			dataLine: orderItems,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillDirectPaymentItem = (token, id, data) => (dispatch) => {
	dispatch({ type: DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payBillDirectPaymentItem/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR,
				payload: error.response,
			});
		});
};