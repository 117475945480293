import {
    GET_SKULOOKUP_DATA_REQUEST,
    GET_SKULOOKUP_DATA_SUCCESS,
    GET_SKULOOKUP_DATA_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  

  export const getskuloopUpData = (token, values) => (dispatch) => {
    dispatch({ type: GET_SKULOOKUP_DATA_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/SkuLookupData`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: values, //{sku:1122}
    })
      .then((response) => {
        return dispatch({
          type: GET_SKULOOKUP_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SKULOOKUP_DATA_ERROR,
          payload: [],
        });
        //   if (error.response.data.data === "No Record Found !") {
        //     return dispatch({
        //       type: GET_BUSSINESS_DASHBOARD_ERROR,
        //       payload: [],
        //     });
        //   }
        //   return dispatch({
        //     type: GET_REGION_ERROR,
        //     payload: error.response,
        //   });
      });
  };
  