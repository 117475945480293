import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Col, Row, Button } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import notification from "../../utils/notification";
import Tables from "../../components/Table/Tables";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
import { BASEURL } from "../../constant/config";

const Routine = () => {
    TabTitle("Routine");
    const token = ReadCookie("token");
    const [productionLoading, setProductionLoading] = useState(false);
    const [productionSuccess, setProductionSuccess] = useState(false);
    const [pkSuccess, setPkSuccess] = useState(false);
    const [pkLoading, setPKLoading] = useState(false);
    const [tblLoading, setTblLoading] = useState(false);
    const [tblSuccess, setTblSuccess] = useState(false);
    const [bGradeLoading, setBGradeLoading] = useState(false);
    const [bGradeSuccess, setBGradeSuccess] = useState(false);
    const [routineLog, setRoutineLog] = useState([]);


    useEffect(() => {
        getRoutineLog();
    }, []);


    const getRoutineLog = () => {
        axios({
            method: "POST",
            // url: "https://backend.dl1961.com/v1/getAll/Routine_stats",
            url: `${BASEURL}getAll/Routine_stats`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }).then(function (response) {
            console.log(response?.data);
            setRoutineLog(response?.data?.data);
        });
    };
    const column = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Routine Name",
            dataIndex: "routine_name",
            key: "routine_name",
        },
        {
            title: "Routine Date",
            dataIndex: "r_date",
            key: "r_date",
        },
        {
            title: "Routine Time",
            dataIndex: "r_time",
            key: "r_time",
        },
        {
            title: "Total SKU",
            dataIndex: "total_skus",
            key: "total_skus",
        },
        {
            title: "Total Qty",
            dataIndex: "total_quantity",
            key: "total_quantity",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text === true ? "True" : "False";
            },
        },
        {
            title: "Status",
            dataIndex: "user_name",
            key: "user_name",
        },
    ];
    const getProductionStock = () => {
        setProductionLoading(true);
        axios
            .get("https://migration.dl1961.com/api/mssql/moveProdutionToWW")
            .then(function (response) {
                console.log(response);
                // handle success
                if (response.status === 200) {
                    setProductionLoading(false);
                    setProductionSuccess(true);
                    notification("success", "Production Stock", "Production Stock Updated Successfully");
                    getRoutineLog();
                }
            })
            .catch(function (error) {
                setProductionSuccess(false);
                setProductionLoading(false);
                // handle error
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Production Stock Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };
    const getPKStock = () => {
        setPKLoading(true);
        axios
            .get("https://migration.dl1961.com/api/mssql/movePKStockToWW")
            .then(function (response) {
                // handle success
                if (response.status === 200) {
                    setPKLoading(false);
                    setPkSuccess(true);
                    getRoutineLog();
                    notification("success", "PK Stock", "PK Stock Updated Successfully");
                }
            })
            .catch(function (error) {
                // handle error
                setPKLoading(false);
                setPkSuccess(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "PK Stock Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };

    const getTblProduction = () => {
        setTblLoading(true);
        axios
            .get("https://migration.dl1961.com/api/mssql/moveTBLProductionToWW")
            .then(function (response) {
                // handle success
                if (response.status === 200) {
                    setTblLoading(false);
                    setTblSuccess(true);
                    getRoutineLog();
                    notification("success", "TBL Order", "TBL Order Successfully");
                }
            })
            .catch(function (error) {
                // handle error
                setTblLoading(false);
                setTblSuccess(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "TBL Order Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };



    const getBGrade = () => {
        setBGradeLoading(true);
        axios
            .get("https://migration.dl1961.com/api/mssql/moveMSSQLBGradeStockWW")
            .then(function (response) {
                // handle success
                if (response.status === 200) {
                    setBGradeLoading(false);
                    setBGradeSuccess(true);
                    getRoutineLog();
                    notification("success", "TBL Order", "TBL Order Successfully");
                }
            })
            .catch(function (error) {
                // handle error
                setBGradeLoading(false);
                setBGradeSuccess(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "TBL Order Failed",
                });
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };
    return (
        <>
            <Row gutter={[18, 12]}>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: productionSuccess ? "green" : "#3D4B58", color: "#ffff" }} icon={productionSuccess && <CheckOutlined />} size="large" loading={productionLoading} onClick={() => getProductionStock()}>
                            Production Stock
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: pkSuccess ? "green" : "#3D4B58", color: "#ffff" }} icon={pkSuccess && <CheckOutlined />} size="large" loading={pkLoading} onClick={() => getPKStock()}>
                            PK Stock
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: tblSuccess ? "green" : "#3D4B58", color: "#ffff" }} icon={tblSuccess && <CheckOutlined />} size="large" loading={tblLoading} onClick={() => getTblProduction()}>
                            TBL production
                        </Button>
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div>
                        <Button style={{ background: bGradeSuccess ? "green" : "#3D4B58", color: "#ffff" }} icon={bGradeSuccess && <CheckOutlined />} size="large" loading={bGradeLoading} onClick={() => getBGrade()}>
                            B-Grade
                        </Button>
                    </div>
                </Col>
            </Row>
            <div style={{ paddingTop: "200px" }}>
                <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={routineLog} col={column} paginate />
            </div>
        </>
    );
};
export default Routine;