
import { GET_ALL_QUERY_ERROR, GET_ALL_QUERY_REQUEST, GET_ALL_QUERY_SUCCESS } from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getQueries = (token, data) => (dispatch) => {
console.log(data,"dataaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    dispatch({ type: GET_ALL_QUERY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/querydata`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            query: data
            // query: data.query
        }
    })
        .then((response) => {
            return dispatch({ type: GET_ALL_QUERY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_ALL_QUERY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_ALL_QUERY_ERROR,
                payload: error.response,
            });
        });
};
