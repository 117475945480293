import { GET_FABRICCOMPOSITION_REQUEST, GET_FABRICCOMPOSITION_SUCCESS, GET_FABRICCOMPOSITION_ERROR, ADD_FABRICCOMPOSITION_REQUEST, ADD_FABRICCOMPOSITION_ERROR, ADD_FABRICCOMPOSITION_SUCCESS, UPDATE_FABRICCOMPOSITION_REQUEST, UPDATE_FABRICCOMPOSITION_SUCCESS, UPDATE_FABRICCOMPOSITION_ERROR, DELETE_FABRICCOMPOSITION_ERROR, DELETE_FABRICCOMPOSITION_REQUEST, DELETE_FABRICCOMPOSITION_SUCCESS } from "./types"

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabricComposition = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_FABRICCOMPOSITION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fabricComposition`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_FABRICCOMPOSITION_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_FABRICCOMPOSITION_ERROR, payload: error.response })
    })
}

export const addFabricComposition = (token, data) => (dispatch) => {
    // 
    dispatch({ type: ADD_FABRICCOMPOSITION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/fabricComposition`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { composition_name: data.fabricCompositionName, is_active: data.check, },
    })
        .then((response) => {
            // 
            return dispatch({ type: ADD_FABRICCOMPOSITION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FABRICCOMPOSITION_ERROR,
                payload: error.response,
            });
        });
};
export const updateFabricComposition = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FABRICCOMPOSITION_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fabricComposition/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { composition_name: data.fabricCompositionName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FABRICCOMPOSITION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FABRICCOMPOSITION_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFabricComposition = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FABRICCOMPOSITION_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fabricComposition/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            // 
            return dispatch({ type: DELETE_FABRICCOMPOSITION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FABRICCOMPOSITION_ERROR,
                payload: error.response,
            });
        });
};
