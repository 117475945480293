import {
  GET_ALL_INVOICE_HEADER_LINE_REQUEST,
  GET_ALL_INVOICE_HEADER_LINE_SUCCESS,
  GET_ALL_INVOICE_HEADER_LINE_ERROR,
  DELETE_INVOICE_HEADER_LINE_ERROR,
  DELETE_INVOICE_HEADER_LINE_SUCCESS,
  DELETE_INVOICE_HEADER_LINE_REQUEST,
  GET_ONE_INVOICE_HEADER_LINE_REQUEST,
  GET_ONE_INVOICE_HEADER_LINE_SUCCESS,
  GET_ONE_INVOICE_HEADER_LINE_ERROR,
  ADD_INVOICE_HEADER_LINE_REQUEST,
  ADD_INVOICE_HEADER_LINE_SUCCESS,
  ADD_INVOICE_HEADER_LINE_ERROR,
  GET_ONE_INVOICE_HEADER_LINE_DETAIL_REQUEST,
  GET_ONE_INVOICE_HEADER_LINE_DETAIL_SUCCESS,
  GET_ONE_INVOICE_HEADER_LINE_DETAIL_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getAllOrderShipmentLine = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_INVOICE_HEADER_LINE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/invoiceLine`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      startDate: "1-1-2022",
      endDate: "1-31-2022",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_INVOICE_HEADER_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_INVOICE_HEADER_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const addInvoiceHeaderLine = (token, object) => (dispatch) => {
  dispatch({ type: ADD_INVOICE_HEADER_LINE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/invoiceLine`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: object,
  })
    .then((response) => {
      return dispatch({
        type: ADD_INVOICE_HEADER_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_INVOICE_HEADER_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const getOneInvoiceLine = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_INVOICE_HEADER_LINE_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/invoiceLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_INVOICE_HEADER_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_INVOICE_HEADER_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const getOneInvoiceLineDetail = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_INVOICE_HEADER_LINE_DETAIL_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/getInvoiceLineDetail/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_INVOICE_HEADER_LINE_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_INVOICE_HEADER_LINE_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const deleteInvoiceLine = (token, data, id) => (dispatch) => {
  dispatch({ type: DELETE_INVOICE_HEADER_LINE_REQUEST });

  // data.map((val) => {
	// 	delete val.available_quantity
	// 	delete val.balance
	// 	delete val.created_by
	// 	delete val.created_date
	// 	delete val.deleted_by
	// 	delete val.deleted_date
	// 	delete val.discount
	// 	delete val.discount_id
	// 	delete val.discount_percent
	// 	delete val.fulfill
	// 	delete val.gross_amount
	// 	delete val.item_code
	// 	delete val.item_id
	// 	delete val.key
	// 	delete val.last_updated_by
	// 	delete val.last_updated_date
	// 	delete val.net_amount
	// 	delete val.order_line_id
	// 	delete val.order_quantity
	// 	delete val.parent_item_id
	// 	delete val.quantity
	// 	delete val.rate
	// 	delete val.ship_header_id
	// 	delete val.style_id
	// 	delete val.style_name
	// 	delete val.tax_amount
	// 	delete val.tax_id
	// 	delete val.tax_percent
	// 	delete val.wash_id
	// 	delete val.wash_name
	// })

  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/invoiceLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: data }
  })
    .then((response) => {
      return dispatch({
        type: DELETE_INVOICE_HEADER_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_INVOICE_HEADER_LINE_ERROR,
        payload: error.response,
      });
    });
};
