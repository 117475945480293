import { Button, Input, Table } from 'antd';
import React, { useState } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASEURL } from "../../constant/config.js";
import { ReadCookie } from '../../utils/readCookie';
const { TextArea } = Input;

const DoNotCommit = () => {
    const token = ReadCookie("token");
    const [value, setValue] = useState("");


    const doNotCommitHandler = () => {
        if (!value) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Enter Your Order Number",
            });
        } else {
            const val = value.split(',');
            val.map((v) => {
                axios({
                    method: "POST",
                    url: `${BASEURL}getAll/SaleOrderDoNotCommit`,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                    data: { order_header_no: v }
                }).then((res) => {
                    console.log("RESULT", res);
                }).catch((error) => {
                    console.log("RESULT ERROR", error);
                })
            })
        }
    }

    const CommitHandler = () => {
        if (!value) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Enter Your Order Number",
            });
        } else {
            const val = value.split(',');
            val.map((v) => {
                console.log("value", v)
            })
        }
    }



    const arr = [
        {
            time: "8978",
            name: "ABC",
        }
    ]



    const columns = [
        {
            title: "Time",
            dataIndex: "time",
        },


    ];






    return (
        <div>
            <TextArea
                placeholder="Enter Your Order Numbers with Comma Seperated"
                autoSize={{ minRows: 14 }}
                style={{ width: 400 }}
                onChange={(e) => setValue(e.target.value)}
            />
            {/* <Input size="small" placeholder='Do Not Commit' style={{ width: 300 }}
                onChange={(e) => setValue(e.target.value)}
            /> */}
            <div style={{ margin: 10, display: "flex", gap: 10 }}>
                <Button onClick={CommitHandler}>Commit</Button>
                <Button onClick={doNotCommitHandler}>Do Not Commit</Button>
            </div>


            <Table
                type={"company"}
                //  loading={ProductSale.loading}
                scroll={({ x: 600 }, { y: 400 })}
                size="small"
                bordered
                dataSource={arr}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}

export default DoNotCommit;