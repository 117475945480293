import React, { useEffect, useState, useRef, useCallback } from "react";
import "../Distributor/Distributor.css"
import logo from "../../assets/images/logo-black.svg";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductType } from "../../redux/actions/productType.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Select, DatePicker, Form, Input } from "antd";
import { getStyle } from "../../redux/actions/style";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getNewSeason } from "../../redux/actions/newSeason.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-loader-spinner";
import { getRegion } from "../../redux/actions/region.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSeason } from "../../redux/actions/season.action";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getStyleWiseShippedReport } from "../../redux/actions/shippedStyleWiseReport.action"
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";


const ShippedStyleWise = ({ token }) => {
    TabTitle("Shipped")
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { style, gender, salePersonForm, adminLocation, saleOrderFormData, region, shippedStyleWise } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const company_id = ReadCookie("defaultCompany");
    const [BGradechecked, setBGradeChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState([]);
    const [allSesion, setAllSesion] = useState(true);
    const [steps, setSteps] = useState([]);
    const stepsArr = [1, 17, 18, 25, 2, 29];
    const [pendingChecked, setPendingChecked] = useState(true);
    const [stepsChecked, setStepChecked] = useState([]);
    const tableRef = useRef(null);
    const dateFormat = "MM-DD-YYYY";

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Shipped Style Wise",
        sheet: "Shipped Style Wise",
    });

    useEffect(() => {
        if (adminLocation?.payload?.data?.length > 0) {
            setSteps(
                adminLocation?.payload?.data
                    .filter((val) => stepsArr.includes(val.location_id))
                    .map((it) => ({ ...it, selected: true }))
            );
        }
    }, [adminLocation]);

    const stepschecked =
        steps && steps?.filter((it) => it.selected)?.map((it) => it.location_id);


    let result =
        shippedStyleWise &&
        shippedStyleWise?.payload?.data?.reduce(function (r, a) {
            r[a.sku] = r[a.sku] || [];
            r[a.sku].push(a);
            return r;
        }, Object.create(null));

    const wiseStockReport = result && Object.values(result);

    const { Option } = Select;

    const onFinish = async (e) => {
        await form.validateFields().then((values) => {
            //  **** get Last Index of the current Year ****
            // values.pendingDate = pendingDate;
            values.BGradeStock = BGradechecked;
            values.stepsChecked = stepsChecked;
            values.isChecked = isChecked;
            if (allSesion) {
                values.allSesion = allSesion;
            };


            if (pendingChecked) {
                stepschecked.push(-1);
            }
            const params = {
                // season_id: isChecked.length === 0 ? null : isChecked.toString(),
                season_id: null,
                style_id: values.style === "All" ? null : values.style,
                region_id: values.region === "All" ? null : values.region,
                gender_id: values.gender === "All" ? null : values.gender,
                customer_id: values.customer === "All" ? null : values.customer,
                sales_person_id: values.sp === "All" ? null : values.sp,
                from_date: moment(values.fromDate).format("YYYY-MM-DD"),
                to_date: moment(values.toDate).format("YYYY-MM-DD"),
                sku: values.sku ? values.sku : null,
            };
            dispatch(getStyleWiseShippedReport(token, params));
            setLoading(true);
        });
    };

    useEffect(() => {
        dispatch(getStyle(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getProductType(token, menuId));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getNewSeason(token, menuId));
        dispatch(getProductStatus(token, menuId));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getSeason(token, menuId));
    }, [dispatch, menuId, token]);

    useEffect(() => {
        if (allSesion) {
            setIsChecked([]);
        }
    }, [allSesion])


    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const handleChanges = (val) => dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""))

    const optimizedFn = useCallback(debounce(handleChanges), []);

    return (
        <div className=" header_class business_header_oms">
            <div className="row justify-content-between business_class_oms distributor">
                <div className="col-sm-3 d-flex align-items-sm-center box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                <div className="col-4 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                        <div className=" d-flex justify-content-end mr-8">
                            <div
                                className="print_icon printing_class"
                                name="ImgExcel"
                                alt="Export to excel"
                                id="ImgExcel"
                                title="Export to excel"
                                src={xlsx}
                            >

                                {wiseStockReport ? (
                                    <input
                                        type="image"
                                        name="ImgExcel"
                                        alt="Export to excel"
                                        id="ImgExcel"
                                        title="Export to excel"
                                        src={xlsx}
                                        onClick={onDownload}
                                        style={{
                                            borderWidth: "0px",
                                            height: "14px",
                                            cursor: "pointer",
                                        }}
                                    />
                                ) : (
                                    <input
                                        type="image"
                                        name="ImgExcel"
                                        alt="Export to excel"
                                        id="ImgExcel"
                                        title="Export to excel"
                                        src={xlsx}
                                        style={{ borderWidth: "0px", height: "14px" }}
                                    />
                                )}
                            </div>
                        </div>
                        <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                            Shipped Style Wise
                            <br />
                            Rpt # 489
                            <br />
                            Login as:{" "}
                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                {userName}
                            </span>
                        </span>
                    </div>

                </div>
            </div>

            {/*  */}
            <div className="business_class_oms mb-2 distributor">
                {/* Table Selects */}
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className="main_form row distributor">
                        <div className=" col-12">
                            <hr className="my-1" />

                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">Customer</label>
                                        <div className=" w-100">
                                            <Form.Item
                                                name="customer"
                                                initialValue={"All"}
                                            >
                                                <Select
                                                    notFoundContent={saleOrderFormData.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                                                    </div> :
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                            No Data
                                                        </div>
                                                    }
                                                    className={"select_Inputs"}
                                                    autoComplete={"nope"}
                                                    allowClear
                                                    size="small"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    loading={saleOrderFormData.loading}
                                                    // onSelect={(data) => onCustomerSelect(data, true)}
                                                    defaultValue={form.getFieldValue().customer}
                                                    placeholder="Select Customer"
                                                    onSearch={(value) => {
                                                        optimizedFn(value);
                                                    }}
                                                >
                                                    <Option value="All">All</Option>
                                                    {saleOrderFormData?.payload?.customerDetail.map(
                                                        (item) => {
                                                            return (
                                                                <Option
                                                                    defaultValue={"All"}
                                                                    key={item.customer_id}
                                                                    value={item.customer_id}
                                                                >
                                                                    {" "}
                                                                    {`${item.customer_code} - ${item.customer_name}`}
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Second */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">Region</label>
                                        <div className=" w-100">
                                            <Form.Item initialValue={"All"} name="region">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Region"
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    
                                                >
                                                    <Option value="All">All</Option>
                                                    {region &&
                                                        region?.payload?.data.map((res, index) => {
                                                            return (
                                                                <Option
                                                                    defaultValue={"All"}
                                                                    key={res.region_id} value={res.region_id}>
                                                                    {res.region_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Third */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">Sales Person</label>
                                        <div className=" w-100">
                                            <Form.Item initialValue={"All"} name="sp">
                                                <Select
                                                    loading={salePersonForm.loading}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Sales Person"
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value!=="All"?
                                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                    }
                                                >
                                                    <Option value="All">All</Option>
                                                    {salePersonForm &&
                                                        salePersonForm?.payload?.data.map((res, index) => {
                                                            return (
                                                                <Option
                                                                    defaultValue={"All"}
                                                                    key={res.sales_person_id} value={res.sales_person_id}>
                                                                    {res.salesperson_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Fourth */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">SKU</label>
                                        <div className="full_date">
                                            <Form.Item name="sku" className="sku_input">
                                                <Input
                                                    size="small"
                                                    placeholder="Enter Sku"
                                                    className={"select_Inputs"}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">Gender</label>
                                        <div className=" w-100">
                                            <Form.Item initialValue={"All"} name="gender">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Gender"
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    <Option value="All">All</Option>
                                                    {gender &&
                                                        gender?.payload?.data.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        defaultValue={"All"}
                                                                        key={res.gender_id}
                                                                        value={res.gender_id}
                                                                    >
                                                                        {res.gender_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Second */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">Style</label>
                                        <div className=" w-100">
                                            <Form.Item initialValue={"All"} name="style">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Style"
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value!=="All"?
                                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                                    }
                                                >
                                                    <Option value="All">All</Option>
                                                    {style &&
                                                        style?.payload?.data.map((res) => {
                                                            return (
                                                                <Option
                                                                    defaultValue={"All"}
                                                                    key={res.style_id} value={res.style_id}>
                                                                    {res.style_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                </div>
                                {/* Third */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">From Date</label>
                                        <div className="full_date">
                                            <Form.Item
                                                name="fromDate"
                                                initialValue={moment().subtract(1, 'years')}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    defaultValue={moment().format("MM-DD-YYYY")}
                                                    format={dateFormat}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Fourth */}
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44">To Date</label>
                                        <div className="full_date">
                                            <Form.Item
                                                name="toDate"
                                                initialValue={moment().endOf('year')}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    defaultValue={moment().format("MM-DD-YYYY")}
                                                    format={dateFormat}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                        <label className=" no_visibility">none</label>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-btn-btm m-0"
                                        >
                                            Generate
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Form>
            </div>
            <div ref={tableRef} className="overflow">
                {wiseStockReport ? (
                    wiseStockReport.length > 0 ? (
                        wiseStockReport.map((data, index) => {
                            const sizes = data;
                            // 

                            let qty1 = null;
                            let qty2 = null;
                            let qty3 = null;
                            let qty4 = null;
                            let qty5 = null;
                            let qty6 = null;
                            let qty7 = null;
                            let qty8 = null;
                            let qty9 = null;
                            let qty10 = null;
                            let qty11 = null;
                            let qty12 = null;
                            let qty13 = null;
                            let qty14 = null;
                            let qty15 = null;
                            let qty16 = null;

                            for (let i = 0; i < data.length; i++) {
                                qty1 += parseInt(data[i].qty_1);
                                qty2 += parseInt(data[i].qty_2);
                                qty3 += parseInt(data[i].qty_3);
                                qty4 += parseInt(data[i].qty_4);
                                qty5 += parseInt(data[i].qty_5);
                                qty6 += parseInt(data[i].qty_6);
                                qty7 += parseInt(data[i].qty_7);
                                qty8 += parseInt(data[i].qty_8);
                                qty9 += parseInt(data[i].qty_9);
                                qty10 += parseInt(data[i].qty_10);
                                qty11 += parseInt(data[i].qty_11);
                                qty12 += parseInt(data[i].qty_12);
                                qty13 += parseInt(data[i].qty_13);
                                qty14 += parseInt(data[i].qty_14);
                                qty15 += parseInt(data[i].qty_15);
                                qty16 += parseInt(data[i].qty_16);
                            }

                            let sumQ = 0;

                            for (let i = 0; i < data.length; i++) {
                                sumQ += parseInt(data[i].sum_quantity);
                            }

                            let totalQuantity = 0;

                            for (let i = 0; i < data.length; i++) {
                                totalQuantity += parseInt(data[i].totalqty);
                            }

                            let discount = 0;

                            for (let i = 0; i < data.length; i++) {
                                discount += parseInt(data[i].discount);
                            }

                            let tax = 0;

                            for (let i = 0; i < data.length; i++) {
                                tax += parseInt(data[i].tax_amount);
                            }

                            let amount = 0;

                            for (let i = 0; i < data.length; i++) {
                                amount += parseInt(data[i].amount);
                            }

                            let freight = 0;

                            for (let i = 0; i < data.length; i++) {
                                freight += parseInt(data[i].freight_amount);
                            }


                            return (
                                <div key={index} className="fitWidth" style={{ margin: "0 auto" }}>
                                    <div className="table_data-Heading">
                                        {/* {data[0].sku} */}
                                    </div>
                                    <table className="table_distributor-data table-auto-adjust" style={{ maxWidth: "1400px" }}>
                                        <tbody>
                                            <tr className=" font_weight">
                                                <td style={{ width: "20px" }}>#</td>
                                                <td style={{ width: "50px", fontWeight: "800" }}>SKU</td>
                                                <td style={{ width: "40px" }}>Inseam</td>
                                                <td style={{ width: "90px" }}>Style</td>
                                                <td style={{ width: "60px" }}>Wash</td>
                                                <td style={{ width: "55px" }}>Invoice #</td>
                                                <td style={{ width: "70px" }}>Invoice Date</td>
                                                <td style={{ width: "40px" }}>PT #</td>
                                                <td style={{ width: "50px" }}>Order #</td>
                                                <td style={{ width: "40px" }}>PO #</td>
                                                <td style={{ width: "70px" }}>Order Date</td>
                                                <td style={{ width: "70px" }}>Start Date</td>
                                                <td style={{ width: "70px" }}>End Date</td>
                                                <td style={{ width: "100px" }}>Customer</td>
                                                <td style={{ width: "30px" }}>State</td>
                                                <td style={{ width: "70px" }}>Region</td>
                                                <td style={{ width: "80px" }}>Sales Person</td>
                                                <td style={{ width: "20px", textAlign: "right", paddingRight: "2px" }}>Qty</td>
                                                <td style={{ width: "50px", textAlign: "right", paddingRight: "2px" }}>Rate</td>
                                                <td style={{ width: "40px", textAlign: "right", paddingRight: "2px" }}>Discount</td>
                                                <td style={{ width: "40px", textAlign: "right", paddingRight: "2px" }}>Tax</td>
                                                <td style={{ width: "40px", textAlign: "right", paddingRight: "2px" }}>Amount</td>
                                                <td style={{ width: "40px",textAlign: "right", paddingRight: "2px" }}>Freight</td>
                                                <td style={{ width: "30px" }}>Term</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_1}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_2}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_3}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_4}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_5}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_6}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_7}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_8}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_9}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_10}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_11}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_12}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_13}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_14}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_15}</td>
                                                <td style={{ width: "15px",textAlign: "right", paddingRight: "2px" }}>{sizes[0].size_16}</td>
                                            </tr>
                                            {data.map((values, index) => {
                                                // const filterQuantity = data.filter(
                                                //     (val) => val.location_id === values.location_id
                                                // );

                                                const totalQuantity = data.map((qty) => {
                                                    return (
                                                        Number(qty.qty_1) +
                                                        Number(qty.qty_2) +
                                                        Number(qty.qty_3) +
                                                        Number(qty.qty_4) +
                                                        Number(qty.qty_5) +
                                                        Number(qty.qty_6) +
                                                        Number(qty.qty_7) +
                                                        Number(qty.qty_8) +
                                                        Number(qty.qty_9) +
                                                        Number(qty.qty_10) +
                                                        Number(qty.qty_11) +
                                                        Number(qty.qty_12) +
                                                        Number(qty.qty_13) +
                                                        Number(qty.qty_14) +
                                                        Number(qty.qty_15) +
                                                        Number(qty.qty_16)
                                                    );
                                                });
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{values.sku}</td>
                                                        <td>{values.inseam}</td>
                                                        <td>{values.style_name}</td>
                                                        <td>{values.wash_name}</td>
                                                        <td>{values.invoice_header_no}</td>
                                                        <td>{values.invoice_date}</td>
                                                        {/* <td>{new Date(values.invoice_date).getMonth() + 1}-{new Date(values.invoice_date).getDate()}-{new Date(values.invoice_date).getFullYear()}</td> */}
                                                        <td>{values.ship_header_no}</td>
                                                        <td>{values.order_header_no}</td>
                                                        <td>{values.reference_no}</td>
                                                        <td>{values.order_date}</td>
                                                        <td>{values.start_date}</td>
                                                        <td>{values.end_date}</td>
                                                        <td>{values.first_name}</td>
                                                        <td>{values.state_name}</td>
                                                        <td>{values.region_name}</td>
                                                        <td>{values.salesperson_name}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.totalqty}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>$ {parseFloat(values.rate).toFixed(2)}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>$ {parseFloat(values.discount).toFixed(2)}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>$ {parseFloat(values.tax_amount).toFixed(2)}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>$ {parseFloat(values.amount).toFixed(2)}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>$ {parseFloat(values.freight_amount).toFixed(2)}</td>
                                                        <td>{values.term}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_1}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_2}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_3}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_4}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_5}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_6}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_7}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_8}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_9}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_10}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_11}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_12}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_13}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_14}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_15}</td>
                                                        <td style={{textAlign: "right", paddingRight: "2px"}}>{values.qty_16}</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td colSpan="15"></td>
                                                <td></td>
                                                <td className="font-weig">{"Total Quantity"}</td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>{totalQuantity}</td>
                                                <td></td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>${discount}</td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>${tax}</td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>${numeral(amount).format("0,00,0")}</td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>${freight}</td>
                                                
                                                <td></td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty1 !== qty1 ? "" : qty1}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty2 !== qty2 ? "" : qty2}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty3 !== qty3 ? "" : qty3}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty4 !== qty4 ? "" : qty4}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty5 !== qty5 ? "" : qty5}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty6 !== qty6 ? "" : qty6}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty7 !== qty7 ? "" : qty7}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty8 !== qty8 ? "" : qty8}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty9 !== qty9 ? "" : qty9}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty10 !== qty10 ? "" : qty10}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty11 !== qty11 ? "" : qty11}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty12 !== qty12 ? "" : qty12}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty13 !== qty13 ? "" : qty13}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty14 !== qty14 ? "" : qty14}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty15 !== qty15 ? "" : qty15}
                                                </td>
                                                <td className="font-weig" style={{textAlign: "right", paddingRight: "2px"}}>
                                                    {qty16 !== qty16 ? "" : qty16}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-Data">
                            <h1>No Data</h1>
                        </div>
                    )
                ) : (
                    loading && (
                        <div className="loader" style={{ marginTop: "20px" }}>
                            <Spin size="medium" />
                        </div>
                    )
                )}
            </div>
        </div >
    );
};

export default ShippedStyleWise;
