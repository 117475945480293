import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form, Button, Modal, Checkbox, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getExpenseBill } from "../../../redux/actions/expenseBill.action";

const BillEditExpense = ({ token, companyId, mode, modalValue, itemIndex,setIsModalVisible, orderItems, setOrderItems, singleExpenseLine, setSingleExpenseLine }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { expenseBill } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selected, setSelected] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);

	const [selectAllRows, setSelectAllRows] = useState(false);

	const updateAmount = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.account_id === id) {
				if (type === "up") {
					item.amount = value;
					return item;
				}
				if (type === "down") {
					item.amount = value;
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
		setSelected(false);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.account_id);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.account_id) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.account_id)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	useEffect(() => {
		let arr = [...data];
		arr = [...arr].map((item, i) => {
			if (selectedRowKey.indexOf(item.account_id) === -1) {
				item.isSelected = false;
				return item;
			} else {
				item.isSelected = true;
				return item;
			}
		});
	}, [selectedRowKey]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		dispatch(getExpenseBill(token, companyId, selectedId)).then((res) => {
			if (res.type === "GET_EXPENSE_BILL_SUCCESS") {
				setData(res.payload);
			}
		});
	};

	const handleOk = () => {
		if (mode === "Add") {
			let filterItem = data.filter((v) => v.isSelected);
			console.log("data", data);
			setOrderItems([...orderItems, ...filterItem]);

		} else if (mode === "Edit") {
			let selectedItem = data.find((v) => v.isSelected);

			let filterItem = orderItems.filter((v, index) => {
				if (index === itemIndex) {
					v.account_id = selectedItem.account_id;
					v.account_no = selectedItem.account_no;
					v.amount = selectedItem.amount;
					v.full_name = selectedItem.full_name;
					v.quantity = selectedItem.quantity;
					v.isSelected = selectedItem.isSelected;
				}
				return { ...v }
			});
			setOrderItems(filterItem);
			// console.log("mode", mode)
		}
		setIsModalVisible(false);
		// console.log("filterItem", filterItem);
		// console.log("orderItems", orderItems);
		// console.log("DATA", data);
		// addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setSingleExpenseLine({});
	};

	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
	};


	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].isSelected = true;
				selectedRows[i].amount = selectedRows[i].hasOwnProperty("amount") ? selectedRows[i].amount : 0;
			}
			setSelected(false);
			setSelectedItems(selectedRows);
			setFilteredItems(selectedRows);
			setSelectedRowKey(selectedRowKeys);
		},
	};

	const selectAll = () => {
		if (selectAllRows) {
			let copyArr = data.map((item) => {
				item.isSelected = false;
				return { ...item }
			})
			setData(copyArr);
		} else {
			let copyArr = data.map((item) => {
				item.isSelected = true;
				return { ...item }
			})
			setData(copyArr);
		}
		setSelectAllRows(!selectAllRows);
	};


	const selectRow = (record) => {
		if (record.isSelected) {
			let copyArr = data.map((item) => {
				if (item.account_id === record.account_id) {
					item.isSelected = false;
				}
				return { ...item }
			});
			setData(copyArr);
		} else {
			let copyArr = data.map((item) => {
				if (item.account_id === record.account_id) {
					item.isSelected = true;
				}

				return { ...item }
			});
			setData(copyArr);
		}
		if (mode === "Edit") {
			let copyArr = data.map((item) => {
				if (item.account_id === record.account_id) {
					// if(data.length)
					item.isSelected = true;
				} else {
					item.isSelected = false;

				}

				return { ...item }
			});
			setData(copyArr);
		}
	}

	const columns = [
		{
			title: mode === "Add" ? (<Checkbox checked={selectAllRows} onChange={selectAll}>Select All</Checkbox>) : null,
			render: (text, record) => {
				return (
					<Checkbox checked={record.isSelected} onChange={() => selectRow(record)}></Checkbox>
					// mode === "Add" ? (
					// 	<Checkbox checked={record.isSelected} onChange={() => selectRow(record)}></Checkbox>
					// ) : (
					// 	<Radio checked={true}></Radio>
					// )

				);
			},
		},

		{
			title: "Account Id",
			dataIndex: "account_id",
		},
		{
			title: "Account No",
			dataIndex: "account_no",
		},
		{
			title: "Full Name",
			dataIndex: "full_name",
		},

		{
			title: "Amount",
			dataIndex: "amount",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						placeholder="Amount"
						onStep={(value, info) => updateAmount(info.type, record.account_id, value)}
						onChange={(value) => updateAmount("up", record.account_id, value)}
						value={record.amount}
					/>
				);
			},
		},
	];


	useEffect(() => {
		if (singleExpenseLine) {
			dispatch(getExpenseBill(token, companyId, singleExpenseLine.full_name)).then((res) => {
				if (res.type === "GET_EXPENSE_BILL_SUCCESS") {
					let copyArr = res.payload.filter((item) => {
						if(item.account_id === singleExpenseLine.account_id) {
							item.isSelected = true;
							item.amount = singleExpenseLine.amount;
							return {
								...item
							}
						}
					});
					setData(copyArr);
				}
			});
		}
	}, [singleExpenseLine])


	return (
		<Modal
			// okButtonProps={{
			// 	style: {
			// 		display: selectedItems.length > 0 ? "" : "none",
			// 	},
			// }}
			okText={`${mode} Expense`}
			destroyOnClose={true}
			width={1200}
			centered={true}
			title={`Expense Selection (${mode})`}
			visible={modalValue}
			onOk={data => handleOk(data)}
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						defaultValue={mode === "Add" ? selectedId : singleExpenseLine.full_name}
						placeholder="Search Item"
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>

				<Table
					size="small"
					loading={expenseBill.loading}
					// scroll={({ x: 600 }, { y: 400 })}
					scroll={{ x: "50rem", y: 360 }}
					// rowSelection={{ ...rowSelection }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.account_id }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default BillEditExpense;