import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesReturnHeader, deleteSaleReturnHeader, getOneSaleReturnHeader, getOneSaleReturnHeaderDetail } from "../../redux/actions/salesReturn.action";
import CrudButtons from "./components/CrudButtons";
import SaleReturnForm from "./components/Form/SaleReturnForm";
//import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";
// import Loader from "react-loader-spinner";
// import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import EditableTable from "./EditableTable";

export default function SaleReturn({ token }) {
  const { id, customerCode, shipHeaderNo } = useParams();
  const dispatch = useDispatch();
  const dCompanyId = ReadCookie("defaultCompany");
  const { salesReturnFormDataReducer, log, salesReturn } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const [saleOrderID, setSaleOrderID] = useState(id);
  const [flag, setFlag] = useState(false);
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const queryParams = new URLSearchParams(window.location.search)
  const mode = queryParams.get("mode");
  const edit = queryParams.get("edit");
  const history = useHistory();
  const startDateList = "01-1-2022"
  const endDateList = "06-01-2022";

  const filterReset = () => {
    setFormState("view");
  };

  const handleDelete = () => {
    dispatch(deleteSaleReturnHeader(token, customerCode)).then((res) => {
      if (res.type === "DELETE_SALE_RETURN_HEADER_SUCCESS") {
        dispatch(getAllSalesReturnHeader(token, startDateList, endDateList));
        notification("success", "Sale Return Header deleted", res.payload);
        setFlag(true);
        history.push('/sale-return/list')
      }
    });
  };

  const formSave = () => {
    setSubmit(true);
  };

  useEffect(() => {
    if (mode === 'add') {
      setFormState('add');
    }
  }, []);


  useEffect(() => {
    if (edit) {
      dispatch(getOneSaleReturnHeader(token, customerCode)).then((res) =>
        setOrderID(res?.payload?.order_header_id));
    }
    else {
      dispatch(getOneSaleReturnHeaderDetail(token, id));
    }
  }, []);

  return (
    <>

      <div className="styleContainer">
        <div className="styleBody" style={minimize ? {} : { height: "100%" }}>
          <PageHeader
            title={"Sale Return"}
            minimize={setMinimize}
            check={minimize}
          />
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                name={"invoice"}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
                saleOrderID={saleOrderID}
                edit={edit}
                formData={salesReturn?.payload}
                orderHeaderId={saleOrderID}
                saleReturnId={customerCode}
                customerCode={shipHeaderNo}
                shipHeaderNo={shipHeaderNo}
                orderID={orderID}
              />

              <div className="mainProductContainer">
                <SaleReturnForm
                  dCompany={dCompanyId}
                  defaultCompany={companyId}
                  companyId={setCompanyId}
                  submit={setSubmit}
                  val={submit}
                  formData={salesReturn?.payload}
                  formState={formState}
                  data={salesReturnFormDataReducer}
                  changeState={setFormState}
                  token={token}
                  checkFlag={flag}
                  setFlag={setFlag}
                  logs={log}
                  orderHeaderId={saleOrderID}
                  shipHeaderId={customerCode}
                  customerCode={shipHeaderNo}
                  shipHeaderNo={shipHeaderNo}
                  edit={edit}

                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className="fw-600 b-radius-5"
              onClick={() => setMinimize(false)}
            >
              {" "}
              Sales Order{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}