import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select, Form, Table, Spin, Button, Input, Checkbox } from "antd";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import { getAllCompany } from "../../redux/actions/companyAccess";
import logo from "../../assets/images/logo-black.svg";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDownloadExcel } from "react-export-table-to-excel";
import printJS from "print-js";
import { Excel } from "antd-table-saveas-excel";
import "./styleSheet.css";
import { header } from "./index";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Loader from "react-loader-spinner";
import { getGenderCategory, getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getGender } from "../../redux/actions/gender.action";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import { getSku } from "../../redux/actions/sku.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getSeason } from "../../redux/actions/season.action";
import { getProductInfoReport } from "../../redux/actions/productInfoReport.action";
import { getWashName } from "../../redux/actions/wash.action";
import { getFabric } from "../../redux/actions/fabric.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getProductInfoBySize } from "../../redux/actions/productInfoBySize.action";
import { CSVLink } from "react-csv";


const { Option } = Select;
const ProductInfoBySize = ({ token }) => {
  TabTitle("Product")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { gender, genderCategory, genderCategoryChange, style, Sku, styleChange, productStatus, season, productInfoBySize, wash, fabric } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [excelToogle, setExcelToogle] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isChecked2, setIsChecked2] = useState([]);
  const [excelData, setExcelData] = useState([])
  const tableRef = useRef(null);
  const company_id = ReadCookie("defaultCompany");
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  useEffect(() => {
    dispatch(getGenderCategory(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getStyle(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getSeason(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getFabric(token, menuId))
    dispatch(getProductStatus(token, menuId));
  }, [dispatch, menuId, token]);

  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      const data = {
        item_code: values.sku === "All" ? null : values.sku,
        gender_id: values.gender_id === "All" ? null : values.gender_id,
        gender_category_id: values.gender_category_id === "All" ? null : values.gender_category_id,
        style_id: values.style_id === "All" ? null : values.style_id,
        season_id: values.season === "All" ? null : values.season,
        new_season_id: values.newSeason === "All" ? null : values.newSeason,
        product_status_id: isChecked2.length > 0 ? isChecked2.toString() : null,
        // product_status_id: values.product_status_id === "All" ? null : values.product_status_id,
        wash_id: values.wash === "All" ? null : values.wash,
        fabric_id: values.fabric === "All" ? null : values.fabric,

      };
      dispatch(getProductInfoBySize(token, data)).then((res) => {
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Parent SKU": v.parent_item_code,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Season": v.season_name,
          "Season Year": v.season_year,
          "New Season": v.new_season,
          "New Season Year": v.new_season_year,
          "Product Status": v.product_status_name,
          "Gender": v.gender_name,
          "Type": v.gender_category_name,
          "size1": v.size_1,
          "size2": v.size_2,
          "size3": v.size_3,
          "size4": v.size_4,
          "size5": v.size_5,
          "size6": v.size_6,
          "size7": v.size_7,
          "size8": v.size_8,
          "size9": v.size_9,
          "size10": v.size_10,
          "size11": v.size_11,
          "size12": v.size_12,
          "size13": v.size_13,
          "size14": v.size_14,
          "size15": v.size_15,
          "size16": v.size_16
        };
      })
    )
  }

  const columns = [
    {
      title: "#",
      align: "left",
      width: 50,
      render: (text, record, index) => {
        return index + 1
      }
      
    },
    {
      title: "Parent SKU",
      dataIndex: "parent_item_code",
      key: "parent_item_code",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.parent_item_code !== null) {
          return record.parent_item_code
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.parent_item_code !== null) & (b.parent_item_code !== null)) {
          return a.parent_item_code.localeCompare(b.parent_item_code);
        }
      },
      // width: 50,

    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.inseam !== null) {
          return record.inseam
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.inseam !== null) & (b.inseam !== null)) {
          return a.inseam.localeCompare(b.inseam);
        }
      },
      // width: 50,

    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.style_name !== null) {
          return record.style_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.style_name !== null) & (b.style_name !== null)) {
          return a.style_name.localeCompare(b.style_name);
        }
      },
      // width: 50,

    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.wash_name !== null) {
          return record.wash_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.wash_name !== null) & (b.wash_name !== null)) {
          return a.wash_name.localeCompare(b.wash_name);
        }
      },
      // width: 50,
    },
    {
      title: "Season",
      dataIndex: "season_name",
      key: "season_name",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.season_name !== null) {
          return record.season_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.season_name !== null) & (b.season_name !== null)) {
          return a.season_name.localeCompare(b.season_name);
        }
      },
    },
    {
      title: "Season Year",
      dataIndex: "season_year",
      key: "season_year",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.season_year !== null) {
          return record.season_year
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.season_year !== null) & (b.season_year !== null)) {
          return a.season_year.localeCompare(b.season_year);
        }
      },
    },
    {
      title: "New Season",
      dataIndex: "new_season",
      key: "new_season",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.new_season !== null) {
          return record.new_season
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.new_season !== null) & (b.new_season !== null)) {
          return a.new_season.localeCompare(b.new_season);
        }
      },
    },
    {
      title: "New Season Year",
      dataIndex: "new_season_year",
      key: "new_season_year",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.new_season_year !== null) {
          return record.new_season_year
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.new_season_year !== null) & (b.new_season_year !== null)) {
          return a.new_season_year.localeCompare(b.new_season_year);
        }
      },
    },
    {
      title: "Product Status",
      dataIndex: "product_status_name",
      key: "product_status_name",
      align: "left",
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.product_status_name !== null) {
          return record.product_status_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.product_status_name !== null) & (b.product_status_name !== null)) {
          return a.product_status_name.localeCompare(b.product_status_name);
        }
      },
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender_name !== null) {
          return record.gender_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.gender_name !== null) & (b.gender_name !== null)) {
          return a.gender_name.localeCompare(b.gender_name);
        }
      },
    },
    {
      title: "Type",
      dataIndex: "gender_category_name",
      key: "gender_category_name",
      align: "left",
      // width: 100,
    },
    {
      title: "Size1",
      dataIndex: "size_1",
      key: "size_1",
    },
    {
      title: "Size2",
      dataIndex: "size_2",
      key: "size_2",
    },
    {
      title: "Size3",
      dataIndex: "size_3",
      key: "size_3",
    },
    {
      title: "Size4",
      dataIndex: "size_4",
      key: "size_4",
    },
    {
      title: "Size5",
      dataIndex: "size_5",
      key: "size_5",
    },
    {
      title: "Size6",
      dataIndex: "size_6",
      key: "size_6",
    },
    {
      title: "Size7",
      dataIndex: "size_7",
      key: "size_7",
    },
    {
      title: "Size8",
      dataIndex: "size_8",
      key: "size_8",
    },
    {
      title: "Size9",
      dataIndex: "size_9",
      key: "size_9",
    },
    {
      title: "Size10",
      dataIndex: "size_10",
      key: "size_10",
    },
    {
      title: "Size11",
      dataIndex: "size_11",
      key: "size_11",
    },
    {
      title: "Size12",
      dataIndex: "size_12",
      key: "size_12",
    },
    {
      title: "Size13",
      dataIndex: "size_13",
      key: "size_13",
    },
    {
      title: "Size14",
      dataIndex: "size_14",
      key: "size_14",
    },
    {
      title: "Size15",
      dataIndex: "size_15",
      key: "size_15",
    },
    {
      title: "Size16",
      dataIndex: "size_16",
      key: "size_16",
    },
  ];

  const handlePrint = () => {
    if (productInfoBySize?.payload?.data.length > 3000) {
      printJS({
        printable: productInfoBySize?.payload?.data,
        // printable: report===``,
        type: "json",
        honorColor: true,
        repeatTableHeader: true,
        documentTitle: "Report",
        properties: [
          "serial_no",
          "region_name",
          "quantity",
          "net_amount",
          "percentage",
        ],
        gridHeaderStyle:
          " border: 1px solid grey; text-align:left; padding-left:10px",
        gridStyle: "border: 1px solid grey; padding-left:10px",
        header: header,
        type: "json",
      });
    } else {
      window.print();
    }
  }

  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All"
    })
  }


  const handleStyle = (gender_category_id) => {
    dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All"
    })
  }

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All"
    })
  }

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }
  const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex printing_class mr-8">
                      <div className="print_icon printing_class" >
                        {productInfoBySize?.payload?.data.length ? (
                          <i
                            // onClick={handlePrint}
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            onClick={handlePrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            // src={btnPrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class"
                      >
                        <CSVLink
                          filename={"Product-Information-By-Size.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            // onClick={onDownload}
                            // className="cursor-not-allowed"
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        </CSVLink>
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Product Information By Size
                      {/* <br /> */}

                      <div>
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <>
                <div className="row">
                  <div className="col">
                    <Form
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className="col-12">

                          <hr className="my-1" />
                          <div>
                            <label htmlFor="">Product Status</label>
                            <div className="checkboxes">
                              <div className="checkbox_content">
                                <Form.Item name="current_status_id" className=" mb-0">
                                  <Checkbox checked={allStatus} onChange={statusSelectAll}>
                                    All
                                  </Checkbox>
                                </Form.Item>
                              </div>
                              {productStatus &&
                                productStatus?.payload?.data.map((it, index) => {
                                  return (
                                    <div key={index} className="checkbox_content">
                                      <Form.Item name="current_status_id">
                                        <Checkbox
                                          checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                                          onChange={(e) =>
                                            isCheckboxChecked2(
                                              it.product_status_id,
                                              e.target.checked
                                            )
                                          }
                                        >
                                          {it.product_status_name}
                                        </Checkbox>
                                      </Form.Item>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className='labels width40'>Gender</label>
                            <div className=" w-100">
                              <Form.Item name="gender_id" initialValue={"All"}>
                                <Select
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Gender"
                                  optionFilterProp="children"
                                  size="small"
                                  onChange={(e) => handleGender(e)}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                >
                                  <Option value={null}>All</Option>
                                  {gender &&
                                    gender?.payload?.data.map((it, index) => {
                                      return (
                                        <Option defaultValue="All" key={index} value={it.gender_id}>
                                          {it.gender_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className='labels width40'>Gender Category:</label>
                            <div className=" w-100">
                              <Form.Item initialValue={"All"} name="gender_category_id">
                                <Select
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Product Category"
                                  optionFilterProp="children"
                                  size="small"
                                  onChange={(e) => handleStyle(e)}
                                  defaultValue={form.getFieldValue().gender_category_id}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }

                                // filterOption={(input, option) =>
                                //   option.children
                                //     .toLowerCase()
                                //     .includes(input.toLowerCase())
                                // }
                                >
                                  <Option value={null}>All</Option>
                                  {
                                    genderCategoryChange &&
                                      genderCategoryChange?.payload?.data.length >= 0 ? (
                                      genderCategoryChange &&
                                      genderCategoryChange?.payload?.data.map((it, index) => {
                                        return (
                                          <Option
                                          defaultValue="All"
                                            key={index}
                                            value={it.gender_category_id}
                                          >
                                            {it.gender_category_name}
                                          </Option>
                                        );
                                      })
                                    ) : (
                                      genderCategory &&
                                      genderCategory?.payload?.data.map((it, index) => {
                                        return (
                                          <Option
                                          defaultValue="All"
                                            key={index}
                                            value={it.gender_category_id}
                                          >
                                            {it.gender_category_name}
                                          </Option>
                                        );
                                      })
                                    )
                                  }
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className='labels width40'>Style</label>
                            <div className=" w-100">
                              <Form.Item initialValue={"All"} name="style_id">
                                <Select
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select a person"
                                  optionFilterProp="children"
                                  defaultValue={form.getFieldValue().style_id}
                                  size="small"
                                  onChange={(e) => handleSku(e)}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                // filterOption={(input, option) =>
                                //   option.children
                                //     .toLowerCase()
                                //     .includes(input.toLowerCase())
                                // }
                                >
                                  <Option value={null}>All</Option>
                                  {
                                    styleChange && styleChange?.payload?.data.length >= 0 ? (
                                      styleChange && styleChange?.payload?.data.map((style, index) => {
                                        return (
                                          <Option defaultValue="All" key={index} value={style.style_id}>
                                            {style.style_name}
                                          </Option>
                                        );
                                      })
                                    ) : (
                                      style && style?.payload?.data.map((style, index) => {
                                        return (
                                          <Option defaultValue="All" key={index} value={style.style_id}>
                                            {style.style_name}
                                          </Option>
                                        );
                                      })
                                    )
                                  }
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className='labels width40'>SKU:</label>
                            <div className=" w-100">
                              <Form.Item name="sku" initialValue={"All"}>
                                <Select
                                  notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                                  </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                                  className={"select_Inputs"}
                                  showSearch
                                  defaultValue={form.getFieldValue().sku}
                                  onSearch={(value) => {
                                    optimizedFn(value)
                                  }}
                                  // placeholder="Select Gender"
                                  optionFilterProp="children"
                                  size="small"
                                //   filterSort={(optionA, optionB) =>
                                //     optionB?.value!==null?
                                //       optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                // }

                                >
                                  <Option value={null}>All</Option>
                                  {Sku &&
                                    Sku?.payload?.data.map((it, index) => {
                                      return (
                                        // <Option value="ABC">ABC</Option>
                                        <Option defaultValue="All" key={index} value={it.sku}>
                                          {it.sku}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main_form row">
                        <div className=" col-6">
                          {/* <div className=" d-flex flex-row align-items-baseline">
                            <label className='labels width40'>Product Status</label>
                            <div className=" w-100">
                              <Form.Item name="product_status_id" initialValue={"All"}>
                                <Select
                                  defaultValue={form.getFieldValue().product_status_id}
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Product Status"
                                  optionFilterProp="children"
                                  size="small"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  <Option value={null}>All</Option>
                                  {productStatus &&
                                    productStatus?.payload?.data?.map(
                                      (status, index) => {
                                        return (
                                          <Option
                                            key={index}
                                            value={status.product_status_id}
                                          >
                                            {status.size_1}
                                          </Option>
                                        );
                                      }
                                    )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div> */}
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width40">
                              New Season:
                            </label>
                            <div className=" w-100">
                              <Form.Item name="newSeason" initialValue={"All"}>
                                <Select
                                  defaultValue={form.getFieldValue().newSeason}
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Product Status"
                                  optionFilterProp="children"
                                  size="small"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                >
                                  <Option value={null}>All</Option>
                                  {season?.payload?.data.map((item) => {
                                    return (
                                      <Option defaultValue="All" key={item.season_id} value={item.season_id}>
                                        {item.season_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className='labels width40'>Season:</label>
                            <div className=" w-100">
                              <Form.Item name="season" initialValue={"All"}>
                                <Select
                                  defaultValue={form.getFieldValue().season}
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Product Status"
                                  optionFilterProp="children"
                                  size="small"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                >
                                  <Option value={null}>All</Option>
                                  {season?.payload?.data.map((item) => {
                                    return (
                                      <Option defaultValue="All" key={item.season_id} value={item.season_id}>
                                        {item.season_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width40">
                              Fabric
                            </label>
                            <div className=" w-100">
                              <Form.Item name="fabric" initialValue={"All"}>
                                <Select
                                  defaultValue={form.getFieldValue().fabric}
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Product Status"
                                  optionFilterProp="children"
                                  size="small"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                >
                                  <Option value={null}>All</Option>
                                  {fabric?.payload?.data.map((item) => {
                                    return (
                                      <Option defaultValue="All" key={item.fabric_id} value={item.fabric_id}>
                                        {item.fabric_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width40">
                              Wash
                            </label>
                            <div className=" w-100">
                              <Form.Item name="wash" initialValue={"All"}>
                                <Select
                                  defaultValue={form.getFieldValue().wash}
                                  className={"select_Inputs"}
                                  showSearch
                                  // placeholder="Select Product Status"
                                  optionFilterProp="children"
                                  size="small"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value!==null?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                }
                                >
                                  <Option value={null}>All</Option>
                                  {wash?.payload?.data.map((item) => {
                                    return (
                                      <Option defaultValue="All" key={item.wash_id} value={item.wash_id}>
                                        {item.wash_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" main_form row">
                        <div className=" col-6">

                        </div>
                        <div className=" col-6">
                          <div>
                            <Button
                              htmlType="submit"
                              type="primary"
                              className="submit-btn-btm "
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            </div>
            <div className="row" ref={tableRef}>
              <div className="col">
                <div className="maxWidth mt-3">
                  <>
                    {loading ? (
                      <div className=" w-100 d-flex align-items-center justify-content-center spin-style mt-3">
                        <Spin size='large' />
                      </div>
                    ) : (productInfoBySize?.payload?.data.length ? (
                      <>
                        <Table
                          showSorterTooltip={false}
                          scroll={{ x: "max-content" }}
                          className="w-100 test04 "
                          size="small"
                          // pagination={{ pageSize: 100 }}
                          pagination={{
                            defaultCurrent: 1,
                            defaultPageSize: 100,
                            pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000, 5000]
                          }}
                          bordered
                          columns={columns}
                          dataSource={
                            productInfoBySize?.payload?.data
                              ? productInfoBySize?.payload?.data
                              : []
                          }
                        />
                      </>) : <div
                        className="report-wrapper-main"
                        style={{
                          backgroundColor: "#CCCCCC",
                          borderWidth: "1pt",
                          borderStyle: "Dashed",
                          fontWeight: "bold",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                      No record(s)
                    </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ProductInfoBySize;
