import {
    GET_VENDOR_LEDGER_REQUEST,
    GET_VENDOR_LEDGER_SUCCESS,
    GET_VENDOR_LEDGER_ERROR,
    GET_VENDOR_LEDGER_2_REQUEST,
    GET_VENDOR_LEDGER_2_SUCCESS,
    GET_VENDOR_LEDGER_2_ERROR,
    GET_VENDOR_SUMMARY_REQUEST,
    GET_VENDOR_SUMMARY_SUCCESS,
    GET_VENDOR_SUMMARY_ERROR
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getVendorLedger = (token, data) => (dispatch) => {
    // 
    dispatch({ type: GET_VENDOR_LEDGER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/VendorLedger`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_from: moment(data.from_date).format("MM-DD-YYYY"),
            date_to: moment(data.to_date).format("MM-DD-YYYY"),
            vendor_id: data.vendor_id,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_VENDOR_LEDGER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_VENDOR_LEDGER_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_VENDOR_LEDGER_ERROR,
                payload: error.response,
            });
        });
};

export const getVendorLedger2 = (token, data) => (dispatch) => {
    // 
    dispatch({ type: GET_VENDOR_LEDGER_2_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/VendorLedger2`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_from: moment(data.from_date).format("MM-DD-YYYY"),
            date_to: moment(data.to_date).format("MM-DD-YYYY"),
            vendor_id: data.vendor_id,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_VENDOR_LEDGER_2_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_VENDOR_LEDGER_2_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_VENDOR_LEDGER_2_ERROR,
                payload: error.response,
            });
        });
};


export const getVendorSummary = (token, data) => (dispatch) => {
    // 
    dispatch({ type: GET_VENDOR_SUMMARY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/ledgerSummary`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_from: moment(data.from_date).format("MM-DD-YYYY"),
            date_to: moment(data.to_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({ type: GET_VENDOR_SUMMARY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_VENDOR_SUMMARY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_VENDOR_SUMMARY_ERROR,
                payload: error.response,
            });
        });
};