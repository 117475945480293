import {
  GET_PENDINGSKULOOKUP_REPORT_REQUEST,
  GET_PENDINGSKULOOKUP_REPORT_SUCCESS,
  GET_PENDINGSKULOOKUP_REPORT_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const getpendingskulookupnu = (token, values) => (dispatch) => {
  dispatch({ type: GET_PENDINGSKULOOKUP_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/pendingSkulookupreport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values, //{sku:1122}
  })
    .then((response) => {
      return dispatch({
        type: GET_PENDINGSKULOOKUP_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PENDINGSKULOOKUP_REPORT_ERROR,
        payload: [],
      });
      //   if (error.response.data.data === "No Record Found !") {
      //     return dispatch({
      //       type: GET_BUSSINESS_DASHBOARD_ERROR,
      //       payload: [],
      //     });
      //   }
      //   return dispatch({
      //     type: GET_REGION_ERROR,
      //     payload: error.response,
      //   });
    });
};
