import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getSeasonYear,addSeasonYear,updateSeasonYear,deleteSeasonYear } from "../../redux/actions/seasonYear.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
// import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { seasonYearColumns, logColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import {TabTitle} from "../../utils/GeneralFunction"
const { TabPane } = Tabs;

export default function SeasonYear({ token }) {
    TabTitle("Season Year")
    const styleNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [seasonYearObj, setSeasonYearObj] = useState(null);
    const dispatch = useDispatch();
    const { seasonYear, userRights, companyAll, log, defaultCompany } = useSelector(state => state)
    // const stateData = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [seasonYears, setSeasonYear] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');



  

  
    const companyAccessCol = [
        { title: 'ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
       
    ];

    useEffect(() => {
        dispatch(getDefaultCompany(token));
    }, [token])

    useEffect(() => {
        dispatch(getSeasonYear(token))
    }, [token])

    useEffect(() => {

        if (seasonYearObj) {
            setFormState('view')
            form.setFieldsValue({
                id: seasonYearObj.season_year_id,
                seasonYear: seasonYearObj.season_year,
                active: seasonYearObj.is_active
            });
            setSeasonYear(seasonYearObj.season_year)
            setCheck(seasonYearObj.is_active)
         

        }
    }, [seasonYearObj]);

    useEffect(() => {
        if (seasonYears === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [seasonYears])

    const showModal = () => {
        // dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const handleTab = (key) => {
        if (key === "logs") {
            // data: { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }
            dispatch(getLog(token, { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }))
        }
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addSeasonYear(token, { seasonYears, check })).then((res) => {

                if (res.type === "ADD_SEASON_YEAR_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_SEASON_YEAR_SUCCESS") {
                    let data = {
                        company_id: defaultCompany?.payload?.func_get_user_defualt_company
                    }
                  
                    notification("success", "Season created", "Season Year created successfully!");
                    form.setFieldsValue({
                        seasonYear: ""
                    })
                    setFormState("")
                    setCheck(false)
                    setSeasonYear("")
                    setSeasonYearObj(null)
                }
                dispatch(getSeasonYear(token))

            })

        }

        if (formState === "edit") {
            dispatch(updateSeasonYear(token, { seasonYears, check }, seasonYearObj.season_year_id)).then((res) => {
                dispatch(getSeasonYear(token))
                if (res.type === "UPDATE_SEASON_YEAR_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_SEASON_YEAR_SUCCESS") {
                    notification("success", "Season Year update", "Record updated successfully")
                }
                dispatch(getSeasonYear(token))
            })


        }


    }

    const handleDelete = () => {
        if (seasonYearObj) {
            dispatch(deleteSeasonYear(token, seasonYearObj.season_year_id)).then((res) => {
                if (res.type === "DELETE_SEASON_YEAR_SUCCESS") {
                    notification("success", "Season Year deleted", "Season Year deleted successfully!");
                }
                if (res.type === "DELETE_SEASON_YEAR_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    seasonYear: ""
                })
                setCheck(false)
                setSeasonYear("")
                setSeasonYearObj(null)
                setFormState('')
                dispatch(getSeasonYear(token))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            styleNameInput.current.focus();
            form.setFieldsValue({
                seasonYear: ""
            })
            setCheck(false)
            setSeasonYear("")
            setSeasonYearObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Season Year"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' >  <CrudButtons
                formType={formState}
                rights={userRights?.payload}
                formState={setFormState}
                reset={filterReset}
                disable={disable}
                save={formSave}
                onDelete={handleDelete}
              /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="seasonYearObj" seasonYearObj={setSeasonYearObj} scroll={602} perPage={25} source={seasonYear?.payload?.data} col={seasonYearColumns} paginate load={seasonYear.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                        >
                                            {seasonYearObj !== null && !(formState === "add") &&
                                                <Form.Item
                                                    label="ID"
                                                    name="id"
                                                    initialValue={form.getFieldValue().id}
                                                >
                                                    <Input className='styleInput id-field-style' readOnly />

                                                </Form.Item>
                                            }
                                            <Form.Item
                                                label="SeasonYear"
                                                name="seasonYear"
                                                initialValue={form.getFieldValue().seasonYear}
                                            >

                                                <Input disabled={!(formState === "add" || formState === "edit")} ref={styleNameInput} onChange={(e) => setSeasonYear(e.target.value)} className='styleInput' />
                                            </Form.Item>
                                            <Form.Item
                                                label="Active"
                                                name="active"
                                            >
                                                <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                            </Form.Item>
                                        </Form>
                                    
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                                {/* <TabPane tab="Companies" key="1">
                                                    <div className='tabs' >
                                                        <Tables type="company" scroll={340} perPage={12} source={(formState === "add" || formState === "") ? [] : companyAccess?.payload?.data}  load={companyAccess.loading} />
                                                    </div>
                                                </TabPane> */}
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                        <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}
                                            >
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}