import React from "react";
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, rights, saleOrderID, id, customerCode, formData, reportTittle, reportName }) {

    const history = useHistory();
    const confirm = () => {
        onDelete()
    };
   

    return <>
        <div className='crudContainer'>
            <div>
                {formType === "" ? null :
                    <div className='buttons-div' >
                        <Tag color={"green"} key={1}>{formType.toUpperCase()}</Tag>
                        {formType !== "view" &&
                            <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className='fspx-12' />} />
                        }
                    </div>
                }
            </div>
            <div>
                {/*Fulfillment Button*/}
                {formData?.fulfilled && formType !== "add" ?
                    <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb"  >
                        <Button type="primary" disabled
                            className="modalButton cursor-pointer">Fulfilled</Button>
                    </Tooltip> : (formType !== 'add' && customerCode) &&
                    <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb"  >
                        <Button type="primary" onClick={() => history.push(`/order-shipment/edit/${id}`)}
                            className="modalButton"  >Fulfillment</Button>
                    </Tooltip>
                }
                {/*Search Button*/}

                {formType === "formView" && <a href={`http://127.0.0.1:61220/viewer/frameset?__report=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports%5C${reportName}&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__title=${reportTittle}&__cubememsize=10&__resourceFolder=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports&-1787786759`} target="_blank"> View Report</a>}
                <Tooltip placement="bottom" title="Search" color="#fbfbfb"  >
                    <Button onClick={() => history.push('/sale-order-adjustment/list')} className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
                </Tooltip>
                {/*Add Button*/}
                <Tooltip placement="bottom" title="Add" color="#fbfbfb"  >
                    <Button
                        disabled={(formType === "add" ? true : false)}
                        onClick={() => {
                            if (formType === "add") {
                                formState("add");
                            } else {
                                history.push('/sale-order-adjustment/add?mode=add');
                                formState("add");
                            }

                        }}
                        className="iconBtn crudBTN"
                        shape="circle"
                        icon={<PlusOutlined />} />
                </Tooltip>
                {/*Edit Button*/}
                <Tooltip placement="bottom" title="Edit" color="#fbfbfb"  >
                    <Button disabled={(disable || (formType === "view" || formType === "add")) || (rights?.can_edit === false ? true : false)} onClick={() => formState("edit")} className="iconBtn crudBTN" shape="circle" icon={<EditOutlined />} />
                </Tooltip>
                {/*Delete Button*/}
                <Tooltip placement="bottom" title="Delete" color="#fbfbfb"   >
                    {rights?.can_delete === false ?
                        <Button className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} /> :
                        <Popconfirm
                            disabled={(disable || (formType === "add" || formType === "view"))}
                            placement="topLeft"
                            title={`Are you sure to delete this ${saleOrderID}?`}
                            onConfirm={confirm}
                            okText="Yes"

                            cancelText="No">
                            <Button
                                disabled={(disable || (formType === "add" || formType === "view"))}
                                className="iconBtn crudBTN"
                                shape="circle"
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    }
                </Tooltip>
                {/*Save Button*/}
                {/* disabled={(!(formType === "add" || formType === "edit") && (name === "product" ? formType === "edit" ? true : flag ? true : false : !disable))}  */}
                <Tooltip placement="bottom" title="Save" color="#fbfbfb"  >
                    <Button onClick={() => save()} className="iconBtn crudBTN" shape="circle" icon={<FileDoneOutlined />} />
                </Tooltip >
            </div >
        </div >
        <Divider />
    </>;
}