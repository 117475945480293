import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SubTitle from "../../components/SubTitle/SubTitle";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import { UndoOutlined } from "@ant-design/icons";
import { loadJobTitle } from "../../redux/actions/salePerson/jobTitle.action";
import { loadReportCurrency } from "../../redux/actions/salePerson/reportCurrency.action";
import { loadSupervisor } from "../../redux/actions/salePerson/supervisor.action";
import { getOneSaleperson } from "../../redux/actions/salePerson/getSalepersonById.action";
import { getRights } from "../../redux/actions/userRights.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "./CrudButtons";
import Tables from "../../components/Table/Tables";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import {
  loadSalePerson,
  addSalePerson,
  deleteSalePerson,
  updateSalePerson,
} from "../../redux/actions/salePerson/salePersonForm.action";
import {
  Button,
  Form,
  Input,
  Tabs,
  Tooltip,
  Divider,
  Select,
  Checkbox,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { logColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import { ReadCookie } from "../../utils/readCookie";

import "../Style/style.css";
import "./saleperson.css";
import "../Product/product.css";
import { TabTitle } from "../../utils/GeneralFunction";
import { getSystemLog } from "../../redux/actions/systemLog.action";
import { useHistory } from "react-router-dom";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

export default function SalePerson({ token }) {
  TabTitle("Sales Person");
  const history = useHistory();
  const [form] = Form.useForm();
  const [submit, setSubmit] = useState(false);
  const submitButton = useRef(null);
  const [salePersonAllId, setSalepersonAllId] = useState(null);
  const dispatch = useDispatch();
  const {
    companyAll,
    systemLog,
    userRights,
    jobTitle,
    supervisor,
    reportCurrency,
    salePersonForm,
  } = useSelector((state) => state);
  console.log(supervisor, "supervisorsupervisor");
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const menuId = ReadCookie("menuId");
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [disable, setDisable] = useState(false);
  const [salepersonGridView, setSalePersonGridView] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataLen, setDataLen] = useState(0);
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);

  const mode = queryParams.get("mode");
  const salePersonID = queryParams.get("salePersonID");
  // const customerID = queryParams.get("customer_id");
  console.log({ mode: mode });
  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  console.log({ salePersonID: salePersonID });

  useEffect(() => {
    if (mode === "edit" && salePersonID != null) {
      dispatch(getOneSaleperson(token, salePersonID)).then((saleperson) => {
        if (saleperson.type === "GET_ONE_SALEPERSON_SUCCESS") {
          form.setFieldsValue({
            company: saleperson?.payload.subsidiary,
            employee_id: saleperson?.payload.sales_person_id,
            empName: saleperson?.payload.salesperson_name,
            jobTitle: saleperson?.payload.job_id,
            SuperVisor: saleperson?.payload.sales_person_id,
            reportingCurrency: saleperson?.payload.reporting_currency,
            email: saleperson?.payload.email,
            officePhone: saleperson?.payload.office,
            mobilePhone: saleperson?.payload.mobile,
            fax: saleperson?.payload.fax,
          });
          setCheck(saleperson?.payload.is_salesperson)
        }
        if (saleperson.type === "GET_ONE_SALEPERSON_ERROR") {
          notification("error", "Customer", saleperson?.payload?.data);
        }
      });
    } else {
    }
  }, [mode, salePersonID]);

  useEffect(() => {
    if (formState === "table") {
      setSalepersonAllId(null);
      setShow(false);
      setFilteredInfo(null);
      setSortedInfo(null);
      dispatch(loadSalePerson(token));
    }
  }, [formState]);

  useEffect(() => {
    if (companyId) {
      setCompanyId(parseInt(companyId));
      form.setFieldsValue({
        company: companyId,
      });
    }
    dispatch(getAllCompany(token));
  }, [companyId]);

  useEffect(() => {
    if (formState === "edit" || formState === "formView") {
      dispatch(
        getSystemLog(token, {
          table_name: "scm_sales_person",
          record_id: salePersonID,
        })
      );
    }
  }, [formState]);

  useEffect(() => {
    dispatch(loadJobTitle(token));
  }, [token]);

  useEffect(() => {
    dispatch(loadSupervisor(token));
  }, [token]);

  useEffect(() => {
    dispatch(loadReportCurrency(token));
  }, [token]);

  useEffect(() => {
    if (!show) {
      setDataLen(0);
    }
    if (show) {
      setDataLen(salePersonForm?.payload?.data.length);
    }
  }, [show]);

  // useEffect(() => {
  //   if (salePersonAllId) {
  //     setSalePersonGridView(false);
  //     setFormState("formView");
  //     dispatch(getOneSaleperson(token, salePersonAllId)).then((saleperson) => {
  //       if (saleperson.type === "GET_ONE_SALEPERSON_SUCCESS") {
  //         form.setFieldsValue({
  //           company: saleperson.payload.subsidiary,
  //           employee_id: saleperson.payload.sales_person_id,
  //           empName: saleperson.payload.salesperson_name,
  //           jobTitle: saleperson.payload.job_id,
  //           SuperVisor: saleperson.payload.sales_person_id,
  //           reportingCurrency: saleperson.payload.reporting_currency,
  //           email: saleperson.payload.email,
  //           officePhone: saleperson.payload.office,
  //           mobilePhone: saleperson.payload.mobile,
  //           fax: saleperson.payload.fax,
  //         });
  //         setSalepersonAllId(saleperson.payload.sales_person_id);

  //         if (saleperson.payload.hire_date === null) {
  //           form.setFieldsValue({
  //             hireDate: "",
  //           });
  //         } else {
  //           form.setFieldsValue({
  //             hireDate: moment(saleperson.payload.hire_date),
  //           });
  //         }
  //       }
  //       if (saleperson.type === "GET_ONE_SALEPERSON_ERROR") {
  //         notification("error", "Customer", saleperson?.payload?.data);
  //       }
  //     });
  //   } else {
  //   }
  // }, [salePersonAllId]);

  useEffect(() => {
    if (formState === "table") {
      setSalePersonGridView(true);
    }
    if (formState === "add") {
    }
  }, [formState]);

  const onSearch = (val) => {};

  useEffect(() => {
    if (formState === "view" || formState === "table" || formState === "add") {
      form.setFieldsValue({
        // company: companyId,
        employee_id: null,
        empName: null,
        jobTitle: null,
        SuperVisor: null,
        reportingCurrency: null,
        hireDate: null,
        email: null,
        officePhone: null,
        mobilePhone: null,
        homePhone: null,
        fax: null,
      });
    }
  }, [formState]);

  const formSave = () => {
    setSubmit(true);
    submitButton.current.click();
  };

  const onFinish = async (e) => {
    setSubmit(false);
    form.setFieldsValue({ [e.target.name]: e.target.value });
    await form.validateFields().then((values) => {
      if (formState === "add") {
        values.is_salesperson = check;
        dispatch(addSalePerson(token, values)).then((res) => {
          if (res.type === "ADD_SALEPERSON_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          if (res.type === "ADD_SALEPERSON_SUCCESS") {
            form.setFieldsValue({ employee_id: res?.payload?.sales_person_id });
            notification(
              "success",
              "Sale Person Created",
              "Record created successfully!"
            );
            history.push(
              `/sales-person?mode=edit&salePersonID=${res?.payload?.sales_person_id}`
            );
            setFormState("formView");
            dispatch(loadSalePerson(token));
            setSalePersonGridView(false);
          }
        });
      }
      if (formState === "edit") {
        values.is_salesperson = check;
        dispatch(updateSalePerson(token, values, salePersonID)).then((res) => {
          if (res.type === "UPDATE_SALEPERSON_ERROR") {
            notification("error", "Update", "Something went wrong");
          }
          if (res.type === "UPDATE_SALEPERSON_SUCCESS") {
            notification(
              "success",
              "SALE PERSON UPDATED",
              "Record updated successfully"
            );
            // form.setFieldsValue({
            //   company: companyId,
            //   employee_id: res.sales_person_id,
            //   empName: null,
            //   jobTitle: null,
            //   SuperVisor: null,
            //   reportingCurrency: null,
            //   hireDate: null,
            //   email: null,
            //   officePhone: null,
            //   mobilePhone: null,
            //   homePhone: null,
            //   fax: null,
            // });
            setFormState("formView");
            setSalepersonAllId(null);
          }
          dispatch(loadSalePerson(token));
        });
        setSalePersonGridView(false);
      }
    });
  };
  const renderColumns = () => {
    const keys = Object.keys(systemLog?.payload?.data[0]);
    return keys.map((it, i) => {
      return {
        title: it,
        dataIndex: it,
        key: i.toString(),
      };
    });
  };
  const handleClose = () => {
    setFormState("view");
    setSalePersonGridView(false);
  };

  const handleDelete = () => {
    dispatch(deleteSalePerson(token, salePersonID)).then((res) => {
      if (res.type === "DELETE_SALEPERSON_SUCCESS") {
        notification(
          "success",
          "SALE PERSON Deleted",
          "Record deleted successfully!"
        );
        // form.setFieldsValue({
        //   company: companyId,
        //   employee_id: null,
        //   empName: null,
        //   jobTitle: null,
        //   SuperVisor: null,
        //   reportingCurrency: null,
        //   hireDate: null,
        //   email: null,
        //   officePhone: null,
        //   mobilePhone: null,
        //   homePhone: null,
        //   fax: null,
        // });
        // setFormState("table");
        history.push("/sales-person/list");
        salePersonID(null);
      }
      if (res.type === "DELETE_SALEPERSON_ERROR") {
        notification(
          "error",
          res?.payload?.request?.statusText,
          res?.payload?.data?.data
        );
      }
      dispatch(loadSalePerson(token));
      setSalePersonGridView(true);
    });
  };

  ///For Another Table
  const colTest = [
    {
      title: "SalePerson Name",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
      width: 130,
      filteredValue: filteredInfo
        ? filteredInfo.salesperson_name || null
        : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style w-11-em"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.salesperson_name)
          return record.salesperson_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "salesperson_name" && sortedInfo.order
        : null,
      sorter: (a, b) => a.salesperson_name.localeCompare(b.salesperson_name),
    },
    {
      title: "SalePerson Id",
      dataIndex: "sales_person_id",
      key: "sales_person_id",
      width: 130,
      filteredValue: filteredInfo ? filteredInfo.sales_person_id || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style w-11-em"
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.sales_person_id)
          return record.sales_person_id === parseInt(value);
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "sales_person_id" && sortedInfo.order
        : null,
      sorter: (a, b) => a.sales_person_id - b.sales_person_id,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: 130,
      filteredValue: filteredInfo ? filteredInfo.mobile || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style w-11-em"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.mobile)
          return record.mobile.toLowerCase().includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "mobile" && sortedInfo.order
        : null,
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 130,
      filteredValue: filteredInfo ? filteredInfo.email || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style w-11-em"
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.email)
          return record.email.toLowerCase().includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "email" && sortedInfo.order
        : null,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 130,
      filteredValue: filteredInfo ? filteredInfo.is_active || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style w-11-em"
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.is_active)
          return record.is_active.toLowerCase().includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "is_active" && sortedInfo.order
        : null,
      sorter: (a, b) => a.is_active.localeCompare(b.is_active),
    },
  ];

  const handleFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setShow(true);
  };

  const clearFilters = () => {
    if (show) {
      setFilteredInfo(null);
      setSortedInfo(null);
      setShow(false);
    }
    if (!show) {
      setShow(!show);
    }
  };

  const filterReset = () => {
    form.setFieldsValue({
      company: companyId,
    });
    setFormState("view");
    setSalepersonAllId(null);
  };

  return (
    <>
      {salepersonGridView === true ? (
        <div className="styleContainer">
          <div className="styleBody h-100">
            <PageHeader title={"Sales Person"} />

            <div className="bodyStyle">
              {salePersonForm.loading ? (
                <div className="mainProductContainer">
                  <SkeletonLoad />
                </div>
              ) : (
                <div className="mainProductContainer">
                  <div className="productTable">
                    <div className="productListDiv">
                      <SubTitle name={"SalePersons List"} />
                      {userRights?.payload?.can_add && (
                        <Button
                          className="iconBtn crudBTN close-button-sub"
                          shape="circle"
                          icon={<CloseCircleOutlined className="color-red" />}
                          onClick={() => handleClose()}
                        />
                      )}
                    </div>
                    <div className="pbpx-10">
                      <Button
                        type="primary"
                        className="mlpx-5"
                        onClick={() => clearFilters()}
                      >
                        {show ? "Clear All" : "Show All"}
                      </Button>
                    </div>
                    <Tables
                      dataLen={dataLen}
                      setDataLen={setDataLen}
                      show={show}
                      handleFilter={handleFilter}
                      loadHeight={40}
                      loadWidth={40}
                      type={"salePerson"}
                      salePersonAllId={setSalepersonAllId}
                      //customerObj={setCustomerObj}
                      scroll={570}
                      perPage={25}
                      scrollX={"80rem"}
                      col={colTest}
                      source={salePersonForm?.payload?.data}
                      load={salePersonForm.loading}
                      paginate
                    />
                    <span className="bottom-records-numbers">
                      {dataLen === 0 ? (
                        <>
                          No of Records: <span className="fw-500"> 0</span>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          No of Records:{" "}
                          <span className="fw-500">
                            {" "}
                            {show ? dataLen : dataLen ? dataLen : "0"}
                          </span>{" "}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="styleContainer">
          <div className="styleBody h-100">
            <PageHeader
              title={"Sales Person"}
              minimize={setMinimize}
              check={minimize}
            />
            {!minimize && (
              <div className="bodyStyle">
                <div className="crudDiv">
                  {/* <CrudButtons
                    name={"salePerson"}
                    reset={filterReset}
                    formType={formState}
                    save={formSave}
                    disable={disable}
                    userRights={userRights?.payload}
                    formState={setFormState}
                    onDelete={handleDelete}
                  /> */}
                  <CrudButtons
                    // id={id}
                    name={"salePerson"}
                    reset={filterReset}
                    userRights={userRights?.payload}
                    formType={formState}
                    save={formSave}
                    disable={disable}
                    formState={setFormState}
                    onDelete={handleDelete}
                    salePersonID={salePersonID}
                    // journalVoucherId={id}
                    // formData={journalVoucherHeader?.payload?.data}
                  />
                </div>

                <SubTitle name={"Primary Information"} />

                <div className="formProduct p-0">
                  {formState === "edit" && (
                    <div className="undoDiv">
                      <Tooltip
                        placement="left"
                        title="Form Reset"
                        color="#fbfbfb"
                      >
                        <Button
                          className="iconBtn undoBtn "
                          icon={<UndoOutlined />}
                          shape="circle"
                        />
                      </Tooltip>
                    </div>
                  )}

                  <Form onFinish={onFinish} form={form} name="basic">
                    <div className="container-fluid pbpx-5 ptpx-5">
                      <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="slc">
                            <Form.Item
                              name="company"
                              label="Company"
                              labelAlign="left"
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              initialValue={
                                formState === "add"
                                  ? null
                                  : form.getFieldValue().company
                              }
                            >
                              <Select
                                size="small"
                                showSearch
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={(input, options) =>
                                  options.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={
                                  formState === "add" ||
                                  formState === "edit" ||
                                  formState === "view" ||
                                  formState === "formView"
                                }
                                className="styleInput w-100"
                              >
                                {companyAll?.payload?.data.map((item) => {
                                  return (
                                    <Option
                                      key={item.company_id}
                                      value={item.company_id}
                                    >
                                      {item.company_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="employee_id"
                                label="Employee Id:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().employee_id
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder={
                                  //   formState === "add" ? "Employee Id" : null
                                  // }
                                  disabled={
                                    formState === "add" ||
                                    formState === "edit" ||
                                    formState === "view" ||
                                    formState === "formView"
                                  }
                                />
                                {/* </div > */}
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="empName"
                                label="Name:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().empName
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  className="styleInput"
                                  size="small"
                                  // placeholder={formState === "add" ? "Name" : null}
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                                {/* </div > */}
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        {/* <Bcol lg={6} xl={4} xxl={3}>
                          <div className="slc">
                            <div className="relative">
                              <Form.Item
                                name="jobTitle"
                                label="Job Title:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                              >
                                <Select
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={onSearch}
                                  filterOption={(input, options) =>
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  loading={jobTitle?.loading}
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().jobTitle
                                      : null
                                  }
                                  className="w-100"
                                  // placeholder={
                                  //   formState === "add" ? "jobTitle" : null
                                  // }
                                  allowClear
                                >
                                  {jobTitle?.payload?.data?.map((job) => {
                                    return (
                                      <Option value={job?.job_id}>
                                        {job?.job_title}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="slc">
                            <div className="relative">
                              <Form.Item
                                name="SuperVisor"
                                label="SuperVisor:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                              >
                                <Select
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={onSearch}
                                  filterOption={(input, options) =>
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  loading={supervisor?.loading}
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().SuperVisor
                                      : null
                                  }
                                  className="w-100"
                                  // placeholder={
                                  //   formState === "add" ? "SuperVisor" : null
                                  // }
                                  allowClear
                                >
                                 
                                  {supervisor?.payload?.data?.map((sup) => {
                                    return (
                                      <Option value={sup?.user_id}>
                                        {sup?.first_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="slc">
                            <div className="relative">
                              <Form.Item
                                name="reportingCurrency"
                                label="Report Currency:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                              >
                                <Select
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={onSearch}
                                  filterOption={(input, options) =>
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  loading={reportCurrency.loading}
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  defaultValue={
                                    formState === "edit"
                                      ? form.getFieldValue().reportingCurrency
                                      : null
                                  }
                                  className="w-100"
                                  // placeholder={
                                  //   formState === "add" ? "Currency" : null
                                  // }
                                  allowClear
                                >
                                  {reportCurrency?.payload?.data?.map(
                                    (currency) => {
                                      return (
                                        <Option value={currency?.currency_id}>
                                          {currency?.currency_name}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol> */}
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="hireDate"
                                label="Hire Date:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().hireDate
                                }
                              >
                                <DatePicker
                                  size="small"
                                  className="styleInput w-100"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  format={"DD/MM/YYYY"}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>

                        <Button
                          style={{ display: "none" }}
                          onClick={onFinish}
                          ref={submitButton}
                        />
                      </Brow>
                    </div>
                  </Form>
                </div>

                <SubTitle name={"Email | Phone | Address"} />
                <div className="formProduct p-0">
                  {formState === "edit" && (
                    <div className="undoDiv">
                      <Tooltip
                        placement="left"
                        title="Form Reset"
                        color="#fbfbfb"
                      >
                        <Button
                          className="iconBtn undoBtn "
                          icon={<UndoOutlined />}
                          shape="circle"
                        />
                      </Tooltip>
                    </div>
                  )}

                  <Form onFinish={onFinish} form={form} name="basic">
                    <div className="container-fluid pbpx-5 ptpx-5">
                      <Brow>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="email"
                                label="Email:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().email
                                }
                                rules={[
                                  {
                                    type: "email",
                                    message: "EMAIL is not Valid",
                                  },
                                  {
                                    required: true,
                                    message: "EMAIL is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="officePhone"
                                label="Office Phone:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().officePhone
                                }
                                // rules={[
                                //   {
                                //     pattern: new RegExp(
                                //       /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                                //     ),
                                //     message: "Must Be a Number.",
                                //   },
                                // ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="mobilePhone"
                                label="Mobile Phone:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().mobilePhone
                                }
                                // rules={[
                                //   {
                                //     pattern: new RegExp(
                                //       /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                                //     ),
                                //     message: "Must Be a Number.",
                                //   },
                                // ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="homePhone"
                                label="Home Phone:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().homePhone
                                }
                                // rules={[
                                //   {
                                //     pattern: new RegExp(
                                //       /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                                //     ),
                                //     message: "Must Be a Number.",
                                //   },
                                // ]}
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                name="fax"
                                label="Fax:"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValue={
                                  formState === "add"
                                    ? null
                                    : form.getFieldValue().fax
                                }
                              >
                                <Input
                                  size="small"
                                  className="styleInput"
                                  // placeholder={formState === "add" ? "Fax" : null}
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Bcol lg={6} xl={4} xxl={3}>
                          <div className="">
                            <div className="relative">
                              <Form.Item
                                label="Is SalesPerson"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                name="active"
                              >
                                <Checkbox
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  checked={check}
                                  onChange={(e) => setCheck(e.target.checked)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Bcol>
                        <Button
                          style={{ display: "none" }}
                          onClick={onFinish}
                          ref={submitButton}
                        />
                      </Brow>
                    </div>
                  </Form>
                </div>
                <Divider />
                <div className="styleTab">
                  <Tabs type="card" size="small" defaultActiveKey="1" centered>
                    {(formState === "edit" ||
                      formState === "formView" ||
                      formState === "view") && (
                      <TabPane tab="System Information" key="logs">
                        <div className="tabs">
                          {systemLog?.payload?.data?.length > 0 && (
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={systemLog?.payload?.data}
                              dataSource={[]}
                              col={renderColumns()}
                              load={systemLog?.loading}
                              paginate
                            />
                          )}
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </div>
            )}
          </div>
          {minimize && (
            <div className="CustomerFooter">
              <Button
                className="minimize-button-style"
                onClick={() => setMinimize(false)}
              >
                SalePerson
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
