import React, { Fragment, useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Button, Table, Divider } from 'antd';
import axios from 'axios';
import Swal from "sweetalert2";
import notification from '../../utils/notification';
import Tables from '../../components/Table/Tables';
import { ReadCookie } from '../../utils/readCookie';
import { TabTitle } from '../../utils/GeneralFunction';
import Loader from "react-loader-spinner";
import { BASEURL } from '../../constant/config';
import moment from 'moment';
import { ModalContainer } from '../../components';

const nu = 'nu'
const lAndT = 'lAndT'
const nordStrom = 'nordStrom'

const routineType = {
    nu: {
        key: nu,
        api: 'getAll/nuOrderRoutine',
        message: ['Nu Order', 'Nu Order Successfully'],
        error: 'Nu Order Failed',
    },
    lAndT: {
        key: lAndT,
        api: 'getAll/lordAndTaylorOrderRoutine',
        message: ['L&T Order', 'L&T Order Successfully'],
        error: 'L&T Order Failed',
    },
    nordStrom: {
        key: nordStrom,
        api: 'getAll/nordStromOrderRoutine',
        message: ['NordStrom Order', 'NordStrom Order Successfully'],
        error: 'NordStrom Order Failed',
    },
}

const RoutineOrder = () => {
    TabTitle("Routine Orders")
    const token = ReadCookie("token");
    const [isLoading, setIsLoading] = useState('');
    const [active, setActive] = useState('');
    const [routineLog, setRoutineLog] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [sessionData, setSessionData] = useState(null);

    useEffect(() => {
        getRoutineLog()
    }, []);

    const getRoutineLog = () => {
        axios({
            method: "POST",
            url: `${BASEURL}getAll/Routine_stats`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                routineOrderStats: true
            }
        }).then(function (response) {
            setRoutineLog(response?.data?.data)
        })
    }

    const column = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Session ID",
            dataIndex: "session_id",
            key: "session_id",
            render: (text, record) => (
                <span role='button' onClick={() => {
                    setShowModal(true)
                    setSessionData(record)
                }}>{text}</span>
            )
        },
        {
            title: "Routine Name",
            dataIndex: "obj",
            key: "obj",
            render: (text, record) => {
                return record.obj.source
            }
        },
        {
            title: "Date",
            dataIndex: "date_time",
            key: "date_time",
            render: (text, record) => {
                return moment(text).format('L')
            }
        },
        {
            title: "Start Time",
            dataIndex: "obj",
            key: "obj",
            render: (text, record) => {
                return moment(record.obj.startTime).format('LT')
            }
        },
        {
            title: "End Time",
            dataIndex: "obj",
            key: "obj",
            render: (text, record) => {
                return moment(record.obj.endTime).format('LT')
            }
        },
        {
            title: "",
            dataIndex: "obj",
            key: "obj",
            render: (text, record) => {
                return `${record.obj.successOrders.length} out of ${record.obj.totalOrders}`
            }
        },
    ];

    const getRoutineByType = (type) => {
        const { key, api, message, error: _err } = routineType[type]
        setIsLoading(key)
        axios({
            method: "POST",
            url: `${BASEURL + api}`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }).then(function (response) {
            if (response.status === 200) {
                setActive(key)
                notification('success', message[0], message[1])
                getRoutineLog()
            }
        })
            .catch(function (error) {
                setActive('')
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: _err,
                });

            })
            .finally(function () {
                setIsLoading('')
            });
    }

    return (
        <>
            <Row gutter={[18, 12]}>
                {isLoading ? (
                    <div>
                        <div className="loaderDiv">
                            <Loader type="Grid" color="#212121" height={40} width={40} />
                            <div>
                                <p style={{ fontSize: 26 }}>Please Wait Until the current Routine is completed.</p>
                            </div>
                        </div>
                    </div>
                ) : null
                }
                <Col className="gutter-row" span={6}>
                    <TabButton active={active === nu} isLoading={isLoading === nu} title='Nu Order' callBack={() => getRoutineByType(nu)} />
                </Col>
                <Col className="gutter-row" span={6}>
                    <TabButton active={active === lAndT} isLoading={isLoading === lAndT} title='L&T Order' callBack={() => getRoutineByType(lAndT)} />
                </Col>
                <Col className="gutter-row" span={6}>
                    <TabButton active={active === nordStrom} isLoading={isLoading === nordStrom} title='NordStrom Order' callBack={() => getRoutineByType(nordStrom)} />
                </Col>
            </Row >
            <div style={{ paddingTop: "200px" }}>
                <Tables
                    type="company"
                    scrollX={"50rem"}
                    scroll={360}
                    source={routineLog}
                    col={column}
                    pagination={false}
                />
            </div>
            <ModalContainer open={showModal} close={() => setShowModal(false)} width="100%" centered footer={null}>
                <SessionDataPresenter data={sessionData} />
            </ModalContainer>
        </>
    )
};
export default RoutineOrder;

const TabButton = ({ active, isLoading, callBack, title }) => {
    return (
        <div>
            <Button className={`tab_button ${active ? 'active' : ''}`} icon={active && <CheckOutlined />} size='large' loading={isLoading} onClick={callBack}>
                {title}
            </Button>
        </div>
    )
}

const SessionDataPresenter = ({ data }) => {
    const t1 = [
        {
            title: 'Session ID',
            value: data.session_id
        },
        {
            title: 'Date',
            value: moment(data.date_time).format('L')
        },
        {
            title: 'Start Time',
            value: moment(data.obj.startTime).format('LT')
        },
        {
            title: 'End Time',
            value: moment(data.obj.endTime).format('LT')
        },
        {
            title: 'Total Orders',
            value: data.obj.totalOrders
        },
        ...(data.obj.wwOrders ? [{
            title: 'Warp And Weft Orders',
            value: data.obj.wwOrders
        }] : []),
        {
            title: 'Duplicate Orders',
            value: data.obj.ignoredOrders.length
        },
        {
            title: 'Successful Orders',
            value: data.obj.successOrders.length
        },
        {
            title: 'New Customer Orders',
            value: data.obj.newCustomersOrders.length
        },
        {
            title: 'Failed Orders',
            value: data.obj.failedOrders.length
        },
    ]
    return (
        <>
            <Row style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <h6 style={{ marginBottom: '1em' }}>Session Summary</h6>
                </Col>
                <Col span={12}>
                    <h6 style={{ marginBottom: '1em' }}>Session</h6>
                </Col>
                <Col span={12}>
                    <h6 style={{ marginBottom: '1em' }}>Result</h6>
                </Col>
                {t1.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Col span={12} >
                                <p>{item.title}</p>

                            </Col>
                            <Col span={12}>
                                <p>{item.value}</p>
                            </Col>
                        </Fragment>
                    )
                })}
            </Row>
            <Row style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <h6 style={{ marginBottom: '1em' }}>Successful Order</h6>
                    <Table
                        scroll={{ y: 360 }}
                        columns={[
                            {
                                title: '#',
                                dataIndex: 'index',
                                key: 'index',
                                width: 40,
                                render: (text, record, index) => {
                                    return <span style={{ whiteSpace: 'nowrap' }}>{index + 1}</span>;
                                },
                            },
                            {
                                title: 'PO no',
                                dataIndex: 'po',
                                key: 'po',
                            },
                        ]} dataSource={data.obj.successOrders} pagination={false} />
                </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <h6 style={{ marginBottom: '1em' }}>New Customers Order</h6>
                    <Table
                        scroll={{ y: 360 }}
                        columns={
                            [
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    width: 40,
                                    render: (text, record, index) => {
                                        return <span style={{ whiteSpace: 'nowrap' }}>{index + 1}</span>;
                                    },
                                },
                                {
                                    title: 'Order',
                                    dataIndex: 'order',
                                    key: 'order',
                                },
                                {
                                    title: 'Buyer Name',
                                    dataIndex: 'buyerName',
                                    key: 'buyerName',
                                },
                                {
                                    title: 'Retailer Code',
                                    dataIndex: 'retailer_code',
                                    key: 'retailer_code',
                                },
                                {
                                    title: 'Retailer Email',
                                    dataIndex: 'retainerEmail',
                                    key: 'retainerEmail',
                                },
                            ]
                        } dataSource={data.obj.newCustomersOrders} pagination={false} />
                </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <h6 style={{ marginBottom: '1em' }}>Failed Orders</h6>
                    <Table
                        scroll={{ y: 360 }}
                        columns={
                            [
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    render: (text, record, index) => {
                                        return <span style={{ whiteSpace: 'nowrap' }}>{index + 1}</span>;
                                    },
                                },
                                {
                                    title: 'PO no',
                                    dataIndex: 'po',
                                    key: 'po',
                                    width: '50%'
                                },
                                {
                                    title: 'Detail',
                                    dataIndex: 'err',
                                    key: 'err',
                                    width: '50%'
                                },
                            ]
                        } dataSource={data.obj.failedOrders} pagination={false} />
                </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
                <Col span={24}>
                    <h6 style={{ marginBottom: '1em' }}>Duplicate Orders</h6>
                    <Table
                        scroll={{ y: 360 }}
                        columns={
                            [
                                {
                                    title: '#',
                                    dataIndex: 'index',
                                    key: 'index',
                                    width: 40,
                                    render: (text, record, index) => {
                                        return <span style={{ whiteSpace: 'nowrap' }}>{index + 1}</span>;
                                    },
                                },
                                {
                                    title: 'PO no',
                                    dataIndex: 'po',
                                    key: 'po',
                                    width: '50%'
                                },
                                {
                                    title: 'Detail',
                                    dataIndex: 'reason',
                                    key: 'reason',
                                    width: '50%'
                                },
                            ]
                        } dataSource={data.obj.ignoredOrders} pagination={false} />
                </Col>
            </Row>
        </>
    )
}