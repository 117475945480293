import { GET_NAME_REQUEST, GET_NAME_SUCCESS, GET_NAME_ERROR } from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
///GET CUSTOMER TYPE

///Post Bussiness dashboard
export const getAllName = (token) => (dispatch) => {
  dispatch({ type: GET_NAME_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getAllEntities`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      entity_type: "%",
      entity_name: "%",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_NAME_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_NAME_ERROR,
        payload: [],
      });
    });
};
