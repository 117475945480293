import {
  GET_SALESPERSON_ERROR,
  GET_SALESPERSON_SUCCESS,
  GET_SALESPERSON_REQUEST,
  ADD_SALEPERSON_REQUEST,
  ADD_SALEPERSON_SUCCESS,
  ADD_SALEPERSON_ERROR,
  DELETE_SALEPERSON_REQUEST,
  DELETE_SALEPERSON_SUCCESS,
  DELETE_SALEPERSON_ERROR,
  UPDATE_SALEPERSON_REQUEST,
  UPDATE_SALEPERSON_SUCCESS,
  UPDATE_SALEPERSON_ERROR,
} from "../types";
import axios from "axios";
import { BASEURL } from "../../../constant/config.js";

export const loadSalePerson = (token) => (dispatch) => {
  dispatch({ type: GET_SALESPERSON_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/salesPerson`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_SALESPERSON_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SALESPERSON_ERROR,
        payload: error.response,
      });
    });
};

export const addSalePerson = (token, data) => (dispatch) => {
  dispatch({ type: ADD_SALEPERSON_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/salesPerson`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: {
      is_deleted: 0,
      is_active: true,
      subsidiary: data.company,
      salesperson_name: data.empName,
      email: data.email,
      mobile: data.mobilePhone,
      office: data.officePhone,
      job_id: data.jobTitle,
      // sales_person_id: data.SuperVisor,
      reporting_currency: data.reportingCurrency,
      //fax: data.fax,
      hire_date: data.hireDate,
      is_salesperson: data.is_salesperson

    },
  })
    .then((response) => {

      return dispatch({ type: ADD_SALEPERSON_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_SALEPERSON_ERROR,
        payload: error.response,
      });
    });
};

export const deleteSalePerson = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_SALEPERSON_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/salesPerson/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({
        type: DELETE_SALEPERSON_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SALEPERSON_ERROR,
        payload: error.response,
      });
    });
};

export const updateSalePerson = (token, data, id) => (dispatch) => {
  dispatch({ type: UPDATE_SALEPERSON_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/salesPerson/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      is_deleted: 0,
      is_active: true,
      subsidiary: data.company,
      salesperson_name: data.empName,
      email: data.email,
      mobile: data.mobilePhone,
      office: data.officePhone,
      job_id: data.jobTitle,
      // sales_person_id: data.SuperVisor,
      reporting_currency: data.reportingCurrency,
      //fax: data.fax,
      hire_date: data.hireDate,
      is_salesperson: data.is_salesperson
    },
  })
    .then((response) => {

      return dispatch({
        type: UPDATE_SALEPERSON_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_SALEPERSON_ERROR,
        payload: error.response,
      });
    });
};
