import React from 'react'
import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons"
import "./crud.css"
import { useHistory, Link } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, userRights }) {
   
    const history = useHistory();
    function confirm() {
        onDelete()
    }
    // export default function CrudButtons({ save, disable, formState, formType, deleteStyle, reset, deleteCustomer,name }) {
    // function confirm() {

    //     if(deleteStyle){
    //     deleteStyle();
    //     }
    //     if(deleteCustomer){
    //     deleteCustomer();
    //     }
    // }
    return (
        <>
            <div className='crudContainer'>

                <div>
                    {formType === "" ? null :
                        <div className='buttons-div'>
                            <Tag color={"green"} key={1}>{formType.toUpperCase()}</Tag>
                            {formType !== "view" &&
                                <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className='fspx-12' />} />
                            }
                        </div>
                    }
                </div>
                <div>
                    {(name === "vendor") &&
                   <Tooltip placement="bottom" title="Search" color="#fbfbfb"  >
                       <Button onClick={() => history.push("/vendor-info/getAll")}
                           // <Button onClick={() => history.push("/sales-person/list")}
                           className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
                   </Tooltip>
               }

                    {(name === "customer" || name === "product" || name === "saleOrder") &&
                        <Tooltip placement="bottom" title="Search" color="#fbfbfb"  >
                            <Button onClick={() => history.push("/product-info/getAll")}
                                // <Button onClick={() => history.push("/sales-person/list")}
                                className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
                        </Tooltip>
                    }
                    {(name === "salePerson") &&
                        <Tooltip placement="bottom" title="Search" color="#fbfbfb"  >
                            {/* <Button onClick={() => history.push("/product-info/getAll")} */}
                            <Button onClick={() => history.push("/sales-person/list")}
                                className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
                        </Tooltip>
                    }
                    <Tooltip placement="bottom" title="Add" color="#fbfbfb"  >
                        <Button disabled={(formType === "add") || (userRights?.can_add === false ? true : false)}
                            onClick={() => {
                                // history.push("/product-info?mode=add")
                                formState("add")
                            }}                            // onClick={() => formState("add")}
                            className="iconBtn crudBTN" shape="circle" icon={<PlusOutlined />} />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Edit" color="#fbfbfb"  >
                        <Button
                            disabled={(formType === "add" || formType === "edit") || (userRights?.can_edit === false ? true : false)}
                            onClick={() => formState("edit")} className="iconBtn crudBTN" shape="circle" icon={<EditOutlined />} />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Delete" color="#fbfbfb"   >
                        {userRights?.can_delete === false ?
                            <Button disabled={true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />

                            :
                            <Popconfirm disabled={disable} placement="topLeft" title={"Are you sure to delete this?"} onConfirm={confirm} okText="Yes" cancelText="No">
                                <Button disabled={(disable || (formType === "add"))} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        }
                    </Tooltip>

                    <Tooltip placement="bottom" title="Save" color="#fbfbfb"  >
                        <Button
                            disabled={!((formType === "add" || formType === "edit") && (name === "product" || name === "saleOrder" ? formType === "edit" ? true : flag ? true : false : !disable))}
                            onClick={() => save()} className="iconBtn crudBTN" shape="circle" icon={<FileDoneOutlined />} />
                    </Tooltip >
                </div >
            </div >
            <Divider />
        </>
    )
}