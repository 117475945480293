import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getFabric, addFabric, deleteFabric, updateFabric } from "../../redux/actions/fabric.action";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { logColumns, fabricColumns, pickTicketStatusColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { addPickTestStatus, deletePickTicketStatus, getPickTicketStatus, updatePickTicketStatus } from '../../redux/actions/pickTicketStatus.action';
import { TabTitle } from '../../utils/GeneralFunction';
const { TabPane } = Tabs;
export default function PickTicketStatus({ token }) {
    TabTitle("Pick Ticket Status")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const PickTickStatusNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [fabricObj, setFabricObj] = useState(null);
    const [pickTicketObj, setPickTicketObj] = useState(null);
    const [fabricName, setFabricName] = useState('');
    const [pickTicketStatusName, setPickTicketStatusName] = useState('');
    const dispatch = useDispatch();
    const { fabric, getDemoDefaultCompany, companyAccess, companyAll, log, userRights, pickTicketStatus } = useSelector(state => state)
    // const stateData = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');

    const handleEdit = (record, type) => {
        if (type === "active") {
            dispatch(updateCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, pickTicketObj.pt_status_id, menuId)));
        }
        if (type === "delete") {
            dispatch(deleteCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, pickTicketObj.pt_status_id, menuId)));
        }
    }

    const createAssignCompany = (record) => {
        dispatch(createCompanyAccess(token, record, pickTicketObj.pt_status_id, menuId)).then((res) => dispatch(getCompanyAccess(token, pickTicketObj.pt_status_id, menuId)))
    }

    const companyCol = [
        { title: 'Company ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Tooltip title="Minimize" color="#fbfbfb"  >
                    </Tooltip>
                    {
                        record.is_active ?
                            <Tooltip title="Activate" color="#fbfbfb"  ><CheckCircleOutlined className='color-green' onClick={() => handleEdit(record, "active")} /></Tooltip> : <Tooltip title="Deactivate" color="#fbfbfb"  ><CloseCircleOutlined className="color-red" onClick={() => handleEdit(record, "active")} /></Tooltip>}
                    {
                        dCompanyId === record.company_id.toString() ?
                            <Tooltip title="Default company can't be deleted" color="#fbfbfb"  >
                                <DeleteOutlined className='delete-Button' disabled twoToneColor="red" />
                            </Tooltip> :
                            <Tooltip title="delete" color="#fbfbfb"  >
                                <Popconfirm placement="top" title={"Are you sure to delete this?"} onConfirm={() => handleEdit(record, "delete")} okText="Yes" cancelText="No">
                                    <DeleteOutlined className='delete-Button' twoToneColor="red" />
                                </Popconfirm>
                            </Tooltip>
                    }
                </>
            ),
        },
    ];
    const companyAccessCol = [
        { title: 'ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    {
                        companyAccess?.payload?.data?.find(m => m.company_id === record?.company_id) ?
                            <>
                                <Tooltip title="Already assigned" color="#fbfbfb"  >
                                    <Button className='modalActionBTN' disabled shape='circle' icon={<CheckCircleOutlined className="color-red" />} />
                                </Tooltip>
                            </> :

                            <Tooltip title="Click to assign" color="#fbfbfb"  >
                                <Button className='modalActionBTN' onClick={() => createAssignCompany(record)} shape='circle' icon={<CloseCircleOutlined className='color-green' />} />
                            </Tooltip>
                    }
                </>
            ),
        },
    ];

    // useEffect(() => {
    //     dispatch(getDefaultCompany(token));
    // }, [token])

    useEffect(() => {
        dispatch(getPickTicketStatus(token, menuId))
    }, [token]);

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (pickTicketObj) {
            setFormState('view')
            form.setFieldsValue({
                id: pickTicketObj.pt_status_id,
                ptStatus: pickTicketObj.pt_status_name,
                active: pickTicketObj.is_active
            });
            setPickTicketStatusName(pickTicketObj.pt_status_name)
            setCheck(pickTicketObj.is_active)
            dispatch(getCompanyAccess(token, pickTicketObj.pt_status_id, menuId))

        }
    }, [pickTicketObj]);

    useEffect(() => {
        if (pickTicketStatusName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [pickTicketStatusName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const handleTab = (key) => {
        if (key === "logs") {
            // data: { inv_style: "inv_style", admin_company_entity_access: "admin_company_entity_access" }
            dispatch(getLog(token, { scm_pt_status: "scm_pt_status" }))
        }
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addPickTestStatus(token, { pickTicketStatusName, check })).then((res) => {

                if (res.type === "ADD_PICKTICKET_STATUS_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                ///need to change code //////////
                // 
                if (res.type === "ADD_PICKTICKET_STATUS_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    notification("success", "Pick Ticket Status Created", "Pick Ticket created successfully!");
                    dispatch(getPickTicketStatus(token, menuId))
                    dispatch(createCompanyAccess(token, data, res?.payload.p_internal_id[0].p_internal_id, menuId)).then((res) => dispatch(getCompanyAccess(token, pickTicketObj.pt_status_id, menuId)))
                    form.setFieldsValue({
                        ptStatus: ""
                    })
                    setFormState("")
                    // setCheck(false)
                    setPickTicketStatusName("")
                    setPickTicketObj(null)

                }

            })
        }

        if (formState === "edit") {
            dispatch(updatePickTicketStatus(token, { pickTicketStatusName, check }, pickTicketObj.pt_status_id)).then((res) => {
                if (res.type === "UPDATE_PICKTICKET_STATUS_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_PICKTICKET_STATUS_SUCCESS") {
                    notification("success", "Pick Ticket Updated", "Record updated Successfully")
                }
                dispatch(getPickTicketStatus(token, menuId))
            })
        }
    }

    const handleDelete = () => {
        if (pickTicketObj) {
            dispatch(deletePickTicketStatus(token, pickTicketObj.pt_status_id)).then((res) => {
                if (res.type === "DELETE_PICKTICKET_STATUS_SUCCESS") {
                    notification("success", "Pick Ticket Deleted", "Pick Ticket Deleted Successfully!");
                }
                if (res.type === "DELETE_PICKTICKET_STATUS_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    ptStatus: ""
                })
                // setCheck(false)
                setPickTicketStatusName("")
                setPickTicketObj(null)
                setFormState('')
                dispatch(getPickTicketStatus(token, menuId))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            PickTickStatusNameInput.current.focus();
            form.setFieldsValue({
                ptStatus: ""
            })
            setCheck(true)
            setPickTicketStatusName("")
            setPickTicketObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer'>
            <div className='styleBody h-100'  >
                <PageHeader title={"Pick Ticket Status"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="pickTicketStatus" pickTicketObj={setPickTicketObj} scrollX={"20rem"} scroll={602} perPage={25} source={pickTicketStatus?.payload?.data} col={pickTicketStatusColumns} paginate load={pickTicketStatus.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {pickTicketObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            initialValue={form.getFieldValue().id}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>
                                                }
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Pick Ticket Status"
                                                        name="ptStatus"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                        initialValue={form.getFieldValue().style}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={PickTickStatusNameInput} onChange={(e) => setPickTicketStatusName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='modalStyle' >

                                            {pickTicketObj === null ?
                                                <Tooltip title="Please select style first" color="#fbfbfb"  >
                                                    <Button disabled={pickTicketObj === null ? true : false} className='' onClick={showModal} >Assign Companies</Button>
                                                </Tooltip>
                                                : <Button type="primary" className='modalButton' onClick={showModal} >Assign Companies</Button>}

                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                                <TabPane tab="Companies" key="1">
                                                    <div className='tabs' >
                                                        <Tables
                                                            type="company" scroll={340} scrollX={"25rem"}
                                                            source={(formState === "add") ? getDemoDefaultCompany?.payload?.data : (formState === "edit" || formState === "view" ? companyAccess?.payload?.data : [])}
                                                            loading={(formState === "add") ? getDemoDefaultCompany.loading : companyAccess.loading} col={companyCol} />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                        <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}
                                            >
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}