import React, { useCallback, useEffect, useState } from "react";
import {
  Spin,
  Select,
  DatePicker,
  Checkbox,
  Form,
  Button,
  Table,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo-black.svg";
import "../Distributor/Distributor.css";
import Loader from "react-loader-spinner";
import { getWashName } from "../../redux/actions/wash.action";
import { useRef } from "react";
import { intransitReport } from "../../redux/actions/intransitReport";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { getStyleShot } from "../../redux/actions/styleShot.action";
import numeral from "numeral";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getAvalaraInvoice } from "../../redux/actions/avalaraInvoice.action";
const { Option } = Select;

const AvalaraInvoice = ({ token }) => {
  TabTitle("Avalara Invoices");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    gender,
    season,
    seasonYear,
    styleChange,
    Sku,
    wash,
    customerForms,
    avalaraInvoices,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [intransitList, setIntransitList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [customer, setCustomer] = useState("All");
  const [allFilters, setAllFilters] = useState([
    { id: 1, name: "Return", selected: false },
    { id: 2, name: "Pending", selected: false },
    { id: 3, name: "POD", selected: false },
    { id: 4, name: "Shipped", selected: false },
  ]);
  const initialDate = moment("2026-12-31");

  const statusSelectAll = (e) => {
    let copyArr = [...allFilters];
    copyArr.map((v) => {
      v.selected = false;
      return v;
    });
    setAllFilters(copyArr);
    setAllStatus(e.target.checked);
  };

  useEffect(() => {
    if (allStatus) {
      // console.log("running")
      setFilters([]);
      // console.log(filters);
    }
  }, [allStatus]);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let query_sku = query.get("sku");
  // console.log("query_sku", query_sku)

  // useEffect(() => {
  //   if (query_sku) {
  //     const values = {
  //       sku: query_sku,
  //     };
  //     form.setFieldsValue({
  //       sku: values.sku,
  //     });
  //     setLoading(true);
  //     dispatch(intransitReport(token, values)).then((res) => {
  //       setLoading(false);
  //       setIntransitList(res?.payload?.data);
  //       downloadExcelFileData(res?.payload?.data);
  //     });
  //   }
  // }, [query_sku]);

  const onFinish = async (e) => {
    setLoading(true);
    await form.validateFields().then((data) => {
      console.log(data);
      dispatch(getAvalaraInvoice(token, data)).then((res) => {
        console.log(res.payload);
        setLoading(false);
        setIntransitList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  useEffect(() => {
    // dispatch(getStyle(token, menuId));
    // dispatch(getGender(token, menuId));
    // dispatch(getSeason(token, menuId));
    // dispatch(getSeasonYear(token, menuId));
    // dispatch(getProductStatus(token, menuId));
    // dispatch(getProductClass(token, menuId));
    // dispatch(getSize(token, menuId));
    // dispatch(getWashName(token, menuId));
    // dispatch(getFitCategory(token, menuId));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  const colums = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Invoice Header Id",
      dataIndex: "invoice_header_id",
      key: "invoice_header_id",
      sorter: (a, b) => {
        if ((a.invoice_header_id !== null) & (b.invoice_header_id !== null)) {
          return a.invoice_header_id.localeCompare(b.invoice_header_id);
        }
      },
    },
    {
      title: "Invoice Header No",
      dataIndex: "invoice_header_no",
      key: "invoice_header_no",
      sorter: (a, b) => {
        return a.invoice_header_no - b.invoice_header_no;
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      sorter: (a, b) => {
        return a.invoice_date - b.invoice_date;
      },
    },
    {
      title: "Billing Customer",
      dataIndex: "billingcustomer",
      key: "billingcustomer",
      sorter: (a, b) => {
        return a.billingcustomer - b.billingcustomer;
      },
    },
    {
      title: "Billing Address 1",
      dataIndex: "billingaddress_1",
      key: "billingaddress_1",
      sorter: (a, b) => {
        return a.billingaddress_1 - b.billingaddress_1;
      },
    },
    {
      title: "Billing Address 2",
      dataIndex: "billingaddress_2",
      key: "billingaddress_2",
      sorter: (a, b) => {
        return a.billingaddress_2 - b.billingaddress_2;
      },
    },
    {
      title: "Bill to City",
      dataIndex: "bill_to_city_name",
      key: "bill_to_city_name",
      sorter: (a, b) => {
        return a.bill_to_city_name - b.bill_to_city_name;
      },
    },
    {
      title: "Bill to Country",
      dataIndex: "bill_to_country_name",
      key: "bill_to_country_name",
      sorter: (a, b) => {
        return a.bill_to_country_name - b.bill_to_country_name;
      },
    },
    {
      title: "Bill to State",
      dataIndex: "bill_to_state_name",
      key: "bill_to_state_name",
      sorter: (a, b) => {
        return a.bill_to_state_name - b.bill_to_state_name;
      },
    },
    {
      title: "Billing Zip",
      dataIndex: "billing_zip",
      key: "billing_zip",
      sorter: (a, b) => {
        return a.billing_zip - b.billing_zip;
      },
    },
    {
      title: "Shipping Address 1",
      dataIndex: "shippingaddress_1",
      key: "shippingaddress_1",
      sorter: (a, b) => {
        return a.shippingaddress_1 - b.shippingaddress_1;
      },
    },
    {
      title: "Shipping Address 2",
      dataIndex: "shippingaddress_2",
      key: "shippingaddress_2",
      sorter: (a, b) => {
        return a.shippingaddress_2 - b.shippingaddress_2;
      },
    },
    {
      title: "Ship to City",
      dataIndex: "ship_to_city_name",
      key: "ship_to_city_name",
      sorter: (a, b) => {
        return a.ship_to_city_name - b.ship_to_city_name;
      },
    },
    {
      title: "Ship to Country",
      dataIndex: "ship_to_country_name",
      key: "ship_to_country_name",
      sorter: (a, b) => {
        return a.ship_to_country_name - b.ship_to_country_name;
      },
    },
    {
      title: "Ship to State",
      dataIndex: "ship_to_state_name",
      key: "ship_to_state_name",
      sorter: (a, b) => {
        return a.ship_to_state_name - b.ship_to_state_name;
      },
    },
    {
      title: "Shipping Zip",
      dataIndex: "shipping_zip",
      key: "shipping_zip",
      sorter: (a, b) => {
        return a.shipping_zip - b.shipping_zip;
      },
    },
    {
      title: "Term",
      dataIndex: "term",
      key: "term",
      sorter: (a, b) => {
        return a.term - b.term;
      },
    },
    {
      title: "Reference No",
      dataIndex: "reference_no",
      key: "reference_no",
      sorter: (a, b) => {
        return a.reference_no - b.reference_no;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      sorter: (a, b) => {
        return a.sku - b.sku;
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      align: "right",
      render: (text, record) => {
        return numeral(record.rate).format("0,0.00");
      },
      sorter: (a, b) => {
        return a.rate - b.rate;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (text, record) => {
        return numeral(record.quantity).format("0,0.00");
      },
      sorter: (a, b) => {
        return a.quantity - b.quantity;
      },
    },
    {
      title: "Tax Amount",
      dataIndex: "tax_amount",
      key: "tax_amount",
      align: "right",
      render: (text, record) => {
        return numeral(record.tax_amount).format("0,0.00");
      },
      sorter: (a, b) => {
        return a.tax_amount - b.tax_amount;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (text, record) => {
        return numeral(record.amount).format("0,0.00");
      },
      sorter: (a, b) => {
        return a.amount - b.amount;
      },
    },
    {
      title: "Discount Amount",
      dataIndex: "discount_amount",
      key: "discount_amount",
      align: "right",
      render: (text, record) => {
        return numeral(record.discount_amount).format("0,0.00");
      },
      sorter: (a, b) => {
        return a.discount_amount - b.discount_amount;
      },
    },
    {
      title: "Shipping Cost",
      dataIndex: "shipping_cost",
      key: "shipping_cost",
      align: "right",
      render: (text, record) => {
    return numeral(record.shipping_cost).format('0,0.00');
  },
      sorter: (a, b) => {
        return a.shipping_cost - b.shipping_cost;
      },
    },
    // {
    //   title: "Inseam",
    //   dataIndex: "inseam",
    //   key: "inseam",
    //   sorter: (a, b) => {
    //     return a.inseam - b.inseam
    //   },
    // },
    // {
    //   title: "Style",
    //   dataIndex: "style_name",
    //   key: "style_name",
    //   sorter: (a, b) => {
    //     if ((a.style_name !== null) & (b.style_name !== null)) {
    //       return a.style_name.localeCompare(b.style_name);
    //     }
    //   }
    // },
    // {
    //   title: "Wash",
    //   dataIndex: "wash_name",
    //   key: "wash_name",
    //   sorter: (a, b) => {
    //     if ((a.wash_name !== null) & (b.wash_name !== null)) {
    //       return a.wash_name.localeCompare(b.wash_name);
    //     }
    //   }
    // },
    // {
    //   title: "BLOOM",
    //   dataIndex: "bloom",
    //   key: "bloom",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.bloom - b.bloom
    //   },
    // },
    // {
    //   title: "SF",
    //   dataIndex: "sf",
    //   key: "sf",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.sf - b.sf
    //   },
    // },
    // {
    //   title: "SAKS",
    //   dataIndex: "saks",
    //   key: "saks",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.saks - b.saks
    //   },
    // },
    // {
    //   title: "SHPBP",
    //   dataIndex: "shpbp",
    //   key: "shpbp",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.shpbp - b.shpbp
    //   },
    // },
    // {
    //   title: "NORD",
    //   dataIndex: "nord",
    //   key: "nord",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.nord - b.nord
    //   },
    // },
    // {
    //   title: "NUULY",
    //   dataIndex: "nuuly",
    //   key: "nuuly",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.nuuly - b.nuuly
    //   },
    // },
    // {
    //   title: "NEIMN",
    //   dataIndex: "neiman_marcus",
    //   key: "neiman_marcus",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.neiman_marcus - b.neiman_marcus
    //   },
    // },
    // // {
    // //   title: "AMZN",
    // //   dataIndex: "amzn",
    // //   key: "amzn",
    // //   align: "right",
    // //   sorter: (a, b) => {
    // //     return a.amzn - b.amzn
    // //   },
    // // },
    // {
    //   title: "ZPPS",
    //   dataIndex: "zpps",
    //   key: "zpps",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.zpps - b.zpps
    //   },
    // },
    // {
    //   title: "GH",
    //   dataIndex: "gh",
    //   key: "gh",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.gh - b.gh
    //   },
    // },
    // {
    //   title: "MRKTING",
    //   dataIndex: "mrktng",
    //   key: "mrktng",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.mrktng - b.mrktng
    //   },
    // },
    // {
    //   title: "ANTHR",
    //   dataIndex: "anthr",
    //   key: "anthr",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.anthr - b.anthr
    //   },
    // },
    // {
    //   title: "RTR",
    //   dataIndex: "rtr",
    //   key: "rtr",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.rtr - b.rtr
    //   },
    // },
    // // {
    // //   title: "BERG",
    // //   dataIndex: "berg",
    // //   key: "berg",
    // //   align: "right",
    // //   sorter: (a, b) => {
    // //     return a.berg - b.berg
    // //   },
    // // },
    // // {
    // //   title: "JH",
    // //   dataIndex: "jh",
    // //   key: "jh",
    // //   align: "right",
    // //   sorter: (a, b) => {
    // //     return a.jh - b.jh
    // //   },
    // // },
    // // {
    // //   title: "FREE PROPLE",
    // //   dataIndex: "free_people",
    // //   key: "free_people",
    // //   align: "right",
    // //   sorter: (a, b) => {
    // //     return a.free_people - b.free_people
    // //   },
    // // },
    // {
    //   title: "MAJOR TTL",
    //   dataIndex: "majors_ttl",
    //   key: "majors_ttl",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.majors_ttl - b.majors_ttl
    //   },
    // },
    // {
    //   title: "WC",
    //   dataIndex: "wc",
    //   key: "wc",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.wc - b.wc
    //   },
    // },
    // {
    //   title: "EC",
    //   dataIndex: "ec",
    //   key: "ec",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.ec - b.ec
    //   },
    // },
    // {
    //   title: "NE1",
    //   dataIndex: "ne1",
    //   key: "ne1",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.ne1 - b.ne1
    //   },
    // },
    // {
    //   title: "MW",
    //   dataIndex: "mw",
    //   key: "mw",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.mw - b.mw
    //   },
    // },
    // {
    //   title: "SE",
    //   dataIndex: "se",
    //   key: "se",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.se - b.se
    //   },
    // },
    // {
    //   title: "SC",
    //   dataIndex: "sc",
    //   key: "sc",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.sc - b.sc
    //   },
    // },
    // {
    //   title: "SPCLTY TTL",
    //   dataIndex: "spclty_ttl",
    //   key: "spclty_ttl",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.spclty_ttl - b.spclty_ttl
    //   },
    // },
    // {
    //   title: "INT TTL",
    //   dataIndex: "int_ttl",
    //   key: "int_ttl",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.int_ttl - b.int_ttl
    //   },
    // },
    // {
    //   title: "ECOM TTL",
    //   dataIndex: "ecom_ttl",
    //   key: "ecom_ttl",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.ecom_ttl - b.ecom_ttl
    //   },
    // },
    // {
    //   title: "OP TTL",
    //   dataIndex: "op_ttl",
    //   key: "op_ttl",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.op_ttl - b.op_ttl
    //   },
    // },
    // {
    //   title: "GRAND TTL",
    //   render: (text, record) => {
    //     return record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl + record.op_ttl
    //   },
    //   align: "right",
    //   sorter: (a, b) => {
    //     const sumA = a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl + a.op_ttl;
    //     const sumB = b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl + b.op_ttl;
    //     return sumA - sumB
    //   },
    // },
    // {
    //   title: "OTS",
    //   dataIndex: "ots",
    //   key: "ots",
    //   align: "right",
    //   sorter: (a, b) => {
    //     return a.ots - b.ots
    //   },
    // },
    // {
    //   title: "ST %",
    //   align: "right",
    //   render: (text, record) => {
    //     return numeral(((record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl) / (record.ots + record.majors_ttl + record.spclty_ttl + record.int_ttl + record.ecom_ttl)) * 100).format("0,00.00")
    //   },
    //   sorter: (a, b) => {
    //     const sumA = ((a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl) / (a.ots + a.majors_ttl + a.spclty_ttl + a.int_ttl + a.ecom_ttl)) * 100
    //     const sumB = ((b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl) / (b.ots + b.majors_ttl + b.spclty_ttl + b.int_ttl + b.ecom_ttl)) * 100;
    //     return sumA - sumB
    //   },
    // },
  ];

  // function debounce(cb, delay = 1000) {
  //   let timeout;
  //   return (...args) => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => {
  //       cb(...args);
  //     }, delay);
  //   };
  // }

  // const handleSkuSearch = () => {
  //   dispatch(getSku(token, null, null, null));
  // };

  // const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  const totalQuantity = () => {
    let sumQty = intransitList.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.sum_quantity));
    }, 0);
    return sumQty;
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "invoice_header_id":v.invoice_header_id,
          "invoice_header_no": v.invoice_header_no,
          "invoice_date": v.invoice_date,
          "billingcustomer": v.billingcustomer,
          "billingaddress_1": v.billingaddress_1,
          "billingaddress_2": v.billingaddress_2,
          "bill_to_city_name": v.bill_to_city_name,
          "bill_to_country_name": v.bill_to_country_name,
          "bill_to_state_name": v.bill_to_state_name,
          "billing_zip": v.billing_zip,
          "shippingaddress_1": v.shippingaddress_1,
          "shippingaddress_2": v.shippingaddress_2,
          "ship_to_city_name": v.ship_to_city_name,
          "ship_to_state_name": v.ship_to_state_name,
          "shipping_zip": v.shipping_zip,

          "term": v.term,
          "reference_no": v.reference_no,
          "sku": v.sku,
          "rate": numeral(v.rate).format("0,0.00"),
          "quantity": numeral(v.quantity).format("0,0.00"),
          "tax_amount": numeral(v.tax_amount).format("0,0.00"),
          "amount": numeral(v.amount).format("0,0.00"),
          "discount_amount": numeral(v.discount_amount).format("0,0.00"),
          "shipping_cost": numeral(v.shipping_cost).format('0,0.00')

          // Season: v.newseason,
          // SKU: v.sku,
          // Inseam: v.inseam,
          // Style: v.style_name,
          // Wash: v.wash_name,
          // BLOOM: v.bloom,
          // SF: v.sf,
          // SAKS: v.saks,
          // SHPBP: v.shpbp,
          // NORD: v.nord,
          // NUULY: v.nuuly,
          // NEIMN: v.neiman_marcus,
          // // "AMZN": v.amzn,
          // ZPPS: v.zpps,
          // GH: v.gh,
          // MRKTING: v.mrktng,
          // ANTHR: v.anthr,
          // RTR: v.rtr,
          // // "BERG": v.berg,
          // // "JH": v.jh,
          // // "FREE PROPLE": v.free_people,
          // "MAJOR TTL": v.majors_ttl,
          // WC: v.wc,
          // EC: v.ec,
          // NE1: v.ne1,
          // MW: v.mw,
          // SE: v.se,
          // SC: v.sc,
          // "SPCLTY TTL": v.spclty_ttl,
          // "INT TTL": v.int_ttl,
          // "ECOM TTL": v.ecom_ttl,
          // "OP TTL": v.op_ttl,
          // "GRAND TTL":
          //   v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl + v.op_ttl,
          // OTS: v.ots,
          // "ST %": numeral(
          //   ((v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl) /
          //     (v.ots + v.majors_ttl + v.spclty_ttl + v.int_ttl + v.ecom_ttl)) *
          //     100
          // ).format("0,00.00"),
        };
      })
    );
  }

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(
      loadCustomers(
        token,
        null,
        null,
        null,
        null,
        null,
        val,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    ).then((res) => {
      if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
        setCustomer(res?.payload?.data);
      }
    });
  };

  const optimizedCus = useCallback(debounce(handleChanges), []);

  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Avalara-Invoices.csv"}
                  data={excelData}
                  onClick={() => {}}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    // onClick={onDownload}
                    // className="cursor-not-allowed"
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Avalara Invoice
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">
                      From
                    </label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf("year")}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">
                      To
                    </label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={initialDate}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div style={{ marginTop: "4px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        // className="business_class_oms mb-2 distributor" style={{ width: "1400px" }}
        className="table_content international_order"
        style={{ maxWidth: "1580px" }}
      >
        <div>
          {avalaraInvoices?.payload?.data !== undefined ? (
            avalaraInvoices?.payload?.data.length > 0 ? (
              <Table
                className="
                // maxWidth 
                test04 mt-3 sales_report_res_table_intransit omsStockSummaryTableNewHeader omsStockSummaryTableNewHeader2"
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName="abc"
                dataSource={intransitList}
                columns={colums}
                loading={avalaraInvoices.loading}
                pagination={{ pageSize: 500 }}
                scroll={{ x: "max-content" }}
              />
            ) : (
              <div className="no-Data">
                {/* {console.log(avalaraInvoices?.payload?.data.length, 'avalaraInvoices?.payload?.data.length')} */}
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AvalaraInvoice;
